@charset "UTF-8";
/*	COLORS
–––––––––––––––––––––––––––*/
/* Fonts ---------------
------------------------*/
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Open+Sans:300,400,600,700);
@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

/* General ---------------
------------------------*/
html,
body {
  background-color: #FFFFFF;
  height: 100%; }

select:invalid {
  color: #484848; }
  select:invalid option {
    color: #333333; }

body {
  font-family: 'Open Sans', sans-serif;
  color: #333333;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  overflow-x: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  body #root {
    height: 100vh; }
    body #root .app {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      min-height: 100vh; }

iframe#keepAlive,
iframe#silentLogout {
  width: 0;
  height: 0;
  border: 0;
  border: none; }

.print-only {
  display: none; }

.desktop-only {
  display: none !important; }

.underline {
  text-decoration: underline; }

.note {
  font-size: 0.875rem;
  color: #b3b3b3;
  margin: 0 0 1rem;
  padding: 0; }
  .note.invoice-history-note {
    padding: 1rem; }
  .note.tiny {
    font-size: 0.6875rem;
    padding-right: 2rem; }
  .note.call {
    margin-top: 2rem;
    font-size: 1.125rem;
    font-style: italic;
    padding-left: 1rem; }
    .note.call .callable {
      color: #049b5c; }
  .note.no-recent-activity {
    text-align: center;
    margin-top: 1rem !important;
    margin-bottom: 2rem;
    font-style: italic; }

.callable .desktop,
.callable a {
  white-space: nowrap;
  display: inline; }

.callable .desktop {
  display: none; }

.backdrop {
  display: flex;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  -webkit-perspective: 800px;
  perspective: 800px; }

@media (min-width: 768px) {
  .callable a {
    display: none; }
  .callable .desktop {
    display: inline;
    color: "#fff"; }
  .mobile-only {
    display: none !important; }
  .desktop-only {
    display: inline-block !important; } }

.italic {
  font-style: italic; }

.emergency {
  color: #c01515 !important; }

.instructions {
  color: #049b5c;
  font-weight: 400;
  margin-left: 1.25rem;
  margin-bottom: 0.5rem; }

/* Main  --------------------
----------------------------- */
.main {
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  margin: 0 auto; }
  .main .private-route {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: calc(100vh - 12.5rem); }

.push {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1; }

/* Normalize -------------
-------------------------- */
*,
*:after,
*:before {
  box-sizing: border-box; }

button:focus {
  outline: 0; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style: none; }

/* Typography ---------------
----------------------------- */
h2 {
  font-weight: 300;
  margin-bottom: 0; }

h3 {
  margin-bottom: 10px;
  font-weight: 500; }

/* Layout ----------------
-------------------------- */
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .row.space {
    justify-content: space-between; }
  .row.end {
    justify-content: flex-end; }

.questions {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .questions .push-down {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1; }

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.flex-grow {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1; }

.right {
  text-align: right; }

.center {
  text-align: center; }

section {
  padding: 0 1rem; }

.tools {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 1.25rem 0; }
  .tools .button {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1; }
    .tools .button:not(:first-child) {
      margin-left: .5rem; }
  .tools.pills {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    padding: 0 1rem; }

.summary {
  width: 100%;
  padding: 0 1rem; }
  .summary h2 {
    margin: 0 0 0.5rem; }
  .summary .note {
    text-align: center; }

.paperless-invoice {
  text-align: center;
  margin-bottom: 1rem; }
  .paperless-invoice svg {
    color: #049b5c;
    margin-right: .5rem;
    font-size: 1.125rem; }
  .paperless-invoice .paperless-billing-cta {
    font-weight: 700; }

.balance {
  padding: 0 1rem 0;
  width: 100%;
  text-align: center; }
  .balance .amount {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: .5rem; }

.icon-field {
  position: relative; }

.info {
  position: relative;
  display: inline;
  margin-left: 0.5rem;
  cursor: pointer; }
  .info > svg {
    font-size: 1.25rem !important;
    top: 3px !important;
    left: 1px !important; }
  .info .message {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: -13.75rem;
    margin: 0 auto;
    z-index: 20;
    width: 20rem;
    text-transform: none;
    font-size: 0.875rem;
    background: white;
    border: 1px solid #049b5c;
    border-radius: 0.25rem;
    padding: 1rem;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25); }
  .info.open {
    box-shadow: none !important; }
    .info.open .message {
      display: block; }
      .info.open .message:before, .info.open .message:after {
        content: '';
        position: absolute;
        top: -0.625rem;
        left: 10rem;
        border-right: 0.625rem solid transparent;
        border-left: 0.625rem solid transparent;
        border-bottom: 0.625rem solid #049b5c; }
      .info.open .message:after {
        left: calc(10rem + 1px);
        top: -0.5625rem;
        border-right: 0.5625rem solid transparent;
        border-left: 0.5625rem solid transparent;
        border-bottom: 0.5625rem solid #FFFFFF; }

.toggle-tip {
  position: fixed;
  z-index: 500;
  background: #FFFFFF;
  text-transform: initial;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  font-size: 0.875rem;
  font-weight: normal;
  padding: 1rem;
  padding-top: 4rem;
  cursor: default;
  animation: from-bottom 0.25s linear forwards; }
  .toggle-tip .close {
    position: absolute;
    font-size: 2rem;
    right: 0.5rem;
    top: 0; }

@media (min-width: 768px) {
  .toggle-tip {
    position: absolute;
    z-index: 100;
    background: #FFFFFF;
    text-transform: initial;
    width: 22.5rem;
    height: auto;
    top: 2.25rem;
    left: -16.5rem;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    font-size: 0.875rem;
    font-weight: normal;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #CCCCCC;
    cursor: default;
    animation: none; }
    .toggle-tip .close {
      display: none; }
    .toggle-tip::before {
      content: '';
      position: absolute;
      top: -0.55rem;
      left: 19rem;
      height: 1rem;
      width: 1rem;
      background: #FFFFFF;
      transform: rotate(45deg);
      box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, 0.15);
      border-left: 1px solid #CCCCCC;
      border-top: 1px solid #CCCCCC;
      z-index: -1; } }

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0; }

.generated-user-image {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  width: 48px;
  min-width: 2em;
  height: 48px;
  min-width: 2em;
  color: #FFFFFF;
  text-transform: uppercase;
  border-radius: 50%;
  text-align: center;
  border: 0.1em solid #FFFFFF;
  background-color: #049b5c; }
  .generated-user-image img {
    width: 43px;
    height: 43px;
    border-radius: 50%; }

svg.generated-user-image {
  background: #FFFFFF; }

@media (max-width: 768px) {
  [mobilebefore]::before {
    content: attr(mobilebefore) " "; }
  [mobileafter]::after {
    content: " " attr(mobileafter); } }

@media (min-width: 768px) {
  [desktopbefore]::before {
    content: attr(desktopbefore) " "; }
  [desktopafter]::after {
    content: " " attr(desktopafter); } }

input:focus,
input:focus .etable-heading.toggle,
textarea:focus,
textarea:focus .etable-heading.toggle,
button:focus,
button:focus .etable-heading.toggle,
select:focus,
select:focus .etable-heading.toggle,
a:focus,
a:focus .etable-heading.toggle,
[tabindex]:focus,
[tabindex]:focus .etable-heading.toggle {
  outline: none;
  box-shadow: 0 0 0.5rem 0 #4A90E2; }

.work-address-select {
  margin: 0 0 0.5rem 0; }

.commute-info.edit .body .select-switch.pad-desk {
  padding-left: 0; }
  .commute-info.edit .body .select-switch.pad-desk .work-address-select {
    width: 100%; }

.mobile-only-label {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  width: 100%;
  font-size: .8rem; }
  .mobile-only-label label {
    font-size: 0.8rem;
    min-width: 5.5rem; }

.fuel-card-charges-table .mobile-only-label,
.recurring .mobile-only-label,
.monthly-charges-table .mobile-only-label {
  justify-content: flex-start; }

@media (min-width: 768px) {
  .mobile-only-label {
    width: auto; }
    .mobile-only-label.right {
      justify-content: flex-end; }
    .mobile-only-label label {
      display: none; }
  .amount .mobile-only-label {
    justify-content: flex-end !important; }
  .fuel-card-charges-table .mobile-only-label,
  .recurring .mobile-only-label,
  .monthly-charges-table .mobile-only-label {
    justify-content: center; }
  .fuel-card-charges-table .description .mobile-only-label,
  .recurring .description .mobile-only-label,
  .monthly-charges-table .description .mobile-only-label {
    justify-content: flex-start; }
  .transactionsContainer .wex-transactions-table .etable-row .etable-column .etable-cell .mobile-only-label {
    justify-content: flex-start; } }

/*	COLORS
–––––––––––––––––––––––––––*/
.aggregate-nav {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: calc(60px - 1px);
  z-index: 100; }

.aggregate-nav-container, .aggregate-nav-filler {
  position: relative;
  top: 1px; }
  .aggregate-nav-container button, .aggregate-nav-filler button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    width: 100%;
    padding: .75rem .75rem .75rem 1rem; }
    .aggregate-nav-container button:focus, .aggregate-nav-filler button:focus {
      box-shadow: none;
      background-color: #e6e6e6; }
    .aggregate-nav-container button:hover, .aggregate-nav-filler button:hover {
      background-color: #f2f2f2; }
  .aggregate-nav-container .current-aggregate, .aggregate-nav-filler .current-aggregate {
    position: relative;
    font-size: 1.125rem;
    justify-content: flex-start;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 2rem;
    overflow: hidden;
    width: 100%;
    display: block;
    border: #CCCCCC solid 1px;
    border-top: none;
    text-align: left;
    background: #FFFFFF; }
    .aggregate-nav-container .current-aggregate svg, .aggregate-nav-filler .current-aggregate svg {
      position: absolute;
      top: 1rem;
      right: 0.5rem;
      transition: all ease .3s;
      color: #049b5c;
      margin-left: 1rem; }
    .aggregate-nav-container .current-aggregate.show svg, .aggregate-nav-filler .current-aggregate.show svg {
      transform: scale(1, -1); }
  .aggregate-nav-container .selector.aggregates, .aggregate-nav-filler .selector.aggregates {
    height: calc(100vh - 3.5rem);
    overflow-x: hidden;
    overflow-y: auto;
    background: transparent;
    z-index: 9999; }
    .aggregate-nav-container .selector.aggregates button, .aggregate-nav-filler .selector.aggregates button {
      border: 1px solid #CCCCCC;
      border-left: #CCCCCC solid 1px;
      border-top: none;
      padding-left: 19px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
      background-color: #FFFFFF;
      cursor: pointer;
      font-size: 1.125rem;
      display: block;
      text-align: left;
      color: #049b5c;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .aggregate-nav-container .selector.aggregates button:first-child, .aggregate-nav-filler .selector.aggregates button:first-child {
      border-top: 1px solid #CCCCCC; }
    .aggregate-nav-container .selector.aggregates .drilldown:first-child .down, .aggregate-nav-filler .selector.aggregates .drilldown:first-child .down {
      border-top: 1px solid #CCCCCC; }
    .aggregate-nav-container .selector.aggregates .back, .aggregate-nav-filler .selector.aggregates .back {
      font-size: 1.125rem; }
      .aggregate-nav-container .selector.aggregates .back svg, .aggregate-nav-filler .selector.aggregates .back svg {
        position: relative;
        top: -1px;
        margin-right: 0.5rem; }
    .aggregate-nav-container .selector.aggregates .title, .aggregate-nav-filler .selector.aggregates .title {
      margin-left: -1px;
      width: calc(100%); }
      .aggregate-nav-container .selector.aggregates .title svg, .aggregate-nav-filler .selector.aggregates .title svg {
        margin-right: 0.5rem;
        position: relative;
        top: -1px; }
    .aggregate-nav-container .selector.aggregates .drilldown, .aggregate-nav-filler .selector.aggregates .drilldown {
      position: relative;
      padding-right: 3rem; }
      .aggregate-nav-container .selector.aggregates .drilldown:focus, .aggregate-nav-filler .selector.aggregates .drilldown:focus {
        box-shadow: none;
        background-color: #e6e6e6; }
      .aggregate-nav-container .selector.aggregates .drilldown:hover, .aggregate-nav-filler .selector.aggregates .drilldown:hover {
        background-color: #f2f2f2; }
      .aggregate-nav-container .selector.aggregates .drilldown .down, .aggregate-nav-filler .selector.aggregates .drilldown .down {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 3rem;
        color: #049b5c; }
        .aggregate-nav-container .selector.aggregates .drilldown .down:focus, .aggregate-nav-filler .selector.aggregates .drilldown .down:focus {
          background: #049b5c;
          color: #FFFFFF; }
    .aggregate-nav-container .selector.aggregates .vanpool, .aggregate-nav-filler .selector.aggregates .vanpool {
      color: #CCCCCC; }
      .aggregate-nav-container .selector.aggregates .vanpool .id, .aggregate-nav-filler .selector.aggregates .vanpool .id {
        margin-left: 2rem;
        white-space: nowrap;
        color: #049b5c; }
      .aggregate-nav-container .selector.aggregates .vanpool .name, .aggregate-nav-filler .selector.aggregates .vanpool .name {
        margin-left: 1rem;
        font-size: 1rem;
        font-style: italic;
        color: #CCCCCC; }
  .aggregate-nav-container .aggregate-emphasis, .aggregate-nav-filler .aggregate-emphasis {
    font-size: 1rem;
    font-style: italic;
    color: #CCCCCC;
    position: relative;
    top: -2px; }
  .aggregate-nav-container .aggregate-children, .aggregate-nav-filler .aggregate-children {
    margin-left: auto; }
  .aggregate-nav-container .aggregate-children, .aggregate-nav-filler .aggregate-children {
    margin-left: auto; }

@media (min-width: 768px) {
  .aggregate-nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    margin-top: 0;
    top: 1px;
    min-width: calc(100vw - 30rem);
    max-width: calc(100vw - 30rem);
    margin-right: 1rem; }
    .aggregate-nav::after {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      content: '';
      display: block; }
  .aggregate-nav-container, .aggregate-nav-filler {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1; }
    .aggregate-nav-container .current-aggregate, .aggregate-nav-filler .current-aggregate {
      min-width: calc(100vw - 30rem);
      max-width: calc(100vw - 30rem);
      border-left: 1px solid #CCCCCC;
      border-right: 1px solid #CCCCCC;
      padding: 1rem 1.25rem 1.125rem;
      background-color: #FFFFFF;
      color: #333333;
      font-size: 1.25rem;
      justify-content: flex-start;
      text-align: left;
      position: relative;
      padding-right: 2rem; }
      .aggregate-nav-container .current-aggregate svg, .aggregate-nav-filler .current-aggregate svg {
        position: absolute;
        top: 1.25rem;
        right: 0.5rem;
        transition: all ease .3s; }
      .aggregate-nav-container .current-aggregate.show, .aggregate-nav-filler .current-aggregate.show {
        display: block; }
        .aggregate-nav-container .current-aggregate.show svg, .aggregate-nav-filler .current-aggregate.show svg {
          transform: scale(1, -1); }
    .aggregate-nav-container .selector.aggregates, .aggregate-nav-filler .selector.aggregates {
      position: absolute;
      top: 4rem;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 15.25rem);
      height: auto; }
      .aggregate-nav-container .selector.aggregates .title, .aggregate-nav-filler .selector.aggregates .title {
        margin-left: auto; }
        .aggregate-nav-container .selector.aggregates .title.no-show, .aggregate-nav-filler .selector.aggregates .title.no-show {
          display: none; }
      .aggregate-nav-container .selector.aggregates button, .aggregate-nav-filler .selector.aggregates button {
        font-size: 1.25rem; }
        .aggregate-nav-container .selector.aggregates button:focus, .aggregate-nav-container .selector.aggregates button:hover, .aggregate-nav-filler .selector.aggregates button:focus, .aggregate-nav-filler .selector.aggregates button:hover {
          border-left: #049b5c solid 5px;
          padding-left: 15px; }
        .aggregate-nav-container .selector.aggregates button:focus, .aggregate-nav-filler .selector.aggregates button:focus {
          background-color: #e6e6e6; }
        .aggregate-nav-container .selector.aggregates button:hover, .aggregate-nav-filler .selector.aggregates button:hover {
          background-color: #f2f2f2; }
      .aggregate-nav-container .selector.aggregates .drilldown:last-child button, .aggregate-nav-container .selector.aggregates .vanpool:last-child button, .aggregate-nav-filler .selector.aggregates .drilldown:last-child button, .aggregate-nav-filler .selector.aggregates .vanpool:last-child button {
        border: 1px solid #CCCCCC;
        border-left: #CCCCCC solid 1px;
        border-bottom: 1px solid #CCCCCC; }
        .aggregate-nav-container .selector.aggregates .drilldown:last-child button:focus, .aggregate-nav-container .selector.aggregates .drilldown:last-child button:hover, .aggregate-nav-container .selector.aggregates .vanpool:last-child button:focus, .aggregate-nav-container .selector.aggregates .vanpool:last-child button:hover, .aggregate-nav-filler .selector.aggregates .drilldown:last-child button:focus, .aggregate-nav-filler .selector.aggregates .drilldown:last-child button:hover, .aggregate-nav-filler .selector.aggregates .vanpool:last-child button:focus, .aggregate-nav-filler .selector.aggregates .vanpool:last-child button:hover {
          border-left: #049b5c solid 5px;
          padding-left: 15px; }
      .aggregate-nav-container .selector.aggregates button.drilldown-name:last-child, .aggregate-nav-filler .selector.aggregates button.drilldown-name:last-child {
        border: 1px solid #CCCCCC;
        border-left: #CCCCCC solid 1px;
        border-bottom: 1px solid #CCCCCC; }
        .aggregate-nav-container .selector.aggregates button.drilldown-name:last-child:focus, .aggregate-nav-container .selector.aggregates button.drilldown-name:last-child:hover, .aggregate-nav-filler .selector.aggregates button.drilldown-name:last-child:focus, .aggregate-nav-filler .selector.aggregates button.drilldown-name:last-child:hover {
          border-left: #049b5c solid 5px;
          padding-left: 15px; }
      .aggregate-nav-container .selector.aggregates .aggregate-children, .aggregate-nav-filler .selector.aggregates .aggregate-children {
        margin-left: auto; }
        .aggregate-nav-container .selector.aggregates .aggregate-children.pad-left, .aggregate-nav-filler .selector.aggregates .aggregate-children.pad-left {
          margin-left: calc(100vw / 7); } }

/*	COLORS
–––––––––––––––––––––––––––*/
@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.alerts-container {
  position: relative; }
  .alerts-container .bell {
    display: flex;
    position: relative; }
    .alerts-container .bell .newAlertIcon {
      position: absolute;
      height: 18px;
      width: 18px;
      right: -3px;
      top: 3px; }
    .alerts-container .bell .bell-icon {
      height: 28px;
      width: 28px; }
  .alerts-container span.num:not(.none) {
    color: #f96302; }
  .alerts-container .alerts {
    padding: 2rem 0 0; }
    .alerts-container .alerts.up-to-date {
      padding: 2rem; }
      .alerts-container .alerts.up-to-date .big {
        font-size: 1.25rem;
        text-align: center;
        margin-bottom: .25rem; }
      .alerts-container .alerts.up-to-date .small {
        font-size: 0.875rem;
        color: #b3b3b3;
        text-align: center; }
    .alerts-container .alerts .count {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #393A3B;
      margin: 10px 0px; }
      .alerts-container .alerts .count span {
        font-weight: 700; }
    .alerts-container .alerts .alert-body {
      max-height: calc(100vh - 4.5rem);
      overflow: auto;
      overflow-x: hidden; }
      .alerts-container .alerts .alert-body .note {
        width: 100%;
        padding: 1rem 0rem;
        margin-top: 5px;
        border-top: 1px solid #CCCCCC;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        position: sticky;
        bottom: 0;
        background: #fff; }
        .alerts-container .alerts .alert-body .note.no-alert {
          border-top: none;
          margin-bottom: 0; }
    .alerts-container .alerts .alert {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      position: relative;
      width: 100%;
      padding: .75rem;
      border-left: solid 0.1875rem #FFFFFF; }
      .alerts-container .alerts .alert .delete {
        position: absolute;
        right: 0;
        top: 0.5rem;
        width: 2rem;
        text-align: center;
        font-size: 1rem;
        background: #f2f2f2;
        cursor: pointer; }

@media (min-width: 768px) {
  .alerts-container .bell svg {
    color: #049b5c;
    font-size: 1.25rem;
    position: relative;
    top: -2px;
    cursor: pointer;
    height: 28px; }
  .alerts-container .bell .notice {
    background-color: #f96302;
    position: absolute;
    right: -3px;
    top: -1px;
    width: .7rem;
    height: .7rem;
    border-radius: .5rem;
    z-index: 100; }
  .alerts-container .alerts {
    width: 600px;
    left: -33.8rem;
    top: 3rem;
    padding: 20px; }
    .alerts-container .alerts.toggle-tip {
      border: none;
      box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.32);
      border-radius: 0; }
    .alerts-container .alerts::before {
      left: 34rem; }
    .alerts-container .alerts .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .alerts-container .alerts .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
      .alerts-container .alerts .header .close {
        display: flex;
        height: 24px;
        width: 24px;
        position: relative;
        cursor: pointer; }
    .alerts-container .alerts .alert-body {
      max-height: 50vh;
      border-radius: 0 0 0.5rem 0.5rem; }
    .alerts-container .alerts .alert .summary,
    .alerts-container .alerts .alert .description {
      padding-right: 1rem; } }

@media (min-width: 768px) and (max-width: 1350px) {
  .alerts-container .alerts {
    position: fixed;
    left: unset;
    top: 55px;
    right: 50px; }
    .alerts-container .alerts::before {
      left: 34.5rem; } }

@media (max-width: 1350px) {
  .notice {
    background-color: #f96302;
    position: absolute;
    right: 0px;
    top: 3px;
    width: .4rem;
    height: .4rem;
    border-radius: .2rem;
    z-index: 100; } }

@media only screen and (max-width: 700px) {
  .alerts-container .alerts {
    padding: 20px; }
    .alerts-container .alerts .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .alerts-container .alerts .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
      .alerts-container .alerts .header .close {
        display: flex;
        height: 24px;
        width: 24px;
        position: relative;
        cursor: pointer; }
    .alerts-container .alerts.toggle-tip::before {
      content: '';
      position: absolute;
      top: -0.55rem;
      left: 21.8rem;
      height: 1rem;
      width: 1rem;
      background: #FFFFFF;
      transform: rotate(45deg);
      box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, 0.15);
      border-left: 1px solid #CCCCCC;
      border-top: 1px solid #CCCCCC;
      z-index: -1; }
    .alerts-container .alerts .alert-body {
      max-height: 60vh;
      overflow: auto; } }

/* Banner Messages ---------------
------------------------*/
.banner {
  position: fixed;
  color: #FFFFFF;
  width: 100%;
  top: 0;
  z-index: 9999;
  left: 0;
  animation: drop-in 0.5s linear;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 1rem; }
  .banner.error {
    background: #e72121; }
  .banner.success {
    background: #049b5c; }
  .banner.out {
    animation: drop-out 0.6s linear; }
  .banner .close-button {
    cursor: pointer; }
    .banner .close-button:focus {
      outline: none;
      border: none;
      box-shadow: none; }

@media (min-width: 768px) {
  .banner {
    height: 64px; }
    .banner.short-nav {
      top: 65px; } }

@keyframes drop-in {
  0% {
    transform: translateY(-60px); }
  100% {
    transform: translateY(0); } }

@keyframes drop-out {
  80%, 100% {
    transform: translateY(-60px); }
  0% {
    transform: translateY(0); } }

/* Footer --------------------
------------------------------ */
footer.global {
  width: 100%;
  position: relative;
  color: #FFFFFF;
  z-index: 0;
  background: #333333; }
  footer.global .top {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    padding-top: 0.5rem;
    max-width: 60rem;
    margin: 0 auto; }
    footer.global .top h1 {
      text-align: center;
      margin: 0 0 1rem;
      padding: 0 0 1rem;
      border-bottom: 2px solid #666666;
      width: 75%; }
      footer.global .top h1 .branding {
        width: 100%; }
    footer.global .top .links {
      -webkit-box-flex: 1 1 auto;
      -moz-box-flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around;
      padding-bottom: 1rem; }
      footer.global .top .links .desk-privacy {
        display: none; }
      footer.global .top .links .section {
        font-size: 0.875rem;
        text-align: center; }
        footer.global .top .links .section .title {
          margin-bottom: 0.4rem; }
        footer.global .top .links .section a {
          margin-top: 0.2rem;
          font-size: 0.75rem;
          color: #CCCCCC;
          display: block;
          margin-left: -0.2rem;
          padding-left: 0.2rem; }
  footer.global .bottom {
    width: 100vw;
    background: #000000;
    padding: 0.5rem;
    font-size: 0.75rem;
    text-align: center; }
    footer.global .bottom .mobile-privacy {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      margin-top: 1rem; }
      footer.global .bottom .mobile-privacy .title {
        font-size: 0.875rem;
        margin-bottom: 0.4rem; }
      footer.global .bottom .mobile-privacy a {
        color: #CCCCCC; }
        footer.global .bottom .mobile-privacy a .privacyImg {
          width: 20px;
          margin-left: 5px; }
        footer.global .bottom .mobile-privacy a .adchoiceImg {
          width: 10px;
          margin-left: 5px; }
    footer.global .bottom p {
      margin: 1rem 0;
      padding: 0; }

@media (min-width: 768px) {
  footer.global {
    min-height: 13rem; }
    footer.global .top {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      height: 11rem;
      max-width: 60rem;
      margin: 0 auto;
      padding-top: 0; }
      footer.global .top h1 {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 100%;
        border: none;
        margin: 0;
        padding: 0; }
        footer.global .top h1 .branding {
          display: block;
          height: 5rem; }
      footer.global .top .links {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-end;
        -moz-justify-content: flex-end;
        -ms-justify-content: flex-end;
        justify-content: flex-end;
        -ms-flex-pack: flex-end; }
        footer.global .top .links .desk-privacy {
          display: block; }
        footer.global .top .links .section {
          text-align: left;
          border-left: 1px solid #b3b3b3;
          margin-left: 4rem;
          padding-left: 2rem;
          min-width: 11rem; }
          footer.global .top .links .section a .privacyImg {
            width: 20px;
            margin-left: 5px; }
          footer.global .top .links .section a .adchoiceImg {
            width: 10px;
            margin-left: 5px; }
    footer.global .bottom {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      position: absolute;
      width: 100vw;
      height: 3rem;
      background: #000000;
      bottom: 0;
      left: 0;
      font-size: 0.75rem; }
      footer.global .bottom .mobile-privacy {
        display: none; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

/* Header --------------------
------------------------------ */
header.global {
  position: fixed;
  width: 100vw;
  z-index: 100; }
  header.global #logo-dashboard-nav-link {
    box-shadow: none; }
  header.global nav {
    height: 60px;
    transition: height .4s ease-in-out;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    header.global nav .transparent-bkrd {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      background: #FFFFFF;
      opacity: .8;
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2; }
    header.global nav .profile-info {
      display: flex;
      padding: 12px; }
      header.global nav .profile-info .user-image {
        height: fit-content !important; }
        header.global nav .profile-info .user-image .generated-user-image {
          height: 36px !important;
          width: 36px !important;
          line-height: 32px; }
          header.global nav .profile-info .user-image .generated-user-image.withImage {
            background-color: #fff; }
          header.global nav .profile-info .user-image .generated-user-image img {
            width: 31px;
            height: 31px;
            border-radius: 50%; }
      header.global nav .profile-info .name-info .name {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 600;
        color: #4A4A4A;
        text-transform: capitalize; }
      header.global nav .profile-info .name-info .company {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383; }
    header.global nav .h1-heading {
      display: flex;
      align-items: center;
      padding: .5rem 1rem;
      background-color: #FFFFFF;
      height: 60px; }
    header.global nav .stationary {
      background-color: #FFFFFF; }
    header.global nav .profile-div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px; }
    header.global nav .profile-image {
      width: 100%;
      text-align: left; }
    header.global nav .profile-name {
      font-size: 1.25rem;
      color: #00a664; }
    header.global nav ul {
      padding: 0;
      position: relative;
      background-color: #FFFFFF; }
      header.global nav ul li > a, header.global nav ul li .find-ride, header.global nav ul li .alerts-container {
        display: block;
        color: #333333;
        font-weight: 600;
        padding: 10px 0px; }
      header.global nav ul.top-nav-popup {
        width: 240px;
        margin: 0 auto;
        position: absolute !important;
        z-index: 999;
        right: 20px;
        top: 70px;
        border-radius: 4px;
        border: 1px solid #D5D5D5;
        box-shadow: 0px 1px 0px #D5D5D5;
        visibility: hidden; }
        header.global nav ul.top-nav-popup.active {
          visibility: visible;
          box-shadow: 0 0 5px 0 #4A90E2; }
        header.global nav ul.top-nav-popup:focus {
          box-shadow: 0 0 5px 0 #4A90E2; }
        header.global nav ul.top-nav-popup .top-nav-popup-wrapper {
          position: relative;
          z-index: 998;
          background-color: #fff;
          border-radius: 4px; }
          header.global nav ul.top-nav-popup .top-nav-popup-wrapper .user-image.static {
            margin: 0px 6px; }
        header.global nav ul.top-nav-popup:after {
          content: "";
          height: 100%;
          width: 100%;
          border-radius: 4px;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2); }
        header.global nav ul.top-nav-popup:before {
          content: "";
          width: 20px;
          height: 20px;
          transform: rotate(45deg);
          background: #fff;
          position: absolute;
          z-index: 970; }
        header.global nav ul.top-nav-popup.arrow-top:before {
          right: 20px;
          top: -8px; }
        header.global nav ul.top-nav-popup li {
          padding: 3px 0px;
          display: flex;
          margin-left: 18px;
          align-items: center; }
          header.global nav ul.top-nav-popup li .icon {
            height: 20px;
            margin-right: 5px; }
            header.global nav ul.top-nav-popup li .icon.marketing-link-icon {
              height: 18px; }
          header.global nav ul.top-nav-popup li > a, header.global nav ul.top-nav-popup li .sign-out {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #4A4A4A;
            box-shadow: none; }
            header.global nav ul.top-nav-popup li > a.joinCommute, header.global nav ul.top-nav-popup li .sign-out.joinCommute {
              display: none; }
            header.global nav ul.top-nav-popup li > a:focus, header.global nav ul.top-nav-popup li .sign-out:focus {
              box-shadow: 0 0 5px 0 #4A90E2; }
          header.global nav ul.top-nav-popup li:last-child {
            padding-top: 18px;
            padding-bottom: 18px; }
          header.global nav ul.top-nav-popup li button {
            padding: 0; }
        header.global nav ul.top-nav-popup .line {
          width: 220px;
          margin: auto; }
      header.global nav ul .find-ride .toggle-tip {
        position: fixed;
        z-index: 500;
        background: #FFFFFF;
        text-transform: initial;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        font-size: 0.875rem;
        font-weight: normal;
        padding: 1rem;
        padding-top: 4rem;
        cursor: default;
        animation: from-bottom 0.25s linear forwards; }
        header.global nav ul .find-ride .toggle-tip .head {
          display: none; }
        header.global nav ul .find-ride .toggle-tip .close {
          position: absolute;
          font-size: 2rem;
          right: 0.5rem;
          top: 0; }
        header.global nav ul .find-ride .toggle-tip .no-vanpool {
          font-weight: 600;
          margin: 0 0 1rem;
          font-size: 0.875rem; }
        header.global nav ul .find-ride .toggle-tip .more-info {
          padding-bottom: 1rem; }
        header.global nav ul .find-ride .toggle-tip button {
          width: 60%;
          margin: 1rem 20%; }
        header.global nav ul .find-ride .toggle-tip .request-result {
          font-weight: 600;
          padding-bottom: 1rem; }
          header.global nav ul .find-ride .toggle-tip .request-result span {
            font-weight: 400; }
        header.global nav ul .find-ride .toggle-tip .search {
          font-size: 0.875rem;
          margin: 1rem 0; }
          header.global nav ul .find-ride .toggle-tip .search .result {
            font-size: 1.125rem;
            font-weight: 600;
            color: #049b5c; }
      header.global nav ul.main-nav {
        order: 0;
        list-style: none; }
        header.global nav ul.main-nav li .inner-content {
          margin: 0px 10px;
          padding: 5px 5px; }
        header.global nav ul.main-nav li a {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.25px;
          color: #4A4A4A;
          padding: 0;
          white-space: nowrap;
          box-shadow: none; }
          header.global nav ul.main-nav li a.active {
            color: #0F8952; }
          header.global nav ul.main-nav li a:focus {
            box-shadow: 0 0 0.5rem 0 #4A90E2; }
    header.global nav.active {
      height: 100vh; }
    header.global nav .menu-button, header.global nav .close-button {
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      width: 60px;
      text-align: center;
      position: absolute;
      z-index: 500; }
      header.global nav .menu-button svg, header.global nav .close-button svg {
        font-size: 32px; }
    header.global nav .menu-button svg {
      position: relative;
      top: 13px; }
    header.global nav .menu-button:focus {
      border: solid 1px black; }
    header.global nav .close-button {
      display: none; }
      header.global nav .close-button svg {
        position: relative;
        top: 13px; }
    header.global nav.active .menu-button {
      display: none; }
    header.global nav.active .close-button {
      display: block; }
  header.global .tools {
    display: none; }
  header.global i {
    width: 100px;
    text-align: center;
    vertical-align: middle;
    position: relative; }
  header.global .badge {
    position: relative; }
    header.global .badge:after {
      content: "1";
      position: absolute;
      background: #c01515;
      height: 15px;
      top: 12px;
      right: 7px;
      width: 15px;
      text-align: center;
      line-height: 1rem;
      font-size: 8px;
      border-radius: 50%;
      color: white;
      border-color: #c01515; }
  header.global .fa-user-circle, header.global .fa-car {
    color: #FFFFFF; }
  header.global.active + .main {
    overflow: hidden; }

button#profile-icon {
  box-shadow: none; }
  button#profile-icon:focus {
    box-shadow: 0 0 0.5rem 0 #4A90E2; }

@keyframes from-bottom {
  0% {
    transform: translateY(100vh); }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@media (min-width: 1351px) {
  .side-navigation-only {
    display: none !important; }
  header.global {
    background-color: #FFFFFF; }
    header.global nav {
      height: auto !important;
      overflow: visible; }
      header.global nav .top-bar {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        border-bottom: solid 1px #CCCCCC; }
        header.global nav .top-bar .right-section {
          display: flex !important; }
      header.global nav .menu-button, header.global nav .close-button, header.global nav .transparent-bkrd {
        display: none !important;
        visibility: hidden; }
      header.global nav .h1-heading {
        padding: 7px 3rem 6px 1rem; }
      header.global nav .faded-overlay {
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1; }
      header.global nav .stationary {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        -ms-justify-content: space-around;
        justify-content: space-around;
        -ms-flex-pack: space-around;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        margin-right: 1rem; }
        header.global nav .stationary > a, header.global nav .stationary .find-ride {
          color: #333333;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 600;
          margin-right: 1rem;
          box-shadow: none; }
          header.global nav .stationary > a:focus, header.global nav .stationary .find-ride:focus {
            box-shadow: 0 0 0.5rem 0 #4A90E2; }
        header.global nav .stationary .find-ride {
          padding: 0;
          position: relative;
          cursor: pointer; }
          header.global nav .stationary .find-ride .toggle-tip .head {
            display: none; }
          header.global nav .stationary .find-ride .toggle-tip .no-vanpool {
            font-weight: 600;
            margin: 0 0 1rem;
            font-size: 0.875rem; }
          header.global nav .stationary .find-ride .toggle-tip .more-info {
            padding-bottom: 1rem; }
          header.global nav .stationary .find-ride .toggle-tip button {
            margin-bottom: 1rem;
            float: right;
            padding: 0.5rem 3rem; }
          header.global nav .stationary .find-ride .toggle-tip .request-result {
            font-weight: 600;
            padding-bottom: 1rem; }
            header.global nav .stationary .find-ride .toggle-tip .request-result span {
              font-weight: 400; }
          header.global nav .stationary .find-ride .toggle-tip .search {
            font-size: 0.875rem;
            margin: 1rem 0; }
            header.global nav .stationary .find-ride .toggle-tip .search .result {
              font-size: 1.125rem;
              font-weight: 600;
              color: #049b5c; }
      header.global nav .profile-image {
        height: 63px;
        width: 63px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        cursor: pointer;
        border: none; }
        header.global nav .profile-image .generated-user-image {
          width: 48px;
          height: 48px;
          border-radius: 50%; }
          header.global nav .profile-image .generated-user-image.withImage {
            background-color: #fff; }
          header.global nav .profile-image .generated-user-image img {
            width: 43px;
            height: 43px;
            border-radius: 50%; }
      header.global nav .profile-dropdown {
        display: block;
        position: absolute;
        top: 64px;
        right: -225px;
        width: 200px;
        height: 116px;
        background-color: #FFFFFF;
        z-index: 700;
        font-size: 1rem;
        box-shadow: 1px 1px 17px 1px #CCCCCC;
        transition: all .5s ease; }
        header.global nav .profile-dropdown.active {
          right: 0; }
      header.global nav ul.main-nav {
        width: 100%;
        opacity: 1;
        max-width: 1000px;
        margin: 0 auto;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        border: none; }
        header.global nav ul.main-nav li {
          list-style: none;
          text-align: center;
          font-size: 1.25rem;
          text-transform: capitalize;
          opacity: 1;
          font-weight: 600;
          margin: 15px 25px; }
          header.global nav ul.main-nav li > a {
            cursor: pointer;
            display: inline-block;
            color: "#4A4A4A";
            text-decoration: none;
            position: relative;
            margin: 0;
            padding: 1.2rem 1rem;
            font-size: 18px;
            font-weight: 600;
            text-transform: capitalize;
            font-family: 'DINNextLTPro-Regular'; }
            header.global nav ul.main-nav li > a.active {
              border-color: #FFFFFF;
              background: #D4EDE1;
              border-radius: 4px; }
            header.global nav ul.main-nav li > a::after {
              position: absolute;
              height: 0.25rem;
              width: 0;
              right: 0;
              margin: auto;
              transition: width 0.2s;
              left: 0;
              bottom: -1px;
              content: ' '; }
            header.global nav ul.main-nav li > a input:focus ~ span::after {
              width: 100%; }
            header.global nav ul.main-nav li > a:focus {
              outline: none;
              box-shadow: none; }
            header.global nav ul.main-nav li > a:hover, header.global nav ul.main-nav li > a.active {
              color: #049b5c; }
            header.global nav ul.main-nav li > a:active, header.global nav ul.main-nav li > a.active, header.global nav ul.main-nav li > a:focus, header.global nav ul.main-nav li > a:hover {
              outline: none;
              color: #049b5c; }
              header.global nav ul.main-nav li > a:active::after, header.global nav ul.main-nav li > a.active::after, header.global nav ul.main-nav li > a:focus::after, header.global nav ul.main-nav li > a:hover::after {
                width: 100%; }
            header.global nav ul.main-nav li > a:hover, header.global nav ul.main-nav li > a:not(.active):focus {
              color: #333333; }
      header.global nav .main-nav li.active {
        background: #D4EDE1;
        border-radius: 4px;
        color: #0F8952; } }

@media (min-width: 759px) {
  header.global nav .h1-heading .branding-mobile {
    display: none; }
  header.global nav .h1-heading .branding {
    display: block;
    height: 50px;
    z-index: 200; }
  header.global nav .profile-div {
    margin-right: 20px; } }

@media (max-width: 758px) {
  nav .h1-heading img {
    height: 35px; }
  nav .h1-heading .branding {
    display: none; }
  nav .h1-heading .branding-mobile {
    display: block;
    padding-left: 40px; }
  nav .top-nav-popup {
    right: 5px !important;
    top: 70px;
    width: 80% !important;
    max-width: 240px; }
    nav .top-nav-popup.arrow-top:before {
      right: 20px;
      top: -8px; }
    nav .top-nav-popup li .find-ride, nav .top-nav-popup li .alerts-container {
      display: none; } }

@media (min-width: 759px) and (max-width: 1350px) {
  header.global nav .h1-heading {
    padding: 0 !important; }
    header.global nav .h1-heading .branding {
      padding-left: 40px;
      position: absolute;
      top: 7px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto; } }

@media (max-width: 1350px) {
  .desktop-only {
    display: none !important; }
  .navigation-hr {
    width: 220px;
    margin: auto; }
  header.global {
    border-bottom: 1px solid #D5D5D5; }
    header.global .side-navigation.active .top-bar {
      height: 100%; }
    header.global .side-navigation.active .transparent-bkrd {
      display: block;
      opacity: 0.3;
      background: #130C0E;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      z-index: 0;
      cursor: none;
      transition: opacity .3s; }
    header.global nav .main-nav {
      width: 270px;
      padding-top: 20px !important;
      margin-bottom: 0px; }
      header.global nav .main-nav li {
        margin-bottom: 15px;
        margin-right: 15px; }
        header.global nav .main-nav li.active {
          border-left: 3px solid #0F8952; }
        header.global nav .main-nav li .inner-content {
          display: flex;
          align-items: center; }
          header.global nav .main-nav li .inner-content.active {
            background: #D4EDE1;
            border-radius: 4px;
            color: #0F8952; }
            header.global nav .main-nav li .inner-content.active .icon {
              stroke: #0F8952; }
        header.global nav .main-nav li .icon {
          height: 24px;
          margin-right: 10px;
          margin-left: 8px; }
      header.global nav .main-nav a {
        font-size: 21px;
        line-height: 25px; }
    header.global nav .stationary {
      display: flex;
      flex-direction: column;
      width: 270px;
      height: 100%;
      padding: 24px; }
      header.global nav .stationary a {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #4A4A4A;
        padding: 0;
        color: #4A4A4A; }
    header.global nav .profile-div {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 5px;
      height: 60px; }
      header.global nav .profile-div .alerts-container {
        margin-right: 5px;
        box-shadow: none; }
      header.global nav .profile-div .generated-user-image {
        height: 40px;
        width: 40px; }
        header.global nav .profile-div .generated-user-image.withImage {
          background-color: #fff; }
        header.global nav .profile-div .generated-user-image img {
          width: 38px;
          height: 38px;
          border-radius: 50%; }
    header.global nav .top-nav-popup.active {
      position: fixed !important; } }

@media (min-width: 1024px) {
  header.global nav ul.main-nav li > a {
    font-size: 1.25rem;
    padding: 0.5rem 1rem; } }

@media (max-height: 634px) and (min-height: 450px) and (max-width: 768px) {
  .faded-overlay {
    position: relative; }
    .faded-overlay::before, .faded-overlay::after {
      content: '';
      display: block;
      position: absolute;
      height: 2rem;
      left: 0.5rem;
      width: 100%;
      z-index: 10; }
    .faded-overlay::before {
      top: 0;
      background-image: linear-gradient(#FFFFFF 20%, rgba(255, 255, 255, 0)); }
    .faded-overlay::after {
      bottom: 1px;
      background-image: linear-gradient(rgba(255, 255, 255, 0), #FFFFFF 80%); }
  header.global nav ul.main-nav {
    max-height: calc(100vh - 408px);
    overflow: auto;
    padding: 5% 0; } }

.overlay-navbar {
  position: fixed;
  left: 0;
  top: 0;
  height: 70px;
  width: 100vw;
  background: rgba(255, 255, 255, 0.75);
  z-index: 100; }

@media (min-width: 768px) {
  .overlay-navbar {
    height: 70px; } }

.fancy-input {
  position: relative; }
  .fancy-input input {
    cursor: pointer; }
    .fancy-input input::placeholder {
      color: transparent; }
    .fancy-input input:disabled {
      cursor: not-allowed;
      background: #f2f2f2; }
      .fancy-input input:disabled ~ .label {
        display: none; }
  .fancy-input .label {
    display: block;
    position: absolute;
    color: #484848;
    background: #FFFFFF;
    font-size: 1rem;
    top: -5px;
    left: 7px;
    padding: 3px;
    transition: all linear 0.1s;
    pointer-events: none; }
  .fancy-input input:focus + .label, .fancy-input input[value]:not([value=""]) + .label {
    top: -20px;
    left: 8px;
    font-size: 0.6875rem;
    color: #049b5c; }
  .fancy-input .card-number {
    width: 290px; }
  .fancy-input input[value]:not([value=""]).card-number.invalid {
    border-color: #c01515; }
    .fancy-input input[value]:not([value=""]).card-number.invalid + .label {
      color: #c01515; }

hr {
  display: block;
  height: 0 !important;
  border: 0;
  border-top: 1px solid #CCCCCC;
  margin: 0 auto 0 0;
  padding: 0; }

.slider-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  /*------ ADDED CSS ---------*/
  /*--------- END --------*/
  /* Rounded sliders */ }
  .slider-container h4 {
    font-size: 0.875rem;
    font-weight: 600;
    padding-right: 25px;
    padding-top: 5px; }
  .slider-container .switch {
    position: relative;
    display: inline-block;
    min-width: 56px;
    height: 26px; }
  .slider-container .switch input {
    opacity: 0;
    height: 1px;
    width: 1px; }
  .slider-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e6e6;
    -webkit-transition: .4s;
    transition: .4s; }
  .slider-container .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 0px;
    bottom: 0px;
    background-color: #FFFFFF;
    -webkit-transition: .4s;
    transition: .4s;
    border: solid 1px #049b5c; }
  .slider-container input:disabled + .slider {
    opacity: 0.5;
    cursor: not-allowed; }
  .slider-container input:checked + .slider {
    background-color: #049b5c; }
  .slider-container input:focus + .slider {
    box-shadow: 0 0 0.25rem #4A90E2; }
  .slider-container input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  .slider-container .on {
    display: none; }
  .slider-container .on, .slider-container .off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif; }
  .slider-container input:checked + .slider .on {
    display: block; }
  .slider-container input:checked + .slider .off {
    display: none; }
  .slider-container .slider.round {
    border-radius: 34px; }
  .slider-container .slider.round:before {
    border-radius: 50%; }

.review section {
  padding: 1rem 0; }
  .review section h2 {
    font-weight: 300;
    text-align: center;
    color: gray;
    font-size: 1.875rem;
    margin-bottom: 1rem;
    line-height: 2.5rem; }
    .review section h2 svg {
      font-size: 60px; }
  .review section p {
    text-align: center;
    font-size: 1.125rem;
    color: gray; }

.review .edit {
  color: #049b5c;
  text-align: center;
  width: 10rem;
  margin: 2rem auto 0;
  cursor: pointer; }
  .review .edit svg {
    position: relative;
    top: -2px;
    margin-right: .25rem; }

.review .details {
  font-size: 1.125rem;
  padding: 0 1rem; }
  .review .details .half, .review .details .full {
    width: 100%;
    padding: 0.5rem 0; }
    .review .details .half .label, .review .details .full .label {
      font-size: 0.875rem;
      font-weight: 300; }
    .review .details .half .value, .review .details .full .value {
      font-size: 1rem; }

.review.error-confirmation svg {
  color: #c01515; }

.review .title svg {
  display: block;
  text-align: center;
  width: 100%; }

.new-card .radio {
  cursor: pointer;
  padding-left: 0; }
  .new-card .radio:before {
    content: ' ';
    height: 20px;
    width: 20px;
    border: 2px solid #049b5c;
    border-radius: 20px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 2px; }
  .new-card .radio .save-card {
    display: block;
    pointer-events: none;
    cursor: pointer; }
    .new-card .radio .save-card input {
      height: 1px;
      width: 1px;
      opacity: 0; }
    .new-card .radio .save-card svg {
      box-sizing: content-box;
      color: #049b5c;
      font-size: 1.125rem;
      margin-right: 10px; }
    .new-card .radio .save-card span {
      position: relative; }
    .new-card .radio .save-card span::after {
      position: absolute;
      height: 2px;
      width: 0;
      right: 0;
      margin: auto;
      transition: width 0.3s;
      left: 0;
      bottom: 0;
      content: ' '; }
    .new-card .radio .save-card input:focus ~ span::after {
      width: 100%;
      background: #049b5c; }
  .new-card .radio h3 {
    display: inline-block; }
  .new-card .radio .new-card-form {
    opacity: 0.25;
    position: relative; }
    .new-card .radio .new-card-form .card-type {
      margin-bottom: .5rem; }
    .new-card .radio .new-card-form .select {
      margin: 0 .5rem 1rem 0; }
      .new-card .radio .new-card-form .select select {
        position: relative;
        top: -1px; }
        .new-card .radio .new-card-form .select select:focus {
          outline: 2px solid #049b5c; }
    .new-card .radio .new-card-form .cvc, .new-card .radio .new-card-form .card-icons {
      margin-bottom: .5rem; }
    .new-card .radio .new-card-form .cvc img {
      position: relative;
      left: 10px;
      top: -5px; }
    .new-card .radio .new-card-form .card-valid {
      position: absolute;
      left: 260px;
      top: 10px;
      font-size: 1.25rem;
      transform: scale(0);
      transition: all linear 0.2s;
      background: #049b5c;
      color: #049b5c;
      border-radius: 20px;
      height: 20px;
      width: 20px; }
      .new-card .radio .new-card-form .card-valid svg {
        position: relative;
        top: -7px; }
      .new-card .radio .new-card-form .card-valid.valid {
        transform: scale(1);
        background: transparent; }
    .new-card .radio .new-card-form .card-invalid {
      position: relative;
      display: inline-block;
      left: -285px;
      top: 27px;
      font-size: 12px;
      color: #c01515;
      z-index: 10;
      margin-bottom: 40px;
      width: 0;
      white-space: nowrap; }
    .new-card .radio .new-card-form .card-invalid-icon {
      position: absolute;
      left: 260px;
      top: 12px;
      font-size: 1.125rem;
      color: #c01515;
      z-index: 10; }
    .new-card .radio .new-card-form .card-exp-invalid-icon {
      display: none; }
    .new-card .radio .new-card-form .select {
      display: inline-block; }
    .new-card .radio .new-card-form .card-icons {
      display: inline-block;
      position: relative;
      top: -1px; }
      .new-card .radio .new-card-form .card-icons.has-type .card-icon:not(.entered) img {
        opacity: 0.3; }
      .new-card .radio .new-card-form .card-icons .card-icon {
        display: inline-block;
        margin-right: 10px;
        margin-top: -2px; }
        .new-card .radio .new-card-form .card-icons .card-icon img {
          opacity: 1;
          height: 35px; }
  .new-card .radio.selected {
    cursor: default; }
    .new-card .radio.selected:before {
      height: 1rem;
      width: 1rem;
      margin-right: 1rem;
      background: #049b5c;
      border-color: #FFFFFF;
      box-shadow: 0 0 0 2px #4A90E2; }
    .new-card .radio.selected .new-card-form {
      opacity: 1; }
      .new-card .radio.selected .new-card-form .select select.invalid {
        border-color: #c01515; }
      .new-card .radio.selected .new-card-form .card-exp-invalid-icon {
        display: inline-block;
        position: relative;
        font-size: 1.125rem;
        color: #c01515;
        z-index: 10; }
      .new-card .radio.selected .new-card-form .save-card {
        pointer-events: all; }

@media (min-width: 768px) {
  .info .message {
    left: -10rem; }
  .review section p {
    padding: 0 27%; }
  .review .details .row {
    border-bottom: 1px solid #e6e6e6; }
    .review .details .row .label {
      font-size: 1.125rem;
      font-weight: 300;
      padding: .5rem 2rem 0; }
    .review .details .row .value {
      font-size: 1.5rem;
      padding: 0 2rem .5rem; }
  .review .details .half {
    width: 50%;
    border-bottom: none; }
    .review .details .half:first-child {
      border-right: 1px solid #e6e6e6; }
  .review .details .full {
    width: 100%; }
  .review .title svg {
    display: inline-block;
    position: relative;
    width: 3.75rem;
    top: -4px; } }

.inline-editor {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: relative; }
  .inline-editor svg {
    cursor: pointer;
    margin-left: 0.75rem;
    font-size: 1.25rem; }
  .inline-editor.editing {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .inline-editor input {
    color: #333333;
    text-align: right;
    font-size: 1.25rem;
    padding-right: 0.625rem;
    width: 100%; }
  .inline-editor p {
    margin-bottom: 0; }
  .inline-editor .tools {
    position: absolute;
    top: 1rem;
    right: 0;
    border: solid 1px #CCCCCC;
    border-radius: 0 0 0.75rem 0.75rem;
    background-color: #FFFFFF;
    padding: 5px; }
    .inline-editor .tools.textarea {
      top: 3rem; }
    .inline-editor .tools .button {
      background: transparent;
      border: solid 1px #FFFFFF;
      font-size: 1rem;
      cursor: pointer; }
      .inline-editor .tools .button:focus {
        border: solid 1px #CCCCCC; }
      .inline-editor .tools .button.cancel {
        color: #b3b3b3;
        margin-right: 3px; }
      .inline-editor .tools .button.save {
        margin-left: 3px;
        font-weight: 600;
        color: #049b5c;
        user-select: none; }
        .inline-editor .tools .button.save:disabled {
          color: #f2f2f2;
          pointer-events: none; }

@media (min-width: 768px) {
  .row {
    flex-wrap: nowrap; }
    .row.cards {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  section {
    padding: 0; }
    section h1, section h2, section h3 {
      text-align: left; }
  .tools {
    padding: 0; }
    .tools .button {
      -webkit-flex-grow: 0;
      -moz-flex-grow: 0;
      -ms-flex-grow: 0;
      flex-grow: 0; }
  .summary {
    width: 700px;
    padding: 0; }
    .summary .paperless-invoice {
      text-align: left;
      margin-top: 79px; }
    .summary h2 {
      font-size: 1.875rem;
      font-weight: 400; }
    .summary h2, .summary .note {
      text-align: left; }
  .balance {
    text-align: right; }
  .inline-editor {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start; } }

/* Buttons ----------------
--------------------------- */
button {
  border: none;
  background: transparent; }

a, button {
  color: #049b5c;
  text-decoration: none;
  cursor: pointer; }
  a:hover, button:hover {
    color: #025030; }
  a:focus, button:focus {
    box-shadow: 0 0 5px 0 #4A90E2; }
  a.button .tnavLink, button.button .tnavLink {
    color: black; }
    a.button .tnavLink:focus, button.button .tnavLink:focus {
      box-shadow: 0 0 5px 0 #4A90E2 !important; }
  a.button, button.button {
    display: inline-block;
    font-size: 1.125rem;
    min-width: 7.5rem;
    padding: 0.5rem 1rem;
    border: solid 0.125rem #049b5c;
    color: #049b5c;
    background-color: #FFFFFF;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer; }
    a.button.primary, button.button.primary {
      background-color: #049b5c;
      color: #FFFFFF; }
    a.button.save:disabled, button.button.save:disabled {
      background-color: #CCCCCC;
      color: #FFFFFF;
      border-color: #CCCCCC;
      pointer-events: none; }
    a.button.small, button.button.small {
      font-size: 0.875rem;
      min-width: 3rem;
      padding: 0.25rem 0.75rem;
      border: none; }
    a.button.mini, button.button.mini {
      padding: 0.125rem 0.5rem;
      border: solid 0.125rem #049b5c;
      color: #049b5c;
      background-color: #FFFFFF;
      text-align: center;
      min-width: auto; }
      a.button.mini:hover, button.button.mini:hover {
        color: #FFFFFF;
        background-color: #049b5c; }
    a.button.delete, button.button.delete {
      border-color: #c01515;
      color: #c01515; }
      a.button.delete:disabled, button.button.delete:disabled {
        background-color: #e6e6e6;
        color: #FFFFFF;
        border-color: #e6e6e6;
        cursor: default;
        user-select: none; }
    a.button.cancel, button.button.cancel {
      color: #049b5c; }
  a.pill, button.pill {
    display: inline-block;
    border: solid 1px #FFFFFF;
    background-color: #FFFFFF;
    font-size: 1.25rem;
    min-width: 4rem;
    padding: 0.25rem 1rem;
    color: #049b5c;
    border-radius: 1.25rem;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    cursor: pointer; }
    a.pill.active, button.pill.active {
      background-color: #049b5c;
      color: #FFFFFF;
      border-color: #049b5c; }

.form-buttons {
  padding: 1.25rem 1.25rem 2.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .form-buttons input {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1; }
    .form-buttons input:first-child {
      margin-right: 1rem; }
  .form-buttons input[type=button], .form-buttons input[type=submit] {
    display: inline-block;
    font-size: 1.125rem;
    min-width: 8rem;
    padding: 0.5rem 1rem;
    border: solid 0.125rem #049b5c;
    color: #049b5c;
    background-color: #FFFFFF;
    text-align: center;
    cursor: pointer; }
  .form-buttons input[type=submit] {
    background-color: #049b5c;
    color: #FFFFFF; }
  .form-buttons input:disabled {
    background-color: #CCCCCC;
    border-color: #CCCCCC;
    color: #FFFFFF;
    cursor: default; }

.vanpool-yes-no {
  margin-top: .5rem; }
  .vanpool-yes-no .pill {
    border: solid 1px #049b5c;
    width: auto;
    margin-right: 1rem;
    font-size: 1.125rem; }
    .vanpool-yes-no .pill.selected {
      background-color: #049b5c;
      color: #FFFFFF; }

.days-editor .days {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 0 0.5rem 1rem;
  max-width: 400px; }
  .days-editor .days .day-btn {
    color: #049b5c;
    background-color: #FFFFFF;
    height: 2.6rem;
    width: 2.2rem;
    font-size: 0.75rem;
    text-align: center;
    border-radius: 0.25rem;
    border: solid 1px #05cd7a;
    cursor: pointer;
    margin: 0 3px;
    padding: 0; }
    .days-editor .days .day-btn:disabled {
      color: #484848;
      border: none;
      background: #FFFFFF;
      border: solid 1px #FFFFFF;
      cursor: default; }
    .days-editor .days .day-btn.active {
      color: #FFFFFF;
      border: solid 1px #049b5c;
      background: #049b5c; }
      .days-editor .days .day-btn.active:disabled {
        opacity: 0.25;
        cursor: default; }

@media (min-width: 768px) {
  .days-editor .days .day-btn {
    font-size: 0.875rem;
    width: 2.6rem; }
    .days-editor .days .day-btn[day="Thursday"]:before, .days-editor .days .day-btn[day="Sunday"]:before {
      content: ''; } }

/* Inputs ------------------
---------------------------- */
input[type=text], input[type=password], input[type=number] {
  padding: 0.5rem;
  border: solid 1px #b3b3b3;
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  color: #333333;
  border-radius: 3px; }
  input[type=text]:focus, input[type=password]:focus, input[type=number]:focus {
    outline: none;
    border-color: #049b5c; }

input[type=submit] {
  min-height: 2.5rem; }

input.short {
  width: 5rem; }

input.long {
  width: 100%;
  max-width: 32rem; }

.textarea-editor {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%; }

.editor-wrapper {
  position: relative;
  width: 100%; }
  .editor-wrapper .editor-counter {
    color: #c01515;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background: #FFFFFF;
    z-index: 10; }

.edit-icon, .green {
  color: #049b5c !important; }

@media (min-width: 768px) {
  input.short {
    width: 8rem; } }

/* Radio Buttons --------------
---------------------------- */
.radio {
  position: relative;
  padding-left: 10px;
  margin-bottom: 5px; }
  .radio input[type=radio] {
    visibility: hidden; }
  .radio input[type="radio"] + label {
    user-select: none; }
  .radio input[type="radio"] + label::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border: solid 2px #049b5c;
    border-radius: 25px;
    position: absolute;
    top: 2px;
    left: 0;
    cursor: pointer; }
  .radio input[type="radio"]:checked + label::after {
    content: "";
    display: inline-block;
    background-color: #049b5c;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    top: 6px;
    left: 4px; }

/* Checkboxes --------------
---------------------------- */
.checkbox {
  display: block;
  cursor: pointer; }
  .checkbox.selected {
    pointer-events: all; }
  .checkbox input {
    height: 1px;
    width: 1px;
    opacity: 0; }
  .checkbox svg {
    box-sizing: content-box;
    color: #049b5c;
    font-size: 1.125rem;
    margin-right: 10px; }
  .checkbox span {
    position: relative; }
  .checkbox span::after {
    position: absolute;
    height: 2px;
    width: 0;
    right: 0;
    margin: auto;
    transition: width 0.3s;
    left: 0;
    bottom: 0;
    content: ' '; }
  .checkbox input:focus ~ span::after {
    width: 100%;
    background: #049b5c; }

@media (min-width: 768px) {
  .checkbox {
    padding-left: 17px; } }

.time-picker {
  border: 1px #b3b3b3 solid;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 7rem;
  display: inline-block;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  transition: width 0.2s linear; }
  .time-picker > svg {
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
    color: #CCCCCC; }
  .time-picker.active {
    width: 10rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .time-picker .time-options {
    position: absolute;
    top: 100%;
    left: -1px;
    background: white;
    display: flex;
    flex-direction: row;
    border: 1px #b3b3b3 solid;
    z-index: 20;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    animation: expand-time-options 0.2s linear forwards; }
    .time-picker .time-options .hours, .time-picker .time-options .minutes, .time-picker .time-options .am-pm {
      flex: 1;
      overflow: auto;
      border-right: 1px #e6e6e6 solid; }
      .time-picker .time-options .hours .hour, .time-picker .time-options .hours .minute, .time-picker .time-options .hours .pm, .time-picker .time-options .hours .am, .time-picker .time-options .minutes .hour, .time-picker .time-options .minutes .minute, .time-picker .time-options .minutes .pm, .time-picker .time-options .minutes .am, .time-picker .time-options .am-pm .hour, .time-picker .time-options .am-pm .minute, .time-picker .time-options .am-pm .pm, .time-picker .time-options .am-pm .am {
        padding: 0.25rem;
        text-align: center;
        position: relative; }
        .time-picker .time-options .hours .hour.active, .time-picker .time-options .hours .minute.active, .time-picker .time-options .hours .pm.active, .time-picker .time-options .hours .am.active, .time-picker .time-options .minutes .hour.active, .time-picker .time-options .minutes .minute.active, .time-picker .time-options .minutes .pm.active, .time-picker .time-options .minutes .am.active, .time-picker .time-options .am-pm .hour.active, .time-picker .time-options .am-pm .minute.active, .time-picker .time-options .am-pm .pm.active, .time-picker .time-options .am-pm .am.active {
          background: #e6e6e6;
          font-weight: 600; }
        .time-picker .time-options .hours .hour:focus, .time-picker .time-options .hours .minute:focus, .time-picker .time-options .hours .pm:focus, .time-picker .time-options .hours .am:focus, .time-picker .time-options .minutes .hour:focus, .time-picker .time-options .minutes .minute:focus, .time-picker .time-options .minutes .pm:focus, .time-picker .time-options .minutes .am:focus, .time-picker .time-options .am-pm .hour:focus, .time-picker .time-options .am-pm .minute:focus, .time-picker .time-options .am-pm .pm:focus, .time-picker .time-options .am-pm .am:focus {
          box-shadow: none; }
          .time-picker .time-options .hours .hour:focus::before, .time-picker .time-options .hours .minute:focus::before, .time-picker .time-options .hours .pm:focus::before, .time-picker .time-options .hours .am:focus::before, .time-picker .time-options .minutes .hour:focus::before, .time-picker .time-options .minutes .minute:focus::before, .time-picker .time-options .minutes .pm:focus::before, .time-picker .time-options .minutes .am:focus::before, .time-picker .time-options .am-pm .hour:focus::before, .time-picker .time-options .am-pm .minute:focus::before, .time-picker .time-options .am-pm .pm:focus::before, .time-picker .time-options .am-pm .am:focus::before {
            content: ' ';
            position: absolute;
            border: 0.25rem transparent solid;
            border-left: 0.25rem #333333 solid;
            left: 0;
            top: 0.75rem; }
    .time-picker .time-options .cancel {
      position: absolute;
      right: 0.5rem;
      top: -1.8rem;
      border-radius: 1rem;
      height: 1rem;
      width: 1rem;
      padding: 0; }
      .time-picker .time-options .cancel svg {
        margin-top: -0.75rem; }

@keyframes expand-time-options {
  from {
    width: 7rem;
    height: 0; }
  to {
    width: 10rem;
    height: 10rem; } }

.phone .select-switch {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .phone .select-switch .select {
    margin-right: .25rem;
    width: 6rem; }
  .phone .select-switch input[type=text].number {
    width: 8rem; }

.phone .Work input[type=text].ext {
  width: 4rem;
  margin-left: .25rem; }

.phone .Work input[type=text].number {
  width: 8rem; }

.phone .checkbox {
  margin-left: .25rem;
  margin-bottom: .5rem; }

.addresses {
  width: calc(100vw - 1rem);
  max-width: 25rem;
  margin-bottom: 1rem; }
  .addresses svg {
    position: relative;
    top: -1px; }
  .addresses .home button, .addresses .mailing-address button {
    color: #049b5c; }
    .addresses .home button svg, .addresses .mailing-address button svg {
      position: relative;
      top: -2px; }
  .addresses .home .address .invalid-address, .addresses .mailing-address .address .invalid-address {
    color: red;
    font-size: 14px;
    position: relative;
    top: -8px;
    padding-left: 5px; }
  .addresses .home .address .invalid-address-auto, .addresses .mailing-address .address .invalid-address-auto {
    border: 1px solid red; }
  .addresses .home .address .street-number, .addresses .mailing-address .address .street-number {
    width: 100%; }
  .addresses .home .address .apt, .addresses .mailing-address .address .apt {
    width: 80%; }
  .addresses .home .address .region, .addresses .mailing-address .address .region {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .addresses .home .address .region .select, .addresses .mailing-address .address .region .select {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      margin: 0 .25rem; }
      .addresses .home .address .region .select label, .addresses .mailing-address .address .region .select label {
        font-size: 1rem; }
    .addresses .home .address .region .validationClass, .addresses .mailing-address .address .region .validationClass {
      max-width: 33%; }
    .addresses .home .address .region .validationClass .city, .addresses .home .address .region .validationClass .zip, .addresses .mailing-address .address .region .validationClass .city, .addresses .mailing-address .address .region .validationClass .zip {
      max-width: 100%; }
      .addresses .home .address .region .validationClass .city.invalid:not(:focus), .addresses .home .address .region .validationClass .zip.invalid:not(:focus), .addresses .mailing-address .address .region .validationClass .city.invalid:not(:focus), .addresses .mailing-address .address .region .validationClass .zip.invalid:not(:focus) {
        border-color: #c01515; }
  .addresses .home .address .fields-invalid, .addresses .mailing-address .address .fields-invalid {
    position: relative;
    display: block;
    left: 0.5rem;
    top: -0.5rem;
    font-size: 0.75rem;
    color: #c01515;
    height: 0; }
  .addresses .home .address .streetInvalid, .addresses .mailing-address .address .streetInvalid {
    height: 15px; }
  .addresses .home .diff-mail, .addresses .mailing-address .diff-mail {
    margin-top: 1rem;
    font-size: 1.125rem; }
  .addresses .optional {
    margin: 1rem 0 .5rem 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .addresses .optional h3 {
      margin: 0;
      padding: 0; }
    .addresses .optional .close {
      position: relative;
      top: 3px;
      font-size: 20px;
      cursor: pointer; }

@media (min-width: 768px) {
  .addresses, .phone {
    margin-left: 0;
    width: calc(100vw - 27rem); }
  .phone {
    width: calc(100vw - 1rem);
    max-width: 25rem; } }

.ant-time-picker-panel-select:not(:first-child) {
  height: 12rem; }
  .ant-time-picker-panel-select:not(:first-child) ul {
    padding: 0; }

.ant-select-selection {
  border-color: #b3b3b3 !important;
  border-radius: 0 !important;
  padding: .25rem 0 .5rem !important; }

.react-tagsinput {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  overflow: hidden;
  padding-left: 0.25rem;
  padding-top: 0.25rem; }

.react-tagsinput--focused {
  border-color: #999999; }

.react-tagsinput-remove {
  color: gray; }

.react-tagsinput-tag {
  background-color: #FFFFFF;
  border-radius: 1rem;
  border: 1px solid gray;
  color: gray;
  display: inline-block;
  font-family: sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  padding: 0.25rem 0.75rem; }

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold; }

.react-tagsinput-tag a::before {
  content: " ×"; }

input.react-tagsinput-input {
  background: transparent;
  border: 0 !important;
  color: #484848;
  font-family: sans-serif;
  font-size: 0.875rem !important;
  font-weight: 400;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  outline: none;
  padding: 0.5rem;
  width: auto; }

/* Select ------------------
---------------------------- */
.select {
  position: relative;
  height: 42px;
  background: white; }
  .select select {
    border: none;
    box-shadow: none;
    border-radius: 3px;
    background: transparent;
    height: 100%;
    width: 100%;
    cursor: pointer;
    outline: none;
    padding-right: 2rem;
    padding-left: 0.5rem;
    border: 1px solid #b3b3b3;
    appearance: none; }
    .select select::-ms-expand {
      display: none; }
  .select:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    right: 0.75rem;
    bottom: 0;
    margin: auto;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #049b5c transparent transparent transparent;
    pointer-events: none; }

/* Switch ------------------
---------------------------- */
.switch a {
  display: inline-block;
  font-size: .8rem;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  width: 3.75rem;
  border: solid 1px #049b5c;
  cursor: pointer;
  text-decoration: none; }
  .switch a.active {
    background-color: #049b5c;
    color: #FFFFFF;
    cursor: default; }

/* Tabs -----------------------
---------------------------- */
.tabs {
  position: relative;
  border-bottom: solid 1px #CCCCCC; }
  .tabs.disabled {
    pointer-events: none;
    cursor: default; }
    .tabs.disabled .tab {
      color: #e6e6e6; }
      .tabs.disabled .tab.active {
        color: #e1f0ea; }
    .tabs.disabled hr {
      border-color: #e1f0ea; }
  .tabs .tab {
    display: inline-block;
    color: gray;
    text-align: center;
    font-size: 1.125rem;
    padding: 0.5rem 0 0;
    width: 7rem;
    cursor: pointer; }
    .tabs .tab.active {
      color: #049b5c;
      box-shadow: none; }
  .tabs hr {
    display: block;
    height: 0;
    width: 7rem;
    border: 0;
    border-top: 0.1875rem solid #049b5c;
    margin: 0 auto 0 0;
    padding: 0;
    transition: margin .2s ease-out; }

.tohome.active ~ hr {
  margin: 0 auto 0 7rem; }

/* E-Table -----------------
---------------------------- */
.etable-body.header-labels {
  display: none; }

.etable {
  width: 100%; }
  .etable .etable-row {
    border-bottom: solid 2px #CCCCCC; }
    .etable .etable-row .etable-cell {
      color: #484848;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: .5px; }
    .etable .etable-row:last-child {
      border-bottom: none; }
    .etable .etable-row:first-child {
      border-bottom: solid 2px #CCCCCC; }
  .etable .etable-header {
    font-size: .6875rem;
    color: gray;
    padding-right: 3em; }
    .etable .etable-header.wexheader {
      padding: 15px 0;
      font-size: 14px; }
    .etable .etable-header.description {
      flex-grow: 1;
      padding-right: 0;
      text-align: center; }
    .etable .etable-header.right {
      padding-right: 0; }
    .etable .etable-header.service-month {
      flex: 0 0 110px; }
    .etable .etable-header.invoice-date {
      flex: 0 0 100px;
      padding-right: 0;
      text-align: center; }
    .etable .etable-header.activity-date {
      flex: 0 0 140px;
      text-align: center;
      padding-right: 0; }
    .etable .etable-header.wexSelectAll {
      flex: 0 0 12%;
      text-align: start; }
    .etable .etable-header.activityDate {
      flex: 0 0 28%; }
    .etable .etable-header.vendorName {
      flex: 0 0 28%; }
    .etable .etable-header.gallons {
      flex: 0 0 25%; }
    .etable .etable-header.expenses {
      flex: 0 0 22%; }
  .etable .etable-heading.payments .etable-head {
    font-weight: 400 !important; }
  .etable .etable-heading.payments .etable-row {
    background-color: #f2f2f2;
    border: none;
    padding: 0.4rem 1rem 0.4rem 2rem; }
  .etable .etable-heading.current-charges .etable-row, .etable .etable-heading.recent-payments .etable-row {
    border-color: #FFFFFF;
    transition: border-color .3s ease; }
  .etable .etable-heading .etable-row.invoice-balance {
    background-color: #f2f2f2;
    border-top: solid 0.1875rem #03693e;
    cursor: default;
    padding: 0.75rem 1rem; }
  .etable .etable-heading .etable-row .etable-head {
    font-weight: 700; }
    .etable .etable-heading .etable-row .etable-head .total {
      transition: color .3s ease; }
  .etable .etable-heading.toggle {
    position: relative;
    cursor: pointer; }
    .etable .etable-heading.toggle.monthly-charges .etable-row .etable-column {
      justify-content: space-between; }
      .etable .etable-heading.toggle.monthly-charges .etable-row .etable-column .total {
        padding-right: 22px;
        display: none;
        font-weight: 600; }
    .etable .etable-heading.toggle .etable-row {
      background-color: #f2f2f2; }
      .etable .etable-heading.toggle .etable-row .etable-column .etable-head {
        padding: 0rem 0rem 0rem 1.5rem; }
        .etable .etable-heading.toggle .etable-row .etable-column .etable-head.total {
          padding-right: 0rem; }
        .etable .etable-heading.toggle .etable-row .etable-column .etable-head .angle-down-button {
          margin-right: 0.5rem;
          transition: transform .2s ease;
          position: absolute;
          top: 12px;
          left: 1rem;
          color: #049b5c;
          transform: rotate(-90deg);
          font-size: 1.5rem; }
  .etable .is-open .etable-heading.current-charges .etable-row, .etable .is-open .etable-heading.recent-payments .etable-row {
    border-color: #CCCCCC; }
  .etable .is-open .etable-heading.toggle .etable-row .etable-column .etable-head svg {
    transform: rotate(0deg); }
  .etable .is-open .etable-heading.toggle .etable-row .etable-head .total {
    color: #FFFFFF; }
  .etable .etable-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    align-items: center;
    padding: 0.6rem 1rem 0.6rem 1.5rem; }
    .etable .etable-row .etable-column {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1; }
      .etable .etable-row .etable-column .etable-cell {
        overflow: hidden;
        color: #484848; }
        .etable .etable-row .etable-column .etable-cell:last-child {
          padding-right: 0.5rem; }
    .etable .etable-row.subtotal {
      padding: 10px 15px; }
      .etable .etable-row.subtotal .etable-cell {
        font-weight: 700;
        color: #333333; }
        .etable .etable-row.subtotal .etable-cell:first-child {
          width: 70%; }
        .etable .etable-row.subtotal .etable-cell:last-child {
          width: 30%; }
    .etable .etable-row .month {
      font-style: italic;
      color: #b3b3b3;
      display: none; }
  .etable .etable-footer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    border-top: solid 3px #03693e;
    background-color: #CCCCCC; }
    .etable .etable-footer.requested-payments-footer {
      background-color: #049b5c; }
    .etable .etable-footer .etable-row {
      padding: 10px 15px 10px 10px; }
    .etable .etable-footer .etable-foot {
      font-weight: 700;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      color: #FFFFFF; }
      .etable .etable-footer .etable-foot:first-child {
        font-weight: 400; }
      .etable .etable-footer .etable-foot:last-child {
        font-weight: 400; }

@media (min-width: 768px) {
  .etable-body.header-labels {
    display: block; }
  .etable .etable-row .etable-cell .mobile-label {
    display: none; }
  .etable .etable-row .etable-column {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  .etable .etable-heading.toggle.monthly-charges .etable-row .etable-column .total, .etable .etable-heading.toggle.monthly-charges .etable-row .etable-column .current-month {
    display: block;
    font-size: 1.125rem; }
  .etable .etable-heading .etable-row.invoice-balance {
    padding: 10px 15px 10px 25px; }
  .etable .etable-body .etable-row .etable-column .etable-cell {
    -webkit-box-flex: 0 0 230px;
    -moz-box-flex: 0 0 230px;
    -webkit-flex: 0 0 230px;
    -ms-flex: 0 0 230px;
    flex: 0 0 230px; }
    .etable .etable-body .etable-row .etable-column .etable-cell.not-yet-invoiced {
      text-align: right; }
    .etable .etable-body .etable-row .etable-column .etable-cell:last-child {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1; }
  .etable .etable-body.monthly-charges-table .etable-row .etable-column .etable-cell {
    font-size: 15px;
    line-height: 2.5;
    align-self: center;
    text-align: center;
    -webkit-box-flex: 0 0 140px;
    -moz-box-flex: 0 0 140px;
    -webkit-flex: 0 0 140px;
    -ms-flex: 0 0 140px;
    flex: 0 0 140px; }
    .etable .etable-body.monthly-charges-table .etable-row .etable-column .etable-cell.spacer {
      -webkit-box-flex: 0 0 110px;
      -moz-box-flex: 0 0 110px;
      -webkit-flex: 0 0 110px;
      -ms-flex: 0 0 110px;
      flex: 0 0 110px; }
    .etable .etable-body.monthly-charges-table .etable-row .etable-column .etable-cell.not-yet-invoiced {
      -webkit-box-flex: 0 0 100px;
      -moz-box-flex: 0 0 100px;
      -webkit-flex: 0 0 100px;
      -ms-flex: 0 0 100px;
      flex: 0 0 100px; }
    .etable .etable-body.monthly-charges-table .etable-row .etable-column .etable-cell.description {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      text-align: left;
      padding-left: 4rem; }
    .etable .etable-body.monthly-charges-table .etable-row .etable-column .etable-cell.amount {
      -webkit-box-flex: 0 0 80px;
      -moz-box-flex: 0 0 80px;
      -webkit-flex: 0 0 80px;
      -ms-flex: 0 0 80px;
      flex: 0 0 80px;
      text-align: right; }
  .etable .etable-body.wex-transactions-table .etable-row {
    padding: 15px 15px 15px 25px; }
    .etable .etable-body.wex-transactions-table .etable-row .etable-column .etable-cell {
      flex: 0 0 28%; }
      .etable .etable-body.wex-transactions-table .etable-row .etable-column .etable-cell.gallons {
        flex: 0 0 25%; }
      .etable .etable-body.wex-transactions-table .etable-row .etable-column .etable-cell.wexSelect {
        flex: 0 0 12%; }
      .etable .etable-body.wex-transactions-table .etable-row .etable-column .etable-cell .mobile-only-label {
        justify-content: flex-start; }
  .etable .etable-footer .etable-row {
    padding: 10px 15px 10px 25px; }
  .etable .etable-cat .etable-row .etable-column {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  .etable .etable-cat .etable-body .etable-row .etable-column .etable-cell {
    -webkit-box-flex: 0 0 230px;
    -moz-box-flex: 0 0 230px;
    -webkit-flex: 0 0 230px;
    -ms-flex: 0 0 230px;
    flex: 0 0 230px; }
    .etable .etable-cat .etable-body .etable-row .etable-column .etable-cell:last-child {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1; }
  .etable .etable-cat .etable-heading .etable-row .etable-column .etable-head {
    -webkit-box-flex: 0 0 230px;
    -moz-box-flex: 0 0 230px;
    -webkit-flex: 0 0 230px;
    -ms-flex: 0 0 230px;
    flex: 0 0 230px; }
  .etable .etable-cat .etable-heading .etable-row .etable-column .month {
    display: block; } }

/* Cards -------------------
---------------------------- */
.card {
  width: 100%;
  padding: 0 0 3% 0; }
  .card .inner {
    cursor: pointer;
    border: solid 2px #CCCCCC;
    padding: 3%; }
    .card .inner .row {
      -webkit-flex-wrap: nowrap;
      -moz-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
      .card .inner .row .info {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1; }
        .card .inner .row .info .row {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          -webkit-flex-wrap: nowrap;
          -moz-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          color: #b3b3b3; }
      .card .inner .row .img {
        width: 25%;
        background-color: #e6e6e6;
        min-height: 52px;
        margin-right: 3%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center; }
        .card .inner .row .img svg {
          margin: 0 auto;
          font-size: 2rem; }
      .card .inner .row h4 {
        margin: 0;
        padding: 0;
        font-weight: 300;
        font-size: 1.25rem;
        color: inherit;
        user-select: none; }
  .card.add-new .inner {
    border-style: dashed; }
    .card.add-new .inner .img {
      background-color: #FFFFFF; }
      .card.add-new .inner .img svg {
        color: #e6e6e6; }
    .card.add-new .inner .info {
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      color: #e6e6e6; }
  .card.active .inner {
    border: solid 2px #049b5c;
    border-color: #049b5c;
    background-color: #f2f2f2; }
    .card.active .inner .row .img {
      background-color: #CCCCCC; }
    .card.active .inner .row .info h4 {
      color: #049b5c; }
    .card.active .inner .row .info .row {
      color: gray; }

@media (min-width: 768px) {
  .card {
    width: 33%;
    padding: 0 3% 2% 0; } }

.overlay {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 99; }
  .overlay.absolute {
    position: absolute; }

.loader {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  margin: 3.75rem auto;
  position: relative;
  border: 0.5rem solid rgba(0, 0, 0, 0.25);
  border-left: 0.5rem solid #333333;
  transform: translateZ(0);
  animation: load 0.75s infinite linear; }
  .loader .hide {
    opacity: 0; }

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.qnav {
  margin: 2rem 0;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  border: solid 1px #049b5c;
  border-radius: 6px;
  height: 2.4rem; }
  .qnav a {
    display: inline-block;
    width: 50%;
    max-width: 15rem;
    text-align: center;
    color: #049b5c;
    padding: 0.25rem; }
    .qnav a#vanpool-aggregate-link {
      border-radius: 5px 0 0 5px; }
      .qnav a#vanpool-aggregate-link svg {
        font-size: 1.75rem; }
    .qnav a#recorded-trips-aggregate-link {
      border-radius: 0 5px 5px 0; }
      .qnav a#recorded-trips-aggregate-link svg {
        font-size: 1.75rem; }
    .qnav a.active {
      color: #FFFFFF;
      background: #049b5c; }
    .qnav a:focus {
      box-shadow: none;
      -webkit-box-shadow: none; }

@media (min-width: 768px) {
  .qnav {
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 600;
    border: none;
    border-bottom: solid 1px #CCCCCC;
    border-radius: 0;
    height: inherit; }
    .qnav a {
      padding-bottom: 0.5rem;
      display: inline-block;
      width: 50%;
      max-width: 15rem;
      text-align: center;
      color: #333333; }
      .qnav a#vanpool-aggregate-link {
        border-radius: 0; }
      .qnav a#recorded-trips-aggregate-link {
        border-radius: 0; }
      .qnav a.active {
        color: #049b5c;
        border-bottom: solid 0.25rem #049b5c;
        background: inherit; }
      .qnav a:focus {
        box-shadow: none;
        -webkit-box-shadow: none; } }

.tnav-tray {
  width: 100%;
  background-color: #FFFFFF; }
  .tnav-tray .tnav {
    margin: 60px auto 0 auto;
    max-width: 1000px; }
    .tnav-tray .tnav ul {
      width: 100%;
      margin-bottom: 0;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .tnav-tray .tnav ul li {
        margin: 0 1rem 0;
        padding: .5rem 0;
        padding-bottom: 0; }
        .tnav-tray .tnav ul li > a {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          margin: 0;
          font-size: 0.875rem;
          min-width: auto;
          color: '#838383';
          background: #FFFFFF;
          border: none;
          padding: 0.7rem 1rem;
          position: relative; }
          .tnav-tray .tnav ul li > a:focus {
            box-shadow: none; }
          .tnav-tray .tnav ul li > a::after {
            position: absolute;
            height: 0.3rem;
            width: 0;
            right: 0;
            margin: auto;
            transition: width 0.2s;
            left: 0;
            bottom: -1px;
            content: ' '; }
          .tnav-tray .tnav ul li > a.tnavLink {
            color: #838383; }
            .tnav-tray .tnav ul li > a.tnavLink:focus {
              box-shadow: 0 0 5px 0 #4A90E2 !important; }
          .tnav-tray .tnav ul li > a span {
            display: none; }
          .tnav-tray .tnav ul li > a.active {
            color: black; }
            .tnav-tray .tnav ul li > a.active::after {
              width: 85%;
              background: #049b5c; }

@media (min-width: 1350px) {
  .tnav-tray {
    position: fixed;
    background-color: #FFFFFF;
    z-index: 90;
    animation-name: dropdown;
    animation-duration: .75s; }
    .tnav-tray .tnav {
      margin: calc(70px + 0.25rem) auto 0 auto !important; }
      .tnav-tray .tnav ul {
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
        -ms-flex-pack: flex-start; }
        .tnav-tray .tnav ul li .button {
          font-size: 1rem; }
          .tnav-tray .tnav ul li .button span {
            display: inline-block; } }

@media (min-width: 1111) {
  .tnav-tray .tnav ul {
    margin-left: -40px; } }

@keyframes dropdown {
  from {
    top: -67px; }
  to {
    top: 0; } }

@media (max-width: 1350px) {
  .tnav-tray .tnav ul {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; } }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable at-rule-no-unknown */
input::-ms-clear,
input::-ms-reveal {
  display: none; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

p {
  margin-top: 0;
  margin-bottom: 1em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit; }

input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: none; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5em;
  margin-left: 0; }

blockquote {
  margin: 0 0 1em; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

pre,
code,
kbd,
samp {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto; }

figure {
  margin: 0 0 1em; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: .75em;
  padding-bottom: .3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5em;
  font-size: 1.5em;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

mark {
  padding: .2em;
  background-color: #feffe6; }

.clearfix {
  zoom: 1; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0; }

@font-face {
  font-family: 'anticon';
  src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot");
  /* IE9*/
  src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff") format("woff"), url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.ttf") format("truetype"), url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.svg#iconfont") format("svg"); }

.anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.anticon:before {
  display: block;
  font-family: "anticon" !important; }

.anticon-clock-circle:before {
  content: "\e640"; }

.anticon-clock-circle-o:before {
  content: "\e641"; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.ant-btn {
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9; }

.ant-btn > .anticon {
  line-height: 1; }

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0; }

.ant-btn:not([disabled]):hover {
  text-decoration: none; }

.ant-btn:not([disabled]):active {
  outline: 0;
  -webkit-transition: none;
  transition: none; }

.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed; }

.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none; }

.ant-btn-lg {
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
  height: 40px; }

.ant-btn-sm {
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  height: 24px; }

.ant-btn > a:only-child {
  color: currentColor; }

.ant-btn > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn:hover,
.ant-btn:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff; }

.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor; }

.ant-btn:hover > a:only-child:after,
.ant-btn:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn:active,
.ant-btn.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9; }

.ant-btn:active > a:only-child,
.ant-btn.active > a:only-child {
  color: currentColor; }

.ant-btn:active > a:only-child:after,
.ant-btn.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-btn.disabled > a:only-child,
.ant-btn[disabled] > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn[disabled].active > a:only-child {
  color: currentColor; }

.ant-btn.disabled > a:only-child:after,
.ant-btn[disabled] > a:only-child:after,
.ant-btn.disabled:hover > a:only-child:after,
.ant-btn[disabled]:hover > a:only-child:after,
.ant-btn.disabled:focus > a:only-child:after,
.ant-btn[disabled]:focus > a:only-child:after,
.ant-btn.disabled:active > a:only-child:after,
.ant-btn[disabled]:active > a:only-child:after,
.ant-btn.disabled.active > a:only-child:after,
.ant-btn[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  background: #fff; }

.ant-btn > i,
.ant-btn > span {
  pointer-events: none; }

.ant-btn-primary {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff; }

.ant-btn-primary > a:only-child {
  color: currentColor; }

.ant-btn-primary > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff; }

.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor; }

.ant-btn-primary:hover > a:only-child:after,
.ant-btn-primary:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9; }

.ant-btn-primary:active > a:only-child,
.ant-btn-primary.active > a:only-child {
  color: currentColor; }

.ant-btn-primary:active > a:only-child:after,
.ant-btn-primary.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor; }

.ant-btn-primary.disabled > a:only-child:after,
.ant-btn-primary[disabled] > a:only-child:after,
.ant-btn-primary.disabled:hover > a:only-child:after,
.ant-btn-primary[disabled]:hover > a:only-child:after,
.ant-btn-primary.disabled:focus > a:only-child:after,
.ant-btn-primary[disabled]:focus > a:only-child:after,
.ant-btn-primary.disabled:active > a:only-child:after,
.ant-btn-primary[disabled]:active > a:only-child:after,
.ant-btn-primary.disabled.active > a:only-child:after,
.ant-btn-primary[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff; }

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9; }

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff; }

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9; }

.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff; }

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9; }

.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: #d9d9d9; }

.ant-btn-ghost > a:only-child {
  color: currentColor; }

.ant-btn-ghost > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: #40a9ff; }

.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor; }

.ant-btn-ghost:hover > a:only-child:after,
.ant-btn-ghost:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9; }

.ant-btn-ghost:active > a:only-child,
.ant-btn-ghost.active > a:only-child {
  color: currentColor; }

.ant-btn-ghost:active > a:only-child:after,
.ant-btn-ghost.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor; }

.ant-btn-ghost.disabled > a:only-child:after,
.ant-btn-ghost[disabled] > a:only-child:after,
.ant-btn-ghost.disabled:hover > a:only-child:after,
.ant-btn-ghost[disabled]:hover > a:only-child:after,
.ant-btn-ghost.disabled:focus > a:only-child:after,
.ant-btn-ghost[disabled]:focus > a:only-child:after,
.ant-btn-ghost.disabled:active > a:only-child:after,
.ant-btn-ghost[disabled]:active > a:only-child:after,
.ant-btn-ghost.disabled.active > a:only-child:after,
.ant-btn-ghost[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: dashed; }

.ant-btn-dashed > a:only-child {
  color: currentColor; }

.ant-btn-dashed > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff; }

.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor; }

.ant-btn-dashed:hover > a:only-child:after,
.ant-btn-dashed:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9; }

.ant-btn-dashed:active > a:only-child,
.ant-btn-dashed.active > a:only-child {
  color: currentColor; }

.ant-btn-dashed:active > a:only-child:after,
.ant-btn-dashed.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor; }

.ant-btn-dashed.disabled > a:only-child:after,
.ant-btn-dashed[disabled] > a:only-child:after,
.ant-btn-dashed.disabled:hover > a:only-child:after,
.ant-btn-dashed[disabled]:hover > a:only-child:after,
.ant-btn-dashed.disabled:focus > a:only-child:after,
.ant-btn-dashed[disabled]:focus > a:only-child:after,
.ant-btn-dashed.disabled:active > a:only-child:after,
.ant-btn-dashed[disabled]:active > a:only-child:after,
.ant-btn-dashed.disabled.active > a:only-child:after,
.ant-btn-dashed[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-danger {
  color: #f5222d;
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-btn-danger > a:only-child {
  color: currentColor; }

.ant-btn-danger > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f; }

.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor; }

.ant-btn-danger:hover > a:only-child:after,
.ant-btn-danger:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-danger:active,
.ant-btn-danger.active {
  color: #fff;
  background-color: #cf1322;
  border-color: #cf1322; }

.ant-btn-danger:active > a:only-child,
.ant-btn-danger.active > a:only-child {
  color: currentColor; }

.ant-btn-danger:active > a:only-child:after,
.ant-btn-danger.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor; }

.ant-btn-danger.disabled > a:only-child:after,
.ant-btn-danger[disabled] > a:only-child:after,
.ant-btn-danger.disabled:hover > a:only-child:after,
.ant-btn-danger[disabled]:hover > a:only-child:after,
.ant-btn-danger.disabled:focus > a:only-child:after,
.ant-btn-danger[disabled]:focus > a:only-child:after,
.ant-btn-danger.disabled:active > a:only-child:after,
.ant-btn-danger[disabled]:active > a:only-child:after,
.ant-btn-danger.disabled.active > a:only-child:after,
.ant-btn-danger[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-circle,
.ant-btn-circle-outline {
  width: 32px;
  padding: 0;
  font-size: 16px;
  border-radius: 50%;
  height: 32px; }

.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  width: 40px;
  padding: 0;
  font-size: 18px;
  border-radius: 50%;
  height: 40px; }

.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  width: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 50%;
  height: 24px; }

.ant-btn:before {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: #fff;
  opacity: 0.35;
  content: '';
  border-radius: inherit;
  z-index: 1;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  pointer-events: none;
  display: none; }

.ant-btn .anticon {
  -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-btn.ant-btn-loading:before {
  display: block; }

.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
  pointer-events: none;
  position: relative; }

.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -14px; }

.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px; }

.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px; }

.ant-btn-group {
  position: relative;
  display: inline-block; }

.ant-btn-group > .ant-btn {
  position: relative; }

.ant-btn-group > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > .ant-btn.active {
  z-index: 2; }

.ant-btn-group > .ant-btn:disabled {
  z-index: 0; }

.ant-btn-group-lg > .ant-btn {
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
  height: 40px; }

.ant-btn-group-sm > .ant-btn {
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  height: 24px; }

.ant-btn-group-sm > .ant-btn > .anticon {
  font-size: 14px; }

.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px; }

.ant-btn-group .ant-btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0; }

.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.ant-btn-group > .ant-btn-group {
  float: left; }

.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0; }

.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 8px; }

.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 8px; }

.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-left: 8px;
  padding-right: 8px; }

.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative; }

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px; }

.ant-btn-clicked:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-radius: inherit;
  border: 0 solid #1890ff;
  opacity: 0.4;
  -webkit-animation: buttonEffect .4s;
  animation: buttonEffect .4s;
  display: block; }

.ant-btn-danger.ant-btn-clicked:after {
  border-color: #f5222d; }

.ant-btn-background-ghost {
  background: transparent !important;
  border-color: #fff;
  color: #fff; }

.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  background-color: transparent;
  border-color: #1890ff; }

.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: #40a9ff; }

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9; }

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-background-ghost.ant-btn-danger {
  color: #f5222d;
  background-color: transparent;
  border-color: #f5222d; }

.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff4d4f;
  background-color: transparent;
  border-color: #ff4d4f; }

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: #cf1322;
  background-color: transparent;
  border-color: #cf1322; }

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9; }

.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor; }

.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent; }

.ant-btn-two-chinese-chars:first-letter {
  letter-spacing: .34em; }

.ant-btn-two-chinese-chars > * {
  letter-spacing: .34em;
  margin-right: -0.34em; }

@-webkit-keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px; } }

@keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px; } }

a.ant-btn {
  line-height: 30px; }

a.ant-btn-lg {
  line-height: 38px; }

a.ant-btn-sm {
  line-height: 22px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.ant-input {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s; }

.ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1; }

.ant-input:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-input::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-input:hover {
  border-color: #40a9ff; }

.ant-input:focus {
  border-color: #40a9ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25); }

.ant-input-disabled:hover {
  border-color: #e6d8d8; }

textarea.ant-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  -webkit-transition: all .3s, height 0s;
  transition: all .3s, height 0s;
  min-height: 32px; }

.ant-input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px; }

.ant-input-sm {
  padding: 1px 7px;
  height: 24px; }

.ant-input-group {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%; }

.ant-input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0; }

.ant-input-group > [class*="col-"] {
  padding-right: 8px; }

.ant-input-group > [class*="col-"]:last-child {
  padding-right: 0; }

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell; }

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0; }

.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle; }

.ant-input-group-wrap > * {
  display: block !important; }

.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0; }

.ant-input-group .ant-input:focus {
  z-index: 1; }

.ant-input-group-addon {
  padding: 0 11px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: relative;
  -webkit-transition: all .3s;
  transition: all .3s; }

.ant-input-group-addon .ant-select {
  margin: -5px -11px; }

.ant-input-group-addon .ant-select .ant-select-selection {
  background-color: inherit;
  margin: -1px;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #1890ff; }

.ant-input-group-addon > i:only-child:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.ant-input-group-addon:first-child {
  border-right: 0; }

.ant-input-group-addon:last-child {
  border-left: 0; }

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px; }

.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 1px 7px;
  height: 24px; }

.ant-input-group-lg .ant-select-selection--single {
  height: 40px; }

.ant-input-group-sm .ant-select-selection--single {
  height: 24px; }

.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  width: 100%;
  float: left; }

.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1; }

.ant-input-group.ant-input-group-compact:before,
.ant-input-group.ant-input-group-compact:after {
  content: " ";
  display: table; }

.ant-input-group.ant-input-group-compact:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0; }

.ant-input-group.ant-input-group-compact > * {
  border-radius: 0;
  border-right-width: 0;
  vertical-align: top;
  float: none;
  display: inline-block; }

.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
  z-index: auto; }

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-radius: 0;
  border-right-width: 0; }

.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right-width: 1px; }

.ant-input-group-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 100%; }

.ant-input-affix-wrapper {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%; }

.ant-input-affix-wrapper .ant-input {
  z-index: 1; }

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #40a9ff; }

.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  line-height: 0;
  color: rgba(0, 0, 0, 0.65); }

.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px; }

.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px; }

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px; }

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px; }

.ant-input-affix-wrapper .ant-input {
  min-height: 100%; }

.ant-input-search-icon {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.45); }

.ant-input-search:not(.ant-input-search-small) > .ant-input-suffix {
  right: 12px; }

.ant-input-search > .ant-input-suffix > .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.ant-input-search > .ant-input-suffix > .ant-input-search-button > .anticon-search {
  font-size: 16px; }

.ant-input-search.ant-input-search-enter-button > .ant-input {
  padding-right: 46px; }

.ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
  right: 0; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.ant-time-picker-panel {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1050;
  position: absolute; }

.ant-time-picker-panel-inner {
  position: relative;
  outline: none;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  overflow: hidden;
  left: -2px; }

.ant-time-picker-panel-input {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  cursor: auto;
  outline: 0; }
  .ant-time-picker-panel-input:focus {
    box-shadow: none; }

.ant-time-picker-panel-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1; }

.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-time-picker-panel-input-wrap {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8; }

.ant-time-picker-panel-input-invalid {
  border-color: red; }

.ant-time-picker-panel-clear-btn {
  position: absolute;
  right: 8px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0; }

.ant-time-picker-panel-clear-btn:after {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: 1;
  width: 20px;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e"; }

.ant-time-picker-panel-clear-btn:hover:after {
  color: rgba(0, 0, 0, 0.45); }

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px; }

.ant-time-picker-panel-select:last-child ul {
  padding-bottom: 0; }

.ant-time-picker-panel-select {
  float: left;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 61px;
  overflow: hidden;
  position: relative;
  max-height: 192px; }

.ant-time-picker-panel-select:hover {
  overflow-y: auto; }

.ant-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0; }

.ant-time-picker-panel-select:last-child {
  border-right: 0; }

.ant-time-picker-panel-select:only-child {
  width: 100%; }

.ant-time-picker-panel-select ul {
  list-style: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 160px;
  width: 100%; }

.ant-time-picker-panel-select li {
  list-style: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin: 0;
  padding: 0 0 0 12px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: background 0.3s;
  transition: background 0.3s; }

.ant-time-picker-panel-select li:hover {
  background: #e6f7ff; }

li.ant-time-picker-panel-select-option-selected {
  background: #f5f5f5;
  font-weight: bold; }

li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5; }

li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25); }

li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed; }

.ant-time-picker-panel-combobox {
  zoom: 1; }

.ant-time-picker-panel-combobox:before,
.ant-time-picker-panel-combobox:after {
  content: " ";
  display: table; }

.ant-time-picker-panel-combobox:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0; }

.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8; }

.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn; }

.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn; }

.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut; }

.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut; }

.ant-time-picker {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  outline: none;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  width: 112px; }

.ant-time-picker-input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s; }

.ant-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1; }

.ant-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf; }

.ant-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf; }

.ant-time-picker-input:hover {
  border-color: #40a9ff; }

.ant-time-picker-input:focus {
  border-color: #40a9ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-time-picker-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25); }

.ant-time-picker-input-disabled:hover {
  border-color: #e6d8d8; }

textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  -webkit-transition: all .3s, height 0s;
  transition: all .3s, height 0s;
  min-height: 32px; }

.ant-time-picker-input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px; }

.ant-time-picker-input-sm {
  padding: 1px 7px;
  height: 24px; }

.ant-time-picker-input[disabled] {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25); }

.ant-time-picker-input[disabled]:hover {
  border-color: #e6d8d8; }

.ant-time-picker-open {
  opacity: 0; }

.ant-time-picker-icon {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 14px;
  height: 14px;
  line-height: 14px;
  right: 11px;
  color: rgba(0, 0, 0, 0.25);
  top: 50%;
  margin-top: -7px; }

.ant-time-picker-icon:after {
  content: "\e641";
  font-family: "anticon";
  color: rgba(0, 0, 0, 0.25);
  display: block;
  line-height: 1; }

.ant-time-picker-large .ant-time-picker-input {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px; }

.ant-time-picker-small .ant-time-picker-input {
  padding: 1px 7px;
  height: 24px; }

.ant-time-picker-small .ant-time-picker-icon {
  right: 7px; }

.ant-time-picker-icon {
  top: 23px; }

.ant-time-picker-input {
  height: 44px; }

/* Account Summary  -------------------
--------------------------------------- */
.account-summary {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  min-height: 100%; }
  .account-summary .pending-activity {
    margin-top: 80px; }
  .account-summary .tools {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .account-summary .balance .tools {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .account-summary .balance .tools .recurring-payment-link {
      font-weight: 700;
      margin-top: 1.3125rem; }
  .account-summary .no-monthly-data {
    margin-top: 24px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #6e7b87; }

@media (min-width: 768px) {
  .account-summary {
    padding: 0 1rem; }
    .account-summary .summary #as-of-date-time {
      margin-bottom: 78px; }
    .account-summary .tools {
      -webkit-justify-content: flex-end;
      -moz-justify-content: flex-end;
      -ms-justify-content: flex-end;
      justify-content: flex-end;
      -ms-flex-pack: flex-end; }
    .account-summary .balance .tools {
      -webkit-align-items: flex-end;
      -moz-align-items: flex-end;
      -ms-align-items: flex-end;
      align-items: flex-end; } }

/* Vanpool Summary --------------------
--------------------------------------- */
.vanpool-route-summary-view {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif; }
  .vanpool-route-summary-view h2.vanpool-summary-name {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    font-size: 1.5rem;
    margin: 0; }
  .vanpool-route-summary-view h3.vanpool-summary-id {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    font-size: 1.4375rem;
    margin: 0; }

/* Vanpool Summary --------------------
--------------------------------------- */
#as-of-date-time {
  font-size: 0.875rem;
  color: #b3b3b3;
  margin: 0;
  padding: 0; }

/* Payment History --------------------
--------------------------------------- */
.old-invoice-notice {
  text-align: center;
  font-size: 0.875rem;
  padding: 1rem 0;
  font-style: italic; }

.payment-history {
  padding: 0 1rem;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1; }
  .payment-history .payment-history-invoices .etable-cell {
    cursor: pointer; }
  .payment-history .payment-history-invoices:last-child {
    border-bottom: 0.125rem solid #130c0e; }
  .payment-history .no-scheduled-payments {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    padding-bottom: 0.5rem !important; }
  .payment-history .account-summary-container .tools {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .payment-history .account-summary-container .tools .primary {
      margin-bottom: 1rem; }
  @media (min-width: 768px) {
    .payment-history .account-summary-container .tools {
      -webkit-align-items: flex-end;
      -moz-align-items: flex-end;
      -ms-align-items: flex-end;
      align-items: flex-end; }
      .payment-history .account-summary-container .tools .recurring-payment-link {
        margin-top: 1rem;
        font-weight: 700;
        margin-top: 1.3125rem; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .payment-history .account-summary-container .tools .recurring-payment-link {
        margin-top: 5px; } }
  .payment-history .payment-invoices .hidden-link {
    display: none; }
  .payment-history .payment-invoices .invoice-table {
    margin-top: 2rem; }
    .payment-history .payment-invoices .invoice-table .header-labels {
      display: none; }
    .payment-history .payment-invoices .invoice-table .latest .etable-row {
      background-color: #049b5c; }
    .payment-history .payment-invoices .invoice-table .latest .invoice-due-by {
      margin: 0;
      padding: 0 1rem 0;
      color: #FFFFFF;
      position: relative;
      top: 2px;
      font-style: italic;
      font-size: 0.9375rem; }
    .payment-history .payment-invoices .invoice-table .latest .month-label-download,
    .payment-history .payment-invoices .invoice-table .latest .angle-down-button,
    .payment-history .payment-invoices .invoice-table .latest .pipe,
    .payment-history .payment-invoices .invoice-table .latest .total {
      color: #FFFFFF !important; }
    .payment-history .payment-invoices .invoice-table .current-month {
      color: #049b5c; }
      .payment-history .payment-invoices .invoice-table .current-month .pipe {
        color: #333333;
        margin: 0 1rem; }
    .payment-history .payment-invoices .invoice-table .invoice-archive {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 1.125rem;
      background-color: #f2f2f2; }
      .payment-history .payment-invoices .invoice-table .invoice-archive button.invoice-number {
        font-weight: 700;
        color: #049b5c; }
        .payment-history .payment-invoices .invoice-table .invoice-archive button.invoice-number:hover {
          color: #025030; }
      .payment-history .payment-invoices .invoice-table .invoice-archive .date {
        margin: 0; }
      .payment-history .payment-invoices .invoice-table .invoice-archive .amount-due {
        display: none; }
    .payment-history .payment-invoices .invoice-table .download-link .download-label {
      display: none;
      font-weight: 700; }
  .payment-history .payment-invoices .tools {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .payment-history .payment-invoices .balance .note {
    margin-top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem; }
  .payment-history .payment-invoices .balance .tools {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .payment-history .payment-invoices .balance .tools .recurring-payment-link {
      font-weight: 700;
      margin-top: 1.3125rem; }
      @media (min-width: 768px) {
        .payment-history .payment-invoices .balance .tools .recurring-payment-link {
          margin-top: 5px; } }
  .payment-history .title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    font-size: 1.125rem;
    padding: 0.5rem;
    border-bottom: 0.125rem solid #333333;
    font-weight: 600;
    text-transform: uppercase; }
    .payment-history .title .description {
      font-size: 0.75rem;
      font-weight: normal;
      text-transform: none; }
  .payment-history .requested {
    margin-bottom: 4rem; }
    .payment-history .requested .description {
      font-style: italic; }
    .payment-history .requested .disclaimer {
      padding: 10px 15px 10px 25px;
      color: #f5a623;
      font-style: italic; }
    .payment-history .requested .due {
      font-weight: 600; }
    .payment-history .requested .total-row {
      font-weight: bold !important; }
    .payment-history .requested .note-toggle {
      color: #CCCCCC;
      font-size: 1.25rem; }
      .payment-history .requested .note-toggle.chosen {
        color: #049b5c; }
        .payment-history .requested .note-toggle.chosen:focus, .payment-history .requested .note-toggle.chosen:hover {
          color: #049b5c; }
      .payment-history .requested .note-toggle:focus, .payment-history .requested .note-toggle:hover {
        box-shadow: none;
        color: #049b5c; }
      .payment-history .requested .note-toggle:disabled {
        visibility: hidden; }
    .payment-history .requested .notes-section {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 0em 2em 0em 2em;
      border-bottom: solid 1px #b3b3b3; }
      .payment-history .requested .notes-section .heading {
        font-weight: 600;
        color: gray; }
      .payment-history .requested .notes-section .note {
        color: #333333; }
  .payment-history .recurring {
    margin-bottom: 4rem; }
    .payment-history .recurring .description {
      font-style: italic;
      width: 20rem; }
    .payment-history .recurring .amount {
      width: 10rem;
      text-align: left !important; }
    .payment-history .recurring .actions {
      display: flex;
      width: 6rem;
      align-self: flex-end;
      text-align: center; }
      .payment-history .recurring .actions .edit {
        flex: 50%; }
      .payment-history .recurring .actions .delete {
        flex: 50%; }
      .payment-history .recurring .actions svg {
        position: relative;
        font-size: 1.125rem;
        color: #049b5c;
        cursor: pointer; }
    .payment-history .recurring .name {
      flex: 1; }
    .payment-history .recurring.etable .etable-row.header-labels {
      padding: 0em;
      border-bottom: 0.125rem solid #333333; }
      .payment-history .recurring.etable .etable-row.header-labels .etable-header.title {
        border-bottom: 0;
        color: #333333;
        flex: 1; }
      .payment-history .recurring.etable .etable-row.header-labels .etable-header.payment-schedule {
        width: 20rem; }
      .payment-history .recurring.etable .etable-row.header-labels .etable-header.amount {
        width: 10rem; }
      .payment-history .recurring.etable .etable-row.header-labels .etable-header.edit {
        width: 3.5rem;
        text-align: center; }
      .payment-history .recurring.etable .etable-row.header-labels .etable-header.delete {
        width: 3.5rem;
        text-align: center; }
    .payment-history .recurring.etable .etable-heading .etable-row {
      background-color: #b3b3b3;
      color: #333333;
      padding: 0.4rem 1rem 0.4rem 1.2rem; }
      .payment-history .recurring.etable .etable-heading .etable-row .etable-head {
        font-weight: 400; }

@media (max-width: 768px) {
  .payment-history {
    padding: 0 1rem; }
    .payment-history .recurring .name {
      max-width: calc(100% - 5rem);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .payment-history .recurring .no-scheduled-payments {
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around; }
    .payment-history .recurring .amount,
    .payment-history .recurring .description {
      display: none; }
    .payment-history .recurring .etable-row {
      position: relative;
      flex-direction: column;
      padding: 0.6rem 0.6rem; }
      .payment-history .recurring .etable-row.header-labels .etable-header.amount, .payment-history .recurring .etable-row.header-labels .etable-header.edit, .payment-history .recurring .etable-row.header-labels .etable-header.delete, .payment-history .recurring .etable-row.header-labels .etable-header.payment-schedule {
        display: none; }
      .payment-history .recurring .etable-row .amount,
      .payment-history .recurring .etable-row .description {
        display: block; }
      .payment-history .recurring .etable-row .amount {
        right: 1rem; } }

@media (min-width: 768px) {
  .payment-history .title {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 1.25rem;
    padding: 0.5rem; }
    .payment-history .title .description {
      margin-left: 1rem; }
  .payment-history .payment-invoices .invoice-table .header-labels {
    display: flex; }
    .payment-history .payment-invoices .invoice-table .header-labels .service-month {
      flex: 0 0 173px; }
    .payment-history .payment-invoices .invoice-table .header-labels .activity-date {
      text-align: right; }
  .payment-history .payment-invoices .invoice-table .latest {
    border-top: solid 2px #0d6a43; }
    .payment-history .payment-invoices .invoice-table .latest .invoice-due-by {
      flex-grow: 1;
      text-align: right; }
  .payment-history .payment-invoices .invoice-table .invoice-archive .date {
    margin: 0 2rem 0 1.5625rem; }
  .payment-history .payment-invoices .invoice-table .invoice-archive .amount-due {
    display: block;
    font-weight: 600; }
  .payment-history .payment-invoices .invoice-table .download-link .download-label {
    display: inline-block; }
  .payment-history .payment-invoices .invoice-table .amount-due {
    display: inline-block; }
  .payment-history .payment-invoices .tools {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
  .payment-history .payment-invoices .balance .tools {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end; }
  .payment-history .recurring .amount,
  .payment-history .recurring .description {
    display: block; } }

/* Login  --------------------
------------------------------ */
.login,
.login-error {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 100vh; }
  .login .card,
  .login-error .card {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    padding: 0 1rem;
    min-height: 400px; }
    .login .card img.branding,
    .login-error .card img.branding {
      width: 80%;
      max-width: 512px;
      margin: 0 auto 40px;
      height: auto !important; }
    .login .card input[type='submit'],
    .login-error .card input[type='submit'] {
      width: 100%;
      margin: 0 auto;
      padding: 2%;
      background-color: #049b5c;
      color: #FFFFFF;
      border: solid 1px #03693e;
      cursor: pointer;
      font-size: 1rem;
      max-width: 512px; }
    .login .card input[name='username'],
    .login-error .card input[name='username'] {
      margin-bottom: 1rem; }
    .login .card .loader,
    .login-error .card .loader {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
      .login .card .loader.closed,
      .login-error .card .loader.closed {
        display: none; }
    .login .card .account-reset,
    .login-error .card .account-reset {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      padding: 10px 0; }
      .login .card .account-reset a,
      .login-error .card .account-reset a {
        font-size: 0.8rem; }
        .login .card .account-reset a:first-child,
        .login-error .card .account-reset a:first-child {
          margin-right: 5px; }
        .login .card .account-reset a:last-child,
        .login-error .card .account-reset a:last-child {
          margin-left: 5px; }

.login-error img.branding {
  width: 80%;
  max-width: 25rem;
  margin: 0 auto 2.5rem;
  height: auto !important; }

.login-error p {
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
  margin: 0; }

.login-error .button {
  width: 30%;
  margin-top: 2rem; }

@media (min-width: 768px) {
  .login .card,
  .login-error .card {
    width: 35%;
    padding: 0;
    min-height: 500px; }
    .login .card input,
    .login-error .card input {
      padding: 10px;
      font-size: 0.8rem; }
      .login .card input[type='submit'],
      .login-error .card input[type='submit'] {
        padding: 10px; }
        .login .card input[type='submit']:hover,
        .login-error .card input[type='submit']:hover {
          background-color: #04aa65; }
      .login .card input[name='password'],
      .login-error .card input[name='password'] {
        margin-bottom: 1rem; }
  .login-error p {
    padding: 0; } }

/* One Time Payment  ------------------
--------------------------------------- */
.one-time-payment .allocation ul {
  padding-left: 5px; }

@media (min-width: 768px) {
  .one-time-payment .method {
    padding: 0; } }

/* Add New Card  ----------------
------------------------------ */
.add-new-card .accepted {
  margin: 20px 0; }
  .add-new-card .accepted svg {
    color: #CCCCCC;
    font-size: 2rem;
    margin-right: 1rem; }

.add-new-card .cc {
  margin-bottom: 20px; }
  .add-new-card .cc input[type='text'] {
    margin-right: 1rem; }
    .add-new-card .cc input[type='text'].long {
      max-width: 200px; }

/* Recurring Payment  -----------
------------------------------ */
.recurring-payment .summary .input {
  margin-top: 1rem; }

.recurring-payment .allocation ul {
  padding-left: 5px; }

@media (min-width: 768px) {
  .recurring-payment .date .note {
    width: 66%;
    max-width: 512px; } }

/* Recurring Payment Scheduled --
------------------------------ */
.recurring-payment-scheduled .payment-info .row .cell {
  padding: 1rem;
  border-bottom: solid 1px #CCCCCC;
  width: 100%; }
  .recurring-payment-scheduled .payment-info .row .cell .label {
    color: gray; }
  .recurring-payment-scheduled .payment-info .row .cell .entry {
    font-size: 1.3rem; }

.recurring-payment-scheduled .payment-info .row .payment-amount {
  border: none; }

@media (min-width: 768px) {
  .recurring-payment-scheduled .payment-info .row {
    border-top: solid 1px #CCCCCC; }
    .recurring-payment-scheduled .payment-info .row:first-child {
      border-top: none;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      .recurring-payment-scheduled .payment-info .row:first-child .cell {
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1; }
    .recurring-payment-scheduled .payment-info .row .payment-name {
      border-right: solid 1px #CCCCCC; }
    .recurring-payment-scheduled .payment-info .row .first-scheduled-charge {
      border-left: solid 1px #CCCCCC; } }

/* PDF Viewer  -----------
------------------------------ */
.pdf-container {
  position: relative; }
  .pdf-container .pdf-view {
    margin-top: 20px;
    background-color: gray;
    padding-top: 20px;
    padding-bottom: 20px; }
    .pdf-container .pdf-view .ReactPDF__Page {
      margin-bottom: 20px; }
      .pdf-container .pdf-view .ReactPDF__Page canvas {
        margin: 0 auto; }
  .pdf-container .button {
    display: none;
    position: absolute;
    top: -60px;
    right: 0; }

.ReactPDF__Page {
  width: 100% !important; }
  .ReactPDF__Page .ReactPDF__Page__textContent {
    width: 100% !important;
    left: 0 !important; }
    .ReactPDF__Page .ReactPDF__Page__textContent div {
      left: 0 !important; }
  .ReactPDF__Page .ReactPDF__Page__canvas {
    width: 100% !important;
    height: auto !important; }

@media (min-width: 768px) {
  .pdf-container .button {
    display: inline-block; }
  .ReactPDF__Page {
    width: auto !important; }
    .ReactPDF__Page .ReactPDF__Page__textContent {
      width: auto !important;
      left: inherit !important; }
      .ReactPDF__Page .ReactPDF__Page__textContent div {
        left: inherit !important; }
    .ReactPDF__Page .ReactPDF__Page__canvas {
      width: 90% !important;
      height: 90% !important; } }

@media (min-width: 768px) {
  .account-summary, .payment-history, .fuel-card-page, .payment-management {
    padding-top: 180px !important; } }

/*	COLORS
–––––––––––––––––––––––––––*/
.payment {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  padding-top: 60px;
  position: relative; }
  .payment .note {
    padding: 0 1rem; }
  .payment h3 {
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-bottom: 1rem; }
    .payment h3 a {
      font-size: 0.75rem;
      text-transform: none;
      font-style: italic;
      text-decoration: underline;
      position: relative;
      margin-left: 0.25rem;
      top: -0.125rem;
      display: inline-block; }
  .payment .payment-type h2 {
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 1rem;
    margin-bottom: .5rem; }
  .payment .payment-type .select-payment-type {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin: 5px 0 1rem; }
    .payment .payment-type .select-payment-type h4 {
      display: none; }
    .payment .payment-type .select-payment-type svg {
      margin-right: .5rem;
      margin-top: 0.25rem;
      color: #049b5c; }
    .payment .payment-type .select-payment-type .payment-toggle {
      display: block;
      text-decoration: underline;
      font-size: 0.875rem;
      margin-top: 1rem;
      margin-left: 0.25rem; }
      .payment .payment-type .select-payment-type .payment-toggle:focus {
        color: #025030;
        outline: none;
        box-shadow: none; }
  .payment .payment-type .last-payment {
    border: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .payment .payment-type .last-payment svg {
      font-size: 18px; }
    .payment .payment-type .last-payment h4 {
      display: none; }
  .payment .stepper .step {
    display: none; }
  .payment .stepper .mobile {
    background-color: #049b5c;
    color: #FFFFFF;
    text-align: center;
    padding: .5rem 0;
    font-weight: 300; }
  .payment .last-payment {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: .5rem; }
    .payment .last-payment .flex div {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      width: 100%; }
    .payment .last-payment svg {
      font-size: 24px;
      color: #049b5c;
      margin-right: 3px; }
    .payment .last-payment h4 {
      font-weight: 600;
      color: #333333;
      line-height: 1.4rem;
      margin: 0;
      padding: 0;
      font-size: 0.875rem; }
    .payment .last-payment p {
      color: gray;
      font-style: italic;
      line-height: 1.4rem;
      font-size: 0.875rem;
      margin: 0 0 0 .5rem;
      padding: 0; }
  .payment .section {
    margin-bottom: 20px;
    padding: 0 1rem; }
  .payment .payment-amount, .payment .payment-name {
    position: relative; }
    .payment .payment-amount svg, .payment .payment-name svg {
      font-size: 32px;
      z-index: 10;
      color: #049b5c;
      position: absolute;
      left: 0;
      top: 4px; }
    .payment .payment-amount input, .payment .payment-name input {
      padding: 4px 10px;
      border: 1px solid #CCCCCC;
      font-size: 1.25rem;
      text-align: right;
      width: 100%; }
  .payment .payment-name input {
    text-align: left;
    font-weight: 300;
    max-width: 600px; }
    .payment .payment-name input::placeholder {
      color: #e6e6e6;
      opacity: 1; }
  .payment .cards {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .payment .cards .radio-btn {
      width: 100%;
      padding: 0; }
    .payment .cards .card {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      height: auto;
      border: 1px solid #b3b3b3;
      padding: .5rem;
      margin: 0 0 .5rem;
      background: #FFFFFF;
      cursor: pointer;
      position: relative; }
      .payment .cards .card .cvc {
        display: none; }
      .payment .cards .card:disabled {
        background-color: #e6e6e6;
        opacity: 0.3;
        pointer-events: none; }
      .payment .cards .card.spacer {
        border: none;
        display: none; }
      .payment .cards .card.selected {
        background: #e5ebf2;
        border: 2px solid #049b5c;
        margin-bottom: 50px;
        color: #809dbe; }
        .payment .cards .card.selected .details .exp {
          color: #809dbe;
          font-size: 0.875rem; }
        .payment .cards .card.selected .cvc {
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
          top: 45px; }
          .payment .cards .card.selected .cvc input {
            position: absolute;
            right: 10px;
            bottom: 9px;
            width: 65px;
            font-size: 1rem;
            color: #333333;
            text-align: center;
            letter-spacing: 3px;
            border: 1px solid #CCCCCC;
            height: 34px; }
            .payment .cards .card.selected .cvc input::placeholder {
              color: #e6e6e6;
              opacity: 1; }
          .payment .cards .card.selected .cvc .popout {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            position: absolute;
            bottom: -42px;
            height: 40px;
            border: 1px solid #809dbe;
            border-top: none;
            padding: 5px 10px;
            width: calc(100% + 4px);
            left: -2px;
            background: #FFFFFF; }
            .payment .cards .card.selected .cvc .popout .cvc-prompt {
              position: relative;
              top: 5px;
              font-size: 0.875rem;
              color: #049b5c; }
            .payment .cards .card.selected .cvc .popout:before, .payment .cards .card.selected .cvc .popout:after {
              content: ' ';
              position: absolute;
              display: block;
              right: 33px;
              top: -10px;
              height: 0;
              border-right: 10px solid transparent;
              border-left: 10px solid transparent;
              border-bottom: 10px solid #809dbe;
              width: 0; }
            .payment .cards .card.selected .cvc .popout:after {
              top: -9px;
              right: 34px;
              border-right: 9px solid transparent;
              border-left: 9px solid transparent;
              border-bottom: 9px solid #FFFFFF; }
      .payment .cards .card .logo {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        -ms-justify-content: space-around;
        justify-content: space-around;
        -ms-flex-pack: space-around;
        margin-right: .5rem; }
        .payment .cards .card .logo img {
          max-height: 34px;
          min-width: 54px; }
      .payment .cards .card .details {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        font-size: 0.875rem; }
        .payment .cards .card .details .account {
          line-height: 1rem; }
        .payment .cards .card .details .exp {
          color: #484848;
          font-size: 0.875rem; }
          .payment .cards .card .details .exp.passed {
            margin-top: 10px; }
          .payment .cards .card .details .exp.expired {
            color: #c01515; }
            .payment .cards .card .details .exp.expired svg {
              position: relative;
              top: -1px;
              left: 5px; }
  .payment .recurring .cards .card.selected {
    margin-bottom: 0.5rem; }
  .payment .radio-btn {
    text-align: left;
    background: none;
    border: none; }
  .payment .schedule {
    transition: opacity 0.5s linear; }
    .payment .schedule.hidden {
      opacity: 0;
      transition: opacity 0s linear; }
    .payment .schedule div {
      font-size: 1rem;
      font-weight: 300;
      max-width: 35rem; }
      .payment .schedule div:last-child {
        font-style: italic;
        color: #b3b3b3; }
      .payment .schedule div .date {
        font-weight: 500;
        font-size: 1.5rem; }
      .payment .schedule div .every {
        color: #333333;
        font-weight: 600;
        font-size: 1rem; }
    .payment .schedule .card {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      width: 18rem;
      height: 5rem;
      border: 1px solid #e6e6e6;
      padding: 1rem;
      margin: 1rem -0.25rem; }
      .payment .schedule .card .card-info {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex; }
      .payment .schedule .card .logo {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        -ms-justify-content: space-around;
        justify-content: space-around;
        -ms-flex-pack: space-around;
        margin-right: .5rem; }
        .payment .schedule .card .logo img {
          min-height: 50px; }
      .payment .schedule .card .details .account {
        color: #333333;
        font-style: normal; }
      .payment .schedule .card .details .exp {
        font-size: 0.875rem;
        font-style: normal; }
  .payment .condition {
    display: flex;
    margin: 16px 0px;
    padding: 0 1rem;
    max-width: 35rem; }
    .payment .condition .checkbox {
      height: 20px;
      min-width: 20px;
      background-color: #EDEDED;
      border: 2px solid #838383;
      border-radius: 2px;
      padding: 0px; }
      .payment .condition .checkbox.selected {
        background: #00A664;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px; }
        .payment .condition .checkbox.selected img {
          width: 13px; }
    .payment .condition .text {
      margin-left: 10px;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A; }
      .payment .condition .text .callable {
        font-weight: 500;
        color: #130C0E; }
  .payment .terms-policy {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    padding: 0 1rem;
    color: #4A4A4A;
    max-width: 20rem;
    margin: 8px 0px; }
    .payment .terms-policy a {
      color: #4A4A4A;
      text-decoration: underline; }
  .payment .modify.recurring .tools.section {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .payment .modify.recurring .tools.section .secondary {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1; }
    .payment .modify.recurring .tools.section .primary {
      -webkit-box-ordinal-group: 0;
      -moz-box-ordinal-group: 0;
      -ms-flex-order: 0;
      -webkit-order: 0;
      order: 0;
      margin-bottom: 1rem; }
  .payment .tools .pay:disabled {
    background: #b3b3b3;
    border-color: #b3b3b3;
    cursor: not-allowed; }

@media (min-width: 768px) {
  .payment {
    padding-top: 70px; }
    .payment .one-time, .payment .recurring {
      padding: 0; }
    .payment .title-toggle {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      border-bottom: 1px solid #e6e6e6;
      margin: .5rem 0 2rem !important;
      padding: 0 0 1rem 0; }
    .payment .last-payment {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around;
      border-bottom: none;
      margin: 0;
      padding: 0; }
      .payment .last-payment .flex div {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: flex-start;
        -moz-align-items: flex-start;
        -ms-align-items: flex-start;
        align-items: flex-start; }
      .payment .last-payment svg {
        font-size: 42px;
        margin-right: .75rem;
        color: #049b5c; }
      .payment .last-payment h4 {
        margin: 0;
        padding: 0;
        font-weight: 600;
        color: #333333;
        line-height: 1.4rem;
        display: block !important; }
      .payment .last-payment p {
        margin: 0;
        padding: 0;
        font-size: 1.125rem;
        color: gray;
        font-style: italic;
        line-height: 1.4rem; }
    .payment .new-card .radio {
      width: 32rem; }
    .payment .stepper {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      margin: 3rem 0 1rem;
      min-height: 2.25rem;
      max-height: 2.25rem; }
      .payment .stepper .mobile {
        display: none; }
      .payment .stepper .step {
        width: 33.3%;
        position: relative;
        display: block; }
        .payment .stepper .step label:after {
          content: '';
          display: block;
          width: 2rem;
          height: 2rem;
          background-color: #e6e6e6;
          position: absolute;
          top: 0.25rem;
          right: 0.5rem;
          border-top: solid 0.1875rem #FFFFFF;
          border-right: solid 0.1875rem #FFFFFF;
          z-index: 10;
          transform: rotate(45deg); }
        .payment .stepper .step label {
          display: block;
          background-color: #e6e6e6;
          color: gray;
          padding: .5rem .5rem .5rem 1rem;
          margin-bottom: 1rem; }
        .payment .stepper .step.one div.label {
          background-color: #e6e6e6; }
          .payment .stepper .step.one div.label label {
            background-color: #049b5c;
            color: white;
            width: 93%; }
            .payment .stepper .step.one div.label label:after {
              background-color: #049b5c; }
        .payment .stepper .step.three label:after {
          display: none; }
      .payment .stepper.step-2 .step.one .label {
        background-color: #049b5c;
        color: #FFFFFF; }
      .payment .stepper.step-2 .step.two .label {
        background-color: #e6e6e6; }
        .payment .stepper.step-2 .step.two .label label {
          background-color: #049b5c;
          color: #FFFFFF;
          width: 93%; }
          .payment .stepper.step-2 .step.two .label label:after {
            background-color: #049b5c; }
      .payment .stepper.step-3 .step.one .label {
        background-color: #049b5c;
        color: #FFFFFF; }
      .payment .stepper.step-3 .step.two .label {
        background-color: #049b5c; }
        .payment .stepper.step-3 .step.two .label label {
          background-color: #049b5c;
          color: #FFFFFF;
          width: 93%; }
          .payment .stepper.step-3 .step.two .label label:after {
            background-color: #049b5c; }
      .payment .stepper.step-3 .step.three .label {
        background-color: #e6e6e6; }
        .payment .stepper.step-3 .step.three .label label {
          background-color: #049b5c;
          color: #FFFFFF; }
    .payment .payment-type {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      width: 100%; }
      .payment .payment-type h2 {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border-bottom: none;
        padding: 0;
        margin: 0;
        margin-left: 1rem; }
      .payment .payment-type .select-payment-type {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        padding-left: 3rem;
        position: relative; }
        .payment .payment-type .select-payment-type h4 {
          display: block;
          margin-bottom: 0; }
        .payment .payment-type .select-payment-type .payment-toggle {
          margin: 0; }
        .payment .payment-type .select-payment-type svg {
          position: absolute;
          left: 0;
          top: 1.5rem;
          font-size: 2.5rem; }
      .payment .payment-type .last-payment {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1; }
        .payment .payment-type .last-payment svg {
          font-size: 4rem;
          position: relative;
          top: 0.125rem; }
    .payment .section {
      margin-top: 30px; }
    .payment .payment-amount input {
      width: 240px; }
    .payment .cards {
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around; }
      .payment .cards .card {
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1;
        max-width: 32%;
        height: 85px; }
        .payment .cards .card.spacer {
          display: block; }
        .payment .cards .card .logo {
          width: 80px; }
          .payment .cards .card .logo img {
            min-height: 50px;
            min-width: 78px; }
        .payment .cards .card .details {
          font-size: 1rem;
          margin-bottom: 0.5rem; }
    .payment .card-icons {
      margin-left: .5rem; }
    .payment .tools {
      -webkit-justify-content: flex-end;
      -moz-justify-content: flex-end;
      -ms-justify-content: flex-end;
      justify-content: flex-end;
      -ms-flex-pack: flex-end; }
    .payment .modify.recurring .tools.section {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .payment .modify.recurring .tools.section .secondary {
        -webkit-box-ordinal-group: 0;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0; }
      .payment .modify.recurring .tools.section .primary {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
        margin-bottom: 0; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .payment .section {
    min-height: 1px; } }

.payment-management {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  position: relative;
  padding: 30px; }
  .payment-management .details {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    border-bottom: 0.125rem solid #333333;
    padding-bottom: 0.5rem; }
    .payment-management .details .title {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      max-width: 18rem;
      min-width: 18rem;
      font-size: 1.75rem;
      font-weight: 600;
      color: #333333;
      padding-left: 0.5rem;
      margin: 0.5rem auto 0; }
    .payment-management .details .last-updated {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      min-width: 17rem;
      max-width: 17rem;
      padding-left: 0.5rem;
      margin: 0.5rem auto 0;
      font-size: 0.75rem;
      font-style: italic;
      color: #484848; }
      .payment-management .details .last-updated .date {
        font-weight: 600; }
    .payment-management .details .amounts {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      min-width: 18rem;
      margin-top: 0.5rem; }
      .payment-management .details .amounts .label {
        font-size: 0.75rem;
        text-transform: uppercase; }
      .payment-management .details .amounts .amount {
        font-size: 1.75rem;
        white-space: nowrap;
        margin: auto;
        max-width: calc(50vw - 1rem); }
      .payment-management .details .amounts .current, .payment-management .details .amounts .billed {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
        padding-right: 1rem;
        min-width: 9rem; }
      .payment-management .details .amounts .current {
        border-right: 1px solid #CCCCCC; }
      .payment-management .details .amounts .billed {
        padding-left: 1rem; }
  .payment-management .participant .amount, .payment-management .split-container .amount {
    text-align: right;
    font-size: 1.25rem;
    color: #333333;
    border: 1px solid #b3b3b3;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    flex: 1;
    margin: 0.5rem auto;
    order: 1;
    min-width: 15rem; }
    .payment-management .participant .amount:disabled, .payment-management .split-container .amount:disabled {
      background: #f2f2f2;
      color: #484848; }
  .payment-management .participant .selected, .payment-management .split-container .selected {
    width: 2rem;
    color: #333333;
    font-size: 1.25rem; }
    .payment-management .participant .selected.chosen, .payment-management .split-container .selected.chosen {
      color: #049b5c; }
    .payment-management .participant .selected:focus, .payment-management .participant .selected:hover, .payment-management .split-container .selected:focus, .payment-management .split-container .selected:hover {
      box-shadow: none;
      color: #03693e; }
  .payment-management .split-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .payment-management .split-container .select-all {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      padding-right: 1rem;
      max-width: 8rem; }
      .payment-management .split-container .select-all span {
        margin-left: 0.5rem; }
    .payment-management .split-container .split {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      background: #e6e6e6;
      padding: 0.25rem 0.5rem; }
      .payment-management .split-container .split .label {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding-left: 1rem;
        font-size: 1.25rem;
        text-transform: uppercase; }
      .payment-management .split-container .split .amount {
        -webkit-box-flex: 0;
        -moz-box-flex: 0;
        -webkit-flex: 0;
        -ms-flex: 0;
        flex: 0;
        order: 0;
        font-size: 1.5rem;
        margin-right: 1rem; }
      .payment-management .split-container .split .button {
        min-width: 0; }
        .payment-management .split-container .split .button:disabled {
          opacity: 0.5;
          pointer-events: none; }
  .payment-management .participants {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .payment-management .participants .participant {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0.5rem;
      border-bottom: 1px solid #CCCCCC;
      max-width: 100vw; }
      .payment-management .participants .participant .credentials {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: calc(12rem - 1px); }
        .payment-management .participants .participant .credentials .roles {
          font-size: 0.75rem;
          font-style: italic;
          color: #b3b3b3;
          margin-bottom: 5px; }
      .payment-management .participants .participant .generated-user-image {
        display: none; }
      .payment-management .participants .participant .comment-toggle {
        color: #484848;
        font-size: 1.25rem; }
        .payment-management .participants .participant .comment-toggle.chosen {
          color: #049b5c; }
          .payment-management .participants .participant .comment-toggle.chosen:focus, .payment-management .participants .participant .comment-toggle.chosen:hover {
            color: #333333; }
        .payment-management .participants .participant .comment-toggle:focus, .payment-management .participants .participant .comment-toggle:hover {
          box-shadow: none;
          color: #049b5c; }
        .payment-management .participants .participant .comment-toggle:disabled {
          visibility: hidden; }
      .payment-management .participants .participant.expand {
        position: relative;
        padding-bottom: 5rem; }
        .payment-management .participants .participant.expand .editor-wrapper {
          position: absolute;
          bottom: 1rem;
          right: 0.5rem;
          width: calc(100% - 1rem); }
          .payment-management .participants .participant.expand .editor-wrapper textarea {
            padding: 0.25rem;
            resize: none;
            border-color: #b3b3b3; }
  .payment-management .total {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    border-top: 0.125rem solid #CCCCCC;
    padding: 0.5rem;
    font-size: 1.25rem; }
    .payment-management .total .label {
      color: #333333;
      text-transform: uppercase; }
    .payment-management .total .value {
      font-size: 1.25em;
      color: #049b5c; }
  .payment-management .note {
    display: inline-block;
    margin-left: auto; }
  .payment-management .actions {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .payment-management .actions .tools {
      width: 100%; }
      .payment-management .actions .tools .button:disabled {
        background: #CCCCCC;
        border-color: #CCCCCC;
        color: #FFFFFF; }

@media (max-width: 700px) {
  .payment-management .split-container .split {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
    .payment-management .split-container .split .label {
      display: none; }
    .payment-management .split-container .split .amount {
      -webkit-box-flex: 1 1 auto;
      -moz-box-flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      max-width: 15rem;
      min-width: 5rem;
      width: 100%;
      margin-right: 0.5rem;
      margin-left: 0; }
  .payment-management .details .amounts .small-font.amount {
    font-size: 1rem;
    line-height: 3rem; }
  .payment-management .details .amounts .medium-font.amount {
    font-size: 1.75rem;
    line-height: 3rem; } }

@media (min-width: 512px) {
  .payment-management .details .amounts {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
    min-width: 24rem; }
    .payment-management .details .amounts .amount {
      max-width: none; }
    .payment-management .details .amounts .billed {
      -webkit-box-flex: 0;
      -moz-box-flex: 0;
      -webkit-flex: 0;
      -ms-flex: 0;
      flex: 0; }
    .payment-management .details .amounts .current, .payment-management .details .amounts .billed {
      text-align: right; }
  .payment-management .participants .participant .generated-user-image {
    display: block;
    background: #FFFFFF;
    border: 1px solid #049b5c;
    color: #049b5c;
    font-size: 1.125rem;
    margin: 0 1rem; }
    .payment-management .participants .participant .generated-user-image span {
      margin-top: 10px;
      display: flex;
      justify-content: center; }
  .payment-management .participants .participant .amount {
    order: 0;
    max-width: 10rem;
    min-width: 10rem; }
  .payment-management .participants .participant.expand .editor-wrapper {
    right: 2.5rem;
    width: calc(100% - 10rem); }
  .payment-management .total .label {
    margin-left: 2.5rem; }
  .payment-management .total .value {
    margin-right: 2.5rem; }
  .payment-management .comments textarea {
    resize: none;
    padding: 0.25rem; } }

@media (max-width: 1024px) {
  .payment-management .actions {
    padding: 0.5rem; } }

@media (max-width: 768px) {
  .payment-management .actions .note {
    display: block;
    text-align: center;
    margin-left: 0; } }

/*	COLORS
–––––––––––––––––––––––––––*/
.vanpool-vehicle {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  padding-top: 60px;
  position: relative; }
  .vanpool-vehicle .vehicle-model {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1; }
    .vanpool-vehicle .vehicle-model .note {
      font-style: italic;
      text-align: center; }
    .vanpool-vehicle .vehicle-model h3 {
      padding-left: 10px;
      color: #333333;
      font-size: 1rem; }
    .vanpool-vehicle .vehicle-model .image {
      width: 300px;
      height: 225px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .vanpool-vehicle .vehicle-model .image img {
        width: 90%; }
    .vanpool-vehicle .vehicle-model .details {
      font-size: 0.875rem;
      padding: 10px; }
      .vanpool-vehicle .vehicle-model .details div {
        margin-bottom: 10px;
        color: #b3b3b3; }
      .vanpool-vehicle .vehicle-model .details div:before {
        content: attr(class);
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
        width: 75px;
        color: #333333; }
    .vanpool-vehicle .vehicle-model .request {
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      border-width: 1px;
      width: calc(100% - 3rem);
      margin: 0 1.5rem;
      margin-bottom: 4rem; }
    .vanpool-vehicle .vehicle-model .reason {
      margin-bottom: 4rem; }
      .vanpool-vehicle .vehicle-model .reason h3 {
        font-weight: 600; }
      .vanpool-vehicle .vehicle-model .reason .select {
        padding: 0.25rem; }
        .vanpool-vehicle .vehicle-model .reason .select select {
          border-radius: 0.125rem; }
      .vanpool-vehicle .vehicle-model .reason .other-reason {
        margin: 0.5rem 0.25rem; }
      .vanpool-vehicle .vehicle-model .reason .tools {
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        -ms-justify-content: space-around;
        justify-content: space-around;
        -ms-flex-pack: space-around; }
      .vanpool-vehicle .vehicle-model .reason .button {
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        border-width: 1px; }
        .vanpool-vehicle .vehicle-model .reason .button:disabled {
          color: #484848;
          border-color: #CCCCCC;
          background-color: #f2f2f2;
          cursor: default; }
    .vanpool-vehicle .vehicle-model .success, .vanpool-vehicle .vehicle-model .failure {
      font-style: italic;
      color: #484848;
      font-size: 0.75rem;
      text-align: center;
      margin-bottom: 4rem; }
      .vanpool-vehicle .vehicle-model .success span, .vanpool-vehicle .vehicle-model .failure span {
        display: block; }
    .vanpool-vehicle .vehicle-model .failure .request {
      margin-top: 2rem; }
  .vanpool-vehicle .vehicle-details {
    width: 100%;
    -webkit-box-ordinal-group: 0;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
    padding: .5rem; }
    .vanpool-vehicle .vehicle-details .add-ons {
      margin-bottom: 2rem; }
      .vanpool-vehicle .vehicle-details .add-ons .add-on {
        border: solid 1px #e6e6e6;
        padding: .5rem .75rem;
        margin-bottom: .5rem; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header .details img {
          width: 2rem;
          height: 2rem; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header .details .title {
          display: inline-block;
          font-size: 1.25rem;
          margin-left: 1rem; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header .description.pending {
          font-style: italic; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header .add {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
          -moz-justify-content: space-between;
          -ms-justify-content: space-between;
          justify-content: space-between;
          -ms-flex-pack: space-between; }
          .vanpool-vehicle .vehicle-details .add-ons .add-on header .add button {
            width: 6rem; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header button:disabled {
          color: #484848;
          border-color: #CCCCCC;
          background-color: #f2f2f2;
          cursor: default; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header .column {
          text-align: right; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header h3 {
          font-weight: 500;
          margin: 0;
          padding: 0; }
          .vanpool-vehicle .vehicle-details .add-ons .add-on header h3 svg {
            color: #049b5c;
            font-size: 22px;
            position: relative;
            top: -2px; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header .price {
          font-size: 1.25rem;
          margin-bottom: .25rem;
          display: inline-block; }
          .vanpool-vehicle .vehicle-details .add-ons .add-on header .price small {
            font-size: 0.6875rem;
            color: #b3b3b3;
            margin-left: .25rem; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header span {
          -webkit-flex-grow: 1;
          -moz-flex-grow: 1;
          -ms-flex-grow: 1;
          flex-grow: 1; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on p {
          font-size: 0.875rem;
          color: gray; }
    .vanpool-vehicle .vehicle-details .vanpool-details textarea {
      text-align: left; }
    .vanpool-vehicle .vehicle-details .vanpool-details .name {
      font-size: 1.75rem;
      font-weight: 600;
      width: 100%; }
    .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.name textarea {
      font-size: 1.75rem;
      font-weight: 600;
      width: 100%;
      resize: none;
      padding-right: 80px; }
    .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.name .inline-editor-counter {
      position: absolute;
      right: 8px;
      bottom: 3px;
      font-style: italic; }
      .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.name .inline-editor-counter .overflowed {
        color: #c01515; }
    .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.name .tools {
      top: 29px; }
    .vanpool-vehicle .vehicle-details .vanpool-details .id {
      color: gray;
      font-size: 20px;
      margin: 0 0 16px;
      font-weight: 300; }
    .vanpool-vehicle .vehicle-details .vanpool-details .description {
      font-size: 0.875rem;
      color: gray;
      font-weight: 300;
      margin-right: 1.5rem; }
    .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.description svg {
      min-width: 1.25rem;
      font-size: 1.25rem;
      position: absolute;
      right: 0; }
    .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.description textarea {
      font-size: 0.875rem;
      color: gray;
      font-weight: 300;
      resize: none;
      padding-right: 80px;
      width: 100%; }
    .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.description .inline-editor-counter {
      position: absolute;
      right: 8px;
      bottom: 3px;
      font-style: italic; }
      .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.description .inline-editor-counter .overflowed {
        color: #c01515; }
    .vanpool-vehicle .vehicle-details .vanpool-details .inline-editor.description .tools {
      top: 49px; }
    .vanpool-vehicle .vehicle-details .vanpool-details .stats {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      margin-top: 1rem; }
      .vanpool-vehicle .vehicle-details .vanpool-details .stats .seats, .vanpool-vehicle .vehicle-details .vanpool-details .stats .occupancy, .vanpool-vehicle .vehicle-details .vanpool-details .stats .contracted.distance, .vanpool-vehicle .vehicle-details .vanpool-details .stats .rent {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        border: solid 1px #CCCCCC;
        border-radius: 4px;
        position: relative;
        min-width: 14rem;
        margin: 0.25rem;
        padding-left: 0.25rem; }
        .vanpool-vehicle .vehicle-details .vanpool-details .stats .seats .column, .vanpool-vehicle .vehicle-details .vanpool-details .stats .occupancy .column, .vanpool-vehicle .vehicle-details .vanpool-details .stats .contracted.distance .column, .vanpool-vehicle .vehicle-details .vanpool-details .stats .rent .column {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          -ms-flex-pack: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          padding-right: 10px; }
          .vanpool-vehicle .vehicle-details .vanpool-details .stats .seats .column .label, .vanpool-vehicle .vehicle-details .vanpool-details .stats .occupancy .column .label, .vanpool-vehicle .vehicle-details .vanpool-details .stats .contracted.distance .column .label, .vanpool-vehicle .vehicle-details .vanpool-details .stats .rent .column .label {
            font-size: 0.6875rem;
            text-transform: uppercase;
            font-weight: 300; }
          .vanpool-vehicle .vehicle-details .vanpool-details .stats .seats .column .data, .vanpool-vehicle .vehicle-details .vanpool-details .stats .occupancy .column .data, .vanpool-vehicle .vehicle-details .vanpool-details .stats .contracted.distance .column .data, .vanpool-vehicle .vehicle-details .vanpool-details .stats .rent .column .data {
            font-size: 1.3rem;
            font-weight: 600; }
      .vanpool-vehicle .vehicle-details .vanpool-details .stats .contracted.distance img {
        max-width: 55px; }
    .vanpool-vehicle .vehicle-details .download-card {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      padding: 8px 15px;
      font-size: 0.875rem; }
      .vanpool-vehicle .vehicle-details .download-card p {
        font-size: 0.75rem;
        margin: 0;
        padding: 0;
        color: #484848;
        width: 60%;
        font-style: italic; }
      .vanpool-vehicle .vehicle-details .download-card img {
        height: 32px;
        margin-right: 20px; }
      .vanpool-vehicle .vehicle-details .download-card a {
        text-decoration: underline;
        font-weight: 700; }
    .vanpool-vehicle .vehicle-details .odometer svg {
      color: #FFFFFF; }
    .vanpool-vehicle .vehicle-details .odometer, .vanpool-vehicle .vehicle-details .maintenance {
      background-color: #b3b3b3;
      border-radius: 4px;
      margin-bottom: 10px;
      color: #FFFFFF;
      padding: 5px 10px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .vanpool-vehicle .vehicle-details .odometer .title, .vanpool-vehicle .vehicle-details .maintenance .title {
        text-align: center; }
        .vanpool-vehicle .vehicle-details .odometer .title .text, .vanpool-vehicle .vehicle-details .maintenance .title .text {
          font-weight: 600; }
      .vanpool-vehicle .vehicle-details .odometer .est-mileage, .vanpool-vehicle .vehicle-details .maintenance .est-mileage {
        font-size: 36px; }
      .vanpool-vehicle .vehicle-details .odometer .mi, .vanpool-vehicle .vehicle-details .maintenance .mi {
        position: relative;
        font-size: 0.875rem;
        color: gray;
        padding-top: 15px;
        padding-left: 3px; }
      .vanpool-vehicle .vehicle-details .odometer .inline-editor .odometer-tooltip, .vanpool-vehicle .vehicle-details .maintenance .inline-editor .odometer-tooltip {
        margin-bottom: 0; }
      .vanpool-vehicle .vehicle-details .odometer .inline-editor.editing .mi, .vanpool-vehicle .vehicle-details .maintenance .inline-editor.editing .mi {
        position: absolute;
        top: -3px;
        right: -21px; }
      .vanpool-vehicle .vehicle-details .odometer .updated, .vanpool-vehicle .vehicle-details .maintenance .updated {
        color: gray;
        font-style: italic;
        font-size: 0.75rem; }
    .vanpool-vehicle .vehicle-details .maintenance {
      background-color: #e6e6e6;
      color: #333333;
      padding: 5px 15px 5px 10px; }
      .vanpool-vehicle .vehicle-details .maintenance.past-due {
        background: #c01515;
        color: #FFFFFF; }
        .vanpool-vehicle .vehicle-details .maintenance.past-due .next, .vanpool-vehicle .vehicle-details .maintenance.past-due .updated, .vanpool-vehicle .vehicle-details .maintenance.past-due .next .date, .vanpool-vehicle .vehicle-details .maintenance.past-due .next .miles {
          color: #FFFFFF; }
      .vanpool-vehicle .vehicle-details .maintenance .next {
        color: gray;
        font-weight: 600;
        font-size: 0.875rem; }
        .vanpool-vehicle .vehicle-details .maintenance .next .date, .vanpool-vehicle .vehicle-details .maintenance .next .miles {
          font-size: 1.25rem;
          color: #333333; }
        .vanpool-vehicle .vehicle-details .maintenance .next .date {
          margin-right: 6px; }
        .vanpool-vehicle .vehicle-details .maintenance .next .miles {
          margin-left: 6px;
          margin-right: 2px; }
    .vanpool-vehicle .vehicle-details .service-provider {
      margin-top: 15px;
      padding: 0 0 70px 15px;
      position: relative;
      margin-bottom: 1rem; }
      .vanpool-vehicle .vehicle-details .service-provider h3 {
        font-size: 1rem; }
      .vanpool-vehicle .vehicle-details .service-provider .google-map-image {
        display: none; }
      .vanpool-vehicle .vehicle-details .service-provider .info {
        max-width: 100%; }
        .vanpool-vehicle .vehicle-details .service-provider .info .name {
          font-size: 1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 3px; }
        .vanpool-vehicle .vehicle-details .service-provider .info .addr {
          width: 60%;
          color: gray;
          font-size: 0.875rem;
          line-height: 18px; }
        .vanpool-vehicle .vehicle-details .service-provider .info .phone {
          margin-top: 3px;
          font-size: 0.875rem;
          color: gray; }
          .vanpool-vehicle .vehicle-details .service-provider .info .phone svg {
            font-size: 1.25rem;
            margin-right: 3px;
            color: #049b5c; }
      .vanpool-vehicle .vehicle-details .service-provider .no-provider {
        font-style: italic;
        display: inline-block; }
      .vanpool-vehicle .vehicle-details .service-provider .button {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%; }
  .vanpool-vehicle .service-provider-search .google-maps {
    height: 30vh; }
  .vanpool-vehicle .service-provider-search .search {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin: 20px 0; }
    .vanpool-vehicle .service-provider-search .search .found {
      font-size: 1.125rem;
      font-weight: 600; }
    .vanpool-vehicle .service-provider-search .search .form {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      font-size: 1rem; }
      .vanpool-vehicle .service-provider-search .search .form input {
        padding: 4px 10px;
        border: 1px solid #CCCCCC; }
      .vanpool-vehicle .service-provider-search .search .form input, .vanpool-vehicle .service-provider-search .search .form .select, .vanpool-vehicle .service-provider-search .search .form .button {
        margin: 10px 0; }
      .vanpool-vehicle .service-provider-search .search .form .button {
        height: 35px;
        padding-top: 2px; }
        .vanpool-vehicle .service-provider-search .search .form .button:disabled {
          background: #b3b3b3;
          border-color: #b3b3b3;
          color: #FFFFFF;
          cursor: not-allowed; }
  .vanpool-vehicle .service-provider-search .results {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin: -10px;
    margin-bottom: 20px; }
    .vanpool-vehicle .service-provider-search .results .location {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      height: 115px;
      min-width: 300px;
      border: 1px solid #CCCCCC;
      margin: 10px;
      cursor: pointer; }
      .vanpool-vehicle .service-provider-search .results .location:before {
        content: attr(label);
        border-radius: 12px;
        background: #049b5c;
        color: #FFFFFF;
        display: inline-block;
        height: 24px;
        width: 24px;
        text-align: center;
        margin: 10px;
        margin-bottom: 0; }
      .vanpool-vehicle .service-provider-search .results .location.selected {
        background: #e6e6e6; }
      .vanpool-vehicle .service-provider-search .results .location .name {
        font-weight: 600;
        font-size: 1rem;
        display: inline-block;
        width: 250px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        top: 6px; }
      .vanpool-vehicle .service-provider-search .results .location .address {
        display: inline-block;
        font-size: 0.875rem;
        color: gray;
        margin-left: 44px; }
      .vanpool-vehicle .service-provider-search .results .location .phone {
        display: inline-block;
        font-size: 1rem;
        color: gray;
        margin-left: 44px; }
        .vanpool-vehicle .service-provider-search .results .location .phone svg {
          font-size: 1.125rem;
          margin-right: 5px;
          color: #049b5c; }

@media (min-width: 768px) {
  .vanpool-vehicle {
    padding-top: 120px; }
  .stats .rent, .stats .contracted.distance {
    margin-bottom: 1rem; } }

@media (min-width: 1024px) {
  .vanpool-vehicle {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .vanpool-vehicle .vehicle-model {
      -webkit-box-ordinal-group: 0;
      -moz-box-ordinal-group: 0;
      -ms-flex-order: 0;
      -webkit-order: 0;
      order: 0;
      padding: 1rem; }
    .vanpool-vehicle .vehicle-details {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1; }
      .vanpool-vehicle .vehicle-details .add-ons .add-on {
        min-height: 5rem; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on header {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
          -moz-justify-content: space-between;
          -ms-justify-content: space-between;
          justify-content: space-between;
          -ms-flex-pack: space-between; }
          .vanpool-vehicle .vehicle-details .add-ons .add-on header .add {
            -webkit-align-items: start;
            -moz-align-items: start;
            -ms-align-items: start;
            align-items: start; }
          .vanpool-vehicle .vehicle-details .add-ons .add-on header .price {
            margin-right: 1rem; }
          .vanpool-vehicle .vehicle-details .add-ons .add-on header .column {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row; }
          .vanpool-vehicle .vehicle-details .add-ons .add-on header .description {
            margin-top: 0.5rem;
            margin-left: 3rem;
            margin-right: -12rem; }
            .vanpool-vehicle .vehicle-details .add-ons .add-on header .description.no-add {
              margin-right: -8rem; }
        .vanpool-vehicle .vehicle-details .add-ons .add-on p {
          margin: 0;
          padding: 0; }
      .vanpool-vehicle .vehicle-details .vanpool-details .stats {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; }
        .vanpool-vehicle .vehicle-details .vanpool-details .stats .seats, .vanpool-vehicle .vehicle-details .vanpool-details .stats .occupancy, .vanpool-vehicle .vehicle-details .vanpool-details .stats .contracted.distance, .vanpool-vehicle .vehicle-details .vanpool-details .stats .rent {
          margin-bottom: 0;
          min-width: 9rem; }
      .vanpool-vehicle .vehicle-details .odometer, .vanpool-vehicle .vehicle-details .maintenance {
        height: 64px;
        min-width: 500px;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; }
        .vanpool-vehicle .vehicle-details .odometer .title, .vanpool-vehicle .vehicle-details .maintenance .title {
          text-align: left; }
        .vanpool-vehicle .vehicle-details .odometer .inline-editor.editing, .vanpool-vehicle .vehicle-details .maintenance .inline-editor.editing {
          left: -20px; }
      .vanpool-vehicle .vehicle-details .service-provider .google-map-image {
        display: block;
        min-height: 90px;
        min-width: 140px;
        height: 90px;
        width: 140px;
        margin-right: 15px;
        background-position: center; }
        .vanpool-vehicle .vehicle-details .service-provider .google-map-image:not([style]) {
          border: 1px dashed #CCCCCC;
          position: relative; }
          .vanpool-vehicle .vehicle-details .service-provider .google-map-image:not([style]):before {
            content: 'No Image Found';
            position: absolute;
            margin: auto;
            width: 110px;
            top: 30px;
            font-size: 0.875rem;
            left: 0;
            right: 0; }
      .vanpool-vehicle .vehicle-details .service-provider .button {
        position: absolute;
        right: 0;
        left: auto;
        bottom: auto;
        width: auto; }
    .vanpool-vehicle .service-provider-search .search {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .vanpool-vehicle .service-provider-search .search .form {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; }
        .vanpool-vehicle .service-provider-search .search .form input, .vanpool-vehicle .service-provider-search .search .form .select, .vanpool-vehicle .service-provider-search .search .form .button {
          margin: 0; }
        .vanpool-vehicle .service-provider-search .search .form input, .vanpool-vehicle .service-provider-search .search .form .select {
          width: 120px;
          margin-right: 10px; }
      .vanpool-vehicle .service-provider-search .search .found {
        font-size: 1.25rem; }
    .vanpool-vehicle .service-provider-search .results {
      max-height: 50vh;
      overflow: auto; } }

/*	COLORS
–––––––––––––––––––––––––––*/
.profile {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  padding-top: 60px; }
  .profile .generated-user-image {
    display: inline-block;
    font-size: 1em;
    width: 2em;
    min-width: 2em;
    height: 2em;
    min-width: 2em;
    color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 1em;
    text-align: center;
    padding-top: 0.15em;
    border: 0.1em solid #FFFFFF;
    background-color: #049b5c; }
    .profile .generated-user-image img {
      width: 43px;
      height: 43px;
      border-radius: 50%; }
  .profile > .sidebar {
    display: none; }
  .profile > .body {
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 724px;
    padding: 1rem; }
    .profile > .body .profile-info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      position: relative;
      padding-bottom: 2rem; }
      .profile > .body .profile-info .profile-image {
        display: none;
        font-size: 3rem; }
      .profile > .body .profile-info .edit {
        cursor: pointer;
        position: absolute;
        right: 0.5rem;
        top: 0;
        padding: 0;
        font-size: 1.125rem;
        color: #049b5c; }
      .profile > .body .profile-info .details {
        padding-left: 0.5rem; }
        .profile > .body .profile-info .details .name {
          font-size: 1.5rem;
          margin: 1rem 0; }
        .profile > .body .profile-info .details .home-address, .profile > .body .profile-info .details .contact-address {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex; }
          .profile > .body .profile-info .details .home-address p, .profile > .body .profile-info .details .contact-address p {
            margin: 0;
            padding: 0; }
            .profile > .body .profile-info .details .home-address p .line2:first-child, .profile > .body .profile-info .details .contact-address p .line2:first-child {
              font-weight: 700; }
        .profile > .body .profile-info .details .field {
          margin: 1rem 0;
          font-size: 0.875rem; }
          .profile > .body .profile-info .details .field.phone .line2 {
            padding-left: 2.5rem; }
          .profile > .body .profile-info .details .field svg {
            color: #049b5c;
            font-size: 1.5rem;
            margin-right: 1rem; }
          .profile > .body .profile-info .details .field i {
            font-style: italic;
            font-weight: 300;
            color: #484848;
            margin-left: 0.5rem;
            font-size: 0.875rem; }
          .profile > .body .profile-info .details .field .line2 {
            display: block; }
      .profile > .body .profile-info .tools {
        width: 100%;
        position: absolute;
        bottom: -1rem;
        right: 0; }
    .profile > .body .section {
      border-radius: 0.25rem;
      box-shadow: 0 2px 3px 0 rgba(63, 72, 82, 0.2);
      border: 1px solid #f2f2f2;
      background: #FFFFFF;
      padding: 0.25rem;
      margin: 1rem -0.5rem; }
      .profile > .body .section .head {
        border-bottom: 1px solid #f2f2f2;
        position: relative; }
        .profile > .body .section .head svg {
          color: #049b5c;
          font-size: 1.125rem;
          margin: 0 0.5rem;
          width: 1.5rem; }
        .profile > .body .section .head h3 {
          text-transform: uppercase;
          margin-top: 0;
          display: inline-block;
          position: relative;
          top: 2px; }
        .profile > .body .section .head .edit {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          padding: 0; }
      .profile > .body .section .body {
        margin-left: 0.5rem; }
        .profile > .body .section .body .note {
          font-size: 0.75rem;
          font-style: italic;
          padding-top: 1rem; }
        .profile > .body .section .body .entry {
          margin: 1rem 0; }
          .profile > .body .section .body .entry .label {
            text-transform: uppercase;
            color: #484848;
            font-weight: 600;
            font-size: 0.75rem; }
          .profile > .body .section .body .entry .value {
            font-weight: 600; }
    .profile > .body .azure-settings .section .body {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0; }
      .profile > .body .azure-settings .section .body .button {
        margin: 1rem;
        width: 42.5%;
        min-width: 14rem;
        font-weight: 600; }
    .profile > .body .commute-info .not-employer, .profile > .body .driver-info .not-employer {
      font-size: 0.875rem;
      text-decoration: underline; }
      .profile > .body .commute-info .not-employer:hover, .profile > .body .driver-info .not-employer:hover {
        color: #03693e; }
    .profile > .body .commute-info .edit-employer, .profile > .body .commute-info .edit-license, .profile > .body .driver-info .edit-employer, .profile > .body .driver-info .edit-license {
      margin-top: 1rem; }
      .profile > .body .commute-info .edit-employer.invalid:not(:focus), .profile > .body .commute-info .edit-license.invalid:not(:focus), .profile > .body .driver-info .edit-employer.invalid:not(:focus), .profile > .body .driver-info .edit-license.invalid:not(:focus) {
        border-color: #c01515; }
        .profile > .body .commute-info .edit-employer.invalid:not(:focus) + .label, .profile > .body .commute-info .edit-license.invalid:not(:focus) + .label, .profile > .body .driver-info .edit-employer.invalid:not(:focus) + .label, .profile > .body .driver-info .edit-license.invalid:not(:focus) + .label {
          color: #c01515; }
    .profile > .body .commute-info .employer-invalid, .profile > .body .commute-info .license-invalid, .profile > .body .commute-info .invalid-address, .profile > .body .driver-info .employer-invalid, .profile > .body .driver-info .license-invalid, .profile > .body .driver-info .invalid-address {
      position: relative;
      display: block;
      left: 0.5rem;
      top: -0.5rem;
      font-size: 0.75rem;
      color: #c01515;
      height: 0; }
    .profile > .body .commute-info .invalid-address-auto, .profile > .body .driver-info .invalid-address-auto {
      border-color: #c01515; }
    .profile > .body .commute-info.edit input, .profile > .body .driver-info.edit input {
      width: 100%; }
    .profile > .body .commute-info.edit label, .profile > .body .driver-info.edit label {
      display: block;
      font-size: 0.875rem;
      margin: 1rem 0 .25rem; }
      .profile > .body .commute-info.edit label.attach, .profile > .body .driver-info.edit label.attach {
        font-size: 0.75rem;
        background: #FFFFFF;
        display: inline-block;
        position: relative;
        top: 1rem;
        left: 0.5rem;
        z-index: 10;
        padding: 0.125rem;
        color: #049b5c;
        margin-top: 0rem; }
    .profile > .body .commute-info.edit .days-editor, .profile > .body .driver-info.edit .days-editor {
      margin-left: -0.5rem; }
    .profile > .body .commute-info.edit .select, .profile > .body .driver-info.edit .select {
      width: 10rem;
      margin-bottom: 1rem; }
    .profile > .body .commute-info.edit .save, .profile > .body .driver-info.edit .save {
      font-weight: 800; }
    .profile > .body .commute-info .employer, .profile > .body .driver-info .employer {
      font-size: 1.125rem;
      font-weight: 600; }
      .profile > .body .commute-info .employer:first-child, .profile > .body .driver-info .employer:first-child {
        margin-top: 1rem; }
    .profile > .body .commute-info .schedule, .profile > .body .driver-info .schedule {
      text-transform: uppercase; }
    .profile > .body .driver-info.edit .select, .profile > .body .driver-info.edit input {
      width: 15rem; }
    .profile > .body .driver-info.edit label[for="stateDropDown"] {
      width: 2rem;
      display: block; }
    .profile > .body .vanpool-info .name {
      font-size: 1.125rem;
      font-weight: 600;
      margin-top: 1rem; }
    .profile > .body .vanpool-info .leave {
      padding: 0.5rem 2rem; }
    .profile > .body .vanpool-info .no-vanpool {
      font-weight: 600;
      margin: 1rem 0;
      font-size: 0.875rem; }
    .profile > .body .vanpool-info .more-info {
      padding-bottom: 1rem; }
    .profile > .body .vanpool-info button {
      margin-bottom: 1rem; }
    .profile > .body .vanpool-info .request-result {
      font-weight: 600;
      padding-bottom: 1rem; }
      .profile > .body .vanpool-info .request-result span {
        font-weight: 400; }
    .profile > .body .vanpool-info .search {
      font-size: 0.875rem;
      margin: 1rem 0; }
      .profile > .body .vanpool-info .search .result {
        font-size: 1.125rem;
        font-weight: 600;
        color: #049b5c; }
    .profile > .body .preferences .preference {
      margin-top: -1.5rem;
      margin-right: 0.5rem; }
      .profile > .body .preferences .preference .note {
        padding-top: 0;
        max-width: 400px;
        position: relative;
        top: -.25rem; }
    .profile > .body .driver-info .apply p {
      font-weight: 600;
      font-size: 0.875rem;
      margin: 1rem 0; }
    .profile > .body .driver-info .apply .button {
      margin-bottom: 1rem; }
    .profile > .body .add-card {
      margin: .5rem 0 1rem; }
      .profile > .body .add-card svg {
        font-size: 30px;
        position: relative;
        top: -1px; }
    .profile > .body .payment-info .radio:before {
      display: none; }
    .profile > .body .payment-info .radio .save-card {
      display: none; }
    .profile > .body .payment-info .radio .card-type input {
      margin-right: .5rem; }
    .profile > .body .payment-info .section .body .add-card {
      height: 5rem;
      width: 30rem;
      max-width: 100%;
      border-radius: 0.25rem;
      border: 1px solid #e6e6e6;
      padding: 1rem;
      margin: 0 0 .5rem;
      background: #FFFFFF;
      cursor: pointer; }
      .profile > .body .payment-info .section .body .add-card:disabled {
        color: #e6e6e6;
        cursor: default; }

.card {
  height: 5rem;
  width: 30rem;
  max-width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #e6e6e6;
  padding: 1rem;
  margin: 1rem -0.25rem;
  background: #FFFFFF;
  position: relative;
  transition: all .25s ease;
  overflow: hidden; }
  .card .card-info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .card.editing {
    height: 18.25rem; }
    .card.editing .card-info {
      margin-bottom: 1rem; }
    .card.editing .card-editor .cvc .card-cvc {
      width: 60px;
      margin-right: .25rem; }
      .card.editing .card-editor .cvc .card-cvc.changed {
        border-color: #049b5c;
        box-shadow: inset 0 0 0 1px #049b5c;
        background: #e1f0ea; }
    .card.editing .card-editor .row {
      margin-bottom: .5rem;
      padding-left: 4.5rem;
      position: relative; }
      .card.editing .card-editor .row.expiration-date {
        margin-bottom: 1rem; }
      .card.editing .card-editor .row .select {
        margin-right: .5rem; }
        .card.editing .card-editor .row .select select.invalid {
          border-color: #c01515; }
        .card.editing .card-editor .row .select.changed {
          border-color: #049b5c;
          box-shadow: inset 0 0 0 1px #049b5c;
          background: #e1f0ea; }
      .card.editing .card-editor .row svg {
        position: absolute;
        top: 0.75rem;
        left: 14rem;
        color: #c01515; }
    .card.editing .card-editor .zip-input input {
      width: 6.25rem; }
    .card.editing .card-editor .zip-input .changed {
      border-color: #049b5c;
      box-shadow: inset 0 0 0 1px #049b5c;
      background: #e1f0ea; }
    .card.editing .card-editor .tools {
      margin: 0 0; }
    .card.editing .card-editor .text-buttons {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
      .card.editing .card-editor .text-buttons .cancel {
        margin-right: 3px; }
      .card.editing .card-editor .text-buttons .save.disabled {
        color: lightgray; }
  .card svg {
    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer; }
  .card .logo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    -ms-flex-pack: space-around;
    margin-right: .5rem; }
    .card .logo img {
      max-height: 2.5rem;
      min-height: 2.5rem;
      min-width: 65px; }
  .card .details {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .card .details .account {
      line-height: 1rem;
      font-size: 1rem; }
    .card .details .exp {
      font-size: 0.875rem;
      color: #484848;
      margin-top: 0.5rem; }
      .card .details .exp.expired {
        color: #c01515; }
        .card .details .exp.expired svg {
          position: relative;
          top: -1px;
          left: 5px; }

@media (min-width: 768px) {
  .card {
    margin-left: 0; }
  .profile {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-top: 70px; }
    .profile.no-trip {
      padding-top: 4rem; }
    .profile:before {
      content: '';
      display: block;
      width: 300px; }
    .profile > .sidebar {
      display: block;
      width: 300px;
      border-right: 1px solid #f2f2f2;
      margin-top: 1rem;
      padding-top: 1rem;
      position: fixed;
      left: calc((100vw - 1024px) / 2);
      opacity: 1;
      transition: opacity 0.3s linear; }
      .profile > .sidebar .scroll-link {
        cursor: pointer;
        margin: 0 1rem;
        padding: 0.5rem;
        border-bottom: 1px solid #f2f2f2;
        font-weight: 600;
        color: #333333; }
        .profile > .sidebar .scroll-link svg {
          font-size: 1.25rem;
          margin-right: 0.5rem;
          color: gray; }
        .profile > .sidebar .scroll-link.active {
          background: #f2f2f2; }
          .profile > .sidebar .scroll-link.active svg {
            color: #049b5c; }
    .profile > .body {
      padding-left: 6rem; }
      .profile > .body:after {
        content: ' ';
        display: block;
        height: 20vh; }
      .profile > .body .profile-info {
        margin: 1rem 0;
        margin-left: -5rem; }
        .profile > .body .profile-info .profile-image {
          display: block; }
        .profile > .body .profile-info .edit {
          font-size: 1.25rem;
          right: 1.5rem; }
        .profile > .body .profile-info .details {
          max-width: 30rem; }
          .profile > .body .profile-info .details .name {
            font-size: 2.5rem; }
      .profile > .body .section {
        padding: 0.5rem;
        margin: 2rem 0; }
        .profile > .body .section .head svg {
          color: #049b5c;
          font-size: 1.25rem;
          margin: 0 1rem;
          width: 1.5rem; }
        .profile > .body .section .body {
          margin-left: 3.5rem;
          margin-right: 1.5rem; }
          .profile > .body .section .body .entry {
            margin: 1.5rem 0; }
          .profile > .body .section .body button {
            margin-bottom: 1rem; }
      .profile > .body .azure-settings .section .body {
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between; } }

@media (max-width: 1000px) {
  .profile > .sidebar {
    opacity: 0; } }

/* Vanpool Route ---------------------
------------------------------ */
.vanpool-route {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  padding-top: 60px;
  min-height: 100% !important;
  height: 100%; }
  .vanpool-route .IE11Warning {
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1rem 2rem;
    background: #f6db00;
    color: #333333;
    margin: 80px 0px 80px 0px;
    position: fixed;
    left: 400px;
    right: 30px; }
    .vanpool-route .IE11Warning p {
      margin-top: 0;
      margin-bottom: 0.3em;
      font-weight: 700;
      font-size: 1rem; }
  .vanpool-route .google-map {
    height: 180px; }
  .vanpool-route .vanpool-route-details {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    background-color: #FFFFFF;
    padding: 16px; }
    .vanpool-route .vanpool-route-details .route-date-time-container {
      border-bottom: 1px solid #D5D5D5;
      padding-bottom: 16px; }
      .vanpool-route .vanpool-route-details .route-date-time-container .route-date {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        padding: 12px 0px 8px 0px;
        text-transform: capitalize; }
      .vanpool-route .vanpool-route-details .route-date-time-container .route-details {
        display: flex; }
        .vanpool-route .vanpool-route-details .route-date-time-container .route-details .route-time {
          margin-left: 5px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
          .vanpool-route .vanpool-route-details .route-date-time-container .route-details .route-time .time {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
    .vanpool-route .vanpool-route-details .roster-container {
      border-bottom: 1px solid #D5D5D5;
      padding-bottom: 20px; }
      .vanpool-route .vanpool-route-details .roster-container .heading {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
    .vanpool-route .vanpool-route-details .tabs {
      margin-bottom: 1.5rem; }
    .vanpool-route .vanpool-route-details .note {
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 600;
      color: #4A4A4A;
      margin: 0px 24px; }
    .vanpool-route .vanpool-route-details .stops {
      position: relative; }
      .vanpool-route .vanpool-route-details .stops .right {
        color: #049b5c;
        position: absolute;
        top: -1rem;
        right: 1rem; }
        .vanpool-route .vanpool-route-details .stops .right svg {
          color: #049b5c;
          cursor: pointer; }
    .vanpool-route .vanpool-route-details .days {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      margin: 10px 24px 20px 24px;
      font-weight: 700; }
      .vanpool-route .vanpool-route-details .days .column {
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1; }
        .vanpool-route .vanpool-route-details .days .column .num-stops {
          font-weight: 400;
          font-size: 0.75rem;
          display: none; }
      .vanpool-route .vanpool-route-details .days svg {
        color: #049b5c;
        cursor: pointer; }
      .vanpool-route .vanpool-route-details .days .route-days {
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1; }
    .vanpool-route .vanpool-route-details .analytics {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      border-top: solid 1px #CCCCCC;
      padding: 25px; }
      .vanpool-route .vanpool-route-details .analytics ul li {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        margin-bottom: 12px; }
        .vanpool-route .vanpool-route-details .analytics ul li span {
          font-size: 0.875rem;
          color: gray; }
          .vanpool-route .vanpool-route-details .analytics ul li span:last-child {
            font-weight: 700; }
          .vanpool-route .vanpool-route-details .analytics ul li span svg {
            font-size: 1.25rem;
            position: relative;
            top: -2px;
            margin-right: 5px; }
    .vanpool-route .vanpool-route-details .footer-container {
      display: flex;
      justify-content: space-between;
      font-size: 0.875rem; }
      .vanpool-route .vanpool-route-details .footer-container .wait-time-container {
        display: flex;
        color: #b3b3b3;
        font-size: 0.875rem; }
        .vanpool-route .vanpool-route-details .footer-container .wait-time-container svg {
          position: relative;
          top: 3px;
          margin-right: 2px; }
        .vanpool-route .vanpool-route-details .footer-container .wait-time-container .wait-time {
          font-style: italic; }
      .vanpool-route .vanpool-route-details .footer-container .participants {
        color: #484848;
        display: flex;
        justify-content: center;
        font-size: 0.875rem;
        min-width: 50px; }
        .vanpool-route .vanpool-route-details .footer-container .participants .user-icon {
          padding-right: 5px;
          position: relative;
          top: -1px; }
    .vanpool-route .vanpool-route-details .route .route-stop {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-bottom: 0.75rem;
      position: relative; }
      .vanpool-route .vanpool-route-details .route .route-stop .column {
        padding: 0 1rem 0 0.6rem;
        width: 100%; }
        .vanpool-route .vanpool-route-details .route .route-stop .column .stop-desc {
          font-weight: 700;
          font-size: 0.875rem;
          text-transform: capitalize;
          line-height: 16px; }
        .vanpool-route .vanpool-route-details .route .route-stop .column .stop-addr {
          overflow: hidden;
          font-size: 0.875rem;
          line-height: 17px;
          max-height: 53px;
          color: gray; }
      .vanpool-route .vanpool-route-details .route .route-stop .stop-time {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
        -ms-flex-pack: flex-start;
        width: 9rem; }
        .vanpool-route .vanpool-route-details .route .route-stop .stop-time:after {
          display: block;
          content: '';
          position: absolute;
          width: 1px;
          border-right: solid 0.25rem #049b5c;
          top: 5px;
          left: 83px;
          bottom: -1rem; }
        .vanpool-route .vanpool-route-details .route .route-stop .stop-time .clock {
          justify-content: center;
          height: 1.5rem;
          width: 4rem;
          text-align: right;
          position: relative; }
          .vanpool-route .vanpool-route-details .route .route-stop .stop-time .clock:before {
            content: '';
            border-radius: 50%;
            background-color: #049b5c;
            width: 25px;
            height: 25px;
            position: absolute;
            left: 69px;
            top: -4px;
            z-index: 10;
            border: solid 3px #FFFFFF;
            box-sizing: content-box; }
          .vanpool-route .vanpool-route-details .route .route-stop .stop-time .clock:after {
            content: attr(label);
            position: absolute;
            top: 0;
            left: 78px;
            color: #FFFFFF;
            width: 13px;
            text-align: center;
            z-index: 20; }
          .vanpool-route .vanpool-route-details .route .route-stop .stop-time .clock span {
            font-size: 0.6875rem;
            position: relative;
            left: -2px; }
      .vanpool-route .vanpool-route-details .route .route-stop:last-child .stop-time:after {
        display: none; }
      .vanpool-route .vanpool-route-details .route .route-stop:last-child .stop-time .clock:before {
        border-radius: 50% 50% 50% 0;
        transform: rotate(-45deg); }

@media (max-width: 768px) {
  .vanpool-details .google-map {
    height: 12rem; } }

@media (min-width: 768px) {
  .vanpool-route {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0px 30px; }
    .vanpool-route .IE11Warning {
      border-radius: 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      padding: 1rem 2rem;
      background: #f6db00;
      color: #333333;
      margin: 80px 0px 80px 0px;
      position: fixed;
      left: 400px;
      right: 30px; }
      .vanpool-route .IE11Warning p {
        margin-top: 0;
        margin-bottom: 0.3em;
        font-weight: 700;
        font-size: 1rem; }
    .vanpool-route .google-map {
      position: absolute;
      left: 376px;
      right: 0;
      box-sizing: border-box;
      height: calc(100% - 70px);
      margin-right: 20px; }
    .vanpool-route .vanpool-route-details {
      -webkit-box-ordinal-group: 0;
      -moz-box-ordinal-group: 0;
      -ms-flex-order: 0;
      -webkit-order: 0;
      order: 0;
      position: relative;
      z-index: 2;
      width: 380px;
      height: 92vh; }
      .vanpool-route .vanpool-route-details .route {
        padding: 1rem 0 1rem 1rem; }
      .vanpool-route .vanpool-route-details .stops-main-container {
        max-height: 80%; } }

/* Edit Vanpool Route ---------------
  ------------------------------ */
.vanpool-route-details {
  background-color: #FFFFFF; }
  .vanpool-route-details h3 {
    font-weight: 600;
    color: #333333;
    font-size: 0.875rem;
    margin-top: 0;
    padding-top: 0; }
  .vanpool-route-details .route-header {
    background-color: #e6e6e6;
    padding: 5px 0 5px 20px;
    margin: 10px 0;
    color: #049b5c; }
  .vanpool-route-details .add {
    display: block;
    cursor: pointer;
    margin: 0 0 2.5rem 1.25rem; }
    .vanpool-route-details .add svg {
      position: relative;
      top: -1px;
      font-size: 1.125rem;
      margin-right: 0.5rem; }
  .vanpool-route-details .route-editor {
    padding: 0 1rem 135px;
    overflow: auto;
    position: relative; }
    .vanpool-route-details .route-editor .sticky-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #FFFFFF;
      width: 100%;
      padding: 1rem;
      border-top: 1px solid #CCCCCC;
      background-color: #049b5c; }
      .vanpool-route-details .route-editor .sticky-footer .form-buttons {
        padding: 0; }
        .vanpool-route-details .route-editor .sticky-footer .form-buttons input#save {
          background-color: #FFFFFF;
          border-color: #FFFFFF;
          color: #049b5c; }
        .vanpool-route-details .route-editor .sticky-footer .form-buttons input#cancel {
          background-color: #049b5c;
          border-color: #FFFFFF;
          color: #FFFFFF; }
        .vanpool-route-details .route-editor .sticky-footer .form-buttons input:hover {
          border-color: #CCCCCC !important; }
        .vanpool-route-details .route-editor .sticky-footer .form-buttons input:focus {
          box-shadow: 0 0 0.5rem 0 #FFFFFF;
          outline: none; }
      .vanpool-route-details .route-editor .sticky-footer .note {
        margin-bottom: 0;
        color: white;
        font-size: 10px; }
        .vanpool-route-details .route-editor .sticky-footer .note a {
          color: #FFFFFF; }
    .vanpool-route-details .route-editor .time {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      margin-top: 0;
      margin-bottom: 1em; }
      .vanpool-route-details .route-editor .time .time-label {
        font-size: 0.875rem;
        font-weight: 600;
        color: #b3b3b3;
        margin-right: 0.5rem;
        position: relative;
        top: -5px; }
      .vanpool-route-details .route-editor .time select {
        height: 28px; }
      .vanpool-route-details .route-editor .time .ant-time-picker.changed input {
        border-color: #049b5c;
        box-shadow: inset 0 0 0 1px #049b5c;
        background: #e1f0ea; }
    .vanpool-route-details .route-editor .rider-message {
      font-size: 0.875rem;
      color: #484848;
      font-style: italic; }
    .vanpool-route-details .route-editor .stop-editor {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 0.25rem 0;
      border-bottom: solid 1px #e6e6e6;
      margin: 0.25rem -1rem;
      background: #FFFFFF; }
      .vanpool-route-details .route-editor .stop-editor.active {
        border-radius: 0.25rem;
        box-shadow: 0.25rem 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.25); }
      .vanpool-route-details .route-editor .stop-editor .participants {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        color: #484848;
        display: flex;
        justify-content: center;
        font-size: 0.875rem;
        min-width: 3rem;
        margin-right: 2rem;
        position: relative;
        top: 2px; }
        .vanpool-route-details .route-editor .stop-editor .participants .user-icon {
          padding-right: 5px;
          position: relative;
          top: -1px; }
      .vanpool-route-details .route-editor .stop-editor .stop-editor-container {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex; }
        .vanpool-route-details .route-editor .stop-editor .stop-editor-container .editor {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-flex-grow: 1;
          -moz-flex-grow: 1;
          -ms-flex-grow: 1;
          flex-grow: 1; }
          .vanpool-route-details .route-editor .stop-editor .stop-editor-container .editor input {
            width: 100%;
            margin-bottom: 10px;
            font-size: 1rem;
            color: #333333;
            padding: 10px;
            border-radius: 0;
            border: solid 1px #CCCCCC;
            outline: none; }
            .vanpool-route-details .route-editor .stop-editor .stop-editor-container .editor input:last-child {
              margin-bottom: 0; }
            .vanpool-route-details .route-editor .stop-editor .stop-editor-container .editor input.validation-error {
              border-color: #049b5c;
              box-shadow: inset 0 0 0 1px #049b5c; }
            .vanpool-route-details .route-editor .stop-editor .stop-editor-container .editor input.new-item {
              border-color: #049b5c;
              box-shadow: inset 0 0 0 1px #049b5c; }
            .vanpool-route-details .route-editor .stop-editor .stop-editor-container .editor input.changed {
              border-color: #049b5c;
              box-shadow: inset 0 0 0 1px #049b5c;
              background: #e1f0ea; }
          .vanpool-route-details .route-editor .stop-editor .stop-editor-container .editor .invalid-address {
            display: none; }
        .vanpool-route-details .route-editor .stop-editor .stop-editor-container .delete,
        .vanpool-route-details .route-editor .stop-editor .stop-editor-container .drag-handle {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          -ms-flex-pack: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          min-width: 2rem; }
          .vanpool-route-details .route-editor .stop-editor .stop-editor-container .delete svg,
          .vanpool-route-details .route-editor .stop-editor .stop-editor-container .drag-handle svg {
            color: #CCCCCC;
            cursor: pointer; }
    .vanpool-route-details .route-editor .wait-time {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      padding-left: 2rem;
      padding-top: 3px;
      font-style: italic; }
      .vanpool-route-details .route-editor .wait-time .select {
        width: 100px;
        height: 1.5rem; }
        .vanpool-route-details .route-editor .wait-time .select select {
          border: 0;
          background: #FFFFFF;
          width: calc(100% - 14px);
          padding-right: 20px; }
        .vanpool-route-details .route-editor .wait-time .select svg {
          position: relative;
          top: -1px;
          margin-right: -3px; }
        .vanpool-route-details .route-editor .wait-time .select.changed {
          background: #e1f0ea; }
          .vanpool-route-details .route-editor .wait-time .select.changed select {
            background: transparent; }
    .vanpool-route-details .route-editor .add-route-point {
      color: #049b5c;
      font-size: 1.5rem;
      height: 53px;
      width: 180px;
      padding: 5px 12px;
      cursor: pointer;
      border-radius: 5px;
      background-color: white;
      transition: background-color 0.1s linear; }
      .vanpool-route-details .route-editor .add-route-point:hover {
        background-color: #e6e6e6; }
      .vanpool-route-details .route-editor .add-route-point span {
        font-size: 1rem;
        margin-left: 0.5rem;
        margin-top: 0.25rem; }
  .vanpool-route-details .days-editor .tools {
    margin: 0 1rem 0;
    padding: 0; }
    .vanpool-route-details .days-editor .tools .button.cancel {
      color: #b3b3b3; }
    .vanpool-route-details .days-editor .tools .button.save {
      margin: 0;
      font-weight: 600; }

@media (min-width: 768px) {
  .vanpool-route .vanpool-route-details .route-editor {
    position: static; }
    .vanpool-route .vanpool-route-details .route-editor .sticky-footer .note {
      font-size: 0.875rem; }
  .vanpool-route .vanpool-route-editor {
    width: 400px;
    position: relative;
    z-index: 2;
    height: 100%; } }

@media (min-width: 1350px) {
  .vanpool-route {
    margin-top: 100px; } }

/* Vanpool Roster ---------------
------------------------------ */
.vanpool-roster {
  position: relative; }
  .vanpool-roster .generated-user-image {
    display: inline-block;
    font-size: 1em;
    width: 2em;
    min-width: 2em;
    height: 2em;
    color: #049b5c;
    text-transform: uppercase;
    border-radius: 1em;
    text-align: center;
    padding-top: 0.175em;
    border: 0.05em solid #049b5c;
    background-color: #FFFFFF; }
  .vanpool-roster ul.participants {
    border-right: solid 1px #CCCCCC;
    margin: 0;
    overflow: auto;
    min-height: calc(100vh - 60px); }
    .vanpool-roster ul.participants li button {
      cursor: pointer;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      min-height: 60px;
      width: 100%;
      border-bottom: solid 1px #CCCCCC;
      padding: 5px 5px 5px 0; }
      .vanpool-roster ul.participants li button:hover {
        background-color: #f2f2f2; }
      .vanpool-roster ul.participants li button.active {
        background-color: #e6e6e6; }
      .vanpool-roster ul.participants li button .credentials {
        text-align: left; }
      .vanpool-roster ul.participants li button .generated-user-image {
        font-size: 1.125rem;
        margin: 0 1rem; }
      .vanpool-roster ul.participants li button .name {
        font-size: 1rem; }
      .vanpool-roster ul.participants li button .roles {
        font-size: 0.75rem;
        font-style: italic;
        color: #b3b3b3;
        margin-bottom: 5px; }
      .vanpool-roster ul.participants li button.invite {
        color: #049b5c; }
        .vanpool-roster ul.participants li button.invite svg {
          color: #049b5c;
          background-color: #FFFFFF;
          border-radius: 0;
          border: none;
          position: relative;
          top: -3px; }
      .vanpool-roster ul.participants li button.email {
        text-align: center;
        color: #FFFFFF;
        background-color: #049b5c; }
        .vanpool-roster ul.participants li button.email svg {
          color: #FFFFFF;
          font-size: 2.125rem;
          margin: 0 1rem; }
  .vanpool-roster .participant-profile {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 100%;
    width: 100%;
    overflow: auto;
    transition: left .5s ease;
    background-color: #FFFFFF;
    z-index: 1; }
    .vanpool-roster .participant-profile.active {
      left: 0; }
    .vanpool-roster .participant-profile .participant-contact {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      border-bottom: solid 2px #CCCCCC;
      padding: 1rem; }
      .vanpool-roster .participant-profile .participant-contact .profile-image {
        font-size: 1.5rem; }
      .vanpool-roster .participant-profile .participant-contact .profile-info {
        padding-left: 1rem; }
        .vanpool-roster .participant-profile .participant-contact .profile-info > svg {
          position: absolute;
          top: .5rem;
          right: 1rem;
          font-size: 1.75rem;
          color: #CCCCCC;
          cursor: pointer; }
        .vanpool-roster .participant-profile .participant-contact .profile-info .contact-details {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          margin: 1rem 0; }
          .vanpool-roster .participant-profile .participant-contact .profile-info .contact-details svg {
            margin-right: 1rem;
            color: #049b5c;
            font-size: 1.25rem; }
          .vanpool-roster .participant-profile .participant-contact .profile-info .contact-details .email {
            margin-top: 0.5rem; }
            .vanpool-roster .participant-profile .participant-contact .profile-info .contact-details .email svg {
              position: relative;
              top: -2px; }
      .vanpool-roster .participant-profile .participant-contact h2 {
        font-size: 1.25rem;
        margin-top: 0;
        margin-bottom: 0; }
      .vanpool-roster .participant-profile .participant-contact .roles {
        font-style: italic;
        color: #b3b3b3;
        font-size: 0.875rem; }
      .vanpool-roster .participant-profile .participant-contact .status {
        font-size: 0.875rem;
        font-weight: 600; }
        .vanpool-roster .participant-profile .participant-contact .status span {
          font-weight: 400;
          font-style: italic;
          color: #b3b3b3; }
    .vanpool-roster .participant-profile .tools {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      border-top: solid 2px #CCCCCC;
      margin: 1rem -2rem;
      padding: 0 1rem; }
      .vanpool-roster .participant-profile .tools .removeRider {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1; }
      .vanpool-roster .participant-profile .tools a {
        margin: 1rem; }
    .vanpool-roster .participant-profile .participant-route {
      padding: 0 1rem; }
      .vanpool-roster .participant-profile .participant-route label {
        font-style: italic;
        font-size: 0.875rem;
        color: #484848; }
      .vanpool-roster .participant-profile .participant-route .route-days {
        text-transform: capitalize;
        font-size: 0.875rem;
        color: gray;
        position: relative;
        margin: 25px 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between; }
        .vanpool-roster .participant-profile .participant-route .route-days .days-editor {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
          -moz-justify-content: space-between;
          -ms-justify-content: space-between;
          justify-content: space-between;
          -ms-flex-pack: space-between;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row; }
          .vanpool-roster .participant-profile .participant-route .route-days .days-editor .days {
            margin-left: -2rem; }
            .vanpool-roster .participant-profile .participant-route .route-days .days-editor .days .day-btn:disabled {
              color: #049b5c;
              border: none;
              background: #FFFFFF;
              border: solid 1px #05cd7a;
              cursor: default; }
        .vanpool-roster .participant-profile .participant-route .route-days .schedule-label {
          font-size: 0.875rem;
          color: #484848;
          margin-bottom: 0.5rem;
          font-weight: 600; }
        .vanpool-roster .participant-profile .participant-route .route-days svg {
          color: #CCCCCC;
          font-size: 1.25rem; }
          .vanpool-roster .participant-profile .participant-route .route-days svg.edit-icon {
            font-size: 1.125rem;
            cursor: pointer; }
          .vanpool-roster .participant-profile .participant-route .route-days svg.bus {
            color: #333333;
            margin-right: 10px; }
        .vanpool-roster .participant-profile .participant-route .route-days .day {
          font-weight: 600;
          color: #333333; }
          .vanpool-roster .participant-profile .participant-route .route-days .day:last-of-type {
            margin-right: auto; }
        .vanpool-roster .participant-profile .participant-route .route-days .day:not(:last-child):after {
          content: ', '; }
      .vanpool-roster .participant-profile .participant-route .days {
        display: inline-block;
        padding: 0;
        margin-top: 5px; }
      .vanpool-roster .participant-profile .participant-route .route-set {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column; }
        .vanpool-roster .participant-profile .participant-route .route-set h4 {
          font-size: 0.875rem;
          font-weight: 600;
          text-transform: capitalize;
          color: #484848; }
          .vanpool-roster .participant-profile .participant-route .route-set h4 svg {
            margin-right: 10px;
            font-size: 1.125rem;
            color: #333333; }
        .vanpool-roster .participant-profile .participant-route .route-set li {
          font-size: 0.875rem; }
        .vanpool-roster .participant-profile .participant-route .route-set .route-type {
          width: 100%;
          position: relative; }
          .vanpool-roster .participant-profile .participant-route .route-set .route-type ul li {
            margin-bottom: 20px;
            position: relative;
            padding-left: 32px; }
            .vanpool-roster .participant-profile .participant-route .route-set .route-type ul li .description {
              font-weight: 600; }
            .vanpool-roster .participant-profile .participant-route .route-set .route-type ul li .address {
              color: gray; }
            .vanpool-roster .participant-profile .participant-route .route-set .route-type ul li:before {
              display: block;
              content: '';
              width: 20px;
              height: 20px;
              background-color: #FFFFFF;
              border: solid 2px #333333;
              position: absolute;
              top: 6px;
              left: 0; }
            .vanpool-roster .participant-profile .participant-route .route-set .route-type ul li:first-child:after {
              display: block;
              content: '';
              position: absolute;
              width: 2px;
              border-right: dashed 3px #CCCCCC;
              top: 28px;
              left: 9px;
              bottom: -24px; }
            .vanpool-roster .participant-profile .participant-route .route-set .route-type ul li.source:before {
              border-radius: 50%; }
            .vanpool-roster .participant-profile .participant-route .route-set .route-type ul li.destination:before {
              background-color: #333333;
              border-radius: 50% 50% 50% 0;
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              transform: rotate(-45deg); }
          .vanpool-roster .participant-profile .participant-route .route-set .route-type.to-home svg {
            font-size: 1.25rem;
            position: relative;
            top: -2px; }
      .vanpool-roster .participant-profile .participant-route .slider-container {
        max-width: 300px; }
  .vanpool-roster .dashboard-user .participant-profile {
    top: 105px; }

@media (min-width: 768px) {
  .vanpool-roster {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    overflow: hidden;
    min-height: calc(100vh - 70px - 10rem - 1.5rem);
    margin-bottom: -1.5rem; }
    .vanpool-roster ul.participants {
      width: 32.5vw;
      max-width: 312px;
      height: calc(100vh - 70px - 10rem - 1rem);
      min-height: 40rem; }
      .vanpool-roster ul.participants li.active {
        background-color: #e6e6e6; }
    .vanpool-roster .participant-profile {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      position: relative;
      left: 0;
      top: 0;
      padding-left: calc(20vw - 15rem); }
      .vanpool-roster .participant-profile .tools {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between; }
        .vanpool-roster .participant-profile .tools .removeRider {
          -webkit-box-ordinal-group: 0;
          -moz-box-ordinal-group: 0;
          -ms-flex-order: 0;
          -webkit-order: 0;
          order: 0; }
      .vanpool-roster .participant-profile .participant-contact {
        border: none; }
        .vanpool-roster .participant-profile .participant-contact .profile-info {
          margin-top: 2rem;
          padding-left: 0; }
          .vanpool-roster .participant-profile .participant-contact .profile-info > svg {
            display: none; }
          .vanpool-roster .participant-profile .participant-contact .profile-info .contact-details {
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row; }
            .vanpool-roster .participant-profile .participant-contact .profile-info .contact-details .phone-num {
              margin-right: 40px; }
            .vanpool-roster .participant-profile .participant-contact .profile-info .contact-details .email {
              margin-top: 0; }
        .vanpool-roster .participant-profile .participant-contact:after {
          content: ' ';
          position: absolute;
          left: 0;
          right: 0;
          top: 13rem;
          height: 0.125rem;
          background: #CCCCCC; }
      .vanpool-roster .participant-profile .participant-route {
        padding: 0 2rem;
        max-width: 60rem; }
        .vanpool-roster .participant-profile .participant-route .route-days .days-editor .days {
          margin-left: 0; }
        .vanpool-roster .participant-profile .participant-route .route-set {
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          padding-left: 0; }
          .vanpool-roster .participant-profile .participant-route .route-set .route-type {
            width: 50%; }
            .vanpool-roster .participant-profile .participant-route .route-set .route-type:first-child {
              margin-right: 1rem; }
      .vanpool-roster .participant-profile .participant-contact .profile-image {
        display: block;
        width: 177px;
        height: 212px;
        font-size: 4rem;
        margin-top: 1.5rem; }
    .vanpool-roster .dashboard-user .participant-profile {
      top: 0; } }

@media (max-width: 1200px) {
  .vanpool-roster .participant-profile {
    padding-left: 0px; } }

/* Driver Application -----------------
--------------------------------------- */
.driver-application, .coordinator-application {
  padding: 60px 0.5rem 0.5rem;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1; }
  .driver-application h2, .coordinator-application h2 {
    margin-bottom: .5rem;
    font-weight: 600; }
  .driver-application h3 svg, .coordinator-application h3 svg {
    position: relative;
    top: -1px; }
  .driver-application p.note, .coordinator-application p.note {
    font-style: italic; }
  .driver-application .dob h3 .calendar, .coordinator-application .dob h3 .calendar {
    position: relative;
    top: -2px; }
  .driver-application .dob h3 .info, .coordinator-application .dob h3 .info {
    top: -5px;
    margin-left: 0; }
    .driver-application .dob h3 .info:hover .message, .coordinator-application .dob h3 .info:hover .message {
      top: calc(100% + 17px);
      left: -8.75rem; }
      .driver-application .dob h3 .info:hover .message:before, .coordinator-application .dob h3 .info:hover .message:before {
        left: 8.7rem; }
      .driver-application .dob h3 .info:hover .message:after, .coordinator-application .dob h3 .info:hover .message:after {
        left: 8.75rem; }
  .driver-application .dob .date-picker, .coordinator-application .dob .date-picker {
    margin-bottom: .5rem;
    position: relative; }
    .driver-application .dob .date-picker .invalid-date, .coordinator-application .dob .date-picker .invalid-date {
      color: #c01515;
      display: block;
      padding: 0.5rem 0;
      position: relative;
      right: 0; }
      .driver-application .dob .date-picker .invalid-date svg, .coordinator-application .dob .date-picker .invalid-date svg {
        position: relative;
        top: -2px; }
    .driver-application .dob .date-picker .date-input, .coordinator-application .dob .date-picker .date-input {
      width: 12rem; }
      .driver-application .dob .date-picker .date-input:focus + .invalid-date, .coordinator-application .dob .date-picker .date-input:focus + .invalid-date {
        display: none; }
      .driver-application .dob .date-picker .date-input.invalid:not(:focus), .coordinator-application .dob .date-picker .date-input.invalid:not(:focus) {
        border-color: #c01515; }
  .driver-application .driver-information .edit-license, .coordinator-application .driver-information .edit-license {
    width: 15rem; }
    .driver-application .driver-information .edit-license.invalid:not(:focus), .coordinator-application .driver-information .edit-license.invalid:not(:focus) {
      border-color: #c01515; }
      .driver-application .driver-information .edit-license.invalid:not(:focus) + .label, .coordinator-application .driver-information .edit-license.invalid:not(:focus) + .label {
        color: #c01515; }
  .driver-application .driver-information .license-invalid, .coordinator-application .driver-information .license-invalid {
    position: relative;
    display: block;
    left: 0.5rem;
    top: -0.5rem;
    font-size: 0.75rem;
    color: #c01515;
    height: 0; }
  .driver-application .driver-information .select, .coordinator-application .driver-information .select {
    margin-bottom: 1rem;
    max-width: 12rem; }
  .driver-application .driver-information .drivers-license, .coordinator-application .driver-information .drivers-license {
    margin-bottom: 2rem; }
    .driver-application .driver-information .drivers-license label, .coordinator-application .driver-information .drivers-license label {
      display: block;
      font-size: 0.875rem;
      margin: 1rem 0 .25rem; }
      .driver-application .driver-information .drivers-license label.attach, .coordinator-application .driver-information .drivers-license label.attach {
        font-size: 0.75rem;
        background: #FFFFFF;
        display: block;
        position: relative;
        top: 1rem;
        left: 0.5rem;
        z-index: 10;
        padding: 0.125rem;
        color: #049b5c;
        margin-top: 0rem;
        padding: 0.1rem 0.25rem;
        width: 2.25rem; }
  .driver-application .driver-information .question, .coordinator-application .driver-information .question {
    margin-bottom: 2rem; }
    .driver-application .driver-information .question .yes-no, .coordinator-application .driver-information .question .yes-no {
      margin-top: 10px; }
      .driver-application .driver-information .question .yes-no .pill, .coordinator-application .driver-information .question .yes-no .pill {
        border: solid 1px #049b5c;
        width: auto;
        margin-right: 1rem;
        font-size: 1.125rem; }
        .driver-application .driver-information .question .yes-no .pill.selected, .coordinator-application .driver-information .question .yes-no .pill.selected {
          background-color: #049b5c;
          color: #FFFFFF; }
  .driver-application .driver-information .pad-desk input[type=text], .coordinator-application .driver-information .pad-desk input[type=text] {
    text-transform: uppercase; }
    .driver-application .driver-information .pad-desk input[type=text]::-webkit-input-placeholder, .coordinator-application .driver-information .pad-desk input[type=text]::-webkit-input-placeholder {
      text-transform: capitalize; }
    .driver-application .driver-information .pad-desk input[type=text]::-moz-placeholder, .coordinator-application .driver-information .pad-desk input[type=text]::-moz-placeholder {
      text-transform: capitalize; }
    .driver-application .driver-information .pad-desk input[type=text]::-ms-input-placeholder, .coordinator-application .driver-information .pad-desk input[type=text]::-ms-input-placeholder {
      text-transform: capitalize; }
  .driver-application .driver-information .warning, .coordinator-application .driver-information .warning {
    position: relative;
    padding-left: 3rem;
    font-weight: 600;
    font-size: 0.875rem; }
    .driver-application .driver-information .warning svg, .coordinator-application .driver-information .warning svg {
      color: #c01515;
      font-size: 2rem;
      position: absolute;
      left: 0;
      top: 0.25rem; }
  .driver-application .driver-information .another-license-button, .coordinator-application .driver-information .another-license-button {
    font-size: 1.125rem;
    color: #049b5c;
    margin-bottom: 1rem; }
  .driver-application .driver-information .third-license, .coordinator-application .driver-information .third-license {
    border-top: 1px solid #CCCCCC;
    position: relative;
    padding-top: 1rem; }
    .driver-application .driver-information .third-license .remove, .coordinator-application .driver-information .third-license .remove {
      font-size: 1.125rem;
      position: absolute;
      right: 0;
      top: 0.25rem; }
  .driver-application .driver-information .second-license, .coordinator-application .driver-information .second-license {
    border: 1px solid #CCCCCC;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 2rem; }
  .driver-application .question, .coordinator-application .question {
    margin-bottom: 2rem; }
    .driver-application .question .yes-no, .coordinator-application .question .yes-no {
      margin-top: 10px; }
      .driver-application .question .yes-no .pill, .coordinator-application .question .yes-no .pill {
        border: solid 1px #049b5c;
        width: auto;
        margin-right: 1rem;
        font-size: 1.125rem; }
        .driver-application .question .yes-no .pill.selected, .coordinator-application .question .yes-no .pill.selected {
          background-color: #049b5c;
          color: #FFFFFF; }
  .driver-application .military-service .question, .coordinator-application .military-service .question {
    margin-bottom: 1.5rem; }
    .driver-application .military-service .question .yes-no, .coordinator-application .military-service .question .yes-no {
      margin-top: 10px; }
      .driver-application .military-service .question .yes-no .pill, .coordinator-application .military-service .question .yes-no .pill {
        border: solid 1px #049b5c;
        width: auto;
        margin-right: 1rem;
        font-size: 1.125rem; }
        .driver-application .military-service .question .yes-no .pill.selected, .coordinator-application .military-service .question .yes-no .pill.selected {
          background-color: #049b5c;
          color: #FFFFFF; }
  .driver-application .checkbox, .coordinator-application .checkbox {
    margin-left: .25rem;
    margin-bottom: .5rem; }
  .driver-application .optional, .coordinator-application .optional {
    margin: 1rem 0 .5rem 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .driver-application .optional h3, .coordinator-application .optional h3 {
      margin: 0;
      padding: 0; }
    .driver-application .optional .close, .coordinator-application .optional .close {
      position: relative;
      top: 3px;
      font-size: 20px;
      cursor: pointer; }
  .driver-application hr, .coordinator-application hr {
    margin: 1rem 0; }

.pending-notification {
  padding: 60px 0.5rem 0.5rem;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1; }
  .pending-notification h2 {
    margin-bottom: .5rem;
    font-weight: 600; }
  .pending-notification p {
    margin-top: 20px;
    font-weight: 100; }

@media (min-width: 768px) {
  .driver-application, .coordinator-application {
    padding-top: 125px;
    width: 500px; }
    .driver-application .dob .date-picker .invalid-date, .coordinator-application .dob .date-picker .invalid-date {
      position: absolute;
      top: 0; }
  .pad-desk {
    padding-left: 25px; }
    .pad-desk input + label:before {
      left: 25px; }
    .pad-desk input[type=checkbox] {
      margin-right: 1.75rem; }
  .military-service .question {
    padding-left: 1.5rem; }
    .military-service .question .yes-no {
      margin-top: 10px; }
      .military-service .question .yes-no .pill {
        border: solid 1px #049b5c;
        width: auto;
        margin-right: 1rem;
        font-size: 1.125rem; }
        .military-service .question .yes-no .pill.selected {
          background-color: #049b5c;
          color: #FFFFFF; }
  .pending-notification {
    padding-top: 125px;
    width: 650px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

.dashboard {
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;
  width: 100%;
  height: 100%; }
  .dashboard .green-strip {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(15, 137, 82, 0.8);
    color: #FFFFFF;
    padding: 0px 60px;
    z-index: 9; }
    .dashboard .green-strip .header {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 700 !important; }
    .dashboard .green-strip .new-user-text {
      font-family: 'DINNextLTPro-Regular';
      font-size: 36px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.5px;
      text-align: left; }
  .dashboard .top {
    display: flex;
    color: #FFFFFF;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    background-color: #4A4A4A; }
    .dashboard .top .common-toast-container {
      position: fixed;
      max-width: 400px; }
      .dashboard .top .common-toast-container .text {
        text-wrap: wrap; }
    .dashboard .top .right-top-section {
      flex-grow: 1;
      display: flex;
      justify-content: space-between; }
    .dashboard .top .next {
      background: #130c0e33;
      position: relative;
      color: #FFFFFF !important;
      padding: 1rem;
      text-wrap: nowrap; }
      .dashboard .top .next .day-details {
        top: 100%;
        background: #130C0E;
        left: 0;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px; }
    .dashboard .top .letter {
      height: 35px;
      width: 35px;
      font-family: "DINNextLTPro-Regular"; }
    .dashboard .top .letter {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      background: #049b5c;
      border: 2px solid #FFFFFF;
      border-radius: 5rem;
      margin: 0.5rem; }
    .dashboard .top .column {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center; }
      .dashboard .top .column .address-desc {
        display: flex;
        flex-wrap: nowrap; }
      .dashboard .top .column .desc {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-size: 18px !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: capitalize; }
      .dashboard .top .column .addr {
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        top: -0.25rem; }
    .dashboard .top .time {
      font-size: 1.5rem;
      margin: 0.5rem; }
      .dashboard .top .time span {
        font-size: 0.875rem; }
    .dashboard .top .driver-info {
      display: flex;
      align-items: center;
      margin-right: 16px;
      justify-content: flex-start; }
      .dashboard .top .driver-info .driving-today-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #D5D5D5; }
      .dashboard .top .driver-info .driver-image-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0px;
        border: 2px solid #4A90E2;
        background-color: #0F8952;
        border-radius: 50%;
        height: 40px;
        width: 40px; }
        .dashboard .top .driver-info .driver-image-container .profile-image {
          height: 35px;
          width: 35px;
          border-radius: 50%; }
        .dashboard .top .driver-info .driver-image-container .driving-icon {
          position: absolute;
          right: -2px;
          bottom: -5px; }
        .dashboard .top .driver-info .driver-image-container .image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 0px;
          border: 2px solid #4A90E2;
          background-color: #0F8952; }
          .dashboard .top .driver-info .driver-image-container .image-container.assigned {
            border-radius: 36px;
            border: 1px solid; }
      .dashboard .top .driver-info .information {
        margin-left: 16px; }
        .dashboard .top .driver-info .information .vanpooler-name {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .dashboard .top .driver-info .information .driving-text {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          opacity: 0.56;
          line-height: 16px; }
    .dashboard .top .no-driver-section {
      display: flex;
      align-items: center;
      background-color: rgba(19, 12, 14, 0.2);
      height: 100%;
      gap: 8px;
      padding: 0px 16px;
      min-height: 46px; }
      .dashboard .top .no-driver-section .no-driver-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-wrap: nowrap; }
      .dashboard .top .no-driver-section .assign-to-me {
        border-radius: 20px;
        border: 1px solid #D5D5D5;
        background: #FFFFFF;
        height: 34px;
        width: fit-content;
        color: #4A4A4A;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-wrap: nowrap; }
  .dashboard .ie11Warning-spacer {
    position: relative;
    height: 10rem; }
    .dashboard .ie11Warning-spacer .IE11Warning {
      border-radius: 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      padding: 1rem 2rem;
      background: #f6db00;
      color: #333333;
      margin: 100px 0px 80px 0px; }
      .dashboard .ie11Warning-spacer .IE11Warning p {
        margin-top: 0;
        margin-bottom: 0.3em;
        font-weight: 700;
        font-size: 1rem; }
  .dashboard .map-spacer {
    position: relative;
    height: 10rem;
    cursor: pointer; }
    .dashboard .map-spacer .google-map {
      height: 100%;
      pointer-events: none; }
    .dashboard .map-spacer.no-click {
      cursor: default; }
  .dashboard .trip-tools {
    padding-bottom: 24px; }
    .dashboard .trip-tools .row {
      display: flex;
      justify-content: space-between; }
    .dashboard .trip-tools .trip-banner {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: rgba(74, 74, 74, 0.9);
      color: #FFFFFF;
      min-height: 4rem; }
      .dashboard .trip-tools .trip-banner.overdue {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 0.5rem; }
        .dashboard .trip-tools .trip-banner.overdue .trip-banner-message {
          max-width: 55vw; }
        .dashboard .trip-tools .trip-banner.overdue .trip-banner-tools {
          width: fit-content; }
      .dashboard .trip-tools .trip-banner .trip-banner-message {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
        -ms-flex-pack: flex-start;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        padding: 0.25rem; }
        .dashboard .trip-tools .trip-banner .trip-banner-message svg {
          font-size: 3rem;
          padding-right: 0.25rem; }
        .dashboard .trip-tools .trip-banner .trip-banner-message .message {
          font-size: 0.875rem; }
          .dashboard .trip-tools .trip-banner .trip-banner-message .message.small {
            font-size: 0.75rem;
            min-width: calc(100vw - 10rem); }
        .dashboard .trip-tools .trip-banner .trip-banner-message .days {
          display: block;
          height: 100%;
          font-size: 1.5rem;
          text-align: center;
          position: relative;
          top: 0.5rem;
          margin-right: 1rem;
          min-width: 2rem; }
          .dashboard .trip-tools .trip-banner .trip-banner-message .days::before {
            content: 'DAYS';
            font-size: 0.75rem;
            top: -0.5rem;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto;
            color: #cccccc;
            font-weight: 200;
            letter-spacing: 1px; }
      .dashboard .trip-tools .trip-banner .trip-banner-tools {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: stretch;
        -moz-justify-content: stretch;
        -ms-justify-content: stretch;
        justify-content: stretch;
        -ms-flex-pack: stretch;
        border-radius: 5px; }
        .dashboard .trip-tools .trip-banner .trip-banner-tools.yes-no .button {
          border-radius: 5px; }
        .dashboard .trip-tools .trip-banner .trip-banner-tools .button {
          cursor: pointer;
          text-wrap: nowrap;
          padding: 10px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
          .dashboard .trip-tools .trip-banner .trip-banner-tools .button.cancel {
            background-color: #333333;
            color: #FFFFFF;
            border-color: #333333; }
          .dashboard .trip-tools .trip-banner .trip-banner-tools .button.warning {
            color: #FFFFFF;
            background-color: #f96302;
            border-color: #f96302;
            font-weight: 600;
            font-size: 1.25rem; }
          .dashboard .trip-tools .trip-banner .trip-banner-tools .button:hover, .dashboard .trip-tools .trip-banner .trip-banner-tools .button:focus {
            opacity: 0.8;
            box-shadow: none; }
  .dashboard .bottom {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 92%;
    margin: auto; }
    .dashboard .bottom .left-side {
      margin-bottom: 24px; }
      .dashboard .bottom .left-side .new-registered-user .top-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap; }
      .dashboard .bottom .left-side .new-registered-user h3 {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-family: 'DIN Alternate';
        margin-bottom: 24px; }
      .dashboard .bottom .left-side .new-registered-user .controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: 70px; }
        .dashboard .bottom .left-side .new-registered-user .controls .find-vanpool-button {
          display: flex;
          height: 48px;
          width: fit-content;
          background-color: #0F8952;
          padding: 20px;
          align-items: center;
          border-radius: 5px; }
          .dashboard .bottom .left-side .new-registered-user .controls .find-vanpool-button a {
            font-family: "Proxima Nova";
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 1px;
            text-align: center;
            color: white; }
        .dashboard .bottom .left-side .new-registered-user .controls .sync-vanpool {
          position: relative;
          top: 30px;
          font-family: "Proxima Nova";
          color: #0F8952;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 2px; }
      .dashboard .bottom .left-side .new-registered-user .benefits {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; }
        .dashboard .bottom .left-side .new-registered-user .benefits ul {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          gap: 15px; }
          .dashboard .bottom .left-side .new-registered-user .benefits ul .list-item {
            margin-bottom: 9px; }
            .dashboard .bottom .left-side .new-registered-user .benefits ul .list-item img {
              margin-right: 8px; }
            .dashboard .bottom .left-side .new-registered-user .benefits ul .list-item span {
              font-family: "Proxima Nova";
              font-size: 15px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.25px;
              text-align: left; }
      .dashboard .bottom .left-side .new-registered-user .contact-info {
        top: 30px; }
      .dashboard .bottom .left-side .impact-data-container .info .message {
        left: -9.8rem;
        top: 47px; }
  .dashboard .vanpool-details .tools,
  .dashboard .impact-data-container .tools,
  .dashboard .vanpool-payment-summary .tools,
  .dashboard .trip-banner .tools {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    -ms-flex-pack: space-around;
    padding: 0rem .75rem; }
  .dashboard .vanpool-details .tablet-left .payment-group,
  .dashboard .impact-data-container .tablet-left .payment-group,
  .dashboard .vanpool-payment-summary .tablet-left .payment-group,
  .dashboard .trip-banner .tablet-left .payment-group {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .dashboard .vanpool-details .tablet-left .payment-group .button,
    .dashboard .impact-data-container .tablet-left .payment-group .button,
    .dashboard .vanpool-payment-summary .tablet-left .payment-group .button,
    .dashboard .trip-banner .tablet-left .payment-group .button {
      margin: 1rem; }
  .dashboard .vanpool-details .tablet-left .payment-recurring,
  .dashboard .impact-data-container .tablet-left .payment-recurring,
  .dashboard .vanpool-payment-summary .tablet-left .payment-recurring,
  .dashboard .trip-banner .tablet-left .payment-recurring {
    text-align: center; }
  .dashboard .vanpool-details .payment-recurring,
  .dashboard .impact-data-container .payment-recurring,
  .dashboard .vanpool-payment-summary .payment-recurring,
  .dashboard .trip-banner .payment-recurring {
    display: block;
    color: #333333;
    font-size: 0.875rem;
    padding: .75rem; }
  .dashboard .vanpool-details .last-payment,
  .dashboard .impact-data-container .last-payment,
  .dashboard .vanpool-payment-summary .last-payment,
  .dashboard .trip-banner .last-payment {
    width: 100%;
    display: block;
    padding: 0 0.75rem; }
    .dashboard .vanpool-details .last-payment svg,
    .dashboard .impact-data-container .last-payment svg,
    .dashboard .vanpool-payment-summary .last-payment svg,
    .dashboard .trip-banner .last-payment svg {
      display: none; }
    .dashboard .vanpool-details .last-payment div,
    .dashboard .impact-data-container .last-payment div,
    .dashboard .vanpool-payment-summary .last-payment div,
    .dashboard .trip-banner .last-payment div {
      width: 100%; }
      .dashboard .vanpool-details .last-payment div h4,
      .dashboard .impact-data-container .last-payment div h4,
      .dashboard .vanpool-payment-summary .last-payment div h4,
      .dashboard .trip-banner .last-payment div h4 {
        font-weight: 500;
        color: #484848;
        margin: 0.25rem 0; }
      .dashboard .vanpool-details .last-payment div p,
      .dashboard .impact-data-container .last-payment div p,
      .dashboard .vanpool-payment-summary .last-payment div p,
      .dashboard .trip-banner .last-payment div p {
        width: 100%;
        text-align: center;
        font-size: 1.25rem; }
  .dashboard .vanpool-details .row,
  .dashboard .impact-data-container .row,
  .dashboard .vanpool-payment-summary .row,
  .dashboard .trip-banner .row {
    border: solid 1px #CCCCCC;
    border-radius: 8px; }
    .dashboard .vanpool-details .row.vehicle,
    .dashboard .impact-data-container .row.vehicle,
    .dashboard .vanpool-payment-summary .row.vehicle,
    .dashboard .trip-banner .row.vehicle {
      cursor: pointer;
      -webkit-flex-wrap: nowrap;
      -moz-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      align-items: center; }
    .dashboard .vanpool-details .row.invite,
    .dashboard .impact-data-container .row.invite,
    .dashboard .vanpool-payment-summary .row.invite,
    .dashboard .trip-banner .row.invite {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      border-top: 1px solid #CCCCCC; }
      .dashboard .vanpool-details .row.invite button,
      .dashboard .impact-data-container .row.invite button,
      .dashboard .vanpool-payment-summary .row.invite button,
      .dashboard .trip-banner .row.invite button {
        height: 100%;
        width: 100%;
        color: #FFFFFF;
        background: #049b5c;
        margin: 1rem 0.5rem; }
    .dashboard .vanpool-details .row.drive,
    .dashboard .impact-data-container .row.drive,
    .dashboard .vanpool-payment-summary .row.drive,
    .dashboard .trip-banner .row.drive {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      border-top: 1px solid #CCCCCC;
      margin-bottom: 24px; }
      .dashboard .vanpool-details .row.drive .apply-drive,
      .dashboard .impact-data-container .row.drive .apply-drive,
      .dashboard .vanpool-payment-summary .row.drive .apply-drive,
      .dashboard .trip-banner .row.drive .apply-drive {
        margin: 1rem auto;
        font-weight: bold; }
        .dashboard .vanpool-details .row.drive .apply-drive a,
        .dashboard .impact-data-container .row.drive .apply-drive a,
        .dashboard .vanpool-payment-summary .row.drive .apply-drive a,
        .dashboard .trip-banner .row.drive .apply-drive a {
          color: #00a664; }
        .dashboard .vanpool-details .row.drive .apply-drive a:hover,
        .dashboard .vanpool-details .row.drive .apply-drive a:active,
        .dashboard .impact-data-container .row.drive .apply-drive a:hover,
        .dashboard .impact-data-container .row.drive .apply-drive a:active,
        .dashboard .vanpool-payment-summary .row.drive .apply-drive a:hover,
        .dashboard .vanpool-payment-summary .row.drive .apply-drive a:active,
        .dashboard .trip-banner .row.drive .apply-drive a:hover,
        .dashboard .trip-banner .row.drive .apply-drive a:active {
          color: #00a664; }
    .dashboard .vanpool-details .row .image,
    .dashboard .impact-data-container .row .image,
    .dashboard .vanpool-payment-summary .row .image,
    .dashboard .trip-banner .row .image {
      display: none; }
    .dashboard .vanpool-details .row .column,
    .dashboard .impact-data-container .row .column,
    .dashboard .vanpool-payment-summary .row .column,
    .dashboard .trip-banner .row .column {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      padding-left: 10px; }
      .dashboard .vanpool-details .row .column .name,
      .dashboard .impact-data-container .row .column .name,
      .dashboard .vanpool-payment-summary .row .column .name,
      .dashboard .trip-banner .row .column .name {
        font-size: 1rem;
        font-weight: 600; }
      .dashboard .vanpool-details .row .column .id,
      .dashboard .impact-data-container .row .column .id,
      .dashboard .vanpool-payment-summary .row .column .id,
      .dashboard .trip-banner .row .column .id {
        font-weight: 300; }
    .dashboard .vanpool-details .row .stats,
    .dashboard .impact-data-container .row .stats,
    .dashboard .vanpool-payment-summary .row .stats,
    .dashboard .trip-banner .row .stats {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      border-left: solid 1px #CCCCCC;
      min-width: 8rem; }
      .dashboard .vanpool-details .row .stats .seats,
      .dashboard .vanpool-details .row .stats .occupancy,
      .dashboard .impact-data-container .row .stats .seats,
      .dashboard .impact-data-container .row .stats .occupancy,
      .dashboard .vanpool-payment-summary .row .stats .seats,
      .dashboard .vanpool-payment-summary .row .stats .occupancy,
      .dashboard .trip-banner .row .stats .seats,
      .dashboard .trip-banner .row .stats .occupancy {
        font-size: 30px;
        text-transform: uppercase;
        text-align: center;
        padding: 10px; }
        .dashboard .vanpool-details .row .stats .seats .label-text,
        .dashboard .vanpool-details .row .stats .occupancy .label-text,
        .dashboard .impact-data-container .row .stats .seats .label-text,
        .dashboard .impact-data-container .row .stats .occupancy .label-text,
        .dashboard .vanpool-payment-summary .row .stats .seats .label-text,
        .dashboard .vanpool-payment-summary .row .stats .occupancy .label-text,
        .dashboard .trip-banner .row .stats .seats .label-text,
        .dashboard .trip-banner .row .stats .occupancy .label-text {
          font-size: 14px;
          font-weight: 300;
          text-align: center; }
        .dashboard .vanpool-details .row .stats .seats img,
        .dashboard .vanpool-details .row .stats .occupancy img,
        .dashboard .impact-data-container .row .stats .seats img,
        .dashboard .impact-data-container .row .stats .occupancy img,
        .dashboard .vanpool-payment-summary .row .stats .seats img,
        .dashboard .vanpool-payment-summary .row .stats .occupancy img,
        .dashboard .trip-banner .row .stats .seats img,
        .dashboard .trip-banner .row .stats .occupancy img {
          display: none;
          position: relative;
          left: -8px;
          width: 40px; }
  .dashboard .vanpool-details .maintenance-container,
  .dashboard .impact-data-container .maintenance-container,
  .dashboard .vanpool-payment-summary .maintenance-container,
  .dashboard .trip-banner .maintenance-container {
    cursor: pointer; }
  .dashboard .vanpool-details .maintenance,
  .dashboard .impact-data-container .maintenance,
  .dashboard .vanpool-payment-summary .maintenance,
  .dashboard .trip-banner .maintenance {
    background-color: #e6e6e6;
    color: #333333;
    padding: 10px 10px 10px 1rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .dashboard .vanpool-details .maintenance.past-due,
    .dashboard .impact-data-container .maintenance.past-due,
    .dashboard .vanpool-payment-summary .maintenance.past-due,
    .dashboard .trip-banner .maintenance.past-due {
      background: #FEFBE9;
      color: #635610;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px; }
      .dashboard .vanpool-details .maintenance.past-due .next,
      .dashboard .vanpool-details .maintenance.past-due .updated,
      .dashboard .vanpool-details .maintenance.past-due .next .date,
      .dashboard .vanpool-details .maintenance.past-due .next .miles,
      .dashboard .impact-data-container .maintenance.past-due .next,
      .dashboard .impact-data-container .maintenance.past-due .updated,
      .dashboard .impact-data-container .maintenance.past-due .next .date,
      .dashboard .impact-data-container .maintenance.past-due .next .miles,
      .dashboard .vanpool-payment-summary .maintenance.past-due .next,
      .dashboard .vanpool-payment-summary .maintenance.past-due .updated,
      .dashboard .vanpool-payment-summary .maintenance.past-due .next .date,
      .dashboard .vanpool-payment-summary .maintenance.past-due .next .miles,
      .dashboard .trip-banner .maintenance.past-due .next,
      .dashboard .trip-banner .maintenance.past-due .updated,
      .dashboard .trip-banner .maintenance.past-due .next .date,
      .dashboard .trip-banner .maintenance.past-due .next .miles {
        color: #635610; }
    .dashboard .vanpool-details .maintenance .title,
    .dashboard .impact-data-container .maintenance .title,
    .dashboard .vanpool-payment-summary .maintenance .title,
    .dashboard .trip-banner .maintenance .title {
      display: flex; }
      .dashboard .vanpool-details .maintenance .title img,
      .dashboard .impact-data-container .maintenance .title img,
      .dashboard .vanpool-payment-summary .maintenance .title img,
      .dashboard .trip-banner .maintenance .title img {
        width: 24px;
        height: 24px;
        margin-right: 16px; }
      .dashboard .vanpool-details .maintenance .title .message-content,
      .dashboard .impact-data-container .maintenance .title .message-content,
      .dashboard .vanpool-payment-summary .maintenance .title .message-content,
      .dashboard .trip-banner .maintenance .title .message-content {
        display: flex;
        flex-direction: column; }
      .dashboard .vanpool-details .maintenance .title .text,
      .dashboard .impact-data-container .maintenance .title .text,
      .dashboard .vanpool-payment-summary .maintenance .title .text,
      .dashboard .trip-banner .maintenance .title .text {
        font-weight: 600; }
    .dashboard .vanpool-details .maintenance .arrow-right,
    .dashboard .impact-data-container .maintenance .arrow-right,
    .dashboard .vanpool-payment-summary .maintenance .arrow-right,
    .dashboard .trip-banner .maintenance .arrow-right {
      align-self: normal;
      margin-right: 16px; }
    .dashboard .vanpool-details .maintenance .updated,
    .dashboard .impact-data-container .maintenance .updated,
    .dashboard .vanpool-payment-summary .maintenance .updated,
    .dashboard .trip-banner .maintenance .updated {
      color: #635610;
      font-style: italic;
      font-size: 0.75rem; }
    .dashboard .vanpool-details .maintenance .next,
    .dashboard .impact-data-container .maintenance .next,
    .dashboard .vanpool-payment-summary .maintenance .next,
    .dashboard .trip-banner .maintenance .next {
      color: #130C0E;
      font-weight: 600;
      font-size: 0.875rem; }
      .dashboard .vanpool-details .maintenance .next .date,
      .dashboard .vanpool-details .maintenance .next .miles,
      .dashboard .impact-data-container .maintenance .next .date,
      .dashboard .impact-data-container .maintenance .next .miles,
      .dashboard .vanpool-payment-summary .maintenance .next .date,
      .dashboard .vanpool-payment-summary .maintenance .next .miles,
      .dashboard .trip-banner .maintenance .next .date,
      .dashboard .trip-banner .maintenance .next .miles {
        font-size: 1.25rem;
        color: #333333; }
      .dashboard .vanpool-details .maintenance .next .date,
      .dashboard .impact-data-container .maintenance .next .date,
      .dashboard .vanpool-payment-summary .maintenance .next .date,
      .dashboard .trip-banner .maintenance .next .date {
        margin-right: 6px; }
      .dashboard .vanpool-details .maintenance .next .miles,
      .dashboard .impact-data-container .maintenance .next .miles,
      .dashboard .vanpool-payment-summary .maintenance .next .miles,
      .dashboard .trip-banner .maintenance .next .miles {
        margin-left: 6px;
        margin-right: 2px; }
  .dashboard .vanpool-payment-summary {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .dashboard .vanpool-payment-summary .payment-due-date {
      padding: 0.75rem 0 0 0.75rem;
      color: #484848;
      font-size: .9rem; }
    .dashboard .vanpool-payment-summary .payment-balance-due {
      text-align: center;
      padding: 0;
      font-size: 2rem;
      position: relative; }
  .dashboard .dashboard-van-details-container {
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 24px;
    background-color: #FFFFFF; }
  .dashboard .impact-data-container {
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    padding: 0.5rem;
    margin-bottom: 24px; }
    .dashboard .impact-data-container .impact-data-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .dashboard .impact-data-container .impact-data-content .info .message {
        left: -9.8rem;
        top: 47px;
        width: auto; }
        .dashboard .impact-data-container .impact-data-content .info .message:before {
          left: 10rem; }
        .dashboard .impact-data-container .impact-data-content .info .message:after {
          left: calc(10rem + 1px); }
        .dashboard .impact-data-container .impact-data-content .info .message .lookup {
          width: auto; }
          .dashboard .impact-data-container .impact-data-content .info .message .lookup .pair {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            padding: 0.25rem 0; }
            .dashboard .impact-data-container .impact-data-content .info .message .lookup .pair .key {
              display: inline-block;
              width: 6rem;
              font-weight: 600; }
            .dashboard .impact-data-container .impact-data-content .info .message .lookup .pair .value {
              -webkit-box-flex: 1;
              -moz-box-flex: 1;
              -webkit-flex: 1;
              -ms-flex: 1;
              flex: 1;
              display: inline-block;
              word-wrap: wrap;
              font-weight: 300;
              font-style: italic;
              color: #484848; }
      .dashboard .impact-data-container .impact-data-content .toggle {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        padding: 0.5rem; }
        .dashboard .impact-data-container .impact-data-content .toggle button {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          background: #FFFFFF;
          color: #049b5c;
          border: 1px solid #049b5c;
          min-width: 3rem;
          max-width: 3rem;
          height: 2rem;
          text-align: center;
          border-radius: 0 0.25rem 0.25rem 0; }
          .dashboard .impact-data-container .impact-data-content .toggle button:first-child {
            border-radius: 0.25rem 0 0 0.25rem; }
          .dashboard .impact-data-container .impact-data-content .toggle button:focus {
            background: #e6e6e6; }
          .dashboard .impact-data-container .impact-data-content .toggle button.active {
            box-shadow: none;
            background: #049b5c;
            color: #FFFFFF; }
            .dashboard .impact-data-container .impact-data-content .toggle button.active svg {
              color: #FFFFFF; }
          .dashboard .impact-data-container .impact-data-content .toggle button svg {
            font-size: 1.25rem; }
        .dashboard .impact-data-container .impact-data-content .toggle .label {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-justify-content: space-between;
          -moz-justify-content: space-between;
          -ms-justify-content: space-between;
          justify-content: space-between;
          -ms-flex-pack: space-between;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          min-width: 50%;
          padding: 0 1rem;
          margin-top: 1rem;
          text-transform: uppercase;
          font-weight: 600;
          display: flex;
          flex-direction: column; }
          .dashboard .impact-data-container .impact-data-content .toggle .label .time {
            font-size: 0.875rem; }
          .dashboard .impact-data-container .impact-data-content .toggle .label .title {
            display: none; }
          .dashboard .impact-data-container .impact-data-content .toggle .label .info {
            position: absolute;
            top: -2px;
            right: -1.5rem; }
            .dashboard .impact-data-container .impact-data-content .toggle .label .info .message {
              top: calc(100% + 0.5rem);
              left: -6.75rem;
              width: calc(100vw - 1px); }
              .dashboard .impact-data-container .impact-data-content .toggle .label .info .message:before {
                left: calc(6.75rem - 1px); }
              .dashboard .impact-data-container .impact-data-content .toggle .label .info .message:after {
                left: 6.75rem; }
              .dashboard .impact-data-container .impact-data-content .toggle .label .info .message .pair {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                padding: 0.25rem 0; }
                .dashboard .impact-data-container .impact-data-content .toggle .label .info .message .pair .key {
                  display: inline-block;
                  width: 6rem;
                  font-weight: 600; }
                .dashboard .impact-data-container .impact-data-content .toggle .label .info .message .pair .value {
                  -webkit-box-flex: 1;
                  -moz-box-flex: 1;
                  -webkit-flex: 1;
                  -ms-flex: 1;
                  flex: 1;
                  display: inline-block;
                  word-wrap: wrap;
                  font-weight: 300;
                  font-style: italic;
                  color: #484848; }
      .dashboard .impact-data-container .impact-data-content .label {
        display: flex;
        justify-content: flex-start;
        padding: 0.5rem; }
        .dashboard .impact-data-container .impact-data-content .label h3 {
          font-family: 'DIN Alternate';
          font-weight: 700;
          size: 24px; }
        .dashboard .impact-data-container .impact-data-content .label .info {
          padding-top: 18px;
          padding-left: 5px;
          height: 20px;
          width: 20px; }
        .dashboard .impact-data-container .impact-data-content .label .time {
          padding: 20px 0 0 14px !important;
          font-family: "Proxima Nova";
          font-weight: 400;
          size: 14px;
          align-self: baseline; }
      .dashboard .impact-data-container .impact-data-content .impact-data-toggle {
        display: flex; }
        .dashboard .impact-data-container .impact-data-content .impact-data-toggle .mobile-only {
          font-family: 'DINNextLTPro-Regular';
          font-weight: 500;
          size: 14px;
          padding-left: 8px;
          display: inline-flex;
          align-items: center; }
        .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-justify-content: space-between;
          -moz-justify-content: space-between;
          -ms-justify-content: space-between;
          justify-content: space-between;
          -ms-flex-pack: space-between;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          min-width: 50%;
          padding: 0 1rem;
          margin-top: 1rem;
          text-transform: uppercase;
          font-weight: 600;
          display: flex;
          flex-direction: column; }
          .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .time {
            font-size: 0.875rem; }
          .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .title {
            display: none; }
          .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .info {
            position: absolute;
            top: -2px;
            right: -1.5rem; }
            .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .info .message {
              top: calc(100% + 0.5rem);
              left: -6.75rem;
              width: calc(100vw - 1px); }
              .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .info .message:before {
                left: calc(6.75rem - 1px); }
              .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .info .message:after {
                left: 6.75rem; }
              .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .info .message .pair {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                padding: 0.25rem 0; }
                .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .info .message .pair .key {
                  display: inline-block;
                  width: 6rem;
                  font-weight: 600; }
                .dashboard .impact-data-container .impact-data-content .impact-data-toggle .label .info .message .pair .value {
                  -webkit-box-flex: 1;
                  -moz-box-flex: 1;
                  -webkit-flex: 1;
                  -ms-flex: 1;
                  flex: 1;
                  display: inline-block;
                  word-wrap: wrap;
                  font-weight: 300;
                  font-style: italic;
                  color: #484848; }
    .dashboard .impact-data-container .impact-data {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0.25rem;
      width: 100%; }
      .dashboard .impact-data-container .impact-data .cell {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        width: 48%;
        flex-wrap: wrap;
        margin: 0.25rem;
        padding: 0.5rem;
        border-radius: 0.25rem;
        background-color: #D4EDE1; }
        .dashboard .impact-data-container .impact-data .cell span {
          text-align: right; }
        .dashboard .impact-data-container .impact-data .cell label {
          text-transform: uppercase;
          color: #484848;
          font-size: 0.75rem;
          display: block; }
        .dashboard .impact-data-container .impact-data .cell .value {
          font-size: 1.25rem; }
        .dashboard .impact-data-container .impact-data .cell .units {
          color: #484848;
          font-size: 0.875rem;
          padding-left: 0.25rem; }
  .dashboard .employer-content {
    position: relative;
    border: 1px solid #CCCCCC;
    background: #FFFFFF;
    padding: 1.8rem 1.25rem; }
    .dashboard .employer-content .title {
      color: #049b5c;
      font-size: 1.25rem;
      padding-bottom: 1rem; }
    .dashboard .employer-content .details {
      font-size: 1rem;
      color: #333333; }
  .dashboard .fuel-card {
    position: relative;
    border: 1px solid #CCCCCC;
    background: #FFFFFF;
    height: 7rem; }
    .dashboard .fuel-card .title {
      position: absolute;
      left: 8%;
      top: 1.25rem;
      font-size: calc(1rem + 40%); }
    .dashboard .fuel-card .label {
      font-size: 0.75rem;
      color: #b3b3b3;
      text-transform: uppercase;
      position: absolute;
      right: 8%;
      top: 0.75rem; }
    .dashboard .fuel-card .balance {
      width: auto;
      padding: 0;
      font-size: 1.6rem;
      position: absolute;
      right: 8%;
      top: 1.5rem; }
    .dashboard .fuel-card .note {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0.5rem;
      text-align: center;
      font-size: 0.75rem; }
      .dashboard .fuel-card .note .callable {
        font-weight: 600;
        color: #333333; }
  .dashboard .contact-info {
    position: relative;
    border: 1px solid #CCCCCC;
    background: #FFFFFF; }
    .dashboard .contact-info .contact {
      padding: 1rem;
      border-top: 1px solid #CCCCCC;
      width: 50%; }
      .dashboard .contact-info .contact:first-child {
        border-top: none; }
      .dashboard .contact-info .contact .title {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700;
        color: #130C0E; }
      .dashboard .contact-info .contact .name {
        font-family: 'Proxima Nova Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin-top: 8px;
        text-transform: capitalize; }
      .dashboard .contact-info .contact svg {
        margin-right: 0.5rem;
        font-size: 1.25rem;
        color: #333333; }
      .dashboard .contact-info .contact .phone,
      .dashboard .contact-info .contact .email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0.5rem 0.5rem 0.5rem 0;
        width: auto; }
      .dashboard .contact-info .contact .phone.mobile {
        display: none; }
      .dashboard .contact-info .contact .phone.desktop {
        display: block; }
      .dashboard .contact-info .contact .email {
        color: #049b5c;
        cursor: pointer;
        text-decoration: underline; }
    .dashboard .contact-info .branch-contact {
      padding: 1rem;
      border-top: 1px solid #CCCCCC; }
      .dashboard .contact-info .branch-contact .title {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700;
        color: #130C0E; }
      .dashboard .contact-info .branch-contact .name {
        font-family: 'Proxima Nova Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin-top: 8px; }
      .dashboard .contact-info .branch-contact .details {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-transform: capitalize;
        font-weight: 400;
        text-align: left; }
      .dashboard .contact-info .branch-contact svg {
        margin-right: 0.5rem;
        font-size: 1.25rem;
        color: #333333; }
      .dashboard .contact-info .branch-contact .contact-details {
        display: flex;
        flex-direction: row; }
        .dashboard .contact-info .branch-contact .contact-details.noinfo {
          flex-direction: column-reverse; }
        .dashboard .contact-info .branch-contact .contact-details .phone,
        .dashboard .contact-info .branch-contact .contact-details .email {
          margin: 0.5rem 0.5rem 0.5rem 0;
          width: auto;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          text-transform: capitalize;
          font-weight: 600;
          color: rgba(74, 74, 74, 0.9);
          text-align: left; }
          .dashboard .contact-info .branch-contact .contact-details .phone img,
          .dashboard .contact-info .branch-contact .contact-details .email img {
            height: 20px;
            width: 20px;
            margin-right: 8px; }
          .dashboard .contact-info .branch-contact .contact-details .phone .info .message,
          .dashboard .contact-info .branch-contact .contact-details .email .info .message {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 30px;
            color: #FFFFFF;
            background: #4A4A4A;
            border-color: #4A4A4A;
            left: -2rem;
            top: 2rem;
            width: 360px;
            border-radius: 8px; }
            .dashboard .contact-info .branch-contact .contact-details .phone .info .message:after,
            .dashboard .contact-info .branch-contact .contact-details .email .info .message:after {
              transform: rotate(0deg);
              border-bottom: 0.5625rem solid #4A4A4A;
              left: 10%; }
            .dashboard .contact-info .branch-contact .contact-details .phone .info .message:before,
            .dashboard .contact-info .branch-contact .contact-details .email .info .message:before {
              transform: rotate(0deg);
              border-bottom: 0.5625rem solid #4A4A4A;
              left: 10%; }
            .dashboard .contact-info .branch-contact .contact-details .phone .info .message .closeIcon,
            .dashboard .contact-info .branch-contact .contact-details .email .info .message .closeIcon {
              position: absolute;
              right: 10px;
              top: 10px;
              width: 12px;
              height: 12px; }
            .dashboard .contact-info .branch-contact .contact-details .phone .info .message .main-content,
            .dashboard .contact-info .branch-contact .contact-details .email .info .message .main-content {
              background: #4A4A4A;
              color: #FFFFFF;
              margin: 5px; }
              .dashboard .contact-info .branch-contact .contact-details .phone .info .message .main-content .heading,
              .dashboard .contact-info .branch-contact .contact-details .email .info .message .main-content .heading {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 700;
                margin-bottom: 8px; }
              .dashboard .contact-info .branch-contact .contact-details .phone .info .message .main-content .list-options .option,
              .dashboard .contact-info .branch-contact .contact-details .email .info .message .main-content .list-options .option {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                margin-bottom: 16px; }
        .dashboard .contact-info .branch-contact .contact-details .email {
          color: #049b5c;
          cursor: pointer;
          margin-left: 10px;
          text-decoration: underline; }
          .dashboard .contact-info .branch-contact .contact-details .email.noinfo {
            margin-left: 0px; }
          .dashboard .contact-info .branch-contact .contact-details .email.nophone {
            margin-left: 0px; }

.badges {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around; }
  .badges .app-store img,
  .badges .play-store img {
    width: 16rem;
    margin: 4rem auto; }
    .badges .app-store img:hover,
    .badges .play-store img:hover {
      cursor: pointer; }

.dashboard-van-details-container {
  padding: 16px 32px; }
  .dashboard-van-details-container .header {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #130C0E; }
  .dashboard-van-details-container .sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .dashboard-van-details-container .sections .vanpool-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #EDEDED;
      padding-left: 40px; }
      .dashboard-van-details-container .sections .vanpool-section .name {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700; }
      .dashboard-van-details-container .sections .vanpool-section .capacity {
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        color: #4A4A4A;
        column-gap: 12px; }
      .dashboard-van-details-container .sections .vanpool-section .image img {
        height: 180px; }
      .dashboard-van-details-container .sections .vanpool-section .image .image-not-found {
        height: 150px;
        margin-top: 10px;
        margin-bottom: 25px; }
      .dashboard-van-details-container .sections .vanpool-section .image-opacity img {
        height: 180px;
        opacity: 0.4; }
      .dashboard-van-details-container .sections .vanpool-section .id {
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #4A4A4A; }
    .dashboard-van-details-container .sections .commute-days-section .heading {
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 700;
      margin-top: 15px; }
    .dashboard-van-details-container .sections .commute-days-section .days {
      display: flex;
      column-gap: 8px;
      row-gap: 8px;
      flex-wrap: wrap;
      margin-top: 8px; }
      .dashboard-van-details-container .sections .commute-days-section .days .day-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .dashboard-van-details-container .sections .commute-days-section .days .day-group .full-indicator {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.25px;
          font-style: normal;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          color: #4A4A4A;
          margin-top: 3px; }
        .dashboard-van-details-container .sections .commute-days-section .days .day-group .day {
          height: 26px;
          width: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #D5D5D5;
          border-radius: 4px;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: -0.25px;
          color: #4A4A4A;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 700;
          padding: 10px 8px;
          background: #D4EDE1; }
          .dashboard-van-details-container .sections .commute-days-section .days .day-group .day.commuting {
            color: #FFFFFF;
            background: #0F8952; }
          .dashboard-van-details-container .sections .commute-days-section .days .day-group .day.notcommuting {
            color: #130C0E; }
    .dashboard-van-details-container .sections .address-section {
      margin-top: 24px; }
      .dashboard-van-details-container .sections .address-section .addresses-container {
        display: flex;
        column-gap: 30px;
        row-gap: 30px;
        flex-wrap: wrap; }
        .dashboard-van-details-container .sections .address-section .addresses-container .address-details {
          display: flex;
          flex-direction: column; }
          .dashboard-van-details-container .sections .address-section .addresses-container .address-details .heading {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 700; }
          .dashboard-van-details-container .sections .address-section .addresses-container .address-details .van-route-details {
            margin-top: 8px;
            margin-left: 0px; }
            .dashboard-van-details-container .sections .address-section .addresses-container .address-details .van-route-details .route-icon {
              margin-left: -5px; }
              .dashboard-van-details-container .sections .address-section .addresses-container .address-details .van-route-details .route-icon .group {
                opacity: 0.5; }
            .dashboard-van-details-container .sections .address-section .addresses-container .address-details .van-route-details .route-address .start-address {
              text-wrap: nowrap; }

@media (min-width: 768px) {
  .tab-mobile-only {
    display: none; }
  .desktop-text-only {
    display: block; }
  .dashboard {
    padding-top: 0;
    width: 100%;
    margin: 70px auto 0;
    z-index: 1; }
    .dashboard .no-trip {
      margin-top: -3rem; }
    .dashboard .predelivery-text {
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      margin: 24px 0px; }
    .dashboard .recentLeadSubmitted-text {
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-size: 24px;
      line-height: 36px;
      color: #4A4A4A;
      margin: 10px 0px 16px;
      width: 600px; }
    .dashboard .ie11Warning-spacer {
      position: relative;
      height: 15rem; }
      .dashboard .ie11Warning-spacer .IE11Warning {
        border-radius: 0.5rem;
        font-size: 0.875rem;
        font-weight: 600;
        padding: 1rem 2rem;
        background: #f6db00;
        color: #333333;
        margin: 100px 0px 80px 0px; }
        .dashboard .ie11Warning-spacer .IE11Warning p {
          margin-top: 0;
          margin-bottom: 0.3em;
          font-weight: 700;
          font-size: 1rem; }
    .dashboard .map-spacer {
      height: 30rem; }
      .dashboard .map-spacer .google-map {
        position: absolute;
        top: 0;
        height: 131%;
        width: 100vw;
        z-index: -1; }
        .dashboard .map-spacer .google-map::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 30%;
          background: linear-gradient(rgba(255, 255, 255, 0), #FFFFFF); }
        .dashboard .map-spacer .google-map::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: #FFFFFF;
          opacity: 1;
          transition: opacity 0.5s linear 0.2s; }
        .dashboard .map-spacer .google-map.ready::before {
          opacity: 0; }
    .dashboard .top-dashboard-new {
      width: 100%;
      height: 600px;
      position: relative;
      margin-bottom: 30px; }
      .dashboard .top-dashboard-new .dashboard-background {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0; }
        .dashboard .top-dashboard-new .dashboard-background img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
          height: 600px; }
    .dashboard .trip-tools .trip-banner {
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      background-color: rgba(74, 74, 74, 0.9);
      color: #FFFFFF;
      min-height: 4rem;
      padding-left: 0.75rem;
      border-radius: 8px; }
      .dashboard .trip-tools .trip-banner.overdue {
        padding: 0;
        padding-left: 0.75rem;
        padding-right: 0.75rem; }
        .dashboard .trip-tools .trip-banner.overdue .trip-banner-message .days,
        .dashboard .trip-tools .trip-banner.overdue .trip-banner-message svg {
          display: block; }
      .dashboard .trip-tools .trip-banner .trip-banner-message {
        -webkit-box-flex: 3;
        -moz-box-flex: 3;
        -webkit-flex: 3;
        -ms-flex: 3;
        flex: 3; }
        .dashboard .trip-tools .trip-banner .trip-banner-message svg {
          font-size: 3rem;
          margin-right: 0.75rem; }
        .dashboard .trip-tools .trip-banner .trip-banner-message .message {
          font-size: 1.125rem; }
          .dashboard .trip-tools .trip-banner .trip-banner-message .message.desktop-only {
            display: contents !important; }
          .dashboard .trip-tools .trip-banner .trip-banner-message .message.small {
            font-size: 0.875rem;
            min-width: 0; }
        .dashboard .trip-tools .trip-banner .trip-banner-message .days {
          font-size: 2rem; }
      .dashboard .trip-tools .trip-banner .trip-banner-tools .button {
        cursor: pointer;
        text-wrap: nowrap; }
        .dashboard .trip-tools .trip-banner .trip-banner-tools .button.cancel {
          background-color: #333333;
          color: #FFFFFF;
          border-color: #333333; }
        .dashboard .trip-tools .trip-banner .trip-banner-tools .button:hover, .dashboard .trip-tools .trip-banner .trip-banner-tools .button:focus {
          opacity: 0.8; }
    .dashboard .bottom {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-box-flex: 2;
      -moz-box-flex: 2;
      -webkit-flex: 2;
      -ms-flex: 2;
      flex: 2;
      padding: 0; }
      .dashboard .bottom .right-side {
        margin-bottom: 1rem; }
      .dashboard .bottom .vanpool-details,
      .dashboard .bottom .impact-data-container,
      .dashboard .bottom .vanpool-payment-summary,
      .dashboard .bottom .trip-tools {
        border-radius: 8px;
        overflow: hidden; }
        .dashboard .bottom .vanpool-details .row,
        .dashboard .bottom .impact-data-container .row,
        .dashboard .bottom .vanpool-payment-summary .row,
        .dashboard .bottom .trip-tools .row {
          border: solid 1px #CCCCCC; }
          .dashboard .bottom .vanpool-details .row.vehicle,
          .dashboard .bottom .impact-data-container .row.vehicle,
          .dashboard .bottom .vanpool-payment-summary .row.vehicle,
          .dashboard .bottom .trip-tools .row.vehicle {
            width: 100%;
            -webkit-justify-content: flex-end;
            -moz-justify-content: flex-end;
            -ms-justify-content: flex-end;
            justify-content: flex-end;
            -ms-flex-pack: flex-end;
            cursor: pointer;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-left: 16px;
            padding-right: 16px; }
          .dashboard .bottom .vanpool-details .row.invite,
          .dashboard .bottom .impact-data-container .row.invite,
          .dashboard .bottom .vanpool-payment-summary .row.invite,
          .dashboard .bottom .trip-tools .row.invite {
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            padding: 1rem; }
            .dashboard .bottom .vanpool-details .row.invite button,
            .dashboard .bottom .impact-data-container .row.invite button,
            .dashboard .bottom .vanpool-payment-summary .row.invite button,
            .dashboard .bottom .trip-tools .row.invite button {
              height: auto;
              width: auto;
              color: #049b5c;
              background: #FFFFFF;
              border-radius: 0.25rem; }
            .dashboard .bottom .vanpool-details .row.invite .message,
            .dashboard .bottom .impact-data-container .row.invite .message,
            .dashboard .bottom .vanpool-payment-summary .row.invite .message,
            .dashboard .bottom .trip-tools .row.invite .message {
              display: block; }
          .dashboard .bottom .vanpool-details .row .image,
          .dashboard .bottom .impact-data-container .row .image,
          .dashboard .bottom .vanpool-payment-summary .row .image,
          .dashboard .bottom .trip-tools .row .image {
            margin-right: 1rem;
            padding-left: 1rem; }
            .dashboard .bottom .vanpool-details .row .image img,
            .dashboard .bottom .impact-data-container .row .image img,
            .dashboard .bottom .vanpool-payment-summary .row .image img,
            .dashboard .bottom .trip-tools .row .image img {
              max-height: 80px; }
          .dashboard .bottom .vanpool-details .row .column,
          .dashboard .bottom .impact-data-container .row .column,
          .dashboard .bottom .vanpool-payment-summary .row .column,
          .dashboard .bottom .trip-tools .row .column {
            -webkit-flex-grow: 1;
            -moz-flex-grow: 1;
            -ms-flex-grow: 1;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 10px; }
            .dashboard .bottom .vanpool-details .row .column .name,
            .dashboard .bottom .impact-data-container .row .column .name,
            .dashboard .bottom .vanpool-payment-summary .row .column .name,
            .dashboard .bottom .trip-tools .row .column .name {
              font-size: 1.25rem;
              font-weight: 600; }
            .dashboard .bottom .vanpool-details .row .column .id,
            .dashboard .bottom .impact-data-container .row .column .id,
            .dashboard .bottom .vanpool-payment-summary .row .column .id,
            .dashboard .bottom .trip-tools .row .column .id {
              font-weight: 300; }
          .dashboard .bottom .vanpool-details .row .stats,
          .dashboard .bottom .impact-data-container .row .stats,
          .dashboard .bottom .vanpool-payment-summary .row .stats,
          .dashboard .bottom .trip-tools .row .stats {
            height: 90px;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            border-left: solid 1px #CCCCCC;
            min-width: 15rem; }
            .dashboard .bottom .vanpool-details .row .stats .seats,
            .dashboard .bottom .vanpool-details .row .stats .occupancy,
            .dashboard .bottom .impact-data-container .row .stats .seats,
            .dashboard .bottom .impact-data-container .row .stats .occupancy,
            .dashboard .bottom .vanpool-payment-summary .row .stats .seats,
            .dashboard .bottom .vanpool-payment-summary .row .stats .occupancy,
            .dashboard .bottom .trip-tools .row .stats .seats,
            .dashboard .bottom .trip-tools .row .stats .occupancy {
              font-size: 30px;
              text-transform: uppercase; }
              .dashboard .bottom .vanpool-details .row .stats .seats .label-text,
              .dashboard .bottom .vanpool-details .row .stats .occupancy .label-text,
              .dashboard .bottom .impact-data-container .row .stats .seats .label-text,
              .dashboard .bottom .impact-data-container .row .stats .occupancy .label-text,
              .dashboard .bottom .vanpool-payment-summary .row .stats .seats .label-text,
              .dashboard .bottom .vanpool-payment-summary .row .stats .occupancy .label-text,
              .dashboard .bottom .trip-tools .row .stats .seats .label-text,
              .dashboard .bottom .trip-tools .row .stats .occupancy .label-text {
                font-size: 14px;
                font-weight: 300;
                text-align: center; }
              .dashboard .bottom .vanpool-details .row .stats .seats img,
              .dashboard .bottom .vanpool-details .row .stats .occupancy img,
              .dashboard .bottom .impact-data-container .row .stats .seats img,
              .dashboard .bottom .impact-data-container .row .stats .occupancy img,
              .dashboard .bottom .vanpool-payment-summary .row .stats .seats img,
              .dashboard .bottom .vanpool-payment-summary .row .stats .occupancy img,
              .dashboard .bottom .trip-tools .row .stats .seats img,
              .dashboard .bottom .trip-tools .row .stats .occupancy img {
                display: inline-block; }
            .dashboard .bottom .vanpool-details .row .stats .seats,
            .dashboard .bottom .impact-data-container .row .stats .seats,
            .dashboard .bottom .vanpool-payment-summary .row .stats .seats,
            .dashboard .bottom .trip-tools .row .stats .seats {
              margin: 0 1.5rem; }
            .dashboard .bottom .vanpool-details .row .stats .occupancy,
            .dashboard .bottom .impact-data-container .row .stats .occupancy,
            .dashboard .bottom .vanpool-payment-summary .row .stats .occupancy,
            .dashboard .bottom .trip-tools .row .stats .occupancy {
              margin: 0 1.5rem 0 0; }
        .dashboard .bottom .vanpool-details .maintenance-container,
        .dashboard .bottom .impact-data-container .maintenance-container,
        .dashboard .bottom .vanpool-payment-summary .maintenance-container,
        .dashboard .bottom .trip-tools .maintenance-container {
          cursor: pointer; }
        .dashboard .bottom .vanpool-details .maintenance,
        .dashboard .bottom .impact-data-container .maintenance,
        .dashboard .bottom .vanpool-payment-summary .maintenance,
        .dashboard .bottom .trip-tools .maintenance {
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          padding: 0.5rem 0.5rem 1rem 1rem;
          margin-bottom: 8px; }
      .dashboard .bottom .trip-banner {
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        background-color: rgba(74, 74, 74, 0.9);
        color: #FFFFFF;
        min-height: 4rem;
        padding-left: 0.75rem; }
        .dashboard .bottom .trip-banner.overdue {
          padding: 0;
          padding-left: 0.75rem;
          padding-right: 0.75rem; }
          .dashboard .bottom .trip-banner.overdue .trip-banner-message .days,
          .dashboard .bottom .trip-banner.overdue .trip-banner-message svg {
            display: block; }
        .dashboard .bottom .trip-banner .trip-banner-message {
          -webkit-box-flex: 3;
          -moz-box-flex: 3;
          -webkit-flex: 3;
          -ms-flex: 3;
          flex: 3; }
          .dashboard .bottom .trip-banner .trip-banner-message svg {
            font-size: 3rem;
            margin-right: 0.75rem; }
          .dashboard .bottom .trip-banner .trip-banner-message .message {
            font-size: 1.125rem;
            text-wrap: nowrap; }
            .dashboard .bottom .trip-banner .trip-banner-message .message.small {
              font-size: 0.875rem;
              min-width: 0; }
          .dashboard .bottom .trip-banner .trip-banner-message .days {
            font-size: 2rem; }
        .dashboard .bottom .trip-banner .trip-banner-tools .button {
          text-wrap: nowrap;
          cursor: pointer; }
          .dashboard .bottom .trip-banner .trip-banner-tools .button.cancel {
            background-color: #333333;
            color: #FFFFFF;
            border-color: #333333; }
          .dashboard .bottom .trip-banner .trip-banner-tools .button:hover, .dashboard .bottom .trip-banner .trip-banner-tools .button:focus {
            opacity: 0.8; }
      .dashboard .bottom .impact-data-container {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        overflow: visible; }
        .dashboard .bottom .impact-data-container .impact-data-content {
          display: flex;
          flex-direction: row; }
          .dashboard .bottom .impact-data-container .impact-data-content .info .message {
            width: auto; }
            .dashboard .bottom .impact-data-container .impact-data-content .info .message:before {
              left: 10rem; }
            .dashboard .bottom .impact-data-container .impact-data-content .info .message:after {
              left: calc(10rem + 1px); }
            .dashboard .bottom .impact-data-container .impact-data-content .info .message .lookup {
              width: 25rem; }
              .dashboard .bottom .impact-data-container .impact-data-content .info .message .lookup .pair {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                padding: 0.25rem 0; }
                .dashboard .bottom .impact-data-container .impact-data-content .info .message .lookup .pair .key {
                  display: inline-block;
                  width: 6rem;
                  font-weight: 600; }
                .dashboard .bottom .impact-data-container .impact-data-content .info .message .lookup .pair .value {
                  -webkit-box-flex: 1;
                  -moz-box-flex: 1;
                  -webkit-flex: 1;
                  -ms-flex: 1;
                  flex: 1;
                  display: inline-block;
                  word-wrap: wrap;
                  font-weight: 300;
                  font-style: italic;
                  color: #484848; }
          .dashboard .bottom .impact-data-container .impact-data-content .toggle {
            -webkit-flex-wrap: nowrap;
            -moz-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap; }
            .dashboard .bottom .impact-data-container .impact-data-content .toggle button {
              max-width: 3rem;
              min-width: 3rem; }
            .dashboard .bottom .impact-data-container .impact-data-content .toggle .impact-data-content .label {
              margin-top: 0; }
              .dashboard .bottom .impact-data-container .impact-data-content .toggle .impact-data-content .label .info .message {
                width: auto; }
                .dashboard .bottom .impact-data-container .impact-data-content .toggle .impact-data-content .label .info .message .lookup {
                  width: 25rem; }
          .dashboard .bottom .impact-data-container .impact-data-content .label {
            display: flex;
            padding: 0 16px 4px 16px; }
            .dashboard .bottom .impact-data-container .impact-data-content .label h3 {
              font-family: 'DIN Alternate';
              font-weight: 700;
              size: 24px; }
            .dashboard .bottom .impact-data-container .impact-data-content .label .info {
              padding-top: 18px;
              height: 20px;
              width: 20px; }
            .dashboard .bottom .impact-data-container .impact-data-content .label .time {
              padding: 18px 0 0 6px;
              font-family: "Proxima Nova";
              font-weight: 400;
              size: 14px; }
          .dashboard .bottom .impact-data-container .impact-data-content .impact-data-toggle {
            display: flex;
            align-items: center; }
            .dashboard .bottom .impact-data-container .impact-data-content .impact-data-toggle .title {
              font-family: 'DIN Alternate';
              font-weight: 700;
              size: 16px;
              align-self: right; }
      .dashboard .bottom .vanpool-payment-summary {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; }
        .dashboard .bottom .vanpool-payment-summary .tablet-left {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-justify-content: space-between;
          -moz-justify-content: space-between;
          -ms-justify-content: space-between;
          justify-content: space-between;
          -ms-flex-pack: space-between;
          -webkit-box-flex: 4;
          -moz-box-flex: 4;
          -webkit-flex: 4;
          -ms-flex: 4;
          flex: 4;
          order: 1; }
          .dashboard .bottom .vanpool-payment-summary .tablet-left .payment-group {
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between; }
            .dashboard .bottom .vanpool-payment-summary .tablet-left .payment-group .last-payment {
              width: auto; }
            .dashboard .bottom .vanpool-payment-summary .tablet-left .payment-group .button {
              width: 50%;
              margin-bottom: 0rem;
              margin-top: 2rem; }
          .dashboard .bottom .vanpool-payment-summary .tablet-left .payment-recurring {
            text-align: left; }
        .dashboard .bottom .vanpool-payment-summary .tablet-right {
          -webkit-box-flex: 5;
          -moz-box-flex: 5;
          -webkit-flex: 5;
          -ms-flex: 5;
          flex: 5;
          order: 2;
          position: relative;
          border-left: 0.125rem solid #e6e6e6; }
          .dashboard .bottom .vanpool-payment-summary .tablet-right .payment-due-date {
            position: absolute;
            width: 10rem; }
          .dashboard .bottom .vanpool-payment-summary .tablet-right .payment-balance-due {
            font-size: 2.6rem;
            text-align: right;
            padding-right: 0.5rem; }
      .dashboard .bottom .employer-content {
        border: 1px solid #CCCCCC;
        border-radius: 0.25rem; }
      .dashboard .bottom .fuel-card,
      .dashboard .bottom .contact-info {
        border: 1px solid #CCCCCC;
        border-radius: 0.25rem; }
      .dashboard .bottom .contact-info {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: flex;
        justify-content: space-between; }
        .dashboard .bottom .contact-info .contact {
          border: 0; }
      .dashboard .bottom .fuel-card {
        max-width: 24rem;
        min-width: 17rem;
        margin-right: 1rem;
        background: #FFFFFF; }
      .dashboard .bottom .ipad-horizontal {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        background: #FFFFFF; }
        .dashboard .bottom .ipad-horizontal .label {
          color: #484848; }
  .dashboard-van-details-container {
    padding: 16px 32px; }
    .dashboard-van-details-container .header {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #130C0E; }
    .dashboard-van-details-container .no-vanpool-sections {
      display: flex;
      flex-direction: column; }
      .dashboard-van-details-container .no-vanpool-sections .no-vanpool {
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        line-height: 22px;
        color: #4A4A4A; }
      .dashboard-van-details-container .no-vanpool-sections .divider {
        border-bottom: 1px solid #CCCCCC;
        margin: 16px 0px; }
      .dashboard-van-details-container .no-vanpool-sections .additional-details {
        display: flex;
        flex-direction: row;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; }
        .dashboard-van-details-container .no-vanpool-sections .additional-details img {
          height: 20px; }
        .dashboard-van-details-container .no-vanpool-sections .additional-details .wait-text {
          margin-left: 8px; }
    .dashboard-van-details-container .sections {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .dashboard-van-details-container .sections .vanpool-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #EDEDED;
        padding-left: 40px; }
        .dashboard-van-details-container .sections .vanpool-section .name {
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 700; }
        .dashboard-van-details-container .sections .vanpool-section .capacity {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          display: flex;
          color: #4A4A4A;
          column-gap: 12px; }
        .dashboard-van-details-container .sections .vanpool-section .image img {
          height: 180px; }
        .dashboard-van-details-container .sections .vanpool-section .image .image-not-found {
          height: 150px;
          margin-top: 10px;
          margin-bottom: 25px; }
        .dashboard-van-details-container .sections .vanpool-section .id {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #4A4A4A; }
      .dashboard-van-details-container .sections .commute-days-section .heading {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700;
        margin-top: 15px; }
      .dashboard-van-details-container .sections .commute-days-section .days {
        display: flex;
        column-gap: 8px;
        margin-top: 8px; }
        .dashboard-van-details-container .sections .commute-days-section .days .day-group .day {
          height: 26px;
          width: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #D5D5D5;
          border-radius: 4px;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: -0.25px;
          color: #4A4A4A;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 700;
          padding: 10px 8px;
          background: #D4EDE1; }
          .dashboard-van-details-container .sections .commute-days-section .days .day-group .day.commuting {
            color: #FFFFFF;
            background: #0F8952; }
          .dashboard-van-details-container .sections .commute-days-section .days .day-group .day.notcommuting {
            color: #130C0E; }
      .dashboard-van-details-container .sections .address-section {
        margin-top: 24px; }
        .dashboard-van-details-container .sections .address-section .addresses-container {
          display: flex;
          column-gap: 30px;
          row-gap: 30px;
          flex-wrap: wrap; }
          .dashboard-van-details-container .sections .address-section .addresses-container .address-details {
            display: flex;
            flex-direction: column; }
            .dashboard-van-details-container .sections .address-section .addresses-container .address-details .heading {
              font-family: 'Proxima Nova';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 700; }
            .dashboard-van-details-container .sections .address-section .addresses-container .address-details .van-route-details {
              margin-top: 8px;
              margin-left: 0px; }
              .dashboard-van-details-container .sections .address-section .addresses-container .address-details .van-route-details .route-icon {
                margin-left: -5px; }
                .dashboard-van-details-container .sections .address-section .addresses-container .address-details .van-route-details .route-icon .group {
                  opacity: 0.5; }
              .dashboard-van-details-container .sections .address-section .addresses-container .address-details .van-route-details .route-address .start-address {
                text-wrap: nowrap; } }

@media only screen and (min-width: 751px) {
  .dashboard .trip-tools.not-mobile {
    display: none !important;
    width: 100%; }
  .dashboard .trip-tools.mobile-only {
    display: flex !important; }
  .dashboard .trip-tools .trip-banner {
    background: rgba(19, 12, 14, 0.8);
    width: 92%;
    margin: auto;
    border-radius: 6px;
    border: 1px solid #EDEDED; }
    .dashboard .trip-tools .trip-banner .trip-banner-message.not-mobile,
    .dashboard .trip-tools .trip-banner .trip-banner-tools.not-mobile {
      display: flex !important; }
    .dashboard .trip-tools .trip-banner .trip-banner-message.mobile-only,
    .dashboard .trip-tools .trip-banner .trip-banner-tools.mobile-only {
      display: none !important; }
  .dashboard .bottom {
    position: relative;
    margin: auto; }
    .dashboard .bottom .left-side {
      margin-bottom: 24px; }
    .dashboard .bottom .right-side {
      border-radius: 8px; }
    .dashboard .bottom .new-registered-user .controls {
      position: relative;
      margin-top: 25px;
      margin-bottom: 40px;
      top: 0; }
    .dashboard .bottom .new-registered-user .benefits {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; } }

@media only screen and (max-width: 750px) {
  .dashboard .bottom {
    margin: auto 20px; }
    .dashboard .bottom .vanpool-details .maintenance {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-bottom: 24px; }
      .dashboard .bottom .vanpool-details .maintenance .arrow-right {
        margin-left: 16px; }
    .dashboard .bottom .impact-data-container .impact-data-content .info .message {
      width: 20rem; }
      .dashboard .bottom .impact-data-container .impact-data-content .info .message:before {
        left: 10rem; }
      .dashboard .bottom .impact-data-container .impact-data-content .info .message:after {
        left: calc(10rem + 1px); }
      .dashboard .bottom .impact-data-container .impact-data-content .info .message .lookup {
        width: 18rem; }
    .dashboard .bottom .impact-data-container .impact-data-content .impact-data-toggle .title {
      display: none; }
    .dashboard .bottom .left-side {
      margin-bottom: 24px; }
      .dashboard .bottom .left-side .no-vanpool-sections {
        display: flex;
        flex-direction: column; }
        .dashboard .bottom .left-side .no-vanpool-sections .no-vanpool {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          line-height: 22px;
          color: #4A4A4A; }
        .dashboard .bottom .left-side .no-vanpool-sections .divider {
          border-bottom: 1px solid #CCCCCC;
          margin: 16px 0px; }
        .dashboard .bottom .left-side .no-vanpool-sections .additional-details {
          display: flex;
          flex-direction: row;
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; }
          .dashboard .bottom .left-side .no-vanpool-sections .additional-details img {
            height: 20px; }
          .dashboard .bottom .left-side .no-vanpool-sections .additional-details .wait-text {
            margin-left: 8px; }
      .dashboard .bottom .left-side .new-registered-user .controls {
        position: relative;
        margin-top: 25px;
        margin-bottom: 40px;
        top: 0; }
      .dashboard .bottom .left-side .new-registered-user .benefits {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; }
        .dashboard .bottom .left-side .new-registered-user .benefits .contact-info {
          border-radius: 8px; }
  .dashboard .top {
    top: 6px; }
    .dashboard .top .dashboard-background img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: fill;
      height: 364px; }
    .dashboard .top .time {
      display: none; }
    .dashboard .top .letter {
      height: 25px;
      width: 25px;
      font-size: 18px;
      margin: 5px !important; }
    .dashboard .top .next {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      font-weight: 500;
      line-height: 18px;
      text-wrap: nowrap; }
  .dashboard .trip-tools.not-mobile {
    display: none !important; }
  .dashboard .trip-tools.mobile-only {
    display: flex !important;
    width: 100%; }
    .dashboard .trip-tools.mobile-only .trip-banner {
      width: 100%;
      min-height: unset;
      height: 3rem;
      background-color: #130C0E; }
      .dashboard .trip-tools.mobile-only .trip-banner.overdue {
        padding: 0; }
      .dashboard .trip-tools.mobile-only .trip-banner .trip-banner-message.not-mobile,
      .dashboard .trip-tools.mobile-only .trip-banner .trip-banner-tools.not-mobile {
        display: none !important; }
      .dashboard .trip-tools.mobile-only .trip-banner .trip-banner-message.mobile-only,
      .dashboard .trip-tools.mobile-only .trip-banner .trip-banner-tools.mobile-only {
        display: flex !important;
        height: 100%; }
        .dashboard .trip-tools.mobile-only .trip-banner .trip-banner-message.mobile-only img,
        .dashboard .trip-tools.mobile-only .trip-banner .trip-banner-tools.mobile-only img {
          margin: 0px 10px; }
      .dashboard .trip-tools.mobile-only .trip-banner .trip-banner-message svg,
      .dashboard .trip-tools.mobile-only .trip-banner .trip-banner-tools svg {
        height: 25px; }
  .dashboard .green-strip {
    padding: 0px 20px; }
    .dashboard .green-strip .left {
      padding: 22px 0px; }
      .dashboard .green-strip .left .header {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-weight: 700 !important; }
      .dashboard .green-strip .left .text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; } }

@media screen and (max-width: 767px) {
  .dashboard {
    padding-top: 0;
    width: 100%;
    margin: 60px auto 0;
    z-index: 1; }
    .dashboard .stop-tracker {
      position: relative !important; }
      .dashboard .stop-tracker .hanging-info {
        position: absolute;
        border-radius: 0px 0px 10px 0px;
        background: #130C0E;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        top: 100%;
        z-index: 100; }
      .dashboard .stop-tracker .tab-mobile-commute-only {
        display: block !important; }
      .dashboard .stop-tracker .desktop-commute-only {
        display: none !important; }
    .dashboard .predelivery-text {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      font-weight: 400;
      color: #4A4A4A;
      margin: 20px 0px; }
    .dashboard .recentLeadSubmitted-text {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      font-weight: 400;
      color: #4A4A4A;
      margin: 30px 0px; }
    .dashboard .top-dashboard-new {
      width: 100%;
      height: 400px;
      position: relative; }
      .dashboard .top-dashboard-new .dashboard-background {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0; }
        .dashboard .top-dashboard-new .dashboard-background img {
          width: 100%;
          aspect-ratio: 1/1;
          height: 400px; }
        .dashboard .top-dashboard-new .dashboard-background .tablet-only {
          display: none; }
    .dashboard .impact-data-container .impact-data .cell {
      width: 100%; }
    .dashboard .contact-info .contact {
      width: unset !important; }
      .dashboard .contact-info .contact .phone.mobile {
        display: block; }
      .dashboard .contact-info .contact .phone.desktop {
        display: none; }
      .dashboard .contact-info .contact .phone .clikable-details {
        display: flex;
        flex-direction: row; }
        .dashboard .contact-info .contact .phone .clikable-details .clickablePhone,
        .dashboard .contact-info .contact .phone .clikable-details .clickableSms {
          padding: 8px 24px;
          align-items: center;
          width: 120px;
          border-radius: 5px;
          border: 2px solid #00A664;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
          .dashboard .contact-info .contact .phone .clikable-details .clickablePhone .icon,
          .dashboard .contact-info .contact .phone .clikable-details .clickableSms .icon {
            margin-right: 5px; }
            .dashboard .contact-info .contact .phone .clikable-details .clickablePhone .icon img,
            .dashboard .contact-info .contact .phone .clikable-details .clickableSms .icon img {
              width: 18px;
              height: 22px; }
          .dashboard .contact-info .contact .phone .clikable-details .clickablePhone .label,
          .dashboard .contact-info .contact .phone .clikable-details .clickableSms .label {
            color: #00A664; }
        .dashboard .contact-info .contact .phone .clikable-details .clickableSms {
          margin-left: 24px; }
    .dashboard .contact-info .branch-contact {
      padding: 1rem;
      border-top: 1px solid #CCCCCC; }
      .dashboard .contact-info .branch-contact .contact-details {
        display: flex;
        flex-direction: column; }
        .dashboard .contact-info .branch-contact .contact-details .phone,
        .dashboard .contact-info .branch-contact .contact-details .email {
          margin: 0.5rem 0.5rem 0.5rem 0rem;
          width: auto;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          text-transform: capitalize;
          font-weight: 600;
          color: rgba(15, 137, 82, 0.8);
          text-align: left;
          text-decoration: underline; }
          .dashboard .contact-info .branch-contact .contact-details .phone :hover,
          .dashboard .contact-info .branch-contact .contact-details .email :hover {
            color: rgba(15, 137, 82, 0.8); }
          .dashboard .contact-info .branch-contact .contact-details .phone img,
          .dashboard .contact-info .branch-contact .contact-details .email img {
            height: 20px;
            width: 20px;
            margin-right: 8px; }
          .dashboard .contact-info .branch-contact .contact-details .phone .info .message,
          .dashboard .contact-info .branch-contact .contact-details .email .info .message {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 15px;
            color: #FFFFFF;
            background: #4A4A4A;
            border-color: #4A4A4A;
            left: -160px;
            top: 2rem;
            width: 260px;
            border-radius: 8px; }
            .dashboard .contact-info .branch-contact .contact-details .phone .info .message:after,
            .dashboard .contact-info .branch-contact .contact-details .email .info .message:after {
              transform: rotate(0deg);
              border-bottom: 0.5625rem solid #4A4A4A;
              left: 60%; }
            .dashboard .contact-info .branch-contact .contact-details .phone .info .message:before,
            .dashboard .contact-info .branch-contact .contact-details .email .info .message:before {
              transform: rotate(0deg);
              border-bottom: 0.5625rem solid #4A4A4A;
              left: 60%; }
            .dashboard .contact-info .branch-contact .contact-details .phone .info .message .main-content,
            .dashboard .contact-info .branch-contact .contact-details .email .info .message .main-content {
              margin: 0; }
        .dashboard .contact-info .branch-contact .contact-details .email {
          color: #049b5c;
          cursor: pointer;
          margin-top: 0px;
          text-decoration: underline; } }

@media screen and (max-width: 700px) {
  .dashboard .stop-tracker .mobile-commute-only {
    display: block !important; }
  .dashboard .stop-tracker .desktop-commute-only {
    display: none !important; }
  .dashboard .top .common-toast-container {
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .dashboard .top .column {
    border-bottom: 1px solid #FFFFFF; }
  .dashboard .top .driver-info {
    padding: 6px;
    margin-right: 0px; }
    .dashboard .top .driver-info .information {
      display: flex;
      justify-content: space-between;
      flex-grow: 1; }
      .dashboard .top .driver-info .information .tab-mobile-only {
        display: block; }
      .dashboard .top .driver-info .information .desktop-text-only {
        display: none; }
    .dashboard .top .driver-info .driver-image-container .image-container {
      height: 25px;
      width: 25px; }
      .dashboard .top .driver-info .driver-image-container .image-container .vanpooler-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-size: 14px; }
  .dashboard .top .next {
    padding: unset !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    .dashboard .top .next .next-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      height: 40px;
      border-bottom: 1px solid #FFFFFF;
      padding: 0px 10px; }
  .dashboard .top .day-details {
    position: unset !important;
    height: 40px; }
  .right-top-section {
    flex-direction: column; }
    .right-top-section .column {
      height: 40px; }
    .right-top-section .no-driver-section {
      height: 40px !important;
      min-height: unset !important;
      justify-content: space-between;
      padding: 0px 8px !important; }
      .right-top-section .no-driver-section:has(.assign-to-me) {
        flex-direction: row-reverse; }
      .right-top-section .no-driver-section .no-driver-text {
        color: #D5D5D5; } }

@media screen and (min-width: 701px) {
  .dashboard .stop-tracker .mobile-commute-only {
    display: none !important; }
  .dashboard .stop-tracker .desktop-commute-only {
    display: block !important; }
  .dashboard .top {
    position: absolute;
    width: 92%;
    top: 0.5rem;
    background: #4A4A4A;
    height: 52px;
    border-radius: 8px 8px 8px 0px; }
    .dashboard .top .common-toast-container {
      right: 5%;
      top: 16%;
      margin-top: unset !important; }
    .dashboard .top .next {
      padding-left: 16px;
      padding-right: 16px;
      height: 100%; }
      .dashboard .top .next .day-details {
        position: absolute;
        border-radius: 0px 0px 10px 10px; }
    .dashboard .top .letter {
      height: 35px;
      width: 35px;
      font-size: 18px; }
    .dashboard .top .column {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .dashboard .top .column .address-desc {
        display: flex; }
      .dashboard .top .column .desc {
        margin-right: 0.5rem;
        font-size: 1.25rem; }
      .dashboard .top .column .addr {
        color: #CCCCCC;
        font-size: 0.875rem;
        top: 0; }
    .dashboard .top .driver-info .information .tab-mobile-only {
      display: none; }
    .dashboard .top .driver-info .information .desktop-text-only {
      display: block; }
    .dashboard .top .driver-info .driver-image-container .image-container {
      height: 36px;
      width: 36px; }
      .dashboard .top .driver-info .driver-image-container .image-container .vanpooler-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px; }
    .dashboard .top .time {
      font-size: 1.5rem;
      margin: 0.5rem; }
      .dashboard .top .time span {
        font-size: 0.875rem; } }

@media screen and (max-width: 889px) {
  .dashboard .column .tab-mobile-only {
    display: block; }
  .dashboard .column .desktop-text-only {
    display: none; } }

@media screen and (min-width: 890px) {
  .dashboard .column .tab-mobile-only {
    display: none; }
  .dashboard .column .desktop-text-only {
    display: block; } }

@media only screen and (max-width: 830px) and (min-width: 701px) {
  .dashboard .top-dashboard-new .tablet-only {
    display: contents !important; } }

.dashboard .pre-delivery {
  text-align: center; }
  .dashboard .pre-delivery .title {
    color: #049b5c;
    font-weight: bold;
    font-size: 1.125rem;
    padding: 2rem 0 2rem 0; }
  .dashboard .pre-delivery .body {
    font-size: 0.875rem;
    padding: 0 2rem; }
  .dashboard .pre-delivery .footer {
    padding: 1.8rem;
    font-size: 0.75rem; }
    .dashboard .pre-delivery .footer .phone {
      font-weight: bold; }

@media (min-width: 768px) {
  .dashboard .pre-delivery .title {
    font-size: 1.25rem;
    padding: 5rem 0 2rem 0; }
  .dashboard .pre-delivery .body {
    padding: auto; }
    .dashboard .pre-delivery .body p {
      margin: 0; }
  .dashboard .pre-delivery .footer {
    padding-top: 2rem;
    font-size: 0.875rem; } }

.dashboard .registered-user .section {
  position: relative;
  border: 1px solid #CCCCCC;
  background: #FFFFFF;
  margin-bottom: 1rem;
  border-radius: 6px; }
  .dashboard .registered-user .section .head {
    display: none; }
  .dashboard .registered-user .section .upper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    padding: 1rem; }
    .dashboard .registered-user .section .upper img {
      margin-right: 1rem; }
    .dashboard .registered-user .section .upper .no-vanpool {
      font-size: 1.25rem; }
      .dashboard .registered-user .section .upper .no-vanpool span {
        font-weight: 600; }
    .dashboard .registered-user .section .upper .link {
      display: block;
      font-size: 0.875rem;
      text-decoration: underline; }
  .dashboard .registered-user .section .lower {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    background: #f2f2f2;
    padding: 1rem;
    border-top: 1px solid #CCCCCC; }
    .dashboard .registered-user .section .lower .request-result {
      font-weight: 600; }
      .dashboard .registered-user .section .lower .request-result span {
        font-weight: 400; }
    .dashboard .registered-user .section .lower > span {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      .dashboard .registered-user .section .lower > span button.request {
        background: #049b5c;
        color: #FFFFFF;
        min-width: 10rem;
        margin-left: 2rem; }
      .dashboard .registered-user .section .lower > span .search {
        display: inline;
        font-weight: 600; }
        .dashboard .registered-user .section .lower > span .search .result {
          color: #049b5c;
          font-size: 1.125rem; }
      .dashboard .registered-user .section .lower > span .more-info {
        display: inline;
        padding-bottom: 1rem; }
        .dashboard .registered-user .section .lower > span .more-info::before {
          content: ' '; }

@media (max-width: 767px) {
  .dashboard .registered-user .section {
    border-radius: 0;
    margin-bottom: 0; }
    .dashboard .registered-user .section .lower .request-result {
      font-weight: 600; }
      .dashboard .registered-user .section .lower .request-result span {
        font-weight: 400; }
    .dashboard .registered-user .section .lower > span {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .dashboard .registered-user .section .lower > span button.request {
        margin-left: 0; }
      .dashboard .registered-user .section .lower > span .more-info {
        display: inline-block;
        padding-bottom: 1rem; }
        .dashboard .registered-user .section .lower > span .more-info::before {
          content: ' '; } }

.trip-container {
  background: #EFEFEF; }
  .trip-container .trip-recording {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    padding-top: 60px; }
    .trip-container .trip-recording .popup-container .popup .message {
      background: none;
      padding: 0;
      margin: 0; }
    .trip-container .trip-recording .popup-container .popup .tools .button {
      margin-top: 1rem; }
    .trip-container .trip-recording .message {
      background: #049b5c;
      color: #FFFFFF;
      padding: 1rem;
      margin: 0.5rem 0.5rem 2rem;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      font-weight: 600; }
      .trip-container .trip-recording .message.warning {
        background: #f6db00;
        color: #333333; }
      .trip-container .trip-recording .message a {
        color: #FFFFFF; }
    .trip-container .trip-recording .top-bar {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .trip-container .trip-recording .top-bar .switch-bar ul {
        margin: 0;
        padding: 0;
        height: 3rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        -ms-align-items: flex-end;
        align-items: flex-end; }
        .trip-container .trip-recording .top-bar .switch-bar ul li a {
          border-top: solid 1px #CCCCCC;
          border-bottom: solid 1px #CCCCCC;
          color: #333333;
          font-weight: 600;
          white-space: nowrap; }
        .trip-container .trip-recording .top-bar .switch-bar ul li:first-child a {
          border: solid 1px #CCCCCC;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px; }
        .trip-container .trip-recording .top-bar .switch-bar ul li:last-child a {
          border: solid 1px #CCCCCC;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px; }
        .trip-container .trip-recording .top-bar .switch-bar ul li a {
          padding: .25rem 0.75rem; }
          .trip-container .trip-recording .top-bar .switch-bar ul li a.active {
            background-color: #049b5c;
            color: #FFFFFF;
            font-weight: 400; }
      .trip-container .trip-recording .top-bar .month-nav {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        order: 1;
        font-size: 1.25rem;
        color: #484848;
        margin: 1rem auto; }
        .trip-container .trip-recording .top-bar .month-nav .prev-month, .trip-container .trip-recording .top-bar .month-nav .curr-month {
          height: 3rem;
          transition: all linear 0.25s;
          padding: 0.75rem 0.25rem 0.25rem;
          margin: 0 0.75rem;
          border-bottom: 0.25rem solid #FFFFFF;
          cursor: pointer;
          text-transform: uppercase; }
          .trip-container .trip-recording .top-bar .month-nav .prev-month.selected, .trip-container .trip-recording .top-bar .month-nav .curr-month.selected {
            padding-top: 0.25rem;
            color: #333333;
            font-weight: 600;
            font-size: 1.35em;
            border-bottom-color: #049b5c; }
    .trip-container .trip-recording .trip-table {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-bottom: 1rem;
      position: relative; }
      .trip-container .trip-recording .trip-table.submitted .day {
        pointer-events: none; }
        .trip-container .trip-recording .trip-table.submitted .day .trip-status {
          color: #CCCCCC !important; }
          .trip-container .trip-recording .trip-table.submitted .day .trip-status .tripEdit {
            display: none; }
      .trip-container .trip-recording .trip-table .info {
        display: none; }
      .trip-container .trip-recording .trip-table .column {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 12.5%;
        position: relative;
        margin-top: 6rem; }
      .trip-container .trip-recording .trip-table .week-nav {
        position: absolute;
        top: -7rem;
        left: 0;
        width: 100vw;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: space-evenly;
        -moz-justify-content: space-evenly;
        -ms-justify-content: space-evenly;
        justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
        padding: 0 calc((100vw - 12rem) / 2);
        background: #f2f2f2;
        height: 3rem;
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: 600; }
        .trip-container .trip-recording .trip-table .week-nav .next-week, .trip-container .trip-recording .trip-table .week-nav .prev-week {
          color: #049b5c;
          font-weight: 600;
          font-size: 2rem;
          padding: 0 0 0.5rem 0; }
          .trip-container .trip-recording .trip-table .week-nav .next-week:disabled, .trip-container .trip-recording .trip-table .week-nav .prev-week:disabled {
            color: #484848;
            cursor: default; }
        .trip-container .trip-recording .trip-table .week-nav .month {
          margin: 0 0.5rem; }
      .trip-container .trip-recording .trip-table .vanpoolers {
        position: relative;
        top: -1px;
        border-top: 1px solid #CCCCCC; }
        .trip-container .trip-recording .trip-table .vanpoolers .vanpooler {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          height: 3rem;
          border-bottom: 1px solid #CCCCCC;
          position: relative; }
          .trip-container .trip-recording .trip-table .vanpoolers .vanpooler .generated-user-image {
            height: 100%;
            width: 100%;
            font-size: 1.25em;
            font-weight: 600;
            color: #FFFFFF;
            background: #049b5c;
            padding-top: 0.65rem;
            border-radius: 0;
            margin: 0;
            border: none; }
          .trip-container .trip-recording .trip-table .vanpoolers .vanpooler .name {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            -moz-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: center;
            -moz-justify-content: center;
            -ms-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            width: 12.5vw;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            height: calc(3rem - 1px);
            background: #049b5c;
            opacity: 0;
            z-index: 10;
            transition: all 0.25s linear;
            color: #FFFFFF;
            white-space: nowrap;
            padding: 0; }
            .trip-container .trip-recording .trip-table .vanpoolers .vanpooler .name .driver {
              font-size: 0.75rem;
              color: #FFFFFF;
              font-style: italic; }
          .trip-container .trip-recording .trip-table .vanpoolers .vanpooler.open .name {
            padding: 0.125rem 0.75rem;
            width: 87.5vw;
            left: 12.5vw;
            opacity: 1; }
          .trip-container .trip-recording .trip-table .vanpoolers .vanpooler:nth-child(2n+1) .generated-user-image, .trip-container .trip-recording .trip-table .vanpoolers .vanpooler:nth-child(2n+1) .name {
            background: #25ad74; }
      .trip-container .trip-recording .trip-table .driveTimeDuration {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 6.5rem;
        border-bottom: 1px solid #CCCCCC;
        position: relative;
        top: -1px; }
        .trip-container .trip-recording .trip-table .driveTimeDuration .stopwatch {
          font-size: 1.25em;
          font-weight: 600;
          color: #FFFFFF;
          padding: 0.4rem;
          border-radius: 0;
          margin: 5px;
          border: none;
          margin-top: 25px; }
        .trip-container .trip-recording .trip-table .driveTimeDuration .driveTimeHeading {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          -ms-flex-pack: center;
          width: 12.5vw;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          height: calc(3rem - 1px);
          background: #049b5c;
          opacity: 0;
          z-index: 10;
          transition: all 0.25s linear;
          color: #FFFFFF;
          white-space: nowrap;
          padding: 0; }
      .trip-container .trip-recording .trip-table .day {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 12.5%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 6rem;
        position: relative; }
        .trip-container .trip-recording .trip-table .day [tabindex='0'].label:hover {
          box-shadow: inset 0 0 0 2px #049b5c;
          cursor: pointer; }
          .trip-container .trip-recording .trip-table .day [tabindex='0'].label:hover .day-of-month, .trip-container .trip-recording .trip-table .day [tabindex='0'].label:hover .day-of-week {
            color: #049b5c; }
        .trip-container .trip-recording .trip-table .day.selected [tabindex='0'].label:hover {
          box-shadow: none;
          color: #FFFFFF; }
          .trip-container .trip-recording .trip-table .day.selected [tabindex='0'].label:hover .day-of-month, .trip-container .trip-recording .trip-table .day.selected [tabindex='0'].label:hover .day-of-week {
            color: #FFFFFF; }
        .trip-container .trip-recording .trip-table .day .label {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          -ms-flex-pack: center;
          height: 4rem;
          border-bottom: 1px solid #CCCCCC;
          position: absolute;
          top: -4rem;
          padding-top: 1rem;
          width: 100%; }
          .trip-container .trip-recording .trip-table .day .label .day-of-week {
            position: absolute;
            top: 0.5rem;
            color: #484848;
            font-size: 0.75rem; }
          .trip-container .trip-recording .trip-table .day .label .day-of-month {
            font-size: 1.25rem;
            margin-left: 0rem; }
            .trip-container .trip-recording .trip-table .day .label .day-of-month.other-month {
              color: #484848; }
          .trip-container .trip-recording .trip-table .day .label.current .day-of-week {
            color: #333333; }
        .trip-container .trip-recording .trip-table .day .trip-status {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          -ms-flex-pack: center;
          position: relative;
          font-size: 1.25rem;
          height: 3rem;
          border-right: 1px solid #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
          cursor: pointer; }
          .trip-container .trip-recording .trip-table .day .trip-status:nth-child(2n) {
            background: #f8f8f8; }
          .trip-container .trip-recording .trip-table .day .trip-status.disabled {
            background: #FFFFFF;
            pointer-events: none;
            color: #b3b3b3; }
          .trip-container .trip-recording .trip-table .day .trip-status.canChange {
            background: #FFFFFF;
            color: #b3b3b3; }
          .trip-container .trip-recording .trip-table .day .trip-status.changed {
            background: #049b5c;
            color: #FFFFFF; }
            .trip-container .trip-recording .trip-table .day .trip-status.changed .tripTime .tripTimeResult .tripEdit {
              color: #fff; }
          .trip-container .trip-recording .trip-table .day .trip-status.selected {
            border: 0.125rem solid #049b5c !important; }
            .trip-container .trip-recording .trip-table .day .trip-status.selected.changed {
              border: 0.125rem solid #03693e !important; }
          .trip-container .trip-recording .trip-table .day .trip-status:focus {
            border: solid 2px #05cd7a;
            box-shadow: none; }
          .trip-container .trip-recording .trip-table .day .trip-status.saved {
            color: #049b5c; }
          .trip-container .trip-recording .trip-table .day .trip-status.trip-time {
            height: 6.5rem; }
          .trip-container .trip-recording .trip-table .day .trip-status .symbol {
            opacity: 0;
            animation: fade-in 0.4s linear forwards; }
          .trip-container .trip-recording .trip-table .day .trip-status .tripTime {
            font-size: 9px;
            font-weight: 700;
            padding: 5px; }
            .trip-container .trip-recording .trip-table .day .trip-status .tripTime svg {
              font-size: 20px; }
            .trip-container .trip-recording .trip-table .day .trip-status .tripTime .tripTimeResult {
              padding-top: 5px;
              text-align: center; }
            .trip-container .trip-recording .trip-table .day .trip-status .tripTime .time {
              border-bottom: 1px solid #ccc;
              padding: 2px; }
            .trip-container .trip-recording .trip-table .day .trip-status .tripTime .mileage {
              border-bottom: 1px solid #ccc;
              padding: 2px; }
            .trip-container .trip-recording .trip-table .day .trip-status .tripTime .tripEdit {
              color: #049b5c;
              font-size: 11px; }
            .trip-container .trip-recording .trip-table .day .trip-status .tripTime .dailytripTimeModal-container .modal {
              color: #333; }
              .trip-container .trip-recording .trip-table .day .trip-status .tripTime .dailytripTimeModal-container .modal .body .overlay {
                opacity: 0.5; }
        .trip-container .trip-recording .trip-table .day .edit-trip-status {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          -webkit-justify-content: space-around;
          -moz-justify-content: space-around;
          -ms-justify-content: space-around;
          justify-content: space-around;
          -ms-flex-pack: space-around;
          position: fixed;
          background: #FFFFFF;
          color: #333333;
          padding: 0.25rem 0;
          z-index: 100;
          box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.25);
          border-top: 1px solid rgba(0, 0, 0, 0.25);
          width: 100vw;
          height: 3rem;
          left: 0;
          bottom: 0;
          font-size: 1.5rem;
          animation: slide-up 0.4s ease; }
          .trip-container .trip-recording .trip-table .day .edit-trip-status div {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-around;
            -moz-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            -ms-flex-pack: space-around;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            border-radius: 2rem; }
          .trip-container .trip-recording .trip-table .day .edit-trip-status:after {
            display: none; }
          .trip-container .trip-recording .trip-table .day .edit-trip-status svg {
            font-size: 2rem;
            position: relative; }
          .trip-container .trip-recording .trip-table .day .edit-trip-status .selected {
            background: #049b5c;
            color: #FFFFFF; }
        .trip-container .trip-recording .trip-table .day .none .trip-status {
          background: #f2f2f2 !important;
          color: transparent !important; }
          .trip-container .trip-recording .trip-table .day .none .trip-status.disabled {
            background: #FFFFFF !important;
            color: #b3b3b3 !important; }
          .trip-container .trip-recording .trip-table .day .none .trip-status.trip-time {
            color: #333 !important; }
          .trip-container .trip-recording .trip-table .day .none .trip-status .tripTime {
            color: #333 !important; }
        .trip-container .trip-recording .trip-table .day .future {
          background-image: url("./images/blocked-out.png");
          background-attachment: fixed;
          border-bottom: 1px solid #CCCCCC;
          border-right: 1px solid #CCCCCC;
          height: calc(100% - 1px); }
        .trip-container .trip-recording .trip-table .day.selected {
          border: 3px solid #03693e;
          margin: -3px;
          margin-top: calc(6rem - 2px);
          z-index: 1; }
          .trip-container .trip-recording .trip-table .day.selected .trip-status {
            border-right: none; }
          .trip-container .trip-recording .trip-table .day.selected .label {
            background: #03693e;
            box-shadow: -3px -3px 0 0 #03693e, 3px -3px 0 0 #03693e; }
            .trip-container .trip-recording .trip-table .day.selected .label .day-of-month, .trip-container .trip-recording .trip-table .day.selected .label .day-of-week, .trip-container .trip-recording .trip-table .day.selected .label.current .day-of-week {
              color: #FFFFFF; }
        .trip-container .trip-recording .trip-table .day.sun .trip-status, .trip-container .trip-recording .trip-table .day.sun .future {
          border-left: 1px solid #CCCCCC; }
          .trip-container .trip-recording .trip-table .day.sun .trip-status:focus, .trip-container .trip-recording .trip-table .day.sun .future:focus {
            border-left: solid 2px #05cd7a; }
    .trip-container .trip-recording .calendar {
      position: relative;
      width: 100%;
      min-height: 20rem; }
      .trip-container .trip-recording .calendar .week {
        display: none; }
      .trip-container .trip-recording .calendar .month {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        -ms-justify-content: space-around;
        justify-content: space-around;
        -ms-flex-pack: space-around; }
        .trip-container .trip-recording .calendar .month .day {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-flex: 1 1 auto;
          -moz-box-flex: 1 1 auto;
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          position: relative;
          text-transform: uppercase;
          text-align: center;
          width: 80vw;
          padding: 1vw;
          margin: 1vw auto;
          border-radius: 0;
          border-bottom: 1px solid #e6e6e6; }
          .trip-container .trip-recording .calendar .month .day.future {
            display: none; }
          .trip-container .trip-recording .calendar .month .day.empty, .trip-container .trip-recording .calendar .month .day.disabled {
            color: #e6e6e6; }
          .trip-container .trip-recording .calendar .month .day .dow {
            position: absolute;
            left: -1.5rem;
            font-size: 0.75rem;
            color: #484848; }
          .trip-container .trip-recording .calendar .month .day .date {
            font-size: 3rem;
            height: 4.5rem;
            width: 4.5rem;
            margin: auto 15vw auto 0.5rem;
            text-align: center; }
          .trip-container .trip-recording .calendar .month .day.current .date {
            background: #049b5c;
            color: #FFFFFF;
            border-radius: 25rem; }
          .trip-container .trip-recording .calendar .month .day.saved .to-work, .trip-container .trip-recording .calendar .month .day.saved .to-home {
            color: #049b5c; }
          .trip-container .trip-recording .calendar .month .day .to-work, .trip-container .trip-recording .calendar .month .day .to-home {
            -webkit-box-flex: 1;
            -moz-box-flex: 1;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
            position: relative;
            display: inline-block;
            font-size: 2rem;
            max-width: 3rem;
            max-height: 3rem;
            transition: background-color 0.5s linear;
            border-radius: 2rem;
            margin-left: 2.5vw; }
            .trip-container .trip-recording .calendar .month .day .to-work.selected, .trip-container .trip-recording .calendar .month .day .to-work.different, .trip-container .trip-recording .calendar .month .day .to-home.selected, .trip-container .trip-recording .calendar .month .day .to-home.different {
              color: #FFFFFF; }
              .trip-container .trip-recording .calendar .month .day .to-work.selected:before, .trip-container .trip-recording .calendar .month .day .to-work.different:before, .trip-container .trip-recording .calendar .month .day .to-home.selected:before, .trip-container .trip-recording .calendar .month .day .to-home.different:before {
                content: ' ';
                background: #049b5c;
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                border-radius: 2rem; }
            .trip-container .trip-recording .calendar .month .day .to-work.greyed, .trip-container .trip-recording .calendar .month .day .to-home.greyed {
              color: #CCCCCC; }
            .trip-container .trip-recording .calendar .month .day .to-work svg, .trip-container .trip-recording .calendar .month .day .to-home svg {
              position: relative;
              top: -3px; }
          .trip-container .trip-recording .calendar .month .day .editor {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            -webkit-justify-content: space-around;
            -moz-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            -ms-flex-pack: space-around;
            position: fixed;
            background: #FFFFFF;
            color: #333333;
            padding: 0.5rem;
            z-index: 100;
            width: 100vw;
            bottom: 0;
            left: 0;
            font-size: 1.5rem;
            box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.25);
            border-top: 1px solid rgba(0, 0, 0, 0.25);
            animation: slide-up 0.4s ease; }
            .trip-container .trip-recording .calendar .month .day .editor .icon {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-flex-direction: column;
              -moz-flex-direction: column;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-align-items: center;
              -moz-align-items: center;
              -ms-align-items: center;
              align-items: center;
              height: 4rem;
              width: 25vw;
              position: relative;
              padding-top: 0.5rem; }
              .trip-container .trip-recording .calendar .month .day .editor .icon svg {
                font-size: 1.125rem; }
                .trip-container .trip-recording .calendar .month .day .editor .icon svg.less-shift {
                  top: -2px; }
              .trip-container .trip-recording .calendar .month .day .editor .icon.selected:before {
                content: '';
                position: absolute;
                height: 1.5rem;
                width: 1.5rem;
                border-radius: 1rem;
                background: #049b5c;
                top: 3px;
                left: calc(50% - 0.75rem); }
              .trip-container .trip-recording .calendar .month .day .editor .icon.selected svg {
                color: #FFFFFF; }
              .trip-container .trip-recording .calendar .month .day .editor .icon .label {
                font-size: 0.75rem;
                text-transform: capitalize;
                max-width: 4rem; }
                .trip-container .trip-recording .calendar .month .day .editor .icon .label.did-not-commute, .trip-container .trip-recording .calendar .month .day .editor .icon .label[class~="ride-hailing-(tnc)"] {
                  font-size: 0.6rem; }
            .trip-container .trip-recording .calendar .month .day .editor svg {
              font-size: 2rem;
              position: relative; }
        .trip-container .trip-recording .calendar .month.approved {
          pointer-events: none; }
          .trip-container .trip-recording .calendar .month.approved .day .date {
            color: #CCCCCC !important; }
          .trip-container .trip-recording .calendar .month.approved .day .to-home, .trip-container .trip-recording .calendar .month.approved .day .to-work {
            color: #049b5c !important;
            opacity: 0.3; }
    .trip-container .trip-recording .legend {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      color: #484848;
      font-size: 0.75rem; }
      .trip-container .trip-recording .legend .symbols, .trip-container .trip-recording .legend .colors {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 50%; }
      .trip-container .trip-recording .legend .pair {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        height: 2rem;
        padding-left: 1rem; }
        .trip-container .trip-recording .legend .pair:not(:last-child) {
          margin-right: 1rem; }
        .trip-container .trip-recording .legend .pair .symbol {
          display: inline-block;
          font-size: 1.125rem;
          color: #333333;
          margin-right: 0.5rem;
          width: 1rem;
          text-align: center; }
          .trip-container .trip-recording .legend .pair .symbol svg {
            position: relative;
            top: -2px; }
        .trip-container .trip-recording .legend .pair .color {
          display: block;
          height: 1rem;
          width: 1rem;
          border-radius: 0.5rem;
          margin-right: 0.5rem; }
          .trip-container .trip-recording .legend .pair .color.black {
            background: #333333; }
          .trip-container .trip-recording .legend .pair .color.green {
            background: #049b5c; }
          .trip-container .trip-recording .legend .pair .color.grey {
            background: #CCCCCC; }
    .trip-container .trip-recording .tools {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .trip-container .trip-recording .tools p {
        width: 100%;
        text-align: center;
        font-size: 0.75rem;
        font-style: italic;
        padding: 0.5rem;
        order: 1; }
      .trip-container .trip-recording .tools .action {
        text-align: center; }
    .trip-container .trip-recording .impact-data {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      position: relative;
      padding: 3rem 2rem 0;
      box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.14);
      width: 100vw;
      margin: 2rem 0 0;
      background: #FFFFFF; }
      .trip-container .trip-recording .impact-data .impact-title {
        position: absolute;
        width: 18rem;
        top: 0.75rem;
        margin: 0 auto;
        text-align: left;
        padding-left: 0.5rem; }
        .trip-container .trip-recording .impact-data .impact-title .info {
          position: absolute;
          top: -2px;
          right: 1rem; }
          .trip-container .trip-recording .impact-data .impact-title .info .message {
            top: 100%;
            background: #FFFFFF; }
            .trip-container .trip-recording .impact-data .impact-title .info .message:before {
              left: 13rem; }
            .trip-container .trip-recording .impact-data .impact-title .info .message:after {
              left: calc(13rem + 1px); }
            .trip-container .trip-recording .impact-data .impact-title .info .message .pair {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              padding: 0.25rem 0; }
              .trip-container .trip-recording .impact-data .impact-title .info .message .pair .key {
                display: inline-block;
                width: 6rem;
                font-weight: 600;
                color: #333333; }
              .trip-container .trip-recording .impact-data .impact-title .info .message .pair .value {
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                -webkit-flex: 1;
                -ms-flex: 1;
                flex: 1;
                display: inline-block;
                word-wrap: wrap;
                font-weight: 300;
                font-style: italic;
                color: #484848; }
      .trip-container .trip-recording .impact-data .cell {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        margin: 0.5rem;
        padding: 0.5rem;
        border: 0.125rem solid #CCCCCC;
        border-radius: 0.25rem;
        min-width: 13rem; }
        .trip-container .trip-recording .impact-data .cell img {
          position: relative;
          top: 10px; }
        .trip-container .trip-recording .impact-data .cell.miles-saved img {
          top: 14px; }
        .trip-container .trip-recording .impact-data .cell.parking-savings img {
          top: 0; }
        .trip-container .trip-recording .impact-data .cell > span {
          display: block;
          text-align: right; }
          .trip-container .trip-recording .impact-data .cell > span label {
            display: block;
            font-size: 0.75rem;
            color: #b3b3b3;
            text-transform: uppercase; }
          .trip-container .trip-recording .impact-data .cell > span .value {
            font-size: 1.25rem;
            font-weight: 600; }
          .trip-container .trip-recording .impact-data .cell > span .units {
            font-size: 0.875rem;
            color: #b3b3b3;
            font-weight: 300;
            margin-left: 0.25rem; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@media (min-width: 768px) {
  .trip-container {
    background-color: #EFEFEF; }
    .trip-container .trip-recording {
      padding-top: 50px;
      margin-top: 120px;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .trip-container .trip-recording .message {
        padding: 1rem 2rem;
        margin-left: 1rem;
        margin-right: 1rem; }
      .trip-container .trip-recording .top-bar {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between; }
        .trip-container .trip-recording .top-bar .month-nav {
          order: 0;
          margin: 1rem 0; }
        .trip-container .trip-recording .top-bar .switch-bar {
          margin-right: 0.5rem; }
      .trip-container .trip-recording .trip-table {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 0px 10px; }
        .trip-container .trip-recording .trip-table .info {
          display: block;
          position: absolute;
          color: #049b5c;
          left: calc(30% - 2.5rem);
          top: 0.75rem;
          z-index: 20; }
          .trip-container .trip-recording .trip-table .info .message {
            color: #333333;
            background: #FFFFFF; }
            .trip-container .trip-recording .trip-table .info .message p {
              position: relative;
              margin-bottom: 0.5rem; }
              .trip-container .trip-recording .trip-table .info .message p:last-child {
                margin-bottom: 0; }
              .trip-container .trip-recording .trip-table .info .message p span {
                position: absolute;
                left: 2rem; }
        .trip-container .trip-recording .trip-table .column {
          -webkit-box-flex: 3;
          -moz-box-flex: 3;
          -webkit-flex: 3;
          -ms-flex: 3;
          flex: 3;
          margin-top: 0; }
        .trip-container .trip-recording .trip-table .week-nav {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          -webkit-justify-content: flex-start;
          -moz-justify-content: flex-start;
          -ms-justify-content: flex-start;
          justify-content: flex-start;
          -ms-flex-pack: flex-start;
          position: static;
          width: auto;
          padding: 0;
          background: transparent;
          height: 3rem;
          font-size: 1.25rem;
          text-transform: uppercase;
          font-weight: 600; }
          .trip-container .trip-recording .trip-table .week-nav .next-week, .trip-container .trip-recording .trip-table .week-nav .prev-week {
            color: #049b5c;
            font-weight: 600;
            font-size: 2rem;
            padding: 0 0 0.5rem 0; }
            .trip-container .trip-recording .trip-table .week-nav .next-week:disabled, .trip-container .trip-recording .trip-table .week-nav .prev-week:disabled {
              color: #484848;
              cursor: default; }
          .trip-container .trip-recording .trip-table .week-nav .month {
            margin: 0 0.5rem; }
        .trip-container .trip-recording .trip-table .vanpoolers {
          top: 0;
          border-top: none; }
          .trip-container .trip-recording .trip-table .vanpoolers .vanpooler {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            height: 4rem;
            border-bottom: 1px solid #CCCCCC;
            padding-left: 0.5rem; }
            .trip-container .trip-recording .trip-table .vanpoolers .vanpooler .generated-user-image {
              height: 2.5rem;
              width: 2.5rem;
              font-size: 1.25em;
              font-weight: 600;
              color: #049b5c;
              background: #FFFFFF;
              border: 1px solid #049b5c;
              padding-top: 0.25rem;
              margin-right: 1rem;
              border-radius: 2.5rem; }
            .trip-container .trip-recording .trip-table .vanpoolers .vanpooler:hover .name, .trip-container .trip-recording .trip-table .vanpoolers .vanpooler .name, .trip-container .trip-recording .trip-table .vanpoolers .vanpooler:nth-child(2n+1) .name {
              position: static;
              display: block;
              color: #333333;
              background: #FFFFFF;
              width: auto;
              padding: 0 0.75rem;
              height: auto;
              opacity: 1; }
              .trip-container .trip-recording .trip-table .vanpoolers .vanpooler:hover .name .driver, .trip-container .trip-recording .trip-table .vanpoolers .vanpooler .name .driver, .trip-container .trip-recording .trip-table .vanpoolers .vanpooler:nth-child(2n+1) .name .driver {
                font-size: 0.75rem;
                color: #b3b3b3;
                font-style: italic; }
            .trip-container .trip-recording .trip-table .vanpoolers .vanpooler:nth-child(2n+1) .generated-user-image {
              background: #FFFFFF; }
        .trip-container .trip-recording .trip-table .driveTimeDuration {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          height: 5rem;
          border-bottom: 1px solid #CCCCCC;
          margin-top: 10px;
          border-top: 1px solid #CCCCCC;
          top: 0px; }
          .trip-container .trip-recording .trip-table .driveTimeDuration .stopwatch {
            font-size: 1.25em;
            font-weight: 600;
            color: #FFFFFF;
            padding: 0px;
            border-radius: 0;
            margin: 10px 8px;
            border: none;
            margin-top: 20px; }
          .trip-container .trip-recording .trip-table .driveTimeDuration .driveTimeHeading {
            width: 10.5vw;
            overflow: hidden;
            position: relative;
            height: auto;
            background: #fff;
            opacity: 1;
            z-index: 10;
            transition: all 0.25s linear;
            color: #333;
            white-space: nowrap;
            padding: 0;
            margin: 20px; }
          .trip-container .trip-recording .trip-table .driveTimeDuration .info {
            display: block;
            position: relative;
            color: #049b5c;
            z-index: 20;
            left: -10px;
            height: 30px;
            top: 1.8rem; }
            .trip-container .trip-recording .trip-table .driveTimeDuration .info svg {
              color: #049b5c;
              margin: 0px; }
        .trip-container .trip-recording .trip-table .day {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          margin-top: 0; }
          .trip-container .trip-recording .trip-table .day .label {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            -moz-justify-content: center;
            -ms-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            min-height: 3rem;
            height: 3rem;
            padding-top: 0;
            position: static;
            border-bottom: 1px solid #CCCCCC; }
            .trip-container .trip-recording .trip-table .day .label .day-of-week {
              position: static;
              font-size: 0.875rem;
              color: #484848;
              text-transform: uppercase; }
            .trip-container .trip-recording .trip-table .day .label .day-of-month {
              font-size: 1.25rem;
              margin-left: 0.5rem; }
              .trip-container .trip-recording .trip-table .day .label .day-of-month.other-month {
                color: #484848; }
          .trip-container .trip-recording .trip-table .day .trip-status {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            -moz-justify-content: center;
            -ms-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            font-size: 2rem;
            height: 4rem;
            border-right: 1px solid #CCCCCC;
            border-bottom: 1px solid #CCCCCC;
            cursor: pointer; }
            .trip-container .trip-recording .trip-table .day .trip-status .symbol {
              opacity: 0;
              animation: fade-in 0.4s linear forwards; }
            .trip-container .trip-recording .trip-table .day .trip-status .tripTime {
              font-size: 12px;
              font-weight: 600;
              padding: 0; }
              .trip-container .trip-recording .trip-table .day .trip-status .tripTime svg {
                font-size: 2.25em; }
              .trip-container .trip-recording .trip-table .day .trip-status .tripTime .tripTimeResult {
                text-align: center; }
              .trip-container .trip-recording .trip-table .day .trip-status .tripTime .mileage {
                padding-top: 2px;
                border: none; }
              .trip-container .trip-recording .trip-table .day .trip-status .tripTime .time {
                border: none; }
              .trip-container .trip-recording .trip-table .day .trip-status .tripTime .tripEdit {
                color: #049b5c;
                padding: 5px 0px;
                font-size: 14px; }
              .trip-container .trip-recording .trip-table .day .trip-status .tripTime .dailytripTimeModal-container .body .overlay {
                opacity: 0.5; }
          .trip-container .trip-recording .trip-table .day .trip-status.trip-time {
            height: 5rem;
            border-bottom: 1px solid #CCCCCC;
            margin-top: 10px;
            border-top: 1px solid #CCCCCC; }
          .trip-container .trip-recording .trip-table .day .edit-trip-status {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            -webkit-justify-content: space-around;
            -moz-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            -ms-flex-pack: space-around;
            position: absolute;
            background: #FFFFFF;
            color: #333333;
            padding: 0.25rem 0;
            z-index: 100;
            border-radius: 5rem;
            box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.25);
            width: 15rem;
            height: 3rem;
            top: -3.5rem;
            left: calc(50% - 7.5rem);
            font-size: 1.5rem; }
            .trip-container .trip-recording .trip-table .day .edit-trip-status div {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-justify-content: space-around;
              -moz-justify-content: space-around;
              -ms-justify-content: space-around;
              justify-content: space-around;
              -ms-flex-pack: space-around;
              -webkit-align-items: center;
              -moz-align-items: center;
              -ms-align-items: center;
              align-items: center;
              width: 2rem;
              height: 2rem;
              border-radius: 2rem; }
            .trip-container .trip-recording .trip-table .day .edit-trip-status:after {
              display: block;
              content: '';
              position: absolute;
              bottom: -0.5rem;
              left: 7rem;
              height: 1rem;
              width: 1rem;
              background: #FFFFFF;
              transform: rotate(45deg);
              box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
              z-index: -1; }
            .trip-container .trip-recording .trip-table .day .edit-trip-status svg {
              font-size: 2rem;
              position: relative; }
            .trip-container .trip-recording .trip-table .day .edit-trip-status .selected {
              background: #049b5c;
              color: #FFFFFF; }
          .trip-container .trip-recording .trip-table .day.sat .edit-trip-status {
            left: -10rem; }
            .trip-container .trip-recording .trip-table .day.sat .edit-trip-status:after {
              left: 12rem; }
          .trip-container .trip-recording .trip-table .day .future {
            -webkit-flex-grow: 1;
            -moz-flex-grow: 1;
            -ms-flex-grow: 1;
            flex-grow: 1;
            background-image: url("./images/blocked-out.png");
            background-attachment: fixed;
            border-bottom: 1px solid #CCCCCC;
            border-right: 1px solid #CCCCCC; }
          .trip-container .trip-recording .trip-table .day .trip-status:not(.changed) {
            background: #FFFFFF; }
          .trip-container .trip-recording .trip-table .day.selected {
            margin: -3px; }
          .trip-container .trip-recording .trip-table .day.sun .trip-status, .trip-container .trip-recording .trip-table .day.sun .future {
            border-left: 1px solid #CCCCCC; }
      .trip-container .trip-recording .tools {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        .trip-container .trip-recording .tools button {
          margin: 0.5rem; }
        .trip-container .trip-recording .tools .action {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-justify-content: flex-end;
          -moz-justify-content: flex-end;
          -ms-justify-content: flex-end;
          justify-content: flex-end;
          -ms-flex-pack: flex-end;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          max-width: 1024px;
          width: 100%;
          margin: 0 auto; }
          .trip-container .trip-recording .tools .action p {
            max-width: 18rem;
            text-align: right;
            margin-bottom: 0;
            margin-right: 1rem;
            order: 0; }
      .trip-container .trip-recording .calendar .week {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        -ms-justify-content: space-around;
        justify-content: space-around;
        -ms-flex-pack: space-around; }
        .trip-container .trip-recording .calendar .week .day {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          text-transform: uppercase;
          text-align: center; }
      .trip-container .trip-recording .calendar .month {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        .trip-container .trip-recording .calendar .month .day {
          display: block;
          min-width: 11%;
          max-width: 11%;
          margin: 1%;
          padding: 1%;
          border: none; }
          .trip-container .trip-recording .calendar .month .day:not(.empty) {
            border-radius: 0.5rem;
            transition: background-color 0.25s linear;
            cursor: pointer; }
            .trip-container .trip-recording .calendar .month .day:not(.empty):hover, .trip-container .trip-recording .calendar .month .day:not(.empty):focus {
              background: #f2f2f2; }
          .trip-container .trip-recording .calendar .month .day.empty, .trip-container .trip-recording .calendar .month .day.future, .trip-container .trip-recording .calendar .month .day.disabled {
            display: block;
            color: #e6e6e6; }
            .trip-container .trip-recording .calendar .month .day.empty .to-home, .trip-container .trip-recording .calendar .month .day.empty .to-work, .trip-container .trip-recording .calendar .month .day.future .to-home, .trip-container .trip-recording .calendar .month .day.future .to-work, .trip-container .trip-recording .calendar .month .day.disabled .to-home, .trip-container .trip-recording .calendar .month .day.disabled .to-work {
              color: #FFFFFF; }
          .trip-container .trip-recording .calendar .month .day .date {
            margin: auto; }
          .trip-container .trip-recording .calendar .month .day .to-work, .trip-container .trip-recording .calendar .month .day .to-home {
            font-size: 1.125rem;
            position: relative;
            height: 1.5rem;
            width: 1.5rem;
            margin: 0.25rem 0.25rem; }
          .trip-container .trip-recording .calendar .month .day .editor {
            position: absolute;
            border-radius: 1.5rem;
            width: 25rem;
            height: 8rem;
            bottom: 2.25rem;
            left: calc(-12.5rem + 50%);
            padding-top: 1rem; }
            .trip-container .trip-recording .calendar .month .day .editor .icon {
              width: 4rem;
              position: relative;
              top: -0.5rem;
              padding-top: 0.5rem; }
            .trip-container .trip-recording .calendar .month .day .editor:after {
              display: block;
              content: '';
              position: absolute;
              bottom: -0.5rem;
              left: 12rem;
              height: 1rem;
              width: 1rem;
              background: #FFFFFF;
              transform: rotate(45deg);
              box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
              z-index: -1; }
            .trip-container .trip-recording .calendar .month .day .editor svg {
              font-size: 2rem;
              position: relative; }
          .trip-container .trip-recording .calendar .month .day.sat .editor {
            left: calc(-22.5rem + 50%); }
            .trip-container .trip-recording .calendar .month .day.sat .editor:after {
              left: 22rem; }
          .trip-container .trip-recording .calendar .month .day.fri .editor {
            left: calc(-17.5rem + 50%); }
            .trip-container .trip-recording .calendar .month .day.fri .editor:after {
              left: 17rem; }
          .trip-container .trip-recording .calendar .month .day.sun .editor {
            left: calc(-2.5rem + 50%); }
            .trip-container .trip-recording .calendar .month .day.sun .editor:after {
              left: 2rem; }
          .trip-container .trip-recording .calendar .month .day.mon .editor {
            left: calc(-7.5rem + 50%); }
            .trip-container .trip-recording .calendar .month .day.mon .editor:after {
              left: 7rem; }
      .trip-container .trip-recording .legend {
        padding: 0 0.5rem; }
        .trip-container .trip-recording .legend.registered::before {
          content: ' '; }
        .trip-container .trip-recording .legend .symbols, .trip-container .trip-recording .legend .colors {
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          width: auto; }
        .trip-container .trip-recording .legend .pair {
          padding-left: 0; }
      .trip-container .trip-recording .impact-data {
        background: #FFFFFF; }
        .trip-container .trip-recording .impact-data .impact-title .info {
          right: auto; }
          .trip-container .trip-recording .impact-data .impact-title .info .message {
            width: auto;
            left: -5rem;
            right: auto; }
            .trip-container .trip-recording .impact-data .impact-title .info .message:before {
              left: calc(5rem  - 1px);
              right: auto; }
            .trip-container .trip-recording .impact-data .impact-title .info .message:after {
              left: 5rem;
              right: auto; }
            .trip-container .trip-recording .impact-data .impact-title .info .message .lookup {
              width: 25rem; } }

@media (min-width: 1024px) {
  .trip-container {
    background: #EFEFEF; }
    .trip-container .trip-recording .impact-data {
      background: #FFFFFF;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin: 0rem calc((1024px - 100vw) / 2);
      padding-bottom: 1rem; } }

.trip-recording .expenses {
  width: 100%;
  padding: 0px 20px 10px; }
  .trip-recording .expenses .update {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    -ms-flex-pack: space-around;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin: 2rem 0 .75rem;
    color: #b3b3b3; }
    .trip-recording .expenses .update .totals {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 25px;
      color: #333333; }
    .trip-recording .expenses .update .last-updated {
      font-style: italic;
      font-size: 0.875rem;
      margin-top: 25px; }
  .trip-recording .expenses .stats {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1.5rem; }
    .trip-recording .expenses .stats .fuel-expense,
    .trip-recording .expenses .stats .fuel-gallons,
    .trip-recording .expenses .stats .other-expenses {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      border: solid 1px #CCCCCC;
      border-radius: 4px;
      position: relative;
      width: 95%;
      margin-bottom: 1rem; }
      .trip-recording .expenses .stats .fuel-expense img,
      .trip-recording .expenses .stats .fuel-gallons img,
      .trip-recording .expenses .stats .other-expenses img {
        position: relative;
        left: 1rem; }
      .trip-recording .expenses .stats .fuel-expense .column,
      .trip-recording .expenses .stats .fuel-gallons .column,
      .trip-recording .expenses .stats .other-expenses .column {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        -ms-align-items: flex-end;
        align-items: flex-end;
        padding-right: 10px; }
        .trip-recording .expenses .stats .fuel-expense .column .label,
        .trip-recording .expenses .stats .fuel-gallons .column .label,
        .trip-recording .expenses .stats .other-expenses .column .label {
          font-size: 0.6875rem;
          text-transform: uppercase;
          font-weight: 300; }
        .trip-recording .expenses .stats .fuel-expense .column .data,
        .trip-recording .expenses .stats .fuel-gallons .column .data,
        .trip-recording .expenses .stats .other-expenses .column .data {
          animation: fade-in 0.4s linear forwards;
          font-size: 1.5rem;
          font-weight: 600; }
  .trip-recording .expenses .big-buttons {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .trip-recording .expenses .big-buttons .button {
      height: 165px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      color: #333333;
      margin-bottom: .5rem;
      text-transform: uppercase;
      padding: 0.25rem;
      font-size: 16px; }
      .trip-recording .expenses .big-buttons .button img {
        height: 80px; }
      .trip-recording .expenses .big-buttons .button:first-child {
        margin-right: .5rem; }
      .trip-recording .expenses .big-buttons .button:last-child {
        margin-left: .5rem; }
      .trip-recording .expenses .big-buttons .button:not(.active) {
        border: 0.125rem solid #CCCCCC; }
  .trip-recording .expenses .add-on-expense {
    height: 300px;
    margin-bottom: 3rem;
    font-size: 14px;
    animation-name: expensePopup;
    animation-duration: .2s; }
    .trip-recording .expenses .add-on-expense .addexpenses {
      margin: 0 auto;
      width: 95%; }
      .trip-recording .expenses .add-on-expense .addexpenses .expensesInput {
        margin: 15px 0;
        width: 100%;
        position: relative; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-calendar {
          width: 310px; }
          .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-calendar .react-calendar__tile--active {
            background: #049b5c;
            color: white; }
          .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-calendar .react-calendar__tile:focus {
            box-shadow: none; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-datetime-picker {
          width: 100%; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-datetime-picker__inputGroup__leadingZero {
          padding-left: 3px;
          font-size: 18px; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-datetime-picker__inputGroup__year {
          width: 50px; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-datetime-picker__inputGroup:focus {
          box-shadow: 0 0 0.5rem 0 #4A90E2; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-datetime-picker__calendar-button:focus {
          box-shadow: 0 0 0.2rem 0 #4A90E2; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-datetime-picker__calendar-button:enabled:focus .react-datetime-picker__calendar-button__icon {
          stroke: #049b5c; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-datetime-picker__calendar-button .react-datetime-picker__calendar-button__icon {
          position: static;
          height: 22px;
          width: 22px; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .react-datetime-picker__calendar-button:enabled:hover .react-datetime-picker__calendar-button__icon {
          stroke: #049b5c; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput svg {
          position: absolute;
          top: 40px;
          font-size: 18px;
          color: #cccc; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .vendor.invalid {
          border: 1px solid red; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .vendor.invalid:focus {
          border: 1px solid red;
          box-shadow: none; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput .dollars {
          padding-left: 18px; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput input[type=text] {
          width: 100%; }
          .trip-recording .expenses .add-on-expense .addexpenses .expensesInput input[type=text]::placeholder {
            color: #e6e6e6;
            opacity: 1; }
          .trip-recording .expenses .add-on-expense .addexpenses .expensesInput input[type=text].invalid {
            border: 1px solid red; }
          .trip-recording .expenses .add-on-expense .addexpenses .expensesInput input[type=text].invalid:focus {
            border: 1px solid red;
            box-shadow: none; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput input[type=number].react-datetime-picker__inputGroup__input {
          border: 0;
          height: calc(100% - 15px);
          padding: 6px 3px;
          font-size: 18px; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput input[type=number].react-datetime-picker__inputGroup__input:focus {
          box-shadow: none; }
        .trip-recording .expenses .add-on-expense .addexpenses .expensesInput label {
          display: block;
          text-transform: uppercase;
          color: #333333;
          margin-bottom: .25rem; }
      .trip-recording .expenses .add-on-expense .addexpenses .activity {
        width: 55%; }
      .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons {
        width: 100%;
        display: flex;
        margin: 15px 0; }
        .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput {
          display: inline-block;
          position: relative; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput svg {
            position: absolute;
            top: 38px;
            font-size: 18px;
            color: #cccc; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput .gallons.invalid {
            border: 1px solid red; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput .gallons.invalid:focus {
            border: 1px solid red;
            box-shadow: none; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput .dollars.invalid {
            border: 1px solid red; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput .dollars.invalid:focus {
            border: 1px solid red;
            box-shadow: none; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput .unitMeasure {
            position: absolute;
            top: 30px;
            font-size: 20px;
            color: #cccc;
            right: 5px; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput.GallonsInput input[type=text] {
            padding-left: 8px; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput label {
            display: block;
            text-transform: uppercase;
            color: #333333;
            margin-bottom: .25rem; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput input[type=text] {
            width: 100%;
            font-size: 1.25rem;
            padding: 0.3rem;
            padding-left: 18px; }
            .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput input[type=text]::placeholder {
              color: #e6e6e6;
              opacity: 1; }
          .trip-recording .expenses .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput.GallonsInput {
            width: 40%;
            margin-left: 10%; }
      .trip-recording .expenses .add-on-expense .addexpenses .button {
        margin-top: 1em;
        margin-left: 75px; }
        .trip-recording .expenses .add-on-expense .addexpenses .button:disabled {
          color: #FFFFFF;
          border-color: #CCCCCC;
          background: #CCCCCC;
          cursor: not-allowed; }

@keyframes expensePopup {
  from {
    height: 300px; }
  to {
    height: 301px; } }
  .trip-recording .expenses .transactionsContainer {
    margin: 50px 0px 25px 0px; }
    .trip-recording .expenses .transactionsContainer .header .heading {
      font-size: 22px;
      font-weight: 600; }
    .trip-recording .expenses .transactionsContainer .header .expenseCount {
      border: 2px solid #049b5c;
      padding: 1px 12px;
      border-radius: 46%;
      margin-left: 10px;
      color: #fff;
      background-color: #049b5c;
      font-size: 18px; }
    .trip-recording .expenses .transactionsContainer .noTransactions {
      padding: 25px 0px;
      border: 2px solid #eee;
      border-left: none;
      border-right: none;
      margin: 20px 0px;
      font-size: 18px;
      color: #7d7a7a; }
    .trip-recording .expenses .transactionsContainer .header-labels button:focus {
      box-shadow: none; }
    .trip-recording .expenses .transactionsContainer .header-labels button:hover {
      color: #049b5c; }
    .trip-recording .expenses .transactionsContainer .header-labels .selected {
      width: 2rem;
      font-size: 1.25rem;
      color: #333333; }
      .trip-recording .expenses .transactionsContainer .header-labels .selected.chosen {
        color: #049b5c; }
    .trip-recording .expenses .transactionsContainer .wex-transactions-table button:focus {
      box-shadow: none; }
    .trip-recording .expenses .transactionsContainer .wex-transactions-table button:hover {
      color: #049b5c; }
    .trip-recording .expenses .transactionsContainer .wex-transactions-table .selected {
      width: 2rem;
      font-size: 1.25rem;
      color: #333333; }
      .trip-recording .expenses .transactionsContainer .wex-transactions-table .selected.chosen {
        color: #049b5c; }
    .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .tooltiptext {
      visibility: hidden;
      background-color: #0a0a0a;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      top: 8px;
      left: 35%;
      width: 170px;
      font-size: 12px; }
    .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .tooltiptext::after {
      content: "";
      position: absolute;
      top: 40%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent black transparent transparent; }
    .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .etable-column .etable-cell {
      color: #484848; }
      .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .etable-column .etable-cell.tooltip {
        position: relative;
        overflow: visible; }
      .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .etable-column .etable-cell.tooltip:hover .tooltiptext {
        visibility: visible;
        transition-delay: 1.5s; }
      .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .etable-column .etable-cell span {
        margin-top: 8px; }
    .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row.unchosen {
      opacity: 0.6; }
      .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row.unchosen .etable-column .etable-cell {
        color: #9a9797; }
  .trip-recording .expenses .addExpenses {
    margin-top: 20px; }
    .trip-recording .expenses .addExpenses .popup-container .popup {
      min-width: 35rem;
      max-width: 35rem; }
      .trip-recording .expenses .addExpenses .popup-container .popup .header {
        float: right;
        font-size: 18px;
        color: #9c9a9a; }
      .trip-recording .expenses .addExpenses .popup-container .popup .title {
        border: none;
        width: 55%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: #333; }
    .trip-recording .expenses .addExpenses .addexpenseButton {
      color: #049b5c;
      font-size: 22px; }
      .trip-recording .expenses .addExpenses .addexpenseButton .title {
        font-size: 18px;
        margin-left: 10px; }
  .trip-recording .expenses .submitExpenses {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end; }
    .trip-recording .expenses .submitExpenses .submitexpenseButton {
      color: #fff;
      background-color: #049b5c;
      padding: 8px 12px; }
    .trip-recording .expenses .submitExpenses .submitexpenseButton:focus {
      box-shadow: 0 0 5px 0 #4A90E2; }
    .trip-recording .expenses .submitExpenses .submitexpenseButton:disabled {
      color: #FFFFFF;
      border-color: #CCCCCC;
      background: #CCCCCC;
      cursor: not-allowed; }

@media (min-width: 768px) {
  .trip-recording .expenses .update {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .trip-recording .expenses .stats {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-bottom: 3rem; }
    .trip-recording .expenses .stats .fuel-expense,
    .trip-recording .expenses .stats .fuel-gallons,
    .trip-recording .expenses .stats .other-expenses {
      max-width: 32%; }
  .trip-recording .expenses .big-buttons .button {
    width: 165px; }
  .trip-recording .expenses .add-on-expense .addexpenses {
    width: 68%; }
  .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .etable-column .etable-cell .mobile-only-label {
    justify-content: flex-start; } }

@media (max-width: 700px) {
  .trip-recording .expenses .addExpenses .popup-container .popup {
    min-width: auto;
    width: 100vw;
    height: 100vh;
    overflow-y: auto; }
    .trip-recording .expenses .addExpenses .popup-container .popup .big-buttons .button {
      width: 165px;
      height: 145px; }
    .trip-recording .expenses .addExpenses .popup-container .popup .add-on-expense {
      height: auto;
      margin-bottom: 1.5rem; }
      .trip-recording .expenses .addExpenses .popup-container .popup .add-on-expense .addexpenses .expensesInput {
        margin: 10px 0; }
      .trip-recording .expenses .addExpenses .popup-container .popup .add-on-expense .addexpenses .fuelGallons {
        width: 100%;
        display: block;
        margin: 10px 0; }
        .trip-recording .expenses .addExpenses .popup-container .popup .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput {
          width: 100%; }
          .trip-recording .expenses .addExpenses .popup-container .popup .add-on-expense .addexpenses .fuelGallons .fuelGallonsInput.GallonsInput {
            width: 100%;
            margin-left: 0; }
      .trip-recording .expenses .addExpenses .popup-container .popup .add-on-expense .addexpenses .button {
        margin-top: 0.5em; }
  .trip-recording .expenses .transactionsContainer .header .heading {
    margin-left: 20px; }
  .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .tooltiptext {
    left: 6%; }
  .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .etable-column .etable-cell .mobile-only-label span {
    margin-top: 0px;
    padding-left: 5px; }
  .trip-recording .expenses .transactionsContainer .wex-transactions-table .etable-row .etable-column .expenses {
    padding: 0; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@media (min-width: 768px) {
  .main-container {
    background: #D9D9D9;
    min-height: calc(100vh - 10.5rem); }
    .main-container .contracts {
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      background-color: #FFFFFF;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      margin-top: 200px;
      padding-top: 48px;
      width: 650px;
      margin-top: 200px;
      padding-bottom: 32px;
      border-radius: 5px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2); }
      .main-container .contracts .container {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center; }
        .main-container .contracts .container img {
          height: 50px;
          width: 40px; }
        .main-container .contracts .container .title {
          font-family: 'DINNextLTPro-Regular';
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          /* 123.077% */
          letter-spacing: 0.15px;
          color: #4A4A4A; }
        .main-container .contracts .container .heading {
          font-family: 'Proxima Nova';
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 133.333% */
          letter-spacing: -0.25px;
          color: #4A4A4A;
          width: 55%;
          margin: 15px 40px 25px 40px; }
        .main-container .contracts .container .tools {
          font-family: 'Proxima Nova';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 150% */
          text-transform: uppercase;
          margin-bottom: 34px; }
          .main-container .contracts .container .tools #continue {
            padding: 6px 34px; }
        .main-container .contracts .container #logout {
          font-family: 'Proxima Nova';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 150% */
          letter-spacing: 0.5px;
          text-transform: uppercase;
          background-color: #fff;
          border: none;
          color: #00A664;
          text-decoration: underline; } }

@media only screen and (max-width: 700px) {
  .main-container {
    background: #fff;
    min-height: calc(100vh - 10.5rem); }
    .main-container .contracts {
      width: 100%;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin-top: 170px; }
      .main-container .contracts .container img {
        height: 50px;
        width: 40px; }
      .main-container .contracts .container .title {
        font-family: 'DINNextLTPro-Regular';
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 123.077% */
        letter-spacing: 0.15px;
        color: #4A4A4A; }
      .main-container .contracts .container .heading {
        font-family: 'Proxima Nova';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 133.333% */
        letter-spacing: -0.25px;
        color: #4A4A4A;
        margin: 15px 40px 35px 40px; }
      .main-container .contracts .container .tools {
        font-family: 'Proxima Nova';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 150% */
        text-transform: uppercase;
        margin-bottom: 34px;
        display: block; }
        .main-container .contracts .container .tools #continue {
          padding: 6px 40px; }
      .main-container .contracts .container #logout {
        font-family: 'Proxima Nova';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 150% */
        letter-spacing: 0.5px;
        text-transform: uppercase;
        background-color: #fff;
        border: none;
        color: #00A664;
        text-decoration: underline; } }

.registration {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding-top: 60px;
  min-height: 100vh; }
  .registration .vanpool-question {
    text-align: center;
    opacity: 0;
    animation: slide-up 1s ease-in forwards; }
    .registration .vanpool-question label {
      font-size: 1.5rem;
      font-weight: 600; }
    .registration .vanpool-question .yes-no {
      margin: 1rem; }
      .registration .vanpool-question .yes-no .button.pill {
        margin: 1.5rem;
        padding: 0.25rem 2rem;
        font-weight: 600; }
  .registration .commute-profile, .registration .in-vanpool, .registration .commuteUser-contact {
    width: calc(100vw - 1rem);
    max-width: 30rem;
    padding: 0.25rem;
    margin: 1rem 0.5rem;
    opacity: 0;
    animation: slide-up 1s ease-in forwards; }
    .registration .commute-profile .title, .registration .in-vanpool .title, .registration .commuteUser-contact .title {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 1rem; }
    .registration .commute-profile.refer-question .title, .registration .in-vanpool.refer-question .title, .registration .commuteUser-contact.refer-question .title {
      font-size: 1.25rem;
      text-align: center; }
    .registration .commute-profile .head, .registration .commute-profile .tools, .registration .in-vanpool .head, .registration .in-vanpool .tools, .registration .commuteUser-contact .head, .registration .commuteUser-contact .tools {
      display: none; }
    .registration .commute-profile .edit-employer, .registration .in-vanpool .edit-employer, .registration .commuteUser-contact .edit-employer {
      margin-top: 1rem; }
      .registration .commute-profile .edit-employer.invalid:not(:focus), .registration .in-vanpool .edit-employer.invalid:not(:focus), .registration .commuteUser-contact .edit-employer.invalid:not(:focus) {
        border-color: #c01515; }
        .registration .commute-profile .edit-employer.invalid:not(:focus) + .label, .registration .in-vanpool .edit-employer.invalid:not(:focus) + .label, .registration .commuteUser-contact .edit-employer.invalid:not(:focus) + .label {
          color: #c01515; }
    .registration .commute-profile .employer-invalid, .registration .in-vanpool .employer-invalid, .registration .commuteUser-contact .employer-invalid {
      position: relative;
      display: block;
      left: 0.5rem;
      top: -0.5rem;
      font-size: 0.75rem;
      color: #c01515;
      height: 0; }
    .registration .commute-profile .invalid-address-auto, .registration .in-vanpool .invalid-address-auto, .registration .commuteUser-contact .invalid-address-auto {
      border-color: #c01515; }
    .registration .commute-profile .invalid-address, .registration .in-vanpool .invalid-address, .registration .commuteUser-contact .invalid-address {
      position: relative;
      display: block;
      left: 0.5rem;
      top: -0.5rem;
      font-size: 0.75rem;
      color: #c01515;
      height: 0; }
    .registration .commute-profile .not-employer, .registration .in-vanpool .not-employer, .registration .commuteUser-contact .not-employer {
      font-size: 0.875rem;
      text-decoration: underline; }
    .registration .commute-profile input, .registration .in-vanpool input, .registration .commuteUser-contact input {
      width: 100%; }
    .registration .commute-profile label, .registration .in-vanpool label, .registration .commuteUser-contact label {
      display: block;
      font-size: 1rem;
      font-weight: 600;
      margin: 1rem 0; }
      .registration .commute-profile label.attach, .registration .in-vanpool label.attach, .registration .commuteUser-contact label.attach {
        font-size: 0.75rem;
        font-weight: 400;
        background: #FFFFFF;
        display: inline-block;
        position: relative;
        top: 1rem;
        left: 0.5rem;
        z-index: 10;
        padding: 0.125rem;
        color: #049b5c;
        margin-top: 0rem;
        margin-bottom: 0.25rem; }
    .registration .commute-profile .select.transportation, .registration .in-vanpool .select.transportation, .registration .commuteUser-contact .select.transportation {
      width: 15rem;
      margin-bottom: 1rem; }
    .registration .commute-profile .pad-desk, .registration .in-vanpool .pad-desk, .registration .commuteUser-contact .pad-desk {
      padding-left: 0; }
    .registration .commute-profile .other-stuff, .registration .in-vanpool .other-stuff, .registration .commuteUser-contact .other-stuff {
      border-top: 1px solid #CCCCCC;
      margin-top: 2rem;
      padding-top: 1rem; }
      .registration .commute-profile .other-stuff .question, .registration .in-vanpool .other-stuff .question, .registration .commuteUser-contact .other-stuff .question {
        margin-bottom: 2rem; }
        .registration .commute-profile .other-stuff .question .yes-no, .registration .in-vanpool .other-stuff .question .yes-no, .registration .commuteUser-contact .other-stuff .question .yes-no {
          margin-top: 10px; }
          .registration .commute-profile .other-stuff .question .yes-no .pill, .registration .in-vanpool .other-stuff .question .yes-no .pill, .registration .commuteUser-contact .other-stuff .question .yes-no .pill {
            border: solid 1px #049b5c;
            width: auto;
            margin-right: 1rem;
            font-size: 1.125rem; }
            .registration .commute-profile .other-stuff .question .yes-no .pill.selected, .registration .in-vanpool .other-stuff .question .yes-no .pill.selected, .registration .commuteUser-contact .other-stuff .question .yes-no .pill.selected {
              background-color: #049b5c;
              color: #FFFFFF; }
    .registration .commute-profile .question, .registration .in-vanpool .question, .registration .commuteUser-contact .question {
      margin-bottom: 2rem; }
      .registration .commute-profile .question .yes-no, .registration .in-vanpool .question .yes-no, .registration .commuteUser-contact .question .yes-no {
        margin-top: 10px; }
        .registration .commute-profile .question .yes-no .pill, .registration .in-vanpool .question .yes-no .pill, .registration .commuteUser-contact .question .yes-no .pill {
          border: solid 1px #049b5c;
          width: auto;
          margin-right: 1rem;
          font-size: 1.125rem; }
          .registration .commute-profile .question .yes-no .pill.selected, .registration .in-vanpool .question .yes-no .pill.selected, .registration .commuteUser-contact .question .yes-no .pill.selected {
            background-color: #049b5c;
            color: #FFFFFF; }
    .registration .commute-profile .actions, .registration .in-vanpool .actions, .registration .commuteUser-contact .actions {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      margin-top: 2rem; }
      .registration .commute-profile .actions button, .registration .in-vanpool .actions button, .registration .commuteUser-contact .actions button {
        padding: 0.5rem 3rem; }
        .registration .commute-profile .actions button:disabled, .registration .in-vanpool .actions button:disabled, .registration .commuteUser-contact .actions button:disabled {
          background: #CCCCCC;
          border-color: #CCCCCC;
          cursor: not-allowed; }
  .registration .referral {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem; }
    .registration .referral .option {
      height: 7.5rem;
      width: 7.5rem;
      margin: 0.5rem 0;
      border: 1px solid lightgrey;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around; }
      .registration .referral .option.selected {
        font-weight: 600;
        color: #049b5c;
        border: 0.1875rem solid #049b5c; }
      .registration .referral .option img {
        width: 4rem; }
      .registration .referral .option .label {
        font-size: 0.875rem; }
  .registration .in-vanpool .title .info {
    top: -1px; }
    .registration .in-vanpool .title .info svg {
      color: #049b5c; }
    .registration .in-vanpool .title .info .message {
      left: -6.5rem; }
      .registration .in-vanpool .title .info .message::before {
        left: calc(6.5rem - 1px); }
      .registration .in-vanpool .title .info .message::after {
        left: 6.5rem; }
  .registration .in-vanpool .desc {
    margin: 2rem 0;
    font-size: 1rem; }
    .registration .in-vanpool .desc span {
      font-weight: 600; }
    .registration .in-vanpool .desc.error {
      color: #c01515; }
      .registration .in-vanpool .desc.error .callable a {
        color: #c01515;
        font-weight: 600;
        text-decoration: underline; }
  .registration .in-vanpool .fancy-input input {
    margin: 1rem 0;
    max-width: 18rem; }
    .registration .in-vanpool .fancy-input input::placeholder {
      color: #B3B3B3; }
    .registration .in-vanpool .fancy-input input:-ms-input-placeholder {
      color: #B3B3B3 !important; }
  .registration .in-vanpool .fancy-input input + .label, .registration .in-vanpool .fancy-input input[value]:not([value=""]) + .label {
    top: -20px;
    left: 8px;
    font-size: 0.6875rem;
    color: #333333; }
  .registration .commuteUser-contact .title .info {
    top: -1px; }
    .registration .commuteUser-contact .title .info svg {
      color: #049b5c; }
    .registration .commuteUser-contact .title .info .message {
      left: -6.5rem; }
      .registration .commuteUser-contact .title .info .message::before {
        left: calc(6.5rem - 1px); }
      .registration .commuteUser-contact .title .info .message::after {
        left: 6.5rem; }
  .registration .commuteUser-contact .desc {
    margin: 2rem 0;
    font-size: 1rem; }
    .registration .commuteUser-contact .desc span {
      font-weight: 600; }
    .registration .commuteUser-contact .desc.error {
      color: #c01515; }
      .registration .commuteUser-contact .desc.error .callable a {
        color: #c01515;
        font-weight: 600;
        text-decoration: underline; }
  .registration .commuteUser-contact .fancy-input input {
    margin: 1rem 0;
    max-width: 22rem; }
    .registration .commuteUser-contact .fancy-input input::placeholder {
      color: #B3B3B3; }
    .registration .commuteUser-contact .fancy-input input:-ms-input-placeholder {
      color: #B3B3B3 !important; }
  .registration .commuteUser-contact .fancy-input input + .label, .registration .commuteUser-contact .fancy-input input[value]:not([value=""]) + .label {
    top: -20px;
    left: 8px;
    font-size: 0.6875rem;
    color: #333333; }
  .registration .commuteUser-contact .select {
    margin: 1rem 0;
    max-width: 22rem; }
    .registration .commuteUser-contact .select::placeholder {
      color: #B3B3B3; }
    .registration .commuteUser-contact .select:-ms-input-placeholder {
      color: #B3B3B3 !important; }

.popup .email {
  margin: 1rem 0;
  font-weight: 800; }

@media (min-width: 768px) {
  .registration {
    -webkit-align-items: start;
    -moz-align-items: start;
    -ms-align-items: start;
    align-items: start;
    padding-top: 70px; }
    .registration .commute-profile .title {
      font-size: 1.25rem; }
    .registration .commute-profile.refer-question .referral {
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
    .registration .commute-profile.refer-question .actions {
      padding: 0 1rem; } }

.stats-page {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding-top: 60px; }
  .stats-page .stats-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse; }
    .stats-page .stats-header .stats-export-container, .stats-page .stats-header .stats-period-selector-container {
      margin: 1rem 0 0 0; }
  .stats-page .stats-export-container svg {
    color: #049b5c; }
  .stats-page .stats-export-container button {
    color: #049b5c; }
    .stats-page .stats-export-container button span {
      color: #CCCCCC;
      font-size: 0.75rem; }
  .stats-page .stats-container {
    width: 100%;
    padding: 1rem; }
    .stats-page .stats-container .selected-aggregate-title {
      font-size: 1.75rem;
      font-weight: 600; }
      .stats-page .stats-container .selected-aggregate-title .alternate-title {
        padding-left: 0.25rem;
        margin-bottom: 1rem;
        font-size: 1.125rem;
        font-style: italic;
        color: #CCCCCC;
        position: relative;
        top: -2px; }
    .stats-page .stats-container .secondary-nav {
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      border: solid 1px #049b5c;
      border-radius: 6px;
      height: 2.4rem; }
      .stats-page .stats-container .secondary-nav a {
        display: inline-block;
        width: 50%;
        max-width: 15rem;
        text-align: center;
        color: #049b5c;
        padding: 0.25rem; }
        .stats-page .stats-container .secondary-nav a#vanpool-aggregate-link {
          border-radius: 5px 0 0 5px; }
          .stats-page .stats-container .secondary-nav a#vanpool-aggregate-link svg {
            font-size: 1.75rem; }
        .stats-page .stats-container .secondary-nav a#recorded-trips-aggregate-link {
          border-radius: 0 5px 5px 0; }
          .stats-page .stats-container .secondary-nav a#recorded-trips-aggregate-link svg {
            font-size: 1.75rem; }
        .stats-page .stats-container .secondary-nav a.active {
          color: #FFFFFF;
          background: #049b5c; }
        .stats-page .stats-container .secondary-nav a:focus {
          box-shadow: none;
          -webkit-box-shadow: none; }
    .stats-page .stats-container .stats-period-selector-container {
      margin-top: 1rem;
      width: 15rem;
      position: relative; }
      .stats-page .stats-container .stats-period-selector-container .display {
        color: #049b5c;
        font-size: 1.5rem;
        cursor: pointer; }
        .stats-page .stats-container .stats-period-selector-container .display::after {
          position: absolute;
          right: 0rem;
          top: 0.5rem;
          content: '';
          display: inline-block;
          height: 0.5em;
          width: 0.5em;
          border-left: 0.125rem solid #049b5c;
          border-bottom: 0.125rem solid #049b5c;
          transform: rotate(-45deg); }
      .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip {
        position: absolute;
        left: 0.5rem;
        top: 2.25rem;
        background: #FFFFFF;
        z-index: 10;
        width: 18rem;
        border-radius: 0.25rem;
        padding: 0;
        height: inherit;
        text-transform: initial;
        -webkit-box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
        border: 1px solid #CCCCCC;
        cursor: default;
        -webkit-animation: none;
        animation: none; }
        .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip::before {
          left: 13.15rem;
          content: '';
          position: absolute;
          top: -0.55rem;
          height: 1rem;
          width: 1rem;
          background: #FFFFFF;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          -webkit-box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, 0.15);
          box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, 0.15);
          border-left: 1px solid #CCCCCC;
          border-top: 1px solid #CCCCCC;
          z-index: -1; }
        .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip .toggle {
          border-bottom: 1px solid #CCCCCC; }
          .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip .toggle button {
            padding: 0.25rem 0.5rem;
            border: none;
            width: 50%;
            color: #049b5c;
            font-weight: 600; }
            .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip .toggle button.quarter {
              border-radius: 0.25rem 0 0 0; }
            .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip .toggle button.month {
              border-radius: 0 0.25rem 0 0; }
            .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip .toggle button.active {
              background: #049b5c;
              color: #FFFFFF;
              font-weight: inherit; }
        .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip .select {
          padding: 0.25rem;
          margin: 0.5rem; }
          .stats-page .stats-container .stats-period-selector-container .selector.toggle-tip .select select {
            border-radius: 0 0 3px 3px;
            border: none;
            border-bottom: 1px solid #CCCCCC;
            color: #049b5c;
            font-size: 1.125rem; }
      .stats-page .stats-container .stats-period-selector-container .month-year-select {
        display: flex; }
        .stats-page .stats-container .stats-period-selector-container .month-year-select .month {
          width: 60%; }
          .stats-page .stats-container .stats-period-selector-container .month-year-select .month select {
            border-radius: 0 0 0 3px; }
        .stats-page .stats-container .stats-period-selector-container .month-year-select .year {
          width: 40%; }
          .stats-page .stats-container .stats-period-selector-container .month-year-select .year select {
            border-radius: 0 0 3px 0; }
    .stats-page .stats-container .impact-data {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: -0.5rem;
      margin-bottom: 0.75rem;
      margin-top: 1rem; }
      .stats-page .stats-container .impact-data .badge {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: 15rem;
        border: solid 1px #CCCCCC;
        border-radius: 4px;
        position: relative;
        padding: 0.25rem;
        height: 5rem;
        margin: 0.5rem; }
        .stats-page .stats-container .impact-data .badge .column {
          -webkit-justify-content: right;
          -moz-justify-content: right;
          -ms-justify-content: right;
          justify-content: right;
          -ms-flex-pack: right;
          -webkit-align-content: right;
          -moz-align-content: right;
          -ms-align-content: right;
          align-content: right;
          text-align: right;
          white-space: nowrap;
          padding-right: 6px; }
          .stats-page .stats-container .impact-data .badge .column .label {
            font-size: 0.6875rem;
            text-transform: uppercase;
            font-weight: 300; }
          .stats-page .stats-container .impact-data .badge .column .data {
            font-size: 1.5rem;
            font-weight: 600; }
          .stats-page .stats-container .impact-data .badge .column .context {
            font-size: 0.75rem;
            font-style: italic;
            color: #CCCCCC; }
        .stats-page .stats-container .impact-data .badge.private-subsidies .column, .stats-page .stats-container .impact-data .badge.transit-subsidies .column {
          padding-right: 1rem; }
    .stats-page .stats-container .participation-container {
      display: none;
      margin-bottom: 2rem; }
      .stats-page .stats-container .participation-container .label > svg {
        width: 1.75rem;
        margin-top: -2px; }
      .stats-page .stats-container .participation-container .content {
        position: relative; }
        .stats-page .stats-container .participation-container .content .no-bar-data {
          width: 100%;
          display: inline-block;
          font-style: italic;
          text-align: center;
          position: absolute;
          top: 170px;
          padding-left: 0rem;
          font-size: 16px;
          color: #CCCCCC;
          -ms-flex-line-pack: center;
          align-content: center;
          white-space: nowrap; }
      .stats-page .stats-container .participation-container .control-section {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; }
        .stats-page .stats-container .participation-container .control-section .grid-navigation {
          padding-top: 1rem;
          padding-left: 3rem;
          margin-right: .5rem; }
        .stats-page .stats-container .participation-container .control-section button {
          margin: 0 5px;
          margin-right: 3rem;
          font-size: 1.125rem;
          padding: 0.5rem 2rem;
          border-radius: 4rem;
          color: #049b5c;
          background: #FFFFFF; }
          .stats-page .stats-container .participation-container .control-section button.active {
            color: #FFFFFF;
            background: #049b5c;
            border: none; }
        .stats-page .stats-container .participation-container .control-section .current-legend {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          padding-right: 20px;
          margin-left: auto; }
          .stats-page .stats-container .participation-container .control-section .current-legend .legend {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            margin-top: 1rem; }
          .stats-page .stats-container .participation-container .control-section .current-legend .legend-circle {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-border-radius: 15px/15px;
            -webkit-border-radius: 15px 15px;
            border-radius: 15px/15px;
            border: solid 3px #f00;
            width: 15px;
            height: 15px;
            margin-top: 3px; }
          .stats-page .stats-container .participation-container .control-section .current-legend .legend-label {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            padding-right: 1.5rem;
            padding-left: .25rem;
            font-size: 0.875rem; }
    .stats-page .stats-container .impact-data-container,
    .stats-page .stats-container .participation-container,
    .stats-page .stats-container .commute-modes-container,
    .stats-page .stats-container .active-users-impact-data-container {
      width: 100%;
      border: solid 1px #CCCCCC;
      border-radius: 6px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.55); }
      .stats-page .stats-container .impact-data-container .top-bar,
      .stats-page .stats-container .participation-container .top-bar,
      .stats-page .stats-container .commute-modes-container .top-bar,
      .stats-page .stats-container .active-users-impact-data-container .top-bar {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: solid 1px #CCCCCC; }
        .stats-page .stats-container .impact-data-container .top-bar .label,
        .stats-page .stats-container .participation-container .top-bar .label,
        .stats-page .stats-container .commute-modes-container .top-bar .label,
        .stats-page .stats-container .active-users-impact-data-container .top-bar .label {
          min-width: 15rem;
          display: flex;
          align-items: center;
          font-size: 1.125rem;
          text-transform: uppercase; }
          .stats-page .stats-container .impact-data-container .top-bar .label svg,
          .stats-page .stats-container .participation-container .top-bar .label svg,
          .stats-page .stats-container .commute-modes-container .top-bar .label svg,
          .stats-page .stats-container .active-users-impact-data-container .top-bar .label svg {
            padding-right: 0.5rem;
            font-size: 1.5rem;
            width: 1.75rem;
            margin-top: -2px; }
        .stats-page .stats-container .impact-data-container .top-bar .selected-dates,
        .stats-page .stats-container .participation-container .top-bar .selected-dates,
        .stats-page .stats-container .commute-modes-container .top-bar .selected-dates,
        .stats-page .stats-container .active-users-impact-data-container .top-bar .selected-dates {
          font-size: 0.75rem;
          color: #CCCCCC;
          font-style: italic; }
        .stats-page .stats-container .impact-data-container .top-bar .info,
        .stats-page .stats-container .participation-container .top-bar .info,
        .stats-page .stats-container .commute-modes-container .top-bar .info,
        .stats-page .stats-container .active-users-impact-data-container .top-bar .info {
          top: -2px; }
          .stats-page .stats-container .impact-data-container .top-bar .info .message,
          .stats-page .stats-container .participation-container .top-bar .info .message,
          .stats-page .stats-container .commute-modes-container .top-bar .info .message,
          .stats-page .stats-container .active-users-impact-data-container .top-bar .info .message {
            top: calc(100% + 0.5rem);
            left: -6.75rem;
            width: 25rem;
            max-width: 100vw; }
            .stats-page .stats-container .impact-data-container .top-bar .info .message:before,
            .stats-page .stats-container .participation-container .top-bar .info .message:before,
            .stats-page .stats-container .commute-modes-container .top-bar .info .message:before,
            .stats-page .stats-container .active-users-impact-data-container .top-bar .info .message:before {
              left: calc(6.75rem  - 1px); }
            .stats-page .stats-container .impact-data-container .top-bar .info .message:after,
            .stats-page .stats-container .participation-container .top-bar .info .message:after,
            .stats-page .stats-container .commute-modes-container .top-bar .info .message:after,
            .stats-page .stats-container .active-users-impact-data-container .top-bar .info .message:after {
              left: 6.75rem; }
            .stats-page .stats-container .impact-data-container .top-bar .info .message .pair,
            .stats-page .stats-container .participation-container .top-bar .info .message .pair,
            .stats-page .stats-container .commute-modes-container .top-bar .info .message .pair,
            .stats-page .stats-container .active-users-impact-data-container .top-bar .info .message .pair {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              padding: 0.25rem 0; }
              .stats-page .stats-container .impact-data-container .top-bar .info .message .pair .key,
              .stats-page .stats-container .participation-container .top-bar .info .message .pair .key,
              .stats-page .stats-container .commute-modes-container .top-bar .info .message .pair .key,
              .stats-page .stats-container .active-users-impact-data-container .top-bar .info .message .pair .key {
                display: inline-block;
                width: 10rem;
                font-weight: 600; }
              .stats-page .stats-container .impact-data-container .top-bar .info .message .pair .value,
              .stats-page .stats-container .participation-container .top-bar .info .message .pair .value,
              .stats-page .stats-container .commute-modes-container .top-bar .info .message .pair .value,
              .stats-page .stats-container .active-users-impact-data-container .top-bar .info .message .pair .value {
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                -webkit-flex: 1;
                -ms-flex: 1;
                flex: 1;
                display: inline-block;
                word-wrap: wrap;
                font-weight: 300;
                font-style: italic;
                color: #484848; }
      .stats-page .stats-container .impact-data-container .content,
      .stats-page .stats-container .participation-container .content,
      .stats-page .stats-container .commute-modes-container .content,
      .stats-page .stats-container .active-users-impact-data-container .content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 1.25rem 0;
        position: relative; }
        .stats-page .stats-container .impact-data-container .content .no-bar-data,
        .stats-page .stats-container .participation-container .content .no-bar-data,
        .stats-page .stats-container .commute-modes-container .content .no-bar-data,
        .stats-page .stats-container .active-users-impact-data-container .content .no-bar-data {
          position: absolute;
          font-style: italic;
          top: 170px;
          font-size: 16px;
          color: #CCCCCC;
          -ms-flex-line-pack: center;
          white-space: nowrap;
          display: inline-block;
          text-align: center;
          width: 100%; }
        .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar,
        .stats-page .stats-container .participation-container .content .impact-data-stacked-bar,
        .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar,
        .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          min-width: 45%;
          width: 16rem;
          margin: 1rem;
          text-shadow: 1px 1px #484848; }
          .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .bar-potential,
          .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .bar-potential,
          .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .bar-potential,
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .bar-potential {
            background: url("./images/blocked-out.png");
            background-attachment: fixed;
            border-radius: 40px; }
          .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .label,
          .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .label,
          .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .label,
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .label {
            font-size: 1.125rem;
            padding-left: 1.25rem;
            text-transform: uppercase;
            text-shadow: none; }
            .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .label.underline,
            .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .label.underline,
            .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .label.underline,
            .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .label.underline {
              border-bottom: solid 1px #CCCCCC; }
          .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .total,
          .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .total,
          .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .total,
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .total {
            color: #5e83ad;
            font-size: 1.5rem;
            padding-left: 1.25rem;
            font-weight: 600;
            padding-bottom: 1.25rem;
            text-shadow: none; }
            .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .total .metric,
            .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .total .metric,
            .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .total .metric,
            .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .total .metric {
              padding-left: 0.5rem;
              font-size: 0.875rem;
              color: #484848; }
          .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .no-data,
          .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .no-data,
          .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .no-data,
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .no-data {
            padding-left: 1.25rem;
            padding-bottom: 1.25rem;
            font-style: italic;
            text-shadow: none; }
          .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .details,
          .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .details,
          .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .details,
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .details {
            display: flex;
            justify-content: flex-end;
            text-shadow: none; }
            .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .details label,
            .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .details label,
            .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .details label,
            .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .details label {
              color: #CCCCCC;
              padding: 0 0.5rem; }
            .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .details .percent,
            .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .details .percent,
            .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .details .percent,
            .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .details .percent {
              font-weight: 600; }
            .stats-page .stats-container .impact-data-container .content .impact-data-stacked-bar .details .potential,
            .stats-page .stats-container .participation-container .content .impact-data-stacked-bar .details .potential,
            .stats-page .stats-container .commute-modes-container .content .impact-data-stacked-bar .details .potential,
            .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .details .potential {
              padding: 0 1rem 0 0; }
      .stats-page .stats-container .impact-data-container .month-charts-container .legend,
      .stats-page .stats-container .participation-container .month-charts-container .legend,
      .stats-page .stats-container .commute-modes-container .month-charts-container .legend,
      .stats-page .stats-container .active-users-impact-data-container .month-charts-container .legend {
        height: 3rem;
        padding: 0 0 5rem 0; }
      .stats-page .stats-container .impact-data-container .month-charts-container .charts,
      .stats-page .stats-container .participation-container .month-charts-container .charts,
      .stats-page .stats-container .commute-modes-container .month-charts-container .charts,
      .stats-page .stats-container .active-users-impact-data-container .month-charts-container .charts {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap; }
        .stats-page .stats-container .impact-data-container .month-charts-container .charts .bar-chart,
        .stats-page .stats-container .participation-container .month-charts-container .charts .bar-chart,
        .stats-page .stats-container .commute-modes-container .month-charts-container .charts .bar-chart,
        .stats-page .stats-container .active-users-impact-data-container .month-charts-container .charts .bar-chart {
          padding-bottom: 3rem;
          position: relative; }
          .stats-page .stats-container .impact-data-container .month-charts-container .charts .bar-chart .no-bar-data,
          .stats-page .stats-container .participation-container .month-charts-container .charts .bar-chart .no-bar-data,
          .stats-page .stats-container .commute-modes-container .month-charts-container .charts .bar-chart .no-bar-data,
          .stats-page .stats-container .active-users-impact-data-container .month-charts-container .charts .bar-chart .no-bar-data {
            position: absolute;
            font-style: italic;
            top: 200px;
            padding-left: 3rem;
            font-size: 16px;
            color: #CCCCCC;
            -ms-flex-line-pack: center;
            align-content: center;
            white-space: nowrap;
            transform: rotate(-45deg); }
          .stats-page .stats-container .impact-data-container .month-charts-container .charts .bar-chart .label,
          .stats-page .stats-container .participation-container .month-charts-container .charts .bar-chart .label,
          .stats-page .stats-container .commute-modes-container .month-charts-container .charts .bar-chart .label,
          .stats-page .stats-container .active-users-impact-data-container .month-charts-container .charts .bar-chart .label {
            font-size: 0.875rem;
            text-align: center;
            text-transform: capitalize;
            font-weight: 600; }
    .stats-page .stats-container .commute-modes-container {
      display: none;
      margin-bottom: 1.5rem; }
    .stats-page .stats-container .active-users-impact-data-container {
      margin-bottom: 1.5rem; }
      .stats-page .stats-container .active-users-impact-data-container .content {
        padding: 0; }
        .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar {
          width: 100%; }
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .bar-container {
            padding: 1.5rem 1.5rem 2rem 1.5rem; }
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .label {
            display: none; }
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .no-data {
            padding: 0.5rem 1.75rem; }
          .stats-page .stats-container .active-users-impact-data-container .content .impact-data-stacked-bar .total {
            padding: 0 0 0 1.5rem;
            font-size: 3rem; }

@media (max-width: 32rem) {
  .stats-page .stats-container .impact-data-container .top-bar .info .message {
    position: absolute;
    left: -12.25rem;
    right: auto;
    width: calc(100vw - 0.5rem); }
    .stats-page .stats-container .impact-data-container .top-bar .info .message::before {
      left: 12.1rem; }
    .stats-page .stats-container .impact-data-container .top-bar .info .message::after {
      left: calc(12.1rem + 1px); } }

@media (min-width: 768px) {
  .stats-page {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-top: 0;
    width: 90%;
    max-width: 1280px;
    margin-top: calc(70px);
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto; }
    .stats-page .stats-header .stats-export-container {
      margin: 1rem; }
    .stats-page .stats-container .secondary-nav {
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      border: none;
      border-bottom: solid 1px #CCCCCC;
      border-radius: 0;
      height: inherit; }
      .stats-page .stats-container .secondary-nav a {
        padding-bottom: 0.5rem;
        display: inline-block;
        width: 50%;
        max-width: 15rem;
        text-align: center;
        color: #333333; }
        .stats-page .stats-container .secondary-nav a#vanpool-aggregate-link {
          border-radius: 0; }
        .stats-page .stats-container .secondary-nav a#recorded-trips-aggregate-link {
          border-radius: 0; }
        .stats-page .stats-container .secondary-nav a.active {
          color: #049b5c;
          border-bottom: solid 0.25rem #049b5c;
          background: inherit; }
        .stats-page .stats-container .secondary-nav a:focus {
          box-shadow: none;
          -webkit-box-shadow: none; }
    .stats-page .stats-container .participation-container {
      padding: 1.25rem;
      display: block; }
      .stats-page .stats-container .participation-container .top-bar {
        margin: -1.25rem -1.25rem 0 -1.25rem; }
    .stats-page .stats-container .commute-modes-container {
      display: block; }
      .stats-page .stats-container .commute-modes-container .content {
        padding: 1.25rem; }
    .stats-page .stats-container .secondary-nav {
      font-size: 1.25rem; } }

#chartjs-tooltip {
  transition: all 0.2s ease-out;
  pointer-events: none; }

.custom-chart-js-tooltip {
  padding: 0.5rem;
  background: #049b5c;
  color: #FFFFFF;
  width: 7.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.5rem 0.125rem rgba(0, 0, 0, 0.25);
  position: relative;
  left: -3.75rem;
  top: -9rem;
  z-index: 1000; }
  .custom-chart-js-tooltip .title {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #FFFFFF;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding-bottom: 0.25rem; }
  .custom-chart-js-tooltip .item {
    padding-top: 0.5rem; }
    .custom-chart-js-tooltip .item .label {
      text-transform: uppercase;
      font-size: 0.6875rem;
      margin-bottom: -0.25rem; }
    .custom-chart-js-tooltip .item .values {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      font-weight: 600;
      font-size: 1.125rem; }

.my-history .etable .etable-header, .vanpool-history .etable .etable-header {
  display: none; }

@media (min-width: 768px) {
  .my-history .etable .etable-header, .vanpool-history .etable .etable-header {
    padding-right: 0;
    display: inline-block; }
    .my-history .etable .etable-header.my-history-month, .vanpool-history .etable .etable-header.my-history-month {
      flex: 0 0 198px; }
    .my-history .etable .etable-header.my-history-payment-date, .vanpool-history .etable .etable-header.my-history-payment-date {
      flex: 0 0 262px; }
    .my-history .etable .etable-header.my-history-description, .vanpool-history .etable .etable-header.my-history-description {
      flex-grow: 1; }
  .my-history .etable .etable-body .etable-row:last-child, .vanpool-history .etable .etable-body .etable-row:last-child {
    border: none; }
  .my-history .etable .etable-body .etable-row .etable-column .etable-cell .mobile-only-label, .vanpool-history .etable .etable-body .etable-row .etable-column .etable-cell .mobile-only-label {
    justify-content: center; }
  .my-history .etable .etable-body .etable-row .etable-column .etable-cell.spacer, .vanpool-history .etable .etable-body .etable-row .etable-column .etable-cell.spacer {
    flex: 0 0 110px; }
  .my-history .etable .etable-body .etable-row .etable-column .etable-cell.description, .vanpool-history .etable .etable-body .etable-row .etable-column .etable-cell.description {
    flex-grow: 1; }
    .my-history .etable .etable-body .etable-row .etable-column .etable-cell.description .mobile-only-label, .vanpool-history .etable .etable-body .etable-row .etable-column .etable-cell.description .mobile-only-label {
      justify-content: flex-start; }
  .my-history .etable .etable-body .etable-row .etable-column .etable-cell.total-charges, .vanpool-history .etable .etable-body .etable-row .etable-column .etable-cell.total-charges {
    flex-grow: 0;
    padding-right: 0; }
    .my-history .etable .etable-body .etable-row .etable-column .etable-cell.total-charges .mobile-only-label, .vanpool-history .etable .etable-body .etable-row .etable-column .etable-cell.total-charges .mobile-only-label {
      justify-content: flex-end; } }

.changeCommuteModal-container .header .pageOrder {
  font-size: 12px;
  color: #a29c9c;
  font-weight: 500; }

.changeCommuteModal-container .header .contact {
  font-size: 13px;
  color: #049b5c;
  font-weight: 600;
  float: right;
  margin-right: 30px;
  cursor: pointer; }
  .changeCommuteModal-container .header .contact .phoneIcon {
    padding-right: 5px;
    font-size: 13px; }

.changeCommuteModal-container .header .contact:focus {
  outline: none; }

.changeCommuteModal-container .header .contact:hover {
  text-decoration: underline; }

.changeCommuteModal-container .header .close:focus {
  outline: none;
  box-shadow: none; }

.changeCommuteModal-container .body {
  /* For Ripple Effect */
  /* For Ripple Effect */ }
  .changeCommuteModal-container .body .overlay {
    opacity: 0.5; }
  .changeCommuteModal-container .body .reasonsHeading {
    font-size: 16px;
    font-weight: 700; }
  .changeCommuteModal-container .body .helperText p {
    font-size: 14px; }
  .changeCommuteModal-container .body input[type=email] {
    padding: .5rem;
    border: 1px solid #b3b3b3;
    margin: 0 0 .5rem;
    font-size: 1rem;
    color: #333;
    border-radius: 3px; }
  .changeCommuteModal-container .body .checkLabel {
    margin-left: 18px;
    font-size: 14px; }
  .changeCommuteModal-container .body .checkbox-label {
    position: relative;
    margin: auto;
    cursor: pointer; }
  .changeCommuteModal-container .body .checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin: 5px 2px; }
  .changeCommuteModal-container .body .checkbox-label .checkbox-custom {
    position: absolute;
    top: 2px;
    left: 0px;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid #333333e0; }
  .changeCommuteModal-container .body .checkbox-label input:checked ~ .checkbox-custom {
    background-color: #049b5c;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 2px solid #049b5c; }
  .changeCommuteModal-container .body .checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out; }
  .changeCommuteModal-container .body .checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0; }
  .changeCommuteModal-container .body .checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0); }
  .changeCommuteModal-container .body .checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 22px;
    height: 22px;
    border-radius: 5px;
    opacity: 0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out; }
  .changeCommuteModal-container .body .radio-label {
    position: relative;
    margin: auto;
    cursor: pointer; }
    .changeCommuteModal-container .body .radio-label .radioLabel {
      margin-left: 18px;
      font-size: 12px;
      font-weight: 700; }
  .changeCommuteModal-container .body .radio-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin: 5px 2px; }
  .changeCommuteModal-container .body .radio-label .radio-custom {
    position: absolute;
    top: 2px;
    left: 0px;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid #333333e0; }
  .changeCommuteModal-container .body .radio-label .radio-custom .circle {
    display: none; }
  .changeCommuteModal-container .body .radio-label input:checked ~ .radio-custom {
    background-color: #fff;
    border-radius: 50%;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 2px solid #049b5c; }
  .changeCommuteModal-container .body .radio-label input:checked ~ .radio-custom .circle {
    display: block;
    background-color: #049b5c;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-top: 2px;
    margin-left: 2px; }
  .changeCommuteModal-container .body .radio-label .radio-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out; }
  .changeCommuteModal-container .body .radio-label .radio-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0); }
  .changeCommuteModal-container .body .radio-label input:checked ~ .radio-custom::before {
    left: -3px;
    top: -3px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    opacity: 0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out; }
  .changeCommuteModal-container .body .reasons {
    margin-top: 30px; }
    .changeCommuteModal-container .body .reasons p {
      font-size: 14px; }
    .changeCommuteModal-container .body .reasons .resonsCheckContainer ul > li {
      margin: 5px; }
    .changeCommuteModal-container .body .reasons .resonsCheckContainer ul > li > .otherInput {
      border: none;
      border-bottom: 1px solid #333;
      margin-left: 10px;
      padding: 0;
      border-radius: 0; }
    .changeCommuteModal-container .body .reasons .resonsCheckContainer ul > li > .otherInput:focus {
      box-shadow: none; }
  .changeCommuteModal-container .body .reviewForm {
    margin-top: 15px;
    padding-right: 25px; }
    .changeCommuteModal-container .body .reviewForm .commuteLabel {
      font-size: 0.875rem;
      font-weight: 600;
      margin: 5px; }
    .changeCommuteModal-container .body .reviewForm .invalid-address-auto {
      border: 1px solid red; }
    .changeCommuteModal-container .body .reviewForm .invalid-address {
      color: red;
      font-size: 16px;
      position: relative;
      top: -20px;
      padding-left: 5px; }
    .changeCommuteModal-container .body .reviewForm .checkLabel {
      margin-left: 24px; }
    .changeCommuteModal-container .body .reviewForm .commuteInput {
      width: 100%;
      margin-bottom: 25px;
      margin-top: 10px; }
    .changeCommuteModal-container .body .reviewForm .commuteDays .days {
      margin-bottom: 25px;
      margin-top: 10px; }
    .changeCommuteModal-container .body .reviewForm .commuteDays .days .day-btn {
      color: #049b5c;
      background-color: #FFFFFF;
      height: 3rem;
      width: 3rem;
      font-size: 0.85rem;
      text-align: center;
      border-radius: 50%;
      border: solid 1px #05cd7a;
      cursor: pointer;
      margin: 0 8px;
      padding: 0; }
    .changeCommuteModal-container .body .reviewForm .commuteDays .days .day-btn.active {
      color: #FFFFFF;
      border: solid 1px #049b5c;
      background: #049b5c; }
    .changeCommuteModal-container .body .reviewForm .flexibleTime {
      width: 100%;
      height: 42px;
      max-height: 42px;
      background: #fff; }
    .changeCommuteModal-container .body .reviewForm .commuteInput .time-picker {
      width: 100%; }
    .changeCommuteModal-container .body .reviewForm .flexible {
      color: #049b5c; }
  .changeCommuteModal-container .body .vanpoolResults {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 300px;
    top: 20px;
    /* For Ripple Effect */ }
    .changeCommuteModal-container .body .vanpoolResults .vanpoolList {
      width: 230px; }
      .changeCommuteModal-container .body .vanpoolResults .vanpoolList p {
        font-size: 14px; }
      .changeCommuteModal-container .body .vanpoolResults .vanpoolList .matchedli {
        margin: 3px 0px; }
    .changeCommuteModal-container .body .vanpoolResults .IE11Warning {
      border-radius: 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      padding: 1rem 2rem;
      background: #f6db00;
      color: #333333;
      margin: 40px 0px 40px 0px;
      position: absolute;
      left: 250px; }
      .changeCommuteModal-container .body .vanpoolResults .IE11Warning p {
        margin-top: 0;
        margin-bottom: 0.3em;
        font-weight: 700;
        font-size: 1rem; }
    .changeCommuteModal-container .body .vanpoolResults .google-map {
      position: absolute;
      left: 250px;
      height: 100%;
      right: 0;
      top: 8px; }
    .changeCommuteModal-container .body .vanpoolResults .radio-label {
      position: relative;
      margin: auto;
      cursor: pointer; }
      .changeCommuteModal-container .body .vanpoolResults .radio-label .radioLabel {
        margin-left: 18px;
        color: #049b5c;
        font-size: 12px;
        font-weight: 700; }
    .changeCommuteModal-container .body .vanpoolResults .radio-label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      margin: 5px 2px; }
    .changeCommuteModal-container .body .vanpoolResults .radio-label .radio-custom {
      position: absolute;
      top: 2px;
      left: 0px;
      height: 18px;
      width: 18px;
      background-color: #fff;
      border-radius: 50%;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      border: 2px solid #049b5c; }
    .changeCommuteModal-container .body .vanpoolResults .radio-label input:checked ~ .radio-custom {
      background-color: #049b5c;
      border-radius: 50%;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 2px solid #049b5c; }
    .changeCommuteModal-container .body .vanpoolResults .radio-label .radio-custom::after {
      position: absolute;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 5px;
      border: solid #009BFF;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity: 1;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out; }
    .changeCommuteModal-container .body .vanpoolResults .radio-label .radio-custom::before {
      position: absolute;
      content: "";
      left: 10px;
      top: 10px;
      width: 0px;
      height: 0px;
      border-radius: 5px;
      border: 2px solid #FFFFFF;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0); }
    .changeCommuteModal-container .body .vanpoolResults .radio-label input:checked ~ .radio-custom::before {
      left: -3px;
      top: -3px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      opacity: 0;
      z-index: 999;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out; }
  .changeCommuteModal-container .body .vanpoolMatchedNotfound {
    margin-top: 50px; }
    .changeCommuteModal-container .body .vanpoolMatchedNotfound p {
      font-size: 14px;
      color: #484848; }
  .changeCommuteModal-container .body .confirmcommute p {
    font-size: 14px;
    color: #484848; }
  .changeCommuteModal-container .body .confirmcommute .userDetailsForm .commuteLabel {
    font-size: 0.875rem;
    font-weight: 600; }
  .changeCommuteModal-container .body .confirmcommute .userDetailsForm .userName {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 18px 0px; }
    .changeCommuteModal-container .body .confirmcommute .userDetailsForm .userName .firstname {
      width: 45%; }
      .changeCommuteModal-container .body .confirmcommute .userDetailsForm .userName .firstname .FirstName {
        width: 100%;
        margin-top: 5px; }
    .changeCommuteModal-container .body .confirmcommute .userDetailsForm .userName .secondname {
      position: absolute;
      left: 375px;
      width: 45%; }
      .changeCommuteModal-container .body .confirmcommute .userDetailsForm .userName .secondname .SecondName {
        width: 100%;
        margin-top: 5px; }
  .changeCommuteModal-container .body .confirmcommute .userDetailsForm .otherInputs {
    margin: 18px 0px; }
    .changeCommuteModal-container .body .confirmcommute .userDetailsForm .otherInputs .Email,
    .changeCommuteModal-container .body .confirmcommute .userDetailsForm .otherInputs .pNumber,
    .changeCommuteModal-container .body .confirmcommute .userDetailsForm .otherInputs .employer {
      width: 97%;
      height: 43px;
      margin-top: 5px; }
  .changeCommuteModal-container .body .confirmcommute .userDetailsForm .flexible {
    color: #049b5c;
    padding-left: 5px; }
  .changeCommuteModal-container .body .stayInTouch p {
    font-size: 14px;
    color: #484848; }
  .changeCommuteModal-container .body .stayInTouch .userDetailsForm .commuteLabel {
    font-size: 0.875rem;
    font-weight: 600; }
  .changeCommuteModal-container .body .stayInTouch .userDetailsForm .otherInputs {
    margin: 18px 0px; }
    .changeCommuteModal-container .body .stayInTouch .userDetailsForm .otherInputs .FirstName,
    .changeCommuteModal-container .body .stayInTouch .userDetailsForm .otherInputs .SecondName,
    .changeCommuteModal-container .body .stayInTouch .userDetailsForm .otherInputs .Email,
    .changeCommuteModal-container .body .stayInTouch .userDetailsForm .otherInputs .pNumber,
    .changeCommuteModal-container .body .stayInTouch .userDetailsForm .otherInputs .employer {
      width: 50%;
      height: 43px;
      margin-top: 5px; }
  .changeCommuteModal-container .body .stayInTouch .userDetailsForm .flexible {
    color: #049b5c;
    padding-left: 5px; }
  .changeCommuteModal-container .body .commuteFooter {
    margin-top: 50px; }
    .changeCommuteModal-container .body .commuteFooter .commuteTools {
      float: right; }
      .changeCommuteModal-container .body .commuteFooter .commuteTools .button {
        float: none;
        margin: 20px 5px;
        min-width: 6.5em;
        padding: 0.5rem 2rem; }
      .changeCommuteModal-container .body .commuteFooter .commuteTools .button.confirm:disabled {
        background: #CCCCCC;
        border: 1px solid #CCCCCC;
        color: #fff; }

@media (max-width: 512px) {
  .changeCommuteModal-container .body .reviewForm .commuteDays .days .day-btn {
    margin-bottom: 10px; }
  .changeCommuteModal-container .body .confirmcommute .userDetailsForm .userName {
    display: block; }
    .changeCommuteModal-container .body .confirmcommute .userDetailsForm .userName .firstname {
      width: auto; }
    .changeCommuteModal-container .body .confirmcommute .userDetailsForm .userName .secondname {
      position: initial;
      left: auto;
      width: auto; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova semibold";
  src: local("Proxima Nova semibold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.join-commute h1 {
  margin: 0;
  padding: 0; }

@media only screen and (min-width: 769px) {
  .push {
    display: none; }
  .join-commute-header {
    width: 100%;
    background: white;
    height: 75px;
    position: fixed;
    z-index: 5;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 60px;
    border-bottom: 1px solid #D5D5D5;
    box-sizing: border-box; }
    .join-commute-header .logo-container .branding {
      height: 50px;
      width: 140px; }
    .join-commute-header .logo-container .branding-mobile {
      display: none; }
    .join-commute-header .login-container .login-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #00A664;
      height: 40px;
      width: 140px;
      border: 2px solid #00A664;
      border-radius: 5px; }
  .join-commute {
    padding-top: 20px;
    padding-bottom: 100px;
    width: 100%;
    min-height: inherit;
    margin: 75px auto 0;
    background-color: #E5E5E5;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal; }
    .join-commute .Collapsible {
      background: #FFFFFF;
      min-width: 800px;
      max-width: 850px;
      height: fit-content;
      margin-top: 50px;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .join-commute .Collapsible:nth-child(4) {
        margin-bottom: 300px; }
      .join-commute .Collapsible .invalid-address-auto {
        border-color: #F96302 !important; }
      .join-commute .Collapsible .invalid-address {
        position: relative;
        display: block;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 600;
        color: #F96302;
        height: 3px; }
      .join-commute .Collapsible .collapsible-heading-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 40px;
        width: 100%;
        height: 100px; }
        .join-commute .Collapsible .collapsible-heading-container .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
      .join-commute .Collapsible .collapsible-content-container {
        padding: 0px 30px; }
        .join-commute .Collapsible .collapsible-content-container .commute-locations {
          display: flex;
          justify-content: space-between; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations label {
            font-family: 'DINNextLTPro-Medium';
            font-size: 14px;
            line-height: 25px;
            color: #4A4A4A;
            font-style: normal; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations .field {
            width: 48%; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations input {
            border: 2px solid #D5D5D5;
            height: 56px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            width: 100%;
            padding: 15px; }
            .join-commute .Collapsible .collapsible-content-container .commute-locations input:not(:focus) {
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
              padding: 15px 80px 15px 15px; }
            .join-commute .Collapsible .collapsible-content-container .commute-locations input ::placeholder {
              color: #838383; }
        .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays {
          margin-top: 24px; }
          .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays .days .day-btn {
            color: #00A664;
            background-color: #FFFFFF;
            height: 40px;
            width: 40px;
            font-size: 14px;
            text-align: center;
            border-radius: 25px;
            border: solid 2px #00A664;
            cursor: pointer;
            margin: 0 15px 0 0;
            padding: 0;
            text-transform: uppercase;
            padding-top: 5px;
            line-height: 17px;
            font-family: 'DINNextLTPro-Regular';
            font-weight: 700; }
          .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays .days .day-btn.active {
            color: #0F8952;
            border: solid 2px #0F8952;
            background: #D4EDE1; }
        .join-commute .Collapsible .collapsible-content-container .commute-days .minCommuteDays {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin-top: 16px;
          color: #838383; }
        .join-commute .Collapsible .collapsible-content-container .time-entry {
          display: flex;
          flex-wrap: wrap;
          grid-template-columns: auto auto auto auto;
          justify-content: space-between;
          margin-bottom: 10px; }
          .join-commute .Collapsible .collapsible-content-container .time-entry .entry {
            display: flex;
            flex-wrap: wrap; }
        .join-commute .Collapsible .collapsible-content-container .drive-schedules {
          display: flex;
          flex-direction: column;
          width: 620px; }
          .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option {
            color: #4A4A4A;
            width: 620px;
            min-height: 105px;
            border: 1px solid #D5D5D5;
            border-radius: 8px;
            margin-top: 15px;
            padding: 24px 24px 24px 15px;
            box-shadow: 0px 2px #D5D5D5;
            cursor: pointer; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option:focus {
              box-shadow: 0 0 5px 0 #4A90E2; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected {
              border: 3px solid #00A664;
              background-color: #D4EDE1;
              box-shadow: 0px 0px; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .text {
                color: #0F8952;
                text-align: left; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .tick-icon-container {
                position: relative; }
                .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .tick-icon-container .tick-icon {
                  position: absolute;
                  bottom: 15px;
                  right: -10px; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container {
              display: flex;
              justify-content: space-between;
              align-items: center; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .recommended-text {
                height: 20px;
                width: 105px;
                background: #4A90E2;
                font-family: 'DINNextLTPro-Regular';
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                border-radius: 4px;
                margin-bottom: 10px; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .text {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                letter-spacing: -0.25px;
                text-align: left; }
                .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .text.big {
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 32px;
                  letter-spacing: 0.15px; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .comment {
                font-family: 'Proxima Nova';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-top: 10px;
                color: #4A4A4A;
                text-align: left; }
        .join-commute .Collapsible .collapsible-content-container .driver-comment {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          margin-top: 24px;
          color: #636363; }
        .join-commute .Collapsible .collapsible-content-container .comment-two {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          margin-top: 15px;
          color: #636363; }
        .join-commute .Collapsible .collapsible-content-container .company-info .note {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-bottom: 24px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .employers-list {
          max-height: 360px;
          overflow: hidden;
          overflow-y: scroll;
          max-width: 670px; }
          .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card {
            width: 620px;
            height: 70px;
            border: 1px solid #D5D5D5;
            filter: drop-shadow(0px 1px 0px #D5D5D5);
            border-radius: 8px;
            margin-top: 20px;
            cursor: pointer; }
            .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card .text-container {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-style: normal;
              font-size: 24px;
              line-height: 36px;
              color: #4A4A4A; }
            .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected {
              background: #D4EDE1;
              border: 3px solid #00A664;
              filter: drop-shadow(0px 1px 0px #D5D5D5);
              border-radius: 8px; }
              .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .text-container {
                color: #0F8952; }
              .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .tick-icon-container {
                position: relative; }
                .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .tick-icon-container .tick-icon {
                  position: absolute;
                  bottom: -10px;
                  right: 10px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .employer-changed-container {
          margin-top: 25px; }
          .join-commute .Collapsible .collapsible-content-container .company-info .employer-changed-container .employer-changed {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #00A664;
            border-bottom: 2px solid #00A664;
            padding: 0; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .comment-one {
          font-family: 'Proxima Nova semibold';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #4A4A4A;
          margin-top: 30px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .comment-second {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #4A4A4A;
          margin-top: 10px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .company-input {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-top: 24px; }
          .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .company-input input {
            width: 620px;
            height: 56px;
            border: 2px solid #D5D5D5;
            padding: 10px; }
      .join-commute .Collapsible .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        height: 40px;
        width: 160px;
        color: #FFFFFF;
        margin: 50px 0px 40px;
        border-radius: 5px; }
        .join-commute .Collapsible .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed; }
        .join-commute .Collapsible .continue-button:enabled {
          background-color: #00A664; }
      .join-commute .Collapsible .arrow {
        border-right: 3px solid;
        border-bottom: 3px solid;
        padding: 5px; }
        .join-commute .Collapsible .arrow.up {
          border-color: #00A664;
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg); }
        .join-commute .Collapsible .arrow.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg); }
          .join-commute .Collapsible .arrow.down.locked {
            border-color: #D5D5D5; }
          .join-commute .Collapsible .arrow.down.unlocked {
            border-color: #00A664; } }

@media only screen and (max-width: 768px) and (min-width: 701px) {
  .join-commute-header {
    width: 100%;
    background: white;
    height: 75px;
    position: fixed;
    z-index: 5;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 60px;
    border-bottom: 1px solid #D5D5D5;
    box-sizing: border-box; }
    .join-commute-header .logo-container .branding {
      height: 50px;
      width: 140px; }
    .join-commute-header .logo-container .branding-mobile {
      display: none; }
    .join-commute-header .login-container .login-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #00A664;
      height: 40px;
      width: 140px;
      border: 2px solid #00A664;
      border-radius: 5px; }
  .join-commute {
    padding-bottom: 50px;
    width: 100%;
    min-height: inherit;
    margin: 75px auto 0;
    background-color: #E5E5E5;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal; }
    .join-commute .Collapsible {
      background: #FFFFFF;
      width: 90%;
      height: fit-content;
      margin-top: 50px;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .join-commute .Collapsible:nth-child(4) {
        margin-bottom: 200px; }
      .join-commute .Collapsible .invalid-address-auto {
        border-color: #F96302 !important; }
      .join-commute .Collapsible .invalid-address {
        position: relative;
        display: block;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 600;
        color: #F96302;
        height: 3px; }
      .join-commute .Collapsible .collapsible-heading-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 40px;
        width: 100%;
        height: 100px; }
        .join-commute .Collapsible .collapsible-heading-container .heading {
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #4A4A4A; }
      .join-commute .Collapsible .collapsible-content-container {
        padding: 0px 30px; }
        .join-commute .Collapsible .collapsible-content-container .commute-locations {
          display: flex;
          justify-content: space-between; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations label {
            font-family: 'DINNextLTPro-Medium';
            font-size: 14px;
            line-height: 25px;
            color: #4A4A4A;
            font-style: normal; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations .field {
            width: 48%; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations input {
            border: 2px solid #D5D5D5;
            height: 56px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            width: 100%;
            padding: 15px; }
            .join-commute .Collapsible .collapsible-content-container .commute-locations input:not(:focus) {
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
              padding: 15px 80px 15px 15px; }
            .join-commute .Collapsible .collapsible-content-container .commute-locations input ::placeholder {
              color: #838383; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations #PlacesAutocomplete__autocomplete-container {
            overflow: auto !important; }
        .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays {
          margin-top: 24px; }
          .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays .days .day-btn {
            color: #00A664;
            background-color: #FFFFFF;
            height: 40px;
            width: 40px;
            font-size: 14px;
            text-align: center;
            border-radius: 25px;
            border: solid 2px #00A664;
            cursor: pointer;
            margin: 0 15px 0 0;
            padding: 0;
            text-transform: uppercase;
            padding-top: 5px;
            line-height: 17px;
            font-family: 'DINNextLTPro-Regular';
            font-weight: 700; }
          .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays .days .day-btn.active {
            color: #0F8952;
            border: solid 2px #0F8952;
            background: #D4EDE1; }
        .join-commute .Collapsible .collapsible-content-container .minCommuteDays {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin-top: 16px;
          color: #838383; }
        .join-commute .Collapsible .collapsible-content-container .time-entry {
          display: flex;
          flex-wrap: wrap;
          grid-template-columns: auto auto auto auto;
          justify-content: space-between;
          margin-bottom: 10px; }
          .join-commute .Collapsible .collapsible-content-container .time-entry .entry {
            display: flex;
            flex-wrap: wrap; }
        .join-commute .Collapsible .collapsible-content-container .drive-schedules {
          min-width: 600px;
          display: flex;
          flex-direction: column; }
          .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option {
            color: #4A4A4A;
            min-width: 600px;
            min-height: 105px;
            border: 1px solid #D5D5D5;
            border-radius: 8px;
            margin-top: 15px;
            padding: 24px 24px 24px 15px;
            box-shadow: 0px 2px #D5D5D5;
            cursor: pointer; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option:focus {
              box-shadow: 0 0 5px 0 #4A90E2; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected {
              border: 3px solid #00A664;
              background-color: #D4EDE1;
              box-shadow: 0px 0px; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .text {
                color: #0F8952; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .tick-icon-container {
                position: relative; }
                .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .tick-icon-container .tick-icon {
                  position: absolute;
                  bottom: 15px;
                  right: -10px; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container {
              display: flex;
              justify-content: space-between;
              align-items: center; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .recommended-text {
                height: 20px;
                width: 105px;
                background: #4A90E2;
                font-family: 'DINNextLTPro-Regular';
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                border-radius: 4px;
                margin-bottom: 10px; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .text {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                letter-spacing: -0.25px;
                text-align: left; }
                .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .text.big {
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 32px;
                  letter-spacing: 0.15px; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .comment {
                font-family: 'Proxima Nova';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-top: 10px;
                color: #4A4A4A;
                text-align: left; }
        .join-commute .Collapsible .collapsible-content-container .driver-comment {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          margin-top: 24px;
          color: #636363; }
        .join-commute .Collapsible .collapsible-content-container .comment-two {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          margin-top: 15px;
          color: #636363; }
        .join-commute .Collapsible .collapsible-content-container .company-info .comment {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-bottom: 24px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .employers-list {
          height: 360px;
          overflow: hidden;
          overflow-y: scroll; }
          .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card {
            width: 90%;
            height: 70px;
            border: 1px solid #D5D5D5;
            filter: drop-shadow(0px 1px 0px #D5D5D5);
            border-radius: 8px;
            margin-top: 20px;
            cursor: pointer; }
            .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card .text-container {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-style: normal;
              font-size: 24px;
              line-height: 36px;
              color: #4A4A4A; }
            .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected {
              background: #D4EDE1;
              border: 3px solid #00A664;
              filter: drop-shadow(0px 1px 0px #D5D5D5);
              border-radius: 8px; }
              .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .text-container {
                color: #0F8952; }
              .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .tick-icon-container {
                position: relative; }
                .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .tick-icon-container .tick-icon {
                  position: absolute;
                  bottom: -10px;
                  right: 10px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .employer-changed-container {
          margin-top: 25px; }
          .join-commute .Collapsible .collapsible-content-container .company-info .employer-changed-container .employer-changed {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #00A664;
            border-bottom: 2px solid #00A664;
            padding: 0; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .comment-one {
          font-family: 'Proxima Nova semibold';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #4A4A4A;
          margin-top: 30px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .comment-second {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #4A4A4A;
          margin-top: 10px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .company-input {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-top: 24px; }
          .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .company-input input {
            width: 90%;
            height: 56px;
            border: 2px solid #D5D5D5;
            padding: 10px; }
    .join-commute .continue-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      height: 40px;
      width: 160px;
      color: #FFFFFF;
      margin: 50px 0px 40px;
      border-radius: 5px; }
      .join-commute .continue-button:disabled {
        background-color: #838383;
        cursor: not-allowed; }
      .join-commute .continue-button:enabled {
        background-color: #00A664; }
    .join-commute .arrow {
      border-right: 3px solid;
      border-bottom: 3px solid;
      padding: 5px; }
      .join-commute .arrow.up {
        border-color: #00A664;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg); }
      .join-commute .arrow.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg); }
        .join-commute .arrow.down.locked {
          border-color: #D5D5D5; }
        .join-commute .arrow.down.unlocked {
          border-color: #00A664; } }

@media only screen and (max-width: 700px) {
  .join-commute-header {
    width: 100%;
    background: white;
    height: 75px;
    position: fixed;
    z-index: 5;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #D5D5D5;
    box-sizing: border-box; }
    .join-commute-header .logo-container .branding {
      display: none; }
    .join-commute-header .logo-container .branding-mobile {
      width: 33px;
      height: 44px; }
    .join-commute-header .login-container .login-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #00A664;
      height: 40px;
      width: 140px;
      border: 2px solid #00A664;
      border-radius: 5px; }
  .join-commute {
    padding-bottom: 50px;
    width: 100%;
    min-height: inherit;
    margin: 75px auto 0;
    background-color: #E5E5E5;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal; }
    .join-commute .Collapsible {
      background: #FFFFFF;
      width: 100%;
      height: fit-content;
      margin-top: 20px;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .join-commute .Collapsible:nth-child(4) {
        margin-bottom: 100px; }
      .join-commute .Collapsible .invalid-address-auto {
        border-color: #F96302 !important; }
      .join-commute .Collapsible .invalid-address {
        position: relative;
        display: block;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 600;
        color: #F96302;
        height: 3px; }
      .join-commute .Collapsible .collapsible-heading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 24px;
        padding-right: 40px;
        width: 100%;
        height: 70px; }
        .join-commute .Collapsible .collapsible-heading-container .heading {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: -0.25px;
          color: #4A4A4A; }
      .join-commute .Collapsible .collapsible-content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%; }
        .join-commute .Collapsible .collapsible-content-container .commute-locations {
          width: 100%; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations label {
            font-family: 'DINNextLTPro-Medium';
            font-size: 14px;
            line-height: 25px;
            color: #4A4A4A;
            font-style: normal; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations .field:nth-child(2) {
            margin-top: 15px; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations input {
            border: 2px solid #D5D5D5;
            width: 100%;
            height: 56px;
            z-index: 2;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            padding: 15px; }
            .join-commute .Collapsible .collapsible-content-container .commute-locations input:not(:focus) {
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
              padding: 15px 80px 15px 15px; }
            .join-commute .Collapsible .collapsible-content-container .commute-locations input ::placeholder {
              color: #838383; }
          .join-commute .Collapsible .collapsible-content-container .commute-locations #PlacesAutocomplete__autocomplete-container {
            overflow: auto !important; }
        .join-commute .Collapsible .collapsible-content-container .commute-days {
          width: 100%; }
          .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays {
            margin-top: 20px; }
            .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays .days .day-btn {
              color: #00A664;
              background-color: #FFFFFF;
              height: 35px;
              width: 35px;
              font-size: 12px;
              text-align: center;
              border-radius: 25px;
              border: solid 2px #00A664;
              cursor: pointer;
              margin: 0 10px 0 0;
              padding: 0;
              text-transform: uppercase;
              padding-top: 5px;
              line-height: 17px;
              font-family: 'DINNextLTPro-Regular';
              font-weight: 700; }
            .join-commute .Collapsible .collapsible-content-container .commute-days .commuteDays .days .day-btn.active {
              color: #0F8952;
              border: solid 2px #0F8952;
              background: #D4EDE1; }
        .join-commute .Collapsible .collapsible-content-container .minCommuteDays {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin-top: 16px;
          color: #838383; }
        .join-commute .Collapsible .collapsible-content-container .time-entry {
          display: flex;
          flex-wrap: wrap;
          grid-template-columns: auto auto;
          justify-content: space-between;
          grid-gap: 15px;
          width: 100%;
          margin-bottom: 10px; }
          .join-commute .Collapsible .collapsible-content-container .time-entry .entry {
            display: flex;
            flex-wrap: wrap; }
        .join-commute .Collapsible .collapsible-content-container .drive-schedules {
          width: 100%;
          display: flex;
          flex-direction: column; }
          .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option {
            color: #4A4A4A;
            min-height: 70px;
            border: 1px solid #D5D5D5;
            border-radius: 8px;
            margin-top: 15px;
            padding: 24px 24px 24px 15px;
            box-shadow: 0px 2px #D5D5D5;
            cursor: pointer; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option:focus {
              box-shadow: 0 0 5px 0 #4A90E2; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected {
              border: 3px solid #00A664;
              background-color: #D4EDE1;
              box-shadow: 0px 0px; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .text {
                color: #0F8952; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .tick-icon-container {
                position: relative; }
                .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option.selected .tick-icon-container .tick-icon {
                  position: absolute;
                  bottom: 15px;
                  right: -10px; }
            .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container {
              display: flex;
              justify-content: space-between;
              align-items: center; }
              .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content {
                width: 70%; }
                .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .recommended-text {
                  height: 20px;
                  width: 105px;
                  background: #4A90E2;
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 17px;
                  letter-spacing: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #FFFFFF;
                  border-radius: 4px;
                  margin-bottom: 10px; }
                .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .text {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 21px;
                  line-height: 25px;
                  letter-spacing: -0.25px;
                  text-align: left; }
                  .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .text.big {
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 32px;
                    letter-spacing: 0.15px; }
                .join-commute .Collapsible .collapsible-content-container .drive-schedules .schedule-option .schedule-container .text-content .comment {
                  font-family: 'Proxima Nova';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  margin-top: 10px;
                  color: #4A4A4A;
                  text-align: left; }
        .join-commute .Collapsible .collapsible-content-container .driver-comment {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          margin-top: 24px;
          color: #636363;
          text-align: center;
          width: 90%; }
        .join-commute .Collapsible .collapsible-content-container .comment-two {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 15px;
          color: #636363;
          text-align: center;
          width: 90%; }
        .join-commute .Collapsible .collapsible-content-container .company-info .note {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #838383;
          margin-bottom: 24px;
          text-align: center; }
        .join-commute .Collapsible .collapsible-content-container .company-info .employers-list {
          max-height: 360px;
          overflow: hidden;
          overflow-y: scroll; }
          .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card {
            width: 100%;
            height: 70px;
            border: 1px solid #D5D5D5;
            filter: drop-shadow(0px 1px 0px #D5D5D5);
            border-radius: 8px;
            margin-top: 20px;
            cursor: pointer; }
            .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card .text-container {
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-style: normal;
              font-size: 21px;
              line-height: 25px;
              color: #4A4A4A;
              padding-left: 15px;
              letter-spacing: -0.25px; }
            .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected {
              background: #D4EDE1;
              border: 3px solid #00A664;
              filter: drop-shadow(0px 1px 0px #D5D5D5);
              border-radius: 8px; }
              .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .text-container {
                color: #0F8952; }
              .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .tick-icon-container {
                position: relative; }
                .join-commute .Collapsible .collapsible-content-container .company-info .employers-list .employer-card.selected .tick-icon-container .tick-icon {
                  position: absolute;
                  bottom: -10px;
                  right: 10px; }
        .join-commute .Collapsible .collapsible-content-container .company-info .employer-changed-container {
          margin-top: 25px; }
          .join-commute .Collapsible .collapsible-content-container .company-info .employer-changed-container .employer-changed {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #00A664;
            border-bottom: 2px solid #00A664;
            padding: 0; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .comment-one {
          font-family: 'Proxima Nova semibold';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #838383;
          margin-top: 30px;
          text-align: center; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .comment-second {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #4A4A4A;
          margin-top: 10px;
          text-align: center; }
        .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .company-input {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-top: 24px; }
          .join-commute .Collapsible .collapsible-content-container .company-info .enter-company .company-input input {
            width: 100%;
            height: 56px;
            border: 2px solid #D5D5D5;
            padding: 10px; }
      .join-commute .Collapsible .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        height: 40px;
        width: 100%;
        color: #FFFFFF;
        margin: 50px 0px 40px;
        border-radius: 5px; }
        .join-commute .Collapsible .continue-button:disabled {
          background-color: #838383 !important;
          cursor: not-allowed; }
        .join-commute .Collapsible .continue-button:enabled {
          background-color: #00A664; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.push {
  display: none; }

.forming {
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  color: #838383;
  margin-left: 3px;
  margin-top: 5px; }

.vans-container {
  min-height: calc(100vh);
  padding: 130px 65px;
  background-color: #E5E5E5;
  display: flex;
  justify-content: center; }

.view-matching-vans-container {
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: flex;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  min-height: calc(100vh);
  justify-content: center; }
  .view-matching-vans-container .update-commute {
    height: fit-content;
    background-color: #FFF;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-top: 30px;
    padding: 25px; }
    .view-matching-vans-container .update-commute .select-container .select-header[aria-disabled="true"],
    .view-matching-vans-container .update-commute .flexibility-container .flexibility-header[aria-disabled="true"] {
      pointer-events: none;
      background-color: #EDEDED; }
      .view-matching-vans-container .update-commute .select-container .select-header[aria-disabled="true"] .time-arrow.down,
      .view-matching-vans-container .update-commute .flexibility-container .flexibility-header[aria-disabled="true"] .time-arrow.down {
        display: none; }
    .view-matching-vans-container .update-commute .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      letter-spacing: -0.5px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-bottom: 30px; }
    .view-matching-vans-container .update-commute .invalid-address-auto {
      border-color: #F96302 !important; }
    .view-matching-vans-container .update-commute .invalid-address {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 600;
      color: #F96302;
      height: 3px;
      margin-bottom: 20px !important; }
    .view-matching-vans-container .update-commute .commute-locations {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .view-matching-vans-container .update-commute .commute-locations label {
        font-family: 'DINNextLTPro-Bold';
        font-size: 14px;
        line-height: 25px;
        color: #4A4A4A;
        font-style: normal; }
      .view-matching-vans-container .update-commute .commute-locations .field {
        width: 100%; }
      .view-matching-vans-container .update-commute .commute-locations input {
        border: 2px solid #D5D5D5;
        height: 56px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        letter-spacing: -0.25px;
        color: #4A4A4A;
        width: 100%;
        padding: 15px; }
        .view-matching-vans-container .update-commute .commute-locations input:not(:focus) {
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          padding: 15px 80px 15px 15px; }
        .view-matching-vans-container .update-commute .commute-locations input ::placeholder {
          color: #838383; }
      .view-matching-vans-container .update-commute .commute-locations :disabled {
        background-color: #EDEDED; }
    .view-matching-vans-container .update-commute .commute-days .commuteDays {
      margin-top: 15px; }
      .view-matching-vans-container .update-commute .commute-days .commuteDays .days {
        display: flex;
        flex-wrap: wrap; }
        .view-matching-vans-container .update-commute .commute-days .commuteDays .days .day-btn {
          color: #00A664;
          background-color: #FFFFFF;
          height: 40px;
          width: 40px;
          font-size: 14px;
          text-align: center;
          border-radius: 25px;
          border: solid 2px #00A664;
          cursor: pointer;
          padding: 0;
          text-transform: uppercase;
          line-height: 17px;
          font-family: 'DINNextLTPro-Regular';
          font-weight: 700;
          padding-top: 5px;
          margin-bottom: 5px;
          margin-right: 6px; }
          .view-matching-vans-container .update-commute .commute-days .commuteDays .days .day-btn:not(:last-child) {
            margin-right: 10px; }
          .view-matching-vans-container .update-commute .commute-days .commuteDays .days .day-btn.unavailable {
            color: #D5D5D5;
            border: none;
            background: #FFFFFF;
            border: solid 2px #D5D5D5;
            cursor: default;
            text-decoration: line-through; }
        .view-matching-vans-container .update-commute .commute-days .commuteDays .days .day-btn.active {
          color: #0F8952;
          border: solid 2px #0F8952;
          background: #D4EDE1; }
    .view-matching-vans-container .update-commute .commute-days .minCommuteDays {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-top: 16px;
      color: #838383; }
    .view-matching-vans-container .update-commute .time-entry {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      grid-gap: 15px; }
    .view-matching-vans-container .update-commute .continue-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      height: 40px;
      width: 160px;
      color: #FFFFFF;
      margin: 40px 0px;
      border-radius: 5px; }
      .view-matching-vans-container .update-commute .continue-button:disabled {
        background-color: #838383;
        cursor: not-allowed; }
      .view-matching-vans-container .update-commute .continue-button:enabled {
        background-color: #00A664; }
  .view-matching-vans-container .matching-vans-container {
    flex: 1 1;
    margin-left: 30px;
    top: 0;
    max-width: 1000px; }
    .view-matching-vans-container .matching-vans-container .help-container {
      padding: 24px 0px 16px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .view-matching-vans-container .matching-vans-container .help-container .help-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #4A4A4A; }
      .view-matching-vans-container .matching-vans-container .help-container .help-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: #FFF !important;
        border: 2px solid #00A664;
        border-radius: 5px;
        color: #00A664;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        padding: 8px 24px;
        white-space: nowrap;
        background-color: #E5E5E5 !important;
        margin-top: 12px; }
        .view-matching-vans-container .matching-vans-container .help-container .help-button:disabled {
          border: 2px solid #838383 !important;
          color: #838383;
          cursor: not-allowed;
          border: none; }
    .view-matching-vans-container .matching-vans-container .overlay {
      background: rgba(0, 0, 0, 0.2); }
    .view-matching-vans-container .matching-vans-container .exactMatch {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      letter-spacing: -0.5px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-left: 10px; }
    .view-matching-vans-container .matching-vans-container .noMatches {
      height: 236px;
      background-color: #FFF;
      top: 189px;
      left: 511px;
      padding: 40px 192px 40px 192px;
      border-radius: 8px;
      border: 1px solid #D5D5D5;
      gap: 8px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex !important;
      flex-direction: column;
      align-items: center; }
      .view-matching-vans-container .matching-vans-container .noMatches .rideIcon {
        width: 60px;
        height: 60px; }
      .view-matching-vans-container .matching-vans-container .noMatches .no-match-heading {
        font-family: 'DINNextLTPro-Regular';
        color: #4A4A4A;
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        text-align: center; }
      .view-matching-vans-container .matching-vans-container .noMatches .no-match-content {
        color: #4A4A4A;
        text-align: center;
        font-family: 'Proxima Nova';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 133.333% */
        letter-spacing: -0.25px; }
        .view-matching-vans-container .matching-vans-container .noMatches .no-match-content .inner-content {
          font-weight: 600; }
    .view-matching-vans-container .matching-vans-container .divider {
      display: flex;
      height: 2px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border: 1px solid #D5D5D5;
      margin-top: 32px;
      margin-bottom: 34px; }
    .view-matching-vans-container .matching-vans-container .matched-van-container {
      margin-top: 30px; }
      .view-matching-vans-container .matching-vans-container .matched-van-container.exactm {
        margin-top: 10px; }
      .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van {
        height: 240px;
        display: flex;
        background-color: #FFF;
        border: 1px solid #D5D5D5;
        border-radius: 8px;
        margin-bottom: 20px; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .car-image {
          width: 300px;
          height: 190px; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .car-image img {
            height: 170px;
            margin: 17px 25px; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .car-image .broken-image {
            height: 80px;
            margin-top: 60px;
            margin-left: 95px; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .car-image-opacity {
          width: 300px;
          height: 190px;
          opacity: 0.4; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .car-image-opacity img {
            height: 170px;
            margin: 17px 25px; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .car-image-opacity .broken-image {
            height: 80px;
            margin-top: 60px;
            margin-left: 95px; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details {
          flex: 1; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container {
            cursor: pointer;
            top: 16px;
            position: relative;
            display: inline-block; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container img {
              height: 16px;
              width: 16px;
              margin-right: 4px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container .sponsored-text {
              font-family: 'DINNextLTPro-Regular';
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: -0.25px;
              text-align: left;
              text-decoration: underline;
              text-underline-offset: 2px;
              color: #00A664; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container .tooltip {
              position: absolute;
              top: 130%;
              left: 90px;
              background-color: #4a4a4a;
              color: #FFFFFF;
              padding: 12px;
              border-radius: 8px;
              width: 320px;
              font-size: 14px;
              z-index: 1;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
              display: flex;
              flex-direction: column;
              justify-content: space-between; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container .tooltip-content {
              display: flex;
              justify-content: space-between;
              align-items: flex-start; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container .tooltip-content span {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                padding-right: 8px; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container .tooltip-content img {
                width: 12px;
                height: 12px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container .tooltip-arrow {
              position: absolute;
              bottom: 100%;
              left: 20px;
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 10px solid #4a4a4a; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container .close-icon {
              margin-left: 8px;
              cursor: pointer;
              color: #FFFFFF;
              font-size: 16px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container a {
              text-decoration: underline;
              color: #00A664;
              font-weight: bold; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .sponsored-container a:hover {
              color: #00A664; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .vehicle-class {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 40px;
            color: #4A4A4A;
            position: relative;
            top: 30px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .vehicle-class .close-match-label {
              width: fit-content;
              padding: 2px 12px;
              justify-content: flex-end;
              align-items: flex-start;
              flex-shrink: 0;
              border-radius: 4px;
              background: #F7D727;
              position: relative;
              color: #4A4A4A;
              text-align: center;
              font-family: 'DINNextLTPro-Regular';
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details div.forming.mobile {
            display: none; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details {
            position: relative;
            top: 60px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .oprational-days {
              font-family: 'DINNextLTPro-Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
              display: flex;
              align-items: center;
              text-align: center; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .oprational-days .day-btn {
                text-align: center;
                padding: 3px 7px !important;
                cursor: default; }
                .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .oprational-days .day-btn:not(:last-child) {
                  margin-right: 5px; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .oprational-days .active {
                background: #E3EBEE;
                height: 20px;
                width: 30px;
                border-radius: 5px;
                text-transform: uppercase;
                padding-top: 3px;
                font-weight: 700;
                display: flex;
                align-items: center;
                text-align: center; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .oprational-days .not-active {
                background: #D5D5D5;
                height: 20px;
                width: 30px;
                border-radius: 5px;
                text-transform: uppercase;
                padding-top: 3px;
                font-weight: 700;
                display: flex;
                align-items: center;
                text-align: center; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .schedule-details {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              margin-top: 15px;
              display: flex;
              justify-content: space-between;
              width: 70%; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .schedule-details .arrival .first {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 500;
                color: #4A4A4A; }
                .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .schedule-details .arrival .first:nth-child(2) {
                  margin-top: 5px; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .schedule-details .arrival .second {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 400;
                color: #838383; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .schedule-details .depart .first {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 500;
                color: #4A4A4A; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .schedule-details .depart .second {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 400;
                color: #838383; }
                .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-details .commute-details .schedule-details .depart .second:nth-child(2) {
                  margin-top: 5px; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details {
          position: relative; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .privateVan {
            display: flex;
            align-items: left;
            color: #4A4A4A;
            position: relative;
            right: 140px;
            top: 25px;
            flex-flow: column;
            height: 45px;
            width: 90px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .privateVan .employer {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 400; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .privateVan img {
              padding-right: 5px;
              height: 20px;
              width: 25px; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price {
            background: #0F8952;
            border-radius: 0px 8px 0px 0px;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            color: #FFFFFF;
            padding: 10px 10px;
            position: absolute;
            top: 0px;
            right: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price .amount {
              display: flex; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price .dollar {
              font-size: 16px;
              line-height: 16px;
              margin-top: 3px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price .price {
              font-weight: 700;
              font-size: 24px;
              line-height: 32px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price .cents {
              font-weight: 100;
              font-size: 16px;
              line-height: 23px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price .month {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              text-wrap: nowrap; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price-traditional {
            position: absolute;
            background: #4A4A4A;
            border-radius: 0px 8px 0px 0px;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            color: #FFFFFF;
            padding: 10px 10px;
            top: 0px;
            right: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price-traditional .amount {
              display: flex; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price-traditional .dollar {
              font-size: 16px;
              line-height: 16px;
              margin-top: 3px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price-traditional .price {
              font-weight: 700;
              font-size: 24px;
              line-height: 32px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price-traditional .cents {
              font-weight: 100;
              font-size: 16px;
              line-height: 23px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price-traditional .month {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              text-wrap: nowrap; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price-traditional .estimate {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .van-price-traditional .traditional-label {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 19px;
              font-family: 'DINNextLTPro-Regular';
              width: 80px;
              text-align: center; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .matched-van .van-price-details .view-details-button {
            position: absolute;
            width: 170px;
            height: 40px;
            right: 22px;
            bottom: 26px;
            background: #00A664;
            border-radius: 5px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF; }
      .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .closeMatch {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin-bottom: 15px; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matchedMain .closeMatch span {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-left: 10px; }
      .view-matching-vans-container .matching-vans-container .matched-van-container .showMore {
        text-align: center; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .showMore .show-more-button {
          border: 2px solid #0F8952;
          border-radius: 5px;
          width: 250px;
          height: 40px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          color: #0F8952; }

.no-vans-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: #FFFFFF;
  padding: 16px 20px;
  border: 1px solid #D5D5D5;
  border-radius: 8px;
  align-items: flex-start;
  margin-top: 30px; }
  .no-vans-container .heading {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #4A4A4A; }
  .no-vans-container .comment {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #4A4A4A;
    margin-top: 8px; }
    .no-vans-container .comment .callable {
      font-weight: 1000; }
  .no-vans-container .stay-in-touch {
    margin-top: 24px; }
    .no-vans-container .stay-in-touch button {
      background-color: #00A664;
      height: 40px;
      width: 190px;
      border-radius: 5px;
      color: #FFFFFF;
      font-family: 'Proxima Nova Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px; }
  .no-vans-container .comment-two {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #4A4A4A;
    margin-top: 40px; }
    .no-vans-container .comment-two.unspaced {
      margin-top: 20px !important;
      margin-bottom: 20px !important; }
  .no-vans-container .new-route {
    font-family: 'Proxima Nova Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #00A664;
    margin-top: 12px;
    height: 24px;
    width: fit-content;
    padding: 0px;
    border-bottom: 2px solid #00A664; }
  .no-vans-container .foot-privacy {
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #838383;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto; }
    .no-vans-container .foot-privacy a {
      color: #838383;
      text-decoration: underline; }

@media only screen and (max-width: 1360px) and (min-width: 701px) {
  .vans-container {
    padding: 50px 0px; }
  .view-matching-vans-container {
    padding: 100px 15px;
    flex-flow: column; }
    .view-matching-vans-container .update-commute {
      width: 100%;
      height: fit-content; }
      .view-matching-vans-container .update-commute .collapsible-content-container {
        display: flex;
        flex-wrap: wrap;
        padding-right: 25px; }
      .view-matching-vans-container .update-commute .commute-locations {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        order: 1; }
      .view-matching-vans-container .update-commute .commute-days {
        width: 50%;
        order: 3; }
        .view-matching-vans-container .update-commute .commute-days .commuteDays {
          margin-top: 15px; }
          .view-matching-vans-container .update-commute .commute-days .commuteDays .days .day-btn {
            height: 40px;
            width: 40px; }
            .view-matching-vans-container .update-commute .commute-days .commuteDays .days .day-btn:not(:last-child) {
              margin-right: 10px; }
      .view-matching-vans-container .update-commute .time-entry {
        width: 45%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 0;
        column-gap: 5%;
        order: 2;
        margin-left: auto; }
        .view-matching-vans-container .update-commute .time-entry .select-container,
        .view-matching-vans-container .update-commute .time-entry .flexibility-container {
          width: 160px; }
      .view-matching-vans-container .update-commute .continue-button {
        width: 175px;
        order: 4;
        margin-left: auto;
        margin-top: 15px; }
    .view-matching-vans-container .matching-vans-container {
      margin-left: 0px;
      margin-top: 50px;
      width: 100%;
      max-width: unset !important; }
      .view-matching-vans-container .matching-vans-container .exactMatch {
        font-size: 26px; }
      .view-matching-vans-container .matching-vans-container .closeMatch {
        font-size: 26px;
        font-weight: 500;
        margin-left: 14px; }
        .view-matching-vans-container .matching-vans-container .closeMatch span {
          font-size: 14px;
          font-weight: 400; }
      .view-matching-vans-container .matching-vans-container .noMatches {
        height: 236px;
        top: 557px;
        left: 16px;
        padding: 40px 128px 40px 127px;
        border-radius: 8px;
        border: 1px solid #D5D5D5;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex !important;
        flex-direction: column;
        align-items: center; }
        .view-matching-vans-container .matching-vans-container .noMatches .no-match-content .inner-content {
          font-weight: 600; }
      .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details {
        margin-left: -35px; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .vehicle-class {
          top: 25px !important; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .schedule-details {
          width: 70%; }
      .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-price-details .privateVan {
        display: flex;
        align-items: left;
        color: #4A4A4A;
        position: relative;
        right: 140px;
        top: 25px;
        flex-flow: column;
        height: 45px;
        width: 90px; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-price-details .privateVan .forming {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.25px;
          font-style: normal;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #838383;
          margin-left: 10px;
          margin-top: 5px; }
      .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-price-details .employer {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #4A4A4A;
        position: relative;
        right: 9px;
        flex-flow: column;
        height: 45px;
        width: 90px; }
      .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-price-details img {
        padding-right: 5px;
        height: 20px;
        width: 25px; } }

@media only screen and (max-width: 900px) {
  .van-price-details .view-details-button {
    display: none; } }

@media only screen and (max-width: 1360px) and (min-width: 901px) {
  .van-price-details .view-details-button {
    display: block; } }

@media only screen and (max-width: 700px) {
  .view-matching-vans-container {
    flex-flow: column;
    padding: 20px 15px;
    display: flex;
    align-items: center; }
    .view-matching-vans-container .update-commute {
      max-width: 380px; }
      .view-matching-vans-container .update-commute .commute-days .commuteDays .days .day-btn {
        height: 40px;
        width: 40px; }
        .view-matching-vans-container .update-commute .commute-days .commuteDays .days .day-btn:not(:last-child) {
          margin-right: 8px; }
    .view-matching-vans-container .matching-vans-container {
      margin-left: 0px;
      margin-top: 50px; }
      .view-matching-vans-container .matching-vans-container .exactMatch {
        font-size: 26px;
        margin-left: 14px; }
      .view-matching-vans-container .matching-vans-container .closeMatch {
        font-size: 26px;
        font-weight: 500;
        margin-left: 14px; }
        .view-matching-vans-container .matching-vans-container .closeMatch span {
          font-size: 14px;
          font-weight: 400;
          display: block; }
      .view-matching-vans-container .matching-vans-container .noMatches {
        padding: 24px 20px;
        height: fit-content;
        margin: 12px; }
        .view-matching-vans-container .matching-vans-container .noMatches .rideIcon {
          margin: 16px; }
        .view-matching-vans-container .matching-vans-container .noMatches .no-match-heading {
          font-size: 21px;
          font-style: normal;
          line-height: 25px;
          margin-bottom: 8px; }
        .view-matching-vans-container .matching-vans-container .noMatches .no-match-content {
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.25px; }
          .view-matching-vans-container .matching-vans-container .noMatches .no-match-content .inner-content {
            font-weight: 600; }
      .view-matching-vans-container .matching-vans-container .matched-van-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 16px; }
        .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van {
          height: fit-content !important;
          display: inline-flex;
          flex-wrap: wrap;
          max-width: 380px;
          margin: 12px; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .car-image {
            width: 300px;
            text-align: center;
            order: 2;
            position: relative;
            top: -70px;
            left: 35px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .car-image img {
              height: 200px; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .car-image-opacity {
            text-align: center;
            position: relative;
            top: -120px;
            left: 35px;
            order: 2; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .car-image-opacity img {
              height: 200px; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details {
            flex: 1 1 auto !important;
            min-height: 140px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .sponsored-container {
              margin-left: 15px;
              margin-bottom: 5px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .vehicle-class {
              top: 15px !important;
              left: 15px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details div.forming.mobile {
              display: block !important;
              position: relative;
              left: 15px;
              top: 15px; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details {
              position: relative;
              top: 180px !important;
              margin-left: 15px;
              margin-right: 15px; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .oprational-days {
                margin-top: 20px; }
                .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .oprational-days .active {
                  background: #E3EBEE;
                  height: 20px;
                  width: fit-content;
                  border-radius: 5px;
                  text-transform: uppercase;
                  padding-top: 3px;
                  font-weight: 700;
                  font-size: 8px;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  cursor: default; }
                  .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .oprational-days .active:not(:last-child) {
                    margin-right: 5px; }
                .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .oprational-days .not-active {
                  background: #D5D5D5;
                  height: 20px;
                  width: fit-content;
                  border-radius: 5px;
                  text-transform: uppercase;
                  padding-top: 3px;
                  font-weight: 700;
                  font-size: 8px;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  cursor: default; }
                  .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .oprational-days .not-active:not(:last-child) {
                    margin-right: 5px; }
              .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .schedule-details {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                width: 100% !important; }
                .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .schedule-details .arrival .first {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 14px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-weight: 500;
                  color: #4A4A4A; }
                .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .schedule-details .arrival .second {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 14px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-weight: 400;
                  color: #838383; }
                .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .schedule-details .depart {
                  text-align: right; }
                  .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .schedule-details .depart .first {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: -0.25px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: antialiased;
                    font-weight: 500;
                    color: #4A4A4A; }
                  .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-details .commute-details .schedule-details .depart .second {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: -0.25px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: antialiased;
                    font-weight: 400;
                    color: #838383; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-price-details .privateVan {
            left: -15px !important;
            top: 200px !important;
            height: unset !important;
            width: unset !important; }
            .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-price-details .privateVan .web {
              display: none; }
          .view-matching-vans-container .matching-vans-container .matched-van-container .matched-van .van-price-details .employer {
            position: relative;
            top: -120px; }
  .no-vans-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: #FFFFFF;
    padding: 20px 20px;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    align-items: center;
    margin-top: 10px !important; }
    .no-vans-container .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      display: flex;
      align-items: center;
      text-align: center; }
    .no-vans-container .comment {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      margin-top: 16px;
      text-align: center; }
    .no-vans-container .stay-in-touch {
      margin-top: 24px;
      width: 100%; }
      .no-vans-container .stay-in-touch button {
        background-color: #00A664;
        height: 40px;
        width: 100%;
        border-radius: 5px;
        color: #FFFFFF;
        font-family: 'Proxima Nova Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px; }
    .no-vans-container .comment-two {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      margin-top: 30px;
      text-align: center; }
    .no-vans-container .new-route {
      font-family: 'Proxima Nova Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #00A664;
      margin-top: 12px;
      height: 24px;
      width: fit-content;
      padding: 0px;
      border-bottom: 2px solid #00A664; }
    .no-vans-container .foot-privacy {
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #838383;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto; }
      .no-vans-container .foot-privacy a {
        color: #838383;
        text-decoration: underline; }
  .vans-container {
    min-height: calc(100vh);
    padding: 0px 0px;
    background-color: #E5E5E5;
    display: flex;
    justify-content: center;
    padding-top: 60px; } }

@media only screen and (min-width: 1361px) {
  .view-matching-vans-container .update-commute {
    max-width: 418px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.push {
  display: none; }

.vanpool-details-container {
  background-color: #E5E5E5;
  flex-grow: 1;
  display: flex; }
  .vanpool-details-container .vanpool-details {
    margin: 150px auto 40px;
    background-color: #ffffff;
    margin-bottom: 30px;
    overflow-x: scroll;
    padding: 25px; }
    .vanpool-details-container .vanpool-details .alert-box {
      display: flex;
      margin-top: 15px;
      background-color: #FEFBE9;
      padding: 15px; }
      .vanpool-details-container .vanpool-details .alert-box img {
        width: 24px;
        height: 24px;
        margin-right: 15px; }
      .vanpool-details-container .vanpool-details .alert-box .alert-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
        .vanpool-details-container .vanpool-details .alert-box .alert-text .alert-box-heading {
          color: #635610;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .vanpool-details-container .vanpool-details .alert-box .alert-text .alert-box-content {
          color: #635610;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
    .vanpool-details-container .vanpool-details .commute-updated-container {
      margin: 20px 0px; }
      .vanpool-details-container .vanpool-details .commute-updated-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383; }
      .vanpool-details-container .vanpool-details .commute-updated-container .section {
        display: flex;
        justify-content: space-between;
        padding-top: 8px; }
        .vanpool-details-container .vanpool-details .commute-updated-container .section .left {
          display: flex;
          gap: 4px; }
          .vanpool-details-container .vanpool-details .commute-updated-container .section .left .block {
            height: 26px;
            width: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #D5D5D5;
            border-radius: 4px;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
        .vanpool-details-container .vanpool-details .commute-updated-container .section .edit-button {
          color: #00A664;
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-transform: uppercase; }
    .vanpool-details-container .vanpool-details .vanpool-details-top {
      display: flex;
      height: fit-content; }
      .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image {
        width: 100%; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image-opacity {
          opacity: 0.4;
          display: flex;
          justify-content: center; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image-opacity .image-not-available {
            height: 120px;
            margin-bottom: 80px;
            margin-top: 80px;
            margin-left: 30px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image-opacity img {
            height: 280px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image {
          display: flex;
          justify-content: center; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image img {
            height: 280px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image .image-not-available {
            height: 120px;
            margin-bottom: 80px;
            margin-top: 80px;
            margin-left: 30px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details {
          display: flex;
          justify-content: space-between; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .close-match-label {
            width: fit-content;
            padding: 2px 12px;
            justify-content: flex-end;
            align-items: flex-start;
            flex-shrink: 0;
            border-radius: 4px;
            background: #F7D727;
            position: relative;
            color: #4A4A4A;
            text-align: center;
            font-family: 'DINNextLTPro-Regular';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin-top: 10px;
            margin-left: auto;
            height: fit-content; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-class-details .van-class {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 48px;
            display: flex;
            align-items: center;
            letter-spacing: -0.5px;
            color: #4A4A4A; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-class-details .van-model {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #838383; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-other-details .fastest {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 700;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            background: #4A90E2;
            border-radius: 5px;
            padding: 5px 10px;
            margin-top: 10px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-other-details .private {
            margin-top: 10px;
            width: 100px; }
            .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-other-details .private .employer {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 400;
              color: #838383; }
            .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-other-details .private img {
              padding-right: 5px;
              height: 20px;
              width: 25px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days-heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          color: #838383;
          margin: 20px 25px 8px 0px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days {
          font-family: 'DINNextLTPro-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          text-align: center;
          margin: 5px 0px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days > .day-btn:not(:first-child) {
            margin-left: 10px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days .active {
            background: #E3EBEE;
            height: 45px;
            width: 60px;
            border-radius: 5px;
            text-transform: uppercase;
            padding-top: 3px;
            font-weight: 700;
            text-align: center;
            cursor: default; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days .not-active {
            background: #D5D5D5;
            height: 45px;
            width: 60px;
            border-radius: 5px;
            text-transform: uppercase;
            padding-top: 3px;
            font-weight: 700;
            text-align: center;
            cursor: default; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days .available {
            display: block;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400;
            color: #838383;
            text-transform: capitalize;
            cursor: default; }
      .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map {
        width: 100%; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map .map {
          background: #D5D5D5;
          width: 100%;
          height: 250px;
          margin-top: 30px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map .map .google-map {
            height: 250px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map .map .re-center {
            display: flex;
            justify-content: right; }
            .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map .map .re-center img {
              width: 50px;
              position: relative;
              bottom: 65px;
              right: 50px;
              cursor: pointer; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map .van-route-details {
          margin-left: 0px;
          margin-top: 30px; }
    .vanpool-details-container .vanpool-details .vanpool-details-bottom {
      display: flex;
      height: fit-content;
      margin-bottom: 30px; }
      .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings {
        border-top: 1px solid #4A4A4A;
        margin-top: 20px; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .personal-savings {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 36px;
          display: flex;
          align-items: center;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          margin: 15px 0px 0px 0px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .personal-savings span {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #838383;
            margin-left: 10px; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .savings ul li {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #D5D5D5;
          padding: 10px 0px 3px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .savings ul li .start {
            display: flex; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .savings ul li .start .icon img {
              width: 35px;
              height: 27px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .savings ul li .start .description {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 30px;
              letter-spacing: -0.25px;
              color: #838383;
              padding-left: 20px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .savings ul li .end {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 36px;
            display: flex;
            align-items: center;
            text-align: right;
            color: #4A4A4A; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .savings li:last-child {
          border: none; }
      .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route {
        border-top: 1px solid #4A4A4A;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container {
          cursor: pointer;
          margin-top: 12px;
          margin-bottom: -8px;
          text-align: right;
          position: relative; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container img {
            height: 16px;
            width: 16px;
            margin-right: 4px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container .sponsored-text {
            font-family: 'DINNextLTPro-Regular';
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.25px;
            text-align: left;
            text-decoration: underline;
            text-underline-offset: 2px;
            color: #00A664; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container .tooltip {
            position: absolute;
            bottom: 28px;
            right: 20px;
            background-color: #4a4a4a;
            color: #FFFFFF;
            padding: 12px;
            border-radius: 8px;
            width: 320px;
            font-size: 14px;
            z-index: 1;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container .tooltip-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            text-align: left; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container .tooltip-content span {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              padding-right: 8px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container .tooltip-content img {
              width: 12px;
              height: 12px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container .tooltip-arrow {
            position: absolute;
            top: 100%;
            left: 17rem;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #4a4a4a; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container .close-icon {
            margin-left: 8px;
            cursor: pointer;
            color: #FFFFFF;
            font-size: 16px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container a {
            text-decoration: underline;
            color: #00A664;
            font-weight: bold; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .sponsored-container a:hover {
            color: #00A664; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .vanpool-distance {
          display: flex;
          justify-content: space-between;
          margin-top: 25px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .vanpool-distance .vanpool-distance-details {
            text-align: center; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .vanpool-distance .vanpool-distance-details img {
              height: 25px;
              width: 25px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .vanpool-distance .vanpool-distance-details .value {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 36px;
              align-items: center;
              text-align: center;
              color: #4A4A4A; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .vanpool-distance .vanpool-distance-details .key {
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              align-items: center;
              text-align: center;
              text-transform: capitalize;
              color: #838383;
              margin-top: -10px; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 20px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow {
            display: flex;
            flex-direction: row; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow.traditional-ibp {
              flex-direction: column; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow.traditional-ibp .ibp-learnMore {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-size: 14px;
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow.traditional {
              width: -webkit-fill-available;
              justify-content: space-between; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow.traditional .total-disclaimer {
                display: flex;
                flex-direction: column; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow.traditional .total-disclaimer .total {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 36px;
                  letter-spacing: -0.5px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  display: flex;
                  align-items: center;
                  text-align: right;
                  color: #4A4A4A; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow.traditional .total-disclaimer .traditional-disclaimer {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  font-style: normal;
                  font-weight: 400;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-size: 14px; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow.traditional .total-disclaimer span {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  font-style: normal;
                  font-weight: 400;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-size: 14px;
                  color: #0F8952;
                  text-decoration: underline;
                  cursor: pointer; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow .traditional-label {
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              color: #4A4A4A;
              font-family: 'Proxima Nova'; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow .total {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              display: flex;
              align-items: center;
              text-align: right;
              color: #4A4A4A; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow .van-amount .price-group {
              display: flex;
              margin-left: 15px;
              margin-right: 20px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow .van-amount .price-group .dollar {
                font-size: 18px;
                line-height: 12px;
                padding-right: 3px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow .van-amount .price-group .price {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 700;
                font-size: 36px;
                line-height: 36px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow .van-amount .price-group .cents {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 100;
                font-size: 18px;
                line-height: 12px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .booknow .van-amount .month {
              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 18px;
              color: #4A4A4A;
              margin-left: 30px;
              margin-top: -5px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .book-now-button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            margin-top: 5px;
            width: 180px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .book-now-button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .book-now-button:enabled {
              background-color: #00A664; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .edit-commute-button {
            margin-top: 5px;
            width: 180px;
            white-space: nowrap; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .edit-commute-button.primary {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              text-transform: uppercase;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #FFFFFF;
              border-radius: 5px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .edit-commute-button.primary:disabled {
                background-color: #838383;
                cursor: not-allowed;
                border: none; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .edit-commute-button.primary:enabled {
                background-color: #00A664; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .edit-commute-button.secondary {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              text-align: center;
              text-transform: uppercase;
              background-color: #FFF !important;
              border: 2px solid #00A664;
              border-radius: 5px;
              color: #00A664;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .edit-commute-button.secondary:disabled {
                border: 2px solid #838383 !important;
                color: #838383;
                cursor: not-allowed;
                border: none; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .check-now-button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            width: 180px;
            padding: 8px;
            white-space: nowrap;
            height: 40px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .check-now-button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .check-now-button:enabled {
              background-color: #00A664; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container .contact {
            font-family: 'Proxima Nova Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            margin-right: 20px; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .disclaimer {
          margin-left: 16px;
          margin-top: 16px;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #130C0E;
          text-align: end; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .disclaimer span {
            color: #0F8952;
            text-decoration: underline;
            cursor: pointer; }
      .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container {
        padding: 24px 0px 16px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container .help-text {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.25px;
          color: #4A4A4A; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container .help-button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          padding: 8px 24px;
          white-space: nowrap;
          margin-left: 20px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container .help-button.primary {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container .help-button.primary:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container .help-button.primary:enabled {
              background-color: #00A664; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container .help-button.secondary {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            background-color: #FFF !important;
            border: 2px solid #00A664;
            border-radius: 5px;
            color: #00A664;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container .help-button.secondary:disabled {
              border: 2px solid #838383 !important;
              color: #838383;
              cursor: not-allowed;
              border: none; }

@media only screen and (min-width: 901px) {
  .desktop {
    display: block; }
  .mobile {
    display: none !important; }
  .tablet {
    display: none !important; }
  .vanpool-details-container .vanpool-details {
    margin: 150px auto 0; }
    .vanpool-details-container .vanpool-details .vanpool-details-top {
      gap: 70px; }
    .vanpool-details-container .vanpool-details .vanpool-details-bottom {
      gap: 70px; }
      .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route {
        width: 50%; }
      .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings {
        width: 50%; }
      .vanpool-details-container .vanpool-details .vanpool-details-bottom .help-container {
        border-bottom: 1px solid #D5D5D5; }
      .vanpool-details-container .vanpool-details .vanpool-details-bottom #traditional {
        border-top: 1px solid #D5D5D5;
        border-bottom: none;
        padding: 16px 0px 16px 0px;
        margin-top: 10px; } }

@media only screen and (max-width: 900px) and (min-width: 701px) {
  .vanpool-details-container {
    flex-flow: column; }
    .vanpool-details-container .desktop {
      display: none !important; }
    .vanpool-details-container .tablet {
      display: block; }
    .vanpool-details-container .desktop-only-sponsored {
      display: none !important; }
    .vanpool-details-container .vanpool-details {
      width: 90%;
      max-width: none;
      margin: 90px auto;
      gap: 0px !important; }
      .vanpool-details-container .vanpool-details .divider {
        border: 0.5px solid #D5D5D5;
        margin: 25px 0px; }
      .vanpool-details-container .vanpool-details .vanpool-details-top {
        flex-direction: column;
        display: flex;
        height: auto;
        max-height: none;
        margin: 0px auto;
        width: 500px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .alert-box {
          display: flex; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image-opacity {
          display: flex;
          justify-content: center; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image-opacity img {
            width: 450px;
            height: 350px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image-opacity .car-image {
            display: flex;
            justify-content: center; }
            .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .car-image-opacity .car-image img {
              width: 450px;
              height: 350px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-class-details .van-class {
          font-size: 26px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-class-details .van-model {
          font-size: 16px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .van-details .van-other-details .fastest {
          width: 100px;
          margin-right: 25px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days {
          font-size: 12px;
          margin: 5px 10px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days .active {
            height: 35px;
            width: 45px;
            cursor: default; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days .not-active {
            height: 35px;
            width: 45px;
            cursor: default; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .oprational-days .available {
            display: block;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: -0.25px;
            color: #838383;
            text-transform: capitalize;
            margin-top: -3px;
            cursor: default; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map {
          height: fit-content; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map .map {
            width: auto;
            height: 250px; }
            .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-map .map .google-map {
              height: 250px; }
      .vanpool-details-container .vanpool-details .vanpool-details-bottom {
        flex-direction: column-reverse;
        display: flex;
        height: auto;
        max-height: none;
        margin: 0px auto;
        width: 500px;
        gap: 70px; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings {
          width: auto;
          margin-left: 0px;
          margin-top: -30px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .personal-savings {
            margin-top: 25px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .savings {
            margin-top: 10px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 35px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container {
              cursor: pointer;
              padding: 12px 0 24px 0;
              margin-bottom: -8px;
              text-align: right;
              position: relative; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container img {
                height: 16px;
                width: 16px;
                margin-right: 4px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .sponsored-text {
                font-family: 'DINNextLTPro-Regular';
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.25px;
                text-align: left;
                text-decoration: underline;
                text-underline-offset: 2px;
                color: #00A664; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip {
                position: absolute;
                bottom: 50px;
                right: 20px;
                background-color: #4a4a4a;
                color: #FFFFFF;
                padding: 12px;
                border-radius: 8px;
                width: 320px;
                font-size: 14px;
                z-index: 1;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
                display: flex;
                flex-direction: column;
                justify-content: space-between; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip-content {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                text-align: left; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip-content span {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  font-style: normal;
                  font-weight: 400;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  padding-right: 8px; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip-content img {
                  width: 12px;
                  height: 12px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip-arrow {
                position: absolute;
                top: 100%;
                left: 17rem;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #4a4a4a; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .close-icon {
                margin-left: 8px;
                cursor: pointer;
                color: #FFFFFF;
                font-size: 16px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container a {
                text-decoration: underline;
                color: #00A664;
                font-weight: bold; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container a:hover {
                color: #00A664; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow-help-container {
              display: flex;
              flex-direction: column; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow-help-container.updated {
                flex-direction: column-reverse; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              border-top: 1px solid #4A4A4A;
              padding: 12px 0px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional .total-disclaimer {
                display: flex;
                flex-direction: column; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional .total-disclaimer .total {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 36px;
                  letter-spacing: -0.5px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  display: flex;
                  align-items: center;
                  text-align: right;
                  color: #4A4A4A; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional .total-disclaimer .traditional-disclaimer {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  font-style: normal;
                  font-weight: 400;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-size: 14px; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional .total-disclaimer span {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  font-style: normal;
                  font-weight: 400;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-size: 14px;
                  color: #0F8952;
                  text-decoration: underline;
                  cursor: pointer; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional-ibp {
                flex-direction: row; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional-ibp .ibp-learnMore {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  font-style: normal;
                  font-weight: 400;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-size: 14px;
                  color: #0F8952;
                  text-decoration: underline;
                  cursor: pointer; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional-ibp .sponsored-container {
                  padding: 0px 0 24px 0; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .traditional-label {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                color: #4A4A4A;
                font-family: 'Proxima Nova'; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .total {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.5px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                display: flex;
                align-items: center;
                text-align: right;
                color: #4A4A4A; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 500; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .price-group {
                  display: flex; }
                  .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .price-group .doller {
                    font-size: 16px;
                    line-height: 18px;
                    padding-right: 3px;
                    margin-top: 3px; }
                  .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .price-group .price {
                    font-size: 36px;
                    line-height: 36px; }
                  .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .price-group .decimal {
                    font-weight: 100;
                    font-size: 16px;
                    line-height: 18px; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .month {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 18px;
                  color: #4A4A4A;
                  margin-left: 25px;
                  margin-top: -5px; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .book-now-button {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              text-transform: uppercase;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #FFFFFF;
              border-radius: 5px;
              padding: 8px 24px; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .book-now-button:disabled {
                background-color: #838383;
                cursor: not-allowed;
                border: none; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .book-now-button:enabled {
                background-color: #00A664; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button {
              padding: 8px 24px;
              white-space: nowrap; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.primary {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #FFFFFF;
                border-radius: 5px; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.primary:disabled {
                  background-color: #838383;
                  cursor: not-allowed;
                  border: none; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.primary:enabled {
                  background-color: #00A664; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.secondary {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                background-color: #FFF !important;
                border: 2px solid #00A664;
                border-radius: 5px;
                color: #00A664;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased; }
                .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.secondary:disabled {
                  border: 2px solid #838383 !important;
                  color: #838383;
                  cursor: not-allowed;
                  border: none; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .check-now-button {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              text-transform: uppercase;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #FFFFFF;
              border-radius: 5px;
              padding: 8px 24px;
              margin-right: 30px;
              white-space: nowrap; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .check-now-button:disabled {
                background-color: #838383;
                cursor: not-allowed;
                border: none; }
              .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .check-now-button:enabled {
                background-color: #00A664; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .contact {
              font-family: 'Proxima Nova';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              display: flex;
              align-items: center;
              color: #4A4A4A;
              margin-right: 20px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .disclaimer {
            margin-left: 16px;
            margin-top: 16px;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #130C0E;
            text-align: end; }
            .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .disclaimer span {
              color: #0F8952;
              text-decoration: underline;
              cursor: pointer; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .vanpool-savings .disclaimer.mobile {
            display: none; }
        .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route {
          width: auto;
          height: auto;
          margin-left: 0px;
          margin-top: 30px; }
          .vanpool-details-container .vanpool-details .vanpool-details-bottom .van-deatils-route .booknow-container {
            display: none; } }

@media only screen and (max-width: 700px) {
  .vanpool-details-container {
    flex-flow: column; }
    .vanpool-details-container .desktop {
      display: none !important; }
    .vanpool-details-container .mobile {
      display: block; }
    .vanpool-details-container .tablet {
      display: none !important; }
    .vanpool-details-container .desktop-only-sponsored {
      display: none !important; }
    .vanpool-details-container .vanpool-details {
      width: 100%;
      max-width: none;
      margin: 50px auto 0; }
      .vanpool-details-container .vanpool-details .vanpool-details-top {
        flex-direction: column;
        display: flex;
        height: auto;
        max-height: none; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .vanpool-image .alert-box {
          width: fit-content; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .car-image-opacity {
          display: flex;
          justify-content: center; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .car-image-opacity .car-image {
            display: flex;
            justify-content: center; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .van-details .van-class-details .van-class {
          font-size: 26px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .van-details .van-class-details .van-model {
          font-size: 16px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .van-details .van-other-details .fastest {
          width: 100px;
          margin-right: 12px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .van-details .van-other-details .private {
          margin-top: 10px;
          margin-right: 15px; }
        .vanpool-details-container .vanpool-details .vanpool-details-top .oprational-days {
          font-size: 10px;
          margin: 5px 10px; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .oprational-days .active {
            height: 35px;
            width: 45px;
            cursor: default; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .oprational-days .not-active {
            height: 35px;
            width: 45px;
            cursor: default; }
          .vanpool-details-container .vanpool-details .vanpool-details-top .oprational-days .available {
            display: block;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: -0.25px;
            color: #838383;
            text-transform: capitalize;
            margin-top: -3px;
            cursor: default; }
      .vanpool-details-container .vanpool-details .vanpool-map {
        width: 100%; }
        .vanpool-details-container .vanpool-details .vanpool-map .map {
          width: auto; }
        .vanpool-details-container .vanpool-details .vanpool-map .van-route-details {
          justify-content: center; }
    .vanpool-details-container .vanpool-details-bottom {
      flex-direction: column-reverse;
      display: flex;
      height: auto;
      max-height: none; }
      .vanpool-details-container .vanpool-details-bottom .vanpool-savings {
        width: auto;
        margin-left: 0px;
        margin-top: -30px; }
        .vanpool-details-container .vanpool-details-bottom .vanpool-savings .personal-savings {
          margin: 20px 10px 0px 15px; }
        .vanpool-details-container .vanpool-details-bottom .vanpool-savings .savings {
          margin: 10px 15px; }
        .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile {
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 35px;
          margin-top: 35px;
          flex-direction: column; }
          .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow-help-container {
            display: flex;
            flex-direction: column; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow-help-container.updated {
              flex-direction: column-reverse; }
          .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container {
            cursor: pointer;
            margin-bottom: -8px;
            text-align: right;
            padding: 12px 0 24px 0;
            position: relative; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container img {
              height: 16px;
              width: 16px;
              margin-right: 4px; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .sponsored-text {
              font-family: 'DINNextLTPro-Regular';
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: -0.25px;
              text-align: left;
              text-decoration: underline;
              text-underline-offset: 2px;
              color: #00A664; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip {
              position: absolute;
              bottom: 50px;
              left: 50px;
              background-color: #4a4a4a;
              color: #FFFFFF;
              padding: 12px;
              border-radius: 8px;
              width: 320px;
              font-size: 14px;
              z-index: 1;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
              display: flex;
              flex-direction: column;
              justify-content: space-between; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip-content {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              text-align: left; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip-content span {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                padding-right: 8px; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip-content img {
                width: 12px;
                height: 12px; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .tooltip-arrow {
              position: absolute;
              top: 100%;
              left: 17rem;
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid #4a4a4a; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container .close-icon {
              margin-left: 8px;
              cursor: pointer;
              color: #FFFFFF;
              font-size: 16px; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container a {
              text-decoration: underline;
              color: #00A664;
              font-weight: bold; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .sponsored-container a:hover {
              color: #00A664; }
          .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-top: 1px solid #4A4A4A;
            padding: 12px 0px; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .total {
              display: flex;
              text-align: right;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              margin-left: 50px; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional .total-disclaimer {
              display: flex;
              flex-direction: column; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional .total-disclaimer .total {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.5px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                display: flex;
                align-items: center;
                text-align: right;
                color: #4A4A4A;
                margin-left: 0px; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional .total-disclaimer .traditional-disclaimer {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-size: 14px; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional .total-disclaimer span {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-size: 14px;
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional-ibp {
              flex-direction: row; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional-ibp .ibp-learnMore {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-size: 14px;
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow.traditional-ibp .sponsored-container {
                padding: 0px 0 24px 0; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .traditional-label {
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              color: #4A4A4A;
              font-family: 'Proxima Nova'; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount {
              font-family: 'DINNextLTPro-Regular'; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .price-group {
                display: flex;
                margin-left: 15px;
                margin-right: 20px;
                color: #4A4A4A;
                font-weight: 500; }
                .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .price-group .doller {
                  font-size: 14px;
                  line-height: 18px;
                  padding-right: 3px;
                  margin-top: 3px; }
                .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .price-group .price {
                  font-size: 24px;
                  line-height: 36px; }
                .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .price-group .decimal {
                  font-size: 14px;
                  line-height: 25px; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .booknow .van-amount .month {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #4A4A4A;
                margin-left: 25px;
                margin-top: -5px; }
          .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .book-now-button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            padding: 8px 24px;
            width: 100%; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .book-now-button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .book-now-button:enabled {
              background-color: #00A664; }
          .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button {
            font-size: 16px;
            line-height: 24px;
            width: fit-content;
            padding: 8px 24px;
            margin: 0 auto;
            width: 100%; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.primary {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              text-transform: uppercase;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #FFFFFF;
              border-radius: 5px; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.primary:disabled {
                background-color: #838383;
                cursor: not-allowed;
                border: none; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.primary:enabled {
                background-color: #00A664; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.secondary {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              text-align: center;
              text-transform: uppercase;
              background-color: #FFF !important;
              border: 2px solid #00A664;
              border-radius: 5px;
              color: #00A664;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
              .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .edit-commute-button.secondary:disabled {
                border: 2px solid #838383 !important;
                color: #838383;
                cursor: not-allowed;
                border: none; }
          .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .check-now-button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            padding: 8px 24px;
            white-space: nowrap; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .check-now-button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .vanpool-details-container .vanpool-details-bottom .vanpool-savings .booknow-container-mobile .check-now-button:enabled {
              background-color: #00A664; }
        .vanpool-details-container .vanpool-details-bottom .vanpool-savings .disclaimer {
          margin-left: 16px;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #130C0E; }
          .vanpool-details-container .vanpool-details-bottom .vanpool-savings .disclaimer span {
            color: #0F8952;
            text-decoration: underline;
            cursor: pointer; }
        .vanpool-details-container .vanpool-details-bottom .vanpool-savings .disclaimer.tablet {
          display: none; }
      .vanpool-details-container .vanpool-details-bottom .van-deatils-route {
        width: auto;
        height: auto;
        margin-left: 0px; }
        .vanpool-details-container .vanpool-details-bottom .van-deatils-route .vanpool-distance {
          display: flex;
          justify-content: space-evenly; }
          .vanpool-details-container .vanpool-details-bottom .van-deatils-route .vanpool-distance .vanpool-distance-details {
            margin: 10px !important;
            width: fit-content !important; }
        .vanpool-details-container .vanpool-details-bottom .van-deatils-route .booknow-container {
          display: none; }
        .vanpool-details-container .vanpool-details-bottom .van-deatils-route .booknow-container-mobile {
          flex-direction: column !important; }
      .vanpool-details-container .vanpool-details-bottom .help-container {
        border-bottom: none !important; }
        .vanpool-details-container .vanpool-details-bottom .help-container .help-button {
          margin: 10px 0px !important; }
        .vanpool-details-container .vanpool-details-bottom .help-container.mobile {
          display: flex;
          flex-direction: column;
          align-items: flex-start; }
          .vanpool-details-container .vanpool-details-bottom .help-container.mobile .help-button {
            width: 100%; }
          .vanpool-details-container .vanpool-details-bottom .help-container.mobile .help-text {
            text-align: left; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@media only screen and (min-width: 769px) {
  .stay-in-touch-page,
  .thank-you-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .stay-in-touch-page .stay-in-touch-container,
    .stay-in-touch-page .thank-you-container,
    .thank-you-page .stay-in-touch-container,
    .thank-you-page .thank-you-container {
      max-width: 900px;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 25px; }
      .stay-in-touch-page .stay-in-touch-container .heading,
      .stay-in-touch-page .thank-you-container .heading,
      .thank-you-page .stay-in-touch-container .heading,
      .thank-you-page .thank-you-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .stay-in-touch-page .stay-in-touch-container .comment,
      .stay-in-touch-page .thank-you-container .comment,
      .thank-you-page .stay-in-touch-container .comment,
      .thank-you-page .thank-you-container .comment {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #636363; }
      .stay-in-touch-page .stay-in-touch-container .form,
      .stay-in-touch-page .thank-you-container .form,
      .thank-you-page .stay-in-touch-container .form,
      .thank-you-page .thank-you-container .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px; }
        .stay-in-touch-page .stay-in-touch-container .form .field,
        .stay-in-touch-page .thank-you-container .form .field,
        .thank-you-page .stay-in-touch-container .form .field,
        .thank-you-page .thank-you-container .form .field {
          width: 48%;
          margin-top: 20px; }
          .stay-in-touch-page .stay-in-touch-container .form .field .label,
          .stay-in-touch-page .thank-you-container .form .field .label,
          .thank-you-page .stay-in-touch-container .form .field .label,
          .thank-you-page .thank-you-container .form .field .label {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 100;
            font-size: 13px;
            line-height: 16px;
            color: #4A4A4A;
            margin-bottom: 4px; }
          .stay-in-touch-page .stay-in-touch-container .form .field input,
          .stay-in-touch-page .thank-you-container .form .field input,
          .thank-you-page .stay-in-touch-container .form .field input,
          .thank-you-page .thank-you-container .form .field input {
            width: 100%;
            background: #FFFFFF;
            border: 2px solid #D5D5D5;
            box-sizing: border-box;
            height: 56px;
            padding: 16px 14px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            letter-spacing: -0.25px;
            color: #4A4A4A; }
            .stay-in-touch-page .stay-in-touch-container .form .field input.invalid,
            .stay-in-touch-page .thank-you-container .form .field input.invalid,
            .thank-you-page .stay-in-touch-container .form .field input.invalid,
            .thank-you-page .thank-you-container .form .field input.invalid {
              border-color: #F96302 !important; }
          .stay-in-touch-page .stay-in-touch-container .form .field .invalid-text,
          .stay-in-touch-page .thank-you-container .form .field .invalid-text,
          .thank-you-page .stay-in-touch-container .form .field .invalid-text,
          .thank-you-page .thank-you-container .form .field .invalid-text {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            color: #F96302;
            height: 3px;
            margin-top: 5px; }
          .stay-in-touch-page .stay-in-touch-container .form .field:nth-child(5) input,
          .stay-in-touch-page .thank-you-container .form .field:nth-child(5) input,
          .thank-you-page .stay-in-touch-container .form .field:nth-child(5) input,
          .thank-you-page .thank-you-container .form .field:nth-child(5) input {
            background: #EDEDED;
            color: #838383; }
      .stay-in-touch-page .stay-in-touch-container .button-container,
      .stay-in-touch-page .thank-you-container .button-container,
      .thank-you-page .stay-in-touch-container .button-container,
      .thank-you-page .thank-you-container .button-container {
        margin-top: 50px;
        margin-bottom: 50px; }
        .stay-in-touch-page .stay-in-touch-container .button-container .continue-button,
        .stay-in-touch-page .thank-you-container .button-container .continue-button,
        .thank-you-page .stay-in-touch-container .button-container .continue-button,
        .thank-you-page .thank-you-container .button-container .continue-button {
          width: 150px;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .stay-in-touch-page .stay-in-touch-container .button-container .continue-button:disabled,
          .stay-in-touch-page .thank-you-container .button-container .continue-button:disabled,
          .thank-you-page .stay-in-touch-container .button-container .continue-button:disabled,
          .thank-you-page .thank-you-container .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .stay-in-touch-page .stay-in-touch-container .button-container .continue-button:enabled,
          .stay-in-touch-page .thank-you-container .button-container .continue-button:enabled,
          .thank-you-page .stay-in-touch-container .button-container .continue-button:enabled,
          .thank-you-page .thank-you-container .button-container .continue-button:enabled {
            background-color: #00A664; }
  .thank-you-page {
    padding-bottom: 400px !important; }
    .thank-you-page .thank-you-container .heading-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px; }
      .thank-you-page .thank-you-container .heading-container .tick {
        height: 36px;
        width: 36px; }
      .thank-you-page .thank-you-container .heading-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        margin-left: 15px; }
    .thank-you-page .thank-you-container .content-container {
      margin-right: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .thank-you-page .thank-you-container .content-container .left-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        margin-top: 15px; }
      .thank-you-page .thank-you-container .content-container .left-container .comment-two {
        margin-top: 32px;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #838383; }
      .thank-you-page .thank-you-container .content-container .left-container .callable .desktop {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        text-align: center;
        color: #00A664; }
      .thank-you-page .thank-you-container .content-container .left-container button {
        padding: 0px;
        gap: 10px;
        width: 186px;
        height: 40px;
        border: 2px solid #00A664;
        border-radius: 5px;
        margin-top: 15px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #00A664;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin-bottom: 30px; }
      .thank-you-page .thank-you-container .content-container .right-container {
        padding-top: 30px;
        padding-bottom: 30px; }
    .thank-you-page .thank-you-container .foot-privacy {
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #838383;
      margin-left: auto;
      margin-right: auto; }
      .thank-you-page .thank-you-container .foot-privacy a {
        color: #838383;
        text-decoration: underline; } }

@media only screen and (max-width: 1024px) and (min-width: 700px) {
  .stay-in-touch-page,
  .thank-you-page {
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    height: calc(100vh);
    background-color: #E5E5E5; }
    .stay-in-touch-page .stay-in-touch-container,
    .stay-in-touch-page .thank-you-container,
    .thank-you-page .stay-in-touch-container,
    .thank-you-page .thank-you-container {
      width: 90%;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 25px; }
      .stay-in-touch-page .stay-in-touch-container .heading,
      .stay-in-touch-page .thank-you-container .heading,
      .thank-you-page .stay-in-touch-container .heading,
      .thank-you-page .thank-you-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .stay-in-touch-page .stay-in-touch-container .comment,
      .stay-in-touch-page .thank-you-container .comment,
      .thank-you-page .stay-in-touch-container .comment,
      .thank-you-page .thank-you-container .comment {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #636363; }
      .stay-in-touch-page .stay-in-touch-container .form,
      .stay-in-touch-page .thank-you-container .form,
      .thank-you-page .stay-in-touch-container .form,
      .thank-you-page .thank-you-container .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px; }
        .stay-in-touch-page .stay-in-touch-container .form .field,
        .stay-in-touch-page .thank-you-container .form .field,
        .thank-you-page .stay-in-touch-container .form .field,
        .thank-you-page .thank-you-container .form .field {
          width: 48%;
          margin-top: 20px; }
          .stay-in-touch-page .stay-in-touch-container .form .field .label,
          .stay-in-touch-page .thank-you-container .form .field .label,
          .thank-you-page .stay-in-touch-container .form .field .label,
          .thank-you-page .thank-you-container .form .field .label {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 100;
            font-size: 13px;
            line-height: 16px;
            color: #4A4A4A;
            margin-bottom: 4px; }
          .stay-in-touch-page .stay-in-touch-container .form .field input,
          .stay-in-touch-page .thank-you-container .form .field input,
          .thank-you-page .stay-in-touch-container .form .field input,
          .thank-you-page .thank-you-container .form .field input {
            width: 100%;
            background: #FFFFFF;
            border: 2px solid #D5D5D5;
            box-sizing: border-box;
            height: 56px;
            padding: 16px 14px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            letter-spacing: -0.25px;
            color: #4A4A4A; }
            .stay-in-touch-page .stay-in-touch-container .form .field input.invalid,
            .stay-in-touch-page .thank-you-container .form .field input.invalid,
            .thank-you-page .stay-in-touch-container .form .field input.invalid,
            .thank-you-page .thank-you-container .form .field input.invalid {
              border-color: #F96302 !important; }
          .stay-in-touch-page .stay-in-touch-container .form .field .invalid-text,
          .stay-in-touch-page .thank-you-container .form .field .invalid-text,
          .thank-you-page .stay-in-touch-container .form .field .invalid-text,
          .thank-you-page .thank-you-container .form .field .invalid-text {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            color: #F96302;
            height: 3px;
            margin-top: 5px; }
          .stay-in-touch-page .stay-in-touch-container .form .field:nth-child(5) input,
          .stay-in-touch-page .thank-you-container .form .field:nth-child(5) input,
          .thank-you-page .stay-in-touch-container .form .field:nth-child(5) input,
          .thank-you-page .thank-you-container .form .field:nth-child(5) input {
            background: #EDEDED;
            color: #838383; }
      .stay-in-touch-page .stay-in-touch-container .button-container,
      .stay-in-touch-page .thank-you-container .button-container,
      .thank-you-page .stay-in-touch-container .button-container,
      .thank-you-page .thank-you-container .button-container {
        margin-top: 50px;
        margin-bottom: 50px; }
        .stay-in-touch-page .stay-in-touch-container .button-container .continue-button,
        .stay-in-touch-page .thank-you-container .button-container .continue-button,
        .thank-you-page .stay-in-touch-container .button-container .continue-button,
        .thank-you-page .thank-you-container .button-container .continue-button {
          width: 150px;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .stay-in-touch-page .stay-in-touch-container .button-container .continue-button:disabled,
          .stay-in-touch-page .thank-you-container .button-container .continue-button:disabled,
          .thank-you-page .stay-in-touch-container .button-container .continue-button:disabled,
          .thank-you-page .thank-you-container .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .stay-in-touch-page .stay-in-touch-container .button-container .continue-button:enabled,
          .stay-in-touch-page .thank-you-container .button-container .continue-button:enabled,
          .thank-you-page .stay-in-touch-container .button-container .continue-button:enabled,
          .thank-you-page .thank-you-container .button-container .continue-button:enabled {
            background-color: #00A664; }
  .thank-you-container .heading-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px; }
    .thank-you-container .heading-container .tick {
      height: 36px;
      width: 36px; }
    .thank-you-container .heading-container .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 48px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #4A4A4A;
      margin-left: 15px; }
  .thank-you-container .content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .thank-you-container .content-container .left-container .comment {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      margin-top: 15px; }
    .thank-you-container .content-container .left-container .comment-two {
      margin-top: 32px;
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #838383; }
    .thank-you-container .content-container .left-container .callable .desktop {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      text-align: center;
      color: #00A664; }
    .thank-you-container .content-container .left-container button {
      padding: 0px;
      gap: 10px;
      width: 186px;
      height: 40px;
      border: 2px solid #00A664;
      border-radius: 5px;
      margin-top: 15px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #00A664;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin-bottom: 30px; }
    .thank-you-container .content-container .right-container {
      padding-top: 30px;
      padding-bottom: 30px; }
      .thank-you-container .content-container .right-container img {
        width: 200px;
        height: 140px; }
  .thank-you-container .foot-privacy {
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #838383;
    margin-left: auto;
    margin-right: auto; }
    .thank-you-container .foot-privacy a {
      color: #838383;
      text-decoration: underline; } }

@media only screen and (max-width: 700px) {
  .stay-in-touch-page,
  .thank-you-page {
    padding-top: 30px;
    padding-bottom: 100px;
    width: 100%;
    background-color: #E5E5E5; }
    .stay-in-touch-page .stay-in-touch-container,
    .stay-in-touch-page .thank-you-container,
    .thank-you-page .stay-in-touch-container,
    .thank-you-page .thank-you-container {
      width: 95%;
      margin: 75px auto 50px;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 25px; }
      .stay-in-touch-page .stay-in-touch-container .heading,
      .stay-in-touch-page .thank-you-container .heading,
      .thank-you-page .stay-in-touch-container .heading,
      .thank-you-page .thank-you-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: center; }
      .stay-in-touch-page .stay-in-touch-container .comment,
      .stay-in-touch-page .thank-you-container .comment,
      .thank-you-page .stay-in-touch-container .comment,
      .thank-you-page .thank-you-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: center;
        color: #4A4A4A; }
      .stay-in-touch-page .stay-in-touch-container .form,
      .stay-in-touch-page .thank-you-container .form,
      .thank-you-page .stay-in-touch-container .form,
      .thank-you-page .thank-you-container .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px; }
        .stay-in-touch-page .stay-in-touch-container .form .field,
        .stay-in-touch-page .thank-you-container .form .field,
        .thank-you-page .stay-in-touch-container .form .field,
        .thank-you-page .thank-you-container .form .field {
          width: 100%;
          margin-top: 20px; }
          .stay-in-touch-page .stay-in-touch-container .form .field .label,
          .stay-in-touch-page .thank-you-container .form .field .label,
          .thank-you-page .stay-in-touch-container .form .field .label,
          .thank-you-page .thank-you-container .form .field .label {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 100;
            font-size: 13px;
            line-height: 16px;
            color: #4A4A4A;
            margin-bottom: 4px; }
          .stay-in-touch-page .stay-in-touch-container .form .field input,
          .stay-in-touch-page .thank-you-container .form .field input,
          .thank-you-page .stay-in-touch-container .form .field input,
          .thank-you-page .thank-you-container .form .field input {
            width: 100%;
            background: #FFFFFF;
            border: 2px solid #D5D5D5;
            box-sizing: border-box;
            height: 56px;
            padding: 16px 14px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            letter-spacing: -0.25px;
            color: #4A4A4A; }
            .stay-in-touch-page .stay-in-touch-container .form .field input.invalid,
            .stay-in-touch-page .thank-you-container .form .field input.invalid,
            .thank-you-page .stay-in-touch-container .form .field input.invalid,
            .thank-you-page .thank-you-container .form .field input.invalid {
              border-color: #F96302 !important; }
          .stay-in-touch-page .stay-in-touch-container .form .field .invalid-text,
          .stay-in-touch-page .thank-you-container .form .field .invalid-text,
          .thank-you-page .stay-in-touch-container .form .field .invalid-text,
          .thank-you-page .thank-you-container .form .field .invalid-text {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            color: #F96302;
            height: 3px;
            margin-top: 5px; }
          .stay-in-touch-page .stay-in-touch-container .form .field:nth-child(5) input,
          .stay-in-touch-page .thank-you-container .form .field:nth-child(5) input,
          .thank-you-page .stay-in-touch-container .form .field:nth-child(5) input,
          .thank-you-page .thank-you-container .form .field:nth-child(5) input {
            background: #EDEDED;
            color: #838383; }
      .stay-in-touch-page .stay-in-touch-container .button-container,
      .stay-in-touch-page .thank-you-container .button-container,
      .thank-you-page .stay-in-touch-container .button-container,
      .thank-you-page .thank-you-container .button-container {
        margin-top: 50px;
        margin-bottom: 50px; }
        .stay-in-touch-page .stay-in-touch-container .button-container .continue-button,
        .stay-in-touch-page .thank-you-container .button-container .continue-button,
        .thank-you-page .stay-in-touch-container .button-container .continue-button,
        .thank-you-page .thank-you-container .button-container .continue-button {
          width: 100%;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .stay-in-touch-page .stay-in-touch-container .button-container .continue-button:disabled,
          .stay-in-touch-page .thank-you-container .button-container .continue-button:disabled,
          .thank-you-page .stay-in-touch-container .button-container .continue-button:disabled,
          .thank-you-page .thank-you-container .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .stay-in-touch-page .stay-in-touch-container .button-container .continue-button:enabled,
          .stay-in-touch-page .thank-you-container .button-container .continue-button:enabled,
          .thank-you-page .stay-in-touch-container .button-container .continue-button:enabled,
          .thank-you-page .thank-you-container .button-container .continue-button:enabled {
            background-color: #00A664; }
  .thank-you-container .heading-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px; }
    .thank-you-container .heading-container .tick {
      height: 60px;
      width: 60px; }
    .thank-you-container .heading-container .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-top: 30px;
      display: flex;
      align-items: center;
      text-align: center; }
  .thank-you-container .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .thank-you-container .content-container .left-container .comment {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      margin-top: 15px;
      text-align: center; }
    .thank-you-container .content-container .left-container .comment-two {
      margin-top: 40px;
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #838383;
      text-align: center; }
    .thank-you-container .content-container .left-container .callable {
      color: #00A664;
      text-align: center;
      display: block;
      margin-top: 10px; }
    .thank-you-container .content-container .left-container button {
      padding: 0px;
      gap: 10px;
      width: 100%;
      height: 40px;
      border: 2px solid #00A664;
      border-radius: 5px;
      margin-top: 15px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #00A664;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin-bottom: 30px; }
    .thank-you-container .content-container .right-container {
      padding-top: 30px;
      padding-bottom: 30px; }
      .thank-you-container .content-container .right-container img {
        width: 220px;
        height: 150px; }
  .thank-you-container .foot-privacy {
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #838383;
    margin-left: auto;
    margin-right: auto; }
    .thank-you-container .foot-privacy a {
      color: #838383;
      text-decoration: underline; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@media only screen and (min-width: 901px) {
  .choose-plan-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .choose-plan-page .choose-plan-container {
      width: 75%;
      max-width: 900px;
      margin: 50px auto 50px;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .choose-plan-page .choose-plan-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .choose-plan-page .choose-plan-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #636363;
        margin-top: 24px; }
      .choose-plan-page .choose-plan-container .recommended .sponsored-container {
        cursor: pointer;
        margin: 16px 0;
        text-align: right;
        position: relative;
        display: inline-block; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container img {
          height: 16px;
          width: 16px;
          margin-right: 4px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .sponsored-text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.25px;
          text-align: left;
          text-decoration: underline;
          text-underline-offset: 2px;
          color: #00A664; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip {
          position: absolute;
          top: -100px;
          background-color: #4a4a4a;
          color: #FFFFFF;
          padding: 12px;
          border-radius: 8px;
          width: 320px;
          font-size: 14px;
          z-index: 1;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left; }
          .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content span {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 8px; }
          .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content img {
            width: 12px;
            height: 12px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-arrow {
          position: absolute;
          top: 100%;
          left: 6rem;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #4A4A4A; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .close-icon {
          margin-left: 8px;
          cursor: pointer;
          color: #FFFFFF;
          font-size: 16px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container a {
          text-decoration: underline;
          color: #00A664;
          font-weight: bold; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container a:hover {
          color: #00A664; }
      .choose-plan-page .choose-plan-container .recommended .tag {
        background: #4A90E2;
        height: 20px;
        width: 108px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px; }
        .choose-plan-page .choose-plan-container .recommended .tag .text {
          font-family: 'DINNextLTPro-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
          margin: auto; }
      .choose-plan-page .choose-plan-container .recommended .select-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #0F8952;
        margin-top: 15px; }
      .choose-plan-page .choose-plan-container .recommended .comment2 {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        margin-top: 10px;
        padding-left: 20px; }
        .choose-plan-page .choose-plan-container .recommended .comment2 li {
          list-style-type: disc; }
      .choose-plan-page .choose-plan-container .recommended .prices-container {
        display: flex; }
      .choose-plan-page .choose-plan-container .recommended .condition {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383;
        margin-top: 10px; }
      .choose-plan-page .choose-plan-container .recommended .price-charges {
        width: 75%; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper {
          position: relative; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info {
            position: absolute;
            top: 10px;
            left: 80px; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info .message {
              left: -6rem; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open {
              box-shadow: none !important; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message {
                display: block; }
                .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:before, .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:after {
                  content: '';
                  position: absolute;
                  left: 6rem; }
                .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:after {
                  left: calc(6rem + 1px); }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description img {
          height: 16px;
          width: 16px;
          margin-right: 8px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .caret {
          width: 16px;
          /* Adjust size as needed */
          height: 16px;
          margin-right: 8px;
          /* Adjust spacing as needed */
          transition: transform 0.3s ease;
          /* Add transition effect */ }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .rotate {
          transform: rotate(180deg);
          /* Rotate the image by 180 degrees */ }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .icon {
          margin-right: 5px;
          margin-bottom: 2px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .accordion {
          width: 100%;
          transition: 0.4s;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #0F8952;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          padding: 16px 0 0 0; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .accordion.top {
            margin-bottom: 10px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel {
          padding-left: 12px;
          background-color: white;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .rate-accordion,
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-accordion {
            border-bottom: 1px solid #D5D5D5; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .description.tax {
            color: #4A4A4A;
            font-weight: 500;
            margin-left: 5px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .price {
            display: flex;
            color: #4A4A4A; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .total {
            display: flex;
            margin: 0 !important;
            color: #838383; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .panel .total .dollar {
              font-size: 13px !important;
              margin-top: -3px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description,
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            color: #838383;
            margin-bottom: 16px;
            line-height: 16px;
            text-transform: capitalize;
            margin-left: 5px; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description .amount,
            .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description .amount {
              display: flex;
              font-size: 14px;
              color: #838383; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description .amount .dollar,
              .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description .amount .dollar {
                font-size: 13px !important;
                margin-top: 0; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel.active {
          max-height: 500px;
          margin-left: 15px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .bottom {
          margin-left: 0px;
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          color: #4A4A4A;
          padding: 15px 0px 0px 5px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom p {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #000000; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom p span {
              color: #0F8952;
              text-decoration: underline;
              cursor: pointer; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total,
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600 !important;
            color: #4A4A4A; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-label,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-label {
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 36px;
              letter-spacing: -0.5px; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .grp,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .grp {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              margin-left: 10px;
              color: #4A4A4A; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .grp .frequency,
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .grp .frequency {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-size: 15px;
                margin-right: 5px; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price {
              display: flex; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price .dollar,
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price .decimal,
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price .dollar,
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price .decimal {
                font-size: 15px;
                line-height: 20px; }
      .choose-plan-page .choose-plan-container .line {
        border: 1px solid #D5D5D5;
        margin: 30px 15px 30px 0; }
        .choose-plan-page .choose-plan-container .line.text {
          margin: 15px 15px 15px 0; }
      .choose-plan-page .choose-plan-container .more .sponsored-container {
        cursor: pointer;
        margin: 16px 0;
        text-align: right;
        position: relative;
        display: inline-block; }
        .choose-plan-page .choose-plan-container .more .sponsored-container img {
          height: 16px;
          width: 16px;
          margin-right: 4px; }
        .choose-plan-page .choose-plan-container .more .sponsored-container .sponsored-text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.25px;
          text-align: left;
          text-decoration: underline;
          text-underline-offset: 2px;
          color: #00A664; }
        .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip {
          position: absolute;
          top: -100px;
          background-color: #4a4a4a;
          color: #FFFFFF;
          padding: 12px;
          border-radius: 8px;
          width: 320px;
          font-size: 14px;
          z-index: 1;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content span {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 8px; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content img {
            width: 12px;
            height: 12px; }
        .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-arrow {
          position: absolute;
          top: 100%;
          left: 6rem;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #4a4a4a; }
        .choose-plan-page .choose-plan-container .more .sponsored-container .close-icon {
          margin-left: 8px;
          cursor: pointer;
          color: #FFFFFF;
          font-size: 16px; }
        .choose-plan-page .choose-plan-container .more .sponsored-container a {
          text-decoration: underline;
          color: #00A664;
          font-weight: bold; }
        .choose-plan-page .choose-plan-container .more .sponsored-container a:hover {
          color: #00A664; }
      .choose-plan-page .choose-plan-container .more .tag {
        background: #4A4A4A;
        height: 20px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px; }
        .choose-plan-page .choose-plan-container .more .tag .text {
          font-family: 'DINNextLTPro-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
          margin: auto; }
      .choose-plan-page .choose-plan-container .more .select-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        margin-top: 15px; }
      .choose-plan-page .choose-plan-container .more .comment2 {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        margin-top: 10px;
        padding-left: 20px; }
        .choose-plan-page .choose-plan-container .more .comment2 li {
          list-style-type: disc; }
      .choose-plan-page .choose-plan-container .more .prices-container {
        display: flex; }
      .choose-plan-page .choose-plan-container .more .condition {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383;
        margin-top: 10px; }
      .choose-plan-page .choose-plan-container .more .price-charges {
        width: 75%; }
        .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper {
          position: relative; }
          .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info {
            position: absolute;
            top: 10px;
            left: 80px; }
            .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info .message {
              left: -6rem; }
            .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open {
              box-shadow: none !important; }
              .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message {
                display: block; }
                .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:before, .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:after {
                  content: '';
                  position: absolute;
                  left: 6rem; }
                .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:after {
                  left: calc(6rem + 1px); }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description img {
          height: 16px;
          width: 16px;
          margin-right: 8px; }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description img {
          height: 16px;
          width: 16px;
          margin-right: 8px; }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .caret {
          width: 16px;
          /* Adjust size as needed */
          height: 16px;
          margin-right: 8px;
          /* Adjust spacing as needed */
          transition: transform 0.3s ease;
          /* Add transition effect */ }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .rotate {
          transform: rotate(180deg);
          /* Rotate the image by 180 degrees */ }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .icon {
          margin-right: 5px;
          margin-bottom: 2px; }
        .choose-plan-page .choose-plan-container .more .price-charges .accordion {
          width: 100%;
          transition: 0.4s;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #0F8952;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          padding: 16px 0 0 0; }
          .choose-plan-page .choose-plan-container .more .price-charges .accordion.top {
            margin-bottom: 10px; }
        .choose-plan-page .choose-plan-container .more .price-charges .panel {
          padding-left: 12px;
          background-color: white;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .rate-accordion,
          .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-accordion {
            border-bottom: 1px solid #D5D5D5; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .description.tax {
            color: #4A4A4A;
            font-weight: 500;
            margin-left: 5px; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .description img {
            height: 16px;
            width: 16px;
            margin-right: 8px; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .price {
            display: flex;
            color: #4A4A4A; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .total {
            display: flex;
            margin: 0 !important;
            color: #838383; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .total .dollar {
              font-size: 13px !important;
              margin-top: -3px; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description,
          .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            color: #838383;
            margin-bottom: 16px;
            line-height: 16px;
            text-transform: capitalize;
            margin-left: 5px; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description .amount,
            .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description .amount {
              display: flex;
              font-size: 14px;
              color: #838383; }
              .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description .amount .dollar,
              .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description .amount .dollar {
                font-size: 13px !important;
                margin-top: 0; }
        .choose-plan-page .choose-plan-container .more .price-charges .panel.active {
          max-height: 500px;
          margin-left: 15px; }
        .choose-plan-page .choose-plan-container .more .price-charges .bottom {
          margin-left: 0px;
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          color: #4A4A4A;
          padding: 15px 0px 0px 5px; }
          .choose-plan-page .choose-plan-container .more .price-charges .bottom p {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #000000; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom p span {
              color: #0F8952;
              text-decoration: underline;
              cursor: pointer; }
          .choose-plan-page .choose-plan-container .more .price-charges .bottom .total,
          .choose-plan-page .choose-plan-container .more .price-charges .bottom .label {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600 !important;
            color: #4A4A4A; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-label,
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-label {
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 36px;
              letter-spacing: -0.5px; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .grp,
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .grp {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              margin-left: 10px;
              color: #4A4A4A; }
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .grp .frequency,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .grp .frequency {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-size: 15px;
                margin-right: 5px; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price,
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price {
              display: flex; }
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price .dollar,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price .decimal,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price .dollar,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price .decimal {
                font-size: 15px;
                line-height: 20px; }
      .choose-plan-page .choose-plan-container .continue-button {
        width: 156px;
        height: 40px;
        background: #00A664;
        border-radius: 5px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-top: 30px;
        margin-bottom: 30px; }
      .choose-plan-page .choose-plan-container .driver-only-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383;
        margin-top: 10px; }
  .upgrade-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .upgrade-page .upgrade-container {
      width: 70%;
      max-width: 900px;
      margin: 75px auto 50px;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding-top: 50px;
      padding-left: 30px;
      padding-right: 50px; }
      .upgrade-page .upgrade-container .content-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row; }
        .upgrade-page .upgrade-container .content-container .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .upgrade-page .upgrade-container .content-container .comment {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #636363;
          margin-top: 20px; }
        .upgrade-page .upgrade-container .content-container .sub-heading {
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-top: 35px; }
        .upgrade-page .upgrade-container .content-container .right-container {
          margin: 130px 10px 10px 50px; }
      .upgrade-page .upgrade-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        height: 40px;
        width: 160px;
        color: #FFFFFF;
        margin: 25px 0px 40px;
        border-radius: 5px; }
        .upgrade-page .upgrade-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed; }
        .upgrade-page .upgrade-container .continue-button:enabled {
          background-color: #00A664; }
      .upgrade-page .upgrade-container .commuteDays {
        margin-top: 30px; }
        .upgrade-page .upgrade-container .commuteDays .days-container .days .day-btn {
          color: #00A664;
          background-color: #FFFFFF;
          height: 40px;
          width: 40px;
          font-size: 14px;
          text-align: center;
          border-radius: 25px;
          border: solid 2px #00A664;
          cursor: pointer;
          margin: 0 30px 0 0;
          padding: 0;
          text-transform: uppercase;
          padding-top: 5px;
          line-height: 17px;
          font-family: 'DINNextLTPro-Regular';
          font-weight: 700; }
        .upgrade-page .upgrade-container .commuteDays .days-container .days .day-btn.active {
          color: #0F8952;
          border: solid 2px #0F8952;
          background: #D4EDE1; }
        .upgrade-page .upgrade-container .commuteDays .days-container .error {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          margin-top: 10px;
          color: #F96302; } }

@media only screen and (max-width: 900px) and (min-width: 701px) {
  .choose-plan-page {
    padding-top: 100px;
    padding-bottom: 150px;
    width: 100%;
    background-color: #E5E5E5; }
    .choose-plan-page .choose-plan-container {
      width: 90%;
      max-width: 900px;
      margin: 50px auto 50px;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .choose-plan-page .choose-plan-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .choose-plan-page .choose-plan-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #636363;
        margin-top: 24px; }
      .choose-plan-page .choose-plan-container .recommended .sponsored-container {
        cursor: pointer;
        margin: 16px 0;
        text-align: right;
        position: relative;
        display: inline-block; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container img {
          height: 16px;
          width: 16px;
          margin-right: 4px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .sponsored-text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.25px;
          text-align: left;
          text-decoration: underline;
          text-underline-offset: 2px;
          color: #00A664; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip {
          position: absolute;
          top: -100px;
          background-color: #4a4a4a;
          color: #FFFFFF;
          padding: 12px;
          border-radius: 8px;
          width: 320px;
          font-size: 14px;
          z-index: 1;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left; }
          .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content span {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 8px; }
          .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content img {
            width: 12px;
            height: 12px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-arrow {
          position: absolute;
          top: 100%;
          left: 6rem;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #4a4a4a; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .close-icon {
          margin-left: 8px;
          cursor: pointer;
          color: #FFFFFF;
          font-size: 16px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container a {
          text-decoration: underline;
          color: #00A664;
          font-weight: bold; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container a:hover {
          color: #00A664; }
      .choose-plan-page .choose-plan-container .recommended .tag {
        background: #4A90E2;
        height: 20px;
        width: 108px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px; }
        .choose-plan-page .choose-plan-container .recommended .tag .text {
          font-family: 'DINNextLTPro-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
          margin: auto; }
      .choose-plan-page .choose-plan-container .recommended .select-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #0F8952;
        margin-top: 15px; }
      .choose-plan-page .choose-plan-container .recommended .comment2 {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        margin-top: 15px;
        padding-left: 20px; }
        .choose-plan-page .choose-plan-container .recommended .comment2 li {
          list-style-type: disc; }
      .choose-plan-page .choose-plan-container .recommended .prices-container {
        display: flex; }
      .choose-plan-page .choose-plan-container .recommended .condition {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383;
        margin-top: 10px; }
      .choose-plan-page .choose-plan-container .recommended .price-charges {
        width: 95%; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper {
          position: relative; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info {
            position: absolute;
            top: 10px;
            left: 80px; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info .message {
              left: -6rem; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open {
              box-shadow: none !important; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message {
                display: block; }
                .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:before, .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:after {
                  content: '';
                  position: absolute;
                  left: 6rem; }
                .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:after {
                  left: calc(6rem + 1px); }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description img {
          height: 16px;
          width: 16px;
          margin-right: 8px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description img {
          height: 16px;
          width: 16px;
          margin-right: 8px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .caret {
          width: 16px;
          /* Adjust size as needed */
          height: 16px;
          margin-right: 8px;
          /* Adjust spacing as needed */
          transition: transform 0.3s ease;
          /* Add transition effect */ }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .rotate {
          transform: rotate(180deg);
          /* Rotate the image by 180 degrees */ }
        .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .icon {
          margin-right: 5px;
          margin-bottom: 2px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .accordion {
          width: 100%;
          transition: 0.4s;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #0F8952;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          padding: 16px 0 0 0; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .accordion.top {
            margin-bottom: 10px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel {
          padding-left: 12px;
          background-color: white;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .rate-accordion,
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-accordion {
            border-bottom: 1px solid #D5D5D5; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .description.tax {
            color: #4A4A4A;
            font-weight: 500;
            margin-left: 5px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .description img {
            height: 16px;
            width: 16px;
            margin-right: 8px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .price {
            display: flex;
            color: #4A4A4A; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .total {
            display: flex;
            margin: 0 !important;
            color: #838383; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .panel .total .dollar {
              font-size: 13px !important;
              margin-top: -3px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description,
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            color: #838383;
            margin-bottom: 16px;
            line-height: 16px;
            text-transform: capitalize;
            margin-left: 5px; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description .amount,
            .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description .amount {
              display: flex;
              font-size: 14px;
              color: #838383; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description .amount .dollar,
              .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description .amount .dollar {
                font-size: 13px !important;
                margin-top: 0; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel.active {
          max-height: 500px;
          margin-left: 15px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .bottom {
          margin-left: 0px;
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          color: #4A4A4A;
          padding: 15px 0px 0px 5px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom p {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #000000; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom p span {
              color: #0F8952;
              text-decoration: underline;
              cursor: pointer; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total,
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600 !important;
            color: #4A4A4A; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-label,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-label {
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 36px;
              letter-spacing: -0.5px; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .grp,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .grp {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              margin-left: 10px;
              color: #4A4A4A; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .grp .frequency,
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .grp .frequency {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-size: 15px;
                margin-right: 5px; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price {
              display: flex; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price .dollar,
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price .decimal,
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price .dollar,
              .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price .decimal {
                font-size: 15px;
                line-height: 20px; }
      .choose-plan-page .choose-plan-container .line {
        border: 1px solid #D5D5D5;
        margin: 30px 15px 0 0; }
        .choose-plan-page .choose-plan-container .line.text.text {
          margin: 15px 15px 15px 0; }
      .choose-plan-page .choose-plan-container .more {
        margin-top: 40px; }
        .choose-plan-page .choose-plan-container .more .sponsored-container {
          cursor: pointer;
          margin: 16px 0;
          text-align: right;
          position: relative;
          display: inline-block; }
          .choose-plan-page .choose-plan-container .more .sponsored-container img {
            height: 16px;
            width: 16px;
            margin-right: 4px; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .sponsored-text {
            font-family: 'DINNextLTPro-Regular';
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.25px;
            text-align: left;
            text-decoration: underline;
            text-underline-offset: 2px;
            color: #00A664; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip {
            position: absolute;
            top: -100px;
            background-color: #4a4a4a;
            color: #FFFFFF;
            padding: 12px;
            border-radius: 8px;
            width: 320px;
            font-size: 14px;
            z-index: 1;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            text-align: left; }
            .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content span {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              padding-right: 8px; }
            .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content img {
              width: 12px;
              height: 12px; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-arrow {
            position: absolute;
            top: 100%;
            left: 6rem;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #4a4a4a; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .close-icon {
            margin-left: 8px;
            cursor: pointer;
            color: #FFFFFF;
            font-size: 16px; }
          .choose-plan-page .choose-plan-container .more .sponsored-container a {
            text-decoration: underline;
            color: #00A664;
            font-weight: bold; }
          .choose-plan-page .choose-plan-container .more .sponsored-container a:hover {
            color: #00A664; }
        .choose-plan-page .choose-plan-container .more .tag {
          background: #4A4A4A;
          height: 20px;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px; }
          .choose-plan-page .choose-plan-container .more .tag .text {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #FFFFFF;
            margin: auto; }
        .choose-plan-page .choose-plan-container .more .select-heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-top: 15px; }
        .choose-plan-page .choose-plan-container .more .comment2 {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-top: 15px;
          padding-left: 20px; }
          .choose-plan-page .choose-plan-container .more .comment2 li {
            list-style-type: disc; }
        .choose-plan-page .choose-plan-container .more .prices-container {
          display: flex; }
        .choose-plan-page .choose-plan-container .more .condition {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          color: #838383;
          margin-top: 10px; }
        .choose-plan-page .choose-plan-container .more .price-charges {
          width: 95%; }
          .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper {
            position: relative; }
            .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info {
              position: absolute;
              top: 10px;
              left: 80px; }
              .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info .message {
                left: -6rem; }
              .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open {
                box-shadow: none !important; }
                .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message {
                  display: block; }
                  .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:before, .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:after {
                    content: '';
                    position: absolute;
                    left: 6rem; }
                  .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:after {
                    left: calc(6rem + 1px); }
          .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description img {
            height: 16px;
            width: 16px;
            margin-right: 8px; }
          .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description img {
            height: 16px;
            width: 16px;
            margin-right: 8px; }
          .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .caret {
            width: 16px;
            /* Adjust size as needed */
            height: 16px;
            margin-right: 8px;
            /* Adjust spacing as needed */
            transition: transform 0.3s ease;
            /* Add transition effect */ }
          .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .rotate {
            transform: rotate(180deg);
            /* Rotate the image by 180 degrees */ }
          .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .icon {
            margin-right: 5px;
            margin-bottom: 2px; }
          .choose-plan-page .choose-plan-container .more .price-charges .accordion {
            width: 100%;
            transition: 0.4s;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #0F8952;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            padding: 16px 0 0 0; }
            .choose-plan-page .choose-plan-container .more .price-charges .accordion.top {
              margin-bottom: 10px; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel {
            padding-left: 12px;
            background-color: white;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .rate-accordion,
            .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-accordion {
              border-bottom: 1px solid #D5D5D5; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .description.tax {
              color: #4A4A4A;
              font-weight: 500;
              margin-left: 5px; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .price {
              display: flex;
              color: #4A4A4A; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .total {
              display: flex;
              margin: 0 !important;
              color: #838383; }
              .choose-plan-page .choose-plan-container .more .price-charges .panel .total .dollar {
                font-size: 13px !important;
                margin-top: -3px; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description,
            .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description {
              display: flex;
              justify-content: space-between;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              color: #838383;
              margin-bottom: 16px;
              line-height: 16px;
              text-transform: capitalize;
              margin-left: 5px; }
              .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description .amount,
              .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description .amount {
                display: flex;
                font-size: 14px;
                color: #838383; }
                .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description .amount .dollar,
                .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description .amount .dollar {
                  font-size: 13px !important;
                  margin-top: 0; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel.active {
            max-height: 500px;
            margin-left: 15px; }
          .choose-plan-page .choose-plan-container .more .price-charges .bottom {
            margin-left: 0px;
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            color: #4A4A4A;
            padding: 15px 0px 0px 5px; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom p {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #000000; }
              .choose-plan-page .choose-plan-container .more .price-charges .bottom p span {
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .total,
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .label {
              display: flex;
              justify-content: space-between;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600 !important;
              color: #4A4A4A; }
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-label,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-label {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: -0.5px; }
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .grp,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .grp {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-left: 10px;
                color: #4A4A4A; }
                .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .grp .frequency,
                .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .grp .frequency {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  font-style: normal;
                  font-weight: 400;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-size: 15px;
                  margin-right: 5px; }
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price {
                display: flex; }
                .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price .dollar,
                .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price .decimal,
                .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price .dollar,
                .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price .decimal {
                  font-size: 15px;
                  line-height: 20px; }
      .choose-plan-page .choose-plan-container .continue-button {
        width: 156px;
        height: 40px;
        background: #00A664;
        border-radius: 5px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-top: 30px;
        margin-bottom: 30px; }
      .choose-plan-page .choose-plan-container .driver-only-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383; }
  .upgrade-page {
    padding-top: 50px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .upgrade-page .upgrade-container {
      width: 95%;
      max-width: 900px;
      margin: 75px auto 50px;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding-top: 50px;
      padding-left: 30px;
      padding-right: 50px; }
      .upgrade-page .upgrade-container .content-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row; }
        .upgrade-page .upgrade-container .content-container .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .upgrade-page .upgrade-container .content-container .comment {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #636363;
          margin-top: 20px; }
        .upgrade-page .upgrade-container .content-container .sub-heading {
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-top: 35px; }
        .upgrade-page .upgrade-container .content-container .right-container {
          margin: 130px 10px 10px 50px; }
      .upgrade-page .upgrade-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        height: 40px;
        width: 160px;
        color: #FFFFFF;
        margin: 25px 0px 40px;
        border-radius: 5px; }
        .upgrade-page .upgrade-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed; }
        .upgrade-page .upgrade-container .continue-button:enabled {
          background-color: #00A664; }
      .upgrade-page .upgrade-container .commuteDays {
        margin-top: 30px; }
        .upgrade-page .upgrade-container .commuteDays .days-container .days .day-btn {
          color: #00A664;
          background-color: #FFFFFF;
          height: 40px;
          width: 40px;
          font-size: 14px;
          text-align: center;
          border-radius: 25px;
          border: solid 2px #00A664;
          cursor: pointer;
          margin: 0 30px 0 0;
          padding: 0;
          text-transform: uppercase;
          padding-top: 5px;
          line-height: 17px;
          font-family: 'DINNextLTPro-Regular';
          font-weight: 700; }
        .upgrade-page .upgrade-container .commuteDays .days-container .days .day-btn.active {
          color: #0F8952;
          border: solid 2px #0F8952;
          background: #D4EDE1; }
        .upgrade-page .upgrade-container .commuteDays .days-container .error {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          margin-top: 10px;
          color: #F96302; } }

@media only screen and (max-width: 700px) {
  .choose-plan-page {
    padding-top: 50px;
    padding-bottom: 100px;
    width: 100%;
    background-color: #E5E5E5; }
    .choose-plan-page .choose-plan-container {
      width: 95%;
      max-width: 900px;
      margin: 50px auto 50px;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .choose-plan-page .choose-plan-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: center; }
      .choose-plan-page .choose-plan-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #636363;
        margin-top: 24px; }
      .choose-plan-page .choose-plan-container .recommended .sponsored-container {
        cursor: pointer;
        margin: 16px 0;
        text-align: right;
        position: relative;
        display: inline-block; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container img {
          height: 16px;
          width: 16px;
          margin-right: 4px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .sponsored-text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.25px;
          text-align: left;
          text-decoration: underline;
          text-underline-offset: 2px;
          color: #00A664; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip {
          position: absolute;
          top: -100px;
          background-color: #4a4a4a;
          color: #FFFFFF;
          padding: 12px;
          border-radius: 8px;
          width: 320px;
          font-size: 14px;
          z-index: 1;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left; }
          .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content span {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 8px; }
          .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-content img {
            width: 12px;
            height: 12px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .tooltip-arrow {
          position: absolute;
          top: 100%;
          left: 6rem;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #4a4a4a; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container .close-icon {
          margin-left: 8px;
          cursor: pointer;
          color: #FFFFFF;
          font-size: 16px; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container a {
          text-decoration: underline;
          color: #00A664;
          font-weight: bold; }
        .choose-plan-page .choose-plan-container .recommended .sponsored-container a:hover {
          color: #00A664; }
      .choose-plan-page .choose-plan-container .recommended .tag {
        background: #4A90E2;
        height: 20px;
        width: 108px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px; }
        .choose-plan-page .choose-plan-container .recommended .tag .text {
          font-family: 'DINNextLTPro-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
          margin: auto; }
      .choose-plan-page .choose-plan-container .recommended .select-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #0F8952;
        margin-top: 15px; }
      .choose-plan-page .choose-plan-container .recommended .comment2 {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        margin-top: 10px;
        padding-left: 20px; }
        .choose-plan-page .choose-plan-container .recommended .comment2 li {
          list-style-type: disc; }
      .choose-plan-page .choose-plan-container .recommended .prices-container {
        display: flex; }
      .choose-plan-page .choose-plan-container .recommended .condition {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383;
        margin-top: 10px; }
      .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper {
        position: relative; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info {
          position: absolute;
          top: 10px;
          left: 70px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info .message {
            left: -5rem; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open {
            box-shadow: none !important; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message {
              display: block;
              width: 14rem; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:before, .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:after {
                content: '';
                position: absolute;
                left: 5rem; }
              .choose-plan-page .choose-plan-container .recommended .price-charges .accordion-wrapper .info.open .message:after {
                left: calc(5rem + 1px); }
      .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description img {
        height: 16px;
        width: 16px;
        margin-right: 8px; }
      .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description img {
        height: 16px;
        width: 16px;
        margin-right: 8px; }
      .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .caret {
        width: 16px;
        /* Adjust size as needed */
        height: 16px;
        margin-right: 8px;
        /* Adjust spacing as needed */
        transition: transform 0.3s ease;
        /* Add transition effect */ }
      .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .rotate {
        transform: rotate(180deg);
        /* Rotate the image by 180 degrees */ }
      .choose-plan-page .choose-plan-container .recommended .price-charges .price-accordion .description .icon {
        margin-right: 5px;
        margin-bottom: 2px; }
      .choose-plan-page .choose-plan-container .recommended .price-charges .accordion {
        width: 100%;
        transition: 0.4s;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #0F8952;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        padding: 16px 0 0 0; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .accordion.top {
          margin-bottom: 10px; }
      .choose-plan-page .choose-plan-container .recommended .price-charges .panel {
        padding-left: 12px;
        background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel .rate-accordion,
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-accordion {
          border-bottom: 1px solid #D5D5D5; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel .description.rate {
          margin-left: -12px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel .description.tax {
          color: #4A4A4A;
          font-weight: 500;
          margin-left: 5px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel .price {
          display: flex;
          color: #4A4A4A; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel .total {
          display: flex;
          margin: 0 !important;
          color: #838383; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .total .dollar {
            font-size: 13px !important;
            margin-top: -3px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description,
        .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description {
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          color: #838383;
          margin-bottom: 16px;
          line-height: 16px;
          text-transform: capitalize;
          margin-left: 5px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description .amount,
          .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description .amount {
            display: flex;
            font-size: 14px;
            color: #838383; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .panel .tax-description .amount .dollar,
            .choose-plan-page .choose-plan-container .recommended .price-charges .panel .surcharges-description .amount .dollar {
              font-size: 13px !important;
              margin-top: 0; }
      .choose-plan-page .choose-plan-container .recommended .price-charges .panel.active {
        max-height: 500px; }
      .choose-plan-page .choose-plan-container .recommended .price-charges .bottom {
        margin-left: 0px;
        display: flex;
        justify-content: space-between;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        display: flex;
        color: #4A4A4A;
        padding: 15px 0px 0px 5px; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .bottom p {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #000000; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom p span {
            color: #0F8952;
            text-decoration: underline;
            cursor: pointer; }
        .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total,
        .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label {
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 600 !important;
          color: #4A4A4A; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-label,
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-label {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: -0.5px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .grp,
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .grp {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-left: 10px;
            color: #4A4A4A; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .grp .frequency,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .grp .frequency {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-size: 15px;
              margin-right: 5px; }
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price,
          .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price {
            display: flex; }
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price .dollar,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .total .total-price .decimal,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price .dollar,
            .choose-plan-page .choose-plan-container .recommended .price-charges .bottom .label .total-price .decimal {
              font-size: 15px;
              line-height: 20px; }
      .choose-plan-page .choose-plan-container .line {
        border: 1px solid #D5D5D5;
        margin-top: 20px; }
        .choose-plan-page .choose-plan-container .line.text {
          margin-bottom: 10px; }
      .choose-plan-page .choose-plan-container .more {
        margin-top: 30px; }
        .choose-plan-page .choose-plan-container .more .sponsored-container {
          cursor: pointer;
          margin: 16px 0;
          text-align: right;
          position: relative;
          display: inline-block; }
          .choose-plan-page .choose-plan-container .more .sponsored-container img {
            height: 16px;
            width: 16px;
            margin-right: 4px; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .sponsored-text {
            font-family: 'DINNextLTPro-Regular';
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.25px;
            text-align: left;
            text-decoration: underline;
            text-underline-offset: 2px;
            color: #00A664; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip {
            position: absolute;
            top: -100px;
            background-color: #4a4a4a;
            color: #FFFFFF;
            padding: 12px;
            border-radius: 8px;
            width: 320px;
            font-size: 14px;
            z-index: 1;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            text-align: left; }
            .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content span {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              padding-right: 8px; }
            .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-content img {
              width: 12px;
              height: 12px; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .tooltip-arrow {
            position: absolute;
            top: 100%;
            left: 6rem;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #4a4a4a; }
          .choose-plan-page .choose-plan-container .more .sponsored-container .close-icon {
            margin-left: 8px;
            cursor: pointer;
            color: #FFFFFF;
            font-size: 16px; }
          .choose-plan-page .choose-plan-container .more .sponsored-container a {
            text-decoration: underline;
            color: #00A664;
            font-weight: bold; }
          .choose-plan-page .choose-plan-container .more .sponsored-container a:hover {
            color: #00A664; }
        .choose-plan-page .choose-plan-container .more .tag {
          background: #4A4A4A;
          height: 20px;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px; }
          .choose-plan-page .choose-plan-container .more .tag .text {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #FFFFFF;
            margin: auto; }
        .choose-plan-page .choose-plan-container .more .select-heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-top: 15px; }
        .choose-plan-page .choose-plan-container .more .comment2 {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-top: 10px;
          padding-left: 20px; }
          .choose-plan-page .choose-plan-container .more .comment2 li {
            list-style-type: disc; }
        .choose-plan-page .choose-plan-container .more .prices-container {
          display: flex; }
        .choose-plan-page .choose-plan-container .more .condition {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          color: #838383;
          margin-top: 10px; }
        .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper {
          position: relative; }
          .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info {
            position: absolute;
            top: 10px;
            left: 70px; }
            .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info .message {
              left: -5rem; }
            .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open {
              box-shadow: none !important; }
              .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message {
                display: block;
                width: 14rem; }
                .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:before, .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:after {
                  content: '';
                  position: absolute;
                  left: 5rem; }
                .choose-plan-page .choose-plan-container .more .price-charges .accordion-wrapper .info.open .message:after {
                  left: calc(5rem + 1px); }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description img {
          height: 16px;
          width: 16px;
          margin-right: 8px; }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description img {
          height: 16px;
          width: 16px;
          margin-right: 8px; }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .caret {
          width: 16px;
          /* Adjust size as needed */
          height: 16px;
          margin-right: 8px;
          /* Adjust spacing as needed */
          transition: transform 0.3s ease;
          /* Add transition effect */ }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .rotate {
          transform: rotate(180deg);
          /* Rotate the image by 180 degrees */ }
        .choose-plan-page .choose-plan-container .more .price-charges .price-accordion .description .icon {
          margin-right: 5px;
          margin-bottom: 2px; }
        .choose-plan-page .choose-plan-container .more .price-charges .accordion {
          width: 100%;
          transition: 0.4s;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #0F8952;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          padding: 16px 0 0 0; }
          .choose-plan-page .choose-plan-container .more .price-charges .accordion.top {
            margin-bottom: 10px; }
        .choose-plan-page .choose-plan-container .more .price-charges .panel {
          padding-left: 12px;
          background-color: white;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .rate-accordion,
          .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-accordion {
            border-bottom: 1px solid #D5D5D5; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .description.rate {
            margin-left: -12px; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .description.tax {
            color: #4A4A4A;
            font-weight: 500;
            margin-left: 5px; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .price {
            display: flex;
            color: #4A4A4A; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .total {
            display: flex;
            margin: 0 !important;
            color: #838383; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .total .dollar {
              font-size: 13px !important;
              margin-top: -3px; }
          .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description,
          .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            color: #838383;
            margin-bottom: 16px;
            line-height: 16px;
            text-transform: capitalize;
            margin-left: 5px; }
            .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description .amount,
            .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description .amount {
              display: flex;
              font-size: 14px;
              color: #838383; }
              .choose-plan-page .choose-plan-container .more .price-charges .panel .tax-description .amount .dollar,
              .choose-plan-page .choose-plan-container .more .price-charges .panel .surcharges-description .amount .dollar {
                font-size: 13px !important;
                margin-top: 0; }
        .choose-plan-page .choose-plan-container .more .price-charges .panel.active {
          max-height: 500px; }
        .choose-plan-page .choose-plan-container .more .price-charges .bottom {
          margin-left: 0px;
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          color: #4A4A4A;
          padding: 15px 0px 0px 5px; }
          .choose-plan-page .choose-plan-container .more .price-charges .bottom p {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #000000; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom p span {
              color: #0F8952;
              text-decoration: underline;
              cursor: pointer; }
          .choose-plan-page .choose-plan-container .more .price-charges .bottom .total,
          .choose-plan-page .choose-plan-container .more .price-charges .bottom .label {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600 !important;
            color: #4A4A4A; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-label,
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-label {
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 36px;
              letter-spacing: -0.5px; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .grp,
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .grp {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              margin-left: 10px;
              color: #4A4A4A; }
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .grp .frequency,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .grp .frequency {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-size: 15px;
                margin-right: 5px; }
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price,
            .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price {
              display: flex; }
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price .dollar,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .total .total-price .decimal,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price .dollar,
              .choose-plan-page .choose-plan-container .more .price-charges .bottom .label .total-price .decimal {
                font-size: 15px;
                line-height: 20px; }
      .choose-plan-page .choose-plan-container .continue-button {
        width: 100%;
        height: 40px;
        background: #00A664;
        border-radius: 5px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-top: 30px; }
      .choose-plan-page .choose-plan-container .driver-only-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383; }
  .upgrade-page {
    padding-top: 10px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .upgrade-page .upgrade-container {
      width: 95%;
      max-width: 900px;
      margin: 75px auto 50px;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding-top: 50px;
      padding-left: 30px;
      padding-right: 30px; }
      .upgrade-page .upgrade-container .content-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.5px;
        color: #4A4A4A; }
      .upgrade-page .upgrade-container .content-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #636363;
        margin-top: 20px; }
      .upgrade-page .upgrade-container .content-container .sub-heading {
        font-family: 'Proxima Nova Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        margin-top: 35px; }
      .upgrade-page .upgrade-container .content-container .right-container {
        display: none; }
      .upgrade-page .upgrade-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        height: 40px;
        width: 100%;
        color: #FFFFFF;
        margin: 25px 0px 40px;
        border-radius: 5px; }
        .upgrade-page .upgrade-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed; }
        .upgrade-page .upgrade-container .continue-button:enabled {
          background-color: #00A664; }
      .upgrade-page .upgrade-container .commuteDays {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .upgrade-page .upgrade-container .commuteDays .days-container .days .day-btn {
          color: #00A664;
          background-color: #FFFFFF;
          height: 40px;
          width: 40px;
          font-size: 14px;
          text-align: center;
          border-radius: 25px;
          border: solid 2px #00A664;
          cursor: pointer;
          margin: 0 16px 15px 0;
          padding: 0;
          text-transform: uppercase;
          padding-top: 5px;
          line-height: 17px;
          font-family: 'DINNextLTPro-Regular';
          font-weight: 700; }
        .upgrade-page .upgrade-container .commuteDays .days-container .days .day-btn.active {
          color: #0F8952;
          border: solid 2px #0F8952;
          background: #D4EDE1; }
        .upgrade-page .upgrade-container .commuteDays .days-container .error {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          margin-top: 10px;
          color: #F96302; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.taxes-accordion {
  border-top: 1px solid #D5D5D5;
  border-bottom: 1px solid #D5D5D5; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container {
  padding: 24px 0 8px 0;
  border-top: 1px solid #D5D5D5;
  display: flex;
  justify-content: space-between; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container span {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #4A4A4A;
    align-self: center; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container {
    cursor: pointer;
    margin: 16px 0;
    text-align: right;
    position: relative;
    display: inline-block; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container img {
      height: 16px;
      width: 16px;
      margin-right: 4px; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container .sponsored-text {
      font-family: 'DINNextLTPro-Regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.25px;
      text-align: left;
      text-decoration: underline;
      text-underline-offset: 2px;
      color: #00A664; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container .tooltip {
      position: absolute;
      top: -100px;
      background-color: #4a4a4a;
      color: #FFFFFF;
      padding: 12px;
      border-radius: 8px;
      width: 320px;
      font-size: 14px;
      z-index: 1;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      right: 0; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container .tooltip-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left; }
      .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container .tooltip-content span {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        padding-right: 8px;
        color: #FFFFFF; }
      .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container .tooltip-content img {
        width: 12px;
        height: 12px; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container .tooltip-arrow {
      position: absolute;
      top: 100%;
      right: 3rem;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #4A4A4A; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container .close-icon {
      margin-left: 8px;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 16px; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container a {
      text-decoration: underline;
      color: #00A664;
      font-weight: bold; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .summary-sponsored-container .sponsored-container a:hover {
      color: #00A664; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper {
  position: relative; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper .info {
    position: absolute;
    top: 22px;
    left: 130px; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper .accordion .description {
    display: flex;
    align-items: center; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper .accordion .description .icon, .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper .accordion .description .rate-desc {
      margin-left: 5px; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .content {
  display: none;
  padding: 10px; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .open .content {
  display: block; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate-badge {
  display: flex;
  align-items: center;
  margin-bottom: 5px; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate-badge img {
  width: 20px;
  height: 20px;
  margin-right: 5px; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .price {
  font-weight: bold;
  display: flex; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .dollar {
  margin-right: 2px; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .label {
  font-weight: bold;
  margin-bottom: 5px; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion {
  width: 100%;
  transition: 0.4s;
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #838383;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 0 0; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion .description img {
    height: 16px;
    width: 16px;
    margin-right: 8px; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion .description .caret {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    transition: transform 0.3s ease; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion .description .rotate {
    transform: rotate(180deg);
    /* Rotate the image by 180 degrees */ }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion .description .icon {
    margin-right: 0;
    margin-bottom: 2px; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion .price {
    display: flex; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion .total {
    display: flex; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .accordion .total .dollar {
      font-size: 13px !important;
      margin-top: -3px; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .panel {
  padding-left: 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease; }
  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .panel .tax-description, .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .panel .surcharges-description {
    display: flex;
    justify-content: space-between;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 18px;
    color: #838383;
    margin-bottom: 16px;
    text-transform: capitalize; }
    .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .panel .tax-description .amount, .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .panel .surcharges-description .amount {
      display: flex; }
      .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .panel .tax-description .amount .dollar, .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .panel .surcharges-description .amount .dollar {
        font-size: 13px !important;
        margin-top: 0; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .panel.active {
  max-height: 500px;
  margin-left: 15px; }

.vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .frequency {
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased; }

@media only screen and (min-width: 901px) {
  .vanpool-summary-container {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .vanpool-summary-container .vanpool-summary-page {
      max-width: 900px;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 35px;
      overflow-x: auto; }
      .vanpool-summary-container .vanpool-summary-page .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .vanpool-summary-container .vanpool-summary-page .vanpool-summary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        margin-top: 35px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details {
          height: fit-content; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details {
            display: flex;
            justify-content: space-between; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-class {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 30px;
              line-height: 40px;
              display: flex;
              align-items: center;
              letter-spacing: -0.5px;
              color: #4A4A4A; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-model {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group {
              display: flex;
              margin-left: 15px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .doller {
                font-size: 18px;
                line-height: 12px;
                padding-right: 3px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .price {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 700;
                font-size: 36px;
                line-height: 36px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .decimal {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 100;
                font-size: 18px;
                line-height: 12px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .sub {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              text-align: center;
              margin-top: -5px;
              margin-right: 5px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details {
            margin: 20px 0px 20px -15px !important; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays {
            margin-top: 10px;
            margin-left: 35px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul {
              display: flex;
              height: 25px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul li {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #838383;
                border-right: 1px solid #838383;
                padding: 0px 5px;
                margin-top: 3px;
                margin-bottom: 5px;
                text-transform: uppercase; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul :last-child {
                border: none; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity {
          width: 400;
          height: 280px;
          opacity: 0.4; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity img {
            height: 300px;
            margin: -30px 0px -30px 50px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity .image-not-found {
            height: 200px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image {
          width: 400px;
          height: 280px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image img {
            height: 300px;
            margin: -30px 0px 0px 50px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image .image-not-found {
            height: 200px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices {
          height: fit-content;
          display: flex;
          flex-direction: column; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .line {
            height: 3px;
            border: 1px solid #EDEDED; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate {
            display: flex;
            justify-content: space-between;
            margin-top: 10px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text {
              display: flex; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text img {
                height: 25px;
                width: 25px;
                margin-right: 5px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info img {
                height: 18px;
                width: 18px;
                margin-right: 5px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                padding-right: 30px;
                color: #FFFFFF;
                background: #4A4A4A;
                border-color: #4A4A4A;
                left: -1rem;
                top: -6rem;
                max-width: 260px;
                border-radius: 8px; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message:after {
                  transform: rotate(180deg);
                  border-bottom: 0.5625rem solid #4A4A4A;
                  left: 5%;
                  top: 100%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message:before {
                  transform: rotate(180deg);
                  border-bottom: 0.5625rem solid #4A4A4A;
                  left: 5%;
                  top: 100%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message .closeIcon {
                  position: absolute;
                  right: 10px;
                  top: 10px;
                  width: 12px;
                  height: 12px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 40px;
              display: flex;
              align-items: center;
              color: #838383; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .price {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 36px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #838383; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .price .dollar {
                font-size: 13px !important;
                margin-top: -5px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .fuel {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            display: flex;
            align-items: center;
            color: #838383;
            margin-left: 10px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            color: #4A4A4A;
            margin-top: 10px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom p {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #000000; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom p span {
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total {
              display: flex;
              justify-content: space-between;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              display: flex;
              color: #4A4A4A; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .label {
                margin-right: 20px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price {
                display: flex;
                flex-direction: row; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price .dollar {
                  font-size: 16px;
                  margin-top: -5px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .grp {
                display: flex;
                flex-direction: column;
                align-items: center; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan {
          display: flex;
          justify-content: center; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker {
            height: 150px;
            max-width: 390px;
            background: #EDEDED;
            padding: 10px 15px;
            border: 1px solid #D5D5D5;
            border-radius: 4px;
            width: 100%; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .plan-heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #0F8952; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container {
              margin-top: 15px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container label {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: capitalize;
                color: #4A4A4A; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker {
                background: #FFFFFF;
                border: 2px solid #D5D5D5;
                height: 56px;
                max-width: 360px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 20px;
                width: 90%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .placeholder {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 24px;
                  letter-spacing: -0.25px;
                  color: #838383;
                  margin-left: 15px; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .time-arrow {
                  border-right: 2px solid;
                  border-bottom: 2px solid;
                  padding: 3px;
                  border-color: #00A664;
                  height: 10px;
                  width: 10px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .time-arrow.down {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    transition: transform 0.2s linear, opacity 0.2s linear; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display {
                  display: flex;
                  justify-content: flex-start;
                  margin: 5px 0 0 5px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .day {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 24px;
                    color: #00A664; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .month,
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .year {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.25px;
                    color: #4A4A4A; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .month {
                    margin-left: 10px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .year {
                    margin-left: 5px; }
      .vanpool-summary-container .vanpool-summary-page .button-container {
        margin-top: 35px; }
        .vanpool-summary-container .vanpool-summary-page .button-container .continue-button {
          width: 150px;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .vanpool-summary-container .vanpool-summary-page .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .vanpool-summary-container .vanpool-summary-page .button-container .continue-button:enabled {
            background-color: #00A664; }
      .vanpool-summary-container .vanpool-summary-page .popup {
        display: none;
        position: fixed;
        background: #FFF;
        z-index: 20;
        left: 0;
        right: 0;
        top: 20%;
        margin-left: auto;
        margin-right: auto;
        width: 375px;
        border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup:after {
          position: fixed;
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: -2;
          border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #FFF;
          z-index: -1;
          border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup.show {
          display: block; }
        .vanpool-summary-container .vanpool-summary-page .popup.hide {
          display: none; } }

@media only screen and (max-width: 900px) and (min-width: 751px) {
  .vanpool-summary-container {
    padding-top: 100px;
    padding-bottom: 100px;
    width: 98%;
    background-color: #E5E5E5; }
    .vanpool-summary-container .vanpool-summary-page {
      max-width: 95%;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 30px 15px;
      overflow-x: auto; }
      .vanpool-summary-container .vanpool-summary-page .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .vanpool-summary-container .vanpool-summary-page .vanpool-summary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin-top: 35px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details {
          height: fit-content; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details {
            display: flex;
            justify-content: space-between; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-class {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 30px;
              line-height: 40px;
              display: flex;
              align-items: center;
              letter-spacing: -0.5px;
              color: #4A4A4A; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-model {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group {
              display: flex;
              margin-left: 15px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .doller {
                font-size: 18px;
                line-height: 12px;
                padding-right: 3px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .price {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 700;
                font-size: 36px;
                line-height: 36px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .decimal {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 100;
                font-size: 18px;
                line-height: 12px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .sub {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              text-align: center;
              margin-top: -5px;
              margin-right: 5px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details {
            margin: 20px 0px 20px -15px !important; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details .route-icon .line {
              height: 70px !important; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details .destination-address {
              margin-top: 0px !important; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays {
            margin-top: 10px;
            margin-left: 30px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul {
              display: flex;
              height: 25px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul li {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #838383;
                border-right: 1px solid #838383;
                padding: 0px 5px;
                margin-top: 3px;
                margin-bottom: 5px;
                text-transform: uppercase; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul :last-child {
                border: none; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity {
          opacity: 0.4;
          display: flex; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity img {
            height: 250px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image {
          display: flex;
          justify-content: flex-end; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image img {
            height: 250px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices {
          height: fit-content;
          display: flex;
          flex-direction: column; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .line {
            height: 3px;
            border: 1px solid #EDEDED; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate {
            display: flex;
            justify-content: space-between; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text {
              display: flex; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text img {
                height: 25px;
                width: 25px;
                margin-right: 5px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info img {
                height: 18px;
                width: 18px;
                margin-right: 5px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                padding-right: 30px;
                color: #FFFFFF;
                background: #4A4A4A;
                border-color: #4A4A4A;
                left: -1rem;
                top: -6rem;
                max-width: 260px;
                border-radius: 8px; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message:after {
                  transform: rotate(180deg);
                  border-bottom: 0.5625rem solid #4A4A4A;
                  left: 5%;
                  top: 100%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message:before {
                  transform: rotate(180deg);
                  border-bottom: 0.5625rem solid #4A4A4A;
                  left: 5%;
                  top: 100%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message .closeIcon {
                  position: absolute;
                  right: 10px;
                  top: 10px;
                  width: 12px;
                  height: 12px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 40px;
              display: flex;
              align-items: center;
              color: #838383; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .price {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 36px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #838383; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .price .dollar {
                font-size: 13px !important;
                margin-top: -5px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .fuel {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            display: flex;
            align-items: center;
            color: #838383;
            margin-left: 10px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            color: #4A4A4A; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom p {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #000000; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom p span {
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total {
              display: flex;
              justify-content: space-between;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .label {
                margin-right: 20px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price {
                display: flex;
                flex-direction: row; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price .dollar {
                  font-size: 16px;
                  margin-top: -5px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan {
          display: flex;
          justify-content: center; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker {
            height: 150px;
            max-width: 390px;
            background: #EDEDED;
            padding: 10px 15px;
            border: 1px solid #D5D5D5;
            border-radius: 4px;
            width: 100%; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .plan-heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #0F8952; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container {
              margin-top: 15px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container label {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: capitalize;
                color: #4A4A4A; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker {
                background: #FFFFFF;
                border: 2px solid #D5D5D5;
                height: 56px;
                max-width: 360px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 20px;
                width: 90%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .placeholder {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 24px;
                  letter-spacing: -0.25px;
                  color: #838383;
                  margin-left: 15px; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .time-arrow {
                  border-right: 2px solid;
                  border-bottom: 2px solid;
                  padding: 3px;
                  border-color: #00A664;
                  height: 10px;
                  width: 10px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .time-arrow.down {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    transition: transform 0.2s linear, opacity 0.2s linear; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display {
                  display: flex;
                  justify-content: flex-start;
                  margin: 5px 0 0 5px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .day {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 24px;
                    color: #00A664; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .month,
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .year {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.25px;
                    color: #4A4A4A; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .month {
                    margin-left: 10px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .year {
                    margin-left: 5px; }
      .vanpool-summary-container .vanpool-summary-page .button-container {
        margin-top: 35px; }
        .vanpool-summary-container .vanpool-summary-page .button-container .continue-button {
          width: 150px;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .vanpool-summary-container .vanpool-summary-page .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .vanpool-summary-container .vanpool-summary-page .button-container .continue-button:enabled {
            background-color: #00A664; }
      .vanpool-summary-container .vanpool-summary-page .popup {
        display: none;
        position: fixed;
        background: #FFF;
        z-index: 20;
        left: 0;
        right: 0;
        top: 20%;
        margin-left: auto;
        margin-right: auto;
        width: 375px;
        border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup:after {
          position: fixed;
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: -2;
          border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #FFF;
          z-index: -1;
          border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup.show {
          display: block; }
        .vanpool-summary-container .vanpool-summary-page .popup.hide {
          display: none; } }

@media only screen and (max-width: 750px) {
  .vanpool-summary-container {
    padding-top: 5px;
    padding-bottom: 100px;
    width: 100%;
    background-color: #E5E5E5; }
    .vanpool-summary-container .vanpool-summary-page {
      margin: 80px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 20px;
      overflow-x: auto; }
      .vanpool-summary-container .vanpool-summary-page .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: center; }
      .vanpool-summary-container .vanpool-summary-page .vanpool-summary {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        margin-top: 35px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details {
          height: fit-content;
          grid-row: 2; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details {
            display: flex;
            justify-content: space-between;
            width: auto; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-class {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 30px;
              line-height: 40px;
              display: flex;
              align-items: center;
              letter-spacing: -0.5px;
              color: #4A4A4A; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-model {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group {
              display: flex;
              margin-left: 15px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .doller {
                font-size: 18px;
                line-height: 12px;
                padding-right: 3px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .price {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 700;
                font-size: 36px;
                line-height: 36px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .decimal {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 100;
                font-size: 18px;
                line-height: 12px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .sub {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              text-align: center;
              margin-top: -5px;
              margin-right: 5px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details {
            margin: 20px 0px 20px -15px !important; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays {
            margin-top: 10px;
            margin-left: 30px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul {
              display: flex;
              height: 25px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul li {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #838383;
                border-right: 1px solid #838383;
                padding: 0px 5px;
                margin-top: 3px;
                margin-bottom: 5px;
                text-transform: uppercase; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul :last-child {
                border: none; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image {
          grid-row: 1;
          margin-bottom: 20px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity {
            text-align: center;
            opacity: 0.4; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity img {
              height: 200px;
              margin: -30px 0px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image {
            text-align: center; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image img {
              height: 200px;
              margin: -30px 0px; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices {
          height: fit-content;
          display: flex;
          flex-direction: column; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .line {
            height: 3px;
            border: 1px solid #EDEDED; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate {
            display: flex;
            justify-content: space-between; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text {
              display: flex; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text img {
                height: 25px;
                width: 25px;
                margin-right: 5px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info img {
                height: 18px;
                width: 18px;
                margin-right: 5px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                padding-right: 30px;
                color: #FFFFFF;
                background: #4A4A4A;
                border-color: #4A4A4A;
                left: -1rem;
                top: -6rem;
                max-width: 260px;
                border-radius: 8px; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message:after {
                  transform: rotate(180deg);
                  border-bottom: 0.5625rem solid #4A4A4A;
                  left: 5%;
                  top: 100%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message:before {
                  transform: rotate(180deg);
                  border-bottom: 0.5625rem solid #4A4A4A;
                  left: 5%;
                  top: 100%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .rate-text .info .message .closeIcon {
                  position: absolute;
                  right: 10px;
                  top: 10px;
                  width: 12px;
                  height: 12px; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 40px;
              display: flex;
              align-items: center;
              color: #838383; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .price {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 36px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #838383; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .rate .price .dollar {
                font-size: 13px !important;
                margin-top: -5px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .fuel {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            display: flex;
            align-items: center;
            color: #838383;
            margin-left: 10px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            color: #4A4A4A; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom p {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #000000; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom p span {
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total {
              display: flex;
              justify-content: space-between;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .label {
                margin-right: 20px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price {
                display: flex;
                flex-direction: row; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price .dollar {
                  font-size: 16px;
                  margin-top: -5px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .container .tooltip {
            left: -11rem; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-prices .container .tooltip .tooltip-arrow {
              left: 14rem; }
        .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan {
          display: flex;
          justify-content: center;
          grid-row: 3;
          margin-bottom: 10px; }
          .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker {
            height: 150px;
            max-width: 340px;
            background: #EDEDED;
            padding: 10px 15px;
            border: 1px solid #D5D5D5;
            border-radius: 4px;
            width: 100%; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .plan-heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #0F8952; }
            .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container {
              margin-top: 15px; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container label {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: capitalize;
                color: #4A4A4A; }
              .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker {
                background: #FFFFFF;
                border: 2px solid #D5D5D5;
                height: 56px;
                max-width: 310px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 20px;
                width: 90%; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .placeholder {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 24px;
                  letter-spacing: -0.25px;
                  color: #838383;
                  margin-left: 15px; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .time-arrow {
                  border-right: 2px solid;
                  border-bottom: 2px solid;
                  padding: 3px;
                  border-color: #00A664;
                  height: 10px;
                  width: 10px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .time-arrow.down {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    transition: transform 0.2s linear, opacity 0.2s linear; }
                .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display {
                  display: flex;
                  justify-content: flex-start;
                  margin: 5px 0 0 5px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .day {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 24px;
                    color: #00A664; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .month,
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .year {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.25px;
                    color: #4A4A4A; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .month {
                    margin-left: 10px; }
                  .vanpool-summary-container .vanpool-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .picker-container .picker .date-display .year {
                    margin-left: 5px; }
      .vanpool-summary-container .vanpool-summary-page .button-container {
        margin-top: 30px; }
        .vanpool-summary-container .vanpool-summary-page .button-container .continue-button {
          width: 100%;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .vanpool-summary-container .vanpool-summary-page .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .vanpool-summary-container .vanpool-summary-page .button-container .continue-button:enabled {
            background-color: #00A664; }
      .vanpool-summary-container .vanpool-summary-page .popup {
        display: none;
        position: fixed;
        background: #FFF;
        z-index: 20;
        left: 0;
        right: 0;
        top: 20%;
        margin-left: auto;
        margin-right: auto;
        max-width: 375px;
        border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup:after {
          position: fixed;
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: -2;
          border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #FFF;
          z-index: -1;
          border-radius: 5px 5px 0px 0px; }
        .vanpool-summary-container .vanpool-summary-page .popup.show {
          display: block; }
        .vanpool-summary-container .vanpool-summary-page .popup.hide {
          display: none; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@media only screen and (min-width: 769px) {
  .error-screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EDEDED;
    width: 100%;
    height: 100vh; }
    .error-screen-container .error-screen {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 300px 0px; }
      .error-screen-container .error-screen .content {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column; }
        .error-screen-container .error-screen .content .heading {
          align-self: center; }
          .error-screen-container .error-screen .content .heading h2 {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 48px;
            text-align: center;
            letter-spacing: -0.5px;
            color: #4A4A4A;
            width: 420px; }
        .error-screen-container .error-screen .content .comment {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          color: #4A4A4A;
          margin-top: 35px; }
        .error-screen-container .error-screen .content .picture {
          margin-top: 100px;
          display: flex;
          justify-content: center; }
          .error-screen-container .error-screen .content .picture .left,
          .error-screen-container .error-screen .content .picture .right {
            display: flex;
            justify-content: center;
            flex: 0 0 50%;
            align-items: flex-end; }
          .error-screen-container .error-screen .content .picture .left :nth-child(1) {
            margin-bottom: -10px; }
          .error-screen-container .error-screen .content .picture .left :nth-child(2) {
            margin-left: 60px;
            margin-bottom: 10px; }
          .error-screen-container .error-screen .content .picture .right :nth-child(2) {
            margin-left: 20px;
            height: 45px; }
      .error-screen-container .error-screen .line {
        position: relative;
        left: 0;
        width: 100%;
        height: 0px;
        border: 1px solid #00A664;
        margin: auto 0; } }

@media only screen and (max-width: 1024px) and (min-width: 700px) {
  .error-screen-container {
    margin: 75px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EDEDED;
    height: 100vh;
    width: 100%; }
    .error-screen-container .error-screen {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%; }
      .error-screen-container .error-screen .content {
        width: 100%;
        margin: auto; }
        .error-screen-container .error-screen .content .heading h2 {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;
          text-align: center;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          width: 490px;
          margin: auto; }
        .error-screen-container .error-screen .content .comment {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          color: #4A4A4A;
          margin-top: 35px; }
        .error-screen-container .error-screen .content .picture {
          margin-top: 100px;
          display: flex;
          justify-content: center; }
          .error-screen-container .error-screen .content .picture .left,
          .error-screen-container .error-screen .content .picture .right {
            display: flex;
            justify-content: center;
            flex: 0 0 50%;
            align-items: flex-end; }
          .error-screen-container .error-screen .content .picture .left :nth-child(1) {
            margin-bottom: -10px; }
          .error-screen-container .error-screen .content .picture .left :nth-child(2) {
            margin-left: 60px;
            margin-bottom: 10px; }
          .error-screen-container .error-screen .content .picture .right :nth-child(2) {
            margin-left: 20px;
            height: 45px; }
      .error-screen-container .error-screen .line {
        position: relative;
        left: 0;
        width: 100%;
        height: 0px;
        border: 1px solid #00A664;
        margin: auto 0; } }

@media only screen and (max-width: 700px) {
  .error-screen-container {
    margin: 75px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EDEDED;
    height: 100vh;
    width: 100%; }
    .error-screen-container .error-screen {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%; }
      .error-screen-container .error-screen .content {
        width: 100%;
        margin: auto; }
        .error-screen-container .error-screen .content .heading h2 {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          text-align: center;
          width: 80%;
          margin: auto; }
        .error-screen-container .error-screen .content .comment {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          text-align: center;
          color: #4A4A4A;
          margin-top: 35px; }
        .error-screen-container .error-screen .content .picture {
          margin-top: 100px;
          display: flex;
          justify-content: center; }
          .error-screen-container .error-screen .content .picture .left,
          .error-screen-container .error-screen .content .picture .right {
            display: flex;
            justify-content: center;
            flex: 0 0 50%; }
          .error-screen-container .error-screen .content .picture .left {
            display: none; }
          .error-screen-container .error-screen .content .picture .right :nth-child(2) {
            margin-left: 15px;
            margin-bottom: -35px; }
      .error-screen-container .error-screen .line {
        position: relative;
        left: 0;
        width: 100%;
        height: 0px;
        border: 1px solid #00A664;
        margin: auto 0; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@media only screen and (min-width: 701px) {
  .payment-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center; }
    .payment-page .payment-container {
      padding: 30px 70px 30px 25px;
      margin-top: 60px;
      width: 900px;
      max-width: 95%;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .payment-page .payment-container .error-message {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin-top: 10px;
        color: #F96302; }
      .payment-page .payment-container .card-container {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 10px;
        gap: 20px 20px;
        grid-template-areas: "card date" "cardInfo ." "cvvError ."; }
        .payment-page .payment-container .card-container .label {
          font-family: 'DINNextLTPro-Medium';
          font-style: normal;
          font-size: 13px;
          line-height: 25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .payment-page .payment-container .card-container .field input {
          background: #FFFFFF;
          border: 2px solid #D5D5D5;
          height: 56px;
          color: #4A4A4A;
          padding: 15px; }
          .payment-page .payment-container .card-container .field input ::placeholder {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.25px;
            color: #838383;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
          .payment-page .payment-container .card-container .field input:disabled {
            background: #D5D5D5;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.25px;
            color: #838383;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
          .payment-page .payment-container .card-container .field input.error {
            border-color: #F96302;
            box-shadow: none; }
        .payment-page .payment-container .card-container .card-number {
          grid-area: card; }
          .payment-page .payment-container .card-container .card-number input {
            width: 400px; }
        .payment-page .payment-container .card-container .date {
          grid-area: date;
          display: flex;
          justify-content: flex-start;
          flex-direction: column; }
          .payment-page .payment-container .card-container .date .fields {
            display: flex; }
            .payment-page .payment-container .card-container .date .fields .flexibility-container {
              width: 100px; }
              .payment-page .payment-container .card-container .date .fields .flexibility-container .flexibility-header.error {
                border-color: #F96302; }
              .payment-page .payment-container .card-container .date .fields .flexibility-container .dropdown-list {
                max-height: 250px !important;
                width: 100px; }
                .payment-page .payment-container .card-container .date .fields .flexibility-container .dropdown-list .list-item {
                  padding: 0; }
              .payment-page .payment-container .card-container .date .fields .flexibility-container .flexibility-label {
                height: 25px; }
              .payment-page .payment-container .card-container .date .fields .flexibility-container:nth-child(2) {
                margin-left: 15px; }
        .payment-page .payment-container .card-container .card-info {
          grid-area: cardInfo;
          display: flex;
          justify-content: space-between; }
          .payment-page .payment-container .card-container .card-info input {
            width: 190px; }
            .payment-page .payment-container .card-container .card-info input:focus {
              border-bottom: 3px solid #00A664; }
            .payment-page .payment-container .card-container .card-info input.error {
              border: 2px solid #F96302 !important;
              box-shadow: none; }
          .payment-page .payment-container .card-container .card-info .cvv {
            width: 190px; }
            .payment-page .payment-container .card-container .card-info .cvv .label {
              display: flex;
              justify-content: space-between; }
            .payment-page .payment-container .card-container .card-info .cvv input:disabled {
              background-color: #EDEDED; }
            .payment-page .payment-container .card-container .card-info .cvv .info .message {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              padding-right: 30px;
              color: #FFFFFF;
              background: #4A4A4A;
              border-color: #4A4A4A;
              left: -12rem;
              top: -7rem;
              width: 260px;
              border-radius: 8px; }
              .payment-page .payment-container .card-container .card-info .cvv .info .message:after {
                transform: rotate(180deg);
                border-bottom: 0.5625rem solid #4A4A4A;
                left: 73%;
                top: 100%; }
              .payment-page .payment-container .card-container .card-info .cvv .info .message:before {
                transform: rotate(180deg);
                border-bottom: 0.5625rem solid #4A4A4A;
                left: 73%;
                top: 100%; }
              .payment-page .payment-container .card-container .card-info .cvv .info .message .closeIcon {
                position: absolute;
                right: 10px;
                top: 10px; }
        .payment-page .payment-container .card-container .cvv-error {
          grid-area: 'cvvError';
          height: 45px;
          grid-row: 3/3;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: -10px; }
      .payment-page .payment-container .legal-copy {
        display: flex;
        flex-direction: row; }
        .payment-page .payment-container .legal-copy.federal {
          margin-top: 30px; }
        .payment-page .payment-container .legal-copy .lock-image {
          display: flex;
          align-items: center; }
          .payment-page .payment-container .legal-copy .lock-image img {
            width: 24px;
            height: 24px;
            margin-bottom: 5px; }
        .payment-page .payment-container .legal-copy .legal-copy-text {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.25px;
          font-style: normal;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-size: 14px;
          margin-left: 6px; }
      .payment-page .payment-container .federal-container {
        background: #EDEDED;
        border: 1px solid #D5D5D5;
        border-radius: 4px;
        max-width: 650px;
        padding: 0px 50px 15px 15px;
        margin-top: 20px; }
        .payment-page .payment-container .federal-container.zip-cvv-invalid {
          margin-top: 40px; }
        .payment-page .payment-container .federal-container .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.25px;
          color: #4A4A4A; }
        .payment-page .payment-container .federal-container .comment {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A; }
        .payment-page .payment-container .federal-container .card-input-container {
          margin-top: 15px;
          display: flex;
          flex-flow: column; }
          .payment-page .payment-container .federal-container .card-input-container .label {
            font-family: 'DINNextLTPro-Medium';
            font-style: normal;
            font-size: 13px;
            line-height: 25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
          .payment-page .payment-container .federal-container .card-input-container input {
            max-width: 160px;
            padding-left: 30px !important;
            background: url("./images/Dollar.png") no-repeat, #FFFFFF;
            background-position-x: 5px;
            background-position-y: 15px; }
        .payment-page .payment-container .federal-container .tooltips-container {
          margin-top: 15px;
          margin-left: -8px; }
      .payment-page .payment-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .payment-page .payment-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin-top: 15px;
        color: #636363; }
      .payment-page .payment-container .sub-heading {
        margin-top: 20px;
        font-family: 'Proxima Nova Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
      .payment-page .payment-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        height: 40px;
        width: 160px;
        margin: 50px 0px 40px; }
        .payment-page .payment-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .payment-page .payment-container .continue-button:enabled {
          background-color: #00A664; }
      .payment-page .payment-container .payment-info {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        padding-top: 16px; }
      .payment-page .payment-container .sponsored-container {
        cursor: pointer;
        margin: 16px 0;
        text-align: right;
        position: relative;
        display: inline-block; }
        .payment-page .payment-container .sponsored-container img {
          height: 16px;
          width: 16px;
          margin-right: 4px; }
        .payment-page .payment-container .sponsored-container .sponsored-text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.25px;
          text-align: left;
          text-decoration: underline;
          text-underline-offset: 2px;
          color: #00A664; }
        .payment-page .payment-container .sponsored-container .tooltip {
          position: absolute;
          top: -100px;
          background-color: #4a4a4a;
          color: #FFFFFF;
          padding: 12px;
          border-radius: 8px;
          width: 320px;
          font-size: 14px;
          z-index: 1;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .payment-page .payment-container .sponsored-container .tooltip-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left; }
          .payment-page .payment-container .sponsored-container .tooltip-content span {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 8px;
            color: #FFFFFF; }
          .payment-page .payment-container .sponsored-container .tooltip-content img {
            width: 12px;
            height: 12px; }
        .payment-page .payment-container .sponsored-container .tooltip-arrow {
          position: absolute;
          top: 100%;
          left: 6rem;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #4A4A4A; }
        .payment-page .payment-container .sponsored-container .close-icon {
          margin-left: 8px;
          cursor: pointer;
          color: #FFFFFF;
          font-size: 16px; }
        .payment-page .payment-container .sponsored-container a {
          text-decoration: underline;
          color: #00A664;
          font-weight: bold; }
        .payment-page .payment-container .sponsored-container a:hover {
          color: #00A664; } }

@media only screen and (max-width: 700px) {
  .payment-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center; }
    .payment-page .payment-container {
      padding: 30px 25px 30px 25px;
      width: 95%;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .payment-page .payment-container .error-message {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin-top: 10px;
        color: #F96302; }
      .payment-page .payment-container .card-container {
        margin-top: 15px;
        display: flex;
        flex-flow: column; }
        .payment-page .payment-container .card-container .label {
          font-family: 'DINNextLTPro-Medium';
          font-style: normal;
          font-size: 13px;
          line-height: 25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .payment-page .payment-container .card-container .field input {
          background: #FFFFFF;
          border: 2px solid #D5D5D5;
          height: 56px;
          color: #4A4A4A;
          padding: 15px; }
          .payment-page .payment-container .card-container .field input ::placeholder {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.25px;
            color: #838383;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
          .payment-page .payment-container .card-container .field input:disabled {
            background: #D5D5D5;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.25px;
            color: #838383;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
          .payment-page .payment-container .card-container .field input.error {
            border-color: #F96302;
            box-shadow: none; }
        .payment-page .payment-container .card-container .date {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 25px; }
          .payment-page .payment-container .card-container .date .fields {
            display: flex; }
            .payment-page .payment-container .card-container .date .fields .flexibility-container {
              width: 160px !important; }
              .payment-page .payment-container .card-container .date .fields .flexibility-container .flexibility-header.error {
                border-color: #F96302; }
              .payment-page .payment-container .card-container .date .fields .flexibility-container .dropdown-list {
                max-height: 250px !important;
                width: 150px; }
                .payment-page .payment-container .card-container .date .fields .flexibility-container .dropdown-list .list-item {
                  padding: 0;
                  height: 40px; }
              .payment-page .payment-container .card-container .date .fields .flexibility-container .flexibility-label {
                height: 25px; }
                .payment-page .payment-container .card-container .date .fields .flexibility-container .flexibility-label label {
                  font-size: 14px; }
              .payment-page .payment-container .card-container .date .fields .flexibility-container:nth-child(2) {
                margin-left: 15px; }
        .payment-page .payment-container .card-container .card-info {
          margin-top: 25px;
          display: flex;
          justify-content: space-between; }
          .payment-page .payment-container .card-container .card-info .field {
            width: inherit; }
          .payment-page .payment-container .card-container .card-info .cvv,
          .payment-page .payment-container .card-container .card-info .zip {
            max-width: 48%; }
            .payment-page .payment-container .card-container .card-info .cvv input,
            .payment-page .payment-container .card-container .card-info .zip input {
              max-width: inherit;
              width: calc(100%); }
              .payment-page .payment-container .card-container .card-info .cvv input:focus,
              .payment-page .payment-container .card-container .card-info .zip input:focus {
                border-bottom: 3px solid #00A664; }
              .payment-page .payment-container .card-container .card-info .cvv input.error,
              .payment-page .payment-container .card-container .card-info .zip input.error {
                border: 2px solid #F96302 !important;
                box-shadow: none; }
              .payment-page .payment-container .card-container .card-info .cvv input:disabled,
              .payment-page .payment-container .card-container .card-info .zip input:disabled {
                background-color: #EDEDED; }
        .payment-page .payment-container .card-container .cvv-error {
          height: 45px;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .payment-page .payment-container .card-container .cvv .label {
          display: flex;
          justify-content: space-between; }
        .payment-page .payment-container .card-container .cvv .info .message {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          padding-right: 30px;
          color: #FFFFFF;
          background: #4A4A4A;
          border-color: #4A4A4A;
          left: -10rem;
          top: -7rem;
          width: 260px;
          border-radius: 8px; }
          .payment-page .payment-container .card-container .cvv .info .message:after {
            transform: rotate(180deg);
            border-bottom: 0.5625rem solid #4A4A4A;
            left: 60%;
            top: 100%; }
          .payment-page .payment-container .card-container .cvv .info .message:before {
            transform: rotate(180deg);
            border-bottom: 0.5625rem solid #4A4A4A;
            left: 60%;
            top: 100%; }
          .payment-page .payment-container .card-container .cvv .info .message .closeIcon {
            position: absolute;
            right: 10px;
            top: 10px; }
      .payment-page .payment-container .federal-container {
        background: #EDEDED;
        border: 1px solid #D5D5D5;
        border-radius: 4px;
        padding: 0px 20px 15px 15px;
        margin-top: 25px; }
        .payment-page .payment-container .federal-container.zip-cvv-invalid {
          margin-top: 50px; }
        .payment-page .payment-container .federal-container .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.25px;
          color: #4A4A4A;
          text-align: left; }
        .payment-page .payment-container .federal-container .comment {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          text-align: left; }
        .payment-page .payment-container .federal-container .card-input-container {
          margin-top: 15px;
          display: flex;
          flex-flow: column;
          padding: 8px; }
          .payment-page .payment-container .federal-container .card-input-container .label {
            font-family: 'DINNextLTPro-Medium';
            font-style: normal;
            font-size: 13px;
            line-height: 25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
          .payment-page .payment-container .federal-container .card-input-container .error-message {
            font-size: 14px !important; }
          .payment-page .payment-container .federal-container .card-input-container input {
            max-width: 160px;
            padding-left: 30px !important;
            background: url("./images/Dollar.png") no-repeat, #FFFFFF;
            background-position-x: 5px;
            background-position-y: 15px; }
        .payment-page .payment-container .federal-container .tooltips-container {
          margin-top: 15px;
          margin-left: -8px; }
      .payment-page .payment-container .legal-copy {
        display: flex;
        flex-direction: row;
        margin-top: 16px; }
        .payment-page .payment-container .legal-copy.federal {
          margin-top: 30px; }
        .payment-page .payment-container .legal-copy .lock-image {
          display: flex;
          align-items: center; }
          .payment-page .payment-container .legal-copy .lock-image img {
            width: 24px;
            height: 24px;
            margin-bottom: 5px; }
        .payment-page .payment-container .legal-copy .legal-copy-text {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.25px;
          font-style: normal;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-size: 14px;
          margin-left: 6px; }
      .payment-page .payment-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: center; }
      .payment-page .payment-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin-top: 15px;
        color: #636363;
        text-align: center; }
      .payment-page .payment-container .sub-heading {
        font-family: 'Proxima Nova Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
      .payment-page .payment-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        height: 40px;
        width: 160px;
        margin: 50px 0px 40px;
        width: 100%; }
        .payment-page .payment-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .payment-page .payment-container .continue-button:enabled {
          background-color: #00A664; }
      .payment-page .payment-container .sponsored-container {
        cursor: pointer;
        margin: 16px 0;
        text-align: right;
        position: relative;
        display: inline-block; }
        .payment-page .payment-container .sponsored-container img {
          height: 16px;
          width: 16px;
          margin-right: 4px; }
        .payment-page .payment-container .sponsored-container .sponsored-text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.25px;
          text-align: left;
          text-decoration: underline;
          text-underline-offset: 2px;
          color: #00A664; }
        .payment-page .payment-container .sponsored-container .tooltip {
          position: absolute;
          top: -100px;
          background-color: #4a4a4a;
          color: #FFFFFF;
          padding: 12px;
          border-radius: 8px;
          width: 320px;
          font-size: 14px;
          z-index: 1;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .payment-page .payment-container .sponsored-container .tooltip-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left; }
          .payment-page .payment-container .sponsored-container .tooltip-content span {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 8px;
            color: #FFFFFF; }
          .payment-page .payment-container .sponsored-container .tooltip-content img {
            width: 12px;
            height: 12px; }
        .payment-page .payment-container .sponsored-container .tooltip-arrow {
          position: absolute;
          top: 100%;
          left: 6rem;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #4A4A4A; }
        .payment-page .payment-container .sponsored-container .close-icon {
          margin-left: 8px;
          cursor: pointer;
          color: #FFFFFF;
          font-size: 16px; }
        .payment-page .payment-container .sponsored-container a {
          text-decoration: underline;
          color: #00A664;
          font-weight: bold; }
        .payment-page .payment-container .sponsored-container a:hover {
          color: #00A664; }
      .payment-page .payment-container .payment-info {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        padding-top: 24px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "SF Pro regular";
  src: local("SF Pro regular"), url("./fonts/SFProText-Regular.ttf") format("opentype"); }

@media only screen and (min-width: 701px) {
  .card-loader-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .card-loader-container .card-loader {
      border: 8px solid #f3f3f3;
      border-radius: 50%;
      border-top: 8px solid #00A664;
      width: 100px;
      height: 100px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      margin: 30px; }
      .card-loader-container .card-loader .hide {
        position: absolute;
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important; }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes spin {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  .card-popup {
    display: none;
    position: fixed;
    z-index: 20;
    left: 0;
    right: 0;
    top: 15%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #D5D5D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 95%;
    width: 800px; }
    .card-popup:after {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -2; }
    .card-popup:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #FFF;
      z-index: -1;
      border-radius: 8px; }
    .card-popup.show {
      display: block; }
    .card-popup.hide {
      display: none; }
    .card-popup .card-operations-container {
      max-height: calc(100vh - 210px);
      overflow-y: auto; }
  .max-cards-message {
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
    background-color: #EDF4FC; }
    .max-cards-message .warning-icon {
      margin-right: 20px; }
    .max-cards-message .warning-text {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #1E3A5A; }
  .card-operations-container {
    padding: 30px 25px 30px 25px; }
    .card-operations-container .max-cards-message {
      height: fit-content;
      padding: 15px;
      border-radius: 8px; }
    .card-operations-container .delete-confirmation-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 350px;
      padding-top: 50px; }
      .card-operations-container .delete-confirmation-container .heading {
        margin-top: 40px !important; }
      .card-operations-container .delete-confirmation-container .foot-notes {
        margin-top: 15px !important; }
      .card-operations-container .delete-confirmation-container .buttons {
        margin-top: 50px; }
        .card-operations-container .delete-confirmation-container .buttons .confirmation-cancel-button {
          height: 40px;
          width: 160px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #FFFFFF;
          border-radius: 5px;
          border: 2px solid #00A664;
          color: #00A664;
          border-radius: 5px;
          background-color: #FFFFFF !important; }
          .card-operations-container .delete-confirmation-container .buttons .confirmation-cancel-button:disabled {
            background-color: #838383;
            cursor: not-allowed;
            border: none; }
          .card-operations-container .delete-confirmation-container .buttons .confirmation-cancel-button:enabled {
            background-color: #00A664; }
        .card-operations-container .delete-confirmation-container .buttons .confirmation-delete-button {
          height: 40px;
          width: 160px;
          margin-left: 25px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #FFFFFF;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold' !important; }
          .card-operations-container .delete-confirmation-container .buttons .confirmation-delete-button:disabled {
            background-color: #838383;
            cursor: not-allowed;
            border: none; }
          .card-operations-container .delete-confirmation-container .buttons .confirmation-delete-button:enabled {
            background-color: #00A664; }
    .card-operations-container .button-container {
      display: flex;
      justify-content: center; }
    .card-operations-container .select-box {
      width: 75% !important;
      display: block !important; }
      .card-operations-container .select-box.selected {
        border: 3px solid #0F8952 !important;
        background-color: #D4EDE1; }
        .card-operations-container .select-box.selected .details {
          color: #0F8952 !important; }
      .card-operations-container .select-box .tick-icon-container {
        position: relative; }
        .card-operations-container .select-box .tick-icon-container .tick-icon {
          position: absolute;
          right: 10px;
          bottom: -10px;
          z-index: 1; }
    .card-operations-container .buttons {
      margin-top: 40px;
      margin-bottom: 50px; }
      .card-operations-container .buttons .delete {
        height: 40px;
        width: 160px;
        background-color: #FFFFFF !important;
        border: 2px solid #F96302;
        color: #F96302 !important;
        font-family: 'Proxima Nova Bold' !important;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px; }
        .card-operations-container .buttons .delete:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .card-operations-container .buttons .delete:enabled {
          background-color: #00A664; }
      .card-operations-container .buttons .save {
        height: 40px;
        width: 160px;
        margin-left: 25px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        font-family: 'Proxima Nova Bold' !important; }
        .card-operations-container .buttons .save:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .card-operations-container .buttons .save:enabled {
          background-color: #00A664; }
      .card-operations-container .buttons .select-save {
        height: 60px;
        width: 340px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px; }
        .card-operations-container .buttons .select-save:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .card-operations-container .buttons .select-save:enabled {
          background-color: #00A664; }
        .card-operations-container .buttons .select-save:focus {
          box-shadow: 0 0 10px 0 #4A90E2; }
    .card-operations-container .error-message {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-top: 10px;
      color: #F96302; }
    .card-operations-container .foot-notes {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      text-align: center;
      color: #838383; }
    .card-operations-container .header {
      display: flex;
      justify-content: space-between; }
      .card-operations-container .header .close {
        float: right;
        font-size: 20px;
        cursor: pointer; }
    .card-operations-container .card-container {
      margin-top: 25px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px 20px;
      grid-template-areas: "card date" "cardInfo ." "cvvError ."; }
      .card-operations-container .card-container .label {
        font-family: 'DINNextLTPro-Medium';
        font-style: normal;
        font-size: 13px;
        line-height: 25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .card-operations-container .card-container .field input {
        background: #FFFFFF;
        border: 2px solid #D5D5D5;
        height: 56px;
        color: #4A4A4A;
        padding: 15px; }
        .card-operations-container .card-container .field input ::placeholder {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.25px;
          color: #838383;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .card-operations-container .card-container .field input:disabled {
          background: #D5D5D5;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.25px;
          color: #838383;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .card-operations-container .card-container .field input.error {
          border-color: #F96302;
          box-shadow: none; }
      .card-operations-container .card-container .card-number {
        grid-area: card; }
        .card-operations-container .card-container .card-number input {
          width: 400px; }
      .card-operations-container .card-container .date {
        grid-area: date;
        display: flex;
        justify-content: flex-start;
        flex-direction: column; }
        .card-operations-container .card-container .date .fields {
          display: flex; }
          .card-operations-container .card-container .date .fields .flexibility-container {
            width: 100px; }
            .card-operations-container .card-container .date .fields .flexibility-container .flexibility-header.error {
              border-color: #F96302; }
            .card-operations-container .card-container .date .fields .flexibility-container .dropdown-list {
              max-height: 250px !important;
              width: 100px; }
              .card-operations-container .card-container .date .fields .flexibility-container .dropdown-list .list-item {
                padding: 0; }
            .card-operations-container .card-container .date .fields .flexibility-container .flexibility-label {
              height: 25px; }
            .card-operations-container .card-container .date .fields .flexibility-container:nth-child(2) {
              margin-left: 15px; }
      .card-operations-container .card-container .card-info {
        grid-area: cardInfo;
        display: flex;
        justify-content: space-between; }
        .card-operations-container .card-container .card-info input {
          width: 190px; }
          .card-operations-container .card-container .card-info input:focus {
            border-bottom: 3px solid #00A664; }
          .card-operations-container .card-container .card-info input.error {
            border: 2px solid #F96302 !important;
            box-shadow: none; }
        .card-operations-container .card-container .card-info .cvv {
          width: 190px; }
          .card-operations-container .card-container .card-info .cvv .label {
            display: flex;
            justify-content: space-between; }
          .card-operations-container .card-container .card-info .cvv input:disabled {
            background-color: #EDEDED; }
          .card-operations-container .card-container .card-info .cvv .info .message {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            padding-right: 30px;
            color: #FFFFFF;
            background: #4A4A4A;
            border-color: #4A4A4A;
            left: -12rem;
            top: -7rem;
            width: 260px;
            border-radius: 8px; }
            .card-operations-container .card-container .card-info .cvv .info .message:after {
              transform: rotate(180deg);
              border-bottom: 0.5625rem solid #4A4A4A;
              left: 73%;
              top: 100%; }
            .card-operations-container .card-container .card-info .cvv .info .message:before {
              transform: rotate(180deg);
              border-bottom: 0.5625rem solid #4A4A4A;
              left: 73%;
              top: 100%; }
            .card-operations-container .card-container .card-info .cvv .info .message .closeIcon {
              position: absolute;
              right: 10px;
              top: 10px; }
      .card-operations-container .card-container .cvv-error {
        grid-area: 'cvvError';
        height: 45px;
        grid-row: 3/3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: -10px; }
    .card-operations-container .federal-container {
      background: #EDEDED;
      border: 1px solid #D5D5D5;
      border-radius: 4px;
      max-width: 650px;
      padding: 0px 50px 15px 15px;
      margin-top: 20px; }
      .card-operations-container .federal-container.zip-cvv-invalid {
        margin-top: 40px; }
      .card-operations-container .federal-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #4A4A4A; }
      .card-operations-container .federal-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
      .card-operations-container .federal-container .card-input-container {
        margin-top: 15px;
        display: flex;
        flex-flow: column; }
        .card-operations-container .federal-container .card-input-container .label {
          font-family: 'DINNextLTPro-Medium';
          font-style: normal;
          font-size: 13px;
          line-height: 25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .card-operations-container .federal-container .card-input-container input {
          max-width: 160px;
          padding-left: 30px !important;
          background: url("./images/Dollar.png") no-repeat, #FFFFFF;
          background-position-x: 5px;
          background-position-y: 15px; }
      .card-operations-container .federal-container .tooltips-container {
        margin-top: 15px;
        margin-left: -8px; }
    .card-operations-container .legal-copy {
      display: flex;
      flex-direction: row;
      margin-top: 16px; }
      .card-operations-container .legal-copy.federal {
        margin-top: 30px; }
      .card-operations-container .legal-copy .lock-image {
        display: flex;
        align-items: center; }
        .card-operations-container .legal-copy .lock-image img {
          width: 24px;
          height: 24px;
          margin-bottom: 5px; }
      .card-operations-container .legal-copy .legal-copy-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-size: 14px;
        margin-left: 6px; }
    .card-operations-container .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased; }
    .card-operations-container .comment {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-top: 15px;
      margin-bottom: 15px;
      color: #636363; }
    .card-operations-container .sub-heading {
      font-family: 'Proxima Nova Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-top: 25px;
      color: #4A4A4A; }
    .card-operations-container .continue-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #FFFFFF;
      border-radius: 5px;
      height: 40px;
      width: 160px;
      margin: 50px 0px 40px; }
      .card-operations-container .continue-button:disabled {
        background-color: #838383;
        cursor: not-allowed;
        border: none; }
      .card-operations-container .continue-button:enabled {
        background-color: #00A664; }
  .card-checkbox {
    display: flex;
    margin-top: 10px;
    grid-row-start: 4;
    grid-row-end: 5; }
    .card-checkbox .checkbox {
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
      background-color: #EDEDED;
      border: 2px solid #838383;
      border-radius: 2px;
      padding: 0px; }
      .card-checkbox .checkbox.selected {
        background: #00A664;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px; }
        .card-checkbox .checkbox.selected img {
          width: 13px; }
      .card-checkbox .checkbox.disabled {
        background: #D4EDE1 !important;
        pointer-events: none;
        border: none; }
    .card-checkbox .text {
      margin-left: 10px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A; } }

@media only screen and (max-width: 700px) {
  .card-loader-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .card-loader-container .card-loader {
      border: 8px solid #f3f3f3;
      border-radius: 50%;
      border-top: 8px solid #00A664;
      width: 80px;
      height: 80px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      margin: 30px; }
      .card-loader-container .card-loader .hide {
        position: absolute;
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important; }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes spin {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  .card-popup {
    display: none;
    position: fixed;
    z-index: 20;
    left: 10px;
    right: 10px;
    top: 10%;
    margin-left: auto;
    margin-right: auto; }
    .card-popup:has(.federal-container) {
      bottom: 0;
      overflow-y: scroll; }
    .card-popup:after {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -2; }
    .card-popup:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #FFF;
      z-index: -1;
      border-radius: 5px; }
    .card-popup.show {
      display: block; }
    .card-popup.hide {
      display: none; }
  .max-cards-message {
    width: inherit;
    display: flex;
    justify-content: center;
    height: 90px;
    background-color: #EDF4FC;
    padding: 25px; }
    .max-cards-message .warning-icon {
      margin-right: 15px; }
      .max-cards-message .warning-icon img {
        width: 20px;
        height: 20px; }
    .max-cards-message .warning-text {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #1E3A5A;
      display: flex;
      align-items: center; }
  .card-operations-container {
    padding: 30px 25px 30px 25px;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 8px; }
    .card-operations-container .max-cards-message {
      height: fit-content;
      padding: 15px;
      border-radius: 8px; }
    .card-operations-container .delete-confirmation-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px 20px 0px 20px !important; }
      .card-operations-container .delete-confirmation-container .heading {
        margin-top: 20px !important;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px; }
      .card-operations-container .delete-confirmation-container .foot-notes {
        margin-top: 15px !important;
        text-align: center;
        font-size: 14px;
        line-height: 24px; }
      .card-operations-container .delete-confirmation-container .buttons {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        width: 100%; }
        .card-operations-container .delete-confirmation-container .buttons .confirmation-cancel-button {
          height: 40px;
          width: 100%;
          max-width: 350px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #FFFFFF;
          border-radius: 5px;
          border: 2px solid #00A664;
          color: #00A664;
          border-radius: 5px;
          background-color: #FFFFFF !important; }
          .card-operations-container .delete-confirmation-container .buttons .confirmation-cancel-button:disabled {
            background-color: #838383;
            cursor: not-allowed;
            border: none; }
          .card-operations-container .delete-confirmation-container .buttons .confirmation-cancel-button:enabled {
            background-color: #00A664; }
        .card-operations-container .delete-confirmation-container .buttons .confirmation-delete-button {
          height: 40px;
          width: 100%;
          max-width: 350px;
          margin-top: 15px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #FFFFFF;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold' !important; }
          .card-operations-container .delete-confirmation-container .buttons .confirmation-delete-button:disabled {
            background-color: #838383;
            cursor: not-allowed;
            border: none; }
          .card-operations-container .delete-confirmation-container .buttons .confirmation-delete-button:enabled {
            background-color: #00A664; }
    .card-operations-container .button-container {
      display: flex;
      justify-content: center; }
    .card-operations-container .select-box {
      width: 95% !important;
      display: block !important;
      height: 90px;
      margin-top: 10px; }
      .card-operations-container .select-box.selected {
        border: 3px solid #0F8952 !important;
        background-color: #D4EDE1; }
        .card-operations-container .select-box.selected .details {
          color: #0F8952 !important; }
      .card-operations-container .select-box .tick-icon-container {
        position: relative; }
        .card-operations-container .select-box .tick-icon-container .tick-icon {
          position: absolute;
          right: 10px;
          bottom: -10px;
          z-index: 1; }
    .card-operations-container .buttons {
      margin-top: 30px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .card-operations-container .buttons .delete {
        font-family: 'Proxima Nova Bold' !important;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        height: 40px;
        width: 160px;
        color: #F96302 !important;
        box-shadow: none;
        text-transform: uppercase;
        margin-bottom: 50px; }
      .card-operations-container .buttons .save {
        height: 60px;
        width: 95%;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        font-family: 'Proxima Nova Bold' !important; }
        .card-operations-container .buttons .save:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .card-operations-container .buttons .save:enabled {
          background-color: #00A664; }
      .card-operations-container .buttons .select-save {
        height: 60px;
        width: 340px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px; }
        .card-operations-container .buttons .select-save:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .card-operations-container .buttons .select-save:enabled {
          background-color: #00A664; }
        .card-operations-container .buttons .select-save:focus {
          box-shadow: 0 0 10px 0 #4A90E2; }
    .card-operations-container .header {
      display: flex;
      justify-content: space-between; }
      .card-operations-container .header .close {
        float: right;
        font-size: 15px;
        cursor: pointer;
        height: 20px;
        width: 20px; }
    .card-operations-container .error-message {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #F96302;
      margin-top: 10px; }
    .card-operations-container .card-container {
      margin-top: 15px;
      display: flex;
      flex-flow: column; }
      .card-operations-container .card-container .label {
        font-family: 'DINNextLTPro-Medium';
        font-style: normal;
        font-size: 13px;
        line-height: 25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .card-operations-container .card-container .field input {
        background: #FFFFFF;
        border: 2px solid #D5D5D5;
        height: 56px;
        color: #4A4A4A;
        padding: 15px; }
        .card-operations-container .card-container .field input ::placeholder {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.25px;
          color: #838383;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .card-operations-container .card-container .field input:disabled {
          background: #D5D5D5;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.25px;
          color: #838383;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .card-operations-container .card-container .field input.error {
          border-color: #F96302;
          box-shadow: none; }
      .card-operations-container .card-container .date {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 25px; }
        .card-operations-container .card-container .date .fields {
          display: flex; }
          .card-operations-container .card-container .date .fields .flexibility-container {
            width: 160px !important; }
            .card-operations-container .card-container .date .fields .flexibility-container .flexibility-header.error {
              border-color: #F96302; }
            .card-operations-container .card-container .date .fields .flexibility-container .dropdown-list {
              max-height: 250px !important;
              width: 150px; }
              .card-operations-container .card-container .date .fields .flexibility-container .dropdown-list .list-item {
                padding: 0;
                height: 40px; }
            .card-operations-container .card-container .date .fields .flexibility-container .flexibility-label {
              height: 25px; }
              .card-operations-container .card-container .date .fields .flexibility-container .flexibility-label label {
                font-size: 14px; }
            .card-operations-container .card-container .date .fields .flexibility-container:nth-child(2) {
              margin-left: 15px; }
      .card-operations-container .card-container .card-info {
        margin-top: 25px;
        display: flex;
        justify-content: space-between; }
        .card-operations-container .card-container .card-info .field {
          width: inherit; }
        .card-operations-container .card-container .card-info .cvv,
        .card-operations-container .card-container .card-info .zip {
          max-width: 48%; }
          .card-operations-container .card-container .card-info .cvv input,
          .card-operations-container .card-container .card-info .zip input {
            max-width: inherit;
            width: calc(100%); }
            .card-operations-container .card-container .card-info .cvv input:focus,
            .card-operations-container .card-container .card-info .zip input:focus {
              border-bottom: 3px solid #00A664; }
            .card-operations-container .card-container .card-info .cvv input.error,
            .card-operations-container .card-container .card-info .zip input.error {
              border: 2px solid #F96302 !important;
              box-shadow: none; }
            .card-operations-container .card-container .card-info .cvv input:disabled,
            .card-operations-container .card-container .card-info .zip input:disabled {
              background-color: #EDEDED; }
      .card-operations-container .card-container .cvv-error {
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .card-operations-container .card-container .cvv .label {
        display: flex;
        justify-content: space-between; }
      .card-operations-container .card-container .cvv .info .message {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding-right: 30px;
        color: #FFFFFF;
        background: #4A4A4A;
        border-color: #4A4A4A;
        left: -10rem;
        top: -7rem;
        width: 260px;
        border-radius: 8px; }
        .card-operations-container .card-container .cvv .info .message:after {
          transform: rotate(180deg);
          border-bottom: 0.5625rem solid #4A4A4A;
          left: 60%;
          top: 100%; }
        .card-operations-container .card-container .cvv .info .message:before {
          transform: rotate(180deg);
          border-bottom: 0.5625rem solid #4A4A4A;
          left: 60%;
          top: 100%; }
        .card-operations-container .card-container .cvv .info .message .closeIcon {
          position: absolute;
          right: 10px;
          top: 10px; }
    .card-operations-container .federal-container {
      background: #EDEDED;
      border: 1px solid #D5D5D5;
      border-radius: 4px;
      padding: 0px 20px 15px 15px;
      margin-top: 25px; }
      .card-operations-container .federal-container.zip-cvv-invalid {
        margin-top: 50px; }
      .card-operations-container .federal-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #4A4A4A;
        text-align: left; }
      .card-operations-container .federal-container .comment {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        text-align: left; }
      .card-operations-container .federal-container .card-input-container {
        margin-top: 15px;
        display: flex;
        flex-flow: column;
        padding: 8px; }
        .card-operations-container .federal-container .card-input-container .label {
          font-family: 'DINNextLTPro-Medium';
          font-style: normal;
          font-size: 13px;
          line-height: 25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .card-operations-container .federal-container .card-input-container .error-message {
          font-size: 14px !important; }
        .card-operations-container .federal-container .card-input-container input {
          max-width: 160px;
          padding-left: 30px !important;
          background: url("./images/Dollar.png") no-repeat, #FFFFFF;
          background-position-x: 5px;
          background-position-y: 15px; }
      .card-operations-container .federal-container .tooltips-container {
        margin-top: 15px;
        margin-left: -8px; }
    .card-operations-container .legal-copy {
      display: flex;
      flex-direction: row; }
      .card-operations-container .legal-copy.federal {
        margin-top: 30px; }
      .card-operations-container .legal-copy .lock-image {
        display: flex;
        align-items: center; }
        .card-operations-container .legal-copy .lock-image img {
          width: 24px;
          height: 24px;
          margin-bottom: 5px; }
      .card-operations-container .legal-copy .legal-copy-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-size: 14px;
        margin-left: 6px; }
    .card-operations-container .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      text-align: center;
      font-size: 22px; }
    .card-operations-container .comment {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-top: 15px;
      margin-bottom: 15px;
      color: #636363;
      text-align: center;
      font-size: 16px;
      line-height: 20px; }
    .card-operations-container .sub-heading {
      font-family: 'Proxima Nova Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-top: 25px;
      color: #4A4A4A; }
    .card-operations-container .foot-notes {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.078px;
      color: #838383; }
    .card-operations-container .continue-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #FFFFFF;
      border-radius: 5px;
      height: 40px;
      width: 160px;
      margin: 50px 0px 40px;
      width: 100%; }
      .card-operations-container .continue-button:disabled {
        background-color: #838383;
        cursor: not-allowed;
        border: none; }
      .card-operations-container .continue-button:enabled {
        background-color: #00A664; }
  .card-checkbox {
    display: flex;
    margin-top: 20px;
    grid-row-start: 7;
    grid-row-end: 8; }
    .card-checkbox .checkbox {
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
      background-color: #EDEDED;
      border: 2px solid #838383;
      border-radius: 2px;
      padding: 0px; }
      .card-checkbox .checkbox.selected {
        background: #00A664;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px; }
        .card-checkbox .checkbox.selected img {
          width: 13px; }
      .card-checkbox .checkbox.disabled {
        background: #D4EDE1 !important;
        pointer-events: none; }
    .card-checkbox .text {
      margin-left: 10px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.final-summary-container .final-summary-page .header {
  background: #00A664;
  border: 1px solid #D5D5D5;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px 8px 0px 0px; }
  .final-summary-container .final-summary-page .header .backArrow {
    width: 10%;
    padding: 22px; }
    .final-summary-container .final-summary-page .header .backArrow img {
      height: 35px;
      width: 35px; }
  .final-summary-container .final-summary-page .header .heading {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    width: 85%;
    padding: 16px; }

.final-summary-container .final-summary-page .blue-notes {
  background: #EDF4FC;
  box-shadow: 0px 1px 0px rgba(213, 213, 213, 0.2);
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  color: #1E3A5A;
  display: flex;
  width: 100%;
  padding: 16px; }
  .final-summary-container .final-summary-page .blue-notes .info-circle {
    height: 20px;
    margin-right: 18px; }

.final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper {
  position: relative; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper .info {
    position: absolute;
    top: 22px;
    left: 120px; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper .accordion .description {
    display: flex;
    align-items: center; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper .accordion .description .icon,
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion-wrapper .accordion .description .rate-desc {
      margin-left: 5px; }

.final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #0F8952; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate-badge img {
    height: 20px;
    margin-right: 5px; }

.final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion {
  width: 100%;
  transition: 0.4s;
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #838383;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 0 0; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion .description img {
    height: 16px;
    width: 16px;
    margin-right: 8px; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion .description .caret {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    transition: transform 0.3s ease; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion .description .rotate {
    transform: rotate(180deg); }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion .description .icon {
    margin-right: 0;
    margin-bottom: 2px; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion .price {
    display: flex; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion .total {
    display: flex; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .accordion .total .dollar {
      font-size: 13px !important;
      margin-top: -3px; }

.final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .panel {
  padding-left: 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .panel .tax-description,
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .panel .surcharges-description {
    display: flex;
    justify-content: space-between;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 18px;
    color: #838383;
    margin-bottom: 16px; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .panel .tax-description .amount,
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .panel .surcharges-description .amount {
      display: flex; }
      .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .panel .tax-description .amount .dollar,
      .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .panel .surcharges-description .amount .dollar {
        font-size: 13px !important;
        margin-top: 0; }

.final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .panel.active {
  max-height: 500px;
  margin-left: 15px; }

.final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container {
  padding: 24px 0 8px 0;
  border-top: 1px solid #D5D5D5;
  display: flex;
  justify-content: space-between; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container span {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #4A4A4A;
    align-self: center; }
  .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container {
    cursor: pointer;
    margin: 16px 0;
    text-align: right;
    position: relative;
    display: inline-block; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container img {
      height: 16px;
      width: 16px;
      margin-right: 4px; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container .sponsored-text {
      font-family: 'DINNextLTPro-Regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.25px;
      text-align: left;
      text-decoration: underline;
      text-underline-offset: 2px;
      color: #00A664; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container .tooltip {
      position: absolute;
      top: -100px;
      background-color: #4a4a4a;
      color: #FFFFFF;
      padding: 12px;
      border-radius: 8px;
      width: 320px;
      font-size: 14px;
      z-index: 1;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      right: 0; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container .tooltip-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left; }
      .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container .tooltip-content span {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        padding-right: 8px;
        color: #FFFFFF; }
      .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container .tooltip-content img {
        width: 12px;
        height: 12px; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container .tooltip-arrow {
      position: absolute;
      top: 100%;
      right: 3rem;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #4A4A4A; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container .close-icon {
      margin-left: 8px;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 16px; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container a {
      text-decoration: underline;
      color: #00A664;
      font-weight: bold; }
    .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .final-sponsored-container .sponsored-container a:hover {
      color: #00A664; }

@media only screen and (min-width: 769px) {
  .final-summary-container {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .final-summary-container .final-summary-page {
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      max-width: 900px;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .final-summary-container .final-summary-page .header .heading {
        font-size: 36px; }
      .final-summary-container .final-summary-page .vanpool-summary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 25px;
        margin: 15px 0px 30px 0px;
        padding: 45px 35px 40px 35px;
        width: 100%;
        overflow-x: auto; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details {
          height: fit-content;
          grid-row-start: 1;
          grid-row-end: 2; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details {
            display: flex;
            justify-content: space-between; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-class {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 30px;
              line-height: 40px;
              display: flex;
              align-items: center;
              letter-spacing: -0.5px;
              color: #4A4A4A; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-model {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group {
                display: flex; }
                .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .doller {
                  font-size: 18px;
                  line-height: 12px;
                  padding-right: 3px; }
                .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .price {
                  font-family: 'DINNextLTPro-Regular';
                  font-weight: 700;
                  font-size: 36px;
                  line-height: 36px; }
                .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .decimal {
                  font-family: 'DINNextLTPro-Regular';
                  font-weight: 100;
                  font-size: 18px;
                  line-height: 12px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .sub {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #4A4A4A;
                text-align: center;
                margin-top: -5px;
                margin-right: 5px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details {
            margin: 20px 0px 20px -15px !important; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays {
            margin-top: 10px;
            margin-left: 35px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul {
              display: flex;
              height: 25px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul li {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #838383;
                border-right: 1px solid #838383;
                padding: 0px 5px;
                margin-top: 3px;
                margin-bottom: 5px;
                text-transform: uppercase; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul :last-child {
                border: none; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity {
          width: 400;
          height: 280px;
          opacity: 0.4; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity img {
            height: 300px;
            margin: -45px 0px 0px 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity .image-not-found {
            height: 200px;
            margin: 45px 0px 0px 35px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image {
          width: 400px;
          height: 280px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image img {
            height: 300px;
            margin: -45px 0px 0px 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image .image-not-found {
            height: 200px;
            margin: 45px 0px 0px 35px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices {
          height: fit-content;
          display: flex;
          flex-direction: column;
          grid-row-start: 2;
          grid-row-end: 4;
          border-top: 1px solid #D5D5D5; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: -0.25px;
            color: #0F8952; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate-badge img {
              height: 20px;
              margin-right: 5px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .line {
            margin-top: 20px;
            height: 2px;
            border: 1px solid #EDEDED; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate {
            display: flex;
            justify-content: space-between;
            margin-top: 10px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 40px;
              display: flex;
              align-items: center;
              color: #838383; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .price {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 36px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #838383; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .price .dollar {
                font-size: 13px !important;
                margin-top: -5px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .fuel {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            display: flex;
            align-items: center;
            color: #838383; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            color: #4A4A4A;
            margin-top: 10px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .grp {
              display: flex;
              flex-direction: column;
              align-items: center; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom p {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #000000; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom p span {
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total {
              display: flex;
              justify-content: space-between;
              font-weight: 600 !important;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              display: flex;
              color: #4A4A4A; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .frequency {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .label {
                margin-right: 20px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price {
                display: flex;
                flex-direction: row; }
                .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price .dollar {
                  font-size: 16px;
                  margin-top: -5px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan {
          display: flex;
          justify-content: center;
          grid-row-start: 2;
          grid-row-end: 3;
          flex-flow: column; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker {
            width: 100%;
            background: #EDEDED;
            padding: 10px 15px;
            border: 1px solid #D5D5D5;
            border-radius: 4px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .plan-heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #0F8952; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container .sub {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: capitalize;
                color: #4A4A4A;
                margin-top: 15px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container .date {
                font-family: 'Proxima Nova';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #4A4A4A;
                margin-top: 5px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .van-start-details {
              padding-top: 16px;
              padding-bottom: 8px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .van-start-details .content {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .line {
          height: 2px;
          border: 1px solid #EDEDED; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today {
          margin-top: 15px;
          margin-bottom: 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .content {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .sub-heading {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .sub-heading .sub {
              font-family: 'DINNextLTPro-Bold';
              font-style: normal;
              font-weight: 1000;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #4A4A4A; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group {
            display: flex;
            margin-left: 15px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .doller {
              font-size: 16px;
              line-height: 24px;
              padding-right: 3px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .price {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .decimal {
              font-family: 'DINNextLTPro-Regular';
              font-weight: 100;
              font-size: 16px;
              line-height: 24px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details {
          border-top: 1px solid #D5D5D5; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .sub-heading {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            margin-top: 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .content {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .content .bold {
              font-family: 'Proxima Nova Bold'; }
        .final-summary-container .final-summary-page .vanpool-summary .card-list {
          border-top: 1px solid #D5D5D5; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list.fill-gap {
            margin-top: -100px; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list .sub-heading {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            margin-top: 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list .side {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
        .final-summary-container .final-summary-page .vanpool-summary .condition {
          display: flex;
          margin-top: 30px;
          grid-row-start: 4;
          grid-row-end: 5; }
          .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox {
            height: 20px;
            min-width: 20px;
            background-color: #EDEDED;
            border: 2px solid #838383;
            border-radius: 2px;
            padding: 0px; }
            .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox.selected {
              background: #00A664;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px; }
              .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox.selected img {
                width: 13px; }
          .final-summary-container .final-summary-page .vanpool-summary .condition .text {
            margin-left: 10px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A;
            font-size: 14px; }
            .final-summary-container .final-summary-page .vanpool-summary .condition .text .desktop {
              font-weight: 600; }
      .final-summary-container .final-summary-page .button-container {
        margin: 35px; }
        .final-summary-container .final-summary-page .button-container .continue-button {
          width: 150px;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .final-summary-container .final-summary-page .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .final-summary-container .final-summary-page .button-container .continue-button:enabled {
            background-color: #00A664; } }

@media only screen and (max-width: 768px) and (min-width: 701px) {
  .final-summary-container {
    padding-top: 100px;
    padding-bottom: 100px;
    width: 98%;
    background-color: #E5E5E5; }
    .final-summary-container .header .heading {
      font-size: 36px; }
    .final-summary-container .final-summary-page {
      max-width: 95%;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .final-summary-container .final-summary-page .vanpool-summary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin-top: 35px;
        padding: 30px 15px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details {
          height: fit-content;
          grid-row-start: 1;
          grid-row-end: 2; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details {
            display: flex;
            justify-content: space-between; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-class {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 30px;
              line-height: 40px;
              display: flex;
              align-items: center;
              letter-spacing: -0.5px;
              color: #4A4A4A; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-model {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group {
              display: flex;
              margin-left: 15px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .doller {
                font-size: 18px;
                line-height: 12px;
                padding-right: 3px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .price {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 700;
                font-size: 36px;
                line-height: 36px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .decimal {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 100;
                font-size: 18px;
                line-height: 12px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .sub {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              text-align: center;
              margin-top: -5px;
              margin-right: 5px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details {
            margin: 20px 0px 20px -15px !important; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details .route-icon .line {
              height: 65px !important; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details .destination-address {
              margin-top: 0px !important; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays {
            margin-top: 10px;
            margin-left: 30px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul {
              display: flex;
              height: 25px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul li {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #838383;
                border-right: 1px solid #838383;
                padding: 0px 5px;
                margin-top: 3px;
                margin-bottom: 5px;
                text-transform: uppercase; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul :last-child {
                border: none; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity {
          opacity: 0.4;
          display: flex; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity img {
            height: 250px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity .image-not-found {
            height: 200px;
            margin: 35px 0px 0px 30px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image img {
          height: 250px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image .image-not-found {
          height: 200px;
          margin: 35px 0px 0px 30px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices {
          height: fit-content;
          display: flex;
          flex-direction: column;
          grid-row-start: 2;
          grid-row-end: 4;
          border-top: 1px solid #D5D5D5; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate-badge {
            font-size: 18px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .line {
            margin-top: 20px;
            height: 2px;
            border: 1px solid #EDEDED; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate {
            display: flex;
            justify-content: space-between;
            margin-top: 10px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 40px;
              display: flex;
              align-items: center;
              color: #838383; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .price {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 36px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #838383; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .price .dollar {
                font-size: 13px !important;
                margin-top: -5px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .fuel {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 40px;
            display: flex;
            align-items: center;
            color: #838383; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            color: #4A4A4A;
            margin-top: 20px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .grp {
              display: flex;
              flex-direction: column;
              align-items: center; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom p {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #000000; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom p span {
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total {
              display: flex;
              justify-content: space-between;
              font-weight: 600 !important;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              display: flex;
              color: #4A4A4A; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .frequency {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .label {
                margin-right: 20px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price {
                display: flex;
                flex-direction: row; }
                .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price .dollar {
                  font-size: 16px;
                  margin-top: -5px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan {
          display: flex;
          justify-content: center;
          grid-row-start: 2;
          grid-row-end: 3; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker {
            width: 100%;
            background: #EDEDED;
            padding: 10px 15px;
            border: 1px solid #D5D5D5;
            border-radius: 4px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .plan-heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #0F8952; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container .sub {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: capitalize;
                color: #4A4A4A;
                margin-top: 15px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container .date {
                font-family: 'Proxima Nova';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #4A4A4A;
                margin-top: 5px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .van-start-details {
              padding-top: 16px;
              padding-bottom: 8px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .van-start-details .content {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .line {
          height: 2px;
          border: 1px solid #EDEDED; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today {
          margin-bottom: 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .content {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .sub-heading {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .sub-heading .sub {
              font-family: 'DINNextLTPro-Bold';
              font-style: normal;
              font-weight: 1000;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #4A4A4A; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group {
            display: flex;
            margin-left: 15px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .doller {
              font-size: 16px;
              line-height: 24px;
              padding-right: 3px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .price {
              font-family: 'DINNextLTPro-Regular';
              font-weight: 500;
              font-size: 24px;
              line-height: 36px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .decimal {
              font-family: 'DINNextLTPro-Regular';
              font-weight: 100;
              font-size: 16px;
              line-height: 24px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details {
          border-top: 1px solid #D5D5D5; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .sub-heading {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            margin-top: 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .content {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .content .bold {
              font-family: 'Proxima Nova Bold'; }
        .final-summary-container .final-summary-page .vanpool-summary .card-list {
          border-top: 1px solid #D5D5D5; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list.fill-gap {
            margin-top: -100px; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list .line {
            margin-top: 10px;
            height: 2px;
            border: 1px solid #EDEDED; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list .sub-heading {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            margin-top: 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list .side {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
        .final-summary-container .final-summary-page .vanpool-summary .condition {
          display: flex;
          margin-top: 10px;
          grid-row-start: 4;
          grid-row-end: 5; }
          .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox {
            height: 20px;
            min-width: 20px;
            background-color: #EDEDED;
            border: 2px solid #838383;
            border-radius: 2px;
            padding: 0px; }
            .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox.selected {
              background: #00A664;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px; }
              .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox.selected img {
                width: 13px; }
          .final-summary-container .final-summary-page .vanpool-summary .condition .text {
            margin-left: 10px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
      .final-summary-container .final-summary-page .button-container {
        margin: 35px 10px; }
        .final-summary-container .final-summary-page .button-container .continue-button {
          width: 150px;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .final-summary-container .final-summary-page .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .final-summary-container .final-summary-page .button-container .continue-button:enabled {
            background-color: #00A664; } }

@media only screen and (max-width: 700px) {
  .final-summary-container {
    padding-top: 5px;
    padding-bottom: 100px;
    width: 100%;
    background-color: #E5E5E5; }
    .final-summary-container .header {
      max-height: 65px; }
      .final-summary-container .header .heading {
        font-size: 24px; }
    .final-summary-container .final-summary-page {
      max-width: 98%;
      margin: 80px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .final-summary-container .final-summary-page .vanpool-summary {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        margin-top: 35px;
        padding: 20px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details {
          height: fit-content;
          grid-row: 2; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details {
            display: flex;
            justify-content: space-between;
            width: auto; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-class {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 30px;
              line-height: 40px;
              display: flex;
              align-items: center;
              letter-spacing: -0.5px;
              color: #4A4A4A; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-class-details .van-model {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group {
              display: flex;
              margin-left: 15px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .doller {
                font-size: 18px;
                line-height: 12px;
                padding-right: 3px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .price {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 700;
                font-size: 36px;
                line-height: 36px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .price-group .decimal {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 100;
                font-size: 18px;
                line-height: 12px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-details .van-price-details .sub {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              text-align: center;
              margin-top: -5px;
              margin-right: 5px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .van-route-details {
            margin: 20px 0px 20px -15px !important; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays {
            margin-top: 10px;
            margin-left: 30px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul {
              display: flex;
              height: 25px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul li {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #838383;
                border-right: 1px solid #838383;
                padding: 0px 5px;
                margin-top: 3px;
                margin-bottom: 5px;
                text-transform: uppercase; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-details .vanpool-Details .rideDays ul :last-child {
                border: none; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image {
          grid-row: 1;
          margin-bottom: 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity {
            text-align: center;
            opacity: 0.4; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity img {
              height: 200px;
              margin: -30px 0px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image-opacity .image-not-found {
              height: 150px;
              margin: 0px 0px 15px 0px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image {
            text-align: center; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image img {
              height: 200px;
              margin: -30px 0px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-image .van-Image .car-image .image-not-found {
              height: 150px;
              margin: 0px 0px 15px 0px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices {
          height: fit-content;
          display: flex;
          flex-direction: column;
          border-top: 1px solid #D5D5D5;
          padding-top: 10px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate-badge {
            font-size: 14px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .line {
            height: 2px;
            border: 1px solid #EDEDED; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate {
            display: flex;
            justify-content: space-between; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 40px;
              display: flex;
              align-items: center;
              color: #838383; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .price {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 36px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #838383; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .rate .price .dollar {
                font-size: 13px !important;
                margin-top: -5px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .fuel {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 40px;
            display: flex;
            align-items: center;
            color: #838383; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            color: #4A4A4A;
            margin-top: 10px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .grp {
              display: flex;
              flex-direction: column;
              align-items: center; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom p {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #000000; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom p span {
                color: #0F8952;
                text-decoration: underline;
                cursor: pointer; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total {
              display: flex;
              justify-content: space-between;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600 !important;
              display: flex;
              color: #4A4A4A; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .label {
                margin-right: 20px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price {
                display: flex;
                flex-direction: row; }
                .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .price .dollar {
                  font-size: 16px;
                  margin-top: -5px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .grp {
                display: flex;
                flex-direction: column;
                align-items: center; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .bottom .total .frequency {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .container .tooltip {
            left: -11rem; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-prices .container .tooltip .tooltip-arrow {
              left: 14rem; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan {
          display: flex;
          justify-content: center;
          grid-row: 3;
          margin-bottom: 10px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker {
            width: 100%;
            background: #EDEDED;
            padding: 10px 15px;
            border: 1px solid #D5D5D5;
            border-radius: 4px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .plan-heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #0F8952; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container .sub {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: capitalize;
                color: #4A4A4A;
                margin-top: 15px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .date-container .date {
                font-family: 'Proxima Nova';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #4A4A4A;
                margin-top: 5px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .van-start-details {
              padding-top: 16px;
              padding-bottom: 8px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-summary-plan .van-datePicker .van-start-details .content {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px; }
        .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details {
          grid-row: 4; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .line {
            height: 2px;
            border: 1px solid #EDEDED; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today {
            margin-bottom: 20px;
            border-top: 1px solid #D5D5D5; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .content {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .sub-heading {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .sub-heading .sub {
                font-family: 'DINNextLTPro-Bold';
                font-style: normal;
                font-weight: 1000;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.25px;
                color: #4A4A4A; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group {
              display: flex;
              margin-left: 15px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .doller {
                font-size: 16px;
                line-height: 24px;
                padding-right: 3px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .price {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 500;
                font-size: 24px;
                line-height: 36px; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .pay-today .price-group .decimal {
                font-family: 'DINNextLTPro-Regular';
                font-weight: 100;
                font-size: 16px;
                line-height: 24px; }
          .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details {
            margin-bottom: 20px;
            border-top: 1px solid #D5D5D5; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .sub-heading {
              font-family: 'DINNextLTPro-Bold';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #4A4A4A;
              margin-top: 20px; }
            .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .content {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A; }
              .final-summary-container .final-summary-page .vanpool-summary .vanpool-payment-details .recurring-details .content .bold {
                font-family: 'Proxima Nova Bold'; }
        .final-summary-container .final-summary-page .vanpool-summary .card-list {
          margin-top: 10px;
          border-top: 1px solid #D5D5D5; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list .sub-heading {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            margin-top: 20px; }
          .final-summary-container .final-summary-page .vanpool-summary .card-list .side {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
        .final-summary-container .final-summary-page .vanpool-summary .condition {
          display: flex;
          margin-top: 30px;
          grid-row-start: 7;
          grid-row-end: 8; }
          .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox {
            height: 20px;
            min-width: 20px;
            background-color: #EDEDED;
            border: 2px solid #838383;
            border-radius: 2px;
            padding: 0px; }
            .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox.selected {
              background: #00A664;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px; }
              .final-summary-container .final-summary-page .vanpool-summary .condition .checkbox.selected img {
                width: 13px; }
          .final-summary-container .final-summary-page .vanpool-summary .condition .text {
            margin-left: 10px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
      .final-summary-container .final-summary-page .button-container {
        margin: 30px 10px; }
        .final-summary-container .final-summary-page .button-container .continue-button {
          width: 100%;
          height: 60px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .final-summary-container .final-summary-page .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .final-summary-container .final-summary-page .button-container .continue-button:enabled {
            background-color: #00A664; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DIN Alternate";
  src: local("DIN-Alternate-Regular"), url("./fonts/DIN-Alternate-Regular.otf") format("opentype"); }

@media only screen and (min-width: 769px) {
  .onboarding-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5;
    height: fit-content; }
    .onboarding-page .onboarding-container {
      max-width: 900px;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .onboarding-page .onboarding-container .slider-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-top: 0px; }
        .onboarding-page .onboarding-container .slider-container .sliding-panel {
          width: 100%; }
          .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .heading {
            margin: 0px;
            height: 80px;
            background: #00A664;
            border: 1px solid #D5D5D5;
            border-radius: 8px 8px 0px 0px;
            font-family: 'DINNextLTPro-Regular';
            font-weight: 500;
            font-size: 36px;
            line-height: 48px;
            letter-spacing: -0.5px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center; }
          .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container {
            padding: 40px 35px; }
            .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .sub-heading {
              font-family: 'DIN Alternate';
              font-style: normal;
              font-weight: 1000;
              font-size: 24px;
              line-height: 36px;
              color: #4A4A4A;
              margin-top: 20px; }
            .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .information {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              margin-top: 20px; }
              .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .information .sub-text {
                font-weight: 600; }
            .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .herobanner {
              display: flex;
              justify-content: center; }
              .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .herobanner img {
                width: 432px;
                height: 218px;
                top: 275px;
                left: 504px;
                gap: 0px;
                opacity: var(--Numbersub); }
        .onboarding-page .onboarding-container .slider-container .base-panel {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 25px 35px; }
          .onboarding-page .onboarding-container .slider-container .base-panel .navigation {
            font-family: 'Proxima Nova Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #0F8952;
            text-decoration: underline; }
            .onboarding-page .onboarding-container .slider-container .base-panel .navigation:disabled {
              color: #838383;
              cursor: not-allowed;
              text-decoration: none; }
            .onboarding-page .onboarding-container .slider-container .base-panel .navigation:focus {
              box-shadow: 0 0 5px 0 #4A90E2; }
          .onboarding-page .onboarding-container .slider-container .base-panel .slider-buttons {
            display: flex;
            cursor: pointer; }
            .onboarding-page .onboarding-container .slider-container .base-panel .slider-buttons :not(:nth-child(1)) {
              margin-left: 15px; }
  .image-container {
    height: 90px;
    width: 90px;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px; }
    .image-container div {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      text-transform: uppercase; } }

@media only screen and (max-width: 768px) and (min-width: 701px) {
  .onboarding-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .onboarding-page .onboarding-container {
      max-width: 700px;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .onboarding-page .onboarding-container .slider-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-top: 0px; }
        .onboarding-page .onboarding-container .slider-container .sliding-panel {
          width: 100%; }
          .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .heading {
            margin: 0px;
            height: 80px;
            background: #00A664;
            border: 1px solid #D5D5D5;
            border-radius: 8px 8px 0px 0px;
            font-family: 'DINNextLTPro-Regular';
            font-weight: 500;
            font-size: 36px;
            line-height: 48px;
            letter-spacing: -0.5px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center; }
          .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container {
            padding: 40px 25px 20px 25px; }
            .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .sub-heading {
              font-family: 'DIN Alternate';
              font-style: normal;
              font-weight: 1000;
              font-size: 24px;
              line-height: 36px;
              color: #4A4A4A;
              margin-top: 20px; }
            .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .information {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              margin-top: 20px; }
              .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .information .sub-text {
                font-weight: 600; }
            .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .herobanner {
              display: flex;
              justify-content: center; }
              .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .herobanner img {
                width: 432px;
                height: 218px;
                top: 275px;
                left: 504px;
                gap: 0px;
                opacity: var(--Numbersub); }
        .onboarding-page .onboarding-container .slider-container .base-panel {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 25px 35px; }
          .onboarding-page .onboarding-container .slider-container .base-panel .navigation {
            font-family: 'Proxima Nova Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #0F8952;
            text-decoration: underline; }
            .onboarding-page .onboarding-container .slider-container .base-panel .navigation:disabled {
              color: #838383;
              cursor: not-allowed;
              text-decoration: none; }
            .onboarding-page .onboarding-container .slider-container .base-panel .navigation:focus {
              box-shadow: 0 0 5px 0 #4A90E2; }
          .onboarding-page .onboarding-container .slider-container .base-panel .slider-buttons {
            display: flex;
            cursor: pointer; }
            .onboarding-page .onboarding-container .slider-container .base-panel .slider-buttons :not(:nth-child(1)) {
              margin-left: 15px; }
  .image-container {
    height: 90px;
    width: 90px;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px; }
    .image-container div {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      text-transform: uppercase; } }

@media only screen and (max-width: 700px) {
  .onboarding-page {
    padding-top: 70px;
    width: 100%;
    background-color: #E5E5E5;
    height: fit-content; }
    .onboarding-page .onboarding-container {
      height: 100%; }
      .onboarding-page .onboarding-container .slider-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 95%;
        padding-top: 0px; }
        .onboarding-page .onboarding-container .slider-container .sliding-panel {
          width: 100%; }
          .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .heading {
              margin: 0px;
              height: 80px;
              background: #00A664;
              font-family: 'DINNextLTPro-Regular';
              font-weight: 500;
              font-size: 30px;
              line-height: 40px;
              letter-spacing: -0.5px;
              color: #FFFFFF;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%; }
            .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container {
              background: #FFFFFF;
              width: 90%;
              display: flex;
              justify-content: center;
              border-radius: 8px 8px 8px 8px;
              margin-top: 25px;
              border-bottom: 2px solid #D5D5D5; }
              .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .sub-heading {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 1000;
                font-size: 24px;
                line-height: 36px;
                color: #4A4A4A;
                margin-top: 20px;
                margin-left: 15px; }
              .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .information {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #4A4A4A;
                margin: 15px; }
                .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .information .sub-text {
                  font-weight: 600; }
              .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .herobanner {
                display: flex;
                justify-content: center; }
                .onboarding-page .onboarding-container .slider-container .sliding-panel .screen-container .content-container .content .herobanner img {
                  width: 100%;
                  height: 200px;
                  top: 275px;
                  left: 504px;
                  gap: 0px;
                  opacity: var(--Numbersub); }
        .onboarding-page .onboarding-container .slider-container .base-panel {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 25px 35px;
          margin-top: 20px; }
          .onboarding-page .onboarding-container .slider-container .base-panel .navigation {
            font-family: 'Proxima Nova Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #0F8952;
            text-decoration: underline; }
            .onboarding-page .onboarding-container .slider-container .base-panel .navigation:disabled {
              color: #838383;
              cursor: not-allowed;
              text-decoration: none; }
            .onboarding-page .onboarding-container .slider-container .base-panel .navigation:focus {
              box-shadow: 0 0 5px 0 #4A90E2; }
          .onboarding-page .onboarding-container .slider-container .base-panel .slider-buttons {
            display: flex;
            cursor: pointer; }
            .onboarding-page .onboarding-container .slider-container .base-panel .slider-buttons :not(:nth-child(1)) {
              margin-left: 15px; }
  .image-container {
    height: 90px;
    width: 90px;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px; }
    .image-container div {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      text-transform: uppercase; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@media only screen and (min-width: 701px) {
  .second-screen .content-container.mobile {
    display: none; }
  .second-screen .desktop {
    display: block; }
  .second-screen .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 420px;
    justify-content: space-evenly;
    padding: 0px 20px 0px 30px !important; }
    .second-screen .content-container .content {
      width: 50%;
      order: 2; }
      .second-screen .content-container .content .row {
        border-bottom: 1px solid #D5D5D5;
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        padding-bottom: 15px;
        flex-wrap: wrap; }
        .second-screen .content-container .content .row .label {
          font-weight: 400; }
        .second-screen .content-container .content .row .value {
          font-weight: 600;
          flex-wrap: wrap;
          text-transform: capitalize; }
          .second-screen .content-container .content .row .value.predelivery-date {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400 !important;
            margin-top: 5px; }
        .second-screen .content-container .content .row:nth-child(3) {
          border: none; }
    .second-screen .content-container .vanpool-summary-image {
      order: 3;
      margin-left: 20px; }
      .second-screen .content-container .vanpool-summary-image .van-Image {
        display: flex;
        justify-content: center; }
        .second-screen .content-container .vanpool-summary-image .van-Image .car-image-opacity {
          opacity: 0.4; }
          .second-screen .content-container .vanpool-summary-image .van-Image .car-image-opacity img:not(.image-not-found) {
            max-width: 320px;
            height: auto; }
          .second-screen .content-container .vanpool-summary-image .van-Image .car-image-opacity .image-not-found {
            width: 320px; }
        .second-screen .content-container .vanpool-summary-image .van-Image .car-image img:not(.image-not-found) {
          max-width: 320px;
          height: auto; }
        .second-screen .content-container .vanpool-summary-image .van-Image .car-image .image-not-found {
          width: 320px; }
    .second-screen .content-container .title {
      order: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 50%;
      gap: 10px; }
      .second-screen .content-container .title .left,
      .second-screen .content-container .title .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
      .second-screen .content-container .title .right {
        align-items: flex-end !important;
        gap: 0px; }
      .second-screen .content-container .title .van-class {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        display: flex;
        align-items: center;
        letter-spacing: -0.5px;
        color: #4A4A4A; }
      .second-screen .content-container .title .van-model {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383; }
      .second-screen .content-container .title .label {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: right;
        font-weight: 600;
        color: #838383;
        line-height: 24px; }
      .second-screen .content-container .title .number {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: right;
        color: #4A4A4A; }
        .second-screen .content-container .title .number .coming-soon {
          font-weight: 500;
          text-align: right; }
  .third-screen .content-container {
    height: 450px;
    overflow-x: auto; }
    .third-screen .content-container .list {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .third-screen .content-container .list .more-vanpoolers-text {
        border-top: 1px solid #CCCCCC;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        width: 80%;
        text-align: center;
        padding: 40px; }
    .third-screen .content-container .vanpoolers {
      display: grid;
      grid-template-columns: repeat(4, 200px);
      height: 100%;
      grid-row-gap: 20px; }
      .third-screen .content-container .vanpoolers.less {
        height: fit-content; }
  .third-screen .vanpooler-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: flex-start;
    width: 180px;
    height: 200px;
    cursor: pointer; }
    .third-screen .vanpooler-container .image img {
      height: 90px;
      width: 90px;
      border-radius: 45px; }
    .third-screen .vanpooler-container .name {
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 600;
      color: #4A4A4A;
      margin-top: 10px;
      white-space: nowrap; }
    .third-screen .vanpooler-container .company-role {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      text-align: center;
      color: #838383;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .third-screen .vanpooler-container .company-role .company {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383;
        font-weight: 400; }
      .third-screen .vanpooler-container .company-role .type {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        font-weight: 400;
        text-transform: capitalize;
        font-weight: 400; }
        .third-screen .vanpooler-container .company-role .type span {
          margin-left: 5px;
          margin-right: 5px; }
  .fourth-screen .image-loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #00A664;
    width: 220px;
    height: 220px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite; }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes spin {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  .fourth-screen .content-container {
    width: 500px;
    margin: 0 auto; }
    .fourth-screen .content-container .content {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;
      margin-left: -100px; }
      .fourth-screen .content-container .content .section {
        margin-top: 24px; }
        .fourth-screen .content-container .content .section.profile-pic {
          align-items: end; }
        .fourth-screen .content-container .content .section.description {
          margin-left: 30px; }
        .fourth-screen .content-container .content .section img {
          height: 250px;
          width: 250px; }
        .fourth-screen .content-container .content .section .profile-image {
          border-radius: 50%; }
        .fourth-screen .content-container .content .section .select-heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A; }
        .fourth-screen .content-container .content .section .select-description {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          width: 360px;
          margin-top: 15px; }
        .fourth-screen .content-container .content .section .button {
          margin-top: 30px;
          width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start; }
          .fourth-screen .content-container .content .section .button button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            height: 40px;
            width: 220px; }
            .fourth-screen .content-container .content .section .button button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .fourth-screen .content-container .content .section .button button:enabled {
              background-color: #00A664; }
            .fourth-screen .content-container .content .section .button button.replace {
              width: 260px; }
        .fourth-screen .content-container .content .section .do-later-link {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          font-weight: 700;
          line-height: 20px !important;
          margin-top: 10px; } }

@media only screen and (max-width: 700px) {
  .second-screen .mobile {
    display: block; }
  .second-screen .desktop {
    display: none; }
  .second-screen .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px 15px !important; }
    .second-screen .content-container .content {
      order: 3; }
      .second-screen .content-container .content .row {
        border-bottom: 1px solid #D5D5D5;
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        padding-bottom: 15px;
        flex-wrap: wrap; }
        .second-screen .content-container .content .row .label {
          font-weight: 400; }
        .second-screen .content-container .content .row .value {
          font-weight: 600;
          text-transform: capitalize; }
          .second-screen .content-container .content .row .value.predelivery-date {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400 !important;
            margin-top: 5px; }
        .second-screen .content-container .content .row:nth-child(3) {
          border: none; }
    .second-screen .content-container .vanpool-summary-image {
      order: 2; }
      .second-screen .content-container .vanpool-summary-image .van-Image .car-image-opacity {
        opacity: 0.4; }
        .second-screen .content-container .vanpool-summary-image .van-Image .car-image-opacity img {
          max-width: 100%;
          height: auto; }
      .second-screen .content-container .vanpool-summary-image .van-Image .car-image img {
        max-width: 100%;
        height: auto; }
    .second-screen .content-container .title {
      order: 1;
      display: flex;
      justify-content: space-between; }
      .second-screen .content-container .title .left,
      .second-screen .content-container .title .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; }
      .second-screen .content-container .title .right {
        align-items: flex-end !important; }
      .second-screen .content-container .title .van-class {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: -0.5px;
        color: #4A4A4A; }
      .second-screen .content-container .title .van-model {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383; }
      .second-screen .content-container .title .label {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        text-align: right;
        color: #838383; }
      .second-screen .content-container .title .number {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: right;
        color: #4A4A4A; }
        .second-screen .content-container .title .number .coming-soon {
          font-weight: 500;
          text-align: right; }
  .third-screen {
    width: 100%; }
    .third-screen .heading {
      width: 100%; }
    .third-screen .content-container {
      padding-top: 40px;
      padding-bottom: 40px; }
      .third-screen .content-container .list {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .third-screen .content-container .list .more-vanpoolers-text {
          border-top: 1px solid #CCCCCC;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          width: 80%;
          text-align: center;
          padding: 40px; }
      .third-screen .content-container .vanpoolers {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 30px;
        width: 100%; }
    .third-screen .vanpooler-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      justify-content: flex-start;
      width: 180px;
      height: 200px;
      cursor: pointer; }
      .third-screen .vanpooler-container .image img {
        height: 90px;
        width: 90px;
        border-radius: 45px; }
      .third-screen .vanpooler-container .name {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 600;
        color: #4A4A4A;
        margin-top: 10px;
        white-space: nowrap; }
      .third-screen .vanpooler-container .company-role {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: center;
        color: #838383;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        .third-screen .vanpooler-container .company-role .company {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #838383;
          font-weight: 400; }
        .third-screen .vanpooler-container .company-role .type {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          font-weight: 400;
          text-transform: capitalize;
          font-weight: 400; }
          .third-screen .vanpooler-container .company-role .type span {
            margin-left: 5px;
            margin-right: 5px; }
  .fourth-screen {
    width: 100%; }
    .fourth-screen .image-loader {
      border: 8px solid #f3f3f3;
      border-radius: 50%;
      border-top: 8px solid #00A664;
      width: 180px;
      height: 180px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite; }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes spin {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
    .fourth-screen .content-container {
      width: 85%; }
      .fourth-screen .content-container .content {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 25px; }
        .fourth-screen .content-container .content .section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 40px; }
          .fourth-screen .content-container .content .section img {
            height: 180px;
            width: 180px; }
          .fourth-screen .content-container .content .section .profile-image {
            border-radius: 50%; }
          .fourth-screen .content-container .content .section .select-heading {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .fourth-screen .content-container .content .section .select-description {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-size: 15px !important;
            line-height: 20px;
            padding: 0px 26px;
            text-align: center; }
          .fourth-screen .content-container .content .section .button {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center; }
            .fourth-screen .content-container .content .section .button button {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              text-transform: uppercase;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #FFFFFF;
              border-radius: 5px;
              height: 40px;
              max-width: 300px;
              padding: 0px 20px; }
              .fourth-screen .content-container .content .section .button button:disabled {
                background-color: #838383;
                cursor: not-allowed;
                border: none; }
              .fourth-screen .content-container .content .section .button button:enabled {
                background-color: #00A664; }
          .fourth-screen .content-container .content .section .do-later-link {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            font-weight: 700;
            line-height: 20px !important;
            margin-top: 10px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@media (min-width: 1350px) {
  .payment-overview-page {
    padding-top: 180px !important; }
    .payment-overview-page .ending-date, .payment-overview-page .starting-date {
      margin: 20px 0px; } }

.payment-overview-page {
  background: #E5E5E5; }
  .payment-overview-page h2, .payment-overview-page h3 {
    margin-top: 0px !important; }
  .payment-overview-page .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    background: #E5E5E5;
    padding: 0px 35px 50px 35px; }
    .payment-overview-page .container .ending-date {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 500;
      color: #F96302; }
    .payment-overview-page .container .starting-date {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 500;
      color: #4A90E2; }
    .payment-overview-page .container .view-link {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #00A664;
      width: fit-content; }
    .payment-overview-page .container .payment-history-overview {
      height: fit-content;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      padding: 24px; }
      .payment-overview-page .container .payment-history-overview .header {
        display: flex;
        justify-content: space-between; }
        .payment-overview-page .container .payment-history-overview .header .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
      .payment-overview-page .container .payment-history-overview .content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        .payment-overview-page .container .payment-history-overview .content .no-records {
          margin: 60px 70px 80px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          text-align: center;
          color: #4A4A4A; }
    .payment-overview-page .container .invoiceCardsOverview {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      margin-bottom: 30px; }
      .payment-overview-page .container .invoiceCardsOverview .invoiceOverview {
        width: 50%;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-radius: 8px;
        padding: 25px; }
        .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoiceHeading {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoiceHeading .invoice {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.5px;
            color: #4A4A4A;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center; }
        .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .lastUpdate {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.25px;
          font-style: normal;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          align-items: center;
          color: #838383;
          margin-top: 3px; }
        .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details {
          padding-top: 15px;
          margin-top: 15px;
          border-top: 1px solid #D5D5D5; }
          .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details .currentInvoice {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px; }
            .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details .currentInvoice label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #4A4A4A; }
            .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details .currentInvoice .amount {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383;
              font-size: 22px;
              line-height: 22px; }
          .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details .invoice-link {
            float: right;
            margin-top: 10px;
            font-size: 14px; }
          .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details .invoiceDetails .billingDetails {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details .invoiceDetails .billingDetails.statement {
              margin-top: 15px; }
            .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details .invoiceDetails .billingDetails .heading {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              display: flex;
              align-items: center;
              color: #4A4A4A; }
            .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .invoice-summary-details .invoiceDetails .billingDetails .date {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 500;
              display: flex;
              align-items: center;
              text-align: right;
              text-transform: uppercase;
              color: #838383; }
        .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .no-records {
          margin: 20px 0px;
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          text-align: center;
          color: #4A4A4A; }
      .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview {
        width: 50%;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-radius: 8px;
        margin-left: 30px; }
        .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .PaymentCardsHeading {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 25px; }
          .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .PaymentCardsHeading .paymentCards {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.5px;
            color: #4A4A4A;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center; }
        .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .max-cards-message {
          width: 100% !important;
          padding: 16px;
          height: fit-content !important;
          margin-bottom: 20px; }
          .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .max-cards-message .tick-icon {
            height: 24px; }
          .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .max-cards-message .warning-text .callable .desktop {
            font-weight: 700; }
          .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .max-cards-message .warning-text .callable a {
            font-weight: 700;
            color: inherit; }
        .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container {
          padding: 0px 25px 20px; }
          .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .no-records {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            text-align: center;
            color: #4A4A4A;
            margin: 20px 0px 50px; }
          .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            flex-wrap: wrap; }
            .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main.backup {
              border-top: 1px solid #D5D5D5;
              padding-top: 20px; }
            .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .leftSection {
              display: flex;
              flex-direction: row; }
              .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .leftSection .CardImage {
                width: 50px;
                margin-right: 8px; }
                .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .leftSection .CardImage img {
                  width: 50px; }
              .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .leftSection .cardDetails .name {
                font-family: 'Proxima Nova';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #4A4A4A;
                text-transform: capitalize; }
              .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .leftSection .cardDetails .type {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 500;
                color: #838383; }
              .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .leftSection .cardDetails .details {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 400;
                color: #838383; }
              .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .leftSection .cardDetails .amount {
                font-family: 'DINNextLTPro-Medium';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                color: #2892E9; }
            .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .rightSection .primary {
              padding: 2px 12px;
              width: 75px;
              height: 24px;
              background: #4A90E2;
              border-radius: 4px;
              color: #FFFFFF;
              font-weight: 700;
              font-size: 14px;
              line-height: 18px; }
            .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .main .rightSection .backup {
              padding: 2px 12px;
              width: 75px;
              height: 24px;
              border-radius: 4px;
              color: #FFFFFF;
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;
              background: #838383; }

@media (max-width: 1350px) {
  .payment-overview-page .ending-date, .payment-overview-page .starting-date {
    margin-top: 20px; }
  .payment-overview-page .container {
    padding: 40px 15px 25px 15px; }
    .payment-overview-page .container .payment-history-overview .content .no-records {
      margin: 60px 0px 80px; }
    .payment-overview-page .container .invoiceCardsOverview {
      flex-direction: column; }
      .payment-overview-page .container .invoiceCardsOverview .invoiceOverview {
        width: auto; }
        .payment-overview-page .container .invoiceCardsOverview .invoiceOverview .no-records {
          margin: 30px 0px; }
      .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview {
        width: auto;
        margin-top: 25px;
        margin-left: 0; }
        .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .max-cards-message {
          width: 100% !important; }
        .payment-overview-page .container .invoiceCardsOverview .PaymentCardsOverview .card-list-container .no-records {
          margin: 20px 0px 50px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DIN Alternate";
  src: local("DIN-Alternate-Regular"), url("./fonts/DIN-Alternate-Regular.otf") format("opentype"); }

@media (min-width: 768px) {
  .mobileOnly {
    display: none; } }

.payment-history:has(> .invoice-detail) {
  width: 100%;
  max-width: 100%;
  margin: 0px;
  background: #E5E5E5;
  padding-bottom: 30px !important;
  height: fit-content; }

.payment-history {
  height: fit-content; }
  .payment-history .invoice-detail .no-records {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #4A4A4A;
    text-align: center;
    margin: 18% auto; }
  .payment-history .invoice-detail .pdf-loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #00A664;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto; }
  .payment-history .invoice-detail .pdf-error {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #838383; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .payment-history .invoice-detail .top-header {
    background: #00A664;
    border: 1px solid #D5D5D5;
    border-radius: unset;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px; }
    .payment-history .invoice-detail .top-header .backArrow {
      width: 10%;
      position: absolute; }
      .payment-history .invoice-detail .top-header .backArrow img {
        height: 35px;
        width: 35px; }
    .payment-history .invoice-detail .top-header .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      margin: auto; }
  .payment-history .invoice-detail .payment-invoice-page {
    height: fit-content; }
    .payment-history .invoice-detail .payment-invoice-page .container {
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      background-color: #FFFFFF;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      margin-bottom: 20px;
      min-height: 500px; }
      .payment-history .invoice-detail .payment-invoice-page .container .header {
        background: #00A664;
        border: 1px solid #D5D5D5;
        border-radius: 8px 8px 0px 0px;
        height: 80px;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .payment-history .invoice-detail .payment-invoice-page .container .header .backArrow {
          width: 10%;
          padding: 22px; }
          .payment-history .invoice-detail .payment-invoice-page .container .header .backArrow img {
            height: 35px;
            width: 35px; }
        .payment-history .invoice-detail .payment-invoice-page .container .header .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: block;
          align-items: center;
          text-align: center;
          color: #FFFFFF;
          width: 85%;
          padding: 16px; }
      .payment-history .invoice-detail .payment-invoice-page .container .download {
        display: flex;
        justify-content: flex-end; }
        .payment-history .invoice-detail .payment-invoice-page .container .download .download-button {
          border: 2px solid #00A664;
          border-radius: 5px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #00A664;
          padding: 8px 24px;
          margin: 24px 24px 0px; }
      .payment-history .invoice-detail .payment-invoice-page .container .main {
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;
        padding: 0px 24px; }
        .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection {
          width: 30%; }
          .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .year {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            margin-bottom: 8px; }
          .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .year-list {
            list-style-type: none; }
          .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .listContainer {
            margin-bottom: 26px; }
            .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .listContainer .invoice {
              background: #FFFFFF;
              border: 1px solid #9B9B9B;
              height: 60px;
              border-bottom: none;
              display: flex;
              flex-direction: row;
              cursor: pointer;
              justify-content: space-between; }
              .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .listContainer .invoice.active {
                background: #D4EDE1;
                border: 1px solid #D5D5D5;
                box-shadow: 0px 1px 0px #D5D5D5; }
              .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .listContainer .invoice .invoiceMonth {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.25px;
                display: flex;
                align-items: center;
                color: #4A4A4A;
                padding: 24px 16px; }
              .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .listContainer .invoice .caret {
                border: none;
                padding: 15px; }
                .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .listContainer .invoice .caret img {
                  height: 22px;
                  width: 22px;
                  border: none; }
            .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .listContainer :first-of-type {
              border-radius: 8px 8px 0px 0px; }
            .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection .listContainer :last-of-type {
              border-bottom: 1px solid #9B9B9B;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px; }
        .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection {
          width: 68%;
          margin-left: 28px; }
          .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection .invoice-month-details {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            color: #838383; }
          .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection .current-invoice-details {
            background: #FFFFFF;
            border: 1px solid #D5D5D5;
            box-shadow: 0px 1px 0px #D5D5D5;
            border-radius: 8px;
            height: 100px;
            margin-top: 10px; }
          .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection .invoice-view-container {
            border-radius: 8px;
            margin-top: 18px;
            height: 700px; }
            .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection .invoice-view-container iframe {
              width: 100%;
              height: 100%; }
            .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection .invoice-view-container .react-pdf__Document {
              display: flex;
              flex-direction: column;
              align-items: center;
              overflow: auto;
              height: inherit; }
            .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection .invoice-view-container .react-pdf__Page {
              width: 95%;
              box-shadow: 0 0 8px rgba(0, 0, 0, 0.25); }
              .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection .invoice-view-container .react-pdf__Page canvas {
                max-width: 100%;
                height: auto !important; }
            .payment-history .invoice-detail .payment-invoice-page .container .main .rightSection .invoice-view-container .react-pdf__message {
              padding: 20px;
              color: white; }
      .payment-history .invoice-detail .payment-invoice-page .container .invoice-details-mobileOnly {
        display: none; }

@media only screen and (max-width: 700px) {
  .payment-history {
    padding: 20px 0px 20px 0px !important; }
    .payment-history .invoice-detail {
      padding-top: 30px !important; }
      .payment-history .invoice-detail .payment-invoice-page .container {
        margin-top: 100px !important; }
        .payment-history .invoice-detail .payment-invoice-page .container .header .heading {
          font-family: 'DIN Alternate';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center; }
        .payment-history .invoice-detail .payment-invoice-page .container .download {
          display: none !important; }
        .payment-history .invoice-detail .payment-invoice-page .container .main {
          margin-top: 20px; }
          .payment-history .invoice-detail .payment-invoice-page .container .main .desktopOnly {
            display: none !important; }
          .payment-history .invoice-detail .payment-invoice-page .container .main .leftSection {
            width: 100% !important; }
        .payment-history .invoice-detail .payment-invoice-page .container .invoice-details-mobileOnly {
          display: block; }
      .payment-history .invoice-detail .header {
        background: #00A664;
        border: 1px solid #D5D5D5;
        border-radius: unset;
        height: 80px;
        display: flex;
        flex-direction: row;
        padding-left: 10px; }
        .payment-history .invoice-detail .header .backArrow {
          width: 10%;
          position: absolute;
          padding: 0px !important; }
          .payment-history .invoice-detail .header .backArrow img {
            height: 35px;
            width: 35px; }
        .payment-history .invoice-detail .header .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          align-items: center;
          text-align: center;
          color: #FFFFFF;
          margin: auto; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DIN Alternate";
  src: local("DIN-Alternate-Regular"), url("./fonts/DIN-Alternate-Regular.otf") format("opentype"); }

.visually-hidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.payment-history:has(> .payment-history-container, .subscription-detail) {
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding-top: 180px !important;
  background: #E5E5E5;
  padding-bottom: 30px !important;
  height: fit-content; }

.payment-history-container,
.subscription-detail {
  background-color: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 8px;
  max-width: 1024px;
  margin: 0px auto; }
  .payment-history-container .loader,
  .subscription-detail .loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #00A664;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: 0 auto; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .payment-history-container .mobile,
  .subscription-detail .mobile {
    display: none; }
  .payment-history-container .desktop,
  .subscription-detail .desktop {
    display: block; }
  .payment-history-container .top-header,
  .subscription-detail .top-header {
    background: #00A664;
    border: 1px solid #D5D5D5;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    border-radius: 8px 8px 0px 0px; }
    .payment-history-container .top-header .backArrow img,
    .subscription-detail .top-header .backArrow img {
      height: 35px;
      width: 35px; }
    .payment-history-container .top-header .heading,
    .subscription-detail .top-header .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 48px;
      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;
      color: #FFFFFF;
      margin: auto; }
  .payment-history-container > .content,
  .subscription-detail > .content {
    min-height: 500px;
    display: flex;
    flex-direction: column; }
    .payment-history-container > .content:has(> .loader),
    .subscription-detail > .content:has(> .loader) {
      justify-content: center; }
    .payment-history-container > .content .subscriptionPlan .planDetails .rate .price-group,
    .subscription-detail > .content .subscriptionPlan .planDetails .rate .price-group {
      color: #130C0E !important; }
    .payment-history-container > .content .subscriptionPlan .vl,
    .subscription-detail > .content .subscriptionPlan .vl {
      border: 1px solid #D5D5D5;
      margin: 25px 0px; }
    .payment-history-container > .content .subscriptionPlan .vanpoolDetails,
    .subscription-detail > .content .subscriptionPlan .vanpoolDetails {
      padding: 25px;
      display: flex;
      justify-content: space-between;
      flex-direction: column; }
      .payment-history-container > .content .subscriptionPlan .vanpoolDetails .vanpoolName .name,
      .subscription-detail > .content .subscriptionPlan .vanpoolDetails .vanpoolName .name {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        display: flex;
        align-items: center;
        letter-spacing: -0.5px;
        color: #4A4A4A; }
      .payment-history-container > .content .subscriptionPlan .vanpoolDetails .vanpoolName .type,
      .subscription-detail > .content .subscriptionPlan .vanpoolDetails .vanpoolName .type {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383;
        margin-top: -5px; }
      .payment-history-container > .content .subscriptionPlan .vanpoolDetails .vanpoolId,
      .subscription-detail > .content .subscriptionPlan .vanpoolDetails .vanpoolId {
        padding-top: 20px; }
        .payment-history-container > .content .subscriptionPlan .vanpoolDetails .vanpoolId .key,
        .subscription-detail > .content .subscriptionPlan .vanpoolDetails .vanpoolId .key {
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 600;
          color: #838383; }
        .payment-history-container > .content .subscriptionPlan .vanpoolDetails .vanpoolId .id,
        .subscription-detail > .content .subscriptionPlan .vanpoolDetails .vanpoolId .id {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A; }
  .payment-history-container .list-container,
  .subscription-detail .list-container {
    margin: 50px 10px 50px 25px;
    overflow: scroll;
    height: 80vh;
    padding-right: 15px;
    overflow-x: hidden; }
    .payment-history-container .list-container .side-heading,
    .subscription-detail .list-container .side-heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      letter-spacing: -0.5px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased; }
    .payment-history-container .list-container .transaction-list,
    .subscription-detail .list-container .transaction-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .payment-history-container .list-container .transaction-list .Collapsible,
      .subscription-detail .list-container .transaction-list .Collapsible {
        width: 100%; }

.no-records {
  margin: auto;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  color: #4A4A4A; }

@media only screen and (max-width: 700px) {
  .payment-history-container .top-header,
  .subscription-detail .top-header {
    height: 60px; }
    .payment-history-container .top-header .heading,
    .subscription-detail .top-header .heading {
      font-family: 'DIN Alternate';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #FFFFFF; }
  .payment-history-container .list-container,
  .subscription-detail .list-container {
    margin: 30px 10px 30px 15px;
    height: 70vh; }
  .payment-history-container .mobile,
  .subscription-detail .mobile {
    display: block; }
  .payment-history-container .desktop,
  .subscription-detail .desktop {
    display: none; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.subscriptionPlan {
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 8px;
  display: flex;
  flex-direction: row; }
  .subscriptionPlan .planDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    padding: 32px 22px 40px 22px;
    flex-wrap: wrap; }
    .subscriptionPlan .planDetails .heading {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .subscriptionPlan .planDetails .heading .sub-title {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        border-bottom: unset;
        padding: 0; }
      .subscriptionPlan .planDetails .heading .plan {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        color: #4A4A4A; }
      .subscriptionPlan .planDetails .heading .manage-button {
        border: 2px solid #00A664;
        border-radius: 5px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #00A664;
        height: 40px;
        width: 120px;
        align-content: center;
        display: inline-grid; }
    .subscriptionPlan .planDetails .right-container {
      display: flex;
      flex-direction: column; }
      .subscriptionPlan .planDetails .right-container .rate {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #4A4A4A; }
        .subscriptionPlan .planDetails .right-container .rate .price-group {
          display: flex; }
          .subscriptionPlan .planDetails .right-container .rate .price-group .doller {
            font-size: 24px;
            line-height: 16px;
            padding-right: 3px;
            font-weight: 400;
            padding-bottom: 30px; }
          .subscriptionPlan .planDetails .right-container .rate .price-group .price {
            font-family: 'DINNextLTPro-Regular';
            font-weight: 500;
            font-size: 48px;
            line-height: 25px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.25px; }
          .subscriptionPlan .planDetails .right-container .rate .price-group .decimal {
            font-family: 'DINNextLTPro-Regular';
            font-weight: 100;
            font-size: 20px;
            line-height: 12px; }
        .subscriptionPlan .planDetails .right-container .rate .perMonth {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          align-items: center;
          color: #4A4A4A;
          margin-left: -15px; }
      .subscriptionPlan .planDetails .right-container .sponsored-container {
        cursor: pointer;
        top: 16px;
        position: relative;
        display: inline-block;
        background-color: #FFFFFF;
        padding: 0;
        text-align: end; }
        .subscriptionPlan .planDetails .right-container .sponsored-container img {
          height: 16px;
          width: 16px;
          margin-right: 4px; }
        .subscriptionPlan .planDetails .right-container .sponsored-container .sponsored-text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.25px;
          text-align: left;
          text-decoration: underline;
          text-underline-offset: 2px;
          color: #00A664; }
        .subscriptionPlan .planDetails .right-container .sponsored-container .tooltip {
          position: absolute;
          top: 35px;
          right: 15px;
          background-color: #4a4a4a;
          color: #FFFFFF;
          padding: 12px;
          border-radius: 8px;
          width: 320px;
          font-size: 14px;
          z-index: 1;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .subscriptionPlan .planDetails .right-container .sponsored-container .tooltip-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start; }
          .subscriptionPlan .planDetails .right-container .sponsored-container .tooltip-content span {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            padding-right: 8px;
            text-align: left; }
          .subscriptionPlan .planDetails .right-container .sponsored-container .tooltip-content img {
            width: 12px;
            height: 12px; }
        .subscriptionPlan .planDetails .right-container .sponsored-container .tooltip-arrow {
          position: absolute;
          top: -10px;
          left: 16rem;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #4A4A4A; }
        .subscriptionPlan .planDetails .right-container .sponsored-container .close-icon {
          margin-left: 8px;
          cursor: pointer;
          color: #FFFFFF;
          font-size: 16px; }
        .subscriptionPlan .planDetails .right-container .sponsored-container a {
          text-decoration: underline;
          color: #00A664;
          font-weight: bold; }
        .subscriptionPlan .planDetails .right-container .sponsored-container a:hover {
          color: #00A664; }
  .subscriptionPlan .vl {
    border: 1px solid #D5D5D5;
    margin: 25px 0px; }
  .subscriptionPlan .vanpoolDetails {
    padding: 32px 22px 24px 22px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    .subscriptionPlan .vanpoolDetails .vanpoolName .name {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #4A4A4A; }
    .subscriptionPlan .vanpoolDetails .vanpoolName .type {
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 400;
      color: #838383;
      margin-top: -5px; }
    .subscriptionPlan .vanpoolDetails .vanpoolId {
      padding-top: 15px; }
      .subscriptionPlan .vanpoolDetails .vanpoolId .key {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 600;
        color: #838383; }
      .subscriptionPlan .vanpoolDetails .vanpoolId .id {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }

.subscription-detail .subscriptionPlan .price-group {
  color: #130C0E !important; }

.subscription-detail .subscription-detail-page {
  display: flex;
  flex-direction: column;
  min-height: 700px; }
  .subscription-detail .subscription-detail-page:has(> .loader) {
    justify-content: center; }
  .subscription-detail .subscription-detail-page .content {
    margin-bottom: 50px; }
    .subscription-detail .subscription-detail-page .content .top-content {
      padding: 25px; }
    .subscription-detail .subscription-detail-page .content .bottom-content {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .subscription-detail .subscription-detail-page .content .bottom-content .rates-accordion {
        border-top: none !important; }
      .subscription-detail .subscription-detail-page .content .bottom-content .sub-title {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        border-bottom: unset;
        padding: 0; }
      .subscription-detail .subscription-detail-page .content .bottom-content .card-charges {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383;
        margin-top: 5px; }
      .subscription-detail .subscription-detail-page .content .bottom-content .charges-container {
        margin: 0px 25px 0px 40px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion-wrapper {
          position: relative; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion-wrapper .info {
            position: absolute;
            top: 24px;
            left: 125px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion {
          width: 100%;
          transition: 0.4s;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 40px;
          display: flex;
          align-items: center;
          color: #838383;
          margin-bottom: 24px;
          display: flex;
          justify-content: space-between;
          padding: 16px 0 0 0; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion .description img {
            height: 16px;
            width: 16px;
            margin-right: 8px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion .description .caret {
            width: 16px;
            /* Adjust size as needed */
            height: 16px;
            margin-right: 8px;
            /* Adjust spacing as needed */
            transition: transform 0.3s ease;
            /* Add transition effect */ }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion .description .rotate {
            transform: rotate(180deg);
            /* Rotate the image by 180 degrees */ }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion .description .icon {
            margin-right: 5px;
            margin-bottom: 2px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion .price {
            display: flex; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion .total {
            display: flex;
            margin: 0 !important; }
            .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .accordion .total .dollar {
              font-size: 13px !important;
              margin-top: -3px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .panel {
          padding-left: 18px;
          background-color: white;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .panel .tax-description, .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .panel .surcharges-description {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 18px;
            color: #838383;
            margin-bottom: 16px;
            text-transform: capitalize; }
            .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .panel .tax-description .amount, .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .panel .surcharges-description .amount {
              display: flex; }
              .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .panel .tax-description .amount .dollar, .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .panel .surcharges-description .amount .dollar {
                font-size: 13px !important;
                margin-top: 0; }
        .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .charges .panel.active {
          max-height: 500px;
          margin-left: 15px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .month {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 600;
          color: #838383;
          margin-top: 5px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .row {
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #838383; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .row .val {
            display: flex; }
            .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .row .val .dollar {
              font-size: 12px;
              line-height: 15px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .row .info .message {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            padding-right: 30px;
            color: #FFFFFF;
            background: #4A4A4A;
            border-color: #4A4A4A;
            left: -1rem;
            top: -6rem;
            max-width: 260px;
            border-radius: 8px; }
            .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .row .info .message:after, .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .row .info .message:before {
              transform: rotate(180deg);
              border-bottom: 0.5625rem solid #4A4A4A;
              left: 5%;
              top: 100%; }
            .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .row .info .message .closeIcon {
              position: absolute;
              right: 10px;
              top: 10px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .sub-heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #4A4A4A;
          margin: 15px 0px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .sub-heading .key img {
            height: 18px;
            width: 18px;
            margin-right: 5px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .sub-heading .price {
            display: flex; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .sub-heading .dollar {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .breakup {
          margin-left: 12px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .breakup .row {
            border-bottom: 1px solid #EDEDED; }
            .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .breakup .row > * {
              margin: 12px 0px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .charges-container .breakup:nth-child(5) .row:last-child {
            border-bottom: unset !important; }
      .subscription-detail .subscription-detail-page .content .bottom-content .bottom {
        margin-left: 45px;
        display: flex;
        justify-content: space-between;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        display: flex;
        color: #4A4A4A;
        padding: 15px 0px 0px 15px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .bottom p {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #000000; }
          .subscription-detail .subscription-detail-page .content .bottom-content .bottom p span {
            color: #0F8952;
            text-decoration: underline;
            cursor: pointer; }
        .subscription-detail .subscription-detail-page .content .bottom-content .bottom .total, .subscription-detail .subscription-detail-page .content .bottom-content .bottom .label {
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 600 !important;
          color: #4A4A4A;
          margin-right: 25px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .bottom .total .grp, .subscription-detail .subscription-detail-page .content .bottom-content .bottom .label .grp {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-left: 10px; }
            .subscription-detail .subscription-detail-page .content .bottom-content .bottom .total .grp .frequency, .subscription-detail .subscription-detail-page .content .bottom-content .bottom .label .grp .frequency {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-size: 15px; }
          .subscription-detail .subscription-detail-page .content .bottom-content .bottom .total .total-price, .subscription-detail .subscription-detail-page .content .bottom-content .bottom .label .total-price {
            display: flex; }
            .subscription-detail .subscription-detail-page .content .bottom-content .bottom .total .total-price .dollar,
            .subscription-detail .subscription-detail-page .content .bottom-content .bottom .total .total-price .decimal, .subscription-detail .subscription-detail-page .content .bottom-content .bottom .label .total-price .dollar,
            .subscription-detail .subscription-detail-page .content .bottom-content .bottom .label .total-price .decimal {
              font-size: 15px;
              line-height: 20px; }
      .subscription-detail .subscription-detail-page .content .bottom-content .right-content-section {
        margin: 0px 30px 0px 30px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .right-content-section .card-charges {
          margin-bottom: 20px; }
        .subscription-detail .subscription-detail-page .content .bottom-content .right-content-section .onetime-charges-container {
          margin-top: 30px;
          padding-top: 20px;
          border-top: 1px solid #EDEDED; }

@media only screen and (max-width: 769px) and (min-width: 701px) {
  .subscriptionPlan .planDetails .right-container .container {
    text-align: left; }
    .subscriptionPlan .planDetails .right-container .container .tooltip {
      top: 135%;
      left: 4px; } }

@media only screen and (max-width: 700px) {
  .subscriptionPlan .price-group {
    color: #130C0E; }
  .bottom-content {
    display: grid;
    grid-template-columns: 1fr !important; }
    .bottom-content .left-content-section .total {
      display: flex;
      justify-content: space-between;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      padding: 15px 0px !important;
      margin: 0px 25px !important; }
    .bottom-content .left-content-section .charges-container {
      margin: 0px 25px !important; }
    .bottom-content .right-content-section {
      border-top: 1px solid #D5D5D5;
      padding-top: 25px !important;
      margin: 0px 25px !important; }
      .bottom-content .right-content-section .card-charges {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383; }
      .bottom-content .right-content-section .onetime-charges-container {
        border-top: none !important;
        margin-top: 5px !important; }
  .subscriptionPlan {
    flex-direction: column; }
    .subscriptionPlan .planDetails {
      width: auto;
      padding: 15px; }
      .subscriptionPlan .planDetails .heading .manage-button {
        font-family: 'Proxima Nova Bold';
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #00A664;
        border: unset;
        height: 40px;
        width: fit-content;
        padding: 0px;
        box-shadow: none;
        align-content: center;
        display: inline-grid; }
      .subscriptionPlan .planDetails .heading .title {
        font-size: 20px; }
      .subscriptionPlan .planDetails .heading .plan {
        font-size: 15px; }
      .subscriptionPlan .planDetails .right-container .rate {
        padding: 5px 0px; }
        .subscriptionPlan .planDetails .right-container .rate .price-group .price {
          font-size: 40px; }
      .subscriptionPlan .planDetails .right-container .sponsored-container {
        top: 5px; }
      .subscriptionPlan .planDetails .right-container .container .tooltip {
        top: 32px;
        left: -9rem; }
      .subscriptionPlan .planDetails .right-container .container .tooltip-arrow {
        left: 12rem; }
    .subscriptionPlan .vl {
      height: 0;
      margin: 0px 15px !important; }
    .subscriptionPlan .vanpoolDetails {
      padding: 15px 15px 20px 15px !important; }
      .subscriptionPlan .vanpoolDetails .vanpoolName .name {
        font-size: 24px; }
      .subscriptionPlan .vanpoolDetails .vanpoolName .type {
        font-size: 14px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.payment-history:has(> .payment-cards-page) {
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding-top: 180px !important;
  background: #E5E5E5;
  padding-bottom: 30px !important; }

.payment-history .payment-cards-page {
  height: fit-content; }
  .payment-history .payment-cards-page .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px; }
    .payment-history .payment-cards-page .container .header {
      background: #00A664;
      border: 1px solid #D5D5D5;
      border-radius: 8px 8px 0px 0px;
      height: 80px;
      display: flex;
      flex-direction: row; }
      .payment-history .payment-cards-page .container .header .backArrow {
        width: 10%;
        padding: 22px; }
        .payment-history .payment-cards-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .payment-history .payment-cards-page .container .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        width: 85%;
        padding: 16px; }
    .payment-history .payment-cards-page .container .main {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .payment-history .payment-cards-page .container .main:has(> .card-loader) {
        align-items: center; }
      .payment-history .payment-cards-page .container .main .payment-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #636363;
        margin-top: 30px; }
      .payment-history .payment-cards-page .container .main .add-payment {
        margin: 50px 0px; }
        .payment-history .payment-cards-page .container .main .add-payment .button-container {
          text-align: center; }
          .payment-history .payment-cards-page .container .main .add-payment .button-container .add-button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            color: #00A664;
            border: 1px solid #00A664;
            border-radius: 8px;
            padding: 16px 24px;
            width: 300px; }
            .payment-history .payment-cards-page .container .main .add-payment .button-container .add-button img {
              height: 15px;
              width: 15px;
              margin-right: 5px;
              margin-bottom: 3px; }
  .payment-history .payment-cards-page .card-list-container {
    margin-top: 20px; }
    .payment-history .payment-cards-page .card-list-container .box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      padding: 0px 15px;
      width: 60%;
      margin: 20px auto; }
      .payment-history .payment-cards-page .card-list-container .box.expired {
        background-color: #EDEDED; }
      .payment-history .payment-cards-page .card-list-container .box .leftSection {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        margin-bottom: 24px; }
        .payment-history .payment-cards-page .card-list-container .box .leftSection .CardImage {
          width: 50px;
          margin-right: 8px; }
          .payment-history .payment-cards-page .card-list-container .box .leftSection .CardImage img {
            width: 50px; }
        .payment-history .payment-cards-page .card-list-container .box .leftSection .cardDetails .name {
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 600;
          color: #4A4A4A;
          text-transform: capitalize; }
        .payment-history .payment-cards-page .card-list-container .box .leftSection .cardDetails .type {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          color: #838383; }
        .payment-history .payment-cards-page .card-list-container .box .leftSection .cardDetails .details {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          color: #838383; }
        .payment-history .payment-cards-page .card-list-container .box .leftSection .cardDetails .amount {
          font-family: 'DINNextLTPro-Medium';
          font-style: normal;
          font-weight: 100;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          color: #4A90E2; }
      .payment-history .payment-cards-page .card-list-container .box .rightSection {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: 12px; }
        .payment-history .payment-cards-page .card-list-container .box .rightSection .badge {
          padding: 2px 12px;
          width: 75px;
          height: 24px;
          border-radius: 4px;
          color: #FFFFFF;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px; }
          .payment-history .payment-cards-page .card-list-container .box .rightSection .badge.primary {
            background: #4A90E2; }
          .payment-history .payment-cards-page .card-list-container .box .rightSection .badge.backup {
            background: #838383; }
          .payment-history .payment-cards-page .card-list-container .box .rightSection .badge.expired {
            background: #F96302; }
        .payment-history .payment-cards-page .card-list-container .box .rightSection .edit-button button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #00A664;
          margin-top: 10px;
          box-shadow: none; }

@media only screen and (max-width: 700px) {
  .payment-history {
    padding: 20px 0px 20px 0px !important; }
    .payment-history .payment-cards-page .card-list-container {
      margin-top: 20px; }
      .payment-history .payment-cards-page .card-list-container .box {
        width: 90%;
        margin: 20px auto; }
    .payment-history .payment-cards-page .container .header {
      background: #00A664;
      border: 1px solid #D5D5D5;
      border-radius: unset;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 10px; }
      .payment-history .payment-cards-page .container .header .backArrow {
        width: 10%;
        position: absolute;
        padding: 0px !important; }
        .payment-history .payment-cards-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .payment-history .payment-cards-page .container .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 48px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        margin: auto; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.toast-container {
  display: flex;
  justify-content: center;
  margin-top: 40px; }
  .toast-container .tick {
    display: flex; }
    .toast-container .tick img {
      height: 15px !important;
      width: 15px !important; }
  .toast-container .message-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    height: 40px;
    background-color: #00A664;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    gap: 8px;
    margin-left: 7px; }
    .toast-container .message-container .text {
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #FFFFFF;
      padding: 8px 12px; }
    .toast-container .message-container .close-section {
      display: flex; }
      .toast-container .message-container .close-section .divider {
        border: 0.5px solid #FFFFFF;
        margin-top: 7px;
        margin-bottom: 7px; }
      .toast-container .message-container .close-section .close {
        padding: 5px 10px 5px 10px; }

@media only screen and (max-width: 700px) {
  .toast-container .tick {
    display: none; }
  .toast-container .message-container {
    width: 340px;
    height: 35px; }
    .toast-container .message-container .text {
      padding: 10px; }
    .toast-container .message-container .close-section .divider {
      margin-top: 7px;
      margin-bottom: 7px; }
    .toast-container .message-container .close-section .close {
      padding: 5px 10px 5px 10px; }
      .toast-container .message-container .close-section .close img {
        height: 8px;
        width: 8px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.common-toast-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  z-index: 100; }
  .common-toast-container .tick {
    display: flex; }
    .common-toast-container .tick img {
      height: 15px !important;
      width: 15px !important; }
  .common-toast-container .message-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    gap: 8px;
    margin-left: 7px;
    padding: 10px; }
    .common-toast-container .message-container.error {
      background-color: #F96302; }
    .common-toast-container .message-container.success {
      background-color: #00A664; }
    .common-toast-container .message-container .text {
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #FFFFFF;
      padding: 8px 12px; }
    .common-toast-container .message-container .close-section {
      display: flex;
      height: 100%; }
      .common-toast-container .message-container .close-section .divider {
        border: 0.5px solid #FFFFFF;
        height: 90%; }
      .common-toast-container .message-container .close-section .close {
        padding: 15px 10px 5px 10px; }
        .common-toast-container .message-container .close-section .close img {
          height: 8px;
          width: 8px; }

@media only screen and (max-width: 700px) {
  .toast-container .tick {
    display: none; }
  .toast-container .message-container {
    width: 340px;
    height: 35px; }
    .toast-container .message-container .text {
      padding: 10px; }
    .toast-container .message-container .close-section {
      height: 100%; }
      .toast-container .message-container .close-section .divider {
        height: 90%; }
      .toast-container .message-container .close-section .close {
        padding: 5px 10px 5px 10px; }
        .toast-container .message-container .close-section .close img {
          height: 8px;
          width: 8px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DIN Alternate";
  src: local("DIN-Alternate-Regular"), url("./fonts/DIN-Alternate-Regular.otf") format("opentype"); }

.profile-overview-page {
  background: #E5E5E5;
  padding-top: 160px; }
  .profile-overview-page .common-toast-container {
    height: fit-content;
    z-index: 100;
    position: sticky;
    bottom: 20px; }
  .profile-overview-page .card-popup {
    left: 20px !important;
    right: 20px !important; }
  .profile-overview-page .container {
    display: grid;
    grid-template-rows: 0fr 1fr;
    gap: 30px;
    grid-template-areas: "personalInfo commuteInfo" "bottomSection commuteInfo";
    background: #E5E5E5;
    padding: 0px 40px 50px 40px;
    max-width: 100%;
    overflow-x: scroll;
    width: fit-content;
    margin: 0 auto; }
    .profile-overview-page .container .personal-information {
      grid-area: personalInfo;
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      max-width: 753px; }
      .profile-overview-page .container .personal-information .header {
        height: 80px;
        background: #00A664;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end; }
        .profile-overview-page .container .personal-information .header .section {
          display: flex;
          justify-content: space-evenly;
          width: 50%; }
          .profile-overview-page .container .personal-information .header .section .icon img {
            height: 45px;
            margin-top: 35px; }
      .profile-overview-page .container .personal-information .sub-container {
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 0px 60px 50px; }
        .profile-overview-page .container .personal-information .sub-container .header-content {
          display: flex;
          flex-direction: row; }
          .profile-overview-page .container .personal-information .sub-container .header-content .profile-picture {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            border: 8px solid #FFFFFF;
            background: #A27827;
            color: #FFFFFF;
            text-align: center;
            position: relative;
            top: -50px;
            left: -15px; }
            .profile-overview-page .container .personal-information .sub-container .header-content .profile-picture .initials {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 44px;
              line-height: 72px;
              text-align: center;
              letter-spacing: -1px;
              color: #FFFFFF;
              margin-top: 10px; }
            .profile-overview-page .container .personal-information .sub-container .header-content .profile-picture img {
              height: 85px;
              width: 85px;
              border-radius: 50%; }
          .profile-overview-page .container .personal-information .sub-container .header-content .imageIcon {
            display: flex;
            height: 25px;
            width: 25px;
            background: #D5D5D5;
            border-radius: 50%;
            text-align: center;
            align-items: center;
            padding: 5px;
            left: 60px;
            position: relative;
            top: -20px;
            cursor: pointer; }
            .profile-overview-page .container .personal-information .sub-container .header-content .imageIcon img {
              height: 16px;
              width: 16px; }
          .profile-overview-page .container .personal-information .sub-container .header-content .roles {
            display: flex;
            flex-direction: row;
            margin: 5px 15px; }
            .profile-overview-page .container .personal-information .sub-container .header-content .roles .role img {
              height: 20px; }
            .profile-overview-page .container .personal-information .sub-container .header-content .roles .role .role-name {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 400;
              color: #4A4A4A;
              margin-left: 12px;
              text-transform: capitalize; }
            .profile-overview-page .container .personal-information .sub-container .header-content .roles .second-role {
              margin-left: 15px; }
        .profile-overview-page .container .personal-information .sub-container .main-content {
          display: flex;
          flex-direction: row;
          position: relative;
          top: -30px; }
          .profile-overview-page .container .personal-information .sub-container .main-content .first-section {
            width: 50%;
            margin-right: 20px; }
            .profile-overview-page .container .personal-information .sub-container .main-content .first-section .name {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.5px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              text-transform: capitalize; }
            .profile-overview-page .container .personal-information .sub-container .main-content .first-section .company-name {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 400;
              color: #838383; }
            .profile-overview-page .container .personal-information .sub-container .main-content .first-section .reset-passowrd {
              display: flex;
              align-items: center;
              margin-top: 10px;
              padding-left: 0px; }
              .profile-overview-page .container .personal-information .sub-container .main-content .first-section .reset-passowrd:focus {
                box-shadow: 0 0 5px 0 #4A90E2; }
            .profile-overview-page .container .personal-information .sub-container .main-content .first-section .update-info-container {
              margin-top: 50px;
              display: flex; }
              .profile-overview-page .container .personal-information .sub-container .main-content .first-section .update-info-container .update-info {
                border: 2px solid #00A664;
                border-radius: 5px;
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
                color: #00A664;
                padding: 8px 14px; }
          .profile-overview-page .container .personal-information .sub-container .main-content .seprator {
            border: 1px solid #D5D5D5; }
          .profile-overview-page .container .personal-information .sub-container .main-content .update-info-container {
            margin-top: 24px;
            display: flex; }
            .profile-overview-page .container .personal-information .sub-container .main-content .update-info-container .update-info {
              border: 2px solid #00A664;
              border-radius: 5px;
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              text-transform: uppercase;
              color: #00A664;
              padding: 8px 14px; }
          .profile-overview-page .container .personal-information .sub-container .main-content .second-section {
            margin-left: 25px;
            width: 50%; }
            .profile-overview-page .container .personal-information .sub-container .main-content .second-section .details {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly; }
              .profile-overview-page .container .personal-information .sub-container .main-content .second-section .details .content {
                display: flex;
                flex-direction: row;
                margin: 10px 0px;
                justify-content: space-between; }
                .profile-overview-page .container .personal-information .sub-container .main-content .second-section .details .content .key {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 14px;
                  line-height: 18px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  font-weight: 400;
                  color: #838383;
                  width: 120px; }
                .profile-overview-page .container .personal-information .sub-container .main-content .second-section .details .content .value {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  color: #4A4A4A;
                  width: 70%; }
                  .profile-overview-page .container .personal-information .sub-container .main-content .second-section .details .content .value.address {
                    word-wrap: break-word; }
    .profile-overview-page .container .driver-information {
      grid-area: driverInfo;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-radius: 8px;
      width: 360px;
      margin: 0 auto; }
      .profile-overview-page .container .driver-information .header {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin: 24px; }
      .profile-overview-page .container .driver-information .driver-image-container {
        text-align: center; }
        .profile-overview-page .container .driver-information .driver-image-container img {
          height: 100px;
          width: 150px; }
      .profile-overview-page .container .driver-information .sub-heading {
        margin-left: 24px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383; }
      .profile-overview-page .container .driver-information .main-content {
        margin: 30px;
        height: fit-content; }
        .profile-overview-page .container .driver-information .main-content .driver-details .section {
          margin-bottom: 12px; }
          .profile-overview-page .container .driver-information .main-content .driver-details .section .label {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400;
            color: #4A4A4A;
            margin-bottom: 10px; }
          .profile-overview-page .container .driver-information .main-content .driver-details .section .value {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400;
            color: #4A4A4A; }
        .profile-overview-page .container .driver-information .main-content .driving-info {
          display: flex;
          flex-direction: row;
          margin-right: 18px;
          margin-bottom: 18px; }
          .profile-overview-page .container .driver-information .main-content .driving-info img {
            height: 24px; }
          .profile-overview-page .container .driver-information .main-content .driving-info .content {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A;
            padding-left: 10px; }
      .profile-overview-page .container .driver-information .button-container {
        text-align: center;
        padding: 8px 24px;
        margin-top: unset !important;
        margin-bottom: 50px; }
        .profile-overview-page .container .driver-information .button-container .update-button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          padding: 8px 14px;
          float: left; }
          .profile-overview-page .container .driver-information .button-container .update-button:disabled {
            border: 2px solid #838383 !important;
            color: #838383;
            cursor: not-allowed;
            border: none; }
          .profile-overview-page .container .driver-information .button-container .update-button.disabled {
            background-color: #838383;
            pointer-events: none; }
          .profile-overview-page .container .driver-information .button-container .update-button.renew {
            background-color: #FFFFFF;
            color: #F96302;
            border: 2px solid #F96302; }
      .profile-overview-page .container .driver-information .legal-info {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
        color: #000000;
        margin: 0px 24px 24px 24px; }
    .profile-overview-page .container .driver-info-container {
      grid-area: driverInfo;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-radius: 8px;
      width: 360px;
      margin: 0 auto;
      height: fit-content; }
      .profile-overview-page .container .driver-info-container.edit .section .head {
        position: relative; }
        .profile-overview-page .container .driver-info-container.edit .section .head svg {
          color: #049b5c;
          font-size: 1.25rem;
          margin: 0 1rem;
          width: 1.5rem; }
        .profile-overview-page .container .driver-info-container.edit .section .head h3 {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: inline-block; }
      .profile-overview-page .container .driver-info-container.edit .section .body {
        display: flex;
        flex-direction: column;
        margin: 20px; }
        .profile-overview-page .container .driver-info-container.edit .section .body .license-invalid {
          position: relative;
          display: block;
          top: -0.5rem;
          font-size: 0.75rem;
          color: #c01515;
          height: 0; }
        .profile-overview-page .container .driver-info-container.edit .section .body .select,
        .profile-overview-page .container .driver-info-container.edit .section .body input {
          width: 15rem; }
        .profile-overview-page .container .driver-info-container.edit .section .body label[for="stateDropDown"] {
          width: 2rem;
          display: block;
          margin-top: 20px; }
      .profile-overview-page .container .driver-info-container .driver-info .section .head {
        position: relative; }
        .profile-overview-page .container .driver-info-container .driver-info .section .head svg {
          color: #049b5c;
          font-size: 1.25rem;
          margin: 0 1rem;
          width: 1.5rem; }
        .profile-overview-page .container .driver-info-container .driver-info .section .head h3 {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: inline-block; }
      .profile-overview-page .container .driver-info-container .driver-info .section .body {
        display: flex;
        flex-direction: column;
        margin: 20px; }
      .profile-overview-page .container .driver-info-container .driver-info .apply {
        display: flex;
        flex-direction: column;
        margin: 20px; }
        .profile-overview-page .container .driver-info-container .driver-info .apply p {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.25px; }
        .profile-overview-page .container .driver-info-container .driver-info .apply .button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          width: fit-content; }
    .profile-overview-page .container .bottom-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      grid-area: bottomSection;
      gap: 30px;
      flex-wrap: wrap; }
      .profile-overview-page .container .bottom-section .notfication-preferences {
        width: fit-content;
        width: 360px; }
    .profile-overview-page .container .notfication-preferences {
      grid-area: bottomSection;
      height: fit-content;
      flex-shrink: 0;
      flex-direction: column;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-radius: 8px;
      padding: 24px 24px 100px 24px;
      margin: 0 auto; }
      .profile-overview-page .container .notfication-preferences .header {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .profile-overview-page .container .notfication-preferences .paperless-billing {
        display: flex;
        flex-direction: column;
        padding-top: 15px; }
        .profile-overview-page .container .notfication-preferences .paperless-billing .content {
          display: flex;
          justify-content: space-between;
          padding-bottom: 15px; }
          .profile-overview-page .container .notfication-preferences .paperless-billing .content .heading {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            max-width: 50%; }
          .profile-overview-page .container .notfication-preferences .paperless-billing .content .tag {
            font-family: "DIN Alternate";
            font-size: 12px;
            font-weight: 700;
            line-height: 20px;
            color: #656565; }
        .profile-overview-page .container .notfication-preferences .paperless-billing .text {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          color: #4A4A4A;
          padding-bottom: 15px; }
          .profile-overview-page .container .notfication-preferences .paperless-billing .text .callable {
            font-family: 'Proxima Nova Bold'; }
      .profile-overview-page .container .notfication-preferences .preference:not(:last-child) {
        border-bottom: 1px solid #D5D5D5;
        padding-bottom: 15px; }
      .profile-overview-page .container .notfication-preferences .preference .slider-container {
        color: #4A4A4A;
        display: flex;
        flex-direction: column;
        align-items: normal; }
        .profile-overview-page .container .notfication-preferences .preference .slider-container .left {
          display: flex;
          flex-direction: row;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          justify-content: space-between; }
          .profile-overview-page .container .notfication-preferences .preference .slider-container .left .text {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            margin-bottom: 8px; }
          .profile-overview-page .container .notfication-preferences .preference .slider-container .left .toggle {
            display: flex;
            font-family: "DIN Alternate";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; }
            .profile-overview-page .container .notfication-preferences .preference .slider-container .left .toggle .selected {
              margin-right: 8px;
              text-transform: uppercase;
              margin-top: 6px; }
            .profile-overview-page .container .notfication-preferences .preference .slider-container .left .toggle input:disabled + .slider:before {
              border: solid 1px #e6e6e6 !important; }
        .profile-overview-page .container .notfication-preferences .preference .slider-container .label {
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          margin-top: 16px; }
    .profile-overview-page .container > .notfication-preferences {
      width: 100%;
      min-width: 360px; }
    .profile-overview-page .container .commute-information {
      grid-area: commuteInfo;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-radius: 8px;
      max-width: 100%;
      height: fit-content; }
      .profile-overview-page .container .commute-information .header {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        padding: 25px 25px 0px 25px; }
      .profile-overview-page .container .commute-information .main-content {
        display: flex;
        flex-direction: column; }
        .profile-overview-page .container .commute-information .main-content .first-section .section {
          display: flex;
          justify-content: space-between;
          margin: 30px; }
          .profile-overview-page .container .commute-information .main-content .first-section .section .left-section {
            display: flex;
            flex-direction: column;
            justify-content: center; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .van-class-details:nth-child(2) {
              margin-top: 10px; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .van-class-details .van-name {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              display: flex;
              align-items: center;
              letter-spacing: -0.25px;
              color: #4A4A4A; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .van-class-details .van-class {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .van-class-details .van-pickup-address {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .view-plan-container {
              margin-top: 30px; }
              .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .view-plan-container .view-plan {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                align-items: center;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #00A664;
                padding: 0; }
          .profile-overview-page .container .commute-information .main-content .first-section .section .right-section {
            height: fit-content; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .car-image img {
              height: 150px;
              width: 200px; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .car-image .image-not-found {
              height: 150px;
              width: 150px; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .car-image-opacity img {
              height: 150px;
              width: 200px;
              opacity: 0.4; }
            .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .car-image-opacity .image-not-found {
              height: 150px;
              width: 150px; }
        .profile-overview-page .container .commute-information .main-content .first-section .traditional-agreement {
          margin: 24px;
          width: 70%; }
          .profile-overview-page .container .commute-information .main-content .first-section .traditional-agreement .text {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #838383; }
            .profile-overview-page .container .commute-information .main-content .first-section .traditional-agreement .text .callable {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 700;
              color: #484848;
              padding-left: 3px; }
        .profile-overview-page .container .commute-information .main-content .first-section .traditional-leave-Vanpool {
          display: flex;
          padding: 24px 16px;
          justify-content: flex-start;
          border-radius: 8px;
          border: 1px solid #D5D5D5;
          box-shadow: 0px 1px 0px 0px #D5D5D5;
          margin: 24px;
          cursor: pointer;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .profile-overview-page .container .commute-information .main-content .seprator {
          border: 1px solid #D5D5D5;
          margin: 0px 20px; }
        .profile-overview-page .container .commute-information .main-content .second-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 8px; }
          .profile-overview-page .container .commute-information .main-content .second-section .newly-approved-driver {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            margin: 20px; }
          .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .big-container {
            max-height: 70vh;
            overflow: scroll; }
            .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .big-container .cancel-subscription-popup {
              margin: 24px !important; }
              .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .big-container .cancel-subscription-popup .change-days-popup-container {
                margin-top: 24px; }
                .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .big-container .cancel-subscription-popup .change-days-popup-container label {
                  font-family: 'Proxima Nova';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  font-weight: 700; }
          .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .right-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end; }
          .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .radio-section .radio {
            padding: 0px; }
            .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .radio-section .radio .round {
              margin: 10px 10px 10px 0px; }
            .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .radio-section .radio.active {
              background-color: unset !important; }
            .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .radio-section .radio .text {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
          .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .blue-notes {
            background: #EDF4FC;
            box-shadow: 0px 1px 0px rgba(213, 213, 213, 0.2);
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #1E3A5A;
            display: flex;
            width: 100%;
            padding: 16px;
            margin-top: 40px; }
            .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .blue-notes .info-circle {
              height: 20px;
              margin-right: 18px; }
          .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .save-button, .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .save-preferences-button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            height: 40px;
            padding: 8px 24px;
            margin: 24px; }
            .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .save-button:disabled, .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .save-preferences-button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .save-button:enabled, .profile-overview-page .container .commute-information .main-content .second-section .subscription-section .save-preferences-button:enabled {
              background-color: #00A664; }
          .profile-overview-page .container .commute-information .main-content .second-section.inactive {
            background-color: #EDEDED;
            margin-top: 20px;
            padding: 20px 16px; }
            .profile-overview-page .container .commute-information .main-content .second-section.inactive .section {
              margin: 20px 0px 0px 0px !important; }
          .profile-overview-page .container .commute-information .main-content .second-section.active {
            background-color: #FFFFFF; }
          .profile-overview-page .container .commute-information .main-content .second-section .section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 30px; }
          .profile-overview-page .container .commute-information .main-content .second-section .commute-days {
            background-color: #F3F3F3;
            padding: 16px;
            border-radius: 8px; }
            .profile-overview-page .container .commute-information .main-content .second-section .commute-days .commute-days-heading {
              font-weight: 500;
              color: #4A4A4A; }
            .profile-overview-page .container .commute-information .main-content .second-section .commute-days .ending-date {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 500;
              color: #F96302;
              margin-top: 8px; }
            .profile-overview-page .container .commute-information .main-content .second-section .commute-days .empty-days {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              text-align: center; }
            .profile-overview-page .container .commute-information .main-content .second-section .commute-days .error-container {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column; }
              .profile-overview-page .container .commute-information .main-content .second-section .commute-days .error-container .empty-days {
                margin-top: 6px; }
            .profile-overview-page .container .commute-information .main-content .second-section .commute-days .change-my-commute {
              width: 100%; }
              .profile-overview-page .container .commute-information .main-content .second-section .commute-days .change-my-commute.error {
                display: flex;
                justify-content: center; }
            .profile-overview-page .container .commute-information .main-content .second-section .commute-days .try-button {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              text-align: center;
              text-transform: uppercase;
              background-color: #FFF !important;
              border: 2px solid #00A664;
              border-radius: 5px;
              color: #00A664;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              padding: 8px 24px;
              background-color: #F3F3F3 !important;
              margin: 0 auto; }
              .profile-overview-page .container .commute-information .main-content .second-section .commute-days .try-button:disabled {
                border: 2px solid #838383 !important;
                color: #838383;
                cursor: not-allowed;
                border: none; }
          .profile-overview-page .container .commute-information .main-content .second-section .commute-details .route-deatils {
            margin-top: 20px; }
            .profile-overview-page .container .commute-information .main-content .second-section .commute-details .route-deatils .heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 400;
              color: #838383; }
            .profile-overview-page .container .commute-information .main-content .second-section .commute-details .route-deatils .details {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-top: 8px;
              margin-bottom: 24px; }
              .profile-overview-page .container .commute-information .main-content .second-section .commute-details .route-deatils .details .address {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #4A4A4A;
                width: 40%; }
              .profile-overview-page .container .commute-information .main-content .second-section .commute-details .route-deatils .details .time {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 500;
                text-align: right;
                color: #4A4A4A; }
        .profile-overview-page .container .commute-information .main-content .viewAgreement {
          color: #4A4A4A;
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-decoration-line: underline;
          text-underline-offset: 4px;
          text-transform: uppercase;
          margin: 0 30px 20px 30px;
          cursor: pointer; }
          .profile-overview-page .container .commute-information .main-content .viewAgreement img {
            height: 22px;
            width: 22px;
            margin-bottom: 2px; }
          .profile-overview-page .container .commute-information .main-content .viewAgreement span {
            margin-left: 5px; }
        .profile-overview-page .container .commute-information .main-content .third-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0px 20px; }
          .profile-overview-page .container .commute-information .main-content .third-section .subscription-action-items {
            margin: 10px 10px 0 10px; }
            .profile-overview-page .container .commute-information .main-content .third-section .subscription-action-items .subscription-items {
              border: 1px solid #D5D5D5;
              filter: drop-shadow(0px 1px 0px #D5D5D5);
              border-radius: 8px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 20px 16px;
              margin-bottom: 24px;
              cursor: pointer; }
              .profile-overview-page .container .commute-information .main-content .third-section .subscription-action-items .subscription-items.disabled {
                pointer-events: none; }
              .profile-overview-page .container .commute-information .main-content .third-section .subscription-action-items .subscription-items span {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 21px;
                line-height: 25px;
                letter-spacing: -0.5px;
                color: #4A4A4A;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                border-bottom: 2px #4A4A4A;
                padding-bottom: 1px; }
              .profile-overview-page .container .commute-information .main-content .third-section .subscription-action-items .subscription-items img {
                height: 22px;
                width: 18px; }
          .profile-overview-page .container .commute-information .main-content .third-section .cancelled-container {
            margin: 0px 0px 20px 0px;
            color: #642801; }
            .profile-overview-page .container .commute-information .main-content .third-section .cancelled-container .status-content {
              border-radius: 8px; }
              .profile-overview-page .container .commute-information .main-content .third-section .cancelled-container .status-content .warning-icon {
                flex-direction: column;
                justify-content: flex-start; }
              .profile-overview-page .container .commute-information .main-content .third-section .cancelled-container .status-content .content-text {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased; }
                .profile-overview-page .container .commute-information .main-content .third-section .cancelled-container .status-content .content-text .warning-heading {
                  font-weight: 600; }
                .profile-overview-page .container .commute-information .main-content .third-section .cancelled-container .status-content .content-text .warning-text {
                  font-weight: 400; }
      .profile-overview-page .container .commute-information .charges-popup {
        top: 15%; }
      .profile-overview-page .container .commute-information .notification-container {
        display: flex;
        background-color: #EDF4FC;
        border-radius: 8px;
        box-shadow: 0px 1px 0px 0px rgba(213, 213, 213, 0.2);
        padding: 16px;
        margin: 20px 30px 30px 30px; }
        .profile-overview-page .container .commute-information .notification-container .info-icon {
          margin-right: 16px; }
          .profile-overview-page .container .commute-information .notification-container .info-icon img {
            height: 20px;
            width: 20px; }
        .profile-overview-page .container .commute-information .notification-container .notification-text {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
          .profile-overview-page .container .commute-information .notification-container .notification-text .callable {
            font-weight: 700; }
            .profile-overview-page .container .commute-information .notification-container .notification-text .callable a {
              text-decoration: underline;
              color: #00A664; }
    .profile-overview-page .container .ds-terms-of-service {
      display: flex;
      position: absolute;
      width: 500px;
      justify-content: center;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .profile-overview-page .container .ds-terms-of-service .ClickwrapIframeAppContainer {
        display: flex; }
    .profile-overview-page .container .join-vanpool {
      grid-area: commuteInfo;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-radius: 8px;
      max-width: 100%;
      height: fit-content;
      padding: 24px 26px; }
      .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500; }
      .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .edit-my-commute-heading {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #636363;
        margin: 16px 0px 16px; }
      .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .edit-my-commute-action {
        border: 1px solid #D5D5D5;
        filter: drop-shadow(0px 1px 0px #D5D5D5);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 16px;
        cursor: pointer; }
        .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .edit-my-commute-action span {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          border-bottom: 2px #4A4A4A;
          padding-bottom: 1px; }
        .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .edit-my-commute-action img {
          height: 22px;
          width: 18px; }
      .profile-overview-page .container .join-vanpool .seprator {
        border: 1px solid #D5D5D5;
        margin: 24px 0px 24px; }
      .profile-overview-page .container .join-vanpool .below-section {
        display: flex;
        flex-direction: column; }
        .profile-overview-page .container .join-vanpool .below-section .header-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .profile-overview-page .container .join-vanpool .below-section .header-section .header {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0.15px;
            color: #4A4A4A;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500; }
          .profile-overview-page .container .join-vanpool .below-section .header-section .vanpool img {
            height: 45px; }
        .profile-overview-page .container .join-vanpool .below-section .sub-header {
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          color: #636363;
          margin: 16px 0px 16px; }
        .profile-overview-page .container .join-vanpool .below-section .bottom-section li {
          margin-bottom: 24px;
          display: flex;
          align-items: center; }
          .profile-overview-page .container .join-vanpool .below-section .bottom-section li .icon {
            height: 20px;
            margin-right: 8px; }
          .profile-overview-page .container .join-vanpool .below-section .bottom-section li span {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
        .profile-overview-page .container .join-vanpool .below-section .button-container {
          display: flex;
          justify-content: center;
          align-items: center; }
          .profile-overview-page .container .join-vanpool .below-section .button-container button {
            padding: 8px 24px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            margin: 0 auto; }
            .profile-overview-page .container .join-vanpool .below-section .button-container button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .profile-overview-page .container .join-vanpool .below-section .button-container button:enabled {
              background-color: #00A664; }

@media only screen and (min-width: 700px) {
  .container {
    grid-template-columns: 755px 450px; } }

@media only screen and (max-width: 1360px) and (min-width: 700px) {
  .profile-overview-page {
    padding-top: 120px;
    flex-direction: column; }
    .profile-overview-page .container {
      grid-template-columns: 1fr !important;
      grid-template-areas: "personalInfo" "commuteInfo" "bottomSection";
      grid-gap: 20px 24px;
      grid-template-rows: unset !important;
      padding: 16px; }
      .profile-overview-page .container .personal-information {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
        height: unset !important; }
        .profile-overview-page .container .personal-information .sub-container {
          background: #FFFFFF;
          border: 1px solid #D5D5D5;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          display: flex;
          flex-direction: column;
          padding: 0px 60px 50px; }
          .profile-overview-page .container .personal-information .sub-container .main-content {
            display: flex;
            flex-direction: row;
            position: relative;
            top: -30px; }
            .profile-overview-page .container .personal-information .sub-container .main-content .first-section {
              width: 50%;
              margin-right: 20px; }
            .profile-overview-page .container .personal-information .sub-container .main-content .second-section {
              margin-left: 25px; }
      .profile-overview-page .container .commute-information {
        margin: 0 auto;
        width: 100%;
        max-width: 753px;
        height: fit-content;
        min-width: 470px; }
        .profile-overview-page .container .commute-information .main-content .first-section .traditional-agreement {
          margin: 24px;
          width: 40%; }
      .profile-overview-page .container .join-vanpool {
        grid-area: commuteInfo;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-radius: 8px;
        max-width: 100%;
        height: fit-content;
        padding: 24px 36px; } }

@media only screen and (max-width: 1360px) {
  .profile-overview-page {
    align-items: center; }
    .profile-overview-page .personal-information {
      width: inherit; } }

@media only screen and (max-width: 700px) {
  .profile-overview-page {
    padding-top: calc(60px + 43px) !important;
    flex-direction: column; }
    .profile-overview-page .container {
      grid-template-columns: 1fr !important;
      grid-template-areas: "personalInfo" "commuteInfo" "bottomSection";
      grid-row-gap: 20px;
      grid-template-rows: unset !important;
      padding: 0px 16px 50px 16px; }
      .profile-overview-page .container .personal-information {
        display: flex;
        flex-direction: column;
        margin: auto;
        height: unset !important;
        width: 100%; }
        .profile-overview-page .container .personal-information .sub-container {
          background: #FFFFFF;
          border: 1px solid #D5D5D5;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          display: flex;
          flex-direction: column;
          padding: 0px 25px 50px; }
          .profile-overview-page .container .personal-information .sub-container .main-content {
            display: flex;
            flex-direction: column;
            position: relative;
            top: -30px; }
            .profile-overview-page .container .personal-information .sub-container .main-content .first-section {
              width: auto;
              margin-bottom: 30px; }
            .profile-overview-page .container .personal-information .sub-container .main-content .second-section {
              margin-left: 0px;
              margin-top: 30px;
              width: unset; }
              .profile-overview-page .container .personal-information .sub-container .main-content .second-section .details .update-info-container {
                text-align: center;
                display: block;
                margin-top: 40px; }
      .profile-overview-page .container .driver-information {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-radius: 8px;
        min-height: 340px;
        width: 100%; }
        .profile-overview-page .container .driver-information .header {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          margin: 24px; }
        .profile-overview-page .container .driver-information .sub-heading {
          margin-left: 24px;
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          color: #838383; }
        .profile-overview-page .container .driver-information .main-content {
          margin-left: 24px;
          margin-top: 30px; }
          .profile-overview-page .container .driver-information .main-content .driver-details .section {
            margin-bottom: 12px; }
            .profile-overview-page .container .driver-information .main-content .driver-details .section .label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 400;
              color: #4A4A4A;
              margin-bottom: 10px; }
            .profile-overview-page .container .driver-information .main-content .driver-details .section .value {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A; }
      .profile-overview-page .container .bottom-section .notfication-preferences {
        width: fit-content;
        width: 100%; }
      .profile-overview-page .container .commute-information .main-content .first-section .section {
        flex-direction: column; }
        .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .van-class-details {
          margin-top: 10px; }
          .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .van-class-details .van-name {
            font-size: 15px;
            line-height: 20px; }
          .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .van-class-details .van-class {
            font-size: 14px;
            line-height: 24px; }
        .profile-overview-page .container .commute-information .main-content .first-section .section .left-section .view-plan-container {
          position: relative;
          top: 140px;
          text-align: center; }
        .profile-overview-page .container .commute-information .main-content .first-section .section .right-section {
          text-align: center; }
          .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .rate .price-group .price {
            font-size: 30px;
            line-height: 25px; }
          .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .rate .price-group .doller,
          .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .rate .price-group .decimal {
            line-height: 8px !important;
            font-size: 15px; }
          .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .plan-details .plan {
            font-size: 15px;
            line-height: 20px; }
          .profile-overview-page .container .commute-information .main-content .first-section .section .right-section .plan-details .package {
            font-size: 15px;
            line-height: 20px;
            margin-top: 0; }
      .profile-overview-page .container .commute-information .main-content .second-section.inactive {
        background-color: #EDEDED; }
      .profile-overview-page .container .commute-information .main-content .second-section.active {
        background-color: #FFFFFF; }
      .profile-overview-page .container .commute-information .main-content .second-section .change-my-commute {
        width: max-content; }
        .profile-overview-page .container .commute-information .main-content .second-section .change-my-commute .change-commute {
          padding: 0px; }
      .profile-overview-page .container .commute-information .main-content .viewAgreement {
        color: #4A4A4A;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-decoration-line: underline;
        text-underline-offset: 4px;
        text-transform: uppercase;
        margin: 20px 30px;
        cursor: pointer; }
        .profile-overview-page .container .commute-information .main-content .viewAgreement img {
          height: 22px;
          width: 22px;
          margin-bottom: 2px; }
        .profile-overview-page .container .commute-information .main-content .viewAgreement span {
          margin-left: 5px; }
      .profile-overview-page .container .commute-information .main-content .third-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px; }
        .profile-overview-page .container .commute-information .main-content .third-section .status-content {
          border-radius: 8px;
          display: flex;
          align-items: flex-start !important; }
          .profile-overview-page .container .commute-information .main-content .third-section .status-content .content-text {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600; }
            .profile-overview-page .container .commute-information .main-content .third-section .status-content .content-text .warning-heading {
              font-weight: 400; }
      .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500; }
      .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .edit-my-commute-heading {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #636363;
        margin: 16px 0px 16px; }
      .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .edit-my-commute-action {
        border: 1px solid #D5D5D5;
        filter: drop-shadow(0px 1px 0px #D5D5D5);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 16px;
        cursor: pointer; }
        .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .edit-my-commute-action span {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          border-bottom: 2px #4A4A4A;
          padding-bottom: 1px; }
        .profile-overview-page .container .join-vanpool .top-section .edit-my-commute .edit-my-commute-action img {
          height: 22px;
          width: 18px; }
      .profile-overview-page .container .join-vanpool .seprator {
        border: 1px solid #D5D5D5;
        margin: 24px 0px 24px; }
      .profile-overview-page .container .join-vanpool .below-section {
        display: flex;
        flex-direction: column; }
        .profile-overview-page .container .join-vanpool .below-section .header-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .profile-overview-page .container .join-vanpool .below-section .header-section .header {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0.15px;
            color: #4A4A4A;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500; }
          .profile-overview-page .container .join-vanpool .below-section .header-section .vanpool img {
            height: 45px; }
        .profile-overview-page .container .join-vanpool .below-section .sub-header {
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          color: #636363;
          margin: 16px 0px 16px; }
        .profile-overview-page .container .join-vanpool .below-section .bottom-section li {
          margin-bottom: 24px;
          display: flex;
          align-items: center; }
          .profile-overview-page .container .join-vanpool .below-section .bottom-section li .icon {
            height: 20px;
            margin-right: 8px; }
          .profile-overview-page .container .join-vanpool .below-section .bottom-section li span {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
        .profile-overview-page .container .join-vanpool .below-section .button-container {
          display: flex;
          justify-content: center;
          align-items: center; }
          .profile-overview-page .container .join-vanpool .below-section .button-container button {
            padding: 8px 24px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            margin: 0 auto; }
            .profile-overview-page .container .join-vanpool .below-section .button-container button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .profile-overview-page .container .join-vanpool .below-section .button-container button:enabled {
              background-color: #00A664; } }

@media only screen and (max-width: 768px) {
  .notfication-preferences .callable {
    color: #00A664;
    text-decoration: underline; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DIN Alternate";
  src: local("DIN-Alternate-Regular"), url("./fonts/DIN-Alternate-Regular.otf") format("opentype"); }

.profile-edit-page {
  max-width: 1160px;
  margin: auto; }
  .profile-edit-page .view-container {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px; }
    .profile-edit-page .view-container .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      margin-bottom: 30px; }
      .profile-edit-page .view-container .buttons .save {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        height: 60px;
        width: 340px;
        font-size: 24px !important; }
        .profile-edit-page .view-container .buttons .save:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .profile-edit-page .view-container .buttons .save:enabled {
          background-color: #00A664; }
    .profile-edit-page .view-container .header {
      background: #00A664;
      border: 1px solid #D5D5D5;
      border-radius: 8px 8px 0px 0px;
      height: 80px;
      display: flex;
      flex-direction: row; }
      .profile-edit-page .view-container .header .backArrow {
        width: 10%;
        padding: 22px; }
        .profile-edit-page .view-container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .profile-edit-page .view-container .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        width: 85%;
        padding: 16px; }
    .profile-edit-page .view-container .content-container {
      padding: 48px 20px 48px 24px;
      display: flex;
      flex-direction: column;
      gap: 20px; }
      .profile-edit-page .view-container .content-container .invalid-text {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #F96302;
        margin-top: unset !important; }
      .profile-edit-page .view-container .content-container .invalid-address-auto {
        border: 2px solid #F96302 !important;
        box-shadow: none; }
      .profile-edit-page .view-container .content-container .sub-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        display: flex;
        align-items: center; }
      .profile-edit-page .view-container .content-container .label {
        font-family: 'DINNextLTPro-Medium';
        font-style: normal;
        font-size: 13px;
        line-height: 25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .profile-edit-page .view-container .content-container .field input {
        background: #FFFFFF;
        border: 2px solid #D5D5D5;
        height: 56px;
        color: #4A4A4A;
        padding: 15px; }
        .profile-edit-page .view-container .content-container .field input ::placeholder {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.25px;
          color: #838383;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .profile-edit-page .view-container .content-container .field input:disabled {
          background: #D5D5D5;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.25px;
          color: #838383;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .profile-edit-page .view-container .content-container .field input.error {
          border-color: #F96302;
          box-shadow: none; }
        .profile-edit-page .view-container .content-container .field input:disabled {
          background-color: #EDEDED !important;
          color: #838383; }
      .profile-edit-page .view-container .content-container .condition {
        display: flex; }
        .profile-edit-page .view-container .content-container .condition .checkbox {
          height: 20px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          background-color: #EDEDED;
          border: 2px solid #838383;
          border-radius: 2px;
          padding: 0px; }
          .profile-edit-page .view-container .content-container .condition .checkbox.selected {
            background: #00A664;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px; }
            .profile-edit-page .view-container .content-container .condition .checkbox.selected img {
              width: 13px; }
        .profile-edit-page .view-container .content-container .condition .text {
          margin-left: 10px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A; }
      .profile-edit-page .view-container .content-container .divider-line {
        border: 1px solid #D5D5D5;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px; }
      .profile-edit-page .view-container .content-container .names {
        display: flex;
        flex-wrap: wrap;
        gap: 20px; }
      .profile-edit-page .view-container .content-container .contacts {
        display: flex;
        flex-direction: column;
        gap: 20px; }

@media only screen and (min-width: 931px) {
  .profile-edit-page .content-container .names input {
    width: 404px !important;
    min-width: unset; }
  .profile-edit-page .content-container .employer input {
    width: 409px !important;
    min-width: unset; }
  .profile-edit-page .content-container .address-section .field {
    min-width: unset; }
  .profile-edit-page .content-container .address-section .address1 input,
  .profile-edit-page .content-container .address-section .city input {
    width: 404px !important; }
  .profile-edit-page .content-container .address-section .address2 input {
    width: 375px !important; }
  .profile-edit-page .content-container .address-section .state input {
    width: 187px !important; }
  .profile-edit-page .content-container .address-section .zip {
    margin-left: 20px; }
    .profile-edit-page .content-container .address-section .zip input {
      width: 164px !important; }
  .profile-edit-page .content-container .address-section .small-address {
    display: flex; }
  .profile-edit-page .content-container .number {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px; }
    .profile-edit-page .content-container .number .flexibility-container {
      width: 110px !important; }
    .profile-edit-page .content-container .number .extension input {
      width: 77px;
      min-width: unset; }
    .profile-edit-page .content-container .number .number-field:nth-child(2) input {
      min-width: unset;
      width: 275px; } }

@media only screen and (max-width: 930px) and (min-width: 803px) {
  .profile-edit-page {
    width: 90%; }
    .profile-edit-page .content-container {
      padding: 48px 30px !important; }
      .profile-edit-page .content-container .names input {
        width: 320px !important;
        min-width: unset; }
      .profile-edit-page .content-container .employer input {
        width: 409px !important;
        min-width: unset; }
      .profile-edit-page .content-container .address-section .field {
        min-width: unset; }
      .profile-edit-page .content-container .address-section .address1 input,
      .profile-edit-page .content-container .address-section .city input {
        width: 390px !important; }
      .profile-edit-page .content-container .address-section .address2 input {
        width: 260px !important; }
      .profile-edit-page .content-container .address-section .state input {
        width: 187px !important; }
      .profile-edit-page .content-container .address-section .zip {
        margin-left: 20px; }
        .profile-edit-page .content-container .address-section .zip input {
          width: 164px !important; }
      .profile-edit-page .content-container .address-section .small-address {
        display: flex; }
      .profile-edit-page .content-container .number {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px; }
        .profile-edit-page .content-container .number .flexibility-container {
          width: 110px !important; }
        .profile-edit-page .content-container .number .extension input {
          width: 77px;
          min-width: unset; }
        .profile-edit-page .content-container .number .number-field:nth-child(2) input {
          min-width: unset;
          width: 275px; } }

@media only screen and (max-width: 802px) {
  .profile-edit-page {
    width: 100%; }
    .profile-edit-page .header {
      border-radius: 0px !important;
      height: 64px !important;
      align-items: center; }
      .profile-edit-page .header .backArrow {
        width: 10%;
        padding: 0px !important;
        display: flex;
        align-items: center; }
      .profile-edit-page .header .heading {
        font-family: 'DIN Alternate' !important;
        font-style: normal;
        font-weight: 1000 !important;
        font-size: 24px !important;
        line-height: 36px;
        padding: 0px !important; }
    .profile-edit-page .content-container {
      padding: 24px 25px !important; }
      .profile-edit-page .content-container .names input,
      .profile-edit-page .content-container .employer input {
        min-width: 350px; }
      .profile-edit-page .content-container .address-section .field {
        min-width: unset; }
      .profile-edit-page .content-container .address-section .address1 input, .profile-edit-page .content-container .address-section .city input, .profile-edit-page .content-container .address-section .address2 input {
        width: 340px !important; }
      .profile-edit-page .content-container .address-section .state input, .profile-edit-page .content-container .address-section .zip input {
        width: 164px !important;
        min-width: unset !important; }
      .profile-edit-page .content-container .address-section .zip {
        margin-left: 20px; }
      .profile-edit-page .content-container .address-section .small-address {
        display: flex; }
      .profile-edit-page .content-container .number {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px; }
        .profile-edit-page .content-container .number .flexibility-container {
          width: 110px !important; }
        .profile-edit-page .content-container .number .extension input {
          width: 110px;
          min-width: unset; }
        .profile-edit-page .content-container .number .number-field:nth-child(2) input {
          min-width: unset;
          width: 220px; } }

@media only screen and (min-width: 271px) and (max-width: 400px) {
  .profile-edit-page .field {
    min-width: 100% !important; }
    .profile-edit-page .field .license {
      max-width: 90% !important;
      width: unset !important; }
    .profile-edit-page .field input {
      min-width: 90% !important;
      width: unset !important; }
  .profile-edit-page .buttons .save {
    width: 80% !important; } }

@media only screen and (max-width: 270px) {
  .profile-edit-page .field {
    min-width: 100% !important; }
    .profile-edit-page .field .license {
      min-width: 90% !important;
      width: unset !important; }
    .profile-edit-page .field input {
      min-width: 90% !important;
      width: unset !important; }
  .profile-edit-page .buttons .save {
    width: 80% !important; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.billing {
  background: #E5E5E5; }

.vanpool-overview-page .notification-container {
  display: flex;
  background-color: #EDF4FC;
  border-radius: 8px;
  box-shadow: 0px 1px 0px 0px rgba(213, 213, 213, 0.2);
  padding: 16px;
  margin-top: 20px; }
  .vanpool-overview-page .notification-container .info-icon {
    margin-right: 12px; }
    .vanpool-overview-page .notification-container .info-icon img {
      height: 20px;
      width: 20px; }
  .vanpool-overview-page .notification-container .notification-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased; }
    .vanpool-overview-page .notification-container .notification-text .callable {
      font-weight: 700;
      text-decoration: underline; }

.vanpool-route-container .roster-container {
  margin-top: 12px; }
  .vanpool-route-container .roster-container .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .vanpool-route-container .roster-container .header .left {
      display: flex;
      justify-content: center;
      align-items: baseline; }
      .vanpool-route-container .roster-container .header .left .heading {
        font-family: 'Proxima Nova';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 600;
        color: #4A4A4A;
        margin-right: 15px; }
      .vanpool-route-container .roster-container .header .left .members {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383;
        font-weight: 400; }
        .vanpool-route-container .roster-container .header .left .members .count {
          font-size: 18px; }
    .vanpool-route-container .roster-container .header .see-all {
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #00A664;
      cursor: pointer; }
  .vanpool-route-container .roster-container .participants {
    display: flex;
    flex-direction: row;
    margin-top: 12px; }
    .vanpool-route-container .roster-container .participants .image {
      width: 48px;
      height: 48px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1px solid #FFFFFF;
      margin-left: -5px; }
      .vanpool-route-container .roster-container .participants .image img {
        width: 48px;
        height: 48px;
        border-radius: 100px; }
      .vanpool-route-container .roster-container .participants .image .image-container {
        height: 48px;
        width: 48px;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0px;
        cursor: default; }
        .vanpool-route-container .roster-container .participants .image .image-container div {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          /* 100% */
          letter-spacing: -0.25px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;
          text-transform: uppercase; }

@media only screen and (max-width: 700px) {
  .billing .vanpool-overview-page {
    background: #E5E5E5;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap; }
    .billing .vanpool-overview-page .container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 0px 30px;
      background: #E5E5E5;
      padding: 0px 20px 50px 20px;
      grid-template-areas: "vanpoollInfo" "otherInfo" "environmentInfo";
      margin-left: 10px; }
      .billing .vanpool-overview-page .container .vanpool-information {
        grid-area: vanpoollInfo;
        display: flex;
        flex-direction: column;
        width: 350px;
        height: max-content;
        flex-grow: 2;
        padding: 24px;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        box-shadow: 0px 1px 1px #D5D5D5;
        border-radius: 8px; }
        .billing .vanpool-overview-page .container .vanpool-information .alert {
          font-family: 'DINNextLTPro-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #FFFFFF;
          background-color: #00A664;
          border-radius: 4px;
          padding: 3px 12px;
          width: max-content;
          text-transform: capitalize;
          margin-bottom: 3px; }
        .billing .vanpool-overview-page .container .vanpool-information .due {
          background-color: #F96302; }
        .billing .vanpool-overview-page .container .vanpool-information .vanpool-name {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          align-items: center;
          padding: 5px 0px; }
        .billing .vanpool-overview-page .container .vanpool-information .vanpool-type {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          color: #4A4A4A; }
        .billing .vanpool-overview-page .container .vanpool-information .vanpool-desc {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          margin-top: 3px;
          color: #4A4A4A; }
        .billing .vanpool-overview-page .container .vanpool-information .vanpool-image-container {
          display: flex;
          text-align: center;
          align-items: center;
          flex-direction: column; }
          .billing .vanpool-overview-page .container .vanpool-information .vanpool-image-container img {
            height: 120px;
            width: 150px; }
          .billing .vanpool-overview-page .container .vanpool-information .vanpool-image-container .image-opacity {
            opacity: 0.4; }
        .billing .vanpool-overview-page .container .vanpool-information .details .vanpool-license {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px; }
          .billing .vanpool-overview-page .container .vanpool-information .details .vanpool-license .key {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            text-align: right;
            color: #838383; }
          .billing .vanpool-overview-page .container .vanpool-information .details .vanpool-license .value {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            color: #4A4A4A;
            padding-left: 10px; }
        .billing .vanpool-overview-page .container .vanpool-information .commuteDays {
          margin-top: 10px; }
          .billing .vanpool-overview-page .container .vanpool-information .commuteDays .heading {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400;
            color: #838383;
            margin-bottom: 6px; }
          .billing .vanpool-overview-page .container .vanpool-information .commuteDays .oprational-days {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 9px;
            line-height: 11px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #4A4A4A; }
            .billing .vanpool-overview-page .container .vanpool-information .commuteDays .oprational-days .day-btn {
              background: #D5D5D5;
              height: 22px;
              width: 28px;
              border-radius: 5px;
              text-transform: uppercase;
              padding-top: 6px;
              font-weight: 700;
              margin-right: 8px;
              text-align: center;
              cursor: default; }
            .billing .vanpool-overview-page .container .vanpool-information .commuteDays .oprational-days .active {
              background: #D4EDE1; }
        .billing .vanpool-overview-page .container .vanpool-information .vcc {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-decoration-line: underline;
          text-transform: uppercase;
          color: #4A4A4A;
          margin-top: 15px;
          cursor: pointer; }
          .billing .vanpool-overview-page .container .vanpool-information .vcc img {
            padding-right: 3px; }
        .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-around;
          -moz-justify-content: space-around;
          -ms-justify-content: space-around;
          justify-content: space-around;
          -ms-flex-pack: space-around;
          position: fixed;
          z-index: 500;
          background: rgba(0, 0, 0, 0.5);
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          padding-right: 10px; }
          .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container .modal {
            width: 100%;
            max-width: 1024px;
            margin: 0 auto;
            background-color: #FFFFFF;
            -webkit-flex-grow: 1;
            -moz-flex-grow: 1;
            -ms-flex-grow: 1;
            flex-grow: 1;
            max-height: calc(100vh - 134px);
            height: max-content;
            padding: 10px;
            max-width: 370px;
            border-radius: 8px;
            border: 1px solid #D5D5D5;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            bottom: 60px;
            position: relative;
            top: 100px; }
            .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container .modal .header {
              height: 24px;
              margin-bottom: 15px;
              font-size: 26px;
              position: relative;
              margin-top: 24px;
              margin-left: 10px;
              display: flex;
              justify-content: space-between; }
              .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container .modal .header span {
                font-family: 'DINNextLTPro-Regular';
                font-size: 26px;
                font-weight: 500;
                line-height: 32px;
                text-align: left; }
              .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container .modal .header .close svg {
                position: absolute;
                right: 0;
                cursor: pointer;
                color: #00A664;
                font-size: 24px; }
            .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container .modal .body {
              overflow-x: auto;
              overflow-y: auto;
              max-height: calc(100vh - 310px);
              margin-left: 40px;
              margin-bottom: 30px; }
              .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container .modal .body .pdf-loader {
                font-family: 'DINNextLTPro-Regular';
                font-size: 16px;
                font-weight: 200;
                line-height: 32px;
                margin-left: 33%; }
            .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container .modal .footer {
              width: 204px;
              height: 41px;
              background-color: #00A664;
              padding: 8px 40px;
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              pointer-events: all;
              border-radius: 5px;
              margin-left: 25%; }
              .billing .vanpool-overview-page .container .vanpool-information .vcc-modal-container .modal .footer button {
                font-family: 'Proxima Nova';
                font-weight: 700;
                color: #FFFFFF; }
      .billing .vanpool-overview-page .container .other-information {
        grid-area: otherInfo;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-radius: 8px;
        height: fit-content;
        max-width: 350px;
        width: 350px;
        margin-top: 25px; }
        .billing .vanpool-overview-page .container .other-information .content-border {
          border-bottom: 1px solid #D5D5D5;
          margin: 0px 24px; }
        .billing .vanpool-overview-page .container .other-information .maintenance-due {
          display: flex;
          flex-direction: row;
          padding: 16px;
          background: #FEEFE6;
          box-shadow: 0px 1px 0px rgba(213, 213, 213, 0.2);
          border-radius: 8px;
          margin: 24px 16px 12px 16px; }
          .billing .vanpool-overview-page .container .other-information .maintenance-due .alert-icon img {
            height: 25px;
            width: 25px; }
          .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container {
            display: flex;
            flex-direction: row;
            padding: 0px 0px 0px 8px; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section {
              padding: 0px 10px; }
              .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section h3 {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #642801;
                margin: 0; }
              .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .due-on {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                letter-spacing: -0.25px;
                color: #642801;
                margin-top: 4px; }
              .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .last-completed {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #4A4A4A;
                margin-top: 4px; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .view-container .view-desktop {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 24px;
              display: none;
              align-items: center;
              text-align: right;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #642801; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .view-container .view-mobile {
              height: 30px;
              width: 30px; }
        .billing .vanpool-overview-page .container .other-information .roster-container {
          margin: 12px 24px 12px 24px; }
          .billing .vanpool-overview-page .container .other-information .roster-container .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .billing .vanpool-overview-page .container .other-information .roster-container .header .left {
              display: flex;
              justify-content: center;
              align-items: baseline; }
              .billing .vanpool-overview-page .container .other-information .roster-container .header .left .heading {
                font-family: 'Proxima Nova';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #4A4A4A;
                margin-right: 15px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .header .left .members {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #838383;
                font-weight: 400; }
                .billing .vanpool-overview-page .container .other-information .roster-container .header .left .members .count {
                  font-size: 18px; }
            .billing .vanpool-overview-page .container .other-information .roster-container .header .see-all {
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              /* 171.429% */
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #00A664;
              cursor: pointer; }
          .billing .vanpool-overview-page .container .other-information .roster-container .participants {
            display: flex;
            flex-direction: row;
            margin-top: 12px; }
            .billing .vanpool-overview-page .container .other-information .roster-container .participants .image {
              width: 36px;
              height: 36px;
              justify-content: center;
              align-items: center;
              border-radius: 100px;
              border: 1px solid #FFFFFF;
              margin-left: -5px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .participants .image img {
                width: 36px;
                height: 36px;
                border-radius: 100px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .participants .image .image-container {
                height: 36px;
                width: 36px;
                border-radius: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0px;
                cursor: default; }
                .billing .vanpool-overview-page .container .other-information .roster-container .participants .image .image-container div {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 18px;
                  /* 100% */
                  letter-spacing: -0.25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #FFFFFF;
                  text-transform: uppercase; }
        .billing .vanpool-overview-page .container .other-information .Collapsible {
          max-width: 350px;
          height: fit-content;
          margin: 0px 0px; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 24px;
            padding-right: 40px;
            width: 100%;
            height: 100px;
            cursor: pointer; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container:focus {
              outline: none;
              box-shadow: none; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container .heading {
              font-family: 'Proxima Nova';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              color: #4A4A4A; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container {
            width: auto; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .map {
              background: #D5D5D5;
              width: 300px;
              height: 300px;
              margin: 0px 24px 30px; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .map .google-map {
                height: 300px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs {
              border-bottom: solid 3px #EDEDED;
              display: flex;
              flex-direction: row;
              margin: 20px 24px;
              width: auto;
              height: 44px; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs .tab {
                padding: 10px 10px;
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                width: 50%;
                color: #838383; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs .active {
                color: #049b5c;
                background: #D4EDE1;
                border-radius: 4px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom: solid 3px #049b5c;
                margin-bottom: -3px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container {
              margin: 20px 24px;
              width: auto; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container:not(:last-child) {
                border-bottom: 1px solid #D5D5D5; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 10px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section {
                  display: flex;
                  flex-direction: row; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon {
                    width: 30px; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon img {
                      height: 60px;
                      width: 60px;
                      margin-left: -20px;
                      margin-top: -15px; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon .stopOrder {
                      width: 22px;
                      height: 22px;
                      background-color: #F96302;
                      border-radius: 50%;
                      color: #FFFFFF;
                      font-family: 'DINNextLTPro-Regular';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px;
                      letter-spacing: -0.25px;
                      text-align: center;
                      padding-top: 3px; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .address {
                    font-family: 'Proxima Nova';
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    /* 133.333% */
                    letter-spacing: -0.25px;
                    color: #4A4A4A; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .address .second-address {
                      font-family: 'DINNextLTPro-Regular';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 18px;
                      /* 128.571% */
                      letter-spacing: -0.25px;
                      color: #838383; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;
                  /* 128.571% */
                  letter-spacing: -0.25px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time .label {
                    color: #838383; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time .value {
                    color: #4A4A4A; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section {
                border-radius: 5px;
                background-color: #EDF4FC;
                padding-left: 10px;
                margin-left: 28px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section .icon img {
                  height: 20px;
                  width: 20px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section .count {
                  font-family: 'Proxima Nova';
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  /* 133.333% */
                  letter-spacing: -0.25px;
                  color: #1E3A5A;
                  margin-left: 5px; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .arrow {
            border-right: 3px solid;
            border-bottom: 3px solid;
            padding: 5px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .arrow.up {
              border-color: #00A664;
              transform: rotate(-135deg);
              -webkit-transform: rotate(-135deg); }
            .billing .vanpool-overview-page .container .other-information .Collapsible .arrow.down {
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              border-color: #00A664; }
        .billing .vanpool-overview-page .container .other-information .maintenance {
          flex-direction: row;
          margin: 16px 24px;
          background: #E5F6EF;
          padding: 16px;
          border-radius: 8px; }
          .billing .vanpool-overview-page .container .other-information .maintenance .link {
            display: flex;
            justify-content: space-between; }
            .billing .vanpool-overview-page .container .other-information .maintenance .link .title {
              display: flex; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title img {
                height: 24px;
                width: 24px;
                margin-right: 16px; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title .tracker .heading {
                font-family: 'Proxima Nova Bold';
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.25px;
                text-align: left;
                color: #004228;
                margin-bottom: 4px; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title .tracker .date {
                font-family: "DINNextLTPro-Regular";
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.25px;
                text-align: left;
                color: #4A4A4A; }
            .billing .vanpool-overview-page .container .other-information .maintenance .link .chevron-right {
              position: relative;
              left: 4px;
              height: 24px;
              width: 24px; }
        .billing .vanpool-overview-page .container .other-information .request-new-vehicle {
          display: flex;
          flex-direction: column;
          margin: 12px 24px 24px 24px; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading {
            display: flex;
            flex-direction: row; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading .check {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              position: relative;
              margin-top: 4px; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading .request-sent {
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0.5px;
              text-align: left;
              color: #4A4A4A; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .sent-msg {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            line-height: 18px; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-heading {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-text {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .submit-request {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #00A664;
            text-decoration: underline;
            width: max-content;
            padding-left: 0; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-around;
            -moz-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            -ms-flex-pack: space-around;
            position: fixed;
            z-index: 500;
            background: rgba(0, 0, 0, 0.2);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding-right: 10px; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup {
              position: fixed;
              z-index: 20;
              left: 0;
              right: 0;
              top: 14%;
              margin-left: auto;
              margin-right: 15px;
              border: 1px solid #D5D5D5;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              max-width: 95%;
              width: 875px;
              height: max-content; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup:after {
                position: fixed;
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.5);
                z-index: -2; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #FFF;
                z-index: -1;
                border-radius: 8px; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-heading {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.5px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 700;
                padding: 16px 12px;
                display: flex;
                justify-content: space-between; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent {
                padding: 24px 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .check-circle {
                  width: 64px;
                  height: 64px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .sub-heading {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 36px;
                  letter-spacing: -0.5px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .text {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content {
                padding: 24px 16px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .content-heading {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  bottom: 16px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul {
                  margin-top: 16px; }
                  .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul .selected {
                    background-color: #E5F6EF;
                    border-radius: 24px; }
                  .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li {
                    margin-bottom: 16px;
                    padding: 8px;
                    background-color: #FCFCFE;
                    border-radius: 24px; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input {
                      margin-right: 10px; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input[type="radio"] {
                      appearance: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      border-radius: 50%;
                      width: 20px;
                      height: 20px;
                      border: 2px solid #838383;
                      vertical-align: middle; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input[type="radio"]:checked {
                      border: 6px solid #00A664; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li label {
                      font-family: 'Proxima Nova';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 20px;
                      letter-spacing: -0.25px;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: antialiased; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input label {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 15.6px;
                  text-align: left; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input input {
                  width: 520px;
                  height: 56px;
                  border: 2px solid #EDEDED; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input input:focus {
                  border-color: #D4EDE1;
                  border-bottom: 2px solid #00A664;
                  box-shadow: none; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom {
                box-shadow: 0px -1px 1px 0px #00000040;
                padding: 24px 0;
                display: flex; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .cancel-button {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px;
                  border: 2px solid #00A664;
                  margin-right: 17px;
                  margin-left: 17px;
                  color: #00A664; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .submit {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom button[class="submit"]:disabled {
                  background-color: #838383;
                  color: #FFFFFF;
                  cursor: default; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom button[class="submit"]:enabled {
                  background-color: #00A664;
                  color: #FFFFFF; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .done {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px;
                  background-color: #00A664;
                  color: #FFFFFF;
                  margin-left: 112px; }
      .billing .vanpool-overview-page .container .environment-information {
        grid-area: environmentInfo;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-radius: 8px;
        width: 350px;
        height: fit-content;
        flex-grow: 1;
        margin-top: 25px;
        padding: 24px; }
        .billing .vanpool-overview-page .container .environment-information .heading {
          display: flex;
          align-items: center;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A; }
          .billing .vanpool-overview-page .container .environment-information .heading span {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            color: #838383;
            padding-left: 10px;
            padding-top: 3px; }
        .billing .vanpool-overview-page .container .environment-information .tabs {
          border-bottom: solid 3px #EDEDED;
          display: flex;
          flex-direction: row;
          margin-top: 20px; }
          .billing .vanpool-overview-page .container .environment-information .tabs .tab {
            padding: 10px 10px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            width: 10rem; }
          .billing .vanpool-overview-page .container .environment-information .tabs .active {
            color: #049b5c;
            background: #D4EDE1;
            border-radius: 4px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
            border-bottom: solid 3px #049b5c;
            margin-bottom: -3px; }
        .billing .vanpool-overview-page .container .environment-information .savings {
          margin-top: 24px; }
          .billing .vanpool-overview-page .container .environment-information .savings ul li {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px 3px; }
            .billing .vanpool-overview-page .container .environment-information .savings ul li .start {
              display: flex; }
              .billing .vanpool-overview-page .container .environment-information .savings ul li .start .icon img {
                width: 20px;
                height: 20px; }
              .billing .vanpool-overview-page .container .environment-information .savings ul li .start .description {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #838383;
                padding-left: 20px;
                padding-top: 5px; }
            .billing .vanpool-overview-page .container .environment-information .savings ul li .end {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #4A4A4A; }
              .billing .vanpool-overview-page .container .environment-information .savings ul li .end .sub {
                padding-left: 5px;
                font-family: 'Proxima Nova'; }
  .billing .vanpool-route-container .vanpool-route .google-map {
    margin-top: 0px !important;
    height: 250px; }
  .billing .vanpool-roster-container .vanpool-roster {
    background: #FFF;
    margin-top: 30px; }
  .billing .vanpool-vehicle {
    margin-top: 60px !important;
    padding-top: 30px !important; } }

@media only screen and (max-width: 1215px) and (min-width: 700px) {
  .billing .vanpool-overview-page {
    background: #E5E5E5;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap; }
    .billing .vanpool-overview-page .container {
      display: grid;
      grid-template-columns: 0.5fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 30px;
      background: #E5E5E5;
      padding: 0px 0px 50px 0px;
      grid-template-areas: "left-content otherInfo"; }
      .billing .vanpool-overview-page .container .left-container {
        grid-area: left-content; }
        .billing .vanpool-overview-page .container .left-container .vanpool-information {
          display: flex;
          flex-direction: column;
          width: 300px;
          min-height: max-content;
          flex-grow: 2;
          padding: 24px;
          background: #FFFFFF;
          border: 1px solid #D5D5D5;
          box-shadow: 0px 1px 1px #D5D5D5;
          border-radius: 8px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .alert {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            background-color: #00A664;
            border-radius: 4px;
            padding: 3px 12px;
            width: max-content;
            text-transform: capitalize;
            margin-bottom: 3px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .due {
            background-color: #F96302; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-name {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.5px;
            color: #4A4A4A;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            padding: 5px 0px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-type {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #4A4A4A; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-desc {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-top: 3px;
            color: #4A4A4A; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-image-container {
            display: flex;
            text-align: center;
            align-items: center;
            flex-direction: column; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-image-container img {
              height: 120px;
              width: 150px; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-image-container .image-opacity {
              opacity: 0.4; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .details .vanpool-license {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .details .vanpool-license .key {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 500;
              text-align: right;
              color: #838383; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .details .vanpool-license .value {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              color: #4A4A4A;
              padding-left: 10px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays {
            margin-top: 10px; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays .heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 400;
              color: #838383;
              margin-bottom: 6px; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays .oprational-days {
              font-family: 'DINNextLTPro-Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #4A4A4A; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays .oprational-days .day-btn {
                background: #D5D5D5;
                height: 22px;
                width: 28px;
                border-radius: 5px;
                text-transform: uppercase;
                padding-top: 6px;
                font-weight: 700;
                margin-right: 8px;
                text-align: center;
                cursor: default; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays .oprational-days .active {
                background: #D4EDE1; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-decoration-line: underline;
            text-transform: uppercase;
            color: #4A4A4A;
            margin-top: 15px;
            cursor: pointer; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc img {
              padding-right: 3px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-around;
            -moz-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            -ms-flex-pack: space-around;
            position: fixed;
            z-index: 500;
            background: rgba(0, 0, 0, 0.5);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal {
              width: 100%;
              max-width: 1024px;
              margin: 0 auto;
              background-color: #FFFFFF;
              -webkit-flex-grow: 1;
              -moz-flex-grow: 1;
              -ms-flex-grow: 1;
              flex-grow: 1;
              max-height: calc(100vh - 134px);
              height: max-content;
              padding: 10px;
              max-width: 736px;
              border-radius: 8px;
              border: 1px solid #D5D5D5;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              top: 125px;
              position: relative; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .header {
                height: 24px;
                margin-bottom: 15px;
                font-size: 26px;
                position: relative;
                margin-top: 24px;
                margin-left: 10px;
                display: flex;
                justify-content: space-between; }
                .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .header span {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 26px;
                  font-weight: 500;
                  line-height: 32px;
                  text-align: left; }
                .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .header .close svg {
                  position: absolute;
                  right: 0;
                  cursor: pointer;
                  color: #00A664;
                  font-size: 24px; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .body {
                overflow-x: hidden;
                overflow-y: auto;
                max-height: calc(100vh - 310px);
                margin-left: 40px;
                margin-bottom: 30px; }
                .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .body .pdf-loader {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 16px;
                  font-weight: 200;
                  line-height: 32px;
                  margin-left: 40%; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .footer {
                width: 204px;
                height: 41px;
                background-color: #00A664;
                padding: 8px 40px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                pointer-events: all;
                border-radius: 5px;
                margin-left: 35%; }
                .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .footer button {
                  font-family: 'Proxima Nova';
                  font-weight: 700;
                  color: #FFFFFF; }
        .billing .vanpool-overview-page .container .left-container .environment-information {
          display: flex;
          flex-direction: column;
          background: #FFFFFF;
          border: 1px solid #D5D5D5;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-radius: 8px;
          width: 300px;
          height: fit-content;
          flex-grow: 1;
          margin-top: 15px;
          padding: 24px; }
          .billing .vanpool-overview-page .container .left-container .environment-information .heading {
            display: flex;
            align-items: center;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
            .billing .vanpool-overview-page .container .left-container .environment-information .heading span {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              display: flex;
              align-items: center;
              color: #838383;
              padding-left: 10px;
              padding-top: 3px; }
          .billing .vanpool-overview-page .container .left-container .environment-information .tabs {
            border-bottom: solid 3px #EDEDED;
            display: flex;
            flex-direction: row;
            margin-top: 20px; }
            .billing .vanpool-overview-page .container .left-container .environment-information .tabs .tab {
              padding: 10px 10px;
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              width: 8rem; }
            .billing .vanpool-overview-page .container .left-container .environment-information .tabs .active {
              color: #049b5c;
              background: #D4EDE1;
              border-radius: 4px;
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom: solid 3px #049b5c;
              margin-bottom: -3px; }
          .billing .vanpool-overview-page .container .left-container .environment-information .savings {
            margin-top: 24px; }
            .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li {
              display: flex;
              justify-content: space-between;
              padding: 10px 0px 3px; }
              .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .start {
                display: flex; }
                .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .start .icon img {
                  width: 20px;
                  height: 20px; }
                .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .start .description {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  color: #838383;
                  padding-left: 20px;
                  padding-top: 5px; }
              .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .end {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
                color: #4A4A4A; }
                .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .end .sub {
                  padding-left: 5px;
                  font-family: 'Proxima Nova'; }
      .billing .vanpool-overview-page .container .other-information {
        grid-area: otherInfo;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-radius: 8px;
        height: fit-content;
        max-width: 530px;
        width: 400px; }
        .billing .vanpool-overview-page .container .other-information .content-border {
          border-bottom: 1px solid #D5D5D5;
          margin: 0px 24px; }
        .billing .vanpool-overview-page .container .other-information .maintenance-due {
          display: flex;
          flex-direction: row;
          padding: 16px;
          background: #FEEFE6;
          box-shadow: 0px 1px 0px rgba(213, 213, 213, 0.2);
          border-radius: 8px;
          margin: 24px 16px 12px 16px; }
          .billing .vanpool-overview-page .container .other-information .maintenance-due .alert-icon img {
            height: 30px;
            width: 30px; }
          .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container {
            display: flex;
            flex-direction: row;
            padding: 0px 14px;
            width: 100%;
            justify-content: space-between; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section h3 {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              color: #642801;
              margin: 0; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .due-on {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              letter-spacing: -0.25px;
              color: #642801;
              margin-top: 4px; }
              .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .due-on span {
                padding: 0px 3px; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .last-completed {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              display: flex;
              align-items: center;
              color: #4A4A4A;
              margin-top: 4px; }
              .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .last-completed span {
                padding: 0px 3px; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .view-container .view-desktop {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: right;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #642801; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .view-container .view-mobile {
              display: none; }
        .billing .vanpool-overview-page .container .other-information .roster-container {
          margin: 12px 24px 12px 24px; }
          .billing .vanpool-overview-page .container .other-information .roster-container .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .billing .vanpool-overview-page .container .other-information .roster-container .header .left {
              display: flex;
              justify-content: center;
              align-items: baseline; }
              .billing .vanpool-overview-page .container .other-information .roster-container .header .left .heading {
                font-family: 'Proxima Nova';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #4A4A4A;
                margin-right: 15px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .header .left .members {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #838383;
                font-weight: 400; }
                .billing .vanpool-overview-page .container .other-information .roster-container .header .left .members .count {
                  font-size: 18px; }
            .billing .vanpool-overview-page .container .other-information .roster-container .header .see-all {
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              /* 171.429% */
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #00A664;
              cursor: pointer; }
          .billing .vanpool-overview-page .container .other-information .roster-container .participants {
            display: flex;
            flex-direction: row;
            margin-top: 12px; }
            .billing .vanpool-overview-page .container .other-information .roster-container .participants .image {
              width: 50px;
              height: 50px;
              justify-content: center;
              align-items: center;
              border-radius: 100px;
              border: 1px solid #FFFFFF;
              margin-left: -5px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .participants .image img {
                width: 50px;
                height: 50px;
                border-radius: 100px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .participants .image .image-container {
                height: 50px;
                width: 50px;
                border-radius: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0px;
                cursor: default; }
                .billing .vanpool-overview-page .container .other-information .roster-container .participants .image .image-container div {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 18px;
                  /* 100% */
                  letter-spacing: -0.25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #FFFFFF;
                  text-transform: uppercase; }
        .billing .vanpool-overview-page .container .other-information .Collapsible {
          max-width: 550px;
          height: fit-content;
          margin: 10px 0px; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 24px;
            padding-right: 40px;
            width: 100%;
            height: 60px;
            cursor: pointer; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container:focus {
              outline: none;
              box-shadow: none; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container .heading {
              font-family: 'Proxima Nova';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              color: #4A4A4A; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container {
            width: 100%; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .map {
              background: #D5D5D5;
              width: 350px;
              height: 300px;
              margin: 0px 24px 30px; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .map .google-map {
                height: 300px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs {
              border-bottom: solid 3px #EDEDED;
              display: flex;
              flex-direction: row;
              margin: 20px 24px;
              width: auto;
              height: 44px; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs .tab {
                padding: 10px 10px;
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                width: 50%;
                color: #838383; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs .active {
                color: #049b5c;
                background: #D4EDE1;
                border-radius: 4px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom: solid 3px #049b5c;
                margin-bottom: -3px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container {
              margin: 20px 24px;
              width: auto; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container:not(:last-child) {
                border-bottom: 1px solid #D5D5D5; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 10px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section {
                  display: flex;
                  flex-direction: row; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon {
                    width: 30px; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon img {
                      height: 60px;
                      width: 60px;
                      margin-left: -20px;
                      margin-top: -15px; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon .stopOrder {
                      width: 22px;
                      height: 22px;
                      background-color: #F96302;
                      border-radius: 50%;
                      color: #FFFFFF;
                      font-family: 'DINNextLTPro-Regular';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px;
                      letter-spacing: -0.25px;
                      text-align: center;
                      padding-top: 3px; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .address {
                    font-family: 'Proxima Nova';
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    /* 133.333% */
                    letter-spacing: -0.25px;
                    color: #4A4A4A; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .address .second-address {
                      font-family: 'DINNextLTPro-Regular';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 18px;
                      /* 128.571% */
                      letter-spacing: -0.25px;
                      color: #838383; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;
                  /* 128.571% */
                  letter-spacing: -0.25px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time .label {
                    color: #838383; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time .value {
                    color: #4A4A4A; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section {
                border-radius: 5px;
                background-color: #EDF4FC;
                padding-left: 10px;
                margin-left: 28px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section .icon img {
                  height: 20px;
                  width: 20px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section .count {
                  font-family: 'Proxima Nova';
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  /* 133.333% */
                  letter-spacing: -0.25px;
                  color: #1E3A5A;
                  margin-left: 5px; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .arrow {
            border-right: 3px solid;
            border-bottom: 3px solid;
            padding: 5px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .arrow.up {
              border-color: #00A664;
              transform: rotate(-135deg);
              -webkit-transform: rotate(-135deg); }
            .billing .vanpool-overview-page .container .other-information .Collapsible .arrow.down {
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              border-color: #00A664; }
        .billing .vanpool-overview-page .container .other-information .maintenance {
          flex-direction: row;
          margin: 16px 24px;
          background: #E5F6EF;
          padding: 16px;
          border-radius: 8px; }
          .billing .vanpool-overview-page .container .other-information .maintenance .link {
            display: flex;
            justify-content: space-between; }
            .billing .vanpool-overview-page .container .other-information .maintenance .link .title {
              display: flex; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title img {
                height: 24px;
                width: 24px;
                margin-right: 16px; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title .tracker .heading {
                font-family: 'Proxima Nova Bold';
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.25px;
                text-align: left;
                color: #004228;
                margin-bottom: 4px; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title .tracker .date {
                font-family: "DINNextLTPro-Regular";
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.25px;
                text-align: left;
                color: #4A4A4A; }
            .billing .vanpool-overview-page .container .other-information .maintenance .link .chevron-right {
              position: relative;
              left: 4px;
              height: 24px;
              width: 24px; }
        .billing .vanpool-overview-page .container .other-information .request-new-vehicle {
          display: flex;
          flex-direction: column;
          margin: 12px 24px 24px 24px; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading {
            display: flex;
            flex-direction: row; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading .check {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              position: relative;
              margin-top: 4px; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading .request-sent {
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0.5px;
              text-align: left;
              color: #4A4A4A; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .sent-msg {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            line-height: 18px; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-heading {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-text {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .submit-request {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #00A664;
            text-decoration: underline;
            width: max-content;
            padding-left: 0; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-around;
            -moz-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            -ms-flex-pack: space-around;
            position: fixed;
            z-index: 500;
            background: rgba(0, 0, 0, 0.2);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup {
              position: fixed;
              z-index: 20;
              left: 0;
              right: 0;
              top: 14%;
              margin-left: auto;
              margin-right: 28px;
              border: 1px solid #D5D5D5;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              max-width: 95%;
              width: 875px;
              height: max-content; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup:after {
                position: fixed;
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.5);
                z-index: -2; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #FFF;
                z-index: -1;
                border-radius: 8px; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-heading {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.5px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 700;
                padding: 16px 24px;
                display: flex;
                justify-content: space-between; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent {
                padding: 24px 60px;
                display: flex;
                flex-direction: column;
                align-items: center; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .check-circle {
                  width: 64px;
                  height: 64px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .sub-heading {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 36px;
                  letter-spacing: -0.5px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .text {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content {
                padding: 24px 171px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .content-heading {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  bottom: 16px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul {
                  margin-top: 16px; }
                  .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul .selected {
                    background-color: #E5F6EF;
                    border-radius: 24px; }
                  .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li {
                    margin-bottom: 16px;
                    padding: 8px;
                    background-color: #FCFCFE;
                    border-radius: 24px; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input {
                      margin-right: 10px; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input[type="radio"] {
                      appearance: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      border-radius: 50%;
                      width: 20px;
                      height: 20px;
                      border: 2px solid #838383;
                      vertical-align: middle; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input[type="radio"]:checked {
                      border: 6px solid #00A664; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li label {
                      font-family: 'Proxima Nova';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 20px;
                      letter-spacing: -0.25px;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: antialiased; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input label {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 15.6px;
                  text-align: left; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input input {
                  width: 520px;
                  height: 56px;
                  border: 2px solid #EDEDED; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input input:focus {
                  border-color: #D4EDE1;
                  border-bottom: 2px solid #00A664;
                  box-shadow: none; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom {
                box-shadow: 0px -1px 1px 0px #00000040;
                padding: 24px 0; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .cancel-button {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px;
                  border: 2px solid #00A664;
                  margin-left: 241px;
                  margin-right: 17px;
                  color: #00A664; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .submit {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom button[class="submit"]:disabled {
                  background-color: #838383;
                  color: #FFFFFF;
                  cursor: default; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom button[class="submit"]:enabled {
                  background-color: #00A664;
                  color: #FFFFFF; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .done {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px;
                  background-color: #00A664;
                  color: #FFFFFF;
                  margin-left: 280px; }
  .billing .vanpool-roster-container .vanpool-roster {
    margin-top: 30px;
    margin-bottom: 50px; }
  .billing .vanpool-vehicle {
    padding: 20px;
    margin-top: 60px; } }

@media (min-width: 1030px) {
  .billing .vanpool-overview-page {
    background: #E5E5E5;
    padding-top: 160px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap; }
    .billing .vanpool-overview-page .container {
      display: grid;
      grid-template-columns: 0.5fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 30px;
      background: #E5E5E5;
      padding: 0px 0px 50px 0px;
      grid-template-areas: "left-content otherInfo"; }
      .billing .vanpool-overview-page .container .left-container {
        grid-area: left-content; }
        .billing .vanpool-overview-page .container .left-container .vanpool-information {
          display: flex;
          flex-direction: column;
          width: 100%;
          min-height: max-content;
          flex-grow: 2;
          padding: 24px;
          background: #FFFFFF;
          border: 1px solid #D5D5D5;
          box-shadow: 0px 1px 1px #D5D5D5;
          border-radius: 8px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .alert {
            font-family: 'DINNextLTPro-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            background-color: #00A664;
            border-radius: 4px;
            padding: 3px 12px;
            width: max-content;
            text-transform: capitalize;
            margin-bottom: 3px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .due {
            background-color: #F96302; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-name {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.5px;
            color: #4A4A4A;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            display: flex;
            align-items: center;
            padding: 5px 0px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-type {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #4A4A4A; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-desc {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-top: 3px;
            color: #4A4A4A; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-image-container {
            display: flex;
            text-align: center;
            align-items: center;
            flex-direction: column; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-image-container img {
              height: 120px;
              width: 150px; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .vanpool-image-container .image-opacity {
              opacity: 0.4; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .details .vanpool-license {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .details .vanpool-license .key {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 500;
              text-align: right;
              color: #838383; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .details .vanpool-license .value {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              color: #4A4A4A;
              padding-left: 10px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays {
            margin-top: 10px; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays .heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 400;
              color: #838383;
              margin-bottom: 6px; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays .oprational-days {
              font-family: 'DINNextLTPro-Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #4A4A4A; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays .oprational-days .day-btn {
                background: #D5D5D5;
                height: 22px;
                width: 28px;
                border-radius: 5px;
                text-transform: uppercase;
                padding-top: 6px;
                font-weight: 700;
                margin-right: 8px;
                text-align: center;
                cursor: default; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .commuteDays .oprational-days .active {
                background: #D4EDE1; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-decoration-line: underline;
            text-transform: uppercase;
            color: #4A4A4A;
            margin-top: 15px;
            cursor: pointer; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc img {
              padding-right: 3px; }
          .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-around;
            -moz-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            -ms-flex-pack: space-around;
            position: fixed;
            z-index: 500;
            background: rgba(0, 0, 0, 0.5);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0; }
            .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal {
              width: 100%;
              max-width: 1024px;
              margin: 0 auto;
              background-color: #FFFFFF;
              -webkit-flex-grow: 1;
              -moz-flex-grow: 1;
              -ms-flex-grow: 1;
              flex-grow: 1;
              max-height: calc(100vh - 134px);
              height: max-content;
              padding: 10px;
              max-width: 800px;
              border-radius: 8px;
              border: 1px solid #D5D5D5;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              bottom: 40px;
              position: relative;
              top: 125px; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .header {
                height: 24px;
                margin-bottom: 15px;
                font-size: 26px;
                position: relative;
                margin-top: 30px;
                margin-left: 40px; }
                .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .header span {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 26px;
                  font-weight: 500;
                  line-height: 32px;
                  text-align: left; }
                .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .header .close svg {
                  position: absolute;
                  right: 0;
                  cursor: pointer;
                  color: #00A664;
                  font-size: 24px; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .body {
                overflow-x: hidden;
                overflow-y: auto;
                max-height: calc(100vh - 310px);
                margin-left: 40px;
                margin-bottom: 30px; }
                .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .body .pdf-loader {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 16px;
                  font-weight: 200;
                  line-height: 32px;
                  margin-left: 40%; }
              .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .footer {
                width: 204px;
                height: 41px;
                background-color: #00A664;
                padding: 8px 40px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                pointer-events: all;
                border-radius: 5px;
                margin-left: 35%; }
                .billing .vanpool-overview-page .container .left-container .vanpool-information .vcc-modal-container .modal .footer button {
                  font-family: 'Proxima Nova';
                  font-weight: 700;
                  color: #FFFFFF; }
        .billing .vanpool-overview-page .container .left-container .environment-information {
          display: flex;
          flex-direction: column;
          background: #FFFFFF;
          border: 1px solid #D5D5D5;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-radius: 8px;
          width: 100%;
          height: fit-content;
          flex-grow: 1;
          margin-top: 15px;
          padding: 24px; }
          .billing .vanpool-overview-page .container .left-container .environment-information .heading {
            display: flex;
            align-items: center;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.5px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
            .billing .vanpool-overview-page .container .left-container .environment-information .heading span {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              display: flex;
              align-items: center;
              color: #838383;
              padding-left: 10px;
              padding-top: 3px; }
          .billing .vanpool-overview-page .container .left-container .environment-information .tabs {
            border-bottom: solid 3px #EDEDED;
            display: flex;
            flex-direction: row;
            margin-top: 20px; }
            .billing .vanpool-overview-page .container .left-container .environment-information .tabs .tab {
              padding: 10px 10px;
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              width: 8rem; }
            .billing .vanpool-overview-page .container .left-container .environment-information .tabs .active {
              color: #049b5c;
              background: #D4EDE1;
              border-radius: 4px;
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom: solid 3px #049b5c;
              margin-bottom: -3px; }
          .billing .vanpool-overview-page .container .left-container .environment-information .savings {
            margin-top: 24px; }
            .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li {
              display: flex;
              justify-content: space-between;
              padding: 10px 0px 3px; }
              .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .start {
                display: flex; }
                .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .start .icon img {
                  width: 20px;
                  height: 20px; }
                .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .start .description {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  color: #838383;
                  padding-left: 20px;
                  padding-top: 5px; }
              .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .end {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
                color: #4A4A4A; }
                .billing .vanpool-overview-page .container .left-container .environment-information .savings ul li .end .sub {
                  padding-left: 5px;
                  font-family: 'Proxima Nova'; }
      .billing .vanpool-overview-page .container .other-information {
        grid-area: otherInfo;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-radius: 8px;
        height: fit-content;
        max-width: 550px;
        width: 550px; }
        .billing .vanpool-overview-page .container .other-information .content-border {
          border-bottom: 1px solid #D5D5D5;
          margin: 0px 24px; }
        .billing .vanpool-overview-page .container .other-information .maintenance-due {
          display: flex;
          flex-direction: row;
          padding: 20px;
          background: #FEEFE6;
          box-shadow: 0px 1px 0px rgba(213, 213, 213, 0.2);
          border-radius: 8px;
          margin: 24px 30px 12px 20px; }
          .billing .vanpool-overview-page .container .other-information .maintenance-due .alert-icon img {
            height: 40px;
            width: 40px; }
          .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container {
            display: flex;
            flex-direction: row;
            padding: 0px 20px;
            width: 100%;
            justify-content: space-between; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section h3 {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              color: #642801;
              margin: 0; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .due-on {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #642801;
              margin-top: 4px; }
              .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .due-on span {
                padding: 0px 3px; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .last-completed {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              display: flex;
              align-items: center;
              color: #4A4A4A;
              margin-top: 4px; }
              .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .first-section .last-completed span {
                padding: 0px 3px; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .view-container .view-desktop {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: right;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #642801; }
            .billing .vanpool-overview-page .container .other-information .maintenance-due .middle-container .view-container .view-mobile {
              display: none; }
        .billing .vanpool-overview-page .container .other-information .roster-container {
          display: flex;
          flex-direction: column;
          margin: 12px 24px 12px 24px; }
          .billing .vanpool-overview-page .container .other-information .roster-container .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .billing .vanpool-overview-page .container .other-information .roster-container .header .left {
              display: flex;
              justify-content: center;
              align-items: baseline; }
              .billing .vanpool-overview-page .container .other-information .roster-container .header .left .heading {
                font-family: 'Proxima Nova';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 600;
                color: #4A4A4A;
                margin-right: 15px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .header .left .members {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #838383;
                font-weight: 400; }
                .billing .vanpool-overview-page .container .other-information .roster-container .header .left .members .count {
                  font-size: 18px; }
            .billing .vanpool-overview-page .container .other-information .roster-container .header .see-all {
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              /* 171.429% */
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #00A664;
              cursor: pointer; }
          .billing .vanpool-overview-page .container .other-information .roster-container .participants {
            display: flex;
            flex-direction: row;
            margin-top: 12px; }
            .billing .vanpool-overview-page .container .other-information .roster-container .participants .image {
              width: 50px;
              height: 50px;
              justify-content: center;
              align-items: center;
              border-radius: 100px;
              border: 1px solid #FFFFFF;
              margin-left: -5px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .participants .image img {
                width: 50px;
                height: 50px;
                border-radius: 100px; }
              .billing .vanpool-overview-page .container .other-information .roster-container .participants .image .image-container {
                height: 50px;
                width: 50px;
                border-radius: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0px;
                cursor: default; }
                .billing .vanpool-overview-page .container .other-information .roster-container .participants .image .image-container div {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 18px;
                  /* 100% */
                  letter-spacing: -0.25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #FFFFFF;
                  text-transform: uppercase; }
        .billing .vanpool-overview-page .container .other-information .Collapsible {
          max-width: 550px;
          height: fit-content;
          margin: 10px 0px; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 24px;
            padding-right: 40px;
            width: 100%;
            height: 60px;
            cursor: pointer; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container:focus {
              outline: none;
              box-shadow: none; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-heading-container .heading {
              font-family: 'Proxima Nova';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              color: #4A4A4A; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .arrow {
            border-right: 3px solid;
            border-bottom: 3px solid;
            padding: 5px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .arrow.up {
              border-color: #00A664;
              transform: rotate(-135deg);
              -webkit-transform: rotate(-135deg); }
            .billing .vanpool-overview-page .container .other-information .Collapsible .arrow.down {
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              border-color: #00A664; }
          .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container {
            width: 500px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .map {
              background: #D5D5D5;
              width: 480px;
              height: 300px;
              margin: 0px 24px 30px; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .map .google-map {
                height: 300px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs {
              border-bottom: solid 3px #EDEDED;
              display: flex;
              flex-direction: row;
              margin: 20px 24px;
              width: 100%;
              height: 44px; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs .tab {
                padding: 10px 10px;
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                width: 50%;
                color: #838383; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tabs .active {
                color: #049b5c;
                background: #D4EDE1;
                border-radius: 4px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom: solid 3px #049b5c;
                margin-bottom: -3px; }
            .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container {
              margin: 20px 24px;
              width: 100%; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container:not(:last-child) {
                border-bottom: 1px solid #D5D5D5; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 10px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section {
                  display: flex;
                  flex-direction: row; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon {
                    width: 30px; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon img {
                      height: 60px;
                      width: 60px;
                      margin-left: -20px;
                      margin-top: -15px; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .icon .stopOrder {
                      width: 22px;
                      height: 22px;
                      background-color: #F96302;
                      border-radius: 50%;
                      color: #FFFFFF;
                      font-family: 'DINNextLTPro-Regular';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px;
                      letter-spacing: -0.25px;
                      text-align: center;
                      padding-top: 3px; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .address {
                    font-family: 'Proxima Nova';
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    /* 133.333% */
                    letter-spacing: -0.25px;
                    color: #4A4A4A; }
                    .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .left-section .address .second-address {
                      font-family: 'DINNextLTPro-Regular';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 18px;
                      /* 128.571% */
                      letter-spacing: -0.25px;
                      color: #838383; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;
                  /* 128.571% */
                  letter-spacing: -0.25px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time .label {
                    color: #838383; }
                  .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .first-section .right-section .time .value {
                    color: #4A4A4A; }
              .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section {
                border-radius: 5px;
                background-color: #EDF4FC;
                padding-left: 10px;
                margin-left: 28px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section .icon img {
                  height: 20px;
                  width: 20px; }
                .billing .vanpool-overview-page .container .other-information .Collapsible .collapsible-content-container .stops .tab-container .stops-container .second-section .count {
                  font-family: 'Proxima Nova';
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  /* 133.333% */
                  letter-spacing: -0.25px;
                  color: #1E3A5A;
                  margin-left: 5px; }
        .billing .vanpool-overview-page .container .other-information .maintenance {
          flex-direction: row;
          margin: 16px 24px;
          background: #E5F6EF;
          padding: 16px;
          border-radius: 8px; }
          .billing .vanpool-overview-page .container .other-information .maintenance .link {
            display: flex;
            justify-content: space-between; }
            .billing .vanpool-overview-page .container .other-information .maintenance .link .title {
              display: flex; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title img {
                height: 24px;
                width: 24px;
                margin-right: 16px; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title .tracker .heading {
                font-family: 'Proxima Nova Bold';
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.25px;
                text-align: left;
                color: #004228;
                margin-bottom: 4px; }
              .billing .vanpool-overview-page .container .other-information .maintenance .link .title .tracker .date {
                font-family: "DINNextLTPro-Regular";
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.25px;
                text-align: left;
                color: #4A4A4A; }
            .billing .vanpool-overview-page .container .other-information .maintenance .link .chevron-right {
              position: relative;
              left: 4px;
              height: 24px;
              width: 24px; }
        .billing .vanpool-overview-page .container .other-information .request-new-vehicle {
          display: flex;
          flex-direction: column;
          margin: 12px 24px 24px 24px; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading {
            display: flex;
            flex-direction: row; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading .check {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              position: relative;
              margin-top: 4px; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .heading .request-sent {
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0.5px;
              text-align: left;
              color: #4A4A4A; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .data-submitted .sent-msg {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            line-height: 18px; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-heading {
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-text {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .submit-request {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #00A664;
            text-decoration: underline;
            width: max-content;
            padding-left: 0; }
          .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-around;
            -moz-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            -ms-flex-pack: space-around;
            position: fixed;
            z-index: 500;
            background: rgba(0, 0, 0, 0.2);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding-right: 10px; }
            .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup {
              position: fixed;
              z-index: 20;
              left: 0;
              right: 0;
              top: 14%;
              margin-left: auto;
              margin-right: auto;
              border: 1px solid #D5D5D5;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              max-width: 95%;
              width: 875px;
              height: max-content; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup:after {
                position: fixed;
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.5);
                z-index: -2; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #FFF;
                z-index: -1;
                border-radius: 8px; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-heading {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.5px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 700;
                padding: 16px 24px;
                display: flex;
                justify-content: space-between; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent {
                padding: 24px 130px;
                display: flex;
                flex-direction: column;
                align-items: center; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .check-circle {
                  width: 64px;
                  height: 64px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .sub-heading {
                  font-family: 'DINNextLTPro-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 36px;
                  letter-spacing: -0.5px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .request-sent .text {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content {
                padding: 24px 171px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .content-heading {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  bottom: 16px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul {
                  margin-top: 16px; }
                  .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul .selected {
                    background-color: #E5F6EF;
                    border-radius: 24px; }
                  .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li {
                    margin-bottom: 16px;
                    padding: 8px;
                    background-color: #FCFCFE;
                    border-radius: 24px; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input {
                      margin-right: 10px; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input[type="radio"] {
                      appearance: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      border-radius: 50%;
                      width: 20px;
                      height: 20px;
                      border: 2px solid #838383;
                      vertical-align: middle; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li input[type="radio"]:checked {
                      border: 6px solid #00A664; }
                    .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content ul li label {
                      font-family: 'Proxima Nova';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 20px;
                      letter-spacing: -0.25px;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: antialiased; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input label {
                  font-family: 'DINNextLTPro-Regular';
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 15.6px;
                  text-align: left; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input input {
                  width: 520px;
                  height: 56px;
                  border: 2px solid #EDEDED; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .popup-content .text-input input:focus {
                  border-color: #D4EDE1;
                  border-bottom: 2px solid #00A664;
                  box-shadow: none; }
              .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom {
                box-shadow: 0px -1px 1px 0px #00000040;
                padding: 24px 0; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .cancel-button {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px;
                  border: 2px solid #00A664;
                  margin-left: 241px;
                  margin-right: 17px;
                  color: #00A664; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .submit {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom button[class="submit"]:disabled {
                  background-color: #838383;
                  color: #FFFFFF;
                  cursor: default; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom button[class="submit"]:enabled {
                  background-color: #00A664;
                  color: #FFFFFF; }
                .billing .vanpool-overview-page .container .other-information .request-new-vehicle .request-modal-container .popup .bottom .done {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: uppercase;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  width: 163px;
                  height: 40px;
                  border-radius: 5px;
                  background-color: #00A664;
                  color: #FFFFFF;
                  margin-left: 353px; }
  .billing .vanpool-roster-container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto; }
    .billing .vanpool-roster-container .vanpool-roster {
      background: #FFF;
      margin-top: 170px;
      margin-bottom: 50px;
      border: 1px solid #D5D5D5;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px; }
  .billing .vanpool-route-container .vanpool-route {
    padding-top: 60px;
    margin-bottom: 30px; }
    .billing .vanpool-route-container .vanpool-route .vanpool-route-details {
      border: 1px solid #D5D5D5;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px; }
    .billing .vanpool-route-container .vanpool-route .google-map {
      border: 1px solid #D5D5D5;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
  .billing .vanpool-vehicle {
    margin-top: 170px;
    padding: 0;
    margin-bottom: 50px;
    border: 1px solid #D5D5D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px; }
    .billing .vanpool-vehicle .vehicle-details {
      padding-top: 40px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.edit-license-page {
  margin-bottom: 70px; }
  .edit-license-page .field .license {
    width: 364px; }
  .edit-license-page .field .label.spread {
    display: flex;
    justify-content: space-between; }
  .edit-license-page .field label {
    font-size: 13px !important; }
  .edit-license-page .cannot-confirm-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 5px;
    padding: 40px 25px;
    margin-top: 25px; }
    .edit-license-page .cannot-confirm-dialog .dialog-heading {
      margin-top: 25px;
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 600;
      text-align: center;
      color: #130C0E; }
    .edit-license-page .cannot-confirm-dialog .second-heading {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 600;
      color: #4A4A4A;
      margin-top: 24px;
      text-align: center; }
    .edit-license-page .cannot-confirm-dialog .notes {
      margin-top: 10px;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 400;
      color: #838383; }
    .edit-license-page .cannot-confirm-dialog .second-notes {
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #636363;
      margin-top: 45px; }
    .edit-license-page .cannot-confirm-dialog .callable {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      text-align: center;
      color: #00A664; }
  .edit-license-page .small-heading {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #4A4A4A; }
  .edit-license-page .question-text {
    font-family: 'Proxima Nova';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-weight: 600;
    color: #4A4A4A; }
  .edit-license-page .state-date {
    display: flex;
    flex-wrap: wrap;
    max-width: 364px;
    justify-content: space-between;
    row-gap: 20px; }
    .edit-license-page .state-date .error-message {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #F96302;
      margin-top: 0px; }
    .edit-license-page .state-date .date-input {
      width: 165px !important;
      min-width: unset !important; }
  .edit-license-page .field input {
    min-width: 364px; }
  .edit-license-page .flexibility-container {
    width: 165px !important; }
    .edit-license-page .flexibility-container .dropdown-input {
      min-width: unset;
      width: 100%; }
    .edit-license-page .flexibility-container .dropdown-container .dropdown-list {
      height: 200px; }
    .edit-license-page .flexibility-container .dropdown-container .list-item.focussed {
      background-color: #00A664;
      color: #FFFFFF; }
  .edit-license-page .licence-picker-container .label {
    display: flex; }
  .edit-license-page .licence-picker-container .react-calendar {
    border-radius: 8px; }
    .edit-license-page .licence-picker-container .react-calendar .react-calendar__navigation {
      background: #D4EDE1;
      border-radius: 8px 8px 0px 0px; }
      .edit-license-page .licence-picker-container .react-calendar .react-calendar__navigation button:hover {
        background: #00A664; }
    .edit-license-page .licence-picker-container .react-calendar .react-calendar__viewContainer .react-calendar__month-view__days button {
      height: 36px;
      width: 36px;
      padding: 0 !important; }
    .edit-license-page .licence-picker-container .react-calendar .react-calendar__viewContainer .react-calendar__tile:hover {
      background: #D4EDE1; }
    .edit-license-page .licence-picker-container .react-calendar .react-calendar__viewContainer .react-calendar__tile--hasActive {
      background-color: #00A664; }
    .edit-license-page .licence-picker-container .react-calendar .react-calendar__viewContainer .react-calendar__tile--active {
      background: unset !important; }
      .edit-license-page .licence-picker-container .react-calendar .react-calendar__viewContainer .react-calendar__tile--active abbr {
        background-color: #00A664;
        border-radius: 20px;
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto; }
    .edit-license-page .licence-picker-container .react-calendar .react-calendar__viewContainer .react-calendar__month-view__days__day--weekend {
      color: unset; }
  .edit-license-page .licence-picker-container .select-container {
    width: unset !important; }
    .edit-license-page .licence-picker-container .select-container .select-header {
      width: 165px; }
      .edit-license-page .licence-picker-container .select-container .select-header img {
        height: 20px;
        width: 20px; }
    .edit-license-page .licence-picker-container .select-container .dropdown-container {
      width: 300px;
      position: absolute;
      z-index: 100; }
  .edit-license-page .questions-section {
    margin-top: 10px; }
    .edit-license-page .questions-section .question {
      margin-top: 20px; }
  .edit-license-page .add-license-container {
    background: #EDEDED;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    max-width: 650px;
    padding: 0px 25px 10px 15px;
    margin-top: 20px; }
    .edit-license-page .add-license-container .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      color: #4A4A4A; }
    .edit-license-page .add-license-container .comment {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 400;
      color: #4A4A4A;
      margin-top: 15px; }
    .edit-license-page .add-license-container .condition {
      margin-top: 24px; }
      .edit-license-page .add-license-container .condition .text {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
    .edit-license-page .add-license-container .license-info {
      margin-bottom: 25px; }
    .edit-license-page .add-license-container .charges-header {
      border-top: 1px solid #D5D5D5;
      padding-top: 15px; }
      .edit-license-page .add-license-container .charges-header img {
        float: right;
        height: 24px; }
    .edit-license-page .add-license-container .field {
      margin-top: 24px;
      display: flex;
      flex-flow: column; }
      .edit-license-page .add-license-container .field label {
        font-family: 'DINNextLTPro-Medium';
        font-style: normal;
        font-size: 13px;
        line-height: 25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .edit-license-page .add-license-container .field input {
        max-width: 300px;
        min-width: 165px;
        background-color: #FFFFFF; }
    .edit-license-page .add-license-container .buttons img {
      margin-right: 6px; }
    .edit-license-page .add-license-container .buttons .add-license {
      font-family: 'Proxima Nova Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #00A664;
      text-transform: uppercase; }

@media only screen and (min-width: 1250px) {
  .edit-license-page {
    min-width: 1000px; }
    .edit-license-page .name {
      display: flex;
      flex-wrap: wrap;
      gap: 20px; } }

@media only screen and (max-width: 1249px) and (min-width: 736px) {
  .edit-license-page {
    min-width: 90%; }
    .edit-license-page .callable {
      text-decoration: underline;
      font-weight: 700 !important; }
      .edit-license-page .callable a {
        color: inherit; }
    .edit-license-page .name .field:nth-child(2) {
      margin-top: 20px; } }

@media only screen and (max-width: 735px) {
  .edit-license-page {
    min-width: 90%; }
    .edit-license-page .callable {
      text-decoration: underline;
      font-weight: 700 !important; }
      .edit-license-page .callable a {
        color: inherit; }
    .edit-license-page .licence-picker-container .label {
      width: 165px; }
    .edit-license-page .small-heading {
      text-align: center; }
    .edit-license-page .name .field:nth-child(2) {
      margin-top: 20px; }
    .edit-license-page .add-license-container {
      padding: 0px 20px 15px 15px;
      margin-top: 25px; }
      .edit-license-page .add-license-container .heading {
        text-align: left; }
      .edit-license-page .add-license-container .comment {
        text-align: left; }
      .edit-license-page .add-license-container .charges-header img {
        height: 16px; }
      .edit-license-page .add-license-container .card-input-container {
        padding: 8px; }
        .edit-license-page .add-license-container .card-input-container .error-message {
          font-size: 14px !important; } }

@media only screen and (max-width: 580px) {
  .licence-picker-container .dropdown-container {
    right: calc(100% - 400px); } }

@media only screen and (max-width: 400px) {
  .edit-license-page .content-container {
    max-width: 400px;
    overflow-x: scroll; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.apply-to-drive-page {
  height: fit-content;
  margin-bottom: 50px; }
  .apply-to-drive-page .popup {
    display: none;
    position: fixed;
    background: #FFF;
    z-index: 20;
    left: 0;
    right: 0;
    top: 20%;
    margin-left: auto;
    margin-right: auto;
    width: 375px;
    border-radius: 5px 5px 0px 0px; }
    .apply-to-drive-page .popup:after {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -2;
      border-radius: 5px 5px 0px 0px; }
    .apply-to-drive-page .popup:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #FFF;
      z-index: -1;
      border-radius: 5px 5px 0px 0px; }
    .apply-to-drive-page .popup.show {
      display: block; }
    .apply-to-drive-page .popup.hide {
      display: none; }
  .apply-to-drive-page .apply-to-drive .content-container {
    display: flex;
    flex-direction: column;
    padding: 35px 30px !important; }
    .apply-to-drive-page .apply-to-drive .content-container hr {
      border: 0;
      border-top: 1px solid #D5D5D5;
      width: 100%; }
    .apply-to-drive-page .apply-to-drive .content-container .thanks-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .apply-to-drive-page .apply-to-drive .content-container .thanks-content img {
        width: 64px;
        margin-bottom: 20px; }
      .apply-to-drive-page .apply-to-drive .content-container .thanks-content .thanks-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
    .apply-to-drive-page .apply-to-drive .content-container .plan-card {
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      margin: auto; }
      .apply-to-drive-page .apply-to-drive .content-container .plan-card .plan-content .amount .price-group {
        display: flex; }
      .apply-to-drive-page .apply-to-drive .content-container .plan-card .badge {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        padding: 2px 12px;
        height: 20px;
        border-radius: 4px;
        color: #FFFFFF;
        font-weight: 700; }
        .apply-to-drive-page .apply-to-drive .content-container .plan-card .badge.primary {
          background: #4A90E2; }
      .apply-to-drive-page .apply-to-drive .content-container .plan-card .plan {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #00A664;
        text-align: center;
        margin-top: 10px; }
      .apply-to-drive-page .apply-to-drive .content-container .plan-card .plan-content {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .apply-to-drive-page .apply-to-drive .content-container .plan-card .rate-badge {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700;
        color: #0F8952; }
        .apply-to-drive-page .apply-to-drive .content-container .plan-card .rate-badge img {
          height: 16px;
          margin-right: 5px; }
      .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        color: #4A4A4A;
        display: flex;
        margin-top: 8px; }
        .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount .price {
          font-size: 40px;
          line-height: 40px; }
        .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount .dollar,
        .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount .decimal {
          font-size: 20px;
          line-height: 20px; }
      .apply-to-drive-page .apply-to-drive .content-container .plan-card .per-month {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #4A4A4A; }
    .apply-to-drive-page .apply-to-drive .content-container .van-info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
      padding-bottom: 25px;
      border-bottom: 1px solid #EDEDED; }
      .apply-to-drive-page .apply-to-drive .content-container .van-info .section {
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin-right: 10pxs; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section.first {
          flex-direction: column; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .top {
          display: flex;
          justify-content: space-between; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .left {
          margin-top: 15px; }
          .apply-to-drive-page .apply-to-drive .content-container .van-info .section .left .van-name {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A; }
          .apply-to-drive-page .apply-to-drive .content-container .van-info .section .left .van-type {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #838383;
            margin-top: 4px; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .van-route-details > .route-address > .origin-address > .start-address,
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .van-route-details > .route-address > .destination-address > .end-address {
          font-size: 16px !important; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .van-route-details > .route-address > .origin-address > .start-timings,
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .van-route-details > .route-address > .destination-address > .end-timings {
          font-size: 14px !important; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .van-route-details {
          margin-right: 5px !important; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .right .van-image {
          height: 100px; }
    .apply-to-drive-page .apply-to-drive .content-container .commuteDays-heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 400;
      color: #838383; }
    .apply-to-drive-page .apply-to-drive .content-container .commute-days {
      display: flex;
      gap: 5px;
      margin-top: 8px; }
      .apply-to-drive-page .apply-to-drive .content-container .commute-days .block {
        height: 26px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #D5D5D5;
        border-radius: 4px;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.25px;
        color: #4A4A4A;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
    .apply-to-drive-page .apply-to-drive .content-container .blue-notes {
      background: #EDF4FC;
      box-shadow: 0px 1px 0px rgba(213, 213, 213, 0.2);
      border-radius: 8px;
      padding: 8px 13px 8px 9px;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.25px;
      font-style: normal;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      display: flex;
      width: fit-content;
      margin-top: 15px; }
      .apply-to-drive-page .apply-to-drive .content-container .blue-notes .error-circle {
        height: 13px;
        margin-right: 8px; }
    .apply-to-drive-page .apply-to-drive .content-container .rate-details {
      display: flex;
      justify-content: space-between;
      gap: 30px; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .bottom-left {
        flex: 1; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .bottom-right {
        display: flex;
        flex-direction: column;
        flex: 1; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .bottom-right .picker {
          width: 100% !important; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .left-section {
        width: 70%; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .right-section .amount {
        margin-left: 4px !important; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .accordion {
        width: 100%;
        transition: 0.4s;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 40px;
        display: flex;
        align-items: center;
        color: #838383;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        padding-right: 0; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .accordion .description img {
          height: 16px;
          width: 16px;
          margin-right: 8px; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .accordion .description .caret {
          width: 16px;
          /* Adjust size as needed */
          height: 16px;
          margin-right: 8px;
          /* Adjust spacing as needed */
          transition: transform 0.3s ease;
          /* Add transition effect */ }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .accordion .description .rotate {
          transform: rotate(180deg);
          /* Rotate the image by 180 degrees */ }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .accordion .total {
          display: flex; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .accordion .total .dollar {
            font-size: 13px !important; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .panel {
        padding-left: 18px;
        background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .panel .tax-description, .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .panel .surcharges-description {
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 18px;
          color: #838383;
          margin-bottom: 16px;
          text-transform: capitalize; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .panel .tax-description .amount, .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .panel .surcharges-description .amount {
            display: flex; }
            .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .panel .tax-description .amount .dollar, .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .panel .surcharges-description .amount .dollar {
              font-size: 13px !important;
              margin-top: 0; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .panel.active {
        max-height: 500px;
        margin-left: 15px; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .blue-notes {
        margin-top: 0px !important;
        margin-bottom: 16px; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .row {
        display: flex;
        justify-content: space-between;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 100;
        font-size: 18px;
        line-height: 24px;
        color: #838383;
        margin-top: 20px; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .row .val {
          display: flex; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .row .val .dollar {
            font-size: 12px;
            line-height: 15px; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .sub-heading {
        margin-bottom: 15px; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .sub-heading .row .key {
          font-weight: 500; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .price,
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .sub-heading > .price {
        display: flex; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .dollar,
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .sub-heading > .dollar {
        font-size: 12px;
        line-height: 32px; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .rate-badge {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: -0.25px;
        color: #0F8952; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .rate-badge img {
          height: 20px;
          width: 20px;
          margin-right: 5px; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .breakup {
        margin-top: 18px;
        margin-left: 15px; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom {
        display: flex;
        justify-content: space-between;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        display: flex;
        color: #4A4A4A;
        padding-top: 10px;
        border-top: 1px solid #EDEDED; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom p {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #000000; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom p span {
            color: #0F8952;
            text-decoration: underline;
            cursor: pointer; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom .total {
          display: flex;
          justify-content: space-between;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.5px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          color: #4A4A4A; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom .total .sub {
            margin-right: 10px; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom .total .grp {
            display: flex;
            flex-direction: column;
            align-items: center; }
            .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom .total .grp .frequency {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              font-style: normal;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom .total .total-price {
            display: flex; }
            .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom .total .total-price .dollar, .apply-to-drive-page .apply-to-drive .content-container .rate-details .breakdown .bottom .total .total-price .decimal {
              font-size: 14px !important;
              line-height: 25px; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .payment-cards {
        margin-top: 20px; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .payment-cards .title {
          display: flex;
          justify-content: space-between; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .payment-cards .title .sub-title {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A; }
          .apply-to-drive-page .apply-to-drive .content-container .rate-details .payment-cards .title .sub-text {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            text-align: right;
            color: #838383; }
    .apply-to-drive-page .apply-to-drive .content-container .button {
      display: flex;
      justify-content: flex-start; }
      .apply-to-drive-page .apply-to-drive .content-container .button .continue {
        height: 40px;
        width: 160px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        font-family: 'Proxima Nova Bold' !important; }
        .apply-to-drive-page .apply-to-drive .content-container .button .continue:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .apply-to-drive-page .apply-to-drive .content-container .button .continue:enabled {
          background-color: #00A664; }

@media only screen and (min-width: 901px) {
  .apply-to-drive-page {
    max-width: 900px;
    width: 90%; }
    .apply-to-drive-page .apply-to-drive .plan-card {
      width: 530px;
      padding: 25px; }
    .apply-to-drive-page .apply-to-drive .rate-details .breakdown {
      width: 50%; } }

@media only screen and (max-width: 900px) and (min-width: 701px) {
  .apply-to-drive-page .apply-to-drive {
    margin: auto;
    width: 90%; }
    .apply-to-drive-page .apply-to-drive .header {
      border-radius: 8px 8px 0px 0px !important; }
      .apply-to-drive-page .apply-to-drive .header .backArrow {
        justify-content: center; }
    .apply-to-drive-page .apply-to-drive .content-container .thanks-content {
      width: 75%;
      margin: auto; }
    .apply-to-drive-page .apply-to-drive .content-container .plan-card {
      width: 400px;
      padding: 25px; }
    .apply-to-drive-page .apply-to-drive .content-container .van-info .section .right .van-image {
      height: 80px; } }

@media only screen and (min-width: 381px) and (max-width: 700px) {
  .apply-to-drive-page .apply-to-drive .content-container {
    padding: 30px 15px !important; }
    .apply-to-drive-page .apply-to-drive .content-container .blue-notes {
      width: 100% !important; }
    .apply-to-drive-page .apply-to-drive .content-container .thanks-content {
      margin: auto;
      border-bottom: 1px solid #D5D5D5;
      padding-bottom: 15px; }
      .apply-to-drive-page .apply-to-drive .content-container .thanks-content .thanks-text {
        text-align: center; }
    .apply-to-drive-page .apply-to-drive .content-container .plan-card {
      width: 340px;
      height: 100px;
      padding: 15px; }
      .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount {
        text-align: center; }
        .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount .price {
          font-size: 32px;
          line-height: 32px; }
        .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount .dollar,
        .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount .decimal, .apply-to-drive-page .apply-to-drive .content-container .plan-card .amount .point {
          font-size: 15px;
          line-height: 12px; }
    .apply-to-drive-page .apply-to-drive .content-container .van-info {
      flex-direction: column;
      margin-top: unset !important; }
      .apply-to-drive-page .apply-to-drive .content-container .van-info .section {
        width: unset !important; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .left .van-type {
          font-size: 14px !important;
          margin-top: unset !important; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .right .van-image {
          height: 80px; }
        .apply-to-drive-page .apply-to-drive .content-container .van-info .section .van-route-details {
          margin-top: 15px;
          margin-left: -10px !important; }
    .apply-to-drive-page .apply-to-drive .content-container .rate-details {
      flex-direction: column; }
      .apply-to-drive-page .apply-to-drive .content-container .rate-details .payment-cards {
        padding-left: 0px;
        margin-top: 30px; }
        .apply-to-drive-page .apply-to-drive .content-container .rate-details .payment-cards .cards-container {
          margin-top: 20px; }
    .apply-to-drive-page .apply-to-drive .content-container .button {
      margin-top: 20px;
      justify-content: center; } }

@media only screen and (max-width: 380px) {
  .plan-card {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center !important; }
  .van-info {
    flex-direction: column; }
    .van-info .section {
      width: unset !important; }
    .van-info .van-route-details {
      margin-top: 20px; }
  .rate-details {
    flex-direction: column; }
    .rate-details .payment-cards {
      padding-left: 0px !important;
      margin-top: 30px; }
      .rate-details .payment-cards .cards-container {
        margin-top: 20px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

.license-page {
  padding-top: 100px;
  padding-bottom: 200px;
  width: 100%;
  background-color: #E5E5E5;
  height: fit-content;
  min-height: 100vh; }
  .license-page .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px;
    max-width: 900px; }
    .license-page .container .header {
      background: #00A664;
      border: 1px solid #D5D5D5;
      border-radius: 8px 8px 0px 0px;
      min-height: 80px;
      display: flex;
      flex-direction: row; }
      .license-page .container .header .backArrow {
        width: 10%;
        padding: 22px; }
        .license-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .license-page .container .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        width: 85%;
        padding: 16px; }
    .license-page .container .main .sections {
      display: flex;
      justify-content: space-between; }
      .license-page .container .main .sections .right-section {
        display: flex;
        justify-content: center;
        align-items: center; }
      .license-page .container .main .sections .blue-notes {
        background: #EDF4FC;
        box-shadow: 0px 1px 0px rgba(213, 213, 213, 0.2);
        border-radius: 8px;
        padding: 16px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #1E3A5A;
        margin-top: 15px;
        display: flex; }
        .license-page .container .main .sections .blue-notes .info-circle {
          height: 20px;
          margin-right: 18px; }

@media only screen and (min-width: 701px) {
  .license-page .container .main {
    padding: 40px 60px 60px 30px; }
    .license-page .container .main .sections {
      flex-direction: row; }
      .license-page .container .main .sections .left-section {
        width: 75%; }
      .license-page .container .main .sections .second-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .license-page .container .main .sections .text {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
    .license-page .container .main .button-container {
      margin-top: 30px; }
      .license-page .container .main .button-container button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        height: 40px;
        width: 160px; }
        .license-page .container .main .button-container button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .license-page .container .main .button-container button:enabled {
          background-color: #00A664; } }

@media only screen and (max-width: 700px) {
  .license-page .container .main {
    padding: 40px 25px; }
    .license-page .container .main .sections {
      flex-direction: column-reverse;
      align-items: center; }
      .license-page .container .main .sections .left-section {
        margin-top: 20px; }
      .license-page .container .main .sections .right-section img {
        width: 90px; }
    .license-page .container .main .second-heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 48px;
      letter-spacing: -0.5px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      text-align: center;
      font-size: 30px;
      line-height: 40px; }
    .license-page .container .main .text {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      text-align: center;
      font-size: 15px; }
    .license-page .container .main .button-container {
      margin-top: 50px; }
      .license-page .container .main .button-container button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        height: 60px;
        width: 100%;
        font-size: 24px; }
        .license-page .container .main .button-container button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .license-page .container .main .button-container button:enabled {
          background-color: #00A664; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.commute-information .charges-popup {
  top: 15%; }
  .commute-information .charges-popup .cancel-subscription-popup {
    padding: 30px 25px 50px 25px;
    height: 70vh;
    overflow: auto; }
    .commute-information .charges-popup .cancel-subscription-popup .sp-header {
      display: flex;
      justify-content: flex-end; }
      .commute-information .charges-popup .cancel-subscription-popup .sp-header .close {
        cursor: pointer; }
    .commute-information .charges-popup .cancel-subscription-popup .cs-header {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      margin-top: 12px; }
    .commute-information .charges-popup .cancel-subscription-popup .cancel-subscription-popup-content {
      margin: 10px auto;
      display: flex;
      flex-direction: column;
      color: #4A4A4A;
      letter-spacing: -0.25px;
      font-style: normal;
      justify-content: flex-start;
      width: fit-content;
      text-align: left; }
      .commute-information .charges-popup .cancel-subscription-popup .cancel-subscription-popup-content .cs-pre-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #838383;
        padding-top: 12px; }
      .commute-information .charges-popup .cancel-subscription-popup .cancel-subscription-popup-content .optionsContainer {
        margin-top: 30px; }
        .commute-information .charges-popup .cancel-subscription-popup .cancel-subscription-popup-content .optionsContainer ul li {
          margin-top: 0px;
          cursor: pointer; }
          .commute-information .charges-popup .cancel-subscription-popup .cancel-subscription-popup-content .optionsContainer ul li .optionsRadio {
            display: flex;
            align-items: center;
            width: 100%; }
            .commute-information .charges-popup .cancel-subscription-popup .cancel-subscription-popup-content .optionsContainer ul li .optionsRadio.active .optionsRound {
              border: 6px solid #00A664; }
            .commute-information .charges-popup .cancel-subscription-popup .cancel-subscription-popup-content .optionsContainer ul li .optionsRadio .optionsRound {
              background: #FFFFFF;
              border: 2px solid #838383;
              width: 18px;
              height: 18px;
              border-radius: 10px;
              margin: 10px 10px 10px 0px; }
            .commute-information .charges-popup .cancel-subscription-popup .cancel-subscription-popup-content .optionsContainer ul li .optionsRadio .text {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 14px;
              color: #4A4A4A; }
    .commute-information .charges-popup .cancel-subscription-popup .button-container .no {
      border: 2px solid #00A664;
      border-radius: 5px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #00A664;
      height: 40px;
      width: 170px; }
    .commute-information .charges-popup .cancel-subscription-popup .button-container .continue {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      height: 40px;
      width: 170px;
      color: #FFFFFF;
      border-radius: 5px;
      margin-left: 25px; }
      .commute-information .charges-popup .cancel-subscription-popup .button-container .continue:enabled {
        background-color: #00A664; }
      .commute-information .charges-popup .cancel-subscription-popup .button-container .continue:disabled {
        background-color: #838383;
        cursor: not-allowed; }

@media only screen and (min-width: 1011px) {
  .charges-popup {
    top: 15%; } }

@media only screen and (max-width: 1010px) and (min-width: 700px) {
  .charges-popup {
    top: 15%; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.cancel-summary-container .cancel-summary-page .vanpool-summary {
  width: 100%;
  overflow: auto; }

.cancel-summary-container .cancel-summary-page .popup {
  display: none;
  position: fixed;
  background: #FFF;
  z-index: 20;
  left: 0;
  right: 0;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
  width: 375px;
  border-radius: 5px 5px 0px 0px; }
  .cancel-summary-container .cancel-summary-page .popup:after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -2;
    border-radius: 5px 5px 0px 0px; }
  .cancel-summary-container .cancel-summary-page .popup:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #FFF;
    z-index: -1;
    border-radius: 5px 5px 0px 0px; }
  .cancel-summary-container .cancel-summary-page .popup.show {
    display: block; }
  .cancel-summary-container .cancel-summary-page .popup.hide {
    display: none; }

.cancel-summary-container .cancel-summary-page .card-list {
  border: none !important; }

.cancel-summary-container .cancel-summary-page .grp {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .cancel-summary-container .cancel-summary-page .grp .frequency {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased; }

.cancel-summary-container .save-button {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background-color: #FFF !important;
  border: 2px solid #00A664;
  border-radius: 5px;
  color: #00A664;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  padding: 8px 24px;
  border: 2px solid #F96302;
  color: #F96302; }
  .cancel-summary-container .save-button:disabled {
    border: 2px solid #838383 !important;
    color: #838383;
    cursor: not-allowed;
    border: none; }

.cancel-summary-container .charges-popup {
  top: 15%; }
  .cancel-summary-container .charges-popup .cancel-confirmation-popup {
    padding: 20px 25px 30px 25px;
    max-height: 80vh;
    overflow: auto; }
    .cancel-summary-container .charges-popup .cancel-confirmation-popup .ccp-header {
      display: flex;
      justify-content: flex-end; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .ccp-header .close {
        cursor: pointer; }
    .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content {
      width: fit-content;
      margin: 24px auto;
      display: flex;
      flex-direction: column;
      color: #4A4A4A;
      letter-spacing: -0.25px;
      font-style: normal; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .success-icon {
        display: flex;
        justify-content: center; }
        .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .success-icon .successIcon {
          width: 65px; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .message {
        font-family: 'DINNextLTPro-Regular';
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        text-align: center;
        margin-top: 20px; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .last-days-section {
        display: grid;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        max-width: 80%;
        grid-gap: 20px;
        text-wrap: nowrap;
        margin: 10px auto;
        padding: 15px 0px; }
        .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .last-days-section .row {
          display: flex;
          justify-content: space-between;
          gap: 30px;
          flex-wrap: nowrap; }
        .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .last-days-section .date {
          font-weight: 600; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .popup-text {
        width: fit-content;
        text-align: center;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #636363;
        border-top: 0.5px solid #D5D5D5;
        margin: 0 auto; }
        .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .popup-text p {
          margin-top: 30px; }
          .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .popup-text p .callable {
            font-weight: 700; }
          .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .popup-text p .terms {
            text-decoration: underline;
            color: #636363; }
          .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .popup-text p .downlaodAgreement {
            text-decoration: underline;
            text-transform: capitalize;
            cursor: pointer;
            color: #130C0E; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .confirmationContainer {
        text-align: center;
        font-family: 'DINNextLTPro-Regular';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 20px 20px 16px 20px;
        color: #4A4A4A; }
        .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .confirmationContainer .date {
          font-weight: 700 !important; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .notify {
        display: flex;
        justify-content: space-evenly;
        border-radius: 8px;
        background: #EDF4FC;
        box-shadow: 0px 1px 0px 0px rgba(213, 213, 213, 0.2);
        padding: 16px;
        font-family: "Proxima Nova";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.25px;
        width: 80%;
        margin-left: 60px; }
        .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .notify .infoIcon {
          height: 20px;
          width: 20px; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .messageContainer {
        display: flex;
        text-align: center;
        font-family: 'DINNextLTPro-Regular';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #838383;
        justify-content: center;
        padding: 0px 100px;
        margin-top: 20px; }
    .cancel-summary-container .charges-popup .cancel-confirmation-popup .button-container {
      margin-top: 30px; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .button-container .noThanks {
        border: 2px solid #00A664;
        border-radius: 5px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #00A664;
        height: 40px;
        width: 200px; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .button-container .continue {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        height: 40px;
        width: 200px;
        color: #FFFFFF;
        border-radius: 5px;
        margin-left: 25px;
        background-color: #00A664; }
    .cancel-summary-container .charges-popup .cancel-confirmation-popup .footer {
      text-align: center;
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #838383;
      margin-top: 24px; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .footer .callable {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #00A664;
        margin-left: 10px; }

@media only screen and (max-width: 700px) {
  .cancel-summary-container .button-container {
    display: flex;
    justify-content: center; }
    .cancel-summary-container .button-container .save-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      background-color: #FFF !important;
      border: 2px solid #00A664;
      border-radius: 5px;
      color: #00A664;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      padding: 8px 24px;
      border: 2px solid #F96302;
      color: #F96302; }
      .cancel-summary-container .button-container .save-button:disabled {
        border: 2px solid #838383 !important;
        color: #838383;
        cursor: not-allowed;
        border: none; }
  .cancel-summary-container .charges-popup .cancel-confirmation-popup {
    height: 80vh; }
    .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content {
      width: auto;
      margin: unset; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .last-days-section {
        margin: 10px auto;
        width: 90%;
        grid-gap: 20px;
        padding: 10px 0px; }
        .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .last-days-section .row {
          display: flex;
          justify-content: space-between;
          gap: 30px;
          flex-wrap: nowrap; }
        .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .last-days-section .date {
          font-weight: 600; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .popup-text .callable {
        color: #00A664;
        text-decoration: underline; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .confirmationContainer {
        margin: 24px 0px; }
      .cancel-summary-container .charges-popup .cancel-confirmation-popup .cancel-confirmation-popup-content .messageContainer {
        padding: 0px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.change-subscription-container .change-subscription-page .header {
  background: #00A664;
  border: 1px solid #D5D5D5;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px 8px 0px 0px; }
  .change-subscription-container .change-subscription-page .header .backArrow {
    width: 10%;
    padding: 22px; }
    .change-subscription-container .change-subscription-page .header .backArrow img {
      height: 35px;
      width: 35px; }
  .change-subscription-container .change-subscription-page .header .heading {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    font-size: 36px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    width: 85%;
    padding: 16px; }

.change-subscription-container .change-subscription-page .content {
  display: flex;
  margin: 0 20%;
  flex-direction: column; }
  .change-subscription-container .change-subscription-page .content .top-section {
    border-radius: 8px;
    border: 1px solid #D5D5D5;
    background: #EDEDED;
    box-shadow: 0px 1px 0px 0px #D5D5D5;
    margin: 30px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 32px; }
    .change-subscription-container .change-subscription-page .content .top-section .commute-days {
      margin-top: 24px; }
      .change-subscription-container .change-subscription-page .content .top-section .commute-days .commute-days-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        color: #838383;
        margin-bottom: 8px; }
      .change-subscription-container .change-subscription-page .content .top-section .commute-days .days {
        display: flex; }
        .change-subscription-container .change-subscription-page .content .top-section .commute-days .days .day-btn {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          display: flex;
          align-items: center;
          text-align: center;
          color: #4A4A4A;
          height: 26px;
          width: 44px;
          background: #D5D5D5;
          border-radius: 4px;
          margin-right: 4px;
          text-transform: uppercase;
          padding-top: 5px;
          justify-content: center; }
    .change-subscription-container .change-subscription-page .content .top-section .left-section .plan-details .plan {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      display: inline-block;
      text-transform: capitalize;
      border-radius: 4px;
      color: #FFFFFF;
      padding: 2px 12px; }
      .change-subscription-container .change-subscription-page .content .top-section .left-section .plan-details .plan.active {
        background: #00A664; }
      .change-subscription-container .change-subscription-page .content .top-section .left-section .plan-details .plan.inactive {
        background: #F96302; }
    .change-subscription-container .change-subscription-page .content .top-section .left-section .plan-details .package {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      align-items: center;
      color: #00A664;
      margin-top: 8px; }
    .change-subscription-container .change-subscription-page .content .top-section .right-section .rate-details {
      margin-left: -5px;
      margin-bottom: 5px; }
      .change-subscription-container .change-subscription-page .content .top-section .right-section .rate-details img {
        height: 20px;
        width: 20px; }
      .change-subscription-container .change-subscription-page .content .top-section .right-section .rate-details span {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700;
        color: #0F8952;
        padding-left: 5px; }
    .change-subscription-container .change-subscription-page .content .top-section .right-section .rate {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .change-subscription-container .change-subscription-page .content .top-section .right-section .rate .price-group {
        display: flex;
        color: #4A4A4A; }
        .change-subscription-container .change-subscription-page .content .top-section .right-section .rate .price-group .doller {
          font-size: 25px;
          line-height: 23px;
          padding-right: 3px;
          font-weight: 400; }
        .change-subscription-container .change-subscription-page .content .top-section .right-section .rate .price-group .price {
          font-family: 'DINNextLTPro-Regular';
          font-weight: 700;
          font-size: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: -0.25px; }
        .change-subscription-container .change-subscription-page .content .top-section .right-section .rate .price-group .decimal {
          font-family: 'DINNextLTPro-Regular';
          font-weight: 100;
          font-size: 25px;
          line-height: 23px; }
      .change-subscription-container .change-subscription-page .content .top-section .right-section .rate .perMonth {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: -0.25px;
        color: #4A4A4A; }
  .change-subscription-container .change-subscription-page .content .bottom-section .heading {
    font-family: 'DINNextLTPro-Regular';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #4A4A4A; }
  .change-subscription-container .change-subscription-page .content .bottom-section .pre-text {
    font-family: 'DINNextLTPro-Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #636363; }
  .change-subscription-container .change-subscription-page .content .bottom-section .no-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px; }
    .change-subscription-container .change-subscription-page .content .bottom-section .no-plan .error-circle-rounded {
      width: 60px;
      height: 60px; }
    .change-subscription-container .change-subscription-page .content .bottom-section .no-plan .message {
      color: #636363;
      text-align: center;
      font-family: 'DINNextLTPro-Regular';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 48px;
      margin-top: 16px;
      margin-bottom: 24px; }
    .change-subscription-container .change-subscription-page .content .bottom-section .no-plan .callable-number {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 217px;
      height: 72px;
      margin-bottom: 33px;
      border-radius: 8px;
      color: #00A664;
      border: 1px solid #D5D5D5; }
      .change-subscription-container .change-subscription-page .content .bottom-section .no-plan .callable-number .phone-icon {
        width: 40px;
        height: 40px;
        margin-right: 16px;
        background-color: #E5F6EF; }
  .change-subscription-container .change-subscription-page .content .bottom-section .tabs {
    border-bottom: solid 3px #EDEDED;
    display: flex;
    flex-direction: row;
    margin: 20px 0px 20px;
    width: 100%; }
    .change-subscription-container .change-subscription-page .content .bottom-section .tabs .tab {
      padding: 10px 10px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      width: 50%;
      color: #838383; }
    .change-subscription-container .change-subscription-page .content .bottom-section .tabs .active {
      color: #049b5c;
      background: #D4EDE1;
      border-radius: 4px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom: solid 3px #049b5c;
      margin-bottom: -3px; }
      .change-subscription-container .change-subscription-page .content .bottom-section .tabs .active:focus {
        box-shadow: 0 0 5px 0 #4A90E2; }
  .change-subscription-container .change-subscription-page .content .bottom-section .pref-text {
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #636363; }
  .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp {
    margin: auto; }
    .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .packages {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .packages .select-heading {
        font-family: 'DINNextLTPro-Regular';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        /* 150% */
        letter-spacing: -0.5px;
        color: #4A4A4A;
        margin: 15px 0px 10px; }
      .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .packages .role-title {
        display: flex;
        margin: 20px 5px 10px; }
        .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .packages .role-title img {
          height: 24px; }
        .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .packages .role-title .role {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 128.571% */
          letter-spacing: -0.25px;
          margin-top: 5px;
          margin-left: 5px; }
    .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component {
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #D5D5D5;
      filter: drop-shadow(0px 1px 0px #D5D5D5);
      border-radius: 8px;
      position: relative;
      cursor: pointer; }
      .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component .tick-icon-container-Change-subscription .tick-icon {
        position: absolute;
        right: 10px;
        top: -10px;
        z-index: 1; }
      .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component.selected {
        background: #D4EDE1;
        border: 3px solid #00A664; }
        .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component.selected .sub-heading {
          color: #0F8952;
          font-weight: 500; }
      .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component .sub-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700;
        align-self: flex-start;
        margin-top: 5px;
        color: #838383;
        position: absolute;
        left: 10px; }
      .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component .price-group {
        display: flex;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
        justify-content: center;
        /* Commute Charcoal */
        color: #4A4A4A; }
        .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component .price-group .doller {
          font-size: 20px; }
        .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component .price-group .price {
          font-size: 36px;
          line-height: 20px;
          margin-top: 15px; }
        .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component .price-group .decimal {
          font-size: 20px; }
      .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .price-component .frequency {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #4A4A4A;
        margin-top: 65px;
        position: absolute; }
    .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp .footer-text {
      font-family: 'DINNextLTPro-Regular';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 128.571% */
      letter-spacing: -0.25px;
      color: #838383;
      margin: 10px 0px 25px; }
  .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .driver-only-text {
    margin: 0 40px;
    font-family: 'DINNextLTPro-Regular';
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: #838383;
    font-weight: 400; }

.change-subscription-container .change-subscription-page .button-container {
  border-radius: 0px 0px 8px 8px;
  background: #FFFFFF;
  box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.25);
  height: 100px;
  position: sticky;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  .change-subscription-container .change-subscription-page .button-container .select-plan {
    border-radius: 5px;
    background: #00A664;
    color: #FFFFFF;
    text-align: center;
    font-family: 'Proxima Nova';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    /* 150% */
    text-transform: uppercase;
    padding: 6px 32px; }
    .change-subscription-container .change-subscription-page .button-container .select-plan:disabled {
      background-color: #838383;
      cursor: not-allowed; }

.change-subscription-container .upgrade-section .commuteDays {
  margin-top: 20px; }
  .change-subscription-container .upgrade-section .commuteDays .days {
    display: flex; }
    .change-subscription-container .upgrade-section .commuteDays .days .day {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center; }
      .change-subscription-container .upgrade-section .commuteDays .days .day .full-badge {
        text-align: center;
        margin: 0 15px 0 0;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A90E2; }
    .change-subscription-container .upgrade-section .commuteDays .days .day-btn {
      color: #00A664;
      background-color: #FFFFFF;
      height: 40px;
      width: 40px;
      font-size: 14px;
      text-align: center;
      border-radius: 25px;
      border: solid 2px #00A664;
      cursor: pointer;
      margin: 5px 15px 0 0;
      padding: 0;
      text-transform: uppercase;
      padding-top: 5px;
      line-height: 17px;
      font-family: 'DINNextLTPro-Regular';
      font-weight: 700; }
    .change-subscription-container .upgrade-section .commuteDays .days .day-btn:disabled {
      color: #049b5c;
      border: none;
      background: #FFFFFF;
      border: solid 1px #05cd7a;
      cursor: default; }
    .change-subscription-container .upgrade-section .commuteDays .days .day-btn.unavailable {
      color: #D5D5D5;
      border: none;
      background: #FFFFFF;
      border: solid 2px #D5D5D5;
      cursor: default;
      text-decoration: line-through; }
    .change-subscription-container .upgrade-section .commuteDays .days .day-btn.active {
      color: #0F8952;
      border: solid 2px #0F8952;
      background: #D4EDE1; }
  .change-subscription-container .upgrade-section .commuteDays .error {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #F96302;
    margin-top: 8px; }

.change-subscription-container .upgrade-section .container .upgrade-content {
  display: flex;
  justify-content: space-between; }
  .change-subscription-container .upgrade-section .container .upgrade-content .left-section .foot-note {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-weight: 400;
    color: #838383;
    width: 90%;
    margin-top: 5px; }
  .change-subscription-container .upgrade-section .container .upgrade-content .left-section .header-text.big {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: #4A4A4A;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased; }
  .change-subscription-container .upgrade-section .container .upgrade-content .left-section .header-text.small {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.5px;
    color: #4A4A4A;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    margin-bottom: 20px; }
  .change-subscription-container .upgrade-section .container .upgrade-content .left-section .comment-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #636363; }
    .change-subscription-container .upgrade-section .container .upgrade-content .left-section .comment-text.driver {
      margin-top: 24px; }
  .change-subscription-container .upgrade-section .container .upgrade-content .left-section .your-days-label {
    margin-top: 24px;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #838383; }
  .change-subscription-container .upgrade-section .container .upgrade-content .left-section .oprational-days {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #4A4A4A;
    margin-top: 5px; }
    .change-subscription-container .upgrade-section .container .upgrade-content .left-section .oprational-days .day-btn {
      text-align: center;
      padding: 4px 10px !important;
      cursor: default; }
      .change-subscription-container .upgrade-section .container .upgrade-content .left-section .oprational-days .day-btn:not(:last-child) {
        margin-right: 5px; }
    .change-subscription-container .upgrade-section .container .upgrade-content .left-section .oprational-days .not-active {
      background: #D5D5D5;
      border-radius: 4px;
      text-transform: uppercase; }
  .change-subscription-container .upgrade-section .container .upgrade-content .left-section .notification {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #1E3A5A;
    padding: 15px;
    background-color: #EDF4FC;
    border-radius: 8px;
    box-shadow: 0px 1px 0px 0px rgba(213, 213, 213, 0.2);
    width: 90%;
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .change-subscription-container .upgrade-section .container .upgrade-content .left-section .notification img {
      height: 24px;
      margin-right: 15px; }
  .change-subscription-container .upgrade-section .container .upgrade-content .right-section {
    display: flex;
    justify-content: center;
    align-items: center; }
    .change-subscription-container .upgrade-section .container .upgrade-content .right-section img {
      max-width: 160px; }

.change-subscription-container .upgrade-section .container .buttons {
  display: flex;
  margin-top: 30px; }
  .change-subscription-container .upgrade-section .container .buttons button {
    width: 155px;
    height: 40px; }
  .change-subscription-container .upgrade-section .container .buttons .cancel-button {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background-color: #FFF !important;
    border: 2px solid #00A664;
    border-radius: 5px;
    color: #00A664;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    margin-right: 24px; }
    .change-subscription-container .upgrade-section .container .buttons .cancel-button:disabled {
      border: 2px solid #838383 !important;
      color: #838383;
      cursor: not-allowed;
      border: none; }
  .change-subscription-container .upgrade-section .container .buttons .continue-button {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #FFFFFF;
    border-radius: 5px; }
    .change-subscription-container .upgrade-section .container .buttons .continue-button:disabled {
      background-color: #838383;
      cursor: not-allowed;
      border: none; }
    .change-subscription-container .upgrade-section .container .buttons .continue-button:enabled {
      background-color: #00A664; }

.change-subscription-container .summary-section .commute-days-heading {
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-weight: 400;
  color: #838383;
  margin-bottom: 8px; }

.change-subscription-container .summary-section .commute-days {
  display: flex;
  gap: 5px;
  margin-top: 8px;
  margin-bottom: 25px; }
  .change-subscription-container .summary-section .commute-days .block {
    height: 26px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #D5D5D5;
    border-radius: 4px;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.25px;
    color: #4A4A4A;
    text-transform: uppercase; }

.change-subscription-container .last-approved-driver-container .heading {
  text-align: center;
  margin-top: 20px !important;
  font-weight: 500 !important;
  max-width: 630px; }

.change-subscription-container .last-approved-driver-container .delete-confirmation-container {
  height: unset !important;
  padding-top: 20px; }
  .change-subscription-container .last-approved-driver-container .delete-confirmation-container .buttons {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
    .change-subscription-container .last-approved-driver-container .delete-confirmation-container .buttons .confirmation-continue-button {
      padding: 8px 70px; }

.change-subscription-container .last-approved-driver-container .callable a[href] {
  text-decoration: underline; }

.change-subscription-confirmation-popup {
  height: fit-content;
  overflow: auto; }
  .change-subscription-confirmation-popup .ccp-header {
    display: flex;
    justify-content: flex-end; }
    .change-subscription-confirmation-popup .ccp-header .close {
      cursor: pointer; }
  .change-subscription-confirmation-popup .cancel-confirmation-popup-content {
    display: flex;
    flex-direction: column;
    color: #4A4A4A;
    letter-spacing: -0.25px;
    font-style: normal; }
    .change-subscription-confirmation-popup .cancel-confirmation-popup-content .success-icon {
      display: flex;
      justify-content: center; }
      .change-subscription-confirmation-popup .cancel-confirmation-popup-content .success-icon .successIcon {
        width: 65px; }
    .change-subscription-confirmation-popup .cancel-confirmation-popup-content .confirmationContainer {
      text-align: center;
      font-family: 'DINNextLTPro-Regular';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #636363;
      margin-top: 20px; }
      .change-subscription-confirmation-popup .cancel-confirmation-popup-content .confirmationContainer .date {
        font-weight: 700 !important; }
  .change-subscription-confirmation-popup .button-container .noThanks {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background-color: #FFF !important;
    border: 2px solid #00A664;
    border-radius: 5px;
    color: #00A664;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-size: 14px;
    height: 40px;
    width: 200px; }
    .change-subscription-confirmation-popup .button-container .noThanks:disabled {
      border: 2px solid #838383 !important;
      color: #838383;
      cursor: not-allowed;
      border: none; }

@media only screen and (min-width: 769px) {
  .change-subscription-container {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .change-subscription-container .change-subscription-page {
      max-width: 900px;
      margin: 75px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .change-subscription-container .change-subscription-page .content {
        display: flex;
        margin: 0 20%;
        flex-direction: column; }
        .change-subscription-container .change-subscription-page .content .bottom-section {
          width: 100%; }
          .change-subscription-container .change-subscription-page .content .bottom-section .heading {
            font-family: 'DINNextLTPro-Regular';
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: -0.5px;
            color: #4A4A4A; }
          .change-subscription-container .change-subscription-page .content .bottom-section .pre-text {
            font-family: 'DINNextLTPro-Regular';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #636363; }
          .change-subscription-container .change-subscription-page .content .bottom-section .tabs {
            border-bottom: solid 3px #EDEDED;
            display: flex;
            flex-direction: row;
            margin: 20px 0px 10px;
            width: 100%; }
            .change-subscription-container .change-subscription-page .content .bottom-section .tabs .tab {
              padding: 10px 10px;
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              width: 50%;
              color: #838383; }
            .change-subscription-container .change-subscription-page .content .bottom-section .tabs .active {
              color: #049b5c;
              background: #D4EDE1;
              border-radius: 4px;
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom: solid 3px #049b5c;
              margin-bottom: -3px; }
          .change-subscription-container .change-subscription-page .content .bottom-section .tab-container {
            height: 300px;
            overflow: auto; }
            .change-subscription-container .change-subscription-page .content .bottom-section .tab-container .price-comp {
              margin: 20px 40px; }
    .change-subscription-container .upgrade-section .container {
      margin: 35px 60px 60px 30px; }
      .change-subscription-container .upgrade-section .container .upgrade-content {
        flex-direction: row; }
        .change-subscription-container .upgrade-section .container .upgrade-content .left-section {
          width: 70%; }
  .change-subscription-confirmation-popup {
    padding: 30px 25px 40px 25px; }
  .cancel-confirmation-popup-content {
    margin: 24px auto; }
    .cancel-confirmation-popup-content .confirmationContainer {
      text-align: center;
      font-family: 'DINNextLTPro-Regular';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #636363;
      margin-top: 20px; }
      .cancel-confirmation-popup-content .confirmationContainer .date {
        font-weight: 700 !important; }
  .button-container {
    margin-top: 30px; }
    .button-container .noThanks {
      height: 40px;
      width: 200px; } }

@media only screen and (max-width: 700px) {
  .change-subscription-container {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5;
    padding-top: 60px;
    padding-bottom: 0px; }
    .change-subscription-container .change-subscription-page {
      max-width: 100%;
      margin: 0px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .change-subscription-container .change-subscription-page .header {
        border-radius: 0;
        border: none; }
        .change-subscription-container .change-subscription-page .header .backArrow {
          width: 10%;
          padding: 22px; }
          .change-subscription-container .change-subscription-page .header .backArrow img {
            height: 35px;
            width: 35px; }
        .change-subscription-container .change-subscription-page .header .heading {
          font-weight: 700;
          line-height: 36px;
          font-size: 24px; }
      .change-subscription-container .change-subscription-page .content {
        margin: 0 4%; }
        .change-subscription-container .change-subscription-page .content .top-section {
          padding: 16px 24px 16px 16px; }
          .change-subscription-container .change-subscription-page .content .top-section .right-section .rate .price-group .doller {
            font-size: 20px;
            line-height: 26px; }
          .change-subscription-container .change-subscription-page .content .top-section .right-section .rate .price-group .price {
            font-size: 32px;
            line-height: 36px; }
          .change-subscription-container .change-subscription-page .content .top-section .right-section .rate .price-group .decimal {
            font-size: 20px;
            line-height: 26px; }
        .change-subscription-container .change-subscription-page .content .bottom-section {
          margin: 30px 0px 10px; }
          .change-subscription-container .change-subscription-page .content .bottom-section .heading {
            font-family: 'DINNextLTPro-Regular';
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: -0.5px;
            color: #4A4A4A; }
          .change-subscription-container .change-subscription-page .content .bottom-section .pre-text {
            font-family: 'DINNextLTPro-Regular';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #636363; }
          .change-subscription-container .change-subscription-page .content .bottom-section .tabs {
            border-bottom: solid 3px #EDEDED;
            display: flex;
            flex-direction: row;
            margin: 20px 0px 40px;
            width: 100%; }
            .change-subscription-container .change-subscription-page .content .bottom-section .tabs .tab {
              padding: 10px 10px;
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              width: 50%;
              color: #838383; }
            .change-subscription-container .change-subscription-page .content .bottom-section .tabs .active {
              color: #049b5c;
              background: #D4EDE1;
              border-radius: 4px;
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom: solid 3px #049b5c;
              margin-bottom: -3px; }
        .change-subscription-container .change-subscription-page .content .no-plan .callable-number {
          margin-top: 24px; }
        .change-subscription-container .change-subscription-page .content .tab-container {
          width: 100%; }
          .change-subscription-container .change-subscription-page .content .tab-container .price-comp {
            margin: 20px 0px;
            width: 100%;
            max-width: 400px; }
      .change-subscription-container .change-subscription-page .button-container {
        border-radius: none;
        box-shadow: none;
        position: none; }
        .change-subscription-container .change-subscription-page .button-container .select-plan {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          padding: 6px 32px; }
    .change-subscription-container .upgrade-section .container {
      margin: 60px 30px; }
      .change-subscription-container .upgrade-section .container .upgrade-content {
        flex-direction: column-reverse; }
        .change-subscription-container .upgrade-section .container .upgrade-content .header-text.big {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-size: 30px !important;
          text-align: center; }
        .change-subscription-container .upgrade-section .container .upgrade-content .header-text.small {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .change-subscription-container .upgrade-section .container .upgrade-content .commuteDays .error {
          text-align: center; }
      .change-subscription-container .upgrade-section .container .buttons {
        display: flex;
        justify-content: center;
        align-items: center; }
  .change-subscription-confirmation-popup {
    padding: 30px 25px 40px 25px; }
    .change-subscription-confirmation-popup .cancel-confirmation-popup-content {
      margin: 24px auto;
      display: flex;
      flex-direction: column;
      color: #4A4A4A;
      letter-spacing: -0.25px;
      font-style: normal; }
      .change-subscription-confirmation-popup .cancel-confirmation-popup-content .confirmationContainer {
        text-align: center;
        font-family: 'DINNextLTPro-Regular';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #636363;
        margin-top: 20px; }
        .change-subscription-confirmation-popup .cancel-confirmation-popup-content .confirmationContainer .date {
          font-weight: 700 !important; }
    .change-subscription-confirmation-popup .button-container {
      margin-top: 30px; }
      .change-subscription-confirmation-popup .button-container .noThanks {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: #FFF !important;
        border: 2px solid #00A664;
        border-radius: 5px;
        color: #00A664;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-size: 14px;
        height: 40px;
        width: 200px; }
        .change-subscription-confirmation-popup .button-container .noThanks:disabled {
          border: 2px solid #838383 !important;
          color: #838383;
          cursor: not-allowed;
          border: none; } }

@media only screen and (max-width: 768px) and (min-width: 701px) {
  .change-subscription-container {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5;
    padding-top: 60px;
    padding-bottom: 0px; }
    .change-subscription-container .change-subscription-page {
      max-width: 100%;
      margin: 0px auto 0;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .change-subscription-container .change-subscription-page .header {
        border-radius: 0;
        border: none; }
        .change-subscription-container .change-subscription-page .header .backArrow {
          width: 10%;
          padding: 22px; }
          .change-subscription-container .change-subscription-page .header .backArrow img {
            height: 35px;
            width: 35px; }
        .change-subscription-container .change-subscription-page .header .heading {
          font-weight: 700;
          line-height: 36px;
          font-size: 24px; }
      .change-subscription-container .change-subscription-page .content {
        margin: 60px; }
        .change-subscription-container .change-subscription-page .content .bottom-section {
          margin: 30px 16px; }
          .change-subscription-container .change-subscription-page .content .bottom-section .heading {
            font-family: 'DINNextLTPro-Regular';
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: -0.5px;
            color: #4A4A4A; }
          .change-subscription-container .change-subscription-page .content .bottom-section .pre-text {
            font-family: 'DINNextLTPro-Regular';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #636363; }
          .change-subscription-container .change-subscription-page .content .bottom-section .tabs {
            border-bottom: solid 3px #EDEDED;
            display: flex;
            flex-direction: row;
            margin: 20px 0px 10px;
            width: 100%; }
            .change-subscription-container .change-subscription-page .content .bottom-section .tabs .tab {
              padding: 10px 10px;
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              width: 50%;
              color: #838383; }
            .change-subscription-container .change-subscription-page .content .bottom-section .tabs .active {
              color: #049b5c;
              background: #D4EDE1;
              border-radius: 4px;
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom: solid 3px #049b5c;
              margin-bottom: -3px; }
        .change-subscription-container .change-subscription-page .content .tab-container {
          width: 100%;
          height: 400px;
          overflow: auto; }
          .change-subscription-container .change-subscription-page .content .tab-container .price-comp {
            margin: 20px 0px;
            width: 100%;
            max-width: 600px; }
      .change-subscription-container .change-subscription-page .button-container .select-plan {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        padding: 6px 40px; }
    .change-subscription-container .upgrade-section .container {
      padding: 60px 30px; }
      .change-subscription-container .upgrade-section .container .upgrade-content {
        flex-direction: column-reverse; }
  .change-subscription-confirmation-popup {
    padding: 30px 25px 40px 25px; }
    .change-subscription-confirmation-popup .cancel-confirmation-popup-content {
      margin: 24px auto; }
      .change-subscription-confirmation-popup .cancel-confirmation-popup-content .confirmationContainer {
        margin-top: 20px; }
    .change-subscription-confirmation-popup .button-container {
      margin-top: 30px; } }

@media only screen and (max-width: 350px) {
  .change-subscription-container .change-subscription-page .content .top-section {
    max-width: 330px;
    overflow-x: scroll; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.edit-popup {
  top: 20% !important; }
  .edit-popup .edit-commute-popup {
    height: 70vh;
    overflow: auto; }
    .edit-popup .edit-commute-popup .sp-header {
      display: flex;
      justify-content: space-between;
      margin: 0px 20px; }
    .edit-popup .edit-commute-popup .edit-commute-container {
      width: 100%;
      min-height: inherit;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      margin-top: 15px; }
      .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container {
        padding: 0px 30px;
        width: 100%; }
        .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .vanpool-info {
          color: #4A4A4A;
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          margin-top: 23px;
          margin-bottom: 24px; }
        .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .selected-days-container {
          display: flex;
          padding: 8px 0 8px 8px;
          max-width: fit-content;
          align-items: center;
          border-radius: 5px;
          background: #EDF4FC; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .selected-days-container .selected-days {
            display: flex;
            width: 135px;
            height: 20px;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0; }
        .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays {
          margin-top: 20px; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays .days {
            display: flex; }
            .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays .days .day {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center; }
              .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays .days .day .full-badge {
                text-align: center;
                margin: 0 15px 0 0;
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #4A90E2; }
            .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays .days .day-btn {
              color: #00A664;
              background-color: #FFFFFF;
              height: 40px;
              width: 40px;
              font-size: 14px;
              text-align: center;
              border-radius: 25px;
              border: solid 2px #00A664;
              cursor: pointer;
              margin: 5px 15px 0 0;
              padding: 0;
              text-transform: uppercase;
              padding-top: 5px;
              line-height: 17px;
              font-family: 'DINNextLTPro-Regular';
              font-weight: 700; }
            .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays .days .day-btn:disabled {
              color: #049b5c;
              border: none;
              background: #FFFFFF;
              border: solid 1px #05cd7a;
              cursor: default; }
            .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays .days .day-btn.unavailable {
              color: #D5D5D5;
              border: none;
              background: #FFFFFF;
              border: solid 2px #D5D5D5;
              cursor: default;
              text-decoration: line-through; }
            .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays .days .day-btn.active {
              color: #0F8952;
              border: solid 2px #0F8952;
              background: #D4EDE1; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commuteDays-selectDays .error {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #F96302;
            margin-top: 8px; }
        .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-locations {
          display: flex;
          justify-content: space-between; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-locations label {
            font-family: 'DINNextLTPro-Medium';
            font-size: 14px;
            line-height: 25px;
            color: #4A4A4A;
            font-style: normal; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-locations .field {
            width: 48%; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-locations input {
            border: 2px solid #D5D5D5;
            height: 56px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            width: 100%;
            padding: 15px;
            pointer-events: none;
            color: #838383;
            background: #EDEDED; }
        .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-days .commuteDays {
          margin-top: 24px; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-days .commuteDays .days .day-btn {
            color: #00A664;
            background-color: #FFFFFF;
            height: 40px;
            width: 40px;
            font-size: 14px;
            text-align: center;
            border-radius: 25px;
            border: solid 2px #00A664;
            cursor: pointer;
            margin: 0 15px 0 0;
            padding: 0;
            text-transform: uppercase;
            padding-top: 5px;
            line-height: 17px;
            font-family: 'DINNextLTPro-Regular';
            font-weight: 700; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-days .commuteDays .days .day-btn.active {
            color: #0F8952;
            border: solid 2px #0F8952;
            background: #D4EDE1; }
        .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-days .minCommuteDays {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin-top: 16px;
          color: #838383; }
        .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .commute-days .commute-days-count {
          color: #F96302;
          width: 429px;
          font-family: 'Proxima Nova';
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin-top: 16px;
          margin-bottom: 18px; }
        .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .time-entry {
          display: flex;
          flex-wrap: wrap;
          grid-template-columns: auto auto auto auto;
          justify-content: space-between;
          margin-bottom: 10px;
          margin-top: 18px; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .time-entry .entry {
            display: flex;
            flex-wrap: wrap; }
          .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .time-entry .select-container .select-header, .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .time-entry .flexibility-container .flexibility-header {
            pointer-events: none;
            background-color: #EDEDED; }
            .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .time-entry .select-container .select-header .time-arrow.down, .edit-popup .edit-commute-popup .edit-commute-container .collapsible-content-container .time-entry .flexibility-container .flexibility-header .time-arrow.down {
              display: none; }
      .edit-popup .edit-commute-popup .edit-commute-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        height: 40px;
        width: 160px;
        color: #FFFFFF;
        margin: 50px 0px 40px;
        border-radius: 5px; }
        .edit-popup .edit-commute-popup .edit-commute-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed; }
        .edit-popup .edit-commute-popup .edit-commute-container .continue-button:enabled {
          background-color: #00A664; }

@media only screen and (max-width: 900px) {
  .edit-commute-container .sp-header {
    margin: 0px !important; }
  .edit-commute-container .commute-locations {
    flex-direction: column; }
    .edit-commute-container .commute-locations .field {
      width: 100% !important; }
  .edit-commute-container .collapsible-content-container {
    padding: 0px !important; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.roster-page {
  background: #E5E5E5;
  display: flex;
  justify-content: center;
  height: 100vh; }
  .roster-page .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px;
    background: #E5E5E5;
    max-width: 900px;
    max-height: 65%; }
    .roster-page .container .header {
      background: #00A664;
      border: 1px solid #D5D5D5;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .roster-page .container .header .backArrow {
        width: 10%;
        padding: 22px; }
        .roster-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .roster-page .container .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        width: 85%; }
    .roster-page .container .main {
      display: flex;
      padding: 24px 44px 0px;
      height: 100%; }
      .roster-page .container .main .participants-container {
        display: grid;
        grid-template-columns: repeat(4, 200px);
        height: 100%;
        grid-row-gap: 20px;
        overflow: scroll;
        overflow-x: hidden; }
        .roster-page .container .main .participants-container.less {
          height: fit-content; }
        .roster-page .container .main .participants-container .vanpooler-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: auto;
          justify-content: flex-start;
          width: 180px;
          height: 200px;
          cursor: pointer;
          margin-bottom: 8px;
          border-radius: 8px;
          border: 1px solid #EDEDED;
          padding: 8px; }
          .roster-page .container .main .participants-container .vanpooler-container .image {
            cursor: pointer; }
            .roster-page .container .main .participants-container .vanpooler-container .image img {
              height: 90px;
              width: 90px;
              border-radius: 45px; }
          .roster-page .container .main .participants-container .vanpooler-container .name {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            color: #4A4A4A;
            margin-top: 10px;
            white-space: nowrap; }
          .roster-page .container .main .participants-container .vanpooler-container .company-role {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            text-align: center;
            color: #838383;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column; }
            .roster-page .container .main .participants-container .vanpooler-container .company-role .company {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383;
              font-weight: 400; }
            .roster-page .container .main .participants-container .vanpooler-container .company-role .type {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              font-weight: 400;
              text-transform: capitalize;
              font-weight: 400; }
              .roster-page .container .main .participants-container .vanpooler-container .company-role .type span {
                margin-left: 5px;
                margin-right: 5px; }
    .roster-page .container .sticky-footer {
      height: 88px;
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.25);
      text-align: center;
      background-color: #fff;
      align-items: center;
      display: flex;
      justify-content: center; }
      .roster-page .container .sticky-footer .invite-vanpool-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: #FFF !important;
        border: 2px solid #00A664;
        border-radius: 5px;
        color: #00A664;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
        .roster-page .container .sticky-footer .invite-vanpool-button:disabled {
          border: 2px solid #838383 !important;
          color: #838383;
          cursor: not-allowed;
          border: none; }

@media only screen and (min-width: 1011px) {
  .roster-page {
    padding-top: 160px; }
    .roster-page .header {
      border-radius: 8px 8px 0px 0px;
      height: 80px; }
      .roster-page .header .heading {
        font-size: 36px;
        line-height: 48px; } }

@media only screen and (max-width: 1010px) and (min-width: 700px) {
  .roster-page {
    padding-top: 30px;
    margin: 20px; }
    .roster-page .header {
      border-radius: 8px 8px 0px 0px;
      height: 80px; }
      .roster-page .header .heading {
        font-size: 36px;
        line-height: 48px; }
    .roster-page .main .participants-container {
      grid-template-columns: repeat(3, 210px); }
      .roster-page .main .participants-container .vanpooler-container {
        cursor: pointer; }
        .roster-page .main .participants-container .vanpooler-container .image {
          cursor: pointer; } }

@media only screen and (max-width: 700px) {
  .roster-page {
    padding-top: 40px;
    margin: 5px 15px; }
    .roster-page .container .header {
      padding-left: 10px;
      height: 65px; }
      .roster-page .container .header .backArrow {
        width: 10%;
        position: absolute;
        padding: 0px !important; }
        .roster-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .roster-page .container .header .heading {
        font-weight: 500;
        font-size: 24px;
        line-height: 48px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        margin: auto; }
    .roster-page .container .main {
      flex-direction: column;
      padding: 20px 20px;
      align-items: center; }
      .roster-page .container .main .participants-container {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        max-height: 500px;
        overflow: scroll;
        grid-row-gap: 30px;
        width: 100%; }
        .roster-page .container .main .participants-container .vanpooler-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: auto;
          justify-content: flex-start;
          width: 180px;
          height: 200px;
          cursor: pointer;
          margin-left: 24px; }
          .roster-page .container .main .participants-container .vanpooler-container .image {
            cursor: pointer; }
            .roster-page .container .main .participants-container .vanpooler-container .image img {
              height: 90px;
              width: 90px;
              border-radius: 45px; }
          .roster-page .container .main .participants-container .vanpooler-container .name {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            color: #4A4A4A;
            margin-top: 10px;
            white-space: nowrap; }
          .roster-page .container .main .participants-container .vanpooler-container .company-role {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            text-align: center;
            color: #838383;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column; }
            .roster-page .container .main .participants-container .vanpooler-container .company-role .company {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #838383;
              font-weight: 400; }
            .roster-page .container .main .participants-container .vanpooler-container .company-role .type {
              font-weight: 400; }
              .roster-page .container .main .participants-container .vanpooler-container .company-role .type span {
                margin-left: 5px;
                margin-right: 5px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.participant-popup {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 16%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 95%;
  width: 900px;
  height: 65%; }
  .participant-popup:after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -2;
    border-radius: 8px; }
  .participant-popup:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #FFF;
    z-index: -1;
    border-radius: 8px; }
  .participant-popup.show {
    display: block; }
  .participant-popup.hide {
    display: none; }
  .participant-popup .participant-details-popup {
    height: 100%;
    overflow: auto; }
    .participant-popup .participant-details-popup .close {
      height: 20px;
      width: 20px;
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer; }
    .participant-popup .participant-details-popup .participant-header .header {
      height: 80px;
      background: #00A664;
      border-radius: 5px 5px 0px 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end; }
      .participant-popup .participant-details-popup .participant-header .header .section {
        display: flex;
        justify-content: space-evenly;
        width: 50%; }
        .participant-popup .participant-details-popup .participant-header .header .section .icon img {
          height: 45px;
          margin-top: 35px; }
    .participant-popup .participant-details-popup .participant-header .header-content {
      display: flex;
      flex-direction: row; }
      .participant-popup .participant-details-popup .participant-header .header-content .profile-picture {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 6px solid #FFFFFF;
        background: #A27827;
        color: #FFFFFF;
        text-align: center;
        position: relative;
        top: -36px;
        left: 36px; }
        .participant-popup .participant-details-popup .participant-header .header-content .profile-picture .initials {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 38px;
          line-height: 70px;
          text-align: center;
          letter-spacing: -1px;
          color: #FFFFFF;
          margin-top: 10px; }
        .participant-popup .participant-details-popup .participant-header .header-content .profile-picture img {
          height: 90px;
          width: 90px;
          border-radius: 50%; }
      .participant-popup .participant-details-popup .participant-header .header-content .imageIcon {
        display: flex;
        height: 25px;
        width: 25px;
        background: #D5D5D5;
        border-radius: 50%;
        text-align: center;
        align-items: center;
        padding: 5px;
        left: 60px;
        position: relative;
        top: -20px;
        cursor: pointer; }
        .participant-popup .participant-details-popup .participant-header .header-content .imageIcon img {
          height: 16px;
          width: 16px; }
      .participant-popup .participant-details-popup .participant-header .header-content .roles {
        display: flex;
        flex-direction: row;
        margin: 8px 60px; }
        .participant-popup .participant-details-popup .participant-header .header-content .roles .role img {
          height: 20px; }
        .participant-popup .participant-details-popup .participant-header .header-content .roles .role .role-name {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          color: #4A4A4A;
          margin-left: 8px;
          text-transform: capitalize; }
        .participant-popup .participant-details-popup .participant-header .header-content .roles .second-role {
          margin-left: 15px; }
    .participant-popup .participant-details-popup .participant-details-popup-content {
      margin: 0 auto;
      margin-top: -50px;
      overflow: auto;
      height: 75%;
      padding: 0px 18%; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-name {
        font-family: 'DINNextLTPro-Regular';
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.5px;
        color: #4A4A4A; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #D5D5D5;
        padding: 16px 0px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details.mobile {
          display: none; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details.desktop {
          display: flex; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .details {
          display: flex;
          flex-direction: row;
          padding-bottom: 8px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .details .icon img {
            width: 18px;
            height: 18px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .details .value {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A;
            margin-left: 8px;
            margin-top: 4px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .details .clickableEmail {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            margin-left: 5px;
            margin-top: 3px;
            text-decoration: underline;
            color: #0F8952; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details {
          display: flex;
          flex-direction: row;
          margin-top: 16px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickablePhone, .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickableSms {
            padding: 8px 24px;
            align-items: center;
            width: 120px;
            border-radius: 5px;
            border: 2px solid #00A664;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickablePhone .icon, .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickableSms .icon {
              margin-right: 5px; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickablePhone .icon img, .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickableSms .icon img {
                width: 18px;
                height: 22px; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickablePhone .label, .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickableSms .label {
              color: #00A664; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickableSms {
            margin-left: 24px; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-company {
        border-bottom: 1px solid #D5D5D5;
        padding: 16px 0px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-company .compnay-label {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.25px;
          font-style: normal;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-size: 14px;
          color: #838383; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-company .company-name {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-top: 8px; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .other-details {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #D5D5D5; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .other-details .content {
          width: 50%;
          padding: 16px 0px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .other-details .content.first {
            border-right: 1px solid #D5D5D5; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .other-details .content.second {
            margin-left: 16px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .other-details .content .label {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-size: 14px;
            color: #838383; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .other-details .content .value {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A;
            margin-top: 8px; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-commute-days {
        margin-top: 24px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-commute-days .label {
          font-family: 'DINNextLTPro-Regular';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 128.571% */
          letter-spacing: -0.25px;
          color: #838383;
          margin-bottom: 10px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-commute-days .commute-days {
          display: flex;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-commute-days .commute-days .day-btn {
            display: flex;
            align-items: center;
            text-align: center;
            color: #4A4A4A;
            height: 26px;
            width: 44px;
            background: #D5D5D5;
            border-radius: 4px;
            margin-right: 4px;
            text-transform: uppercase;
            padding-top: 5px;
            justify-content: center;
            cursor: default; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile {
        width: 100%;
        background-color: #FFFFFF;
        z-index: 1; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile.active {
          left: 0; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .tools {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          margin: 0px 0px 32px;
          padding: 0 1rem; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .tools button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            width: 300px; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .tools button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .tools button:enabled {
              background-color: #00A664; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days {
          margin-top: 25px;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
          -moz-justify-content: space-between;
          -ms-justify-content: space-between;
          justify-content: space-between;
          -ms-flex-pack: space-between;
          border-bottom: 1px solid #D5D5D5; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .edit-icon {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #00A664;
            text-decoration: underline;
            cursor: pointer; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .days-editor {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .days-editor .flex-grow .commute-days .days .day-btn {
              height: 40px;
              width: 40px;
              border-radius: 50px;
              background: #FFFFFF;
              color: #0F8952;
              border: 2px solid #0F8952; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .days-editor .flex-grow .commute-days .days .day-btn.active {
                background: #D4EDE1;
                border: 2px solid #0F8952; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .days-editor .flex-grow .commute-days .days .day-btn:disabled {
              color: #049b5c;
              border: none;
              background: #FFFFFF;
              border: solid 1px #05cd7a;
              cursor: default; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .days-editor .flex-grow .commute-days .days .day-btn.unavailable {
              color: #D5D5D5;
              border: none;
              background: #FFFFFF;
              border: solid 2px #D5D5D5;
              cursor: default;
              text-decoration: line-through; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .days-editor .flex-grow .commute-days .days .error {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #F96302;
              margin-top: 12px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .schedule-label {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            margin-bottom: 8px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .commute-days {
            padding: 16px 0px; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .commute-days .commute-label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .commute-days .days {
              display: flex;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-days .commute-days .days .day-btn {
                display: flex;
                align-items: center;
                text-align: center;
                color: #4A4A4A;
                height: 26px;
                width: 44px;
                background: #D5D5D5;
                border-radius: 4px;
                margin-right: 4px;
                text-transform: uppercase;
                padding-top: 2px;
                justify-content: center; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .days {
          display: inline-block;
          padding: 0;
          margin-top: 5px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-label {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-size: 14px;
            color: #838383; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type {
            width: 100%;
            position: relative;
            padding: 16px 0px; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type.to-work {
              border-bottom: 1px solid #D5D5D5; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details {
              margin-top: 8px;
              margin-left: 0px; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-icon {
                width: 20px;
                display: flex;
                flex-direction: column;
                align-items: center; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-icon .group {
                  height: 16px;
                  width: 16px;
                  margin-top: 2px; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-icon .LocationGreen {
                  height: 24px;
                  width: 24px; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-icon .line {
                  width: 3px;
                  margin-top: 2px;
                  height: 40px; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-address .origin-address {
                margin-bottom: 15px;
                height: 58px; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-address .origin-address .start-address {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  color: #4A4A4A; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-address .origin-address .start-timings {
                  font-size: 14px;
                  padding-top: 2px; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-address .destination-address {
                margin-top: -12px; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-address .destination-address .end-address {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  color: #4A4A4A; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-address .destination-address .start-address {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.25px;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased;
                  color: #4A4A4A; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .van-route-details .route-address .destination-address .end-timings {
                  font-size: 14px;
                  padding-top: 2px; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .select {
              height: 52px;
              margin-bottom: 8px;
              width: 90%;
              display: flex;
              position: relative; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .select.first {
                margin-top: 8px; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .select .image-box {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 52px; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .select .image-box .imageBefore {
                  width: 16px;
                  height: 16px; }
                .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .select .image-box .imageAfter {
                  width: 24px;
                  height: 24px; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .select .start {
                border: 2px solid #D5D5D5;
                padding-left: 35px; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .participant-profile .participant-route .route-set .route-type .select .end {
                padding-left: 35px; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .common-toast-container {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .common-toast-container .message-container {
          padding: 0; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .common-toast-container .message-container .close-section {
            display: flex; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .common-toast-container .message-container .close-section .divider {
              border: 0.5px solid #FFFFFF;
              margin-top: -5px;
              margin-bottom: -5px; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .common-toast-container .message-container .close-section .close {
              padding: 5px 15px 5px 25px;
              width: 8px;
              height: 8px;
              position: relative;
              top: -12px; }
              .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .common-toast-container .message-container .close-section .close img {
                width: 10px;
                height: 10px; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .drop-pickup-details {
        display: flex;
        flex-direction: column;
        margin-top: 20px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .drop-pickup-details .section {
          margin-bottom: 16px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .drop-pickup-details .section .heading {
            font-family: 'DINNextLTPro-Regular';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: -0.25px;
            color: #838383;
            margin-bottom: 8px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .drop-pickup-details .section .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .drop-pickup-details .section .row .start {
              font-family: 'Proxima Nova';
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              /* 133.333% */
              letter-spacing: -0.25px;
              color: #4A4A4A; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .drop-pickup-details .section .row .end {
              font-family: 'DINNextLTPro-Regular';
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              /* 128.571% */
              letter-spacing: -0.25px;
              color: #4A4A4A; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles {
        display: flex;
        padding: 16px 30px;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #D5D5D5; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .slider-container .text {
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 600;
          width: 60%; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .slider-container .toggle {
          display: flex;
          flex-direction: row; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .slider-container .toggle .selected {
            padding-right: 8px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .max-trip {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .max-trip-disabled {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #F96302; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .line {
          border: 1px solid #D5D5D5;
          margin-top: 16px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .button-container {
          display: flex;
          justify-content: center;
          margin: 16px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .button-container .button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            width: 300px; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .button-container .button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .button-container .button:enabled {
              background-color: #00A664; }
      .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .remove-button-container {
        display: flex;
        justify-content: center;
        margin: 40px 16px 24px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .remove-button-container .remove-vanpool-button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #F96302;
          border-radius: 5px;
          border: 2px solid #F96302;
          padding: 8px 24px;
          width: 300px; }

@media only screen and (max-width: 700px) {
  .participant-popup {
    top: 10% !important;
    max-height: 90vh; }
    .participant-popup .participant-details-popup {
      max-height: 90vh; }
      .participant-popup .participant-details-popup .close {
        cursor: pointer; }
      .participant-popup .participant-details-popup .participant-header .header-content .profile-picture {
        left: 15px; }
      .participant-popup .participant-details-popup .participant-header .header-content .roles {
        margin: 8px 24px;
        flex-direction: column; }
        .participant-popup .participant-details-popup .participant-header .header-content .roles .second-role {
          margin-left: 0px; }
      .participant-popup .participant-details-popup .participant-details-popup-content {
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
        padding-bottom: 10px;
        overflow-y: scroll;
        max-height: 80vh;
        margin-top: -20px;
        padding: 0px 15px; }
        .participant-popup .participant-details-popup .participant-details-popup-content .sub-content {
          top: 0px;
          position: relative;
          padding: 0px 15px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details.desktop {
            display: none; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details.mobile {
            display: flex; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickablePhone .icon img, .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .contact-details .clikable-details .clickableSms .icon img {
            width: 22px;
            height: 22px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .drop-pickup-details .section .row .start {
            width: 50%; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .slider-container .text {
            width: 80%; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .manage-roles .button-container .button {
            width: 250px; }
          .participant-popup .participant-details-popup .participant-details-popup-content .sub-content .remove-button-container .remove-vanpool-button {
            width: 250px; } }

@media only screen and (max-width: 900px) and (min-width: 700px) {
  .participant-popup {
    top: 20%; }
    .participant-popup .participant-details-popup .close {
      cursor: pointer; }
    .participant-popup .participant-details-popup .participant-details-popup-content .sub-content {
      margin-top: 15px;
      margin-left: 30px; } }

.ds-terms-of-service {
  width: 650px;
  margin: 0 auto;
  height: 600px;
  contain: content;
  margin-top: 100px; }
  .ds-terms-of-service :first-child {
    width: 100%;
    height: 600px !important;
    display: block; }
  .ds-terms-of-service iframe {
    height: 600px !important; }
    .ds-terms-of-service iframe .globalstyleprovider-vtn2z1 {
      background-color: #fff !important; }

@media (min-width: 768px) {
  .ds-terms-of-service {
    width: 650px;
    height: 600px;
    margin: 0 auto;
    contain: content;
    margin-top: 100px; }
    .ds-terms-of-service :first-child {
      width: 100%;
      height: 600px !important;
      display: block; }
    .ds-terms-of-service iframe {
      height: 600px !important; }
      .ds-terms-of-service iframe .globalstyleprovider-vtn2z1 {
        background-color: #fff !important; } }

@media only screen and (max-width: 700px) {
  .ds-terms-of-service {
    width: auto;
    margin: 100px 40px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

.payment-history:has(> .payment-fuel-card-page) {
  width: 100%;
  max-width: 100%;
  margin: 0px;
  background: #E5E5E5;
  padding-bottom: 30px !important; }

.payment-history .payment-fuel-card-page {
  height: fit-content; }
  .payment-history .payment-fuel-card-page .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px; }
    .payment-history .payment-fuel-card-page .container .header {
      background: #00A664;
      border: 1px solid #D5D5D5;
      border-radius: 8px 8px 0px 0px;
      height: 80px;
      display: flex;
      flex-direction: row; }
      .payment-history .payment-fuel-card-page .container .header .backArrow {
        width: 10%;
        padding: 22px; }
        .payment-history .payment-fuel-card-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .payment-history .payment-fuel-card-page .container .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        width: 85%;
        padding: 16px; }
  .payment-history .payment-fuel-card-page .van-details, .payment-history .payment-fuel-card-page .reset-collapsible {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px 24px 32px; }
    .payment-history .payment-fuel-card-page .van-details .top-section, .payment-history .payment-fuel-card-page .reset-collapsible .top-section {
      display: flex;
      justify-content: space-between; }
      .payment-history .payment-fuel-card-page .van-details .top-section .van-details-container, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .van-details-container {
        display: flex;
        align-items: center; }
        .payment-history .payment-fuel-card-page .van-details .top-section .van-details-container img, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .van-details-container img {
          height: 48px;
          width: 57px;
          margin-right: 20px; }
        .payment-history .payment-fuel-card-page .van-details .top-section .van-details-container .vanpool, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .van-details-container .vanpool {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly; }
          .payment-history .payment-fuel-card-page .van-details .top-section .van-details-container .vanpool .vanpool-name, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .van-details-container .vanpool .vanpool-name {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A; }
          .payment-history .payment-fuel-card-page .van-details .top-section .van-details-container .vanpool .vanpool-id, .payment-history .payment-fuel-card-page .van-details .top-section .van-details-container .vanpool .account, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .van-details-container .vanpool .vanpool-id, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .van-details-container .vanpool .account {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #838383; }
        .payment-history .payment-fuel-card-page .van-details .top-section .van-details-container .commute-card, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .van-details-container .commute-card {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.25px; }
      .payment-history .payment-fuel-card-page .van-details .top-section .right-section .active .active-card, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .right-section .active .active-card {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383;
        display: flex;
        justify-content: flex-end; }
      .payment-history .payment-fuel-card-page .van-details .top-section .right-section .active .inactive-card, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .right-section .active .inactive-card {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383;
        display: flex;
        justify-content: flex-end;
        padding-top: 10px; }
      .payment-history .payment-fuel-card-page .van-details .top-section .right-section .active img, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .right-section .active img {
        width: 20px;
        height: 18px;
        top: 2px;
        left: 74px;
        gap: 0px;
        margin-left: 5px; }
      .payment-history .payment-fuel-card-page .van-details .top-section .last-updated, .payment-history .payment-fuel-card-page .reset-collapsible .top-section .last-updated {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: left;
        align-self: center;
        color: #838383; }
  .payment-history .payment-fuel-card-page .mid-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D5D5D5; }
    .payment-history .payment-fuel-card-page .mid-section .card-details {
      height: 169px; }
      .payment-history .payment-fuel-card-page .mid-section .card-details .info-text {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
      .payment-history .payment-fuel-card-page .mid-section .card-details .add-card {
        margin-top: 16px; }
        .payment-history .payment-fuel-card-page .mid-section .card-details .add-card .text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 13px;
          font-weight: 700;
          line-height: 15.6px;
          text-align: left;
          height: 21px; }
        .payment-history .payment-fuel-card-page .mid-section .card-details .add-card .number-input {
          height: 56px;
          width: 280px;
          border: 2px solid #D5D5D5;
          outline: none;
          transition: border-color 0.3s; }
        .payment-history .payment-fuel-card-page .mid-section .card-details .add-card .number-input:focus {
          border-color: #D4EDE1;
          border-bottom: 2px solid #00A664;
          box-shadow: none; }
      .payment-history .payment-fuel-card-page .mid-section .card-details .bottom-text {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383; }
    .payment-history .payment-fuel-card-page .mid-section .wex-web img {
      height: 168px;
      width: 269px; }
  .payment-history .payment-fuel-card-page .bottom-section {
    padding-top: 12px; }
    .payment-history .payment-fuel-card-page .bottom-section .text-pin {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A; }
    .payment-history .payment-fuel-card-page .bottom-section .text {
      font-family: 'DINNextLTPro-Regular';
      font-size: 13px;
      font-weight: 700;
      line-height: 15.6px;
      text-align: left;
      height: 21px;
      margin-top: 15px; }
    .payment-history .payment-fuel-card-page .bottom-section .number-input {
      height: 56px;
      width: 280px;
      border: 2px solid #D5D5D5;
      outline: none;
      transition: border-color 0.3s; }
    .payment-history .payment-fuel-card-page .bottom-section .number-input:focus {
      border-color: #D4EDE1;
      border-bottom: 2px solid #00A664;
      box-shadow: none; }
  .payment-history .payment-fuel-card-page .primary.button {
    font-family: 'Proxima Nova';
    padding: 5px 24px;
    border-radius: 5px;
    margin-top: 21px;
    width: 280px;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; }
  .payment-history .payment-fuel-card-page .primary.button.disabled {
    opacity: 0.5;
    /* Reduce opacity to indicate disabled state */
    pointer-events: none;
    /* Disable pointer events to prevent click */
    background-color: #838383;
    border: none; }
  .payment-history .payment-fuel-card-page .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 154%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center; }
  .payment-history .payment-fuel-card-page .loader {
    border: 7px solid #D4EDE1;
    /* Light grey */
    border-top: 7px solid #00a664;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .payment-history .payment-fuel-card-page .common-toast-container .message-container.error {
    padding: 8px 12px;
    width: 50%; }
    .payment-history .payment-fuel-card-page .common-toast-container .message-container.error .text {
      padding: 0; }
      .payment-history .payment-fuel-card-page .common-toast-container .message-container.error .text p {
        margin-bottom: 0; }
    .payment-history .payment-fuel-card-page .common-toast-container .message-container.error .close-section .divider {
      height: 24px;
      margin: 0;
      align-self: center; }
    .payment-history .payment-fuel-card-page .common-toast-container .message-container.error .close-section .close {
      align-self: center;
      padding-right: 10px;
      padding-top: 5px; }
  .payment-history .payment-fuel-card-page .common-toast-container .message-container.success {
    padding: 8px 12px; }
    .payment-history .payment-fuel-card-page .common-toast-container .message-container.success .text {
      padding: 0;
      margin-right: 50px; }
    .payment-history .payment-fuel-card-page .common-toast-container .message-container.success .close-section .divider {
      height: 24px;
      align-self: center; }
    .payment-history .payment-fuel-card-page .common-toast-container .message-container.success .close-section .close {
      padding-right: 0;
      padding-top: 5px;
      align-self: center; }

.payment-history .reset-collapsible {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  border: 1px solid #D5D5D5;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 24px 32px; }
  .payment-history .reset-collapsible .Collapsible {
    border: none;
    box-shadow: none; }
    .payment-history .reset-collapsible .Collapsible .collapsible-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .payment-history .reset-collapsible .Collapsible .collapsible-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: -0.5px;
        font-size: 21px;
        color: #4A4A4A; }
      .payment-history .reset-collapsible .Collapsible .collapsible-container .angle-down-button {
        color: #00A664;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        margin-right: 15px;
        margin-top: 8px; }
    .payment-history .reset-collapsible .Collapsible .reset-pin {
      padding-left: 20px;
      display: flex; }
      .payment-history .reset-collapsible .Collapsible .reset-pin .text-input {
        padding-right: 50px; }
        .payment-history .reset-collapsible .Collapsible .reset-pin .text-input .text {
          font-family: 'DINNextLTPro-Regular';
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          padding-bottom: 5px; }
        .payment-history .reset-collapsible .Collapsible .reset-pin .text-input .input-box .fancy-input .label {
          display: none; }
        .payment-history .reset-collapsible .Collapsible .reset-pin .text-input .input-box .fancy-input input {
          border: 2px solid #D5D5D5;
          outline: none;
          transition: border-color 0.3s; }
        .payment-history .reset-collapsible .Collapsible .reset-pin .text-input .input-box .fancy-input input:focus {
          border-color: #D4EDE1;
          border-bottom: 2px solid #00A664;
          box-shadow: none; }
      .payment-history .reset-collapsible .Collapsible .reset-pin .primary.button {
        font-family: 'Proxima Nova';
        padding: 5px 24px;
        border-radius: 5px;
        margin-top: 21px;
        width: 160px;
        height: 40px;
        font-size: 16px;
        font-weight: 700; }
      .payment-history .reset-collapsible .Collapsible .reset-pin .primary.button.disabled {
        opacity: 0.5;
        /* Reduce opacity to indicate disabled state */
        pointer-events: none;
        /* Disable pointer events to prevent click */
        background-color: #838383;
        border: none; }
      .payment-history .reset-collapsible .Collapsible .reset-pin .common-toast-container {
        display: flex;
        justify-content: center;
        margin-top: 21px;
        margin-left: 100px;
        width: 227px;
        height: 40px; }
        .payment-history .reset-collapsible .Collapsible .reset-pin .common-toast-container .message-container {
          margin-left: 0;
          padding: 0;
          width: max-content; }
          .payment-history .reset-collapsible .Collapsible .reset-pin .common-toast-container .message-container .text {
            padding: 8px 0;
            width: fit-content;
            margin-left: 8px;
            white-space: nowrap; }
    .payment-history .reset-collapsible .Collapsible .is-open .collapsible-container .angle-down-button {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg); }

.payment-history .transaction {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  border: 1px solid #D5D5D5;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 24px 16px 24px 32px; }
  .payment-history .transaction .Collapsible {
    margin-bottom: 20px;
    box-shadow: none; }
    .payment-history .transaction .Collapsible .etable-heading .etable-row .etable-column {
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      flex-direction: row; }
      .payment-history .transaction .Collapsible .etable-heading .etable-row .etable-column .etable-head {
        font-family: 'DINNextLTPro-Regular';
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: -0.5px;
        font-size: 21px;
        color: #4A4A4A;
        margin-left: 15px; }
        .payment-history .transaction .Collapsible .etable-heading .etable-row .etable-column .etable-head .angle-down-button {
          color: #00A664;
          width: 24px;
          height: 24px;
          margin-left: 10px;
          margin-right: 15px; }
    .payment-history .transaction .Collapsible .etable-body .etable-row {
      display: flex;
      flex-direction: row; }
      .payment-history .transaction .Collapsible .etable-body .etable-row .etable-column {
        display: flex; }
        .payment-history .transaction .Collapsible .etable-body .etable-row .etable-column .etable-cell {
          font-size: .6875rem;
          color: gray;
          padding-right: 3em; }
    .payment-history .transaction .Collapsible .Collapsible__contentInner {
      overflow: auto; }
      .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table {
        padding: 0 24px; }
        .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels {
          border-top: 1px solid #D5D5D5;
          border-bottom: 1px solid #D5D5D5;
          display: flex;
          padding: 10px 0; }
          .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header {
            font-family: 'DINNextLTPro-Regular';
            font-size: 14px;
            font-weight: 500;
            color: #393A3B;
            text-align: center;
            padding-right: 0; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.fc-service-month {
              flex: 0 0 110px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.fc-activity-date {
              flex: none; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.merchant, .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header .gallons {
              flex: 0 0 260px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.gallons {
              flex: 0 0 185px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.auth-num {
              flex: 0 0 240px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.right {
              flex-grow: 1;
              text-align: right; }
      .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table {
        padding: 0 24px; }
        .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row {
          border-bottom: 1px solid #D5D5D5; }
          .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .mobile-container {
            display: contents; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .mobile-container.merchant, .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .mobile-container .auth-num {
              flex: 0 0 260px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .mobile-container.auth-num {
              flex: 0 0 190px; }
          .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell {
            flex: none;
            text-align: left;
            padding: 16px 0 16px 0; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.fc-activity-date {
              flex: 0 0 58px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.merchant {
              margin-left: 118px;
              width: 200px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.auth-num {
              margin-left: 38px;
              flex: none;
              width: 100px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.gallons {
              margin-left: 130px;
              width: 100px; }
            .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.amount {
              text-align: right;
              color: #130C0E;
              margin-left: 50px;
              flex: none;
              width: 100px; }
          .payment-history .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row:last-child {
            border-bottom: none; }
  .payment-history .transaction .Collapsible .is-open .etable-heading {
    border-radius: 8px 8px 0 0; }
    .payment-history .transaction .Collapsible .is-open .etable-heading .etable-row .etable-column .etable-head .angle-down-button {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg); }
  .payment-history .transaction .transaction-heading {
    font-family: 'DINNextLTPro-Regular';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.5px;
    font-size: 21px;
    color: #4A4A4A;
    display: flex;
    justify-content: space-between; }
    .payment-history .transaction .transaction-heading label {
      font-family: 'Proxima Nova Bold';
      font-size: 14px;
      font-weight: 900;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      text-decoration: underline; }
    .payment-history .transaction .transaction-heading .common-toast-container {
      position: fixed;
      bottom: 24rem;
      left: 50%;
      transform: translateX(-50%);
      /* transform: translateY(-244%); */
      z-index: 1;
      height: 40px; }
    .payment-history .transaction .transaction-heading .message-container {
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }
    .payment-history .transaction .transaction-heading .close-section {
      display: flex;
      align-items: center; }
      .payment-history .transaction .transaction-heading .close-section .divider {
        height: 24px; }
    .payment-history .transaction .transaction-heading .close {
      cursor: pointer;
      margin-left: 10px; }
  .payment-history .transaction .no-transactions {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #4A4A4A;
    padding: 50px 349px 58.5px 349px; }

.payment-history .fuel-card-assistance {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  border: 1px solid #D5D5D5;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 24px 16px 24px 32px; }
  .payment-history .fuel-card-assistance .sub-heading {
    font-family: 'DINNextLTPro-Regular';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.5px;
    font-size: 21px;
    color: #4A4A4A; }
  .payment-history .fuel-card-assistance .text {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #4A4A4A;
    margin-bottom: 10px; }
  .payment-history .fuel-card-assistance .callable {
    font-weight: 600;
    font-family: 'Proxima Nova Bold'; }
  .payment-history .fuel-card-assistance .sub-text {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #4A4A4A; }

@media only screen and (max-width: 900px) and (min-width: 751px) {
  .payment-history {
    padding-top: 100px !important; } }

@media only screen and (max-width: 1023px) and (min-width: 701px) {
  .payment-history .payment-fuel-card-page .reset-collapsible .Collapsible .reset-pin .common-toast-container {
    justify-content: center;
    margin-top: 90px;
    margin-left: 0px;
    right: 185px;
    width: 227px;
    height: 40px;
    position: relative; }
  .payment-history .payment-fuel-card-page .transaction .no-transactions {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #4A4A4A;
    padding: 50px 20px 58.5px 20px;
    text-align: center; }
  .payment-history .payment-fuel-card-page .transaction .Collapsible {
    overflow-x: auto !important; }
  .payment-history .payment-fuel-card-page .transaction .transaction-heading .common-toast-container {
    bottom: 14rem; }
  .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .card-summary-table {
    padding: 0 20px; }
    .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header {
      flex: none; }
      .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.fc-activity-date {
        width: 80px; }
      .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.merchant {
        width: 150px;
        padding-left: 20px;
        flex: none; }
      .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.auth-num, .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.gallons {
        width: 100px;
        flex: none;
        margin-left: 50px; }
      .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .card-summary-table .header-labels .etable-header.right {
        width: 100px;
        flex-grow: unset;
        padding-left: 66px;
        padding-right: 0; }
  .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table {
    padding: 0 20px; }
    .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.fc-activity-date {
      flex: none;
      margin-left: 2px; }
    .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.merchant {
      flex: none;
      text-overflow: ellipsis;
      margin-left: 78px;
      width: 150px; }
    .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.auth-num {
      flex: none;
      margin-left: 2px; }
    .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.gallons {
      margin-left: 69px; }
    .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .etable-cell.right {
      flex: none;
      width: 85px;
      margin-left: 0; }
  .payment-history .payment-fuel-card-page .van-details .mid-section {
    padding-bottom: 35px; }
    .payment-history .payment-fuel-card-page .van-details .mid-section .card-details .bottom-text {
      margin-top: 0; } }

@media only screen and (max-width: 767px) {
  .payment-history .payment-fuel-card-page {
    background: #FFFFFF; }
    .payment-history .payment-fuel-card-page .callable {
      color: #00A664;
      text-decoration: underline;
      font-family: 'Proxima Nova Bold'; }
    .payment-history .payment-fuel-card-page .container {
      border: none; }
      .payment-history .payment-fuel-card-page .container .header {
        border: none;
        border-radius: 0; }
    .payment-history .payment-fuel-card-page .van-details {
      width: -webkit-fill-available;
      margin: 6px;
      padding: 16px 0 16px 16px;
      display: flex;
      flex-direction: column; }
      .payment-history .payment-fuel-card-page .van-details .top-section .van-details-container {
        display: none; }
      .payment-history .payment-fuel-card-page .van-details .top-section .van-details-mobile {
        display: flex;
        align-items: center; }
        .payment-history .payment-fuel-card-page .van-details .top-section .van-details-mobile img {
          height: 48px;
          width: 57px;
          margin-right: 20px; }
        .payment-history .payment-fuel-card-page .van-details .top-section .van-details-mobile .vanpool {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly; }
          .payment-history .payment-fuel-card-page .van-details .top-section .van-details-mobile .vanpool .vanpool-name {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A; }
          .payment-history .payment-fuel-card-page .van-details .top-section .van-details-mobile .vanpool .vanpool-id, .payment-history .payment-fuel-card-page .van-details .top-section .van-details-mobile .vanpool .account {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #838383; }
        .payment-history .payment-fuel-card-page .van-details .top-section .van-details-mobile .commute-card {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.25px; }
      .payment-history .payment-fuel-card-page .van-details .fuel-card {
        font-family: 'DINNextLTPro-Regular';
        font-size: 58px;
        font-style: normal;
        font-weight: 500;
        line-height: 72px;
        letter-spacing: -1px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 8px; }
        .payment-history .payment-fuel-card-page .van-details .fuel-card .active {
          align-self: center;
          margin-right: 16px; }
          .payment-history .payment-fuel-card-page .van-details .fuel-card .active .active-card, .payment-history .payment-fuel-card-page .van-details .fuel-card .active .inactive-card {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #838383;
            display: flex;
            justify-content: flex-end; }
            .payment-history .payment-fuel-card-page .van-details .fuel-card .active .active-card img, .payment-history .payment-fuel-card-page .van-details .fuel-card .active .inactive-card img {
              width: 20px;
              height: 18px;
              top: 2px;
              left: 74px;
              gap: 0px;
              margin-left: 5px; }
      .payment-history .payment-fuel-card-page .van-details .mid-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        border: none; }
        .payment-history .payment-fuel-card-page .van-details .mid-section .card-details .info-text {
          display: none; }
        .payment-history .payment-fuel-card-page .van-details .mid-section .card-details .info {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-left: 0;
          padding-bottom: 20px; }
        .payment-history .payment-fuel-card-page .van-details .mid-section .card-details .wex-card {
          margin-top: 20px;
          margin-left: 45px; }
          .payment-history .payment-fuel-card-page .van-details .mid-section .card-details .wex-card img {
            width: 245px;
            height: 153px;
            gap: 0px; }
        .payment-history .payment-fuel-card-page .van-details .mid-section .card-details .bottom-text {
          padding-bottom: 20px;
          border-bottom: 1px solid #D5D5D5;
          margin-right: 16px; }
        .payment-history .payment-fuel-card-page .van-details .mid-section .wex-web {
          display: none; }
      .payment-history .payment-fuel-card-page .van-details .bottom-section {
        margin-top: 200px; }
      .payment-history .payment-fuel-card-page .van-details .common-toast-container .message-container.error {
        padding: 8px 12px;
        width: 100%; }
    .payment-history .payment-fuel-card-page .right-section .last-updated, .payment-history .payment-fuel-card-page .right-section .active {
      display: none; }
    .payment-history .payment-fuel-card-page .reset-collapsible {
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      background-color: #FFFFFF;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      border: 1px solid #D5D5D5;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      padding: 16px 0 16px 16px;
      margin: 6px;
      width: -webkit-fill-available; }
      .payment-history .payment-fuel-card-page .reset-collapsible .Collapsible {
        border: none;
        box-shadow: none; }
        .payment-history .payment-fuel-card-page .reset-collapsible .Collapsible .reset-pin {
          padding-left: 0; }
          .payment-history .payment-fuel-card-page .reset-collapsible .Collapsible .reset-pin .common-toast-container {
            display: flex;
            justify-content: center;
            margin-top: 85px;
            margin-left: 0;
            width: 227px;
            height: 40px;
            position: relative;
            right: 19rem; }
          .payment-history .payment-fuel-card-page .reset-collapsible .Collapsible .reset-pin .primary.button {
            opacity: 0.5;
            pointer-events: none;
            background-color: #838383;
            border: none;
            white-space: nowrap;
            right: 15px;
            position: relative; }
    .payment-history .payment-fuel-card-page .transaction {
      width: -webkit-fill-available;
      padding: 0;
      margin: 6px; }
      .payment-history .payment-fuel-card-page .transaction .no-transactions {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A;
        padding: 8px 16px;
        text-align: center; }
      .payment-history .payment-fuel-card-page .transaction .update {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        align-self: center;
        color: #838383;
        padding-left: 16px; }
      .payment-history .payment-fuel-card-page .transaction .transaction-heading {
        padding: 16px 16px 0 16px; }
        .payment-history .payment-fuel-card-page .transaction .transaction-heading .common-toast-container {
          bottom: 2rem;
          height: 40px;
          width: 100%; }
        .payment-history .payment-fuel-card-page .transaction .transaction-heading .export-csv {
          display: contents; }
          .payment-history .payment-fuel-card-page .transaction .transaction-heading .export-csv button {
            font-family: 'Proxima Nova Bold';
            font-size: 14px;
            font-weight: 900;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left;
            text-decoration: underline;
            color: #00A664; }
      .payment-history .payment-fuel-card-page .transaction .Collapsible {
        border: none;
        width: 100%; }
        .payment-history .payment-fuel-card-page .transaction .Collapsible .etable-heading .etable-row {
          background-color: #f3f3f3; }
        .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .card-summary-table {
          display: none; }
        .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .mobile-only-label {
          font-size: 14px; }
        .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row {
          padding-bottom: 10px; }
          .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-column .mobile-container {
            display: none; }
          .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell.spacer, .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell.fc-activity-date, .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell.gallons, .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell.right {
            display: none; }
          .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only {
            display: flex; }
            .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .date-container {
              display: flex;
              flex-direction: column;
              margin-right: 20px; }
              .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .date-container .date {
                font-family: 'DINNextLTPro-Regular';
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center; }
              .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .date-container .day {
                font-family: 'DINNextLTPro-Regular';
                font-size: 12px;
                font-weight: 500;
                line-height: 8px;
                letter-spacing: 0px;
                text-align: center; }
            .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .other-info .merchant-amount {
              padding-bottom: 5px;
              color: #393A3B;
              display: flex;
              justify-content: space-between;
              width: 290px; }
              .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .other-info .merchant-amount .merchant {
                font-family: 'DINNextLTPro-Regular';
                padding-right: 30px;
                font-size: 14px;
                text-overflow: ellipsis;
                font-weight: 500;
                line-height: 18px; }
              .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .other-info .merchant-amount .amount {
                font-family: 'Proxima Nova';
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.25px;
                text-align: right; }
            .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .other-info .auth-gallons {
              display: flex;
              justify-content: space-between;
              width: 290px;
              font-family: 'DINNextLTPro-Regular';
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: -0.25px;
              text-align: left;
              color: #656565; }
              .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .other-info .auth-gallons .auth-num {
                flex: none;
                font-size: 14px;
                text-overflow: ellipsis; }
              .payment-history .payment-fuel-card-page .transaction .Collapsible .Collapsible__contentInner .fuel-card-charges-table .etable-row .etable-cell .mobile-only .other-info .auth-gallons .gallons {
                flex: none; }
    .payment-history .payment-fuel-card-page .fuel-card-assistance {
      width: -webkit-fill-available;
      margin: 6px;
      display: flex;
      padding: 24px 16px 24px 16px; }
      .payment-history .payment-fuel-card-page .fuel-card-assistance .sub-heading {
        display: none; }
      .payment-history .payment-fuel-card-page .fuel-card-assistance .icon {
        height: 40px;
        width: 40px;
        background-color: #E5F6EF;
        padding: 8px;
        border-radius: 4px;
        margin-right: 15px; }
        .payment-history .payment-fuel-card-page .fuel-card-assistance .icon .phone-green {
          height: 24px;
          width: 24px; }
      .payment-history .payment-fuel-card-page .fuel-card-assistance .text .callable {
        font-size: 16px; }
      .payment-history .payment-fuel-card-page .fuel-card-assistance .sub-text {
        display: none; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Light";
  src: local("DINNextLTPro-Light"), url("./fonts/DINNextLTPro-Light.otf") format("opentype"); }

html:has(.driver-schedule-page) {
  overflow: hidden; }

.driver-schedule-page {
  background: #E5E5E5;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .driver-schedule-page .common-toast-container {
    position: absolute;
    bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0; }
  .driver-schedule-page .charges-popup {
    top: 20% !important; }
    .driver-schedule-page .charges-popup .edit-pref-popup label {
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      font-weight: 700; }
    .driver-schedule-page .charges-popup .edit-pref-popup .blue-notes {
      background: #EDF4FC;
      box-shadow: 0px 1px 0px rgba(213, 213, 213, 0.2);
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #1E3A5A;
      display: flex;
      width: 100%;
      padding: 16px;
      margin-top: 40px; }
      .driver-schedule-page .charges-popup .edit-pref-popup .blue-notes .info-circle {
        height: 20px;
        margin-right: 18px; }
    .driver-schedule-page .charges-popup .edit-pref-popup .save-preferences-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #FFFFFF;
      border-radius: 5px;
      height: 40px;
      padding: 8px 24px;
      margin: 24px; }
      .driver-schedule-page .charges-popup .edit-pref-popup .save-preferences-button:disabled {
        background-color: #838383;
        cursor: not-allowed;
        border: none; }
      .driver-schedule-page .charges-popup .edit-pref-popup .save-preferences-button:enabled {
        background-color: #00A664; }
  .driver-schedule-page .driver-schedule-container {
    max-width: 100%; }
    .driver-schedule-page .driver-schedule-container .bottom-button-section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 24px 0px;
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.25); }
      .driver-schedule-page .driver-schedule-container .bottom-button-section button {
        height: 40px;
        padding: 0px 24px;
        max-width: 90%; }
        .driver-schedule-page .driver-schedule-container .bottom-button-section button.undo-button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
          .driver-schedule-page .driver-schedule-container .bottom-button-section button.undo-button:disabled {
            border: 2px solid #838383 !important;
            color: #838383;
            cursor: not-allowed;
            border: none; }
        .driver-schedule-page .driver-schedule-container .bottom-button-section button.review-button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #FFFFFF;
          border-radius: 5px; }
          .driver-schedule-page .driver-schedule-container .bottom-button-section button.review-button:disabled {
            background-color: #838383;
            cursor: not-allowed;
            border: none; }
          .driver-schedule-page .driver-schedule-container .bottom-button-section button.review-button:enabled {
            background-color: #00A664; }
    .driver-schedule-page .driver-schedule-container .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      padding: 16px 24px;
      margin: 0; }
    .driver-schedule-page .driver-schedule-container .second-heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      padding-left: 16px;
      color: #4A4A4A; }
    .driver-schedule-page .driver-schedule-container .driver-schedule-list {
      background: #FFFFFF;
      margin: 0 auto;
      max-width: 950px;
      border-radius: 8px;
      border: 1px solid #D5D5D5; }
      .driver-schedule-page .driver-schedule-container .driver-schedule-list .heading {
        border-bottom: 1px solid #EDEDED; }
      .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule {
        overflow: auto;
        margin: 10px auto;
        display: flex;
        flex-direction: column; }
        .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container {
          padding: 24px;
          width: 100%;
          border-radius: 8px;
          border: 1px solid #D5D5D5;
          background: #EDEDED;
          box-shadow: 0px 1px 0px 0px #D5D5D5; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .preference-dropdown-heading {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: #4A4A4A; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field {
            width: 100%; }
            .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container {
              position: relative;
              width: 100% !important; }
              .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .flexibility-label {
                font-family: 'DINNextLTPro-Medium';
                font-style: normal;
                font-size: 13px;
                line-height: 25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                font-weight: 700;
                color: #4A4A4A; }
              .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .flexibility-header {
                width: 100%;
                padding: 15px; }
              .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .placeholder {
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                color: #4A4A4A; }
              .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .dropdown-container {
                position: relative;
                width: 100%; }
                .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .dropdown-container .dropdown-list {
                  width: 100%; }
                  .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .dropdown-container .dropdown-list .list-item {
                    font-family: 'DINNextLTPro-Regular';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: -0.25px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: antialiased;
                    background-color: #FFFFFF;
                    color: #0F8952;
                    height: 40px;
                    font-weight: 400;
                    cursor: pointer; }
                    .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .dropdown-container .dropdown-list .list-item:first-child {
                      margin-top: unset !important; }
                    .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .dropdown-container .dropdown-list .list-item[addedclassname="selected"] {
                      background-color: #E7E7E7;
                      color: #130C0E !important; }
                    .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .preference-dropdown-container .field .flexibility-container .dropdown-container .dropdown-list .list-item:hover {
                      background: #EBF4F1 !important;
                      color: #0F8952; }
        .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days {
          display: flex;
          justify-content: space-between;
          background-color: #FCFCFE;
          margin-top: 20px;
          width: 530px;
          height: 62px;
          border-radius: 8px;
          padding: 16px; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days .text {
            font-family: 'Proxima Nova';
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.25px;
            text-align: left; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days .toggle-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            justify-content: space-between; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days .toggle {
            position: relative;
            display: inline-block;
            min-width: 56px;
            height: 26px; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days .toggle input {
            opacity: 0;
            height: 1px;
            width: 1px; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #838383;
            -webkit-transition: .4s;
            transition: .4s; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 0px;
            bottom: 0px;
            background-color: #FFFFFF;
            -webkit-transition: .4s;
            transition: .4s;
            border: solid 1px #838383; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days input:checked + .slider {
            background-color: #049b5c; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days input:focus + .slider {
            box-shadow: 0 0 0.25rem #4A90E2; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days input:checked + .slider:before {
            -webkit-transform: translateX(30px);
            -ms-transform: translateX(30px);
            transform: translateX(30px); }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days .slider.round {
            border-radius: 34px; }
          .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days .slider.round:before {
            border-radius: 50%; }
    .driver-schedule-page .driver-schedule-container .review-driver-schedule {
      background: #FFFFFF;
      margin: 0 auto;
      max-width: 950px;
      border-radius: 8px;
      border: 1px solid #D5D5D5; }
      .driver-schedule-page .driver-schedule-container .review-driver-schedule .second-heading {
        font-weight: 400 !important; }
      .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section {
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: fit-content;
        min-width: 60%;
        max-width: 90%;
        margin-bottom: 20px; }
        .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .divider {
          border-bottom: 1px solid #D5D5D5; }
        .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .second-heading {
          padding: unset !important; }
        .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section {
          margin: 16px; }
          .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .icon-container {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E5F6EF; }
            .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .icon-container .icon {
              width: 24px;
              height: 24px; }
          .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .top {
            display: flex;
            align-items: center; }
            .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .top .text {
              display: flex;
              flex-direction: column;
              margin-left: 16px; }
              .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .top .text h2 {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 21px;
                line-height: 25px;
                letter-spacing: -0.5px;
                color: #4A4A4A;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                margin: 0; }
              .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .top .text .sub-text {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased; }
          .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .bottom {
            display: flex;
            flex-direction: column; }
            .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .bottom .month-section {
              display: flex;
              align-items: flex-start;
              margin-top: 16px; }
              .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .bottom .month-section .month-name {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.25px;
                font-weight: 500;
                color: #4A4A4A;
                text-transform: uppercase;
                width: 40px;
                text-align: center; }
            .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .bottom .dates-list {
              display: flex;
              flex-wrap: wrap;
              margin-left: 16px;
              gap: 6px; }
              .driver-schedule-page .driver-schedule-container .review-driver-schedule .changes-section .section .bottom .dates-list .date-section {
                margin-right: 6px; }
  .driver-schedule-page .Collapsible {
    height: fit-content;
    width: 100%;
    margin-top: 7px; }
    .driver-schedule-page .Collapsible .Collapsible__trigger.is-open {
      position: sticky;
      top: -2px; }
    .driver-schedule-page .Collapsible .collapsible-heading-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #FFFFFF;
      padding: 18px 10px;
      border-bottom: 1px solid #EDEDED; }
      .driver-schedule-page .Collapsible .collapsible-heading-container.empty[aria-expanded='true'] {
        border-bottom: none !important; }
      .driver-schedule-page .Collapsible .collapsible-heading-container .heading1 .month-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #130C0E; }
      .driver-schedule-page .Collapsible .collapsible-heading-container .arrow {
        border-right: 3px solid;
        border-bottom: 3px solid;
        padding: 6px;
        border-color: #00A664;
        height: 5px; }
        .driver-schedule-page .Collapsible .collapsible-heading-container .arrow.top {
          transform: rotate(-135);
          -webkit-transform: rotate(-135deg);
          transition: transform 0.2s linear, opacity 0.2s linear; }
        .driver-schedule-page .Collapsible .collapsible-heading-container .arrow.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transition: transform 0.2s linear, opacity 0.2s linear; }
    .driver-schedule-page .Collapsible .collapsible-content {
      display: flex;
      flex-direction: column; }
      .driver-schedule-page .Collapsible .collapsible-content .row-container {
        display: flex;
        flex-direction: column; }
        .driver-schedule-page .Collapsible .collapsible-content .row-container .no-data-text {
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          padding: 18px 10px;
          border-bottom: 1px solid #EDEDED; }
        .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row {
          display: flex;
          justify-content: space-between;
          padding: 14px 0px;
          border-bottom: 1px solid #EDEDED;
          flex-wrap: wrap; }
          .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .left-section {
            display: flex;
            justify-content: flex-start;
            align-items: center; }
            .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .left-section .vanpooler-name {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-weight: 600;
              color: #4A4A4A;
              text-transform: capitalize; }
              .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .left-section .vanpooler-name.unassigned {
                font-weight: 400; }
            .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .left-section .profile-image,
            .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .left-section .image-container {
              height: 48px;
              width: 48px;
              border-radius: 24px;
              margin-right: 15px; }
            .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .left-section .image-container {
              padding-top: unset !important; }
              .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .left-section .image-container div {
                font-family: 'Proxima Nova Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased; }
            .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .left-section .border-line {
              border-color: #D5D5D5;
              width: 95%;
              height: 1px;
              margin: auto; }
          .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .right-section {
            width: fit-content; }
            .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .right-section .big-button {
              width: 56px;
              height: 48px;
              border-radius: 4px;
              border: 1px solid #838383;
              background: #FCFCFE;
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
              .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .right-section .big-button.cancel.active {
                background-color: #FEEFE6;
                border-color: #F96302; }
              .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .right-section .big-button.drive.active {
                background-color: #E5F6EF;
                border-color: #0F8952; }
            .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .right-section .app-info-container {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A;
              text-align: end; }
              .driver-schedule-page .Collapsible .collapsible-content .row-container .driver-day-row.row .right-section .app-info-container .button-as-link {
                color: #00A664;
                text-decoration: underline;
                padding: 0; }

@media only screen and (min-width: 701px) {
  .driver-schedule-page {
    padding-top: 160px; }
    .driver-schedule-page .driver-schedule-container {
      padding: 0px 40px 50px 40px;
      min-width: 80%; }
      .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule {
        height: 800px;
        max-width: 550px;
        padding: 0px 20px; }
      .driver-schedule-page .driver-schedule-container .review-driver-schedule .bottom-button-section button.edit-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: #FFF !important;
        border: 2px solid #00A664;
        border-radius: 5px;
        color: #00A664;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
        .driver-schedule-page .driver-schedule-container .review-driver-schedule .bottom-button-section button.edit-button:disabled {
          border: 2px solid #838383 !important;
          color: #838383;
          cursor: not-allowed;
          border: none; } }

@media only screen and (max-width: 700px) {
  .driver-schedule-page {
    padding-top: calc(60px + 43px) !important;
    flex-direction: column; }
    .driver-schedule-page .driver-schedule-container {
      min-width: 80%;
      padding: 0px 15px; }
      .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule {
        margin: 0px 15px;
        height: 500px; }
        .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .show-assigned-days {
          width: -webkit-fill-available; }
        .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .Collapsible .collapsible-content .row-container .driver-day-row.row .date-section {
          margin-right: 8px; }
        .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .Collapsible .collapsible-content .row-container .driver-day-row.row .vanpooler-name {
          font-size: 15px !important; }
      .driver-schedule-page .driver-schedule-container .review-driver-schedule .bottom-button-section {
        flex-direction: column-reverse; }
        .driver-schedule-page .driver-schedule-container .review-driver-schedule .bottom-button-section button {
          height: unset; }
          .driver-schedule-page .driver-schedule-container .review-driver-schedule .bottom-button-section button.review-button {
            min-height: 40px; }
          .driver-schedule-page .driver-schedule-container .review-driver-schedule .bottom-button-section button.edit-button {
            color: #00A664;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            letter-spacing: 0.5px;
            line-height: 15px !important;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            padding: 0px;
            margin-top: 10px; } }

@media screen and (max-width: 350px) {
  .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .Collapsible .collapsible-content .row-container .driver-day-row.row {
    flex-direction: column; }
    .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .Collapsible .collapsible-content .row-container .driver-day-row.row .right-section {
      margin-top: 8px; }
      .driver-schedule-page .driver-schedule-container .driver-schedule-list .schedule .Collapsible .collapsible-content .row-container .driver-day-row.row .right-section .app-info-container {
        text-align: start; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.mobile-alert {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 100vh;
  width: 400px;
  margin: 100px auto; }
  .mobile-alert img.branding {
    width: 225px;
    max-width: 25rem;
    margin: 4rem auto 2.5rem;
    height: auto !important; }
  .mobile-alert p {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center;
    color: #4A4A4A; }
  .mobile-alert .btn-container {
    display: flex;
    flex-direction: column;
    width: 90%; }
    .mobile-alert .btn-container .button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
    .mobile-alert .btn-container .secondary {
      margin-top: 2rem; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.invite-vanpool-page {
  background: #E5E5E5;
  display: flex;
  justify-content: center;
  height: 100vh; }
  .invite-vanpool-page .common-toast-container {
    position: absolute;
    bottom: 150px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0; }
  .invite-vanpool-page .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px;
    background: #E5E5E5;
    max-width: 900px;
    max-height: 60%; }
    .invite-vanpool-page .container .header {
      background: #00A664;
      border: 1px solid #D5D5D5;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .invite-vanpool-page .container .header .backArrow {
        width: 10%;
        padding: 22px; }
        .invite-vanpool-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .invite-vanpool-page .container .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        width: 85%; }
    .invite-vanpool-page .container .main {
      display: flex;
      padding: 40px 44px 0px;
      height: 100%;
      justify-content: center; }
      .invite-vanpool-page .container .main .email-container {
        overflow: auto;
        width: 100%;
        justify-content: center;
        display: flex; }
        .invite-vanpool-page .container .main .email-container .content {
          display: flex;
          flex-direction: column;
          width: 60%; }
          .invite-vanpool-page .container .main .email-container .content p {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
          .invite-vanpool-page .container .main .email-container .content .email-input {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: 15px;
            position: relative; }
            .invite-vanpool-page .container .main .email-container .content .email-input label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 700;
              font-size: 26px;
              line-height: 32px;
              letter-spacing: 0.15px;
              color: #4A4A4A;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-size: 13px;
              line-height: 16.5px;
              margin-bottom: 10px; }
            .invite-vanpool-page .container .main .email-container .content .email-input input {
              height: 56px;
              border: 2px solid #D5D5D5;
              padding: 10px;
              width: 100%;
              padding-right: 40px;
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #4A4A4A; }
              .invite-vanpool-page .container .main .email-container .content .email-input input.error {
                border: 2px solid #F96302;
                color: #4A4A4A;
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased; }
            .invite-vanpool-page .container .main .email-container .content .email-input .plus-icon {
              height: 24px;
              width: 24px;
              position: relative;
              left: calc(100% - 35px);
              display: inline;
              cursor: pointer;
              position: absolute;
              bottom: 15px; }
            .invite-vanpool-page .container .main .email-container .content .email-input .error {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.25px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              color: #F96302; }
          .invite-vanpool-page .container .main .email-container .content .email-lists {
            padding-top: 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; }
            .invite-vanpool-page .container .main .email-container .content .email-lists .email-box {
              display: flex;
              flex-direction: row;
              padding: 5px 8px 5px 8px;
              border-radius: 20px;
              border: 1px solid #d5d5d5;
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              width: fit-content;
              margin-right: 10px;
              margin-bottom: 10px; }
              .invite-vanpool-page .container .main .email-container .content .email-lists .email-box .delete-content {
                height: 20px;
                width: 20px;
                background-color: #D5D5D5;
                border: 1px solid white;
                border-radius: 50%;
                margin-left: 5px;
                margin-top: 2px;
                cursor: pointer; }
                .invite-vanpool-page .container .main .email-container .content .email-lists .email-box .delete-content .delete-icon {
                  height: 12px;
                  width: 12px;
                  display: flex;
                  margin-top: 3px;
                  margin-left: 3px; }
          .invite-vanpool-page .container .main .email-container .content .message-container {
            margin-top: 10px; }
            .invite-vanpool-page .container .main .email-container .content .message-container label {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 700;
              font-size: 26px;
              line-height: 32px;
              letter-spacing: 0.15px;
              color: #4A4A4A;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-size: 13px;
              line-height: 16.5px; }
            .invite-vanpool-page .container .main .email-container .content .message-container .editor-wrapper {
              margin-top: 10px;
              margin-bottom: 20px; }
              .invite-vanpool-page .container .main .email-container .content .message-container .editor-wrapper .textarea-editor {
                border: 2px solid #D5D5D5;
                background: #FFFFFF;
                border: 2px solid #D5D5D5;
                height: 56px;
                color: #4A4A4A;
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: -0.25px;
                color: #838383;
                font-style: italic;
                padding: 10px; }
                .invite-vanpool-page .container .main .email-container .content .message-container .editor-wrapper .textarea-editor ::placeholder {
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  letter-spacing: -0.25px;
                  color: #838383;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased; }
                .invite-vanpool-page .container .main .email-container .content .message-container .editor-wrapper .textarea-editor:disabled {
                  background: #D5D5D5;
                  font-family: 'Proxima Nova';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  letter-spacing: -0.25px;
                  color: #838383;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: antialiased; }
    .invite-vanpool-page .container .sticky-footer {
      height: 110px;
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.25);
      text-align: center;
      background-color: #fff;
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      .invite-vanpool-page .container .sticky-footer .acknowledge {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        margin-bottom: 8px; }
      .invite-vanpool-page .container .sticky-footer .send-invitation-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 8px 24px; }
        .invite-vanpool-page .container .sticky-footer .send-invitation-button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        .invite-vanpool-page .container .sticky-footer .send-invitation-button:enabled {
          background-color: #00A664; }

@media only screen and (min-width: 1011px) {
  .invite-vanpool-page {
    padding-top: 160px; }
    .invite-vanpool-page .header {
      border-radius: 8px 8px 0px 0px;
      height: 80px; }
      .invite-vanpool-page .header .heading {
        font-size: 36px;
        line-height: 48px; } }

@media only screen and (max-width: 1010px) and (min-width: 700px) {
  .invite-vanpool-page {
    padding-top: 30px;
    margin: 20px; }
    .invite-vanpool-page .header {
      border-radius: 8px 8px 0px 0px;
      height: 80px; }
      .invite-vanpool-page .header .heading {
        font-size: 36px;
        line-height: 48px; } }

@media only screen and (max-width: 700px) {
  .invite-vanpool-page {
    padding-top: 40px;
    margin: 5px 15px; }
    .invite-vanpool-page .common-toast-container {
      left: 12px;
      right: 20px; }
    .invite-vanpool-page .container .header {
      padding-left: 10px;
      height: 65px; }
      .invite-vanpool-page .container .header .backArrow {
        width: 10%;
        position: absolute;
        padding: 0px !important; }
        .invite-vanpool-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .invite-vanpool-page .container .header .heading {
        font-weight: 500;
        font-size: 24px;
        line-height: 48px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        margin: auto; }
    .invite-vanpool-page .container .main {
      flex-direction: column;
      padding: 40px 12px;
      align-items: center;
      justify-content: flex-start; }
      .invite-vanpool-page .container .main .email-container .content {
        width: 100%; }
        .invite-vanpool-page .container .main .email-container .content .email-lists {
          justify-content: center; }
    .invite-vanpool-page .container .sticky-footer .acknowledge {
      text-align: left;
      padding-left: 10px; } }

@media (min-width: 1247px) {
  .dashboard-van-details-container .van-desktop-only {
    display: block; }
  .dashboard-van-details-container .van-not-desktop {
    display: none !important; } }

@media (max-width: 1246px) {
  .dashboard-van-details-container .sections .vanpool-section.van-desktop-only {
    display: none !important; }
  .dashboard-van-details-container .sections .vanpool-section.van-not-desktop {
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    border-left: none !important;
    padding-left: 0px !important;
    margin-top: 16px; }
  .dashboard-van-details-container .sections .vanpool-section .image {
    order: 1; } }

@media screen and (max-width: 1023px) {
  .dashboard .bottom .left-side .new-registered-user .controls {
    position: relative;
    margin: 25px auto 40px;
    top: 0; } }

@media (max-width: 600px) {
  .dashboard .bottom .vanpool-details .row.drive {
    border-top: none; }
    .dashboard .bottom .vanpool-details .row.drive .apply-drive {
      margin-top: 0; }
  .dashboard .badges {
    display: block; }
    .dashboard .badges .app-store img,
    .dashboard .badges .play-store img {
      display: block;
      width: 15rem;
      margin: 2rem auto; }
      .dashboard .badges .app-store img:hover,
      .dashboard .badges .play-store img:hover {
        cursor: pointer; } }

@media (min-width: 1024px) {
  .dashboard .green-strip .left {
    padding: 22px 0px; }
    .dashboard .green-strip .left .header {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 700 !important; }
    .dashboard .green-strip .left .text {
      font-family: 'DINNextLTPro-Regular';
      font-size: 58px;
      font-style: normal;
      font-weight: 500;
      line-height: 72px;
      letter-spacing: -1px; }
  .dashboard .bottom {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .dashboard .bottom .trip-tools .trip-banner {
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      background-color: rgba(74, 74, 74, 0.9);
      color: #FFFFFF;
      min-height: 4rem;
      padding-left: 0.75rem;
      border-radius: 8px; }
      .dashboard .bottom .trip-tools .trip-banner.overdue {
        padding: 0;
        padding-left: 0.75rem;
        padding-right: 0.75rem; }
        .dashboard .bottom .trip-tools .trip-banner.overdue .trip-banner-message .days,
        .dashboard .bottom .trip-tools .trip-banner.overdue .trip-banner-message svg {
          display: block; }
      .dashboard .bottom .trip-tools .trip-banner .trip-banner-message {
        -webkit-box-flex: 3;
        -moz-box-flex: 3;
        -webkit-flex: 3;
        -ms-flex: 3;
        flex: 3; }
        .dashboard .bottom .trip-tools .trip-banner .trip-banner-message svg {
          font-size: 3rem;
          margin-right: 0.75rem; }
        .dashboard .bottom .trip-tools .trip-banner .trip-banner-message .message {
          font-size: 1.125rem; }
          .dashboard .bottom .trip-tools .trip-banner .trip-banner-message .message.small {
            font-size: 0.875rem;
            min-width: 0; }
      .dashboard .bottom .trip-tools .trip-banner .trip-banner-tools .button {
        cursor: pointer;
        text-wrap: nowrap; }
        .dashboard .bottom .trip-tools .trip-banner .trip-banner-tools .button.cancel {
          background-color: #333333;
          color: #FFFFFF;
          border-color: #333333; }
        .dashboard .bottom .trip-tools .trip-banner .trip-banner-tools .button:hover, .dashboard .bottom .trip-tools .trip-banner .trip-banner-tools .button:focus {
          opacity: 0.8; }
      .dashboard .bottom .trip-tools .trip-banner .trip-banner-tools.yes-no {
        margin-top: 0px !important; }
    .dashboard .bottom .left-side {
      width: calc(65% - 0.5vw);
      border-radius: 8px; }
    .dashboard .bottom .right-side {
      width: calc(30% - 0.5vw); }
      .dashboard .bottom .right-side .vanpool-payment-summary {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column; }
        .dashboard .bottom .right-side .vanpool-payment-summary .tablet-left {
          -webkit-box-flex: 1 auto;
          -moz-box-flex: 1 auto;
          -webkit-flex: 1 auto;
          -ms-flex: 1 auto;
          flex: 1 auto;
          order: 2;
          background: transparent;
          border-right: none; }
          .dashboard .bottom .right-side .vanpool-payment-summary .tablet-left .payment-group {
            -webkit-flex-direction: column;
            -moz-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between; }
            .dashboard .bottom .right-side .vanpool-payment-summary .tablet-left .payment-group .last-payment {
              width: auto; }
            .dashboard .bottom .right-side .vanpool-payment-summary .tablet-left .payment-group .button {
              width: auto;
              margin: 1rem; }
          .dashboard .bottom .right-side .vanpool-payment-summary .tablet-left .payment-recurring {
            text-align: center; }
        .dashboard .bottom .right-side .vanpool-payment-summary .tablet-right {
          -webkit-box-flex: 1 auto;
          -moz-box-flex: 1 auto;
          -webkit-flex: 1 auto;
          -ms-flex: 1 auto;
          flex: 1 auto;
          order: 1;
          border: none; }
          .dashboard .bottom .right-side .vanpool-payment-summary .tablet-right .payment-due-date {
            position: relative;
            width: auto; }
          .dashboard .bottom .right-side .vanpool-payment-summary .tablet-right .payment-balance-due {
            text-align: center; }
    .dashboard .bottom .ipad-horizontal {
      display: block !important; }
      .dashboard .bottom .ipad-horizontal .fuel-card {
        margin-right: 0;
        margin-bottom: 1rem; } }

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .dashboard .green-strip .left {
    padding: 22px 0px; }
    .dashboard .green-strip .left .header {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 700 !important; }
    .dashboard .green-strip .left .text {
      font-family: 'DINNextLTPro-Regular';
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.5px; } }

@media (min-width: 1000px) {
  .vehicle .image {
    display: block !important; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.coordinator-page {
  padding-bottom: 100px; }
  .coordinator-page .apply-coordinator-page {
    height: 100% !important; }
    .coordinator-page .apply-coordinator-page .state-date {
      flex-direction: column;
      row-gap: 5px;
      max-width: unset; }
    .coordinator-page .apply-coordinator-page .heading {
      font-weight: 500 !important;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased; }
    .coordinator-page .apply-coordinator-page .small-heading {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased; }
      .coordinator-page .apply-coordinator-page .small-heading.verify {
        color: #636363; }
    .coordinator-page .apply-coordinator-page .save-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #FFFFFF;
      border-radius: 5px;
      padding: 8px 24px;
      width: fit-content;
      margin-top: 20px; }
      .coordinator-page .apply-coordinator-page .save-button:disabled {
        background-color: #838383;
        cursor: not-allowed;
        border: none; }
      .coordinator-page .apply-coordinator-page .save-button:enabled {
        background-color: #00A664; }
    .coordinator-page .apply-coordinator-page .checkbox-section {
      display: flex;
      gap: 10px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased; }
      .coordinator-page .apply-coordinator-page .checkbox-section .checkbox {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        background-color: #EDEDED;
        border-radius: 2px;
        padding: 0px; }
        .coordinator-page .apply-coordinator-page .checkbox-section .checkbox.selected {
          background: #00A664;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px; }
          .coordinator-page .apply-coordinator-page .checkbox-section .checkbox.selected img {
            width: 13px; }
        .coordinator-page .apply-coordinator-page .checkbox-section .checkbox:not(.selected) {
          border: 2px solid #838383; }
    .coordinator-page .apply-coordinator-page .verify-address {
      display: flex;
      border-radius: 8px;
      border: #D5D5D5;
      background: #EDEDED;
      padding: 16px;
      max-width: 350px;
      gap: 16px; }
      .coordinator-page .apply-coordinator-page .verify-address .address-view {
        display: flex;
        justify-content: center;
        flex-direction: column; }
        .coordinator-page .apply-coordinator-page .verify-address .address-view .label {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          font-weight: 700; }
        .coordinator-page .apply-coordinator-page .verify-address .address-view .text {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A; }
    .coordinator-page .apply-coordinator-page .charges-popup.verify-home-address {
      width: fit-content; }
      .coordinator-page .apply-coordinator-page .charges-popup.verify-home-address .one-time-charges-popup {
        padding: 30px; }
      .coordinator-page .apply-coordinator-page .charges-popup.verify-home-address .one-time-charges-popup-content {
        text-align: left; }
      .coordinator-page .apply-coordinator-page .charges-popup.verify-home-address .popup-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .coordinator-page .apply-coordinator-page .charges-popup.verify-home-address .charges-header {
        display: flex;
        justify-content: space-between !important; }
      .coordinator-page .apply-coordinator-page .charges-popup.verify-home-address .pre-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px; }
      .coordinator-page .apply-coordinator-page .charges-popup.verify-home-address .continue-button {
        margin: 15px 0px 0px 0px; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@media only screen and (min-width: 769px) {
  .driver-coordinator-thankyou-page {
    height: calc(100vh - 10rem - 1.5rem);
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5; }
    .driver-coordinator-thankyou-page .container {
      width: 900px;
      margin: 60px auto;
      background-color: #fff;
      padding: 64px 0px; }
      .driver-coordinator-thankyou-page .container .header {
        text-align: center; }
        .driver-coordinator-thankyou-page .container .header .header-image img {
          height: 65px;
          width: 65px; }
        .driver-coordinator-thankyou-page .container .header .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          margin-top: 8px;
          font-size: 30px;
          line-height: 40px; }
      .driver-coordinator-thankyou-page .container .pre-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-weight: 400;
        color: #838383;
        padding: 20px 150px;
        text-align: center; }
        .driver-coordinator-thankyou-page .container .pre-text .callable {
          color: #00A664;
          margin-left: 5px; }
          .driver-coordinator-thankyou-page .container .pre-text .callable .desktop {
            display: unset !important; }
      .driver-coordinator-thankyou-page .container .pre-text-2 {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-weight: 400;
        color: #838383;
        padding: 0px 150px;
        text-align: center; }
      .driver-coordinator-thankyou-page .container .button-container {
        display: flex;
        justify-content: center; }
        .driver-coordinator-thankyou-page .container .button-container .continue {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          height: 40px;
          width: 160px; }
          .driver-coordinator-thankyou-page .container .button-container .continue:disabled {
            border: 2px solid #838383 !important;
            color: #838383;
            cursor: not-allowed;
            border: none; } }

@media only screen and (max-width: 768px) and (min-width: 701px) {
  .driver-coordinator-thankyou-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5;
    height: calc(100vh - 10rem - 1.5rem); }
    .driver-coordinator-thankyou-page .container {
      width: 600px;
      margin: 60px auto;
      background-color: #fff;
      padding: 64px 0px; }
      .driver-coordinator-thankyou-page .container .header {
        text-align: center; }
        .driver-coordinator-thankyou-page .container .header .header-image img {
          height: 65px;
          width: 65px; }
        .driver-coordinator-thankyou-page .container .header .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          font-size: 30px;
          line-height: 40px;
          margin-top: 8px; }
      .driver-coordinator-thankyou-page .container .pre-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-weight: 400;
        color: #838383;
        padding: 20px 50px;
        text-align: center; }
        .driver-coordinator-thankyou-page .container .pre-text .callable {
          color: #00A664;
          margin-left: 5px; }
          .driver-coordinator-thankyou-page .container .pre-text .callable .desktop {
            display: unset !important; }
      .driver-coordinator-thankyou-page .container .pre-text-2 {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-weight: 400;
        color: #838383;
        padding: 0px 50px;
        text-align: center; }
      .driver-coordinator-thankyou-page .container .button-container {
        display: flex;
        justify-content: center;
        margin-top: 30px; }
        .driver-coordinator-thankyou-page .container .button-container .continue {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          height: 40px;
          width: 160px; }
          .driver-coordinator-thankyou-page .container .button-container .continue:disabled {
            border: 2px solid #838383 !important;
            color: #838383;
            cursor: not-allowed;
            border: none; } }

@media only screen and (max-width: 700px) {
  .driver-coordinator-thankyou-page {
    padding-top: 100px;
    padding-bottom: 200px;
    width: 100%;
    background-color: #E5E5E5;
    height: calc(100vh - 10rem - 1.5rem); }
    .driver-coordinator-thankyou-page .container {
      width: 400px;
      margin: 60px auto;
      background-color: #fff;
      padding: 64px 0px; }
      .driver-coordinator-thankyou-page .container .header {
        text-align: center; }
        .driver-coordinator-thankyou-page .container .header .header-image img {
          height: 65px;
          width: 65px; }
        .driver-coordinator-thankyou-page .container .header .heading {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          margin-top: 8px;
          font-size: 30px;
          line-height: 40px; }
      .driver-coordinator-thankyou-page .container .pre-text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-weight: 400;
        color: #838383;
        padding: 20px;
        text-align: center; }
        .driver-coordinator-thankyou-page .container .pre-text .callable {
          color: #00A664;
          margin-left: 5px; }
          .driver-coordinator-thankyou-page .container .pre-text .callable .desktop {
            display: unset !important; }
      .driver-coordinator-thankyou-page .container .pre-text-2 {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-weight: 400;
        color: #838383;
        padding: 0px;
        text-align: center; }
      .driver-coordinator-thankyou-page .container .button-container {
        display: flex;
        justify-content: center;
        margin-top: 30px; }
        .driver-coordinator-thankyou-page .container .button-container .continue {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          height: 40px;
          width: 160px; }
          .driver-coordinator-thankyou-page .container .button-container .continue:disabled {
            border: 2px solid #838383 !important;
            color: #838383;
            cursor: not-allowed;
            border: none; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.coordinator-page {
  padding-bottom: 100px; }
  .coordinator-page .email-verification-page {
    height: 100% !important; }
    .coordinator-page .email-verification-page .view-container .content-container {
      padding: 24px 15px !important; }
      .coordinator-page .email-verification-page .view-container .content-container .heading {
        font-weight: 500 !important;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .coordinator-page .email-verification-page .view-container .content-container .small-heading {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        margin-top: -10px; }
      .coordinator-page .email-verification-page .view-container .content-container .email-section {
        display: flex;
        border-radius: 8px;
        border: #D5D5D5;
        background: #EDEDED;
        padding: 16px;
        max-width: 350px;
        gap: 16px;
        flex-direction: column; }
        .coordinator-page .email-verification-page .view-container .content-container .email-section .email-view {
          display: flex;
          justify-content: center;
          flex-direction: column; }
          .coordinator-page .email-verification-page .view-container .content-container .email-section .email-view .label {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A;
            font-weight: 700; }
          .coordinator-page .email-verification-page .view-container .content-container .email-section .email-view .text {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
      .coordinator-page .email-verification-page .view-container .content-container .button-container {
        display: flex;
        flex-direction: column; }
        .coordinator-page .email-verification-page .view-container .content-container .button-container .continue-button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #FFFFFF;
          border-radius: 5px;
          padding: 8px 24px;
          width: 260px; }
          .coordinator-page .email-verification-page .view-container .content-container .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed;
            border: none; }
          .coordinator-page .email-verification-page .view-container .content-container .button-container .continue-button:enabled {
            background-color: #00A664; }
        .coordinator-page .email-verification-page .view-container .content-container .button-container .change-button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          width: 260px;
          padding: 8px 0px;
          margin-top: 32px; }
          .coordinator-page .email-verification-page .view-container .content-container .button-container .change-button:disabled {
            border: 2px solid #838383 !important;
            color: #838383;
            cursor: not-allowed;
            border: none; }

.stops-main-container {
  width: auto; }
  .stops-main-container .first-address {
    padding-right: 10px; }
  .stops-main-container .second-address {
    margin-top: 5px; }
  .stops-main-container .header {
    display: flex;
    justify-content: space-between;
    padding-top: 10px; }
  .stops-main-container .heading {
    font-family: 'Proxima Nova';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-weight: 600;
    color: #4A4A4A; }
  .stops-main-container .map {
    background: #D5D5D5;
    width: 300px;
    height: 300px;
    margin: 0px 24px 30px; }
    .stops-main-container .map .google-map {
      height: 300px; }
  .stops-main-container .stops {
    height: 100%; }
    .stops-main-container .stops .tabs {
      border-bottom: solid 3px #EDEDED;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      width: auto;
      height: 44px; }
      .stops-main-container .stops .tabs .tab {
        padding: 10px 10px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        width: 50%;
        color: #838383; }
      .stops-main-container .stops .tabs .active {
        color: #049b5c;
        background: #D4EDE1;
        border-radius: 4px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom: solid 3px #049b5c;
        margin-bottom: -3px; }
    .stops-main-container .stops .tab-container {
      margin-top: 10px;
      width: auto;
      height: 80%;
      overflow-y: auto; }
      .stops-main-container .stops .tab-container .stops-container {
        padding: 20px 0px; }
        .stops-main-container .stops .tab-container .stops-container:not(:last-child) {
          border-bottom: 1px solid #D5D5D5; }
        .stops-main-container .stops .tab-container .stops-container img {
          height: 16px;
          width: 16px; }
        .stops-main-container .stops .tab-container .stops-container .caret {
          width: 16px;
          /* Adjust size as needed */
          height: 16px;
          margin-right: 8px;
          /* Adjust spacing as needed */
          transition: transform 0.3s ease;
          /* Add transition effect */ }
        .stops-main-container .stops .tab-container .stops-container .rotate {
          transform: rotate(180deg);
          /* Rotate the image by 180 degrees */ }
        .stops-main-container .stops .tab-container .stops-container .icon {
          margin-right: 5px;
          margin-bottom: 2px; }
        .stops-main-container .stops .tab-container .stops-container .first-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 10px; }
          .stops-main-container .stops .tab-container .stops-container .first-section .left-section {
            display: flex;
            flex-direction: row; }
            .stops-main-container .stops .tab-container .stops-container .first-section .left-section .icon {
              width: 30px; }
              .stops-main-container .stops .tab-container .stops-container .first-section .left-section .icon img {
                height: 70px;
                width: 70px;
                margin-left: -23px;
                margin-top: -15px; }
              .stops-main-container .stops .tab-container .stops-container .first-section .left-section .icon .stopOrder {
                width: 22px;
                height: 22px;
                background-color: #F96302;
                border-radius: 50%;
                color: #FFFFFF;
                font-family: 'DINNextLTPro-Regular';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: -0.25px;
                text-align: center;
                padding-top: 3px; }
              .stops-main-container .stops .tab-container .stops-container .first-section .left-section .icon .stopCancelled {
                width: 20px !important;
                height: 20px !important;
                margin-left: 0 !important;
                margin-top: 0 !important; }
            .stops-main-container .stops .tab-container .stops-container .first-section .left-section .address {
              font-family: 'Proxima Nova';
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              /* 133.333% */
              letter-spacing: -0.25px;
              color: #4A4A4A;
              margin-left: 3px; }
              .stops-main-container .stops .tab-container .stops-container .first-section .left-section .address .second-address {
                font-family: 'DINNextLTPro-Regular';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
                letter-spacing: -0.25px;
                color: #838383; }
          .stops-main-container .stops .tab-container .stops-container .first-section .right-section .time {
            font-family: 'DINNextLTPro-Regular';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: -0.25px;
            display: flex;
            flex-direction: column;
            align-items: flex-end; }
            .stops-main-container .stops .tab-container .stops-container .first-section .right-section .time .label {
              color: #838383; }
            .stops-main-container .stops .tab-container .stops-container .first-section .right-section .time .value {
              color: #4A4A4A;
              white-space: nowrap; }
        .stops-main-container .stops .tab-container .stops-container .user-count {
          display: flex; }
        .stops-main-container .stops .tab-container .stops-container .second-section {
          border-radius: 5px;
          width: fit-content;
          padding: 2px 15px 4px 10px;
          text-wrap: nowrap; }
          .stops-main-container .stops .tab-container .stops-container .second-section:first-child {
            margin-left: 30px; }
          .stops-main-container .stops .tab-container .stops-container .second-section:not(:first-child) {
            margin-left: 8px; }
          .stops-main-container .stops .tab-container .stops-container .second-section.blue {
            background-color: #EDF4FC; }
          .stops-main-container .stops .tab-container .stops-container .second-section.red {
            background-color: #FEEFE6; }
          .stops-main-container .stops .tab-container .stops-container .second-section.disabled {
            background-color: #EDEDED; }
          .stops-main-container .stops .tab-container .stops-container .second-section .icon img {
            height: 20px;
            width: 20px; }
          .stops-main-container .stops .tab-container .stops-container .second-section .count {
            font-family: 'Proxima Nova';
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 133.333% */
            letter-spacing: -0.25px;
            color: #1E3A5A;
            margin-left: 5px; }
        .stops-main-container .stops .tab-container .stops-container .bottom-section {
          display: flex;
          justify-content: space-between; }
          .stops-main-container .stops .tab-container .stops-container .bottom-section .accordion img {
            height: 24px;
            width: 24px; }

.export-csv {
  display: none; }

@media (min-width: 768px) {
  .export-csv {
    display: block; }
    .export-csv button {
      color: #049b5c;
      cursor: pointer; }
      .export-csv button svg {
        font-size: 1.125rem; }
      .export-csv button label {
        font-weight: 700;
        cursor: pointer; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.select-container {
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-weight: 200;
  color: #4A4A4A;
  width: 170px; }
  .select-container label {
    font-family: 'DINNextLTPro-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 25px; }
  .select-container .select-header {
    height: 56px;
    padding: 10px;
    background: #ffffff;
    border: 2px solid #D5D5D5;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .select-container .select-header .selected-time {
      width: 45%;
      display: flex;
      align-items: center;
      padding-top: 5px; }
      .select-container .select-header .selected-time .selected-hour {
        color: #00A664;
        font-weight: 400;
        font-size: 40px;
        line-height: 24px;
        margin-right: 5px; }
      .select-container .select-header .selected-time .selected-mins-day {
        display: flex;
        flex-direction: column;
        color: #333333; }
    .select-container .select-header .placeholder {
      width: 50%;
      display: flex;
      justify-content: space-between;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      align-items: center;
      color: #00A664; }
      .select-container .select-header .placeholder .text {
        margin-top: 5px; }
  .select-container .time-arrow {
    border-right: 2px solid;
    border-bottom: 2px solid;
    padding: 3px;
    border-color: #00A664; }
    .select-container .time-arrow.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      transition: transform 0.2s linear,opacity 0.2s linear; }
    .select-container .time-arrow.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition: transform 0.2s linear,opacity 0.2s linear; }

.dropdown-list {
  background: #ffffff;
  border: 2px solid #e5e5e5;
  color: #0F8952;
  z-index: 10;
  position: absolute;
  height: 265px;
  overflow: auto;
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-indent: 16px;
  width: 170px; }
  .dropdown-list .list-item {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-bottom: 1px solid #D5D5D5; }
    .dropdown-list .list-item:hover {
      background: #0F8952;
      color: #ffffff; }
    .dropdown-list .list-item:first-child {
      margin-top: 0.8em; }
    .dropdown-list .list-item:last-child {
      border-bottom: none; }
  .dropdown-list::-webkit-scrollbar {
    width: 5px; }
  .dropdown-list::-webkit-scrollbar-track {
    border-radius: 3px; }
  .dropdown-list::-webkit-scrollbar-thumb {
    background: #838383;
    border-radius: 10px; }

@media only screen and (max-width: 768px) and (min-width: 701px) {
  .select-container {
    width: 130px; }
    .select-container label {
      font-size: 11px; }
  .dropdown-list {
    width: 130px; } }

@media only screen and (max-width: 700px) {
  .select-container {
    width: 150px; }
    .select-container label {
      font-size: 11px; }
  .dropdown-list {
    width: 150px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.flexibility-container {
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-weight: 200;
  color: #4A4A4A;
  width: 170px; }
  .flexibility-container .flexibility-label {
    display: flex;
    justify-content: space-between; }
    .flexibility-container .flexibility-label .info .message {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.25px;
      font-style: normal;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      padding-right: 30px;
      color: #FFFFFF;
      background: #4A4A4A;
      border-color: #4A4A4A;
      left: -15rem;
      top: -6rem;
      width: 260px;
      border-radius: 8px; }
      .flexibility-container .flexibility-label .info .message:after {
        transform: rotate(180deg);
        border-bottom: 0.5625rem solid #4A4A4A;
        left: 91%;
        top: 100%; }
      .flexibility-container .flexibility-label .info .message:before {
        transform: rotate(180deg);
        border-bottom: 0.5625rem solid #4A4A4A;
        left: 91%;
        top: 100%; }
      .flexibility-container .flexibility-label .info .message .closeIcon {
        position: absolute;
        right: 10px;
        top: 10px; }
  .flexibility-container label {
    font-family: 'DINNextLTPro-Medium';
    font-style: normal;
    font-size: 13px;
    line-height: 25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased; }
  .flexibility-container .dropdown-list {
    background: #ffffff;
    border: 2px solid #e5e5e5;
    color: #0F8952;
    z-index: 10;
    position: absolute;
    height: 265px;
    overflow: auto;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-indent: 16px;
    width: 170px;
    height: auto; }
    .flexibility-container .dropdown-list .list-item {
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      border-bottom: 1px solid #D5D5D5; }
      .flexibility-container .dropdown-list .list-item:hover {
        background: #0F8952;
        color: #ffffff; }
      .flexibility-container .dropdown-list .list-item:first-child {
        margin-top: 0.8em; }
      .flexibility-container .dropdown-list .list-item:last-child {
        border-bottom: none; }
    .flexibility-container .dropdown-list::-webkit-scrollbar {
      width: 5px; }
    .flexibility-container .dropdown-list::-webkit-scrollbar-track {
      border-radius: 3px; }
    .flexibility-container .dropdown-list::-webkit-scrollbar-thumb {
      background: #838383;
      border-radius: 10px; }

.flexibility-header {
  height: 56px;
  padding: 10px 10px;
  background: #ffffff;
  border: 2px solid #D5D5D5;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .flexibility-header .placeholder {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    align-items: center;
    color: #838383; }
    .flexibility-header .placeholder .text {
      margin-top: 5px; }
  .flexibility-header .placeholder-content {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #838383; }

.time-arrow {
  border-right: 2px solid;
  border-bottom: 2px solid;
  padding: 3px;
  border-color: #00A664; }
  .time-arrow.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transition: transform 0.2s linear, opacity 0.2s linear; }
  .time-arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: transform 0.2s linear, opacity 0.2s linear; }

@media only screen and (max-width: 768px) and (min-width: 701px) {
  .flexibility-container {
    width: 130px; }
    .flexibility-container label {
      font-size: 11px; } }

@media only screen and (max-width: 700px) {
  .flexibility-container {
    width: 150px !important; }
    .flexibility-container label {
      font-size: 11px; }
    .flexibility-container .dropdown-list {
      width: 150px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@media only screen and (min-width: 701px) {
  .price-comp {
    cursor: pointer; }
    .price-comp:nth-child(2) {
      margin-left: 30px; }
    .price-comp .tick-icon-container {
      position: relative; }
      .price-comp .tick-icon-container .tick-icon {
        position: absolute;
        right: 10px;
        bottom: -10px;
        z-index: 1; }
    .price-comp .price-component-small {
      height: 90px;
      width: 290px;
      border: 1px solid #D5D5D5;
      filter: drop-shadow(0px 1px 0px #D5D5D5);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .price-comp .price-component-small .sub-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700;
        align-self: flex-start;
        margin-left: 10px;
        margin-top: 5px;
        color: #838383; }
      .price-comp .price-component-small.selected {
        background: #D4EDE1;
        border: 3px solid #00A664; }
        .price-comp .price-component-small.selected .sub-heading {
          color: #0F8952; }
    .price-comp .price-component-large {
      height: 68px;
      width: 290px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #D5D5D5;
      filter: drop-shadow(0px 1px 0px #D5D5D5);
      border-radius: 8px; }
      .price-comp .price-component-large.selected {
        background: #D4EDE1;
        border: 3px solid #00A664; }
    .price-comp .price-component-large,
    .price-comp .price-component-small {
      display: flex;
      flex-direction: column; }
      .price-comp .price-component-large .price-group,
      .price-comp .price-component-small .price-group {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* Commute Charcoal */
        color: #4A4A4A; }
        .price-comp .price-component-large .price-group .doller,
        .price-comp .price-component-small .price-group .doller {
          font-size: 20px; }
        .price-comp .price-component-large .price-group .price,
        .price-comp .price-component-small .price-group .price {
          font-size: 36px;
          line-height: 20px;
          margin-top: 15px; }
        .price-comp .price-component-large .price-group .decimal,
        .price-comp .price-component-small .price-group .decimal {
          font-size: 20px; }
      .price-comp .price-component-large .frequency,
      .price-comp .price-component-small .frequency {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #4A4A4A;
        margin-top: 5px; } }

@media only screen and (max-width: 700px) {
  .price-comp {
    cursor: pointer;
    max-width: 320px;
    width: 100%; }
    .price-comp:nth-child(2) {
      margin-left: 30px; }
    .price-comp .tick-icon-container {
      position: relative; }
      .price-comp .tick-icon-container .tick-icon {
        position: absolute;
        right: 10px;
        bottom: -10px;
        z-index: 1; }
    .price-comp .price-component-small {
      height: 85px;
      max-width: 150px;
      border: 1px solid #D5D5D5;
      filter: drop-shadow(0px 1px 0px #D5D5D5);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .price-comp .price-component-small .sub-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 700;
        align-self: flex-start;
        margin-left: 10px;
        margin-top: 5px;
        color: #838383; }
      .price-comp .price-component-small.selected {
        background: #D4EDE1;
        border: 3px solid #00A664;
        border-radius: 8px; }
        .price-comp .price-component-small.selected .sub-heading {
          color: #0F8952; }
    .price-comp .price-component-large {
      height: 68px;
      max-width: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #D5D5D5;
      filter: drop-shadow(0px 1px 0px #D5D5D5);
      border-radius: 8px; }
      .price-comp .price-component-large.selected {
        background: #D4EDE1;
        border: 3px solid #00A664; }
    .price-comp .price-component-large,
    .price-comp .price-component-small {
      display: flex;
      flex-direction: column; }
      .price-comp .price-component-large .price-group,
      .price-comp .price-component-small .price-group {
        display: flex;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        display: flex;
        align-items: center;
        text-align: center;
        /* Commute Charcoal */
        color: #4A4A4A; }
        .price-comp .price-component-large .price-group .doller,
        .price-comp .price-component-small .price-group .doller {
          font-size: 15px; }
        .price-comp .price-component-large .price-group .price,
        .price-comp .price-component-small .price-group .price {
          font-size: 32px;
          line-height: 20px;
          margin-top: 15px; }
        .price-comp .price-component-large .price-group .decimal,
        .price-comp .price-component-small .price-group .decimal {
          font-size: 15px; }
      .price-comp .price-component-large .frequency,
      .price-comp .price-component-small .frequency {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #4A4A4A;
        margin-top: 5px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.join-commute-calendar {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  max-width: 90%; }
  .join-commute-calendar .calendar-header {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.25px;
    color: #000000; }
    .join-commute-calendar .calendar-header .arrow-box {
      height: 30px;
      width: 30px;
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      border-radius: 8px; }
      .join-commute-calendar .calendar-header .arrow-box:hover {
        border-width: 2px 2px 2px 2px; }
      .join-commute-calendar .calendar-header .arrow-box.disabled {
        pointer-events: none;
        cursor: pointer; }
      .join-commute-calendar .calendar-header .arrow-box .arrow {
        display: inline-block;
        padding: 4px;
        margin-top: 9px; }
        .join-commute-calendar .calendar-header .arrow-box .arrow.disabled {
          border: solid #D5D5D5;
          border-width: 0 2px 2px 0; }
        .join-commute-calendar .calendar-header .arrow-box .arrow.enabled {
          border: solid #00A664;
          border-width: 0 2px 2px 0; }
        .join-commute-calendar .calendar-header .arrow-box .arrow.left {
          margin-left: 6px;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg); }
        .join-commute-calendar .calendar-header .arrow-box .arrow.right {
          margin-right: 6px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg); }
    .join-commute-calendar .calendar-header .month-year {
      text-align: center; }
  .join-commute-calendar .calendar-body {
    width: 100%;
    align-items: center;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .join-commute-calendar .calendar-body .table-header {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-around; }
    .join-commute-calendar .calendar-body .weekday {
      width: 45px;
      text-align: center;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 500;
      text-align: center;
      letter-spacing: -0.25px;
      color: #838383; }
    .join-commute-calendar .calendar-body .table-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      box-sizing: border-box;
      align-items: center;
      max-height: 30vh;
      overflow-y: auto;
      overflow-x: hidden; }
      .join-commute-calendar .calendar-body .table-content .calendar-day {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #4A4A4A;
        cursor: default;
        height: 45px;
        width: 45px;
        border-radius: 8px; }
        .join-commute-calendar .calendar-body .table-content .calendar-day:hover {
          background-color: #D4EDE1; }
        .join-commute-calendar .calendar-body .table-content .calendar-day p {
          color: #4A4A4A;
          margin: auto; }
        .join-commute-calendar .calendar-body .table-content .calendar-day .today-label {
          position: absolute;
          top: -8px;
          font-size: 12px;
          font-weight: bold;
          color: #4A4A4A; }
      .join-commute-calendar .calendar-body .table-content .striked {
        pointer-events: none; }
        .join-commute-calendar .calendar-body .table-content .striked p.date-number {
          color: #D5D5D5;
          text-decoration: line-through; }
      .join-commute-calendar .calendar-body .table-content .selected {
        background: #D4EDE1;
        border: 1px solid #00A664;
        border-radius: 8px; }
        .join-commute-calendar .calendar-body .table-content .selected p {
          color: #00A664; }
      .join-commute-calendar .calendar-body .table-content .hide {
        visibility: hidden; }

.confirm-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 25px;
  margin-bottom: 20px; }
  .confirm-button-container button {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    background-color: #FFFFFF; }
    .confirm-button-container button:disabled {
      cursor: not-allowed;
      color: #838383;
      border: 2px solid #838383; }
    .confirm-button-container button:enabled {
      border: 2px solid #00A664;
      color: #00A664; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.van-route-details {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  margin-right: 35px; }
  .van-route-details .route-icon {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .van-route-details .route-icon .group {
      height: 20px;
      width: 20px;
      margin-top: 2px; }
    .van-route-details .route-icon .LocationGreen {
      height: 25px;
      width: 25px; }
    .van-route-details .route-icon .line {
      width: 3px;
      margin-top: 2px;
      height: 50px; }
  .van-route-details .route-address {
    margin-left: 5px; }
    .van-route-details .route-address .origin-address {
      margin-bottom: 15px;
      height: 70px; }
      .van-route-details .route-address .origin-address .start-address {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #4A4A4A;
        max-height: 70px;
        overflow: hidden; }
      .van-route-details .route-address .origin-address .start-timings {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: #838383;
        margin-top: -5px;
        max-width: 250px; }
    .van-route-details .route-address .destination-address {
      margin-top: -10px; }
      .van-route-details .route-address .destination-address .end-address {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #4A4A4A;
        max-height: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
      .van-route-details .route-address .destination-address .end-timings {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: #838383;
        margin-top: -5px;
        max-width: 250px; }

@media only screen and (max-width: 900px) and (min-width: 700px) {
  .van-route-details {
    margin-left: 10px; }
    .van-route-details .route-icon .group {
      margin-top: -2px; }
    .van-route-details .route-icon .line {
      height: 50px; }
    .van-route-details .route-address {
      margin-top: -5px; }
      .van-route-details .route-address .origin-address .start-address {
        font-size: 16px;
        line-height: 30px; }
      .van-route-details .route-address .origin-address .start-timings {
        font-size: 16px; }
      .van-route-details .route-address .destination-address .end-address {
        font-size: 16px;
        line-height: 30px; }
      .van-route-details .route-address .destination-address .end-timings {
        font-size: 16px; } }

@media only screen and (max-width: 700px) {
  .van-route-details {
    margin-left: 10px; }
    .van-route-details .route-icon .line {
      height: 50px; }
    .van-route-details .route-address {
      margin-top: -5px; }
      .van-route-details .route-address .origin-address .start-address {
        font-size: 16px;
        line-height: 30px; }
      .van-route-details .route-address .destination-address .end-address {
        font-size: 16px;
        line-height: 30px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

.card-input-container input {
  border: 2px solid #D5D5D5;
  background-repeat: no-repeat;
  background-position-x: 8px;
  background-position-y: 11px;
  background-size: 40px;
  padding-left: 60px;
  height: 56px; }
  .card-input-container input.error {
    border: 2px solid #F96302 !important;
    box-shadow: none; }
  .card-input-container input:focus {
    border-bottom: 3px solid #00A664; }
  .card-input-container input.disabled {
    background-color: #EDEDED;
    box-shadow: none;
    border-bottom: none;
    border: 2px solid #D5D5D5;
    color: #838383; }

@media only screen and (max-width: 700px) {
  .card-input-container input {
    width: 100%; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

.tooltip-link-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px; }
  .tooltip-link-container .info img {
    height: 17px;
    width: 17px; }
  .tooltip-link-container .info .message {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding-right: 30px;
    color: #FFFFFF;
    background: #4A4A4A;
    border-color: #4A4A4A;
    left: -1rem;
    top: -6rem;
    width: 260px;
    border-radius: 8px; }
    .tooltip-link-container .info .message:after {
      transform: rotate(180deg);
      border-bottom: 0.5625rem solid #4A4A4A;
      left: 6%;
      top: 100%; }
    .tooltip-link-container .info .message:before {
      transform: rotate(180deg);
      border-bottom: 0.5625rem solid #4A4A4A;
      left: 6%;
      top: 100%; }
    .tooltip-link-container .info .message .closeIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 12px;
      width: 12px; }
  .tooltip-link-container .link-text {
    margin-left: 8px;
    margin-top: 4px;
    font-family: 'DINNextLTPro-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-decoration-line: underline;
    color: #0F8952;
    cursor: pointer; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

.display-card-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px; }
  .display-card-container .left-section {
    display: flex; }
    .display-card-container .left-section .card-image {
      margin-right: 10px; }
      .display-card-container .left-section .card-image img {
        width: 34px; }
    .display-card-container .left-section .content {
      margin: 0 !important; }
      .display-card-container .left-section .content .heading {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
      .display-card-container .left-section .content .type {
        margin-top: 3px;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 300;
        color: #838383; }
      .display-card-container .left-section .content .amount {
        margin-top: 3px;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 1000;
        color: #838383; }
  .display-card-container .right-section {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #838383;
    display: flex;
    align-items: flex-start; }
    .display-card-container .right-section .amount {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #838383;
      margin-left: 0.5rem; }
      .display-card-container .right-section .amount .dollar {
        font-size: 11px !important;
        margin-top: -7px; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.circle-container .circle {
  width: 15px;
  height: 15px;
  border: 2px solid #D5D5D5;
  border-radius: 10px; }
  .circle-container .circle.selected {
    background: #00A664;
    border: 2px solid #00A664; }

@media only screen and (min-width: 831px) {
  .video {
    height: 220px;
    display: flex;
    justify-content: center; }
    .video .video-responsive {
      padding-bottom: 30%;
      position: relative;
      width: 400px; }
      .video .video-responsive .desktop {
        display: block; }
      .video .video-responsive .mobile {
        display: none; }
      .video .video-responsive iframe {
        left: 0;
        top: 0;
        height: 220px;
        width: 400px;
        position: absolute; } }

@media only screen and (max-width: 830px) and (min-width: 701px) {
  .video {
    height: 220px;
    display: flex;
    justify-content: center; }
    .video .video-responsive {
      padding-bottom: 30%;
      position: relative;
      width: 400px; }
      .video .video-responsive .desktop {
        display: none; }
      .video .video-responsive .mobile {
        display: block; }
      .video .video-responsive iframe {
        left: 0;
        top: 0;
        height: 220px;
        width: 400px;
        position: absolute; } }

@media only screen and (max-width: 700px) {
  .video {
    height: 200px; }
    .video .video-responsive {
      padding-bottom: 60%;
      position: relative;
      height: 0;
      border-radius: 8px 8px 0px 0px; }
      .video .video-responsive .desktop {
        display: none; }
      .video .video-responsive .mobile {
        display: block; }
      .video .video-responsive iframe {
        left: 0;
        top: 0;
        height: 200px;
        width: 100%;
        position: absolute;
        border-radius: 8px 8px 0px 0px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@media only screen and (min-width: 701px) {
  .crop-container {
    border-radius: 5px;
    background-color: #FFFFFF;
    position: absolute; }
    .crop-container .header .close {
      float: right;
      margin-right: 10px;
      font-size: 20px;
      cursor: pointer; }
    .crop-container .image-content {
      height: 500px;
      width: 700px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      padding: 30px; }
      .crop-container .image-content .img-container {
        height: 65%;
        width: 100%; }
        .crop-container .image-content .img-container .reactEasyCrop_Container {
          height: 300px !important;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
          top: 30px;
          width: 90%; }
          .crop-container .image-content .img-container .reactEasyCrop_Container img {
            height: 350px; }
          .crop-container .image-content .img-container .reactEasyCrop_Container .reactEasyCrop_CropArea {
            border: 2px solid #00A664 !important; }
      .crop-container .image-content .rotate {
        cursor: pointer; }
        .crop-container .image-content .rotate .image {
          height: 24px;
          width: 24px; }
        .crop-container .image-content .rotate span {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          align-items: center;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #00A664;
          margin-left: 5px; }
      .crop-container .image-content .button-container {
        margin-bottom: 35px; }
        .crop-container .image-content .button-container button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #FFFFFF;
          border-radius: 5px;
          height: 40px;
          width: 150px; }
          .crop-container .image-content .button-container button:disabled {
            background-color: #838383;
            cursor: not-allowed;
            border: none; }
          .crop-container .image-content .button-container button:enabled {
            background-color: #00A664; }
        .crop-container .image-content .button-container .cancel {
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          margin-right: 24px; }
  .crop-popup {
    display: none;
    position: fixed;
    background: #FFF;
    z-index: 20;
    left: 0;
    right: 0;
    top: 12%;
    margin-left: auto;
    margin-right: auto;
    width: 700px;
    border-radius: 5px 5px 0px 0px; }
    .crop-popup:after {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -2;
      border-radius: 5px 5px 0px 0px; }
    .crop-popup:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #FFF;
      z-index: -1;
      border-radius: 5px 5px 0px 0px; }
    .crop-popup.show {
      display: block; }
    .crop-popup.hide {
      display: none; } }

@media only screen and (max-width: 700px) {
  .crop-container {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .crop-container .header {
      width: 100%; }
      .crop-container .header .close {
        float: right;
        margin-right: 10px;
        font-size: 20px;
        cursor: pointer; }
    .crop-container .image-content {
      height: 520px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column; }
      .crop-container .image-content .img-container {
        height: 65%;
        width: 100%; }
        .crop-container .image-content .img-container .reactEasyCrop_Container {
          height: 300px !important;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
          top: 30px;
          width: 90%; }
          .crop-container .image-content .img-container .reactEasyCrop_Container .reactEasyCrop_Contain {
            max-width: unset;
            max-height: unset; }
          .crop-container .image-content .img-container .reactEasyCrop_Container img {
            height: 350px; }
          .crop-container .image-content .img-container .reactEasyCrop_Container .reactEasyCrop_CropArea {
            border: 2px solid #00A664 !important; }
      .crop-container .image-content .rotate {
        cursor: pointer;
        text-align: center; }
        .crop-container .image-content .rotate .image {
          height: 24px;
          width: 24px; }
      .crop-container .image-content .button-container {
        margin: 5px 0px 15px;
        text-align: center; }
        .crop-container .image-content .button-container button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #FFFFFF;
          border-radius: 5px;
          height: 40px;
          width: 70%; }
          .crop-container .image-content .button-container button:disabled {
            background-color: #838383;
            cursor: not-allowed;
            border: none; }
          .crop-container .image-content .button-container button:enabled {
            background-color: #00A664; }
        .crop-container .image-content .button-container .cancel {
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          margin-bottom: 20px; }
  .crop-popup {
    display: none;
    position: fixed;
    background: #FFF;
    z-index: 20;
    left: 10px;
    right: 10px;
    top: 20%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px 5px 0px 0px; }
    .crop-popup:after {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -2;
      border-radius: 5px 5px 0px 0px; }
    .crop-popup:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #FFF;
      z-index: -1;
      border-radius: 5px 5px 0px 0px; }
    .crop-popup.show {
      display: block; }
    .crop-popup.hide {
      display: none; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@media only screen and (min-width: 701px) {
  .slider-parent {
    width: 600px; } }

@media only screen and (max-width: 700px) {
  .slider-parent {
    width: 350px; } }

.slider-parent {
  position: relative; }
  .slider-parent input[type=range] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%; }
  .slider-parent input[type=range]:focus {
    outline: none;
    box-shadow: none; }
  .slider-parent input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #838383;
    border-radius: 1px;
    border: 0px solid #000000; }
  .slider-parent input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #00A664;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #00A664;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px; }
  .slider-parent input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #838383;
    border-radius: 1px;
    border: 0px solid #000000; }
  .slider-parent input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #0F8952;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #00A664;
    cursor: pointer; }
  .slider-parent input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent; }
  .slider-parent input[type=range]::-ms-fill-lower {
    background: #838383;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000; }
  .slider-parent input[type=range]::-ms-fill-upper {
    background: #838383;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000; }
  .slider-parent input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #838383;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #00A664;
    cursor: pointer; }
  .slider-parent input[type=range]:focus::-ms-fill-lower {
    background: #838383; }
  .slider-parent input[type=range]:focus::-ms-fill-upper {
    background: #838383; }
  .slider-parent input {
    width: 100%;
    height: 2px; }
  .slider-parent .buble {
    position: absolute; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@media only screen and (min-width: 701px) {
  .payment-history-overview .Collapsible,
  .payment-history .Collapsible {
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    box-shadow: 0px 1px 0px #D5D5D5;
    width: 100%;
    margin-top: 7px; }
    .payment-history-overview .Collapsible .collapsible-heading-container,
    .payment-history .Collapsible .collapsible-heading-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 22px 35px 22px 20px; }
      .payment-history-overview .Collapsible .collapsible-heading-container .heading1 .month-heading,
      .payment-history .Collapsible .collapsible-heading-container .heading1 .month-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .payment-history-overview .Collapsible .collapsible-heading-container .heading1 .card-heading,
      .payment-history .Collapsible .collapsible-heading-container .heading1 .card-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #4A4A4A; }
      .payment-history-overview .Collapsible .collapsible-heading-container .posted-day,
      .payment-history .Collapsible .collapsible-heading-container .posted-day {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383; }
      .payment-history-overview .Collapsible .collapsible-heading-container .heading2,
      .payment-history .Collapsible .collapsible-heading-container .heading2 {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383; }
      .payment-history-overview .Collapsible .collapsible-heading-container .details,
      .payment-history .Collapsible .collapsible-heading-container .details {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .payment-history-overview .Collapsible .collapsible-content,
    .payment-history .Collapsible .collapsible-content {
      display: flex;
      flex-direction: column; }
      .payment-history-overview .Collapsible .collapsible-content .content-heading,
      .payment-history .Collapsible .collapsible-content .content-heading {
        height: 45px;
        background: #EDEDED;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        color: #838383;
        display: flex;
        align-items: center;
        padding-left: 15px; }
      .payment-history-overview .Collapsible .collapsible-content .row-container,
      .payment-history .Collapsible .collapsible-content .row-container {
        display: flex;
        flex-direction: column; }
        .payment-history-overview .Collapsible .collapsible-content .row-container .row,
        .payment-history .Collapsible .collapsible-content .row-container .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 30px 15px 30px; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .card-img,
          .payment-history .Collapsible .collapsible-content .row-container .row .card-img {
            width: 50px;
            margin-right: 12px; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .card-info,
          .payment-history .Collapsible .collapsible-content .row-container .row .card-info {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 400;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .price-value,
          .payment-history .Collapsible .collapsible-content .row-container .row .price-value {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #838383; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .content-label,
          .payment-history .Collapsible .collapsible-content .row-container .row .content-label {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .content-value,
          .payment-history .Collapsible .collapsible-content .row-container .row .content-value {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            text-align: right;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row.mobile,
          .payment-history .Collapsible .collapsible-content .row-container .row.mobile {
            display: none; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .label,
          .payment-history .Collapsible .collapsible-content .row-container .row .label {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .value,
          .payment-history .Collapsible .collapsible-content .row-container .row .value {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            color: #4A4A4A; }
        .payment-history-overview .Collapsible .collapsible-content .row-container:not(:last-child) hr,
        .payment-history .Collapsible .collapsible-content .row-container:not(:last-child) hr {
          width: 95%;
          margin: auto; }
        .payment-history-overview .Collapsible .collapsible-content .row-container:last-child,
        .payment-history .Collapsible .collapsible-content .row-container:last-child {
          margin-bottom: 10px; }
      .payment-history-overview .Collapsible .collapsible-content .border-line,
      .payment-history .Collapsible .collapsible-content .border-line {
        border-color: #D5D5D5;
        width: 95%;
        height: 1px;
        margin: auto; }
    .payment-history-overview .Collapsible .arrow,
    .payment-history .Collapsible .arrow {
      border-right: 3px solid;
      border-bottom: 3px solid;
      padding: 6px;
      border-color: #00A664;
      height: 5px;
      margin-left: 25px; }
      .payment-history-overview .Collapsible .arrow.right,
      .payment-history .Collapsible .arrow.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transition: transform 0.2s linear, opacity 0.2s linear; }
      .payment-history-overview .Collapsible .arrow.down,
      .payment-history .Collapsible .arrow.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transition: transform 0.2s linear, opacity 0.2s linear; }
    .payment-history-overview .Collapsible .price-group,
    .payment-history .Collapsible .price-group {
      display: flex;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 500;
      color: #4A4A4A; }
      .payment-history-overview .Collapsible .price-group .dollar,
      .payment-history-overview .Collapsible .price-group .decimal,
      .payment-history .Collapsible .price-group .dollar,
      .payment-history .Collapsible .price-group .decimal {
        font-size: 20px;
        margin-bottom: 10px !important; }
      .payment-history-overview .Collapsible .price-group .hyphen,
      .payment-history-overview .Collapsible .price-group .price,
      .payment-history .Collapsible .price-group .hyphen,
      .payment-history .Collapsible .price-group .price {
        font-size: 32px;
        line-height: 25px; }
      .payment-history-overview .Collapsible .price-group .hyphen,
      .payment-history .Collapsible .price-group .hyphen {
        margin-right: 5px; } }

@media only screen and (max-width: 700px) {
  .payment-history-overview .Collapsible,
  .payment-history .Collapsible {
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    box-shadow: 0px 1px 0px #D5D5D5;
    width: 100%;
    margin-top: 7px; }
    .payment-history-overview .Collapsible .collapsible-heading-container,
    .payment-history .Collapsible .collapsible-heading-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 15px; }
      .payment-history-overview .Collapsible .collapsible-heading-container .heading1 .month-heading,
      .payment-history .Collapsible .collapsible-heading-container .heading1 .month-heading {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 600;
        color: #4A4A4A; }
      .payment-history-overview .Collapsible .collapsible-heading-container .heading1 .card-heading,
      .payment-history .Collapsible .collapsible-heading-container .heading1 .card-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #4A4A4A; }
      .payment-history-overview .Collapsible .collapsible-heading-container .posted-day,
      .payment-history .Collapsible .collapsible-heading-container .posted-day {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383; }
      .payment-history-overview .Collapsible .collapsible-heading-container .details,
      .payment-history .Collapsible .collapsible-heading-container .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap; }
    .payment-history-overview .Collapsible .collapsible-content,
    .payment-history .Collapsible .collapsible-content {
      display: flex;
      flex-direction: column; }
      .payment-history-overview .Collapsible .collapsible-content .content-heading,
      .payment-history .Collapsible .collapsible-content .content-heading {
        height: 45px;
        background: #EDEDED;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        color: #838383;
        display: flex;
        align-items: center;
        padding-left: 15px; }
      .payment-history-overview .Collapsible .collapsible-content .row-container,
      .payment-history .Collapsible .collapsible-content .row-container {
        display: flex;
        flex-direction: column; }
        .payment-history-overview .Collapsible .collapsible-content .row-container .row,
        .payment-history .Collapsible .collapsible-content .row-container .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 20px; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .card-info,
          .payment-history .Collapsible .collapsible-content .row-container .row .card-info {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .price-value,
          .payment-history .Collapsible .collapsible-content .row-container .row .price-value {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #838383; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .content-label,
          .payment-history .Collapsible .collapsible-content .row-container .row .content-label {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .content-value,
          .payment-history .Collapsible .collapsible-content .row-container .row .content-value {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            text-align: right;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row.desktop,
          .payment-history .Collapsible .collapsible-content .row-container .row.desktop {
            display: none; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .label,
          .payment-history .Collapsible .collapsible-content .row-container .row .label {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .value,
          .payment-history .Collapsible .collapsible-content .row-container .row .value {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .payment-history-overview .Collapsible .collapsible-content .row-container .row .card-img,
          .payment-history .Collapsible .collapsible-content .row-container .row .card-img {
            width: 35px;
            margin-right: 12px; }
        .payment-history-overview .Collapsible .collapsible-content .row-container:not(:last-child) hr,
        .payment-history .Collapsible .collapsible-content .row-container:not(:last-child) hr {
          width: 95%;
          margin: auto; }
        .payment-history-overview .Collapsible .collapsible-content .row-container:last-child,
        .payment-history .Collapsible .collapsible-content .row-container:last-child {
          margin-bottom: 5px; }
      .payment-history-overview .Collapsible .collapsible-content .border-line,
      .payment-history .Collapsible .collapsible-content .border-line {
        border-color: #D5D5D5;
        width: 95%;
        height: 1px;
        margin: auto; }
    .payment-history-overview .Collapsible .arrow,
    .payment-history .Collapsible .arrow {
      border-right: 3px solid;
      border-bottom: 3px solid;
      padding: 6px;
      border-color: #00A664;
      height: 5px;
      margin-left: 10px; }
      .payment-history-overview .Collapsible .arrow.right,
      .payment-history .Collapsible .arrow.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transition: transform 0.2s linear, opacity 0.2s linear; }
      .payment-history-overview .Collapsible .arrow.down,
      .payment-history .Collapsible .arrow.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transition: transform 0.2s linear, opacity 0.2s linear; }
    .payment-history-overview .Collapsible .price-group,
    .payment-history .Collapsible .price-group {
      display: flex;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 500;
      color: #4A4A4A; }
      .payment-history-overview .Collapsible .price-group .dollar,
      .payment-history-overview .Collapsible .price-group .decimal,
      .payment-history .Collapsible .price-group .dollar,
      .payment-history .Collapsible .price-group .decimal {
        font-size: 12px;
        margin-bottom: 10px !important; }
      .payment-history-overview .Collapsible .price-group .hyphen,
      .payment-history-overview .Collapsible .price-group .price,
      .payment-history .Collapsible .price-group .hyphen,
      .payment-history .Collapsible .price-group .price {
        font-size: 18px;
        line-height: 25px; }
      .payment-history-overview .Collapsible .price-group .hyphen,
      .payment-history .Collapsible .price-group .hyphen {
        margin-right: 5px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@media only screen and (min-width: 701px) {
  .payment-invoice-page .Collapsible {
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    box-shadow: 0px 1px 0px #D5D5D5;
    width: 100%;
    margin-top: 7px; }
    .payment-invoice-page .Collapsible .collapsible-heading-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 22px 35px 22px 20px; }
      .payment-invoice-page .Collapsible .collapsible-heading-container .heading1 .month-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.5px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .payment-invoice-page .Collapsible .collapsible-heading-container .heading1 .card-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #4A4A4A; }
      .payment-invoice-page .Collapsible .collapsible-heading-container .heading2 {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383;
        margin-top: 5px; }
      .payment-invoice-page .Collapsible .collapsible-heading-container .details {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 30%; }
    .payment-invoice-page .Collapsible .collapsible-content {
      display: flex;
      flex-direction: column; }
      .payment-invoice-page .Collapsible .collapsible-content .content-heading {
        height: 45px;
        background: #EDEDED;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        color: #838383;
        display: flex;
        align-items: center;
        padding: 0px 30px;
        justify-content: space-between; }
      .payment-invoice-page .Collapsible .collapsible-content .row-container {
        display: flex;
        flex-direction: column; }
        .payment-invoice-page .Collapsible .collapsible-content .row-container .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 30px; }
          .payment-invoice-page .Collapsible .collapsible-content .row-container .row .card-info {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A;
            max-width: 70%; }
          .payment-invoice-page .Collapsible .collapsible-content .row-container .row .price-value {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #838383; }
          .payment-invoice-page .Collapsible .collapsible-content .row-container .row .content-label {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #838383; }
          .payment-invoice-page .Collapsible .collapsible-content .row-container .row .content-value {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            text-align: right;
            color: #4A4A4A; }
        .payment-invoice-page .Collapsible .collapsible-content .row-container:not(:last-child) hr {
          width: 95%;
          margin: auto; }
        .payment-invoice-page .Collapsible .collapsible-content .row-container:last-child {
          margin-bottom: 10px; }
      .payment-invoice-page .Collapsible .collapsible-content .border-line {
        border-color: #D5D5D5;
        width: 95%;
        height: 1px;
        margin: auto; }
    .payment-invoice-page .Collapsible .arrow {
      border-right: 3px solid;
      border-bottom: 3px solid;
      padding: 6px;
      border-color: #00A664;
      height: 5px; }
      .payment-invoice-page .Collapsible .arrow.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transition: transform 0.2s linear, opacity 0.2s linear; }
      .payment-invoice-page .Collapsible .arrow.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transition: transform 0.2s linear, opacity 0.2s linear; }
    .payment-invoice-page .Collapsible .price-group {
      display: flex;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 500;
      color: #4A4A4A; }
      .payment-invoice-page .Collapsible .price-group .dollar,
      .payment-invoice-page .Collapsible .price-group .decimal {
        font-size: 20px;
        margin-bottom: 10px !important; }
      .payment-invoice-page .Collapsible .price-group .dollar {
        margin-left: 5px; }
      .payment-invoice-page .Collapsible .price-group .price {
        font-size: 32px;
        line-height: 25px; } }

@media only screen and (max-width: 700px) {
  .payment-invoice-page .Collapsible {
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    box-shadow: 0px 1px 0px #D5D5D5;
    width: 100%;
    margin-top: 7px; }
    .payment-invoice-page .Collapsible .collapsible-heading-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 15px; }
      .payment-invoice-page .Collapsible .collapsible-heading-container .heading1 .month-heading {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #4A4A4A; }
      .payment-invoice-page .Collapsible .collapsible-heading-container .heading1 .card-heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #4A4A4A; }
      .payment-invoice-page .Collapsible .collapsible-heading-container .heading2 {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #838383;
        margin-top: 5px; }
      .payment-invoice-page .Collapsible .collapsible-heading-container .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 25%; }
    .payment-invoice-page .Collapsible .collapsible-content {
      display: flex;
      flex-direction: column; }
      .payment-invoice-page .Collapsible .collapsible-content .content-heading {
        height: 45px;
        background: #EDEDED;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        color: #838383;
        display: flex;
        align-items: center;
        padding-left: 15px; }
      .payment-invoice-page .Collapsible .collapsible-content .row-container {
        display: flex;
        flex-direction: column; }
        .payment-invoice-page .Collapsible .collapsible-content .row-container .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 20px; }
          .payment-invoice-page .Collapsible .collapsible-content .row-container .row .card-info {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .payment-invoice-page .Collapsible .collapsible-content .row-container .row .price-value {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #838383; }
          .payment-invoice-page .Collapsible .collapsible-content .row-container .row .content-label {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A; }
          .payment-invoice-page .Collapsible .collapsible-content .row-container .row .content-value {
            font-family: 'Proxima Nova';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 600;
            text-align: right;
            color: #4A4A4A; }
        .payment-invoice-page .Collapsible .collapsible-content .row-container:not(:last-child) hr {
          width: 95%;
          margin: auto; }
        .payment-invoice-page .Collapsible .collapsible-content .row-container:last-child {
          margin-bottom: 5px; }
      .payment-invoice-page .Collapsible .collapsible-content .border-line {
        border-color: #D5D5D5;
        width: 95%;
        height: 1px;
        margin: auto; }
    .payment-invoice-page .Collapsible .arrow {
      border-right: 3px solid;
      border-bottom: 3px solid;
      padding: 6px;
      border-color: #00A664;
      height: 5px; }
      .payment-invoice-page .Collapsible .arrow.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transition: transform 0.2s linear, opacity 0.2s linear; }
      .payment-invoice-page .Collapsible .arrow.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transition: transform 0.2s linear, opacity 0.2s linear; }
    .payment-invoice-page .Collapsible .price-group {
      display: flex;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 500;
      color: #4A4A4A; }
      .payment-invoice-page .Collapsible .price-group .dollar,
      .payment-invoice-page .Collapsible .price-group .decimal {
        font-size: 12px;
        margin-bottom: 10px !important; }
      .payment-invoice-page .Collapsible .price-group .price {
        font-size: 18px;
        line-height: 25px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.invoice-details-mobileOnly {
  background-color: #FFFFFF; }
  .invoice-details-mobileOnly .heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px; }
    .invoice-details-mobileOnly .heading-container .heading1 .month-heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      letter-spacing: -0.5px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased; }
    .invoice-details-mobileOnly .heading-container .heading1 .card-heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      color: #4A4A4A; }
    .invoice-details-mobileOnly .heading-container .heading2 {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.25px;
      font-style: normal;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #838383;
      margin-top: 5px; }
    .invoice-details-mobileOnly .heading-container .details {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .invoice-details-mobileOnly .heading-container .details .price-group {
        display: flex;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: 500;
        color: #4A4A4A; }
        .invoice-details-mobileOnly .heading-container .details .price-group .dollar,
        .invoice-details-mobileOnly .heading-container .details .price-group .decimal {
          font-size: 12px;
          margin-bottom: 10px !important; }
        .invoice-details-mobileOnly .heading-container .details .price-group .price {
          font-size: 21px;
          line-height: 25px; }
  .invoice-details-mobileOnly .actions {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px; }
    .invoice-details-mobileOnly .actions .view-button {
      border: 2px solid #00A664;
      border-radius: 5px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #00A664;
      height: 40px;
      width: 47%; }
    .invoice-details-mobileOnly .actions .download-button {
      background: #00A664;
      border-radius: 5px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      height: 40px;
      width: 47%; }
  .invoice-details-mobileOnly .content {
    display: flex;
    flex-direction: column;
    margin-top: 30px; }
    .invoice-details-mobileOnly .content .content-heading {
      height: 45px;
      background: #EDEDED;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding-left: 15px;
      justify-content: space-between; }
      .invoice-details-mobileOnly .content .content-heading .amount {
        padding-right: 20px; }
    .invoice-details-mobileOnly .content .row-container {
      display: flex;
      flex-direction: column; }
      .invoice-details-mobileOnly .content .row-container .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px; }
        .invoice-details-mobileOnly .content .row-container .row .card-info {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          width: 60%; }
        .invoice-details-mobileOnly .content .row-container .row .price-value {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500;
          color: #838383; }
        .invoice-details-mobileOnly .content .row-container .row .content-label {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A; }
        .invoice-details-mobileOnly .content .row-container .row .content-value {
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 600;
          text-align: right;
          color: #4A4A4A; }
      .invoice-details-mobileOnly .content .row-container:not(:last-child) hr {
        width: 95%;
        margin: auto; }
      .invoice-details-mobileOnly .content .row-container:last-child {
        margin-bottom: 5px; }
    .invoice-details-mobileOnly .content .border-line {
      border-color: #D5D5D5;
      width: 95%;
      height: 1px;
      margin: auto; }

.invoice-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px; }
  .invoice-view-container.desktop-view {
    max-width: 1000px;
    width: 100%;
    margin: auto; }
    .invoice-view-container.desktop-view .top-header {
      border-radius: 8px 8px 0px 0px; }
    .invoice-view-container.desktop-view .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 700px;
      overflow: scroll;
      border-radius: 0px 0px 8px 8px; }
    .invoice-view-container.desktop-view .react-pdf__Document {
      width: fit-content; }
  .invoice-view-container .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    background-color: #FFFFFF;
    min-height: 500px; }
    .invoice-view-container .content .download-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #FFFFFF;
      border-radius: 5px;
      width: 90%;
      margin: 0 auto;
      height: 40px;
      margin-bottom: 30px;
      max-width: 250px; }
      .invoice-view-container .content .download-button:disabled {
        background-color: #838383;
        cursor: not-allowed;
        border: none; }
      .invoice-view-container .content .download-button:enabled {
        background-color: #00A664; }
  .invoice-view-container .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: inherit; }
  .invoice-view-container .react-pdf__Page {
    width: 90%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    margin-top: 15px; }
    .invoice-view-container .react-pdf__Page canvas {
      max-width: 100%;
      height: auto !important; }
  .invoice-view-container .react-pdf__message {
    padding: 20px;
    color: white; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.radio-section {
  display: flex;
  margin-top: 5px; }
  .radio-section .radio {
    display: flex;
    align-items: center;
    width: 170px;
    border-radius: 24px; }
    .radio-section .radio.active {
      background-color: #E5F6EF; }
      .radio-section .radio.active .round {
        border: 6px solid #00A664; }
    .radio-section .radio .round {
      background: #FFFFFF;
      border: 2px solid #838383;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin: 10px; }
    .radio-section .radio .text {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #4A4A4A; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.status-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 16px; }
  .status-content.pending {
    background-color: #EDF4FC; }
    .status-content.pending .warning-heading, .status-content.pending .warning-text {
      color: #1E3A5A; }
  .status-content.declined, .status-content.expiring, .status-content.expired {
    background-color: #FEEFE6; }
    .status-content.declined .warning-heading, .status-content.declined .warning-text, .status-content.expiring .warning-heading, .status-content.expiring .warning-text, .status-content.expired .warning-heading, .status-content.expired .warning-text {
      color: #642801; }
  .status-content .warning-icon {
    display: flex;
    margin-right: 16px;
    height: 100%; }
    .status-content .warning-icon img {
      height: 23px; }
  .status-content .warning-heading {
    font-family: 'Proxima Nova Bold';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    margin-bottom: 3px;
    text-transform: capitalize; }
  .status-content .warning-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased; }
  .status-content .callable {
    font-weight: 1000;
    text-decoration: underline;
    font-family: 'Proxima Nova Bold'; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.maintenance-page {
  background: #E5E5E5;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 12.5rem); }
  .maintenance-page .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px;
    background: #E5E5E5;
    max-width: 900px;
    height: fit-content; }
    .maintenance-page .container .header {
      background: #00A664;
      border: 1px solid #D5D5D5;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .maintenance-page .container .header .backArrow {
        width: 10%;
        padding: 22px; }
        .maintenance-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .maintenance-page .container .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        width: 85%; }
    .maintenance-page .container .main {
      display: flex;
      padding: 24px 44px; }
      .maintenance-page .container .main .section {
        display: flex;
        flex-direction: column;
        min-width: 45%; }
        .maintenance-page .container .main .section .top-data {
          display: flex;
          justify-content: space-between; }
          .maintenance-page .container .main .section .top-data .maintenance-static {
            display: flex; }
            .maintenance-page .container .main .section .top-data .maintenance-static img {
              padding-bottom: 9px; }
            .maintenance-page .container .main .section .top-data .maintenance-static .info {
              display: flex;
              flex-direction: column; }
              .maintenance-page .container .main .section .top-data .maintenance-static .info .label {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.25px;
                font-style: normal;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: antialiased;
                text-align: left;
                color: #838383;
                text-transform: uppercase; }
              .maintenance-page .container .main .section .top-data .maintenance-static .info .data {
                font-family: 'DINNextLTPro-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.25px;
                text-align: left;
                margin: 0px;
                color: #4A4A4A; }
        .maintenance-page .container .main .section .left-section .maintenance-due .text {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased; }
        .maintenance-page .container .main .section .left-section .maintenance-due .due-details {
          display: flex;
          margin-top: 24px;
          margin-bottom: 16px; }
          .maintenance-page .container .main .section .left-section .maintenance-due .due-details img {
            width: 24px;
            height: 24px;
            margin-right: 6px; }
          .maintenance-page .container .main .section .left-section .maintenance-due .due-details .or {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            padding: 0 9px;
            color: #838383; }
          .maintenance-page .container .main .section .left-section .maintenance-due .due-details .data {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px; }
        .maintenance-page .container .main .section .left-section .find-provider {
          margin-top: 8px;
          border-top: 1px solid #D5D5D5; }
          .maintenance-page .container .main .section .left-section .find-provider .service-providers .heading {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            margin-bottom: 0; }
          .maintenance-page .container .main .section .left-section .find-provider .service-providers .details .name {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
          .maintenance-page .container .main .section .left-section .find-provider .service-providers .details .address-line {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #838383; }
          .maintenance-page .container .main .section .left-section .find-provider .service-providers .phone-details .left-section img {
            width: 16px;
            height: 16px;
            margin-right: 4px; }
          .maintenance-page .container .main .section .left-section .find-provider .service-providers .phone-details .left-section .phone-number {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-weight: 500;
            color: #00A664; }
          .maintenance-page .container .main .section .left-section .find-provider .service-providers-help {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            padding: 16px 0 8px 0;
            margin-top: 8px;
            display: flex;
            justify-content: space-between; }
            .maintenance-page .container .main .section .left-section .find-provider .service-providers-help .find-button {
              padding: 0; }
            .maintenance-page .container .main .section .left-section .find-provider .service-providers-help img {
              width: 24px;
              height: 24px; }
          .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .sp-header {
            display: flex;
            justify-content: space-between; }
            .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .sp-header .sp-heading {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 700;
              font-size: 26px;
              line-height: 32px;
              letter-spacing: 0.15px;
              color: #4A4A4A;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased; }
            .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .sp-header .close {
              height: 24px;
              width: 24px;
              cursor: pointer; }
          .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .pre-text {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            font-weight: 400;
            margin-top: 16px; }
          .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content {
            width: auto;
            display: flex;
            justify-content: space-around;
            margin-top: 52px;
            margin-left: 120px;
            margin-right: 120px; }
            .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand {
              border: 1px solid #d5d5d5;
              -webkit-filter: drop-shadow(0 1px 0 #d5d5d5);
              filter: drop-shadow(0 1px 0 #D5D5D5);
              border-radius: 4px;
              width: 200px;
              height: 60px;
              padding: 16px;
              cursor: pointer; }
              .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand.selected {
                border: 3px solid #00a664;
                background-color: #d4ede1;
                box-shadow: 0 0; }
                .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand.selected .tick-icon-container {
                  position: relative; }
                  .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand.selected .tick-icon-container .tick-icon {
                    position: absolute;
                    bottom: 6px;
                    right: 10px; }
              .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand .storeImage-firestone {
                width: 120px;
                height: 18px;
                margin-left: 20px; }
              .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand .storeImage-pepboys {
                width: 96px;
                height: 32px;
                margin-left: 35px;
                position: relative;
                bottom: 4px; }
          .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .title-container {
            display: flex;
            justify-content: space-around;
            margin-top: 15px;
            margin-left: 120px;
            margin-right: 120px; }
            .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .title-container .title {
              font-family: 'DINNextLTPro-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: -0.25px; }
          .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .button-container .continue {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            width: 216px;
            height: 40px;
            border-radius: 5px; }
            .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .button-container .continue:disabled {
              background: #838383; }
            .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .button-container .continue:enabled {
              background-color: #00a664; }
        .maintenance-page .container .main .section .left-section .download-card {
          margin-top: 8px;
          border-top: 1px solid #D5D5D5; }
          .maintenance-page .container .main .section .left-section .download-card .download {
            display: flex;
            justify-content: space-between;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            padding-top: 16px; }
            .maintenance-page .container .main .section .left-section .download-card .download img {
              width: 24px;
              height: 24px; }
          .maintenance-page .container .main .section .left-section .download-card .help-text {
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            width: 253px;
            height: 40px;
            display: block;
            color: #838383; }
        .maintenance-page .container .main .section .update-info {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 400;
          margin-top: 8px;
          margin-bottom: 16px;
          color: #838383; }
        .maintenance-page .container .main .section .update-note {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #838383; }
      .maintenance-page .container .main .line {
        display: block;
        border: 0.8px solid #ccc;
        margin: 10px 50px; }
      .maintenance-page .container .main .section-right .update-section {
        width: 337px;
        height: fit-content;
        padding: 24px 40px 0 40px;
        gap: 16px;
        border: 1px solid #D5D5D5;
        border-radius: 8px; }
        .maintenance-page .container .main .section-right .update-section h2 {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A4A4A;
          margin-top: 0;
          margin-bottom: 16px;
          text-align: center; }
        .maintenance-page .container .main .section-right .update-section .dynamic-update .update-note {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #838383;
          margin: 16px 0; }
        .maintenance-page .container .main .section-right .update-section .dynamic-update .mileage {
          font-family: 'DINNextLTPro-Medium';
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          letter-spacing: -0.25px;
          color: #4A4A4A;
          margin-top: 40px;
          margin-bottom: 24px;
          display: flex;
          justify-content: center;
          height: 47px;
          padding-top: 5px; }
          .maintenance-page .container .main .section-right .update-section .dynamic-update .mileage div:nth-child(1) {
            font-size: 48px; }
          .maintenance-page .container .main .section-right .update-section .dynamic-update .mileage div:nth-child(2) {
            font-size: 28px;
            line-height: 1px; }
        .maintenance-page .container .main .section-right .update-section .dynamic-update .button-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px; }
          .maintenance-page .container .main .section-right .update-section .dynamic-update .button-container button {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #FFFFFF;
            border-radius: 5px;
            border-radius: 5px;
            height: 40px; }
            .maintenance-page .container .main .section-right .update-section .dynamic-update .button-container button:disabled {
              background-color: #838383;
              cursor: not-allowed;
              border: none; }
            .maintenance-page .container .main .section-right .update-section .dynamic-update .button-container button:enabled {
              background-color: #00A664; }
          .maintenance-page .container .main .section-right .update-section .dynamic-update .button-container .update-button {
            width: 100%;
            background-color: #FFFFFF !important;
            border: 2px solid #00A664;
            color: #00A664; }
          .maintenance-page .container .main .section-right .update-section .dynamic-update .button-container .cancel-button {
            width: 147px;
            margin-right: 17px;
            margin-left: -27px;
            position: relative;
            bottom: 10px; }
          .maintenance-page .container .main .section-right .update-section .dynamic-update .button-container .save-button {
            width: 147px;
            margin-right: -27px;
            position: relative;
            bottom: 10px; }
        .maintenance-page .container .main .section-right .update-section .dynamic-update .mileage-input {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 16px;
          margin-bottom: 16px; }
          .maintenance-page .container .main .section-right .update-section .dynamic-update .mileage-input input {
            width: 150px;
            height: 59px;
            border: 2px solid #D5D5D5;
            padding: 10px;
            font-family: 'DINNextLTPro-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 48px;
            /* or 133% */
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -1px;
            /* Commute Charcoal */
            color: #4A4A4A; }
            .maintenance-page .container .main .section-right .update-section .dynamic-update .mileage-input input:focus {
              border-bottom: 3px solid #00A664; }
            .maintenance-page .container .main .section-right .update-section .dynamic-update .mileage-input input.error {
              border: 2px solid #F96302; }
          .maintenance-page .container .main .section-right .update-section .dynamic-update .mileage-input .error-div {
            margin-top: 10px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.25px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #F96302; }
        .maintenance-page .container .main .section-right .update-section .dynamic-update .wait-notification .status-content.pending {
          display: flex;
          justify-content: flex-start;
          padding: 16px 16px 14px 16px;
          margin-left: -40px;
          margin-right: -40px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          width: auto; }
      .maintenance-page .container .main .section-right .update-info {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 400;
        text-align: center;
        margin-top: 8px;
        color: #838383; }
    .maintenance-page .container .predelivery-screen {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #FFFFFF;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px; }
      .maintenance-page .container .predelivery-screen img {
        width: 60px;
        height: 60px;
        margin-top: 80px;
        margin-bottom: 24px; }
      .maintenance-page .container .predelivery-screen .text {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        font-weight: 400;
        margin-bottom: 92px; }

@media only screen and (min-width: 1011px) {
  .maintenance-page {
    padding-top: 160px; }
    .maintenance-page .header {
      border-radius: 8px 8px 0px 0px;
      height: 80px; }
      .maintenance-page .header .heading {
        font-size: 36px;
        line-height: 48px; } }

@media only screen and (max-width: 1010px) and (min-width: 700px) {
  .maintenance-page {
    padding-top: 80px; }
    .maintenance-page .header {
      border-radius: 8px 8px 0px 0px;
      height: 80px; }
      .maintenance-page .header .heading {
        font-size: 36px;
        line-height: 48px; }
    .maintenance-page .container {
      padding-left: 25px;
      margin-right: 30px; }
      .maintenance-page .container .main .line {
        margin: 10px 20px; } }

@media only screen and (max-width: 700px) {
  .maintenance-page {
    padding-top: 50px;
    flex-direction: column;
    margin: 5px 15px; }
    .maintenance-page .container .header {
      padding-left: 10px;
      height: 65px; }
      .maintenance-page .container .header .backArrow {
        width: 10%;
        position: absolute;
        padding: 0px !important; }
        .maintenance-page .container .header .backArrow img {
          height: 35px;
          width: 35px; }
      .maintenance-page .container .header .heading {
        font-weight: 500;
        font-size: 24px;
        line-height: 48px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        margin: auto; }
    .maintenance-page .container .main {
      flex-direction: column;
      padding: 20px 20px;
      align-items: center; }
      .maintenance-page .container .main .section {
        padding: 20px;
        width: 100%;
        min-width: unset !important;
        max-width: 340px; }
        .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .sp-header .sp-heading {
          font-size: 18px; }
        .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .pre-text {
          font-size: 14px; }
        .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content {
          width: auto;
          display: flex;
          justify-content: space-around;
          margin-top: 24px;
          margin-left: 0;
          margin-right: 0; }
          .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand {
            width: 154px; }
            .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand .storeImage-firestone {
              margin-left: 0; }
            .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .service-provider-popup-content .sp-box .sp-brand .storeImage-pepboys {
              margin-left: 16px; }
        .maintenance-page .container .main .section .left-section .find-provider .charges-popup .service-provider-popup .title-container {
          margin: 15px 0 24px 0; }
      .maintenance-page .container .main .line {
        margin: 10px 0px;
        width: 100%; }
      .maintenance-page .container .main .section-right {
        padding-top: 20px;
        margin-left: -18px;
        width: 100%;
        min-width: unset !important;
        max-width: 340px; }
    .maintenance-page .container .predelivery-screen img {
      margin-top: 108px;
      margin-bottom: 8px; }
    .maintenance-page .container .predelivery-screen .text {
      margin: 0 30px 132px 30px;
      text-align: center; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

.download-app-container .mid-section {
  background-color: #130C0E;
  padding: 12px 0px; }
  .download-app-container .mid-section .green-section {
    background-color: #0F8952;
    padding: 5px; }
  .download-app-container .mid-section .heading {
    text-align: center;
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-weight: 600 !important;
    color: #FFFFFF;
    margin: 10px 0px; }
  .download-app-container .mid-section .app-container {
    margin: 20px 0px; }
    .download-app-container .mid-section .app-container .apps {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 10px; }
      .download-app-container .mid-section .app-container .apps img {
        cursor: pointer; }

.download-app-container .bottom-section {
  margin: 25px; }
  .download-app-container .bottom-section ul {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 15px; }
  .download-app-container .bottom-section .list-item {
    display: flex; }
    .download-app-container .bottom-section .list-item img {
      width: 24px;
      margin-right: 12px; }
    .download-app-container .bottom-section .list-item .text {
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; }

.download-app-popup {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 95%;
  max-height: 70vh; }
  .download-app-popup:after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -2; }
  .download-app-popup:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #FFF;
    z-index: -1;
    border-radius: 8px; }
  .download-app-popup.show {
    display: block; }
  .download-app-popup.hide {
    display: none; }

#download-app-popup {
  width: fit-content;
  border-radius: 8px; }
  #download-app-popup .close {
    float: right; }
  #download-app-popup .download-app-popup-container {
    text-align: center;
    margin: auto; }
    #download-app-popup .download-app-popup-container .apps-section {
      display: flex;
      justify-content: space-evenly;
      margin-top: 15px;
      width: 100%;
      flex-wrap: wrap; }
      #download-app-popup .download-app-popup-container .apps-section .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      #download-app-popup .download-app-popup-container .apps-section .store {
        margin-top: 16px;
        margin-bottom: 20px; }
      #download-app-popup .download-app-popup-container .apps-section .divider {
        border-left: 2px solid #D5D5D5; }
    #download-app-popup .download-app-popup-container .header {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      text-align: center; }
    #download-app-popup .download-app-popup-container .download-app-popup-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 50vh;
      overflow: auto; }
      #download-app-popup .download-app-popup-container .download-app-popup-content .qr {
        padding: 10px;
        width: 125px;
        border-radius: 8px;
        border: 1px solid #D5D5D5;
        margin-top: 20px; }
      #download-app-popup .download-app-popup-container .download-app-popup-content .appstore {
        height: 40px;
        margin-top: 15px; }
      #download-app-popup .download-app-popup-container .download-app-popup-content .content {
        font-family: 'Proxima nova';
        font-size: 15px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.25px;
        color: #4A4A4A; }
        #download-app-popup .download-app-popup-container .download-app-popup-content .content.question {
          font-weight: 700;
          margin-top: 20px; }
        #download-app-popup .download-app-popup-container .download-app-popup-content .content.text {
          font-weight: 400; }
      #download-app-popup .download-app-popup-container .download-app-popup-content .save-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 8px 24px;
        width: 215px;
        margin-top: 32px;
        margin-bottom: 20px; }
        #download-app-popup .download-app-popup-container .download-app-popup-content .save-button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        #download-app-popup .download-app-popup-container .download-app-popup-content .save-button:enabled {
          background-color: #00A664; }

@media only screen and (min-width: 1024px) {
  .download-app-container {
    border-radius: 8px;
    border: 1px solid #D5D5D5; }
    .download-app-container .mobile-image img {
      width: calc(100%);
      border-radius: 8px 8px 0px 0px; }
  #download-app-popup {
    padding: 30px; }
    #download-app-popup .download-app-popup-container {
      width: 65%; } }

@media only screen and (max-width: 1023px) and (min-width: 701px) {
  .download-app-container .mobile-image img {
    display: none; }
  #download-app-popup {
    padding: 20px; }
    #download-app-popup .download-app-popup-container {
      width: 80%; } }

@media only screen and (max-width: 700px) {
  .download-app-container .mobile-image img {
    display: none; }
  #download-app-popup {
    padding: 20px; } }

@media only screen and (max-width: 324px) {
  .apps-section .divider {
    display: none; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.alerts-container input:focus,
.alerts-container input:focus .etable-heading.toggle,
.alerts-container textarea:focus,
.alerts-container textarea:focus .etable-heading.toggle,
.alerts-container button:focus,
.alerts-container button:focus .etable-heading.toggle,
.alerts-container select:focus,
.alerts-container select:focus .etable-heading.toggle,
.alerts-container a:focus,
.alerts-container a:focus .etable-heading.toggle,
.alerts-container [tabindex]:focus,
.alerts-container [tabindex]:focus .etable-heading.toggle {
  outline: none;
  box-shadow: none; }

@media only screen and (min-width: 701px) {
  .alerts-container .Collapsible:has(.is-open):has(.read) {
    background: #F3F3F3; }
  .alerts-container .Collapsible {
    background: #FFFFFF;
    height: fit-content;
    border-top: 1px solid #D5D5D5;
    width: 100%; }
    .alerts-container .Collapsible .collapsible-heading-container {
      display: flex;
      flex-direction: row;
      padding: 16px 12px 16px 12px;
      justify-content: space-between; }
      .alerts-container .Collapsible .collapsible-heading-container .header-content {
        display: flex;
        flex-direction: column; }
        .alerts-container .Collapsible .collapsible-heading-container .header-content .heading {
          display: flex; }
          .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .right-section {
            max-width: 90%;
            display: flex;
            flex-direction: column; }
            .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .right-section .summary-text {
              font-family: 'DINNextLTPro-Regular';
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.25px;
              text-align: left;
              color: #4A4A4A; }
          .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .circle {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            margin-right: 10px;
            margin-top: 2px; }
            .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .circle.read {
              background-color: #C3C3C3; }
            .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .circle.unread {
              background-color: #2275D3; }
        .alerts-container .Collapsible .collapsible-heading-container .header-content .createdDate {
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          line-height: 18px;
          font-size: 12px;
          color: #838383;
          text-transform: uppercase; }
      .alerts-container .Collapsible .collapsible-heading-container .arrow {
        border-right: 3px solid;
        border-bottom: 3px solid;
        padding: 5px;
        border-color: #838383;
        height: 5px;
        margin-left: 25px;
        width: auto; }
        .alerts-container .Collapsible .collapsible-heading-container .arrow.up {
          transform: rotate(-130deg);
          -webkit-transform: rotate(-130deg);
          transition: transform 0.2s linear, opacity 0.2s linear; }
        .alerts-container .Collapsible .collapsible-heading-container .arrow.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transition: transform 0.2s linear, opacity 0.2s linear; }
    .alerts-container .Collapsible .collapsible-content {
      display: flex;
      flex-direction: column;
      padding: 0px 34px 10px; }
      .alerts-container .Collapsible .collapsible-content .content-heading {
        height: 45px;
        background: #EDEDED;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        color: #838383;
        display: flex;
        align-items: center;
        padding-left: 15px; }
      .alerts-container .Collapsible .collapsible-content .content-container {
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        line-height: 18px;
        color: #838383;
        display: flex;
        flex-direction: column; }
        .alerts-container .Collapsible .collapsible-content .content-container ul li {
          list-style: disc;
          background: #EDEDED; } }

@media only screen and (max-width: 700px) {
  .alerts-container .Collapsible:has(.is-open):has(.read) {
    background: #F3F3F3; }
  .alerts-container .Collapsible {
    background: #FFFFFF;
    height: fit-content;
    border-top: 1px solid #D5D5D5;
    width: 100%; }
    .alerts-container .Collapsible .collapsible-heading-container {
      display: flex;
      flex-direction: row;
      padding: 16px 12px 16px 12px;
      justify-content: space-between; }
      .alerts-container .Collapsible .collapsible-heading-container .header-content {
        display: flex; }
        .alerts-container .Collapsible .collapsible-heading-container .header-content .heading {
          display: flex; }
          .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .right-section {
            max-width: 90%;
            display: flex;
            flex-direction: column; }
            .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .right-section .summary-text {
              font-family: 'DINNextLTPro-Regular';
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.25px;
              text-align: left;
              color: #4A4A4A; }
          .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .circle {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            margin-right: 10px;
            margin-top: 2px; }
            .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .circle.read {
              background-color: #C3C3C3; }
            .alerts-container .Collapsible .collapsible-heading-container .header-content .heading .circle.unread {
              background-color: #2275D3; }
      .alerts-container .Collapsible .collapsible-heading-container .arrow {
        border-right: 3px solid;
        border-bottom: 3px solid;
        padding: 5px;
        border-color: #838383;
        height: 5px;
        margin-left: 10px;
        width: fit-content; }
        .alerts-container .Collapsible .collapsible-heading-container .arrow.up {
          transform: rotate(-130deg);
          -webkit-transform: rotate(-130deg);
          transition: transform 0.2s linear, opacity 0.2s linear; }
        .alerts-container .Collapsible .collapsible-heading-container .arrow.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transition: transform 0.2s linear, opacity 0.2s linear; }
    .alerts-container .Collapsible .collapsible-content {
      display: flex;
      flex-direction: column;
      padding: 0px 20px 10px 30px; }
      .alerts-container .Collapsible .collapsible-content .content-heading {
        height: 45px;
        background: #EDEDED;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        color: #838383;
        display: flex;
        align-items: center;
        padding-left: 15px; }
      .alerts-container .Collapsible .collapsible-content .content-container {
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        line-height: 18px;
        color: #838383;
        display: flex;
        flex-direction: column; }
        .alerts-container .Collapsible .collapsible-content .content-container ul li {
          list-style: disc;
          background: #EDEDED; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

.notification-banners {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px; }
  .notification-banners .notification-banner {
    display: flex;
    flex-direction: row;
    background-color: #FEEFE6;
    padding: 16px;
    margin-bottom: 8px;
    justify-content: space-between;
    box-shadow: 0px 1px 0px 0px rgba(213, 213, 213, 0.2); }
    .notification-banners .notification-banner .main-content {
      display: flex;
      flex-direction: row; }
      .notification-banners .notification-banner .main-content .notification-icon {
        display: flex; }
        .notification-banners .notification-banner .main-content .notification-icon img {
          height: 24px;
          width: 24px; }
      .notification-banners .notification-banner .main-content .notification-content {
        display: flex;
        flex-direction: column;
        padding-left: 16px; }
        .notification-banners .notification-banner .main-content .notification-content .notification-header {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #642801; }
        .notification-banners .notification-banner .main-content .notification-content .notification-description {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #642801;
          margin-top: 5px; }
    .notification-banners .notification-banner .notification-dismiss {
      display: flex;
      justify-content: center; }
      .notification-banners .notification-banner .notification-dismiss img {
        width: 24px;
        height: 24px;
        cursor: pointer; }

/*	COLORS
–––––––––––––––––––––––––––*/
.modal-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  position: fixed;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
  .modal-container .modal {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    padding: 10px; }
    .modal-container .modal .header {
      width: 100%;
      height: 40px;
      margin-bottom: 10px;
      font-size: 1.25rem;
      position: relative; }
      .modal-container .modal .header .close svg {
        position: absolute;
        right: 0;
        cursor: pointer; }
    .modal-container .modal .body {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 34px); }

@media (min-width: 768px) {
  .modal-container .modal {
    padding: 24px;
    margin-top: 100px; }
    .modal-container .modal .body {
      max-height: calc(100vh - 134px); } }

/*	COLORS
–––––––––––––––––––––––––––*/
.changeCommuteModal-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  position: fixed;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
  .changeCommuteModal-container .modal {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    padding: 10px;
    max-height: calc(100vh - 134px);
    height: max-content;
    max-width: 800px;
    display: inline-table; }
    .changeCommuteModal-container .modal .header {
      width: 100%;
      height: 24px;
      margin-bottom: 15px;
      font-size: 1.25rem;
      position: relative; }
      .changeCommuteModal-container .modal .header .close svg {
        position: absolute;
        right: 0;
        cursor: pointer;
        color: #d03131;
        font-size: 18px; }
    .changeCommuteModal-container .modal .body {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 250px); }
    .changeCommuteModal-container .modal .footer .privacy {
      color: #049b5c;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer; }
    .changeCommuteModal-container .modal .footer .privacy:hover {
      text-decoration: underline; }

@media (min-width: 768px) {
  .changeCommuteModal-container .modal {
    padding: 25px 25px 25px 50px;
    margin-top: 80px;
    max-height: calc(100vh - 134px); }
    .changeCommuteModal-container .modal .body {
      max-height: calc(100vh - 250px); } }

/*	COLORS
–––––––––––––––––––––––––––*/
.dailytripTimeModal-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  position: fixed;
  z-index: 500;
  background: #3e3b3b1a;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
  .dailytripTimeModal-container .modal {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    padding: 10px;
    max-height: calc(100vh - 134px);
    height: max-content;
    max-width: 900px;
    display: inline-table;
    color: #333333; }
    .dailytripTimeModal-container .modal .header {
      width: 100%;
      position: relative;
      border-bottom: 1px solid #ccc; }
      .dailytripTimeModal-container .modal .header .close svg {
        position: absolute;
        right: 0;
        cursor: pointer;
        color: #333;
        font-size: 18px; }
      .dailytripTimeModal-container .modal .header .driveTimeHeader {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
        color: #9a9797; }
      .dailytripTimeModal-container .modal .header p {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0.5em; }
    .dailytripTimeModal-container .modal .body {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 250px);
      overflow: visible; }
      .dailytripTimeModal-container .modal .body .labelContainer {
        height: 40px;
        font-size: 18px;
        display: flex; }
        .dailytripTimeModal-container .modal .body .labelContainer .timelabel {
          flex-direction: column;
          flex: 0.22; }
        .dailytripTimeModal-container .modal .body .labelContainer .startLabel {
          flex-direction: column;
          text-align: center;
          padding: 20px;
          flex: 0.22; }
        .dailytripTimeModal-container .modal .body .labelContainer .endLabel {
          flex-direction: column;
          text-align: center;
          padding: 20px;
          flex: 0.22; }
        .dailytripTimeModal-container .modal .body .labelContainer .calTimelabel {
          flex-direction: column;
          text-align: center;
          flex: 0.28;
          padding: 20px; }
        .dailytripTimeModal-container .modal .body .labelContainer .mileageLabel {
          flex-direction: column;
          text-align: center;
          flex: 0.25;
          padding: 20px; }
      .dailytripTimeModal-container .modal .body .upperContainer {
        height: 100px;
        border-bottom: 1px solid #ccc;
        display: flex;
        font-size: 18px;
        margin: 0px 20px; }
        .dailytripTimeModal-container .modal .body .upperContainer .timelabel {
          flex-direction: column;
          padding-top: 50px;
          flex: 0.5; }
        .dailytripTimeModal-container .modal .body .upperContainer .start {
          flex-direction: column;
          margin: 20px 0px 0px 0px;
          text-align: center;
          padding: 20px;
          border: 1px solid #ccc;
          border-bottom: none;
          border-right: none; }
          .dailytripTimeModal-container .modal .body .upperContainer .start .time-picker {
            padding: 0.5rem 0em;
            width: 8rem; }
            .dailytripTimeModal-container .modal .body .upperContainer .start .time-picker svg {
              padding-left: 5px; }
        .dailytripTimeModal-container .modal .body .upperContainer .start.disabled {
          pointer-events: none;
          opacity: 0.5;
          background: #CCC; }
        .dailytripTimeModal-container .modal .body .upperContainer .end {
          flex-direction: column;
          margin: 20px 0px 0px 0px;
          text-align: center;
          padding: 20px;
          border: 1px solid #ccc;
          border-bottom: none; }
          .dailytripTimeModal-container .modal .body .upperContainer .end .time-picker {
            padding: 0.5rem 0em;
            width: 8rem; }
            .dailytripTimeModal-container .modal .body .upperContainer .end .time-picker svg {
              padding-left: 5px; }
        .dailytripTimeModal-container .modal .body .upperContainer .end.disabled {
          pointer-events: none;
          opacity: 0.5;
          background: #CCC; }
        .dailytripTimeModal-container .modal .body .upperContainer .time {
          flex-direction: column;
          margin: 20px;
          margin: 20px 0px 0px 10px;
          text-align: center;
          padding: 20px;
          border: 1px solid #ccc;
          flex: 0.7;
          border-bottom: none; }
        .dailytripTimeModal-container .modal .body .upperContainer .mileage {
          flex-direction: column;
          margin: 20px;
          margin: 20px 0px 0px 10px;
          text-align: center;
          padding: 20px;
          border: 1px solid #ccc;
          flex: 0.5;
          border-bottom: none; }
          .dailytripTimeModal-container .modal .body .upperContainer .mileage .workMiles {
            width: 6rem; }
          .dailytripTimeModal-container .modal .body .upperContainer .mileage .workMiles.disabled {
            pointer-events: none;
            opacity: 0.5;
            background: #CCC; }
          .dailytripTimeModal-container .modal .body .upperContainer .mileage .milesUnits {
            position: relative;
            top: -42px;
            font-size: 18px;
            color: #333333;
            right: -30px; }
        .dailytripTimeModal-container .modal .body .upperContainer .mileage.disabled {
          pointer-events: none;
          opacity: 0.5;
          background: #CCC; }
      .dailytripTimeModal-container .modal .body .lowerContainer {
        height: 100px;
        display: flex;
        font-size: 18px;
        margin: 0px 20px; }
        .dailytripTimeModal-container .modal .body .lowerContainer .timelabel {
          flex-direction: column;
          padding-top: 25px;
          flex: 0.5; }
        .dailytripTimeModal-container .modal .body .lowerContainer .start {
          flex-direction: column;
          margin: 0px 0px 20px 0px;
          text-align: center;
          padding: 20px;
          border: 1px solid #ccc;
          border-top: none;
          border-right: none; }
          .dailytripTimeModal-container .modal .body .lowerContainer .start .time-picker {
            padding: 0.5rem 0em;
            width: 8rem; }
            .dailytripTimeModal-container .modal .body .lowerContainer .start .time-picker svg {
              padding-left: 5px; }
        .dailytripTimeModal-container .modal .body .lowerContainer .start.disabled {
          pointer-events: none;
          opacity: 0.5;
          background: #CCC; }
        .dailytripTimeModal-container .modal .body .lowerContainer .end {
          flex-direction: column;
          margin: 0px 0px 20px 0px;
          text-align: center;
          padding: 20px;
          border: 1px solid #ccc;
          border-top: none; }
          .dailytripTimeModal-container .modal .body .lowerContainer .end .time-picker {
            padding: 0.5rem 0em;
            width: 8rem; }
            .dailytripTimeModal-container .modal .body .lowerContainer .end .time-picker svg {
              padding-left: 5px; }
        .dailytripTimeModal-container .modal .body .lowerContainer .end.disabled {
          pointer-events: none;
          opacity: 0.5;
          background: #CCC; }
        .dailytripTimeModal-container .modal .body .lowerContainer .time {
          flex-direction: column;
          margin: 20px;
          margin: 0px 0px 20px 10px;
          text-align: center;
          padding: 20px;
          border: 1px solid #ccc;
          border-top: none;
          flex: 0.7;
          border-top: none; }
        .dailytripTimeModal-container .modal .body .lowerContainer .mileage {
          flex-direction: column;
          margin: 20px;
          margin: 0px 0px 20px 10px;
          text-align: center;
          padding: 20px;
          border: 1px solid #ccc;
          border-top: none;
          flex: 0.5;
          border-top: none; }
          .dailytripTimeModal-container .modal .body .lowerContainer .mileage .homeMiles {
            width: 6rem; }
          .dailytripTimeModal-container .modal .body .lowerContainer .mileage .homeMiles.disabled {
            pointer-events: none;
            opacity: 0.5;
            background: #CCC; }
          .dailytripTimeModal-container .modal .body .lowerContainer .mileage .milesUnits {
            position: relative;
            top: -42px;
            font-size: 18px;
            color: #333333;
            right: -30px; }
        .dailytripTimeModal-container .modal .body .lowerContainer .mileage.disabled {
          pointer-events: none;
          opacity: 0.5;
          background: #CCC; }
      .dailytripTimeModal-container .modal .body .footer p {
        font-size: 16px;
        font-weight: 600; }
      .dailytripTimeModal-container .modal .body .footer .tools {
        margin: 0em 0em 0em 0em; }
        .dailytripTimeModal-container .modal .body .footer .tools button {
          flex: 0.4;
          padding: 0.2rem 1rem; }
      .dailytripTimeModal-container .modal .body .footer.warning {
        background-color: #f6db00;
        padding: 15px 30px;
        border-radius: 10px; }
    .dailytripTimeModal-container .modal .footer p {
      font-size: 16px;
      font-weight: 600; }
    .dailytripTimeModal-container .modal .footer .tools {
      margin: 0em 0em 0em 0em; }
      .dailytripTimeModal-container .modal .footer .tools button {
        flex: 0.4;
        padding: 0.2rem 1rem; }
    .dailytripTimeModal-container .modal .footer.warning {
      background-color: #f6db00;
      padding: 15px 30px;
      border-radius: 10px; }

@media (min-width: 768px) {
  .dailytripTimeModal-container .modal {
    padding: 25px 25px 25px 25px;
    margin-top: 80px;
    max-height: calc(100vh - 134px);
    display: inline-table;
    color: #333333; }
    .dailytripTimeModal-container .modal .body {
      max-height: calc(100vh - 250px);
      overflow: visible; } }

@media (max-width: 512px) {
  .dailytripTimeModal-container .modal {
    padding: 25px 25px 25px 25px;
    max-height: calc(100vh - 134px);
    color: #333333; }
    .dailytripTimeModal-container .modal .header {
      width: 100%;
      position: relative;
      border-bottom: 1px solid #ccc; }
      .dailytripTimeModal-container .modal .header .close svg {
        position: absolute;
        right: 0;
        cursor: pointer;
        color: #333;
        font-size: 18px; }
      .dailytripTimeModal-container .modal .header .driveTimeHeader {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px;
        color: #9a9797;
        padding-top: 15px; }
      .dailytripTimeModal-container .modal .header p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0.5em; }
    .dailytripTimeModal-container .modal .body {
      max-height: calc(100vh - 150px);
      overflow: visible;
      overflow-y: scroll; }
      .dailytripTimeModal-container .modal .body .labelContainer {
        font-size: 16px; }
        .dailytripTimeModal-container .modal .body .labelContainer .timelabel {
          flex: 0.5 1; }
        .dailytripTimeModal-container .modal .body .labelContainer .startLabel {
          flex: 0.4 1; }
        .dailytripTimeModal-container .modal .body .labelContainer .endLabel {
          flex: 0.4 1; }
        .dailytripTimeModal-container .modal .body .labelContainer .calTimelabel {
          flex: 0.3 1; }
        .dailytripTimeModal-container .modal .body .labelContainer .mileageLabel {
          display: none; }
      .dailytripTimeModal-container .modal .body .upperContainer {
        font-size: 16px;
        margin: 0px 5px;
        height: 80px;
        border: none; }
        .dailytripTimeModal-container .modal .body .upperContainer .timelabel {
          flex: 1 1;
          padding-top: 15px; }
        .dailytripTimeModal-container .modal .body .upperContainer .start {
          padding: 0px;
          border: none;
          margin: 15px 5px; }
          .dailytripTimeModal-container .modal .body .upperContainer .start .time-picker {
            width: 5em; }
            .dailytripTimeModal-container .modal .body .upperContainer .start .time-picker svg {
              display: none; }
        .dailytripTimeModal-container .modal .body .upperContainer .end {
          padding: 0px;
          border: none;
          margin: 15px 5px; }
          .dailytripTimeModal-container .modal .body .upperContainer .end .time-picker {
            width: 5em; }
            .dailytripTimeModal-container .modal .body .upperContainer .end .time-picker svg {
              display: none; }
        .dailytripTimeModal-container .modal .body .upperContainer .time {
          flex: 1;
          padding: 0px;
          border: none; }
      .dailytripTimeModal-container .modal .body .upperContainer:disabled {
        pointer-events: none;
        opacity: 0.5;
        background: #CCC; }
      .dailytripTimeModal-container .modal .body .lowerContainer {
        font-size: 16px;
        margin: 0px 5px;
        height: 80px; }
        .dailytripTimeModal-container .modal .body .lowerContainer .timelabel {
          flex: 1 1;
          padding-top: 15px; }
        .dailytripTimeModal-container .modal .body .lowerContainer .start {
          padding: 0px;
          border: none;
          margin: 15px 5px; }
          .dailytripTimeModal-container .modal .body .lowerContainer .start .time-picker {
            width: 5em; }
            .dailytripTimeModal-container .modal .body .lowerContainer .start .time-picker svg {
              display: none; }
        .dailytripTimeModal-container .modal .body .lowerContainer .end {
          padding: 0px;
          border: none;
          margin: 15px 5px; }
          .dailytripTimeModal-container .modal .body .lowerContainer .end .time-picker {
            width: 5em; }
            .dailytripTimeModal-container .modal .body .lowerContainer .end .time-picker svg {
              display: none; }
        .dailytripTimeModal-container .modal .body .lowerContainer .time {
          flex: 1;
          padding: 0px;
          border: none;
          margin-top: 15px; }
      .dailytripTimeModal-container .modal .body .lowerContainer:disabled {
        pointer-events: none;
        opacity: 0.5;
        background: #CCC; }
      .dailytripTimeModal-container .modal .body .mobileView {
        border-top: 1px solid #ccc;
        margin-top: 15px; }
        .dailytripTimeModal-container .modal .body .mobileView .mobileLableContainer {
          height: 40px;
          font-size: 16px;
          display: flex; }
          .dailytripTimeModal-container .modal .body .mobileView .mobileLableContainer .timelabel {
            flex-direction: column;
            flex: 0.18 1; }
          .dailytripTimeModal-container .modal .body .mobileView .mobileLableContainer .mileageLabel {
            flex-direction: column;
            text-align: center;
            flex: 0.25;
            padding: 20px 10px 0px; }
        .dailytripTimeModal-container .modal .body .mobileView .mobileUpperContainer {
          height: 80px;
          display: flex;
          font-size: 16px; }
          .dailytripTimeModal-container .modal .body .mobileView .mobileUpperContainer .timelabel {
            flex-direction: column;
            flex: 0.3 1;
            padding-top: 15px; }
          .dailytripTimeModal-container .modal .body .mobileView .mobileUpperContainer .mobileMileage {
            flex-direction: column;
            padding: 0px;
            border: none;
            margin: 15px 5px; }
            .dailytripTimeModal-container .modal .body .mobileView .mobileUpperContainer .mobileMileage .workMiles {
              width: 5rem; }
            .dailytripTimeModal-container .modal .body .mobileView .mobileUpperContainer .mobileMileage .workMiles.disabled {
              pointer-events: none;
              opacity: 0.5;
              background: #CCC; }
            .dailytripTimeModal-container .modal .body .mobileView .mobileUpperContainer .mobileMileage .milesUnits {
              position: relative;
              top: 0px;
              font-size: 16px;
              color: #333333;
              right: 25px; }
          .dailytripTimeModal-container .modal .body .mobileView .mobileUpperContainer .mobileMileage.disabled {
            pointer-events: none;
            opacity: 0.5;
            background: #CCC; }
        .dailytripTimeModal-container .modal .body .mobileView .mobileLowerContainer {
          height: 80px;
          display: flex;
          font-size: 16px; }
          .dailytripTimeModal-container .modal .body .mobileView .mobileLowerContainer .timelabel {
            flex-direction: column;
            flex: 0.3 1;
            padding-top: 15px; }
          .dailytripTimeModal-container .modal .body .mobileView .mobileLowerContainer .mobileMileage {
            flex-direction: column;
            padding: 0px;
            border: none;
            margin: 15px 5px; }
            .dailytripTimeModal-container .modal .body .mobileView .mobileLowerContainer .mobileMileage .homeMiles {
              width: 5rem; }
            .dailytripTimeModal-container .modal .body .mobileView .mobileLowerContainer .mobileMileage .homeMiles.disabled {
              pointer-events: none;
              opacity: 0.5;
              background: #CCC; }
            .dailytripTimeModal-container .modal .body .mobileView .mobileLowerContainer .mobileMileage .milesUnits {
              position: relative;
              top: 0px;
              font-size: 16px;
              color: #333333;
              right: 25px; }
          .dailytripTimeModal-container .modal .body .mobileView .mobileLowerContainer .mobileMileage.disabled {
            pointer-events: none;
            opacity: 0.5;
            background: #CCC; }
      .dailytripTimeModal-container .modal .body .footer p {
        font-size: 14px; }
      .dailytripTimeModal-container .modal .body .footer .tools {
        flex-direction: row; }
    .dailytripTimeModal-container .modal .footer p {
      font-size: 14px; }
    .dailytripTimeModal-container .modal .footer .tools {
      flex-direction: row; } }

/*	COLORS
–––––––––––––––––––––––––––*/
.matchCommuteModal-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  position: fixed;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
  .matchCommuteModal-container .modal {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    padding: 10px;
    max-height: calc(100vh - 134px);
    height: max-content;
    max-width: 600px;
    display: inline-table;
    color: #333333;
    margin: auto 0;
    border-radius: 15px; }
    .matchCommuteModal-container .modal .header {
      width: 100%;
      position: relative; }
      .matchCommuteModal-container .modal .header .close svg {
        position: absolute;
        right: 20px;
        top: 5px;
        cursor: pointer;
        color: #333;
        font-size: 18px; }
    .matchCommuteModal-container .modal .body {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 250px);
      overflow: visible; }
      .matchCommuteModal-container .modal .body .content {
        margin: 30px 20px;
        font-size: 16px; }
    .matchCommuteModal-container .modal .footer {
      text-align: center;
      margin: 30px 0px 25px; }
      .matchCommuteModal-container .modal .footer .button {
        min-width: 6rem;
        padding: 0.25rem 0.8rem; }

@media (min-width: 768px) {
  .covidModal-container .modal {
    margin: auto 0; }
    .covidModal-container .modal .body .content {
      margin: 30px 60px; } }

@media (max-width: 512px) {
  .covidModal-container .modal {
    padding: 25px 25px 25px 25px;
    max-height: calc(100vh - 134px);
    color: #333333;
    margin: 30px 20px 0px; }
    .covidModal-container .modal .header {
      width: 100%;
      position: relative; }
    .covidModal-container .modal .body {
      max-height: calc(100vh - 250px);
      overflow: visible; }
      .covidModal-container .modal .body .content {
        margin: 25px 20px;
        font-size: 16px; }
    .covidModal-container .modal .footer {
      text-align: center;
      margin: 30px 0px 20px; } }

.terms-overlay {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.5);
  z-index: 1000; }
  .terms-overlay .terms {
    background: #FFFFFF;
    padding: 0.5rem;
    width: 100vw;
    max-width: 1024px;
    max-height: 100vh;
    overflow: auto;
    opacity: 0;
    animation: from-bottom 0.5s ease-out 1s forwards; }
    .terms-overlay .terms .title {
      margin-top: 3rem;
      text-align: left;
      font-size: 1.25rem;
      font-weight: 600;
      text-transform: uppercase; }
    .terms-overlay .terms .desc {
      padding: 1.5rem 0; }
    .terms-overlay .terms .frame {
      position: relative;
      border: 1px solid #CCCCCC;
      margin: 0 -0.5rem; }
    .terms-overlay .terms iframe {
      border: none;
      width: 100vw;
      height: 50vh;
      overflow: scroll;
      pointer-events: none; }
    .terms-overlay .terms button.primary {
      width: 80%;
      margin-left: 10%;
      margin-bottom: 3rem; }
      .terms-overlay .terms button.primary:disabled {
        background-color: #CCCCCC;
        color: #FFFFFF;
        border-color: #CCCCCC;
        pointer-events: none; }
    .terms-overlay .terms button.hidden:focus {
      outline: none;
      box-shadow: none; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .terms-overlay .terms {
    opacity: 1 !important;
    animation: none !important; } }

@media (min-width: 768px) {
  .terms-overlay .terms {
    padding: 2rem;
    max-width: 1024px;
    text-align: center; }
    .terms-overlay .terms .title {
      margin-top: 0; }
    .terms-overlay .terms .desc {
      text-align: left;
      padding-right: 30%; }
    .terms-overlay .terms iframe {
      height: 30vh;
      width: 100%;
      pointer-events: all; }
    .terms-overlay .terms button.primary {
      float: right;
      margin-left: auto;
      width: auto;
      margin-bottom: 0; } }

/*	COLORS
–––––––––––––––––––––––––––*/
#confirmation-popup {
  z-index: 1000;
  position: relative; }

.popup-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  perspective: 800px; }
  .popup-container.form-popup-container .popup .title {
    margin: 0 0 1rem;
    padding: 0 0 .25rem;
    border-bottom: solid 1px #b3b3b3;
    color: gray; }
  .popup-container.form-popup-container .popup .body .input {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-bottom: .5rem;
    color: gray; }
    .popup-container.form-popup-container .popup .body .input span {
      display: inline-block;
      margin-right: .5rem; }
    .popup-container.form-popup-container .popup .body .input input {
      width: 100%;
      margin: 0; }
  .popup-container.form-popup-container .popup .body .emails {
    border: solid 1px #b3b3b3;
    padding: .25rem; }
  .popup-container.form-popup-container .popup .body h4 {
    font-weight: 400;
    margin-bottom: .25rem;
    margin-top: 1rem;
    color: gray; }
  .popup-container.form-popup-container .popup .body textarea {
    width: 100%;
    min-height: 8rem;
    padding: .5rem;
    font-size: 0.875rem;
    color: gray; }
  .popup-container.out {
    animation: fade-out 0.25s ease-out;
    animation-fill-mode: forwards; }
    .popup-container.out button {
      pointer-events: none; }
  .popup-container .overlay {
    background: transparent; }
  .popup-container .popup {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 0.75rem;
    margin: 0.5rem;
    max-width: 35rem;
    animation: flip-in 0.2s ease-out;
    animation-fill-mode: forwards; }
    .popup-container .popup .title {
      font-size: 1.25rem;
      color: #333333; }
    .popup-container .popup .message {
      font-size: 0.875rem;
      color: gray; }
    .popup-container .popup .response {
      margin-bottom: 0;
      -webkit-flex-wrap: wrap-reverse;
      -moz-flex-wrap: wrap-reverse;
      -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
      -webkit-justify-content: space-around;
      -moz-justify-content: space-around;
      -ms-justify-content: space-around;
      justify-content: space-around;
      -ms-flex-pack: space-around; }
      .popup-container .popup .response button {
        padding: 0.25rem 2rem;
        min-width: 15rem;
        margin-bottom: 1rem;
        font-size: 1rem;
        margin-left: 0; }
        .popup-container .popup .response button.validation {
          margin-left: 1.5rem; }
        .popup-container .popup .response button.cancel {
          border: none;
          color: #049b5c; }
  .popup-container .docusign {
    height: 100vh;
    width: 100vw;
    transition: all 0.5s ease-out;
    transform: translateY(100vh); }
    .popup-container .docusign.ready {
      transform: translateY(0); }
    .popup-container .docusign.iosDocusign {
      padding-bottom: 60px;
      z-index: 10; }
    .popup-container .docusign .title {
      display: none;
      font-size: 1.5rem;
      height: 4rem;
      padding: 1rem; }
    .popup-container .docusign iframe {
      height: 100%;
      width: 100%;
      border: none; }

@media (min-width: 768px) {
  .form-popup-container .popup {
    min-width: 40rem;
    max-width: 40rem; }
  .popup-container .docusign {
    height: 80vh;
    width: 80vw; }
  .popup-container .popup {
    padding: 1.25rem; } }

@keyframes flip-in {
  0% {
    transform: rotateY(90deg); }
  100% {
    transform: rotateY(0deg); } }

@keyframes fade-out {
  80%, 100% {
    opacity: 0; }
  0% {
    opacity: 1; } }

@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  p:first-line,
  div:first-line,
  blockquote:first-line,
  li:first-line {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: none; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
   * HIDE ELEMENTS
   */
  nav, .button, .stepper, .review section, .recurring-payment-link, header.global h1 img, footer.global, .tnav, .push {
    display: none !important; }
  header.global {
    border-bottom: solid 2px black; }
  .print-only {
    display: inline-block; } }

/*	COLORS
–––––––––––––––––––––––––––*/
@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

.vanpool-modal-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  position: fixed;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
  .vanpool-modal-container .modal {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    padding: 10px;
    max-height: calc(100vh - 134px);
    height: max-content;
    max-width: 800px;
    display: inline-table;
    border-radius: 8px;
    border: 1px solid #D5D5D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
    .vanpool-modal-container .modal .header {
      width: 100%;
      height: 24px;
      margin-bottom: 15px;
      font-size: 1.25rem;
      position: relative; }
      .vanpool-modal-container .modal .header .close svg {
        position: absolute;
        right: 0;
        cursor: pointer;
        color: #00A664;
        font-size: 24px; }
    .vanpool-modal-container .modal .body {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 250px); }
      .vanpool-modal-container .modal .body .vanpool-popup-container .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: center; }
      .vanpool-modal-container .modal .body .vanpool-popup-container .title {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        text-align: center;
        letter-spacing: -0.25px;
        color: #838383;
        margin-top: 15px; }
      .vanpool-modal-container .modal .body .vanpool-popup-container .container {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        align-items: center;
        justify-content: center; }
        .vanpool-modal-container .modal .body .vanpool-popup-container .container .section {
          display: flex;
          flex-direction: row;
          width: 460px; }
          .vanpool-modal-container .modal .body .vanpool-popup-container .container .section .icon {
            height: 60px; }
            .vanpool-modal-container .modal .body .vanpool-popup-container .container .section .icon img {
              width: 35px;
              height: 32px;
              margin-top: 5px; }
          .vanpool-modal-container .modal .body .vanpool-popup-container .container .section .details {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            margin-left: 20px; }
        .vanpool-modal-container .modal .body .vanpool-popup-container .container .section:last-child {
          margin-top: 10px; }
      .vanpool-modal-container .modal .body .vanpool-popup-container .button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 35px; }
        .vanpool-modal-container .modal .body .vanpool-popup-container .button .continue {
          background: #00A664;
          border-radius: 5px;
          width: 170px;
          height: 40px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          margin-right: 30px; }
    .vanpool-modal-container .modal .footer .privacy {
      color: #049b5c;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer; }
    .vanpool-modal-container .modal .footer .privacy:hover {
      text-decoration: underline; }

@media (min-width: 768px) {
  .vanpool-modal-container .modal {
    padding: 25px 25px 25px 50px;
    margin: 80px 50px;
    max-height: calc(100vh - 134px); }
    .vanpool-modal-container .modal .body {
      max-height: calc(100vh - 250px); }
      .vanpool-modal-container .modal .body .vanpool-popup-container .container .traditional {
        width: 475px; } }

@media (max-width: 512px) {
  .vanpool-modal-container .modal {
    padding: 25px 25px 25px 25px;
    max-height: calc(100vh - 134px);
    margin: 30px 20px 0px; }
    .vanpool-modal-container .modal .header {
      width: 100%;
      position: relative; }
    .vanpool-modal-container .modal .body {
      max-height: calc(100vh - 250px);
      overflow: visible; }
      .vanpool-modal-container .modal .body .vanpool-popup-container .heading {
        font-size: 24px;
        line-height: 30px; }
      .vanpool-modal-container .modal .body .vanpool-popup-container .title {
        font-size: 16px;
        line-height: 22px; }
      .vanpool-modal-container .modal .body .vanpool-popup-container .container .section {
        width: 100%; }
        .vanpool-modal-container .modal .body .vanpool-popup-container .container .section .icon {
          height: 60px; }
          .vanpool-modal-container .modal .body .vanpool-popup-container .container .section .icon img {
            width: 32px;
            height: 30px; }
        .vanpool-modal-container .modal .body .vanpool-popup-container .container .section .details {
          font-size: 16px;
          line-height: 22px; }
      .vanpool-modal-container .modal .body .vanpool-popup-container .container .section:last-child {
        margin-top: 15px; }
      .vanpool-modal-container .modal .body .vanpool-popup-container .button .continue {
        width: 150px;
        font-size: 14px;
        line-height: 22px; }
    .vanpool-modal-container .modal .footer {
      text-align: center;
      margin: 30px 0px 20px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

#change-commute-days-popup .sp-header {
  display: flex;
  justify-content: space-between; }
  #change-commute-days-popup .sp-header .header {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    padding: 0px !important; }

#change-commute-days-popup .card-operations-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 70vh;
  height: fit-content;
  border: none !important;
  padding: 0px !important;
  margin: 40px; }
  #change-commute-days-popup .card-operations-container .popup {
    display: none;
    position: fixed;
    background: #FFF;
    z-index: 20;
    left: 0;
    right: 0;
    top: 20%;
    margin-left: auto;
    margin-right: auto;
    width: 375px;
    border-radius: 5px 5px 0px 0px; }
    #change-commute-days-popup .card-operations-container .popup:after {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -2;
      border-radius: 5px 5px 0px 0px; }
    #change-commute-days-popup .card-operations-container .popup:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #FFF;
      z-index: -1;
      border-radius: 5px 5px 0px 0px; }
    #change-commute-days-popup .card-operations-container .popup.show {
      display: block; }
    #change-commute-days-popup .card-operations-container .popup.hide {
      display: none; }
  #change-commute-days-popup .card-operations-container .pre-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #4A4A4A;
    margin-top: 15px; }
  #change-commute-days-popup .card-operations-container .commuteDays {
    margin-top: 20px; }
    #change-commute-days-popup .card-operations-container .commuteDays .days {
      display: flex; }
      #change-commute-days-popup .card-operations-container .commuteDays .days .day {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center; }
        #change-commute-days-popup .card-operations-container .commuteDays .days .day .full-badge {
          text-align: center;
          margin: 0 15px 0 0;
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.25px;
          font-style: normal;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          color: #4A90E2; }
      #change-commute-days-popup .card-operations-container .commuteDays .days .day-btn {
        color: #00A664;
        background-color: #FFFFFF;
        height: 40px;
        width: 40px;
        font-size: 14px;
        text-align: center;
        border-radius: 25px;
        border: solid 2px #00A664;
        cursor: pointer;
        margin: 5px 15px 0 0;
        padding: 0;
        text-transform: uppercase;
        padding-top: 5px;
        line-height: 17px;
        font-family: 'DINNextLTPro-Regular';
        font-weight: 700; }
      #change-commute-days-popup .card-operations-container .commuteDays .days .day-btn:disabled {
        color: #049b5c;
        border: none;
        background: #FFFFFF;
        border: solid 1px #05cd7a;
        cursor: default; }
      #change-commute-days-popup .card-operations-container .commuteDays .days .day-btn.unavailable {
        color: #D5D5D5;
        border: none;
        background: #FFFFFF;
        border: solid 2px #D5D5D5;
        cursor: default;
        text-decoration: line-through; }
      #change-commute-days-popup .card-operations-container .commuteDays .days .day-btn.active {
        color: #0F8952;
        border: solid 2px #0F8952;
        background: #D4EDE1; }
    #change-commute-days-popup .card-operations-container .commuteDays .error {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #F96302;
      margin-top: 12px; }
  #change-commute-days-popup .card-operations-container .date-picker {
    margin-top: 40px; }
    #change-commute-days-popup .card-operations-container .date-picker .date-picker-custom {
      width: fit-content; }
      #change-commute-days-popup .card-operations-container .date-picker .date-picker-custom .picker-container {
        margin-top: 10px !important; }
  #change-commute-days-popup .card-operations-container .save-button {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #FFFFFF;
    border-radius: 5px;
    height: 40px;
    width: 150px;
    margin-top: 50px; }
    #change-commute-days-popup .card-operations-container .save-button:disabled {
      background-color: #838383;
      cursor: not-allowed;
      border: none; }
    #change-commute-days-popup .card-operations-container .save-button:enabled {
      background-color: #00A664; }

#change-commute-days-popup .confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px; }
  #change-commute-days-popup .confirmation .confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
    #change-commute-days-popup .confirmation .confirmation-container .success-icon img {
      height: 65px; }
    #change-commute-days-popup .confirmation .confirmation-container .text {
      font-family: "DINNextLTPro-Regular";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin-top: 24px; }
      #change-commute-days-popup .confirmation .confirmation-container .text .bolded {
        font-weight: 700 !important; }
    #change-commute-days-popup .confirmation .confirmation-container .commute-days {
      display: flex;
      gap: 5px;
      margin-top: 24px; }
      #change-commute-days-popup .confirmation .confirmation-container .commute-days .block {
        height: 26px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #D5D5D5;
        border-radius: 4px;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.25px;
        color: #4A4A4A;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
    #change-commute-days-popup .confirmation .confirmation-container .done-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      background-color: #FFF !important;
      border: 2px solid #00A664;
      border-radius: 5px;
      color: #00A664;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      width: 170px;
      height: 40px;
      font-size: 14px;
      margin-top: 24px; }
      #change-commute-days-popup .confirmation .confirmation-container .done-button:disabled {
        border: 2px solid #838383 !important;
        color: #838383;
        cursor: not-allowed;
        border: none; }

#change-commute-days-popup .max-days-error-container {
  border-radius: 8px;
  border: 1px solid #D5D5D5;
  padding: 24px;
  width: fit-content;
  margin-top: 24px; }
  #change-commute-days-popup .max-days-error-container .max-days-error {
    display: flex;
    align-items: center;
    gap: 15px; }
    #change-commute-days-popup .max-days-error-container .max-days-error .text {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      #change-commute-days-popup .max-days-error-container .max-days-error .text .top-text {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      #change-commute-days-popup .max-days-error-container .max-days-error .text .callable {
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #00A664; }

@media only screen and (max-width: 500px) {
  #change-commute-days-popup .card-operations-container {
    margin: 20px !important; }
    #change-commute-days-popup .card-operations-container .popup {
      width: 80%;
      overflow-x: scroll; }
      #change-commute-days-popup .card-operations-container .popup:after {
        position: fixed;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -2;
        border-radius: 5px 5px 0px 0px; }
      #change-commute-days-popup .card-operations-container .popup:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FFF;
        z-index: -1;
        border-radius: 5px 5px 0px 0px; }
      #change-commute-days-popup .card-operations-container .popup.show {
        display: block; }
      #change-commute-days-popup .card-operations-container .popup.hide {
        display: none; }
    #change-commute-days-popup .card-operations-container.confirmation {
      height: fit-content; }
      #change-commute-days-popup .card-operations-container.confirmation .confirmation-container {
        height: 100%;
        overflow-y: scroll; }
        #change-commute-days-popup .card-operations-container.confirmation .confirmation-container .success-icon img {
          height: 65px; }
        #change-commute-days-popup .card-operations-container.confirmation .confirmation-container .text {
          font-family: "DINNextLTPro-Regular";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          margin-top: 24px; }
          #change-commute-days-popup .card-operations-container.confirmation .confirmation-container .text .bolded {
            font-weight: 700 !important; }
        #change-commute-days-popup .card-operations-container.confirmation .confirmation-container .commute-days {
          display: flex;
          gap: 5px;
          margin-top: 24px; }
          #change-commute-days-popup .card-operations-container.confirmation .confirmation-container .commute-days .block {
            height: 26px;
            width: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #D5D5D5;
            border-radius: 4px;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased; }
        #change-commute-days-popup .card-operations-container.confirmation .confirmation-container .done-button {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          background-color: #FFF !important;
          border: 2px solid #00A664;
          border-radius: 5px;
          color: #00A664;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          width: 170px;
          height: 40px;
          font-size: 14px;
          margin-top: 24px; }
          #change-commute-days-popup .card-operations-container.confirmation .confirmation-container .done-button:disabled {
            border: 2px solid #838383 !important;
            color: #838383;
            cursor: not-allowed;
            border: none; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

#price-disclaimer-popup {
  width: 600px;
  top: 22%; }
  #price-disclaimer-popup .sp-header {
    justify-content: space-between;
    display: flex; }
    #price-disclaimer-popup .sp-header .header {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      letter-spacing: -0.5px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      letter-spacing: -0.25px;
      padding: 0px !important;
      background: none;
      border: none;
      text-align: left; }
      #price-disclaimer-popup .sp-header .header.traditional {
        width: 90%; }
    #price-disclaimer-popup .sp-header .close {
      width: 24px;
      height: 24px; }
      #price-disclaimer-popup .sp-header .close.traditional {
        margin-top: -20px; }
  #price-disclaimer-popup .price-disclaimer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 70vh;
    height: fit-content;
    border: none !important;
    padding: 0px !important;
    margin: 40px; }
    #price-disclaimer-popup .price-disclaimer-container .popup {
      display: none;
      position: fixed;
      background: #FFF;
      z-index: 20;
      left: 0;
      right: 0;
      top: 20%;
      margin-left: auto;
      margin-right: auto;
      width: 375px;
      border-radius: 5px 5px 0px 0px; }
      #price-disclaimer-popup .price-disclaimer-container .popup:after {
        position: fixed;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -2;
        border-radius: 5px 5px 0px 0px; }
      #price-disclaimer-popup .price-disclaimer-container .popup:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FFF;
        z-index: -1;
        border-radius: 5px 5px 0px 0px; }
      #price-disclaimer-popup .price-disclaimer-container .popup.show {
        display: block; }
      #price-disclaimer-popup .price-disclaimer-container .popup.hide {
        display: none; }
    #price-disclaimer-popup .price-disclaimer-container .price-disclaimer-popup-container {
      margin-top: 24px; }
      #price-disclaimer-popup .price-disclaimer-container .price-disclaimer-popup-container .content {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
    #price-disclaimer-popup .price-disclaimer-container .button-container {
      display: flex;
      justify-content: center;
      margin: 0; }
      #price-disclaimer-popup .price-disclaimer-container .button-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        height: 40px;
        width: 160px;
        margin: 50px 0px 40px;
        width: 190px;
        margin: 50px 0px 0px; }
        #price-disclaimer-popup .price-disclaimer-container .button-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        #price-disclaimer-popup .price-disclaimer-container .button-container .continue-button:enabled {
          background-color: #00A664; }

@media only screen and (max-width: 500px) {
  #price-disclaimer-popup .sp-header .header.traditional {
    width: 100%;
    margin-top: 10px; }
  #price-disclaimer-popup .sp-header .close.traditional {
    margin-top: -25px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

#preferred-vanpool-popup {
  width: 800px;
  top: 22%; }
  #preferred-vanpool-popup .top-header {
    display: flex;
    justify-content: flex-end; }
    #preferred-vanpool-popup .top-header .close {
      width: 20px;
      height: 20px; }
  #preferred-vanpool-popup .sp-header {
    justify-content: center;
    display: flex; }
    #preferred-vanpool-popup .sp-header .header .van-image {
      display: flex;
      justify-content: center; }
      #preferred-vanpool-popup .sp-header .header .van-image img {
        height: 55px;
        width: 90px; }
    #preferred-vanpool-popup .sp-header .header .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #4A4A4A;
      margin: 24px 0px; }
  #preferred-vanpool-popup .preferred-vanpool-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 70vh;
    height: fit-content;
    border: none !important;
    padding: 0px !important;
    margin: 20px 30px 40px; }
    #preferred-vanpool-popup .preferred-vanpool-container .popup {
      display: none;
      position: fixed;
      background: #FFF;
      z-index: 20;
      left: 0;
      right: 0;
      top: 20%;
      margin-left: auto;
      margin-right: auto;
      width: 375px;
      border-radius: 5px 5px 0px 0px; }
      #preferred-vanpool-popup .preferred-vanpool-container .popup:after {
        position: fixed;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -2;
        border-radius: 5px 5px 0px 0px; }
      #preferred-vanpool-popup .preferred-vanpool-container .popup:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FFF;
        z-index: -1;
        border-radius: 5px 5px 0px 0px; }
      #preferred-vanpool-popup .preferred-vanpool-container .popup.show {
        display: block; }
      #preferred-vanpool-popup .preferred-vanpool-container .popup.hide {
        display: none; }
    #preferred-vanpool-popup .preferred-vanpool-container .preferred-vanpool-popup-container .content {
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center; }
      #preferred-vanpool-popup .preferred-vanpool-container .preferred-vanpool-popup-container .content .middle-section {
        display: flex;
        justify-content: center;
        margin: 16px 0px; }
        #preferred-vanpool-popup .preferred-vanpool-container .preferred-vanpool-popup-container .content .middle-section .ride-days {
          display: flex;
          flex-direction: row; }
          #preferred-vanpool-popup .preferred-vanpool-container .preferred-vanpool-popup-container .content .middle-section .ride-days .ride-day {
            height: 26px;
            width: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #D5D5D5;
            border-radius: 4px;
            font-family: 'DINNextLTPro-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: -0.25px;
            color: #4A4A4A;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            color: #4A4A4A;
            margin-right: 5px; }
      #preferred-vanpool-popup .preferred-vanpool-container .preferred-vanpool-popup-container .content .last-section span {
        font-weight: 600;
        display: contents; }
      #preferred-vanpool-popup .preferred-vanpool-container .preferred-vanpool-popup-container .content .last-section.spaced {
        margin-top: 25px; }
    #preferred-vanpool-popup .preferred-vanpool-container .button-container {
      display: flex;
      justify-content: center;
      margin: 0; }
      #preferred-vanpool-popup .preferred-vanpool-container .button-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        height: 40px;
        width: 160px;
        margin: 50px 0px 40px;
        width: 190px;
        margin: 24px 0px 0px; }
        #preferred-vanpool-popup .preferred-vanpool-container .button-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        #preferred-vanpool-popup .preferred-vanpool-container .button-container .continue-button:enabled {
          background-color: #00A664; }

@media only screen and (max-width: 500px) {
  #preferred-vanpool-popup .top-header {
    display: flex;
    justify-content: flex-end; }
    #preferred-vanpool-popup .top-header .close {
      width: 20px;
      height: 20px; }
  #preferred-vanpool-popup .preferred-vanpool-container .preferred-vanpool-popup-container .content {
    justify-content: flex-start;
    text-align: left; }
    #preferred-vanpool-popup .preferred-vanpool-container .preferred-vanpool-popup-container .content .middle-section {
      display: flex;
      justify-content: flex-start;
      text-align: left; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

#leave-vanpool-popup {
  width: 800px;
  top: 22%; }
  #leave-vanpool-popup .top-header {
    display: flex;
    justify-content: flex-end; }
    #leave-vanpool-popup .top-header .close {
      width: 20px;
      height: 20px; }
    #leave-vanpool-popup .top-header .closeIcon {
      width: 20px;
      height: 20px; }
  #leave-vanpool-popup .sp-header {
    justify-content: center;
    display: flex; }
    #leave-vanpool-popup .sp-header .header .van-image {
      display: flex;
      justify-content: center; }
      #leave-vanpool-popup .sp-header .header .van-image img {
        height: 32px;
        width: 32px; }
    #leave-vanpool-popup .sp-header .header .heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 500;
      color: #4A4A4A;
      margin: 24px 0px; }
  #leave-vanpool-popup .leave-vanpool-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 70vh;
    height: fit-content;
    border: none !important;
    padding: 0px !important;
    margin: 20px 30px 40px; }
    #leave-vanpool-popup .leave-vanpool-container .popup {
      display: none;
      position: fixed;
      background: #FFF;
      z-index: 20;
      left: 0;
      right: 0;
      top: 20%;
      margin-left: auto;
      margin-right: auto;
      width: 375px;
      border-radius: 5px 5px 0px 0px; }
      #leave-vanpool-popup .leave-vanpool-container .popup:after {
        position: fixed;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -2;
        border-radius: 5px 5px 0px 0px; }
      #leave-vanpool-popup .leave-vanpool-container .popup:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FFF;
        z-index: -1;
        border-radius: 5px 5px 0px 0px; }
      #leave-vanpool-popup .leave-vanpool-container .popup.show {
        display: block; }
      #leave-vanpool-popup .leave-vanpool-container .popup.hide {
        display: none; }
    #leave-vanpool-popup .leave-vanpool-container .leave-vanpool-popup-container .content {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center; }
      #leave-vanpool-popup .leave-vanpool-container .leave-vanpool-popup-container .content.coordinator {
        display: block;
        margin: 0px 60px; }
      #leave-vanpool-popup .leave-vanpool-container .leave-vanpool-popup-container .content.removePartiicpant {
        margin: 20px 60px 0px; }
    #leave-vanpool-popup .leave-vanpool-container .button-container {
      display: flex;
      justify-content: center;
      margin-top: 8px; }
      #leave-vanpool-popup .leave-vanpool-container .button-container .leave-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: #FFF !important;
        border: 2px solid #00A664;
        border-radius: 5px;
        color: #00A664;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        width: 170px;
        margin: 24px;
        height: 40px; }
        #leave-vanpool-popup .leave-vanpool-container .button-container .leave-button:disabled {
          border: 2px solid #838383 !important;
          color: #838383;
          cursor: not-allowed;
          border: none; }
      #leave-vanpool-popup .leave-vanpool-container .button-container .continue-button {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        color: #FFFFFF;
        border-radius: 5px;
        height: 40px;
        width: 160px;
        margin: 50px 0px 40px;
        width: 170px;
        margin: 24px 0px 0px; }
        #leave-vanpool-popup .leave-vanpool-container .button-container .continue-button:disabled {
          background-color: #838383;
          cursor: not-allowed;
          border: none; }
        #leave-vanpool-popup .leave-vanpool-container .button-container .continue-button:enabled {
          background-color: #00A664; }

@media only screen and (max-width: 500px) {
  #leave-vanpool-popup .top-header {
    display: flex;
    justify-content: flex-end; }
    #leave-vanpool-popup .top-header .close {
      width: 20px;
      height: 20px; }
  #leave-vanpool-popup .leave-vanpool-container .leave-vanpool-popup-container .content {
    justify-content: flex-start;
    text-align: left; }
    #leave-vanpool-popup .leave-vanpool-container .leave-vanpool-popup-container .content.coordinator {
      display: block;
      margin: 0px 30px; }
      #leave-vanpool-popup .leave-vanpool-container .leave-vanpool-popup-container .content.coordinator span {
        text-decoration: underline;
        text-decoration-color: #00A664; }
    #leave-vanpool-popup .leave-vanpool-container .leave-vanpool-popup-container .content .middle-section {
      display: flex;
      justify-content: flex-start;
      text-align: left; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.coordinator-popup {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 30%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 90%;
  width: 700px; }
  .coordinator-popup:after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -2; }
  .coordinator-popup:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #FFF;
    z-index: -1;
    border-radius: 8px; }
  .coordinator-popup.show {
    display: block; }
  .coordinator-popup.hide {
    display: none; }

.coordinator-confirmation-popup {
  padding: 40px; }
  .coordinator-confirmation-popup .sp-header {
    display: flex;
    justify-content: center; }
    .coordinator-confirmation-popup .sp-header .header {
      text-align: center; }
      .coordinator-confirmation-popup .sp-header .header .header-image img {
        height: 65px;
        width: 65px; }
      .coordinator-confirmation-popup .sp-header .header .heading {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #4A4A4A;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        font-weight: 500;
        margin-top: 8px; }
  .coordinator-confirmation-popup .coordinator-confirmation-popup-content {
    margin: 8px 40px; }
    .coordinator-confirmation-popup .coordinator-confirmation-popup-content .pre-text {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      font-weight: 400;
      color: #838383; }
      .coordinator-confirmation-popup .coordinator-confirmation-popup-content .pre-text .callable {
        color: #00A664;
        margin-left: 5px; }
        .coordinator-confirmation-popup .coordinator-confirmation-popup-content .pre-text .callable .desktop {
          display: unset !important; }
  .coordinator-confirmation-popup .button-container {
    display: flex;
    justify-content: center; }
    .coordinator-confirmation-popup .button-container .continue {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      background-color: #FFF !important;
      border: 2px solid #00A664;
      border-radius: 5px;
      color: #00A664;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      height: 40px;
      width: 160px; }
      .coordinator-confirmation-popup .button-container .continue:disabled {
        border: 2px solid #838383 !important;
        color: #838383;
        cursor: not-allowed;
        border: none; }

@media only screen and (max-width: 700px) {
  .coordinator-popup {
    top: 15% !important;
    max-height: 80vh; }
  .coordinator-confirmation-popup {
    padding: 10px 10px !important;
    max-height: 80vh; }
    .coordinator-confirmation-popup .charges-header {
      margin: 10px; }
      .coordinator-confirmation-popup .charges-header img {
        height: 20px; }
    .coordinator-confirmation-popup .coordinator-confirmation-popup-content {
      width: 95%;
      text-align: left;
      margin-bottom: 20px;
      padding-bottom: 10px;
      overflow-y: scroll;
      max-height: 70vh;
      margin: 8px 12px; }
      .coordinator-confirmation-popup .coordinator-confirmation-popup-content .pre-text {
        height: 30%; }
        .coordinator-confirmation-popup .coordinator-confirmation-popup-content .pre-text .callable {
          color: #00A664; }
          .coordinator-confirmation-popup .coordinator-confirmation-popup-content .pre-text .callable .desktop {
            display: none !important; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.edit-my-commute-popup {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 16%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 95%;
  width: 900px;
  height: 65%; }
  .edit-my-commute-popup:after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -2;
    border-radius: 8px; }
  .edit-my-commute-popup:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #FFF;
    z-index: -1;
    border-radius: 8px; }
  .edit-my-commute-popup.show {
    display: block; }
  .edit-my-commute-popup.hide {
    display: none; }
  .edit-my-commute-popup .edit-my-commute-details-popup {
    height: 100%;
    overflow: auto;
    padding: 24px; }
    .edit-my-commute-popup .edit-my-commute-details-popup .edit-commute-header {
      justify-content: space-between;
      display: flex;
      flex-direction: row; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-commute-header .header {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-commute-header .close {
        cursor: pointer; }
    .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content {
      margin: 0 auto;
      margin-top: 24px;
      overflow: auto; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations {
        display: flex;
        justify-content: space-between; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations label {
          font-family: 'DINNextLTPro-Medium';
          font-size: 14px;
          line-height: 25px;
          color: #4A4A4A;
          font-style: normal; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations .field {
          width: 48%; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations input {
          border: 2px solid #D5D5D5;
          height: 56px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          letter-spacing: -0.25px;
          color: #4A4A4A;
          width: 100%;
          padding: 15px; }
          .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations input:not(:focus) {
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            padding: 15px 80px 15px 15px; }
          .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations input ::placeholder {
            color: #838383; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations .invalid-address {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 600;
          color: #F96302;
          height: 3px;
          margin-bottom: 20px !important; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-days .commuteDays {
        margin-top: 24px; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-days .commuteDays .days .day-btn {
          color: #00A664;
          background-color: #FFFFFF;
          height: 40px;
          width: 40px;
          font-size: 14px;
          text-align: center;
          border-radius: 25px;
          border: solid 2px #00A664;
          cursor: pointer;
          margin: 0 15px 0 0;
          padding: 0;
          text-transform: uppercase;
          padding-top: 5px;
          line-height: 17px;
          font-family: 'DINNextLTPro-Regular';
          font-weight: 700; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-days .commuteDays .days .day-btn.active {
          color: #0F8952;
          border: solid 2px #0F8952;
          background: #D4EDE1; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-days .minCommuteDays {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-top: 16px;
        color: #838383; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-days .commute-days-count {
        color: #F96302;
        width: 429px;
        font-family: 'Proxima Nova';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-top: 16px;
        margin-bottom: 18px; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .time-entry {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        margin-top: 24px; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .time-entry .entry {
          display: flex;
          flex-wrap: wrap; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .time-entry .select-container:last-child {
          margin-left: 50px; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .flexible {
        margin: 24px 0px; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .flexible .radio-section {
          display: flex;
          margin-top: 10px;
          margin-left: 10px; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .employer .field {
        width: 48%; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .employer .field .label {
          font-family: 'DINNextLTPro-Bold';
          font-style: normal;
          font-weight: 100;
          font-size: 13px;
          line-height: 16px;
          color: #4A4A4A;
          margin-bottom: 4px; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .employer .field input {
          width: 100%;
          background: #FFFFFF;
          border: 2px solid #D5D5D5;
          box-sizing: border-box;
          height: 56px;
          padding: 16px 14px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: flex;
          align-items: center;
          letter-spacing: -0.25px;
          color: #4A4A4A; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .button-container {
        margin-top: 50px;
        margin-bottom: 50px; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .button-container .continue-button {
          width: 200px;
          height: 40px;
          border-radius: 5px;
          font-family: 'Proxima Nova Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF; }
          .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .button-container .continue-button:disabled {
            background-color: #838383;
            cursor: not-allowed; }
          .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .button-container .continue-button:enabled {
            background-color: #00A664; }

@media only screen and (max-width: 700px) {
  .edit-my-commute-popup {
    top: 10% !important;
    max-height: 90vh; }
    .edit-my-commute-popup .edit-my-commute-details-popup {
      max-height: 90vh; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-commute-header {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        margin: 24px 0px 24px; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-commute-header .header {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #4A4A4A;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 500; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-commute-header .close {
          cursor: pointer; }
      .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content {
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
        padding-bottom: 10px;
        overflow-y: scroll;
        max-height: 80vh;
        margin-top: -20px; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations {
          display: flex;
          flex-direction: column; }
          .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-locations .field {
            width: 100%; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .commute-days .commuteDays .days .day-btn {
          margin: 0 8px 0 0; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .time-entry {
          justify-content: space-between; }
          .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .time-entry .select-container {
            width: 160px; }
            .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .time-entry .select-container:last-child {
              margin-left: 0px; }
        .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .employer .field {
          width: 100%; } }

@media only screen and (max-width: 900px) and (min-width: 700px) {
  .edit-my-commute-popup {
    top: 20%; }
    .edit-my-commute-popup .edit-my-commute-details-popup .close {
      cursor: pointer; }
    .edit-my-commute-popup .edit-my-commute-details-popup .edit-my-commute-details-popup-content .commute-details .time-entry .select-container {
      width: 170px; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.email-changed {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 30%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 90%;
  width: 700px; }
  .email-changed:after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -2; }
  .email-changed:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #FFF;
    z-index: -1;
    border-radius: 8px; }
  .email-changed.show {
    display: block; }
  .email-changed.hide {
    display: none; }

.email-changed-popup {
  padding: 40px; }
  .email-changed-popup .charges-header {
    display: flex;
    justify-content: flex-end !important; }
    .email-changed-popup .charges-header .close {
      cursor: pointer; }
  .email-changed-popup .info-icon {
    display: flex;
    justify-content: center; }
    .email-changed-popup .info-icon .info-img {
      height: 48px;
      width: 48px; }
  .email-changed-popup .email-changed-popup-content {
    margin: 8px 40px; }
    .email-changed-popup .email-changed-popup-content .popup-title {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 500;
      text-align: center; }
    .email-changed-popup .email-changed-popup-content .pre-text {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-top: 10px;
      text-align: center; }
      .email-changed-popup .email-changed-popup-content .pre-text .callable {
        color: #00A664;
        margin-left: 5px; }
        .email-changed-popup .email-changed-popup-content .pre-text .callable .desktop {
          display: unset !important; }
  .email-changed-popup .button-container {
    display: flex;
    justify-content: center; }
    .email-changed-popup .button-container .close-button {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      color: #FFFFFF;
      border-radius: 5px;
      height: 40px;
      width: 160px; }
      .email-changed-popup .button-container .close-button:disabled {
        background-color: #838383;
        cursor: not-allowed;
        border: none; }
      .email-changed-popup .button-container .close-button:enabled {
        background-color: #00A664; }

@media only screen and (max-width: 700px) {
  .email-changed {
    top: 15% !important;
    max-height: 80vh; }
  .email-changed-popup {
    padding: 10px 10px 40px !important;
    max-height: 80vh; }
    .email-changed-popup .charges-header {
      margin: 10px; }
      .email-changed-popup .charges-header img {
        height: 20px; }
    .email-changed-popup .email-changed-popup-content {
      width: 95%;
      text-align: left;
      margin-bottom: 20px;
      padding-bottom: 10px;
      overflow-y: scroll;
      max-height: 70vh;
      margin: 8px; }
      .email-changed-popup .email-changed-popup-content .pre-text {
        height: 30%; }
        .email-changed-popup .email-changed-popup-content .pre-text .callable {
          color: #00A664; }
          .email-changed-popup .email-changed-popup-content .pre-text .callable .desktop {
            display: none !important; } }

.smart-banners {
  z-index: 200; }

.smartbanner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 90px;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  background: #FFFFFF;
  overflow: hidden;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: -54px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none; }

.smartbanner-container {
  margin: 0 auto;
  height: 90px; }

.smartbanner-close {
  position: absolute;
  left: 7px;
  top: 7px;
  display: block;
  font-family: 'ArialRoundedMTBold',Arial;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
  border: 0;
  width: 17px;
  height: 17px;
  line-height: 17px;
  color: #b1b1b3;
  background: #efefef;
  padding: 0px; }

.smartbanner-close:active, .smartbanner-close:hover {
  color: #333; }

.smartbanner-icon {
  position: absolute;
  left: 30px;
  top: 16px;
  display: block;
  width: 45px;
  height: 57px;
  background-color: #FFFFFF;
  background-size: cover;
  background-image: url("./images/logo.png"); }

.smartbanner-info {
  position: absolute;
  left: 98px;
  top: 20px;
  width: 44%;
  font-size: 12px;
  line-height: 1.2em;
  font-weight: bold;
  color: gray; }

.smartbanner-title {
  font-size: 15px;
  line-height: 17px;
  color: #000000;
  font-weight: bold; }

.smartbanner-button {
  position: absolute;
  right: 20px;
  top: 24px;
  min-width: 17%;
  font-size: 14px;
  line-height: 29px;
  text-align: center;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #049b5c;
  text-decoration: none; }

.smartbanner-button:hover {
  background-color: #049b5c; }

.smartbanner-button-text {
  text-align: center;
  display: block;
  padding: 5px 5px; }

.ios-smartbanner {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: 100%;
  height: 84px;
  background: #f3f3f3;
  font-family: Helvetica, sans, sans-serif;
  z-index: 200; }

.smartbanner__exit {
  position: absolute;
  top: calc(50% - 6px);
  left: 9px;
  display: block;
  margin: 0;
  width: 12px;
  height: 12px;
  border: 0;
  text-align: center; }

.smartbanner__exit::before, .smartbanner__exit::after {
  position: absolute;
  width: 1px;
  height: 12px;
  background: #767676;
  content: ' '; }

.smartbanner__exit::before {
  transform: rotate(45deg); }

.smartbanner__exit::after {
  transform: rotate(-45deg); }

.smartbanner__icon {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 64px;
  height: 64px;
  border-radius: 15px;
  background-size: 64px 64px;
  background-image: url("./images/new-logo.PNG"); }

.smartbanner__info {
  position: absolute;
  top: 10px;
  left: 104px;
  display: flex;
  overflow-y: hidden;
  width: 60%;
  height: 64px;
  align-items: center;
  color: #000; }

.smartbanner__info__title {
  font-size: 14px; }

.smartbanner__info__author, .smartbanner__info__price {
  font-size: 12px; }

.smartbanner__button {
  position: absolute;
  top: 32px;
  right: 10px;
  z-index: 1;
  display: block;
  padding: 0 10px;
  min-width: 10%;
  border-radius: 5px;
  background: #f3f3f3;
  color: #1474fc;
  font-size: 18px;
  text-align: center;
  text-decoration: none; }

.smartbanner__button__label {
  text-align: center; }

/*	COLORS
–––––––––––––––––––––––––––*/
.fuel-card-page {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  padding: 0 1rem;
  min-height: 100%;
  position: relative; }
  .fuel-card-page #as-of-date-time {
    margin-bottom: 2rem; }
  .fuel-card-page .row.space {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .fuel-card-page .row.space img {
      width: auto;
      height: 125px; }
    .fuel-card-page .row.space .help-text {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .fuel-card-page .row.space .help-text .help-number {
        font-weight: 600; }
  .fuel-card-page .logo {
    margin-bottom: 2.4375rem; }
    .fuel-card-page .logo img {
      max-height: 74px; }
  .fuel-card-page .activate-the-card, .fuel-card-page .active-card {
    margin-top: 4rem; }
    .fuel-card-page .activate-the-card .card-input .input-box, .fuel-card-page .activate-the-card .pin-input .input-box, .fuel-card-page .active-card .card-input .input-box, .fuel-card-page .active-card .pin-input .input-box {
      position: relative; }
      .fuel-card-page .activate-the-card .card-input .input-box .fancy-input input + .label, .fuel-card-page .activate-the-card .card-input .input-box .fancy-input input[value]:not([value=""]) + .label, .fuel-card-page .activate-the-card .pin-input .input-box .fancy-input input + .label, .fuel-card-page .activate-the-card .pin-input .input-box .fancy-input input[value]:not([value=""]) + .label, .fuel-card-page .active-card .card-input .input-box .fancy-input input + .label, .fuel-card-page .active-card .card-input .input-box .fancy-input input[value]:not([value=""]) + .label, .fuel-card-page .active-card .pin-input .input-box .fancy-input input + .label, .fuel-card-page .active-card .pin-input .input-box .fancy-input input[value]:not([value=""]) + .label {
        top: -20px;
        left: 8px;
        font-size: 0.6875rem;
        color: #333333; }
      .fuel-card-page .activate-the-card .card-input .input-box .active-card svg, .fuel-card-page .activate-the-card .pin-input .input-box .active-card svg, .fuel-card-page .active-card .card-input .input-box .active-card svg, .fuel-card-page .active-card .pin-input .input-box .active-card svg {
        fill: #049b5c;
        height: 1.5rem;
        width: 1.5rem;
        position: absolute;
        top: 8px; }
      .fuel-card-page .activate-the-card .card-input .input-box .error-text, .fuel-card-page .activate-the-card .pin-input .input-box .error-text, .fuel-card-page .active-card .card-input .input-box .error-text, .fuel-card-page .active-card .pin-input .input-box .error-text {
        color: red; }
      .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo, .fuel-card-page .activate-the-card .pin-input .input-box .wexCardInfo, .fuel-card-page .active-card .card-input .input-box .wexCardInfo, .fuel-card-page .active-card .pin-input .input-box .wexCardInfo {
        color: #049b5c; }
        .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo .info .message, .fuel-card-page .activate-the-card .pin-input .input-box .wexCardInfo .info .message, .fuel-card-page .active-card .card-input .input-box .wexCardInfo .info .message, .fuel-card-page .active-card .pin-input .input-box .wexCardInfo .info .message {
          color: #333333;
          background: #FFFFFF; }
          .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo .info .message .wexCard, .fuel-card-page .activate-the-card .pin-input .input-box .wexCardInfo .info .message .wexCard, .fuel-card-page .active-card .card-input .input-box .wexCardInfo .info .message .wexCard, .fuel-card-page .active-card .pin-input .input-box .wexCardInfo .info .message .wexCard {
            text-align: center; }
            .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo .info .message .wexCard img, .fuel-card-page .activate-the-card .pin-input .input-box .wexCardInfo .info .message .wexCard img, .fuel-card-page .active-card .card-input .input-box .wexCardInfo .info .message .wexCard img, .fuel-card-page .active-card .pin-input .input-box .wexCardInfo .info .message .wexCard img {
              width: 15rem; }
    .fuel-card-page .activate-the-card .card-input .input-box, .fuel-card-page .active-card .card-input .input-box {
      position: relative; }
      .fuel-card-page .activate-the-card .card-input .input-box input, .fuel-card-page .active-card .card-input .input-box input {
        width: 18rem; }
      .fuel-card-page .activate-the-card .card-input .input-box .active-card svg, .fuel-card-page .active-card .card-input .input-box .active-card svg {
        left: 16.1rem; }
    .fuel-card-page .activate-the-card .pin-input .input-box, .fuel-card-page .active-card .pin-input .input-box {
      position: relative; }
      .fuel-card-page .activate-the-card .pin-input .input-box input, .fuel-card-page .active-card .pin-input .input-box input {
        width: 10rem; }
      .fuel-card-page .activate-the-card .pin-input .input-box .active-card svg, .fuel-card-page .active-card .pin-input .input-box .active-card svg {
        left: 8rem; }
    .fuel-card-page .activate-the-card .card-input, .fuel-card-page .activate-the-card .pin-input, .fuel-card-page .active-card .card-input, .fuel-card-page .active-card .pin-input {
      margin: 2rem 0; }
      .fuel-card-page .activate-the-card .card-input .input-info, .fuel-card-page .activate-the-card .pin-input .input-info, .fuel-card-page .active-card .card-input .input-info, .fuel-card-page .active-card .pin-input .input-info {
        margin-top: -5px;
        font-size: 0.74rem;
        font-weight: 600;
        color: #b3b3b3; }
    .fuel-card-page .activate-the-card .button:disabled, .fuel-card-page .active-card .button:disabled {
      color: #FFFFFF;
      border-color: #CCCCCC;
      background: #CCCCCC;
      cursor: not-allowed; }
    .fuel-card-page .activate-the-card .reset-pin .reset-pin-link, .fuel-card-page .active-card .reset-pin .reset-pin-link {
      text-decoration: underline;
      font-style: italic; }
  .fuel-card-page .help-text {
    margin-top: 4rem; }
    .fuel-card-page .help-text span {
      color: #049b5c;
      font-weight: 600; }
  .fuel-card-page .card-summary-table .header-labels {
    display: none; }
  .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell {
    text-align: left; }
  .fuel-card-page .card-pending {
    text-align: center;
    padding: 3rem 0;
    font-family: 'Montserrat', sans-serif; }
    .fuel-card-page .card-pending h4 {
      font-weight: 600;
      font-size: 1.375rem;
      margin-bottom: .5rem; }
  @media (min-width: 768px) {
    .fuel-card-page .card-summary-table .header-labels {
      display: flex; }
      .fuel-card-page .card-summary-table .header-labels .etable-header {
        text-align: center;
        padding-right: 0; }
        .fuel-card-page .card-summary-table .header-labels .etable-header.fc-service-month {
          flex: 0 0 110px; }
        .fuel-card-page .card-summary-table .header-labels .etable-header.fc-activity-date {
          flex: 0 0 140px; }
        .fuel-card-page .card-summary-table .header-labels .etable-header.merchant, .fuel-card-page .card-summary-table .header-labels .etable-header .auth-num, .fuel-card-page .card-summary-table .header-labels .etable-header .gallons {
          flex: 0 0 260px; }
        .fuel-card-page .card-summary-table .header-labels .etable-header.auth-num, .fuel-card-page .card-summary-table .header-labels .etable-header.gallons {
          flex: 0 0 160px; }
        .fuel-card-page .card-summary-table .header-labels .etable-header.right {
          flex-grow: 1;
          text-align: right; }
    .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell {
      flex: none;
      text-align: center; }
      .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell.spacer {
        flex: 0 0 110px; }
      .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell.fc-activity-date {
        flex: 0 0 140px; }
      .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell.merchant, .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell .auth-num, .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell .gallons {
        flex: 0 0 260px; }
      .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell.auth-num, .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell.gallons {
        flex: 0 0 160px; }
      .fuel-card-page .card-summary-table .fuel-card-charges-table .etable-row .etable-column .etable-cell.amount {
        padding-right: 0;
        flex-grow: 1;
        text-align: right; } }
  @media (min-width: 768px) {
    .fuel-card-page .export-csv {
      position: absolute;
      top: 407px;
      right: 25px; } }
  @media (max-width: 512px) {
    .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo .info .message, .fuel-card-page .active-card .card-input .input-box .wexCardInfo .info .message {
      left: auto;
      top: 35px; }
      .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo .info .message .wexCard, .fuel-card-page .active-card .card-input .input-box .wexCardInfo .info .message .wexCard {
        text-align: center; }
        .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo .info .message .wexCard img, .fuel-card-page .active-card .card-input .input-box .wexCardInfo .info .message .wexCard img {
          width: 15rem; }
      .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo .info .message:before, .fuel-card-page .active-card .card-input .input-box .wexCardInfo .info .message:before {
        left: 18rem; }
      .fuel-card-page .activate-the-card .card-input .input-box .wexCardInfo .info .message:after, .fuel-card-page .active-card .card-input .input-box .wexCardInfo .info .message:after {
        left: calc(18rem + 1px); } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

/*	COLORS
–––––––––––––––––––––––––––*/
@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

.payment-history:has(> .payment-info) {
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding-top: 170px !important;
  background: #E5E5E5;
  padding-bottom: 30px !important; }

.payment-history {
  height: fit-content;
  overflow: auto;
  min-height: calc(100vh - 12.5rem); }
  .payment-history .payment-info .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    background-color: #FFFFFF;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    margin-bottom: 20px;
    padding-bottom: 40px; }
    .payment-history .payment-info .container .radio:before {
      display: none; }
    .payment-history .payment-info .container .radio .save-card {
      display: none; }
    .payment-history .payment-info .container .radio .card-type input {
      margin-right: .5rem; }
    .payment-history .payment-info .container .section .head {
      font-family: "DINNextLTPro-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #636363;
      margin-top: 30px; }
    .payment-history .payment-info .container .section .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 25px; }
      .payment-history .payment-info .container .section .body .add-card {
        height: 5rem;
        width: 35rem;
        max-width: 100%;
        border-radius: 0.25rem;
        border: 1px solid #e6e6e6;
        padding: 1rem;
        margin: 0 0 .5rem;
        background: #FFFFFF;
        cursor: pointer; }
        .payment-history .payment-info .container .section .body .add-card:disabled {
          color: #e6e6e6;
          cursor: default; }
      .payment-history .payment-info .container .section .body .card {
        width: 35rem; }

@media only screen and (max-width: 700px) {
  .payment-history .payment-info .container {
    margin: 16px;
    width: 90%; }
    .payment-history .payment-info .container .section {
      padding: 10px; }
      .payment-history .payment-info .container .section .body .card {
        width: 22rem; } }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

.green-link-underline {
  color: #00A664;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  letter-spacing: 0.5px;
  border-bottom: 2px solid #00A664;
  width: fit-content;
  cursor: pointer; }

.date-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  margin-right: 15px;
  height: 52px;
  width: 45px;
  background-color: #FCFCFE; }
  .date-section .day {
    font-family: "DINNextLTPro-Light";
    font-size: 24px;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 24px;
    letter-spacing: -1px;
    text-align: center;
    padding-top: 5px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px 7px 0px 0px; }
    .date-section .day.today {
      background-color: #D4EDE1; }
  .date-section .weekday {
    font-family: "DINNextLTPro-Regular";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    text-transform: uppercase;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #FFFFFF; }

.date-picker-custom {
  height: fit-content;
  background: #EDEDED;
  padding: 10px 15px;
  border: 1px solid #D5D5D5;
  border-radius: 4px; }
  .date-picker-custom .plan-heading {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0F8952; }
  .date-picker-custom .alternate-text {
    margin-top: 8px;
    color: #4A4A4A;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased; }
  .date-picker-custom .picker-container {
    margin-top: 15px; }
    .date-picker-custom .picker-container label {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: normal;
      color: #4A4A4A; }
    .date-picker-custom .picker-container .picker {
      background: #FFFFFF;
      border: 2px solid #D5D5D5;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
      margin-top: 5px; }
      .date-picker-custom .picker-container .picker .placeholder {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #838383;
        margin-left: 15px; }
      .date-picker-custom .picker-container .picker .time-arrow {
        border-right: 2px solid;
        border-bottom: 2px solid;
        padding: 3px;
        border-color: #00A664;
        height: 10px;
        width: 10px; }
        .date-picker-custom .picker-container .picker .time-arrow.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transition: transform 0.2s linear, opacity 0.2s linear; }
      .date-picker-custom .picker-container .picker .date-display {
        display: flex;
        justify-content: flex-start;
        margin: 5px 0 0 5px; }
        .date-picker-custom .picker-container .picker .date-display .day {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 40px;
          line-height: 24px;
          color: #00A664; }
        .date-picker-custom .picker-container .picker .date-display .month,
        .date-picker-custom .picker-container .picker .date-display .year {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.25px;
          color: #4A4A4A; }
        .date-picker-custom .picker-container .picker .date-display .month {
          margin-left: 10px; }
        .date-picker-custom .picker-container .picker .date-display .year {
          margin-left: 5px; }
  .date-picker-custom .foot-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-weight: 600;
    color: #4A4A4A;
    margin-top: 8px; }

.screenreader-only {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: -1px; }

.selected-days-badge {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #EDF4FC;
  width: fit-content;
  padding: 5px 8px;
  margin-top: 20px; }
  .selected-days-badge .selected-days-label {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    line-height: 19px !important; }
  .selected-days-badge .selected-days-value {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    line-height: 19px !important; }

.subscription-section {
  grid-gap: 10px; }
  .subscription-section.inactive {
    padding: 15px 15px;
    margin: 20px;
    background-color: #EDEDED;
    border-radius: 8px;
    border: 1px solid #D5D5D5;
    box-shadow: 0px 1px 0px 0px #D5D5D5; }
  .subscription-section.active {
    margin: 0px 20px;
    padding: 15px 10px; }
  .subscription-section .header {
    padding: 10px 0px 15px 0px !important; }
  .subscription-section .commute-days {
    margin-top: 24px; }
    .subscription-section .commute-days .commute-days-heading {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 600 !important;
      color: #838383;
      margin-bottom: 8px; }
    .subscription-section .commute-days .days {
      display: flex;
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-weight: 600; }
      .subscription-section .commute-days .days .day-btn {
        display: flex;
        align-items: center;
        text-align: center;
        color: #4A4A4A;
        height: 26px;
        width: 44px;
        background: #D5D5D5;
        border-radius: 4px;
        margin-right: 4px;
        text-transform: uppercase;
        padding-top: 2px;
        justify-content: center; }
  .subscription-section .change-my-commute {
    margin-top: 15px; }
  .subscription-section .ending-date {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-weight: 500;
    color: #F96302;
    margin-top: 10px; }
  .subscription-section .starting-date {
    font-family: 'DINNextLTPro-Regular';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-weight: 500;
    color: #4A90E2;
    margin-top: 8px; }
  .subscription-section .sections {
    display: flex;
    justify-content: space-between; }
    .subscription-section .sections .left-section .plan-details .plan {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      display: inline-block;
      text-transform: capitalize;
      border-radius: 4px;
      color: #FFFFFF;
      padding: 2px 12px; }
      .subscription-section .sections .left-section .plan-details .plan.active {
        background: #00A664; }
      .subscription-section .sections .left-section .plan-details .plan.inactive {
        background: #F96302; }
      .subscription-section .sections .left-section .plan-details .plan.new {
        background: #4A90E2; }
    .subscription-section .sections .left-section .plan-details .package {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      align-items: center;
      color: #00A664;
      margin-top: 8px; }
    .subscription-section .sections .right-section {
      max-width: 170px; }
      .subscription-section .sections .right-section .pending-text {
        color: #4A4A4A;
        margin-top: 15px;
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        line-height: 14px;
        text-align: end; }
      .subscription-section .sections .right-section .rate-details {
        margin-bottom: 5px; }
        .subscription-section .sections .right-section .rate-details.grayed span {
          color: #838383 !important; }
        .subscription-section .sections .right-section .rate-details img {
          height: 20px;
          width: 20px; }
        .subscription-section .sections .right-section .rate-details span {
          font-family: 'DINNextLTPro-Regular';
          font-style: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.25px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          font-weight: 700;
          color: #0F8952;
          padding-left: 5px; }
      .subscription-section .sections .right-section .rate {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .subscription-section .sections .right-section .rate .price-group {
          display: flex;
          color: #4A4A4A; }
          .subscription-section .sections .right-section .rate .price-group .doller {
            font-size: 25px;
            line-height: 23px;
            padding-right: 3px;
            font-weight: 400; }
          .subscription-section .sections .right-section .rate .price-group .price {
            font-family: 'DINNextLTPro-Regular';
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.25px; }
          .subscription-section .sections .right-section .rate .price-group .decimal {
            font-family: 'DINNextLTPro-Regular';
            font-weight: 100;
            font-size: 25px;
            line-height: 23px; }
        .subscription-section .sections .right-section .rate .perMonth {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: -0.25px;
          color: #4A4A4A;
          margin-left: 20px; }
  .subscription-section .sponsored-container {
    float: right;
    position: relative;
    cursor: pointer; }
    .subscription-section .sponsored-container img {
      height: 16px;
      width: 16px;
      margin-right: 4px; }
    .subscription-section .sponsored-container .sponsored-text {
      font-family: 'DINNextLTPro-Regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.25px;
      text-align: left;
      text-decoration: underline;
      text-underline-offset: 2px;
      color: #00A664; }
    .subscription-section .sponsored-container .tooltip {
      position: absolute;
      top: -6rem;
      right: 0;
      background-color: #4a4a4a;
      color: #FFFFFF;
      padding: 12px;
      border-radius: 8px;
      width: 320px;
      font-size: 14px;
      z-index: 1;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    .subscription-section .sponsored-container .tooltip-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left; }
      .subscription-section .sponsored-container .tooltip-content span {
        font-family: 'DINNextLTPro-Regular';
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: antialiased;
        padding-right: 8px;
        color: #FFFFFF; }
      .subscription-section .sponsored-container .tooltip-content img {
        width: 12px;
        height: 12px; }
    .subscription-section .sponsored-container .tooltip-arrow {
      position: absolute;
      top: 100%;
      left: 14rem;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #4A4A4A; }
    .subscription-section .sponsored-container .close-icon {
      margin-left: 8px;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 16px; }
    .subscription-section .sponsored-container a {
      text-decoration: underline;
      color: #00A664;
      font-weight: bold; }
    .subscription-section .sponsored-container a:hover {
      color: #00A664; }
  .subscription-section .viewAgreement {
    margin: 15px 0px !important; }
    .subscription-section .viewAgreement img {
      height: 22px;
      width: 22px;
      margin-bottom: 2px; }
    .subscription-section .viewAgreement span {
      margin-left: 5px; }

.discard-popup .buttons {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px; }
  .discard-popup .buttons .confirmation-continue-button {
    height: unset !important;
    width: unset !important;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #FFFFFF;
    border-radius: 5px;
    color: #FFFFFF;
    border-radius: 5px;
    background-color: #00A664 !important;
    font-family: 'Proxima Nova Bold' !important;
    padding: 6px 24px; }
    .discard-popup .buttons .confirmation-continue-button:disabled {
      background-color: #838383;
      cursor: not-allowed;
      border: none; }
    .discard-popup .buttons .confirmation-continue-button:enabled {
      background-color: #00A664; }
  .discard-popup .buttons .confirmation-discard-button {
    height: unset !important;
    width: unset !important;
    padding: 6px 24px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: #FFFFFF;
    border-radius: 5px;
    border: 2px solid #F96302;
    color: #F96302;
    border-radius: 5px;
    background-color: #FFFFFF !important;
    font-family: 'Proxima Nova Bold' !important; }
    .discard-popup .buttons .confirmation-discard-button:disabled {
      background-color: #838383;
      cursor: not-allowed;
      border: none; }
    .discard-popup .buttons .confirmation-discard-button:enabled {
      background-color: #00A664; }

@media only screen and (min-width: 751px) {
  .date-picker-custom .picker-container .picker {
    width: 360px; } }

@media only screen and (max-width: 768px) and (min-width: 701px) {
  .subscription-section .container {
    padding-left: 33rem; }
    .subscription-section .container .tooltip {
      top: 60rem;
      right: 3rem; } }

@media only screen and (max-width: 750px) {
  .date-picker-custom .picker-container .picker {
    width: 310px; }
  .subscription-section .container {
    padding-left: 53%; }
    .subscription-section .container .tooltip {
      top: 145%;
      right: 3rem; } }

.capsules-grid {
  width: 90%;
  display: flex;
  margin-top: 16px;
  margin-left: 28px;
  flex-wrap: wrap;
  gap: 16px; }
  .capsules-grid .user-capsule-container {
    width: fit-content;
    height: 26px;
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 16px;
    border: 1px solid #D5D5D5;
    display: flex;
    justify-content: space-evenly;
    padding: 4px 3px;
    align-items: center; }
    .capsules-grid .user-capsule-container.red {
      background-color: #FEEFE6; }
    .capsules-grid .user-capsule-container .name {
      color: #4A4A4A;
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-right: 5px; }
    .capsules-grid .user-capsule-container .image-container {
      padding-top: 2px !important; }
    .capsules-grid .user-capsule-container img, .capsules-grid .user-capsule-container .image-container {
      width: 18px;
      height: 18px;
      border-radius: 18px;
      margin-right: 6px; }
      .capsules-grid .user-capsule-container img .vanpooler-text, .capsules-grid .user-capsule-container .image-container .vanpooler-text {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center; }

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: local("DINNextLTPro-Regular"), url("./fonts/DINNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Bold";
  src: local("DINNextLTPro-Bold"), url("./fonts/DINNextLTPro-Bold.otf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/proxima_nova_regular.ttf") format("opentype"); }

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), url("./fonts/proxima_nova_semibold.ttf") format("opentype"); }

@font-face {
  font-family: "DINNextLTPro-Medium";
  src: local("DINNextLTPro-Medium"), url("./fonts/DINNextLTPro-Medium.otf") format("opentype"); }

.charges-popup {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 30%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 95%;
  width: 900px; }
  .charges-popup:after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -2; }
  .charges-popup:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #FFF;
    z-index: -1;
    border-radius: 8px; }
  .charges-popup.show {
    display: block; }
  .charges-popup.hide {
    display: none; }

.one-time-charges-popup {
  padding: 30px 25px 70px 25px; }
  .one-time-charges-popup .charges-header {
    display: flex;
    justify-content: flex-end !important; }
    .one-time-charges-popup .charges-header .close {
      cursor: pointer; }
  .one-time-charges-popup .one-time-charges-popup-content {
    width: 580px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    color: #4A4A4A;
    letter-spacing: -0.25px;
    font-style: normal;
    text-align: center; }
    .one-time-charges-popup .one-time-charges-popup-content .popup-title {
      font-family: 'DINNextLTPro-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      letter-spacing: -0.5px;
      color: #4A4A4A;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-bottom: 10px;
      margin-top: 10px; }
    .one-time-charges-popup .one-time-charges-popup-content .pre-text {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.25px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      margin-top: 15px; }
      .one-time-charges-popup .one-time-charges-popup-content .pre-text .callable {
        color: #00A664; }
        .one-time-charges-popup .one-time-charges-popup-content .pre-text .callable .desktop {
          display: unset !important; }

@media only screen and (max-width: 700px) {
  .charges-popup {
    top: 15% !important;
    max-height: 80vh; }
  .one-time-charges-popup {
    padding: 10px 10px !important;
    max-height: 80vh; }
    .one-time-charges-popup .charges-header {
      margin: 10px; }
      .one-time-charges-popup .charges-header img {
        height: 20px; }
    .one-time-charges-popup .one-time-charges-popup-content {
      width: 95%;
      text-align: left;
      margin-bottom: 20px;
      padding-bottom: 10px;
      overflow-y: scroll;
      max-height: 70vh; }
      .one-time-charges-popup .one-time-charges-popup-content .pre-text {
        height: 30%; }
        .one-time-charges-popup .one-time-charges-popup-content .pre-text .callable {
          color: #00A664; }
          .one-time-charges-popup .one-time-charges-popup-content .pre-text .callable .desktop {
            display: none !important; } }
